/* eslint-disable react/no-unescaped-entities */

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, H3, Paragraph } from "@engaging-tech/components"
import React, { useState } from "react"

import ErrorState from "../../business-information/components/error-state"
import Privacy from "../../surveyManagement/surveyInfo/components/Privacy"
import ErrorList from "../../ui/components/ErrorList"
import DatePicker from "../components/Page2/DatePicker"
import SectionSteps from "../components/Page2/SectionSteps"
import SurveyIntroCard from "../components/Page2/SurveyIntroCard"
import ParticipantsContainer from "../containers/Page2/Participants"
import SliceAndDiceContainer from "../containers/Page2/SliceAndDiceContainer"

const Page2 = ({
  title,
  introduction,
  setTitle,
  setIntroduction,
  startDate,
  endDate,
  setDate,
  saveSelfServeSurvey,
  error,
  setPage,
  currentPage,
  surveyData,
  setPrivate,
  privateValue,
  shareableLinks
}) => {
  const objectType = new URLSearchParams(window.location.search).get("inactive")

  const [pageErrors, setPageErrors] = useState({})

  const handleErrors = (localError, attribute) => {
    if (localError) {
      setPageErrors({ ...pageErrors, [attribute]: localError })
    }

    if (Object.keys(pageErrors).includes(attribute) && !localError) {
      const newPageErrors = JSON.parse(JSON.stringify(pageErrors))
      delete newPageErrors[attribute]
      setPageErrors(newPageErrors)
    }
  }

  const handleGoBack = () => {
    setPage(currentPage - 1)
  }

  const handleSubmit = () => {
    saveSelfServeSurvey(surveyData)
    setPage(currentPage + 1)
  }

  const errorKeys = Object.keys(pageErrors)

  if (error) return <ErrorState />

  return (
    <Flex
      width={1 / 1}
      flexDirection="column"
      mt={[3, 4]}
      px={[0, 4, 6]}
      alignItems="center"
    >
      <H3
        fontSize={5}
        textAlign="left"
        color="secondary.0"
        mb={2}
        fontWeight={600}
      >
        Template in Use: Self Serve Survey
      </H3>
      <Paragraph>
        Now it's time to set up your survey. Write a short introduction to
        provide context to your employees to encourage them to fill in the
        survey
      </Paragraph>
      <SurveyIntroCard
        title={title}
        introduction={introduction}
        setTitle={setTitle}
        setIntroduction={setIntroduction}
        handleErrors={handleErrors}
        titlePlaceholder="Write your survey title (e.g. Engagement Survey)"
        introPlaceholder="Write a short introduction to your survey to help your employees understand why you are asking them to complete it and what the benefits to them will be."
      />
      <SectionSteps />
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        setDate={setDate}
        handleErrors={handleErrors}
        pageErrors={pageErrors}
        introduction="Select a start and finish date for your survey. We recommend surveys start and finish on a weekday. Make sure to give participants adequate time to complete the survey."
      />
      <ParticipantsContainer
        pageErrors={pageErrors}
        handleErrors={handleErrors}
      />
      <Privacy
        values={{ private: privateValue }}
        setFieldValue={setPrivate}
        mb={4}
      />
      {objectType ? (
        <SliceAndDiceContainer
          hideContinueButton={!!errorKeys.length}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          shareableLinks={shareableLinks}
        />
      ) : (
        <Flex justifyContent="space-between" mt={4}>
          <Button
            color="light.0"
            bg="primary.0"
            width="120px"
            onClick={handleGoBack}
          >
            BACK
          </Button>
          <Button
            color="light.0"
            bg={errorKeys.length ? "dark.2" : "primary.0"}
            width="120px"
            onClick={handleSubmit}
            disabled={errorKeys.length}
          >
            NEXT
          </Button>
        </Flex>
      )}

      <Flex width={1 / 1} alignItems="center" my={3} flexDirection="column">
        {!!errorKeys.length && (
          <ErrorList pageErrors={pageErrors} errorKeys={errorKeys} />
        )}
      </Flex>
    </Flex>
  )
}

export default Page2
