/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

import { ReactComponent as CheckMarkGreenSvgIcon } from "./check-mark-green-icon.svg"
import { ReactComponent as NoImageRectangleSvgIcon } from "./no-image-rectangle-icon.svg"

export const CheckMarkGreenIcon = styled(CheckMarkGreenSvgIcon)`
  width: 28px;
  height: 28px;
`

export const NoImageRectangleIcon = styled(NoImageRectangleSvgIcon)`
  width: 120px;
  height: 70px;
`
