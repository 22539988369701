import React from "react"
import styled from "styled-components"

import { Card, H1, Flex, Button } from "@engaging-tech/components"

import Disclaimer from "./Disclaimer"

const FormWrapper = styled.form`
  width: 100%;
`

const FormCard = ({ title, onSubmit, disableSubmitWhen, children }) => {
  return (
    <Card bg="light.0" p={3} mx={[3, 3, 4]} width="auto">
      <H1 fontSize={4} fontWeight="500" mb={4}>
        {title}
      </H1>
      <FormWrapper onSubmit={onSubmit}>
        {children}
        <Disclaimer />
        <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
          <Button
            width={168}
            type="submit"
            variant="primary.0"
            ml={3}
            disabled={disableSubmitWhen}
          >
            Publish
          </Button>
        </Flex>
      </FormWrapper>
    </Card>
  )
}

export default FormCard
