import React from "react"
import { useParams } from "@engaging-tech/routing"
import { Flex, Card, Box, Text } from "@engaging-tech/components"
import { ErrorTypes } from "../store/report.reducer"
import ReportHeader from "../Header/containers/ReportHeader"
import { emailEB } from "../../../lib/globalConstants"

const GenericError = () => (
  <Card
    mt={[5, 5, 6]}
    mb={[6, 5]}
    elevation={8}
    bg="light.0"
    alignItems="center"
    maxWidth="800px"
  >
    <Box bg="dark.4" py={[2, 3]} px={3} width={1 / 1} mb={5}>
      <Text
        fontSize={[3, 6]}
        textAlign="center"
        fontWeight="500"
        display="block"
      >
        Oops! Something Went Wrong…
      </Text>
    </Box>
    <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
      <Text fontWeight="500" mb={3} width="auto" textAlign="center">
        Unfortunately we were not able to process the data for this survey.
      </Text>
      <Text fontSize={3} mb={3} width="auto" textAlign="center" color="dark.2">
        If you need some help as to why you are seeing this message, please get
        in touch with us at:
      </Text>
      <Text
        fontWeight="500"
        fontSize={3}
        mb={3}
        width="auto"
        textAlign="center"
        color="primary.0"
      >
        {emailEB}
      </Text>

      <Text fontSize={3} mb={5} width="auto" textAlign="center" color="dark.2">
        If you are seeing this message repeatedly, please sign out of your
        account and try again.
      </Text>
    </Flex>
  </Card>
)

const NotPermittedError = () => (
  <Card
    mt={[5, 5, 6]}
    mb={[6, 5]}
    elevation={8}
    bg="light.0"
    alignItems="center"
    maxWidth="800px"
  >
    <Box bg="dark.4" py={[2, 3]} px={3} width={1 / 1} mb={5}>
      <Text
        fontSize={[3, 6]}
        textAlign="center"
        fontWeight="500"
        display="block"
      >
        Not Permitted
      </Text>
    </Box>
    <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
      <Text fontWeight="500" mb={3} width="auto" textAlign="center">
        You do not have the correct permissions to view this survey. Please
        return to the Dashboard.
      </Text>
    </Flex>
  </Card>
)

const FilterPermissionsError = () => {
  const { id } = useParams()

  if (id === "5f50cc59bdc92a25badd1213") {
    return (
      <>
        <ReportHeader isErrorView />
        <Card
          mt={[5, 5, 6]}
          mb={[6, 5]}
          elevation={8}
          bg="light.0"
          alignItems="center"
          maxWidth="800px"
        >
          <Box bg="dark.4" py={[2, 3]} px={3} width={1 / 1} mb={5}>
            <Text
              fontSize={[3, 6]}
              textAlign="center"
              fontWeight="500"
              display="block"
            >
              Welcome to the Your Say Survey Dashboard!
            </Text>
          </Box>
          <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
            <Text fontWeight="500" mb={3} width="auto" textAlign="center">
              In order to see your report, please click on the orange
              &apos;FILTER RESULTS&apos; button just above this box.
            </Text>
          </Flex>
        </Card>
      </>
    )
  }
  return (
    <>
      <ReportHeader isErrorView />
      <Card
        mt={[5, 5, 6]}
        mb={[6, 5]}
        elevation={8}
        bg="light.0"
        alignItems="center"
        maxWidth="800px"
      >
        <Box bg="dark.4" py={[2, 3]} px={3} width={1 / 1} mb={5}>
          <Text
            fontSize={[3, 6]}
            textAlign="center"
            fontWeight="500"
            display="block"
          >
            Filter Combination Not Permitted
          </Text>
        </Box>
        <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
          <Text fontWeight="500" mb={3} width="auto" textAlign="center">
            You are not permitted to see the results of the current filter
            combination. This could mean you either have not yet applied your
            permitted filters or have applied a filter you do not have
            permissions for. Try and change the filters in the orange box of the
            top right hand corner to the groups you are permitted to view.
          </Text>
          <Text fontWeight="500" mb={3} width="auto" textAlign="center">
            If you think there has been a mistake please contact one of the
            administrators of your team.
          </Text>
        </Flex>
      </Card>
    </>
  )
}

const ErrorComponents = {
  [ErrorTypes.generic]: GenericError,
  [ErrorTypes.ERR100]: NotPermittedError,
  [ErrorTypes.ERR101]: FilterPermissionsError
}

const ErrorView = ({ errorType = ErrorTypes.generic }) => {
  const ErrorComponent = ErrorComponents[errorType]
  return <ErrorComponent />
}

export default ErrorView
