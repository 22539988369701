import styled from "styled-components"
import { Text } from "@engaging-tech/components"

const AbsoluteErrorText = styled(Text)`
  position: absolute;
  width: fit-content;
  top: ${({ top }) => top || "10px"};
  right: ${({ right }) => right || "10px"};
  font-size: 12px;
  color: red;
`

export default AbsoluteErrorText
