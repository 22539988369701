import { Box, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useContext } from "react"

import SignUpNavigation from "./SignUpNavigation"
import FormTextField from "./FormTextField"

const NameForm = ({
  context,
  handleChange,
  touched,
  values,
  handleBlur,
  errors
}) => {
  const { handleNext } = useContext(context)

  const isValid = !errors.firstName && !errors.lastName

  return (
    <>
      <Box my={4} px={4}>
        <FormTextField
          width={1 / 1}
          id="sign-up-firstname"
          name="firstName"
          type="text"
          label="First Name"
          borderRadius="4px 4px 0px 0px"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
          error={touched.firstName && errors.firstName}
        />
        <FormTextField
          width={1 / 1}
          id="sign-up-surname"
          name="lastName"
          type="text"
          borderRadius="4px 4px 0px 0px"
          label="Surname"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          error={touched.lastName && errors.lastName}
        />
        <Text fontSize={3} color="dark.3">
          *PLEASE NOTE If you are entering any awards please create an account
          to set up your awards survey
        </Text>
      </Box>
      <SignUpNavigation
        primary={{
          label: "Next",
          action: () => {
            // submit form to cogntio??
            handleNext()
          },
          disabled: !isValid
        }}
        secondary={{
          label: "Cancel",
          action: () => {
            window.location.href = getConfig().publicSite
          },
          disabled: false
        }}
      />
    </>
  )
}
export default NameForm
