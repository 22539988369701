import { connect } from "react-redux"
import StepScoreComponent from "../../components/ScoreGraphic"
import * as Selectors from "../store/sixstepsoverview.selectors"

const mapState = (state, { step }) => ({
  score: Selectors.getCurrentStepScore(state, step),
  industry: Selectors.getCurrentStepIndustry(state, step),
  stepName: step
})

const StepScore = connect(mapState)(StepScoreComponent)

export default StepScore
