import { types } from "./basket.actions"

const general = "Something went wrong"

export const initialState = {
  addedToBasket: null,
  isLoading: false,
  error: { general: "", error: null },
  tax: 0,
  totalPrice: 0,
  price: 0,
  data: [],
  acceptedCurrencies: [],
  stripePaymentError: { general: "", error: null },
  invoicePaymentError: { general: "", error: null }
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.SET_ADDED_TO_BASKET: {
      return {
        ...state,
        addedToBasket: payload
      }
    }

    case types.HANDLE_STRIPE_PAYMENT_FAIL: {
      return {
        ...state,
        stripePaymentError: { general, error: payload.error }
      }
    }

    case types.HANDLE_INVOICE_FAIL: {
      return {
        ...state,
        invoicePaymentError: { general, error: payload.error }
      }
    }

    case types.SAVE_BASKET_SUCCESS:
    case types.REMOVE_FROM_BASKET_SUCCESS:
    case types.GET_BASKET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload.products,
        price: payload.price,
        totalPrice: payload.totalPrice,
        acceptedCurrencies: payload.acceptedCurrencies,
        tax: payload.tax
      }
    }

    case types.REMOVE_FROM_BASKET_FAIL:
    case types.GET_BASKET_FAIL:
    case types.SAVE_BASKET_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: { general, error: payload.error }
      }
    }

    case types.GET_BASKET_REQ:
    case types.SAVE_BASKET_REQ: {
      return {
        ...state,
        isLoading: true
      }
    }

    case types.SET_SELECTED_PRODUCT: {
      const selectedProductIdx = state.data.findIndex(
        product => product.object_type === payload.object_type
      )

      if (selectedProductIdx === -1) {
        return {
          ...state,
          data: [...state.data, payload]
        }
      }

      return {
        ...state,
        data: [
          ...state.data.slice(0, selectedProductIdx),
          { ...payload },
          ...state.data.slice(selectedProductIdx + 1)
        ]
      }
    }

    default:
      return state
  }
}
