import { connect } from "react-redux"
import { setCurrentPage } from "../store/actions"
import Page1 from "../views/Page1"
import { getSelectedProduct } from "../../../store/products/products.selectors"
import { getObjectType } from "../store/selectors"

const mapState = state => ({
  selectedProduct: getSelectedProduct(state, {
    object_type: getObjectType(state)
  }),
  objectType: getObjectType(state)
})

const mapDispatch = dispatch => ({
  setPage: page => dispatch(setCurrentPage(page))
})

const Page1Container = connect(mapState, mapDispatch)(Page1)

export default Page1Container
