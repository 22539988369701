import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

const CircleFlex = styled(Flex)`
  border-radius: 100%;
  border: ${({ theme }) => `2px ${theme.colors.secondary[0]} solid`};
`

const CircledNumber = ({ number }) => {
  return (
    <Flex width="auto" flexDirection="column" alignItems="center">
      <CircleFlex
        width="50px"
        height="50px"
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
      >
        <Text width="auto" noSelect color="secondary.0">
          {number}
        </Text>
      </CircleFlex>
    </Flex>
  )
}

export default CircledNumber
