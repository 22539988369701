import { Spinner } from "@engaging-tech/components"
import { Redirect, usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import { getConfig } from "@engaging-tech/ssr-config"

import EBNBilling from "../containers/EBNBilling"

const EBNBillingWrapper = ({
  cancelRoute,
  isAuthenticated,
  isEBNMember,
  hasPaid,
  isLoading,
  children
}) => {
  const [confirm, setConfirm] = useState(false)

  const router = useRouter()
  const paths = usePaths()

  const handleCancel = () =>
    isAuthenticated
      ? router.navigate(cancelRoute)
      : router.navigate(getConfig().publicSite)

  const handleAction = () =>
    isAuthenticated ? setConfirm(true) : router.navigate(paths.account.signUp)

  if (hasPaid) {
    router.navigate(paths.account.ebnSuccess)
  }

  if (confirm) {
    return <EBNBilling onBack={() => setConfirm(false)} />
  }

  // we need to touch this when building this as a feature

  // if (isEBNMember) {
  //   return <Redirect to="/surveys" />
  // }

  return isLoading ? (
    <Spinner justifyContent="center" color="primary.0" />
  ) : (
    children({ handleCancel, handleAction })
  )
}

export default EBNBillingWrapper
