import React from "react"
import { Flex, MotionFlex, Text } from "@engaging-tech/components"

import SurveyAnimatedIllustration from "./SurveyAnimatedIllustration"
import TemplateAnimatedIllustration from "../stepOne/TemplateAnimatedIllustration"
import ConnectionLine from "./ConnectionLine"

const MultipleSurveysOneTemplateIllustration = ({
  width = 500,
  height = 500
}) => {
  const surveySize = {
    width: (width / 100) * 34,
    height: (height / 100) * 50
  }
  const templateSize = {
    width: (width / 100) * 32,
    height: (height / 100) * 46
  }

  const lineSize = {
    length: (width / 100) * 52,
    left: (width / 100) * 30,
    top: (height / 100) * 50
  }
  return (
    <Flex width={width} height={height} position="relative">
      <ConnectionLine
        width={10}
        length={lineSize.length}
        bg="secondary.0"
        delay={1.4}
        style={{
          transform: "rotate(-40deg)",
          left: lineSize.left,
          top: lineSize.top,
          position: "absolute"
        }}
      />
      <ConnectionLine
        width={10}
        length={lineSize.length}
        bg="secondary.0"
        delay={1.5}
        style={{
          transform: "rotate(40deg)",
          left: lineSize.left,
          top: lineSize.top,
          position: "absolute"
        }}
      />
      <MotionFlex
        initial={{ scale: 0 }}
        animate={{ scale: 1, transition: { delay: 1, duration: 0.4 } }}
        position="absolute"
        style={{ left: "0px", top: (height / 100) * 28 }}
        width={templateSize.width}
        height={templateSize.height}
        flexDirection="column"
        pb={24}
      >
        <Text height={20} mb="4px">
          Template
        </Text>
        <TemplateAnimatedIllustration
          noAnimation
          width={templateSize.width}
          height={templateSize.height}
        />
      </MotionFlex>
      <MotionFlex
        initial={{ scale: 0 }}
        animate={{ scale: 1, transition: { delay: 1.5, duration: 0.4 } }}
        width={surveySize.width}
        height={surveySize.height}
        position="absolute"
        style={{ right: "0px", top: "0px" }}
        flexDirection="column"
      >
        <Text height={20} mb="4px">Surveys</Text>
        <SurveyAnimatedIllustration
          noAnimation
          forceSequenceCount={6}
          hideTemplate
          width={surveySize.width}
          height={surveySize.height}
        />
      </MotionFlex>

      <MotionFlex
        initial={{ scale: 0 }}
        animate={{ scale: 1, transition: { delay: 1.6, duration: 0.4 } }}
        width={surveySize.width}
        height={surveySize.height}
        position="absolute"
        style={{ right: "0px", top: surveySize.height + 36 }}
      >
        <SurveyAnimatedIllustration
          noAnimation
          forceSequenceCount={6}
          hideTemplate
          width={surveySize.width}
          height={surveySize.height}
        />
      </MotionFlex>
    </Flex>
  )
}

export default MultipleSurveysOneTemplateIllustration
