import { usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import { OrgGuard } from "../../features/account"
import BackBar from "../../features/ui/components/BackBar"
import PageLayout from "../../layouts/PageLayout"
import Home from "../../features/jobs/views/Home"
import addParams from "../../utils/addParams"

// TODO: Update the back button

const Live = () => {
  useEffect(() => {
    addParams(false, getConfig().entitlements.jobsBoard, "type")
  }, [])

  const paths = usePaths()
  return (
    <OrgGuard>
      <PageLayout
        title="Live Jobs"
        banner={() => <BackBar to={paths.home.index} text="Back" visible />}
      >
        <Home />
      </PageLayout>
    </OrgGuard>
  )
}

export default Live
