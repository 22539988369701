import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const getTitle = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.title
)

export const getReportTypename = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.__typename
)

export const getReportMeta = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.meta
)

export const getReportsSurveyStartDate = createSelector(
  getReportMeta,
  response => response?.startDate
)

export const getLayout = createSelector(
  ReportSelectors.getReportLayout,
  layout => layout?.find(element => element.name === "reportHeader")?.children
)
