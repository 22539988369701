/* eslint-disable react/jsx-curly-newline */
import React, { useState } from "react"
import { createPortal } from "react-dom"
import {
  Button,
  CustomHooks,
  Flex,
  Modal,
  MotionFlex,
  Text,
  Card,
  Icon
} from "@engaging-tech/components"
import styled from "styled-components"
import { Link, usePaths, useRouter } from "@engaging-tech/routing"
import useBusinessAppMutation from "../../../../hooks/useBusinessAppMutation"
import {
  saveTemplateReq,
  saveTemplateSuccess,
  saveTemplateFail
} from "../store/template.actions"
import { SAVE_ORGANISATION_TEMPLATE } from "../services/template.queries"
import ShowPageErrors from "./ShowPageErrors"

const HoverFlex = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ShowError = ({ openModal, setOpenModal, clearApiErrors, errors }) => {
  return createPortal(
    <Modal onToogle={openModal}>
      <Card bg="light.0" alignItems="center" width={1 / 1} py={4}>
        <Text width="fit-content">{errors}</Text>
        <Button
          bg="secondary.0"
          color="light.0"
          width="100px"
          mt={2}
          onClick={() => {
            setOpenModal(false)
            clearApiErrors()
          }}
        >
          Ok
        </Button>
      </Card>
    </Modal>,
    appRoot()
  )
}

const BottomStickyNav = ({
  viewOnly,
  errors,
  templateId,
  isDraft,
  saveTemplateVariables,
  clearApiErrors,
  pageErrors
}) => {
  const device = CustomHooks.useClientDevice()
  const [openModal, setOpenModal] = useState(false)
  const [showPageErrors, setShowPageErrors] = useState(false)

  const paths = usePaths()
  const router = useRouter()

  const variants = {
    hidden: {
      bottom: -100
    },
    visible: {
      bottom: 0,
      transition: { delay: 2 }
    }
  }

  const redirectToSurveyCreation = id => {
    router.navigate(`${paths.surveys.surveyCreation.index}?templateId=${id}`)
  }

  const redirectToTemplateindex = () => {
    router.navigate(paths.surveys.buildATemplate.index)
  }

  const { post } = useBusinessAppMutation(SAVE_ORGANISATION_TEMPLATE, {
    initAction: saveTemplateReq,
    successAction: saveTemplateSuccess,
    failAction: saveTemplateFail
  })

  return (
    <>
      <MotionFlex
        width="100vw"
        height={[100, 70]}
        justifyContent="space-between"
        flexDirection={["column", "row"]}
        bg="light.0"
        position="fixed"
        alignItems="center"
        px={5}
        py={[3, 0]}
        variants={variants}
        animate="visible"
        initial="hidden"
        zIndex={99999999}
        elevation={3}
      >
        <Flex width="auto" mb={[2, 0]}>
          <Link to={paths.surveys.buildATemplate.index}>
            <Button
              width="auto"
              variant="secondary.0"
              px={3}
              style={{ minHeight: 32 }}
              mr={3}
            >
              {viewOnly ? "Back" : "Close"}
            </Button>
          </Link>

          {!viewOnly && (
            <Button
              style={{ minHeight: 32 }}
              width="auto"
              variant="secondary.0"
              disabled={Object.keys(pageErrors).length > 0}
              px={3}
              onClick={() =>
                post(saveTemplateVariables, redirectToTemplateindex)
              }
            >
              Save and close
            </Button>
          )}
          {viewOnly && isDraft && (
            <Link
              to={`${paths.surveys.buildATemplate.editTemplate.replace(
                ":id",
                templateId
              )}`}
            >
              <Button
                style={{ minHeight: 32 }}
                width="auto"
                variant="secondary.0"
                px={3}
              >
                Edit template
              </Button>
            </Link>
          )}
        </Flex>

        {pageErrors.length > 0 && (
          <HoverFlex
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            position="relative"
            onMouseEnter={() => setShowPageErrors(true)}
            onMouseLeave={() => setShowPageErrors(false)}
          >
            <Icon name="warning" color="error.0" mr={2} size={40} />
            <Text fontSize={5} color="error.0">
              Errors
            </Text>
            {showPageErrors && <ShowPageErrors pageErrors={pageErrors} />}
          </HoverFlex>
        )}

        <Button
          width={viewOnly ? 200 : 240}
          minWidth={viewOnly ? 200 : 240}
          variant="primary.0"
          style={{ minHeight: 32 }}
          color="light.0"
          trailingIcon="arrow_selected"
          disabled={Object.keys(pageErrors).length > 0}
          onClick={() => {
            if (viewOnly) {
              redirectToSurveyCreation(templateId)
            } else {
              post(saveTemplateVariables, () =>
                redirectToSurveyCreation(templateId)
              )
            }
          }}
          px={3}
        >
          <Text color="light.0" fontSize="14px" width="auto">
            {(() => {
              if (viewOnly) {
                return "Create survey"
              }
              return device === "MOBILE"
                ? "Save and create survey"
                : "Save and create survey"
            })()}
          </Text>
        </Button>
      </MotionFlex>
      {errors && (
        <ShowError
          openModal={openModal}
          setOpenModal={setOpenModal}
          clearApiErrors={clearApiErrors}
          errors={errors}
        />
      )}
    </>
  )
}

export default BottomStickyNav
