import { connect } from "react-redux"

import {
  setCurrentPage,
  setDate,
  setIntroduction,
  setPrivate,
  setTitle
} from "../store/actions"
import {
  getCurrentPage,
  getDates,
  getError,
  getIntroduction,
  getPrivate,
  getShareableLinks,
  getSurveyObj,
  getTitle
} from "../store/selectors"
import Page2 from "../views/Page2"

const mapState = state => ({
  title: getTitle(state),
  introduction: getIntroduction(state),
  startDate: getDates(state).startDate,
  endDate: getDates(state).endDate,
  error: getError(state),
  currentPage: getCurrentPage(state),
  privateValue: getPrivate(state),
  surveyData: getSurveyObj(state),
  shareableLinks: getShareableLinks(state)
})

const mapDispatch = dispatch => ({
  setTitle: title => dispatch(setTitle(title)),
  setIntroduction: intro => dispatch(setIntroduction(intro)),
  setDate: dateObj => dispatch(setDate(dateObj)),
  setPage: page => dispatch(setCurrentPage(page)),
  setPrivate: (key, value) => dispatch(setPrivate(value))
})

const Page2Container = connect(mapState, mapDispatch)(Page2)

export default Page2Container
