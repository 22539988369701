import { Flex, Icon, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const BorderedCard = styled(Flex)`
  margin-top: 1px;
  border-top: ${({ isSelected, theme }) =>
    isSelected
      ? `1px solid ${theme.colors.secondary[1]}`
      : "1px solid #c2c2c2"};
  border-bottom: ${({ isSelected, theme }) =>
    isSelected
      ? `1px solid ${theme.colors.secondary[1]}`
      : "1px solid #c2c2c2"};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.secondary[2] : theme.colors.dark[4]};
  height: 35px;
  min-height: 35px;
  width: 95%;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: #d2d2d2;
  }

  &:focus {
      background-color: ${({ theme }) => theme.colors.secondary[2]};
      border-top: ${({ theme }) => `1px solid ${theme.colors.secondary[1]}`};
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.secondary[1]}`};
    }
  }
`

const ListFlex = styled(Flex)`
  flex-direction: column;
  overflow: scroll;
  align-items: center;
  height: 149px;

  ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

const StepList = ({ bespokeSteps, setSelectedStep, selectedStep }) => {
  return (
    <ListFlex>
      {bespokeSteps.map(step => (
        <BorderedCard
          key={step}
          p="0px 15px"
          fontSize={3}
          onClick={() => setSelectedStep(step)}
          isSelected={step === selectedStep}
        >
          <Icon name="add_circle" mr={2} color="#c2c2c2" />
          <Text>{step}</Text>
        </BorderedCard>
      ))}
    </ListFlex>
  )
}

export default StepList
