import {
  Button,
  Flex,
  MotionFlex,
  TabletUp,
  MobileOnly,
  Text
} from "@engaging-tech/components"

import React from "react"
import { createGlobalStyle } from "styled-components"

import StepSlide from "./StepSlide"

// Moves freshchat up above navbar
const GlobalFCStyle = createGlobalStyle`
  #fc_frame {
    transform: translateY(-50px);
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    #fc_frame {
    transform: translateY(-100px);
  }
  }

`

const variants = {
  hidden: {
    bottom: -115
  },
  visible: {
    bottom: 0,
    transition: { delay: 2 }
  }
}

const BottomStickyNav = ({ step, stepHandler }) => {
  const buttonWidth = 90

  return (
    <>
      <GlobalFCStyle />
      <MotionFlex
        width="100vw"
        height={[115, 70]}
        justifyContent="center"
        flexDirection={["column", "row"]}
        bg="light.0"
        position="fixed"
        alignItems="center"
        px={5}
        py={[3, 0]}
        variants={variants}
        initial="hidden"
        animate="visible"
        zIndex={99999999}
        elevation={3}
      >
        <MobileOnly width="100%" mx={3} mt={1} mb={1}>
          <Flex justifyContent="center" width="100%">
            <StepSlide
              selectedStep={step}
              stepHandler={stepHandler}
              width="100%"
              maxWidth={400}
            />
          </Flex>
        </MobileOnly>
        <Flex
          width="100%"
          mb={[2, 0]}
          maxWidth={1280}
          justifyContent="space-between"
          alignItems="center"
        >
          <MotionFlex
            width={buttonWidth}
            minWidth={buttonWidth}
            initial={{ opacity: 0 }}
            animate={step > 1 ? { opacity: 1 } : { opacity: 0 }}
          >
            <Button
              width={buttonWidth}
              variant="secondary.0"
              style={{
                cursor: step > 1 ? "pointer" : "initial",
                minHeight: 32
              }}
              disabled={step === 1}
              onClick={() => {
                stepHandler(step - 1)
              }}
            >
              <Text color="light.0">Back</Text>
            </Button>
          </MotionFlex>
          <TabletUp width="100%" mx={3} justifyContent="center">
            <Flex justifyContent="center" width="100%">
              <StepSlide
                selectedStep={step}
                stepHandler={stepHandler}
                maxWidth={400}
              />
            </Flex>
          </TabletUp>

          <MotionFlex
            width={buttonWidth}
            minWidth={buttonWidth}
            initial={{ opacity: 0 }}
            animate={step < 3 ? { opacity: 1 } : { opacity: 0 }}
          >
            <Button
              style={{
                cursor: step < 3 ? "pointer" : "initial",
                minHeight: 32
              }}
              width={buttonWidth}
              disabled={step === 3}
              variant="primary.0"
              onClick={() => {
                stepHandler(step + 1)
              }}
            >
              <Text color="light.0">Next</Text>
            </Button>
          </MotionFlex>
        </Flex>
      </MotionFlex>
    </>
  )
}

export default BottomStickyNav
