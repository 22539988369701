import { Box, Flex, Text } from "@engaging-tech/components"
import React from "react"

import ClickableIcon from "../../../ui/components/ClickableIcon"

export default function EditableField({
  heading,
  children,
  hiddeEdit = false,
  onEditDisabled = false,
  onEdit = () => null
}) {
  return (
    <Box>
      <Flex mb={3}>
        <Text>{heading}</Text>
        {hiddeEdit ? null : (
          <ClickableIcon
            name="mode_edit"
            disabled={onEditDisabled}
            onClick={e => {
              e.preventDefault()
              onEdit()
            }}
          />
        )}
      </Flex>
      {children}
    </Box>
  )
}
