/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const getAllIndicators = createSelector(
  ReportSelectors.getReportData,
  data => data?.indicators
)

export const getDiversityAndInclusion = createSelector(
  getAllIndicators,
  indicators => indicators?.diIndex
)

export const getDiversityAndInclusionIndex = createSelector(
  getDiversityAndInclusion,
  sections => sections?.find(section => section?.section === "index")
)

export const getDiversityAndInclusionAverageScores = createSelector(
  getDiversityAndInclusionIndex,
  data => ({
    score: data?.minority?.score - data?.majority?.score,
    global: data?.minority?.global - data?.majority?.global,
    industry: data?.minority?.industry - data?.majority?.industry
  })
)

export const getDiversityAndInclusionBreakdowns = createSelector(
  getDiversityAndInclusion,
  sections => {
    const nonIndexSections =
      sections?.filter(section => section?.section !== "index") || []
    return {
      labels: nonIndexSections.map(
        nonIndexSection => nonIndexSection.questions[0].question
      ),
      datasets: ["score", "industry", "global"].map(d => ({
        label: d === "score" ? "Your Score" : d,
        data: nonIndexSections.map(
          nonIndexSection =>
            nonIndexSection?.minority[d] - nonIndexSection?.majority[d]
        )
      }))
    }
  }
)

export const getManagementConfidence = createSelector(
  getAllIndicators,
  indicators => indicators?.managementConfidence
)

// Can handle management confidence, flight risk and well being indicator data. D & I have to be handled separately
export const getIndicatorGraphQuestionBreakdown = createSelector(
  [getAllIndicators, (_, type) => type],
  (allIndicators, type) => {
    const selectedIndicator = allIndicators ? allIndicators[type] : null
    if (!selectedIndicator) {
      return null
    }

    return {
      labels: selectedIndicator.questions.map(q => q.question),
      datasets: ["score", "industry", "global"].map(d => ({
        label: d === "score" ? "Your Score" : d,
        data: selectedIndicator.questions.map(question => question[d])
      }))
    }
  }
)
