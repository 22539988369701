import React from "react"
import styled from "styled-components"
import { Text, Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"

const TestingText = styled(Text)`
  text-transform: uppercase;
`

const TestingSiteBanner = () => {
  const { isProd } = getConfig()
  return isProd ? null : (
    <Flex bg="dark.4" p={[2, 3]} width="100%" justifyContent="center">
      <TestingText
        width="auto"
        color="#848484"
        fontSize={[4, 6]}
        fontWeight={700}
        style={{ textAlign: "center" }}
      >
        This is the test site for WorkL For Business
      </TestingText>
    </Flex>
  )
}

export default TestingSiteBanner
