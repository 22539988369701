import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  gql
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

import { Auth } from "aws-amplify"

/**
 * Singleton class for handling graphQL queries using Apollo. A JWT will be
 * appended to the requests sent if a user is logged in.
 */
class Apollo {
  getCurrentUser = () => Auth.currentUserInfo()

  getSession = async () => {
    try {
      const user = await Auth.currentSession()
      return user.getIdToken().getJwtToken()
    } catch (error) {
      return null
    }
  }

  getLanguage = () =>
    window && window.localStorage && window.localStorage.getItem("i18nextLng")

  createClientWithToken = async (uri, entitlement) => {
    const sessionToken = await this.getSession()
    const cache = new InMemoryCache()

    const httpLink = createHttpLink({
      uri
    })

    const headerLink = setContext((_, { headers }) => {
      const language = this.getLanguage()
      const headerObj = { ...headers }

      if (sessionToken) headerObj.Authorization = `JWT ${sessionToken}`

      if (language) headerObj["Content-Language"] = language

      if (entitlement) headerObj.entitlement = entitlement

      return {
        headers: headerObj
      }
    })

    return new ApolloClient({
      ssrMode: true,
      link: headerLink.concat(httpLink),
      cache
    })
  }

  /**
   * Execute a graphQL query. This is mostly a wrapper for ApolloClient's query,
   * with support for a URI and automatic JWT attachment.
   * @param {string} uri The endpoint to make the query against.
   * @param {object} queryParams Other query parameters. These are passed into the
   * ApolloClient's query function, so anything used there *should* be valid here.
   */
  query = async ({ uri, entitlement, ...queryParams } = {}) => {
    const client = await this.createClientWithToken(uri, entitlement)
    return client.query(queryParams)
  }

  /**
   * Execute a graphQL mutation. This is mostly a wrapper for ApolloClient's mutate,
   * with support for a URI and automatic JWT attachment
   * @param {string} uri The endpoint to make the query against.
   * @param {object} queryParams Other query parameters. These are passed into the
   * ApolloClient's query function, so anything used there *should* be valid here.
   */
  mutate = async ({ uri, entitlement, ...mutationParams } = {}) => {
    const client = await this.createClientWithToken(uri, entitlement)
    return client.mutate(mutationParams)
  }
}

const apollo = new Apollo()

export { gql, apollo }

export default Apollo
