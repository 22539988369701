import { Box, Button, Flex, Text, TextField } from "@engaging-tech/components"
import { Link, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import AccountDialog from "../../components/AccountDialog"

const ActionContainer = styled(Flex)`
  width: 100%;

  flex-direction: row-reverse;
  justify-content: end;
  align-items: center;

  @media only screen and (max-width: 930px) {
    flex-direction: column;

    > button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
`

const SignInFormContent = ({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isAuthenticationLoading,
  siteLogo,
  footer: Footer,
  title,
  signUpRoute = "/account/sign-up",
  subtitle,
  cancelRoute
}) => {
  const router = useRouter()

  return (
    <AccountDialog title={title} renderLogo={siteLogo} subtitle={subtitle}>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            width={1 / 1}
            id="sign-in-email"
            name="email"
            type="email"
            borderRadius="4px 4px 0px 0px"
            label="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email}
          />
        </Box>
        <Box mb={2}>
          <TextField
            width={1 / 1}
            id="sign-in-password"
            name="password"
            type="password"
            borderRadius="4px 4px 0px 0px"
            label="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
          />
        </Box>
        <Box mb={5}>
          <Link to="/account/forgot-password">
            <Text fontSize={[4, 3, 2]} color="primary.0">
              Forgot password?
            </Text>
          </Link>
        </Box>
        {Footer && <Footer />}
        <ActionContainer>
          <Button
            variant="secondary.0"
            type="submit"
            disabled={!isValid || isAuthenticationLoading}
            ml={2}
            width="auto"
          >
            Continue
          </Button>
          <Button
            type="button"
            variant="text.noBg.dark.1"
            ml={2}
            onClick={() => window.open(cancelRoute, "_self")}
          >
            Cancel
          </Button>
          <Button
            variant="outline.primary.0"
            type="button"
            ml={3}
            onClick={() => window.open("/account/magic-link", "_self")}
          >
            Send Magic Link
          </Button>
          <Button
            variant="text.noBg.dark.1"
            onClick={() => router.navigate(signUpRoute)}
          >
            Sign Up
          </Button>
        </ActionContainer>
      </form>
    </AccountDialog>
  )
}

export default SignInFormContent
