import { Box, Button, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import TextField from "./TextField"

const StyledBox = styled(Box)`
  position: relative;
  & > button {
    position: absolute;
    left: calc(100% - ${({ buttonWidth }) => buttonWidth});
    top: 0;
    border-radius: 10px;
    border: ${({ error, theme }) =>
      error
        ? `1px solid ${theme.colors.error[0]}`
        : "1px solid rgba(99,152,195,1)"};
  }
  & > div > input {
    margin-bottom: 0;
  }
`

const StyledText = styled(Text)`
  text-transform: initial;
`

const TextFieldAndButton = ({
  disabled = false,
  name,
  value,
  error,
  onclick = () => null,
  textFieldProps,
  buttonProps = {
    width: "105px",
    ...buttonProps
  },
  buttonText,
  ...props
}) => {
  return (
    <StyledBox buttonWidth={buttonProps.width} {...props}>
      <TextField
        name={name}
        noLabel={!error}
        error={error}
        value={value}
        disabled={disabled}
        {...textFieldProps}
      />
      <Button
        height={error ? "75%" : "100%"}
        bg="secondary.0"
        onClick={e => {
          e.preventDefault()
          onclick()
        }}
        error={error}
        disabled={disabled}
        elevation={0}
        {...buttonProps}
      >
        <StyledText color="light.0" fontSize={3}>
          {buttonText}
        </StyledText>
      </Button>
    </StyledBox>
  )
}

export default TextFieldAndButton
