import { Button, Dialog, Flex, Icon, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"

const Success = ({ setSuccess, resetForm }) => {
  const router = useRouter()
  const paths = usePaths()

  const exit = () => {
    resetForm()
    router.navigate(paths.home.index)
  }
  const restart = () => {
    resetForm()
    setSuccess(false)
  }

  return (
    <Dialog bg="dark.2" fontWeight={700} title="Job Posted">
      <Flex>
        <Icon mr={2} name="check" />
        <Flex flexDirection="column">
          <Text fontWeight={500} mb={1}>
            Event
          </Text>
          <Text mb={3}>
            Your event is now posted on the global hub and is visible to
            everyone.
          </Text>
        </Flex>
      </Flex>

      <Flex flexDirection="row" justifyContent="space-between" mt={3}>
        <Button
          variant="text.primary.0"
          width="auto"
          elevation={0}
          onClick={exit}
        >
          Home
        </Button>
        <Button onClick={restart} width="auto" variant="primary.0" ml={3}>
          Post another event
        </Button>
      </Flex>
    </Dialog>
  )
}

export default Success
