import { Flex, Text } from "@engaging-tech/components"
import React, { useContext } from "react"
import styled from "styled-components"

import questionIconOptions from "../../lib/questionIconOptions"
import TemplateQuestionContext from "../TemplateQuestionContext"
import DropdownQuestionPreview from "./DropdownQuestionPreview"
import RadioQuestionPreview from "./RadioQuestionPreview"
import ScaleQuestionPreview from "./ScaleQuestionPreview"
import ShortTextQuestionPreview from "./ShortTextQuestionPreview"

const Image = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
`

const PreviewBody = () => {
  const { icon, title, type } = useContext(TemplateQuestionContext)
  const iconObj = questionIconOptions?.find(option => option?.id === icon)

  return (
    <Flex p="24px 34px 24px 24px" flexDirection="column" height="inherit">
      <Flex alignItems="center">
        <Image src={iconObj?.image} />
        <Text ml={4} fontSize={5}>
          {title}
        </Text>
      </Flex>
      <Flex height="166px">
        {type === "scale" && <ScaleQuestionPreview />}
        {type === "radio" && <RadioQuestionPreview />}
        {type === "short-text" && <ShortTextQuestionPreview />}
        {["other", "dropdown", "industry", "job-role", "country"].includes(
          type
        ) && <DropdownQuestionPreview />}
      </Flex>
    </Flex>
  )
}

export default PreviewBody
