import React, { useState } from "react"
import { useLocation } from "@engaging-tech/routing"
import { useHistory } from "react-router-dom"

import { Text } from "@engaging-tech/components"
import styled from "styled-components"
import DashboardLayout from "../../../layouts/DashboardLayout"
import SurveyInformationView from "../../../features/surveyManagement/surveyInformation/views/SurveyInformationView"
import { OrgGuard } from "../../../features/account"
import BottomStickyNav from "../../../features/surveyManagement/surveyInformation/components/BottomStickyNav"

const StyledLink = styled.a`
  text-decoration: underline;
`

const SurveyInformation = () => {
  const location = useLocation()
  const history = useHistory()

  let initialStep = 1
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const paramStep = urlParams.get("step")
    if (paramStep) {
      initialStep = parseInt(paramStep, 10)
    }
  }
  const [step, setStep] = useState(initialStep)

  const stepHandler = s => {
    history.push(`${location.pathname}?step=${s}`)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
    setStep(s)
  }
  return (
    <OrgGuard>
      <DashboardLayout bg="#EEF4F9">
        {step === 1 ? (
          <Text ml={2} color="secondary.0" fontWeight={700}>
            For The Sunday Times Best Places to Work, powered by WorkL, Awards
            please click <StyledLink href="/awards">HERE</StyledLink>
          </Text>
        ) : null}
        <SurveyInformationView step={step} />
      </DashboardLayout>
      <BottomStickyNav stepHandler={stepHandler} step={step} />
    </OrgGuard>
  )
}

export default SurveyInformation
