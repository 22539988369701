import React from "react"
import { Paragraph, Flex, H1 } from "@engaging-tech/components"

const ContentBlock = ({
  title,
  titleStyles,
  description,
  description2,
  descriptionStyles,
  descriptionStyles2,
  children,
  footerText,
  p = "60px 24px",
  ...props
}) => {
  return (
    <Flex p={p} {...props}>
      {title && <H1 {...titleStyles}>{title}</H1>}
      {description && (
        <Paragraph {...descriptionStyles}>{description}</Paragraph>
      )}
      {description2 && (
        <Paragraph {...descriptionStyles2}>{description2}</Paragraph>
      )}
      {children}
      {footerText && <Paragraph>{footerText}</Paragraph>}
    </Flex>
  )
}

export default ContentBlock
