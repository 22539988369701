import { connect } from "react-redux"

import ManagementConfidenceLogo from "../../reportLogos/ManagementConfidenceLogo"
import IndicatorComponent from "../components/Indicator"
import * as DataHighlightSelectors from "../store/datahighlights.selectors"

const mapState = state => {
  const averageScoreData = DataHighlightSelectors.getIndicatorAverageScores(state, "managementConfidence")
  return {
    averageScoreData,
    mainScore: averageScoreData.score,
    labelScore: averageScoreData.score,
    heading: "Confidence In Management",
    text: "confidence score in management",
    logo: ManagementConfidenceLogo,
    breakDown: "confidenceInManagement"
  }
}

const ManagementConfidenceIndicator = connect(mapState)(IndicatorComponent)

export default ManagementConfidenceIndicator
