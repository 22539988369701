import { gql } from "@apollo/client"

const UPLOAD_FILE = gql`
  mutation uploadFile($fileName: String!, $service: Service, $uniqueId: String) {
    uploadFile(fileName: $fileName, service: $service, uniqueId: $uniqueId) {
      baseUrl
      key
      message
      url
    }
  }
`

export default UPLOAD_FILE
