/* eslint-disable import/prefer-default-export */
import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { AccountActions } from "../../../account"
import { surveyInfoActions, surveyInfoSelectors } from "../../surveyInfo"
import {
  purchaseCredits,
  scheduleSurvey as scheduleSurveyService
} from "../services/surveyInfoReview.services"
import * as Actions from "./surveyInfoReview.actions"
import {
  purchaseCreditsSuccess,
  scheduleSurvey
} from "./surveyInfoReview.actions"
import { getCreditsToPurchase } from "./surveyInfoReview.selectors"

export function* createIntentSaga(action) {
  try {
    const credits = yield select(getCreditsToPurchase)
    const surveyId = yield select(surveyInfoSelectors.getSurveyId)
    const req = { credits }
    if (surveyId) req.surveyId = surveyId

    const res = yield call(purchaseCredits, req)

    yield put(Actions.createIntentSuccess(res.data.purchaseSurveyCredits))

    if (action.payload.bypassPayment) {
      yield put(purchaseCreditsSuccess())
      yield put(scheduleSurvey(surveyId))
    }
    if (action.payload.onSuccess) {
      action.payload.onSuccess()
    }
  } catch (error) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: action.payload.bypassPayment
          ? "There has been an error"
          : "Failed to purchase"
      })
    )
    yield put(Actions.createIntentFail())
  }
}

export function* scheduleSurveySaga(action) {
  try {
    yield call(scheduleSurveyService, action.payload)
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Your survey has been successfully scheduled!"
      })
    )
    yield put(Actions.scheduleSurveySuccess())
    yield put(surveyInfoActions.closeSurvey())
    yield put(AccountActions.load(2000))
  } catch (e) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Failed to schedule survey"
      })
    )
    yield put(Actions.scheduleSurveyFail())
  }
}

export function* purchaseCreditsSuccessSaga() {
  try {
    yield put(AccountActions.load(2000))
  } catch {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Failed to purchase."
      })
    )
    yield put(Actions.purchaseCreditsFailure())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.CREATE_INTENT, createIntentSaga)
  yield takeLatest(Actions.types.SCHEDULE_SURVEY, scheduleSurveySaga)
  yield takeLatest(
    Actions.types.PURCHASE_CREDITS_SUCCESS,
    purchaseCreditsSuccessSaga
  )
}
