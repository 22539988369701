import { connect } from "react-redux"
import SelectProduct from "../../views/SelectProduct"
import {
  getAwardsCurrency,
  getAwardsType,
  getParticipations
} from "../../store/awards.selectors"
import { getSelectedProduct } from "../../../../store/products/products.selectors"
import { setPage } from "../../store/awards.actions"

const mapState = state => ({
  selectedProduct: getSelectedProduct(state, {
    object_type: getAwardsType(state)
  }),
  participations: getParticipations(state),
  awardsType: getAwardsType(state),
  awardsCurrency: getAwardsCurrency(state)
})

const mapDispatch = dispatch => ({
  setPage: page => dispatch(setPage(page))
})

const SelectProductContainer = connect(mapState, mapDispatch)(SelectProduct)

export default SelectProductContainer
