import { Spinner } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"

import { HANDLE_INVOICE } from "../../../graphql/mutations"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import { handleInvoiceFail } from "../../../store/basket/basket.actions"
import ErrorState from "../../business-information/components/error-state"
import InvoiceBody from "../components/InvoiceBody"

const Invoice = () => {
  const paths = usePaths()
  const router = useRouter()

  const urlParams = new URLSearchParams(window.location.search)
  const from = urlParams.get("from")

  const handleBack = () => (from ? router.navigate(from) : router.navigate(paths.yourBasket.index))

  const { isLoading, errors, post, data } = useBusinessAppMutation(HANDLE_INVOICE, {
    failAction: handleInvoiceFail
  })

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />
  if (errors) return <ErrorState />
  if (data) window.location.href = data.handleInvoice

  return <InvoiceBody submitAction={post} onBack={handleBack} />
}

export default Invoice
