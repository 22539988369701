import React from "react"
import { Text, Flex } from "@engaging-tech/components"

const ErrorList = ({ errorKeys, pageErrors }) => (
  <Flex width={1 / 1} alignItems="center" flexDirection="column">
    <Text fontWeight={600} mb={2}>
      Please fix the following errors to continue
    </Text>
    {errorKeys?.map(key => (
      <Text color="red" key={key}>
        {`${key}: ${pageErrors[key]}`}
      </Text>
    ))}
  </Flex>
)

export default ErrorList
