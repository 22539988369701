import { Spinner } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React, { useState } from "react"

import { GET_ACTION_PLAN_STEP } from "../../../graphql/queries"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"
import ErrorState from "../../ui/components/ErrorState"
import PreviewStepModal from "../components/EditActionPlan/PreviewStepModal"
import AccordionWrapperContainer from "../containers/EditActionPlan/AccordionWrapperContainer"
import EditActionPlanHeaderContainer from "../containers/EditActionPlan/EditActionPlanHeaderContainer"

const EditActionPlanStep = ({ setActionPlanStep, actionPlanStep }) => {
  const { stepId } = useParams()
  const [openModal, setOpenModal] = useState(false)

  const { errors, isLoading } = useAppSyncQuery(GET_ACTION_PLAN_STEP, {
    variables: { id: stepId, preview: false },
    successAction: setActionPlanStep
  })

  const handleOpenPreviewModal = () => setOpenModal(!openModal)

  if (isLoading)
    return <Spinner color="secondary.0" justifyContent="center" width={1 / 1} />
  if (errors) return <ErrorState />

  return (
    <>
      <EditActionPlanHeaderContainer
        handleOpenPreviewModal={handleOpenPreviewModal}
      />
      <AccordionWrapperContainer />
      {openModal && (
        <PreviewStepModal
          actionPlanStep={actionPlanStep}
          setOpenModal={setOpenModal}
          handleOpenPreviewModal={handleOpenPreviewModal}
        />
      )}
    </>
  )
}

export default EditActionPlanStep
