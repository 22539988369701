import { connect } from "react-redux"
import SurveyIntroCard from "../../../selfServiceSurveys/components/Page2/SurveyIntroCard"
import {
  setDescription,
  setPageErrors,
  setName
} from "../store/template.actions"
import {
  getDescription,
  getName,
  getViewOnly
} from "../store/template.selectors"

const mapState = state => ({
  title: getName(state),
  introduction: getDescription(state),
  viewOnly: getViewOnly(state),
  type: "introduction",
  noIntroErrors: true
})

const mapDispatch = (dispatch, { id }) => ({
  setTitle: title => dispatch(setName(title)),
  setIntroduction: intro => dispatch(setDescription(intro)),
  handleErrors: (errors, type) => dispatch(setPageErrors(errors, type, id))
})

const TemplateIntroCard = connect(mapState, mapDispatch)(SurveyIntroCard)

export default TemplateIntroCard
