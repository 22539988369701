import { usePaths } from "@engaging-tech/routing"
import React from "react"
import JoinBIPComponent from "../components/JoinBIP"
import EBNBillingWrapper from "../containers/EBNBillingWrapper"

const JoinBIP = () => {
  const paths = usePaths()
  return (
    <EBNBillingWrapper cancelRoute={paths.home.index}>
      {props => <JoinBIPComponent {...props} />}
    </EBNBillingWrapper>
  )
}

export default JoinBIP
