export const types = {
  SET_TEMPLATE_ID: "[TEMPLATES] Set Template ID",
  SET_NAME: "[TEMPLATES] Set Name",
  SET_DESCRIPTION: "[TEMPLATES] Set Description",
  SET_PAGE_ERRORS: "[TEMPLATES] Set Page Errors",
  LOAD_TEMPLATE_REQ: "[TEMPLATES] Load Template Req",
  LOAD_TEMPLATE_SUCCESS: "[TEMPLATES] Load Template Success",
  LOAD_TEMPLATE_FAIL: "[TEMPLATES] Load Template Fail",
  OPEN_POP_UP_MENU: "[TEMPLATES] Open Pop Up Menu",
  MOVE_SECTION: "[TEMPLATES] Move Section",
  MOVE_QUESTION: "[TEMPLATES] Move Question",
  ADD_SECTION: "[TEMPLATES] Add Section",
  DELETE_QUESTION: "[TEMPLATES] Delete Question",
  DELETE_SECTION: "[TEMPLATES] Delete Section",
  ADD_CORE_QUESTION: "[TEMPLATES] Add Core Question",
  ADD_CUSTOM_QUESTION: "[TEMPLATES] Add Custom Question",
  LOAD_CORE_QUESTIONS_REQ: "[TEMPLATES] Load Core Questions Req",
  LOAD_CORE_QUESTIONS_SUCCESS: "[TEMPLATES] Load Core Questions Success",
  LOAD_CORE_QUESTIONS_FAIL: "[TEMPLATES] Load Core Questions Fail",
  SET_CUSTOM_QUESTION_TYPE: "[TEMPLATES] Set Custom Question Type",
  ADD_OPTION: "[TEMPLATES] Add Option",
  DELETE_OPTION: "[TEMPLATES] Delete Option",
  SET_FILTER_ON_REPORT: "[TEMPLATES] Set Filter On Report",
  SET_REQUIRED: "[TEMPLATES] Set Required",
  CHANGE_SCALE: "[TEMPLATES] Change Scale",
  SET_QUESTION_TITLE: "[TEMPLTES] Set Question Title",
  SET_QUESTION_LABEL: "[TEMPLATES] Set Question Label",
  SET_VALUE: "[TEMPLATES] Set Value",
  SET_FIELD: "[TEMPLATES] Set Field",
  SWITCH_QUESTION_TYPE: "[TEMPLATES] Switch Question Type",
  CHANGE_CORE_QUESTION: "[TEMPLATES] Change Core Question",
  SET_QUESTION_ICON: "[TEMPLATES] Set Question Icon",
  SET_SECTION_TITLE: "[TEMPLATES] Set Section Title",
  SET_SECTION_INTRO: "[TEMPLATES] Set Section Intro",
  SAVE_TEMPLATE_REQ: "[TEMPLATES] Save Template Req",
  SAVE_TEMPLATE_SUCCESS: "[TEMPLATES] Save Template Success",
  SAVE_TEMPLATE_FAIL: "[TEMPLATES] Save Template Fail",
  SET_BRANCHING_VIEW: "[TEMPLATES] Set Branching View",
  SET_BRANCHING_LOGIC: "[TEMPLATES] Set Branching Logic",
  SET_VIEW_ONLY: "[TEMPLATES] Set View Only",
  CLEAR_TEMPLATE_DATA: "[TEMPLATES] Clear Template Data",
  SET_BESPOKE_STEPS: "[TEMPLATES] Set Bespoke Steps",
  UPDATE_QUESTION_VALUE: "[TEMPLATES] Update Question Value",
  ADD_STEP_TO_QUESTION: "[TEMPLATES] Add Step To Question",
  DELETE_STEP_FROM_QUESTION: "[TEMPLATES] Delete Step From Question",
  CLEAR_API_ERRORS: "[TEMPLATES] Clear API Errors"
}

export const clearApiErrors = () => ({
  type: types.CLEAR_API_ERRORS
})

export const addStepToQuestion = (step, questionId) => ({
  type: types.ADD_STEP_TO_QUESTION,
  payload: { step, questionId }
})

export const deleteStepFromQuestion = (step, questionId) => ({
  type: types.DELETE_STEP_FROM_QUESTION,
  payload: { step, questionId }
})

export const updateQuestionValue = payload => ({
  type: types.UPDATE_QUESTION_VALUE,
  payload
})

export const setBespokeSteps = payload => ({
  type: types.SET_BESPOKE_STEPS,
  payload
})

export const clearTemplateData = () => ({
  type: types.CLEAR_TEMPLATE_DATA
})

export const setViewOnly = payload => ({
  type: types.SET_VIEW_ONLY,
  payload
})

export const setBranchingLogic = (
  choice,
  questionId,
  target,
  targetAnswers = []
) => ({
  type: types.SET_BRANCHING_LOGIC,
  payload: { target, choice, questionId, targetAnswers }
})

export const setBranchingView = payload => ({
  type: types.SET_BRANCHING_VIEW,
  payload
})

export const saveTemplateSuccess = payload => ({
  type: types.SAVE_TEMPLATE_SUCCESS,
  payload
})

export const saveTemplateFail = payload => ({
  type: types.SAVE_TEMPLATE_FAIL,
  payload
})

export const saveTemplateReq = payload => ({
  type: types.SAVE_TEMPLATE_REQ,
  payload
})

export const setSectionIntro = (intro, sectionId) => ({
  type: types.SET_SECTION_INTRO,
  payload: { intro, sectionId }
})

export const setSectionTitle = (title, sectionId) => ({
  type: types.SET_SECTION_TITLE,
  payload: { title, sectionId }
})

export const setQuestionIcon = (icon, questionId) => ({
  type: types.SET_QUESTION_ICON,
  payload: { icon, questionId }
})

export const changeCoreQuestion = (selectedId, currentId) => ({
  type: types.CHANGE_CORE_QUESTION,
  payload: { selectedId, currentId }
})

export const switchQuestionType = (questionId, availableCoreQuestionIds) => ({
  type: types.SWITCH_QUESTION_TYPE,
  payload: { questionId, availableCoreQuestionIds }
})

export const setField = (field, fieldIdx, questionId) => ({
  type: types.SET_FIELD,
  payload: { field, fieldIdx, questionId }
})

export const setValue = (value, valueIdx, questionId) => ({
  type: types.SET_VALUE,
  payload: { value, valueIdx, questionId }
})

export const setQuestionLabel = (label, questionId) => ({
  type: types.SET_QUESTION_LABEL,
  payload: { label, questionId }
})

export const setQuestionTitle = (title, questionId) => ({
  type: types.SET_QUESTION_TITLE,
  payload: { title, questionId }
})

export const changeScale = (value, type, questionId) => ({
  type: types.CHANGE_SCALE,
  payload: { value, type, questionId }
})

export const setRequired = (isRequired, questionId) => ({
  type: types.SET_REQUIRED,
  payload: { isRequired, questionId }
})

export const setFilterOnReport = (filterStatus, questionId) => ({
  type: types.SET_FILTER_ON_REPORT,
  payload: { filterStatus, questionId }
})

export const deleteOption = (valueIdx, questionId) => ({
  type: types.DELETE_OPTION,
  payload: { valueIdx, questionId }
})

export const addOption = id => ({
  type: types.ADD_OPTION,
  payload: { id }
})

export const setCustomQuestionType = (type, id) => ({
  type: types.SET_CUSTOM_QUESTION_TYPE,
  payload: { type, id }
})

export const loadCoreQuestionsFail = payload => ({
  type: types.LOAD_CORE_QUESTIONS_FAIL,
  payload
})

export const loadCoreQuestionsSuccess = payload => ({
  type: types.LOAD_CORE_QUESTIONS_SUCCESS,
  payload: payload.getCoreQuestions
})

export const loadCoreQuestionsReq = payload => ({
  type: types.LOAD_CORE_QUESTIONS_REQ,
  payload
})

export const addCustomQuestion = payload => ({
  type: types.ADD_CUSTOM_QUESTION,
  payload
})

export const addCoreQuestion = payload => ({
  type: types.ADD_CORE_QUESTION,
  payload
})

export const deleteSection = payload => ({
  type: types.DELETE_SECTION,
  payload
})

export const deleteQuestion = payload => ({
  type: types.DELETE_QUESTION,
  payload
})

export const addSection = payload => ({
  type: types.ADD_SECTION,
  payload
})

export const moveSection = (currentSection, order) => ({
  type: types.MOVE_SECTION,
  payload: { currentSection, order }
})

export const moveQuestion = (currentQuestion, order) => ({
  type: types.MOVE_QUESTION,
  payload: { currentQuestion, order }
})

export const setPopUpMenu = payload => ({
  type: types.OPEN_POP_UP_MENU,
  payload
})

export const loadTemplateFail = payload => ({
  type: types.LOAD_TEMPLATE_FAIL,
  payload
})

export const loadTemplateSuccess = payload => ({
  type: types.LOAD_TEMPLATE_SUCCESS,
  payload: payload?.getSurveyTemplate
})

export const loadTemplateReq = payload => ({
  type: types.LOAD_TEMPLATE_REQ,
  payload
})

export const setTemplateId = payload => ({
  type: types.SET_TEMPLATE_ID,
  payload
})

export const setName = payload => ({
  type: types.SET_NAME,
  payload
})

export const setDescription = payload => ({
  type: types.SET_DESCRIPTION,
  payload
})

export const setPageErrors = (errors, type, id) => ({
  type: types.SET_PAGE_ERRORS,
  payload: { errors, type, id }
})
