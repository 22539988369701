import { connect } from "react-redux"

import { getReportResponseId } from "../../../Reports/store/report.selectors"
import SliceAndDiceUpgradeModalContainer from "./SliceAndDiceUpgradeModalContainer"
import { getSDLowestPrice, getSDUpgradePrice } from "./store/selectors"

const mapState = state => ({
  sliceAndDiceProductLowestPrice: getSDLowestPrice(state),
  sliceAndDiceProductUpgradePrice: getSDUpgradePrice(state),
  surveyId: getReportResponseId(state)
})

const SliceAndDiceUpgradeModal = connect(
  mapState,
  null
)(SliceAndDiceUpgradeModalContainer)

export default SliceAndDiceUpgradeModal
