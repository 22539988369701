import { Flex, CircularProgress } from "@engaging-tech/components"
import React from "react"

import ReportCard from "../../components/ReportCard"
import { ScoreLabel, getIndicatorLabelData } from "../../components/ScoreLabel"
import IndustryAndGlobalScoreBox from "./IndustryAndGlobalScoreBox"

const IndicatorOnePager = ({
  heading,
  averageScoreData,
  mainScore,
  labelScore,
  isPdfDownloading
}) => {
  const { score, industry, global } = averageScoreData

  if (typeof score !== "number") return <></>

  return (
    <ReportCard
      isPdfDownloading={isPdfDownloading}
      heading={heading}
      width={["100%"]}
      exportingPDF={false}
      innerProps={{ justifyContent: "space-between" }}
    >
      <Flex
        flexDirection={["row"]}
        justifyContent="space-around"
        alignItems="center"
      >
        <CircularProgress
          percentage={Math.round(mainScore)}
          disableAllAnimation
          strokeColor={getIndicatorLabelData(labelScore).color}
          maxWidth={150}
        />
        <Flex flexDirection="column" mb={4} mt={3}>
          <ScoreLabel
            showIcon
            indicator
            score={labelScore}
            fontSize={5}
            fontWeight={700}
          />
        </Flex>
      </Flex>
      <IndustryAndGlobalScoreBox
        globalScore={global ? Math.round(global) : null}
        industryScore={industry ? Math.round(industry) : null}
        mb={0}
        mt={[3]}
      />
    </ReportCard>
  )
}

export default IndicatorOnePager
