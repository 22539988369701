const generic = [
  {
    section: "reward_recognition",
    heading: "Action Plan to Improve Scores in Reward and Recognition",
    summary: {
      heading: "What Is Reward & Recognition?",
      infos: [
        {
          text: "Being fairly paid is a huge concern for everyone, and if you are not paying a fair salary no amount of recognition for a job well done will be enough to make your employees forget they are not being paid enough. Your pay scale has to meet expectations.",
          type: "paragraph"
        },
        {
          text: "Everyone in an organisation should enjoy the rewards of success. Recognising an individual’s performance with a thank you, a note of congratulations or small gift of acknowledgement is also a powerful motivator. It says good work has been noticed throughout the year, not just at an annual appraisal. It builds bonds and trust and increases engagement. If your score for Reward and Recognition indicates scope for improvement, then you should look at the scores for each question to pinpoint where issues may lay.",
          type: "paragraph"
        },
        {
          text: "Three questions combine to make up your overall score for Reward and Recognition. They are:",
          type: "paragraph"
        }
      ]
    },
    dialHeading: "Reward & Recognition Score",
    planInfo: [
      {
        subtitle: "Reward - Overview:",
        explanation: [
          "It is not unusual to find that people say they are not paid enough. Why wouldn't they! Pay however can only minimises discontent rather than motivating. Let me explain. If somebody feels they are 5% underpaid it niggles away at them, but if they feel they are paid 5% more than they are worth they don't work any harder.",
          "The challenge therefore is to pay fairly for the job somebody is doing, be able to reassure them that is the case and help them understand what they can do to improve their pay. The same applies if the whole team feel their pay rates too low. If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Understand the pay range for the job/jobs in question."
          },
          {
            text: "2. Discuss with the team and individuals their concerns on pay."
          },
          {
            text: "3. Consider where an individual sits within that range and be prepared to explain to them why."
          },
          {
            text: "4. On the basis of what you have concluded for number 3, set out a plan for the individual to improve their contribution and as a result their pay."
          },
          {
            text: "5. Consider what opportunities exist to pay an additional bonus for work and projects completed to a high standard."
          },
          {
            text: "6. Can you give an individual more responsibility in order to increase their earning power?"
          },
          { text: "7. Can you coach this person to achieve a promotion?" },
          {
            text: "8. If the issue is team wide get the information you need internally or externally to reassure yourself pay rates sit fairly. (Good to have a link here on external benchmarking.)"
          }
        ]
      },
      {
        subtitle: "Work Hours - Overview:",
        explanation: [
          "If somebody scores poorly on the question 'working hours' there could be a number of reasons which are pertinent to a group or individuals. You should therefore, through asking open questions, try and discover the following:",
          "1. Are people unhappy with their working hours because they are working too many?",
          "2. Are people unhappy with their working hours because they are working too few?",
          "3. Would they like more flexibility in their working hours?",

          "4. Are people unable to plan because they don't know what their working hours will be?",

          "5. Are concerns on working hours linked to pay? For instance too many hours for the pay received or not enough hours for the pay desired."
        ],
        bulletPoints: [
          {
            text: "1. Discuss with individuals and as a group the first four points above."
          },
          {
            text: "2. Consider how you might contain workload within the working week (i.e. do people need to send and reply to emails after say 7pm at night and before 8am in the morning and at weekends?)"
          },
          { text: "3. How might you increase flexible working?" },
          {
            text: "4. How might you be able to schedule better to provide individuals with a better plan of when they will and won't be working?"
          },
          {
            text: "5. Discuss with individuals whether concerns on hours are really about pay?"
          }
        ]
      },
      {
        subtitle: "Recognition - Overview:",
        explanation: [
          "Recognition is one of the most powerful motivators and causes of high levels of workplace engagement. Yet on average people are only thanked for what they do once every four and a half months, whereas they are criticised twice a week. Rewarding the behaviour you want to see and encourage will create a positive working environment. You can put in place a plan to do the following things to do improve workplace recognition if you scored poorly on this question."
        ],
        bulletPoints: [
          {
            text: "1. Try and speak to each member of your immediate team every day or at least once a week."
          },
          {
            text: "2. Recognise and praise somebody when they do something well."
          },
          {
            text: "3. If somebody completes work you think they can improve upon use it as an opportunity to coach (i.e how well did you feel that went, could you have done that differently, or what more support might you need if you were to do that task again etc.) so that the individual feels that they are learning rather than being criticised."
          },
          {
            text: "4. Send somebody a note/email/card when they do something well."
          },
          {
            text: "5. Give somebody a small gift (chocolate/flowers/wine) when they do something that has impressed you."
          },
          {
            text: "6. Recognise individual successes publicly and discuss their failures privately."
          },
          {
            text: "7. For outstanding work, recognise an individual with a bonus - either cash or tickets to go and see a show, football match, or go out for dinner with their partner."
          },
          {
            text: "8. Take every opportunity to recognise and celebrate your team or a member of your team's success."
          },
          {
            text: "9. Ask your boss to recognise individuals in your team who have done well."
          }
        ]
      }
    ],
    resources: [
      {
        type: "Article",
        title: "10 Powerful Ways to Improve Your Employee Recognition",
        url: "https://app.workl.co/business-library/browse/articles/5d76a722c289ff00166b4145"
      },
      {
        type: "Lecture",
        title: "The Puzzle of Motivation",
        url: "https://app.workl.co/business-library/browse/lectures/5d7feaf0c289ff00166b4300"
      },
      {
        type: "Research",
        title: "Show Me the Money: the Behavioural Science of Reward",
        url: "https://app.workl.co/business-library/browse/research/5d6e70a8c289ff00166b3fc8"
      },
      {
        type: "Book",
        title: "Six Steps to Engaged Employees",
        url: "https://app.workl.co/business-library/browse/books/82913"
      }
    ]
  },
  {
    section: "information_sharing",
    heading: "Action Plan to Improve Scores in Information Sharing",
    summary: {
      heading: "What is Information Sharing?",
      infos: [
        {
          text: "Everybody in your organisation needs to have a realistic and well-sourced view of where they work. Informed staff perform better and we would encourage you to ensure you have managed processes to achieve this. However, information sharing is often seen as pushing data outwards and downwards which often causes the “information disconnect”, whereby the management team believe they have communicated but the information is either being ignored or misinterpreted",
          type: "paragraph"
        },
        {
          text: "Like all communication, information sharing is a two-way process. Not sharing information makes employees feel an unimportant part of the business. Engagement and commitment can be eroded by this.",
          type: "paragraph"
        },
        {
          text: "The closed-door approach doesn’t just have a negative impact on engagement, it can directly impact decision-making and therefore profitability and success. How free is the information in your organisation? How do you compare to others around the world? If you would like to start improving your organisation's information score, the first step is to listen and then to respond. The organisations with the best scores in this area have developed mechanisms for systematic listening and response.",
          type: "paragraph"
        },
        {
          text: "The questions in this section are designed to separate out those points, they are:",
          type: "paragraph"
        }
      ]
    },
    dialHeading: "Information Sharing Score",
    planInfo: [
      {
        subtitle: "Enough Information - Overview:",
        explanation: [
          "This question goes to the heart of whether people feel they have been properly trained to do their job well or have enough information on an ongoing basis to do what is required.",
          "If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Review whether employees been trained effectively to complete their tasks."
          },
          {
            text: "2. Ensure information is readily at hand for them to complete their job efficiently."
          },
          {
            text: "3. Ask if new information relevant to their job is effectively communicated. If not, consider how."
          },
          {
            text: "4. Do you have regular team meetings and with individuals to share updates affecting their jobs?"
          },
          {
            text: "5. Do you have an effective system to update individuals to do their job effectively?"
          }
        ]
      },
      {
        subtitle: "Openly Shared Information - Overview:",
        explanation: [
          " This question is about whether an individual understands how the organisation is performing and their role within in and/or whether you as a manager are being transparent with information. If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Do you have routine meetings to share how the organisation is performing, its plans and your departments role within it?"
          },
          {
            text: "2. Does the organisation give routine updates through meetings or otherwise?"
          },
          {
            text: "3. Do you have a system where employees can communicate and feedback?"
          },
          {
            text: "4. Do you openly, transparently and positively answer questions about the wider organisations approach?"
          },
          {
            text: "5. Do you test for levels of understanding within your team?"
          },
          {
            text: "6. Does the organisation have a newsletter or communication portal to keep employees up to date? Do you promote it to your team?"
          }
        ]
      },
      {
        subtitle: "Views Heard - Overview:",
        explanation: [
          "If people do not feel their views are listened to then it is unlikely they will feel empowered or engaged to make a full contribution to the organisation.",
          "If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Ask yourself what you are doing to get feedback from individuals and the team?"
          },
          {
            text: "2. Are you listening or are you just continually giving instructions?"
          },
          {
            text: "3. You should try and ask for five bits of information before you give one piece of advice or direction."
          },
          {
            text: "4. Ask for good suggestions. (Do you have a good suggestions/ideas scheme?)"
          },
          {
            text: "5. Encourage the team and individuals to express their views openly. Everyone has a different viewpoint because everyone has different experiences, education and upbringing. None are right or wrong they are just what people think based on those factors. All contributions should be welcomed to bring diversity of view and collective agreement and understanding."
          }
        ]
      }
    ],
    resources: [
      {
        type: "Book",
        title: "Difficult Conversations",
        url: "https://app.workl.co/business-library/browse/books/11785"
      },
      {
        type: "Research",
        title: "Becoming a Knowledge-sharing Organisation",
        url: "https://app.workl.co/business-library/browse/research/5d701dcec289ff00166b3fee"
      },
      {
        type: "Book",
        title: "Business Communication",
        url: "https://app.workl.co/business-library/browse/books/29100"
      },
      {
        type: "Article",
        title: "5 Tips For Improving Leadership Communication",
        url: "https://app.workl.co/business-library/browse/articles/5d702209c289ff00166b3ff6"
      },
      {
        type: "Article",
        title: "Why Employees Need the Right Equipment",
        url: "https://app.workl.co/business-library/browse/articles/5d702340c289ff00166b4004"
      }
    ]
  },
  {
    section: "empowerment",
    heading: "Action Plan to Improve Scores in Empowerment",
    summary: {
      heading: "What is Empowerment?",
      infos: [
        {
          text: "Nobody is perfect but a team can be. The aim of any business must surely be to make their employees feel empowered and this means making them a key part of the decision-making process, listening to their ideas and integrating their suggestions to build and refine into your strategy. Our experiences inevitably bring us all to different solutions and ways of achieving them, but only by listening to all views can the best outcomes be reached.",
          type: "paragraph"
        },
        {
          text: "A low empowerment score can be caused through resources, culture or both. To crystallise the causes explore the following areas:",
          type: "paragraph"
        },
        {
          points: [
            "Are staff adequately trained (formal and coaching)",
            "Do they have the equipment to do the job well",
            "Do they understand their delegated authorities",
            "How would the staff describe the culture; positive challenge / recognition and encouragement / blame culture / factions etc."
          ],
          type: "bullets"
        },
        {
          text: "Three questions make up this step and they are amongst the most important for making people feel that they are engaged and making a real contribution to the organisation:",
          type: "paragraph"
        }
      ]
    },
    dialHeading: "Empowerment Score",
    planInfo: [
      {
        subtitle: "Empowerment - Overview:",
        explanation: [
          "When you own something you care more. The secret of empowerment is to help employees own things, like decisions, projects, action plans so they feel more accountability and care more about the outcomes. It is also about whether an individual has the freedom to do what they need to do to be effective in their job, making the organisation more effective. Empowerment requires delegation and delegation requires training and coaching. But when done effectively the whole organisation moves more quickly. If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Is your team clear on the freedoms that they are given and the flexibility they can exercise by you and the organisation?"
          },
          {
            text: "2. Have you and the organisation set the right parameters for individuals to make decisions?"
          },
          {
            text: "3. Have the team been given the right training for you to delegate responsibility to them?"
          },
          {
            text: "4. When things go wrong, and they will, are you ready to coach constructively to improve future performance?"
          }
        ]
      },
      {
        subtitle: "Resources - Overview:",
        explanation: [
          "In any business resources are tightly managed, too much resource means that you will not make as much profit as you could, or be as efficient as you might be. Too little resource and you place considerable strain on the individuals within your team. It's a fine balance. If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Ask individuals and the team what more they need to do their job well."
          },
          {
            text: "2. This is likely to breakdown into a few key areas: ",
            children: ["More people", "More hours", "Better equipment", "Better IT"]
          },
          {
            text: "3. Some of these you will be able to control, others you won't. It is important that you listen to what the team says, make a list or note and pass that to whoever is responsible for making those decisions if you are not."
          },
          { text: "4. Keep the team apprised of the progress you are making." },
          {
            text: "5. Consider with the team how you might work more efficiently to relieve the pressure on hours and people."
          },
          {
            text: "6. Consider with the team how you might be able to invest more in resources through generating more sales and/or efficiency."
          },
          {
            text: "7. Do your team understand the economics of the business and your ability, or otherwise, to invest in resources?"
          }
        ]
      },
      {
        subtitle: "Trust - Overview:",
        explanation: [
          "This question is about whether the team or individuals feel that you trust them to operate within parameters set by the organisation. If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Have you clearly set out for your team what you are delegating to them?"
          },
          {
            text: "2. Consider whether you are over controlling and asking to be asked about decisions which you have said are delegated."
          },
          {
            text: "3. Have you trained your team well enough to feel confident that they can make the right decision within the parameters set?"
          },
          {
            text: "4. Are you coaching, rather than reprimanding your team and individuals, when things you have delegated go wrong?"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Article",
        title: "Leaders Can Cultivate True Employee Empowerment",
        url: "https://app.workl.co/business-library/browse/articles/5d7111b4c289ff00166b4035"
      },
      {
        type: "Article",
        title: "When Empowering Employees Works, and When It Doesn't",
        url: "https://app.workl.co/business-library/browse/articles/5d72b168c289ff00166b4059"
      },
      {
        type: "Article",
        title: "Delegation and Empowerment",
        url: "https://app.workl.co/business-library/browse/articles/5d74a4ebc289ff00166b4076"
      },
      {
        type: "Article",
        title: "Aaron Dignan: Being a Leader Means Giving Up Control",
        url: "https://app.workl.co/business-library/browse/articles/5d7a695ac289ff00166b426b"
      },
      {
        type: "Research",
        title: "Employee Empowerment – An Empirical Study",
        url: "https://app.workl.co/business-library/browse/research/5d72baaec289ff00166b4072"
      }
    ]
  },
  {
    section: "job_satisfaction",
    heading: "Action Plan to Improve Scores in Job Satisfaction",
    summary: {
      heading: "What is Job Satisfaction?",
      infos: [
        {
          text: "Job satisfaction is personal and so is the development of an organisations.",
          type: "paragraph"
        },
        {
          text: "We have nothing of greater value than our people. High levels of employee engagement is the key to unlock organisational success. Happy employees equal a solid, successful and long-lasting business. But what makes people tick on a personal level? In other words, what makes workers happy and satisfied at work? More importantly, what can we, as business leaders, do about it?",
          type: "paragraph"
        },
        {
          text: "There are five questions under this heading: one about whether you work in a well run organisation, the second about career development, the third about the relationship an individual has with their manager, the fourth about whether you enjoy your job and the last about whether you feel respected. They are:",
          type: "paragraph"
        }
      ]
    },
    dialHeading: "Job Satisfaction Score",
    planInfo: [
      {
        subtitle: "Career Development - Overview",
        explanation: [
          "For many people it is important to their working lives that they feel they are being developed and as a consequence can increase their earning potential. Even mature employees want to keep learning and it's vital for new employees if the organisation is to achieve its potential. Employees want to feel that their boss is developing them rather than just helping make their boss look good."
        ],
        bulletPoints: [
          {
            text: "1. Are you watching closely your teams performance to see where it might be improved?"
          },
          {
            text: "2. Are you then taking the time to coach members of your team to improve their performance and learn more on the job?"
          },
          {
            text: "3. Can you recommend individuals search the EW business library to find more resources and information to help them develop?"
          },
          {
            text: "4. Can you recommend individuals search EW for a mentor or coach?"
          },
          {
            text: "5. Do you have a regular review of performance with an individual to discuss their training and development?",
            text: "5. Do you have a regular review of performance with an individual to discuss their training and development?"
          }
        ]
      },
      {
        subtitle: "Manager Relationship - Overview",
        explanation: [
          "The most critical relationship in the workplace is between an individual and their boss. 83% of people leave their jobs because of the poor relationship they have with their line manager. Even if they work for a great company. There can be many reasons for this, but if you have scored poorly in this area you may like to consider the following:"
        ],
        bulletPoints: [
          {
            text: "1. Take an active interest in each of your team members - finding out about their personal life and their hopes and ambitions for work and their wellbeing."
          },
          {
            text: "2. Recognise that we are all different as we have different skills, experiences and backgrounds."
          },
          {
            text: "3. Positively coach your team to improve their performance."
          },
          {
            text: "4. Recognise and respect their contribution both individually and publicly. Praise works; criticism doesn't."
          },
          {
            text: "5. Be positive. Nobody wants to work for a negative draining boss."
          },
          {
            text: "6. Give individuals the information they need to do their job well and put that into context around how the whole organisation is performing."
          },
          {
            text: "7. Give individuals as much responsibility as they are capable of and reward them when they deliver."
          },
          {
            text: "8. Create an environment where you team feel that their jobs have a broader purpose within the organisation and for society. (E.g. what are they doing that is good for the communities they live in)"
          },
          {
            text: "9. If an individual does not fit the role or organisation work constructively to help them move on recognising they have abilities."
          }
        ]
      },
      {
        subtitle: "Treated with Respect - Overview",
        explanation: [
          "It is hard to be satisfied at work if you do not feel respected by you manager and or colleagues. If an individual or team has scored poorly on this question you can consider:"
        ],
        bulletPoints: [
          {
            text: "1. Find out why the team or an individual feels a lack of respect and check the previous responses to the survey questions. Perhaps they feel:",
            text: "1. Find out why the team or an individual feels a lack of respect and check the previous responses to the survey questions. Perhaps they feel:",
            children: [
              "They aren't paid fairly",
              "They aren't recognised when they do something well?",
              "They don't feel information is shared with them?",
              "Their views aren't heard?",
              "They don't feel trusted to make decisions?",
              "Their wellbeing is cared for?",
              "What they do is without worth?",
              "That they don't have a good relationship with you?",
              "They are being taken for granted and not developed."
            ]
          },
          {
            text: "2. Build your action plans around whichever of these areas score poorly.",
            text: "2. Build your action plans around whichever of these areas score poorly."
          }
        ]
      },
      {
        subtitle: "Enjoy your Job - Overview",
        explanation: [
          "It is possible to work for a great organisation, a great boss, have wonderful colleagues but still feel that you are in the wrong job. It is hard to see, if that is the case, how someone can feel happy at work over the long term. If an individual or team score poorly on this question you should consider:"
        ],
        bulletPoints: [
          {
            text: "1. Find out why the team or an individual is not enjoying their job and check the previous responses to the survey questions. Perhaps they feel:",
            text: "1. Find out why the team or an individual is not enjoying their job and check the previous responses to the survey questions. Perhaps they feel:",
            children: [
              "They aren't paid fairly",
              "They aren't recognised when they do something well",
              "They don't feel information is shared with them",
              "Their views aren't heard",
              "They don't feel trusted to make decisions",
              "Their wellbeing is cared for",
              "What they do is without worth",
              "That they don't have a good relationship with you",
              "They are being taken for granted and not developed"
            ]
          },
          {
            text: "2. If you eliminate these issues, and it is the job itself the employee doesn't enjoy, discuss what they would enjoy.",
            text: "2. If you eliminate these issues, and it is the job itself the employee doesn't enjoy, discuss what they would enjoy."
          },
          {
            text: "3. Is that role available in your department or organisation?",
            text: "3. Is that role available in your department or organisation?"
          },
          {
            text: "4. What training or support is required to achieve their preferred role?",
            text: "4. What training or support is required to achieve their preferred role?"
          },
          {
            text: "5. If you cannot satisfy the individual how can you positively support them moving elsewhere. There is a career path and personality profile test on WorkL to help individuals discover where their skills and interests best fit."
          },
          {
            text: "6. Happy at work. The very last of the numbered scoring questions is 'Do you feel happy at work?'. It's there to help you hone down what is really important to your team in terms of their workplace engagement. You see they might score themselves unhappy at work but happy to most of the questions. The one or two low scoring answers will be having a disproportionate effect on their mood. Similarly if the score to this question is high those areas scoring poorly are not materially. But that's not to say they shouldn't be worked on to improve future survey results by improving your teams engagement and engagement."
          },
          {
            text: "7. Written responses. You should also look at the answers to the question “what three things would you change to be happier at work”. In the table on these responses you'll see a correlation between how often an answer was given and how it relates to the overall engagement scores by group. This will help you identify what improvements really matter. You can build those requests into your action plan above."
          }
        ]
      }
    ],
    resources: [
      {
        type: "Article",
        title: "Why Job Satisfaction is an Important Phenomenon of the Vicious Circle?",
        url: "https://app.workl.co/business-library/browse/articles/5d7dee0fc289ff00166b42ed"
      },
      {
        type: "Research",
        title: "Impact of Working Environment on Job Satisfaction",
        url: "https://app.workl.co/business-library/browse/research/5d777b48c289ff00166b41a8"
      },
      {
        type: "Book",
        title: "Job Satisfaction",
        url: "https://app.workl.co/business-library/browse/books/107764"
      },
      {
        type: "Article",
        title: "4 Ways to Successfully Develop Employees",
        url: "https://app.workl.co/business-library/browse/articles/5d890bfcc289ff00166b444d"
      },
      {
        type: "Internal resource",
        title: "My Learning site including the ways of working toolkit",
        url: "https://sites.google.com/morrisonsplc.co.uk/mylearningdevelopment/home          "
      }
    ]
  },
  {
    section: "instilling_pride",
    heading: "Action plan to improve scores in Instilling Pride",
    summary: {
      heading: "What Is Instilling Pride?",
      infos: [
        {
          text: "Pride from the organisation, colleagues, its products/services and its interactions with the broader community. Every organisation, marketplace and community is unique. Understanding pride scores will involve examining all of these areas.",
          type: "paragraph"
        },
        ,
        {
          text: "Have you ever heard any of your employees respond to ‘where do you work'? Employees who love what they do and feel proud of where they work will speak openly and positively about it to colleagues, potential employees, customers and people in their community. Instilling such pride is not just about stirring speeches, sharing growth figures, or saying a few well-placed thank yous. It centres on having a purpose and helping everyone see that what they do each day is worthwhile.",
          type: "paragraph"
        },
        ,
        {
          text: "A big part of that is to do with how your business interacts with the wider world. People want to work for an organisation that cares about how it impacts on society. How proud are your employees to work in your organisation?",
          type: "paragraph"
        },
        {
          text: "These three questions together identify whether somebody feels pride and purpose in what they do but each question is designed to help understand where there might be scope for improvement. Academic research suggests that where people feel a sense of purpose they contribute more to the organization’s success and are happier.",
          type: "paragraph"
        },
        {
          text: "Your overall score for Instilling Pride is comprised of the following questions:",
          type: "paragraph"
        }
      ]
    },
    dialHeading: "Instilling Pride Score",
    planInfo: [
      {
        subtitle: "Pride in the Organisation - Overview",
        explanation: [
          "It's great if you feel proud when you tell someone where you work. People working for charities usually score highly on this dimension. If you or your team don't feel that and you score poorly on this question you should consider the following:"
        ],
        bulletPoints: [
          {
            text: "1. Have you set out what the organisation does that helps society: employment, taxes paid, charitable activity, social purpose? (Something more than just making money for shareholders)"
          },
          {
            text: "2. If the organisation does not have a clearly articulated social purpose what might you be able to do to create that for employees? For example if you make mattresses you're helping people sleep better so that they're happier. If you grow apples you're preserving and protecting the countryside and helping people eat healthily."
          },
          {
            text: "3. You can think about supporting a local charity by perhaps donating time or running sponsored events."
          }
        ]
      },
      {
        subtitle: "Instilling Pride: Friends and Family - Overview",
        explanation: [
          "One of the surest ways to test if people are happy where they work is to ask if they would recommend where they work to their friends and family. If they are not you need to start working out if that can be fixed or if the employee is in the wrong organisation or job. You should view the Organisation satisfaction matrix. You might see that your team, or members of it, are anarchists and unlikely to talk positively about where they work. This is bad for the individual and organisation."
        ],
        bulletPoints: [
          {
            text: "1. Ask the team and individuals why they have scored you poorly on this point."
          },
          {
            text: "2. Do you feel you can address the points raised?"
          },
          {
            text: "3. If you can't and your score in the area suggest people are unhappy consider how you move people to other roles or organisations where they will be happier."
          }
        ]
      },
      {
        subtitle: "Instilling Pride: Something Worthwhile - Overview",
        explanation: [
          "Everyone wants to feel that they are making a contribution to an organisation's success and society. There's a wonderful story about President J F Kennedy visiting NASA to see the space rockets. In the line to meet him was a cleaner. When President Kennedy asked him what his job was, he said “putting a man on the moon”! Teachers, nurses all tend to score highly on this question but so can others if they understand the contribution they are making."
        ],
        bulletPoints: [
          {
            text: "1. Have you conveyed the importance of the individuals' role to the success of the organisation?"
          },
          {
            text: "2. Does the employee understand the greater contribution the organisation is making to society?"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Article",
        title: "'It's Not In My Job Description': 5 Steps To Build Company Pride And A Sense Of Ownership",
        url: "https://app.workl.co/business-library/browse/articles/5d8c5ba5c289ff00166b4610"
      },
      {
        type: "Book",
        title: "Take Pride",
        url: "https://app.workl.co/business-library/browse/books/92846"
      },
      {
        type: "Article",
        title: "Is a Lack of Company Pride Killing Your Company? Research Says it Could Be",
        url: "https://app.workl.co/business-library/browse/articles/5d94ec47c289ff00166b47aa"
      },
      {
        type: "Article",
        title: "How to Encourage Employees to Take Pride in the Workplace",
        url: "https://app.workl.co/business-library/browse/articles/5d96f3f4baf3b70017711075"
      }
    ]
  },
  {
    section: "well_being",
    heading: "Action plan to improve scores in Wellbeing",
    summary: {
      heading: "What is Wellbeing?",
      infos: [
        {
          text: "Wellbeing looks at staff from a broader perspective, considering the person as a whole, which is a challenge for an organisation as they have to balance the needs and preferences of the individual against the team as a whole and their objectives.",
          type: "paragraph"
        },
        {
          text: "There is a growing body of evidence to support the idea that wellbeing is an essential aspect of employee engagement. It leads to improved production, lower rates of absence and stress, and higher levels of motivation. In other words, employee health and wellbeing has become a hard economic factor. Measure how well your organisational wellbeing compares to others.",
          type: "paragraph"
        },
        {
          text: "There are four questions which make up this section. They are:",
          type: "paragraph"
        }
      ]
    },
    dialHeading: "Wellbeing Score",
    planInfo: [
      {
        subtitle: "Wellbeing - Overview",
        explanation: [
          "If you score poorly on the question asking 'Do you feel the organisation cares for your wellbeing?' you'll need to consider whether an individual has concerns about the organising general or the way they are managed. You can look at scores about management relationships in the Sixth Step on job satisfaction.",
          "If the poor scores are as a result of the organisation not caring, consider the following steps to improve wellbeing:"
        ],
        bulletPoints: [
          {
            text: "1. Are working hours clearly defined with an understanding of the split between work time and home time?"
          },
          {
            text: "2. Are working hours too great and creating extra pressure and stress?"
          },
          {
            text: "3. Is the individual or team equipped for their job - sufficient training, resources, support, ability?"
          },
          {
            text: "4. Could the organisation offer more to help people be healthier? (E.g. gym memberships, classes etc.)"
          },
          {
            text: "5. Does the organisation promote a healthy lifestyle? (E.g. free fruit, encouraging lunchtime walking etc.)"
          }
        ]
      },
      {
        subtitle: "",
        explanation: [
          "If the concern is more that the manager doesn't care, you might consider the following steps:"
        ],
        bulletPoints: [
          {
            text: "1. Ask each day how your team are."
          },
          {
            text: "2. Show concern when they are unwell."
          },
          {
            text: "3. Wish them a happy break/holiday."
          },
          {
            text: "4. Take a genuine interest in them and their personal lives."
          },
          {
            text: "5. Is the individual or team equipped for their job - sufficient training, resources, support, ability?"
          }
        ]
      },
      {
        subtitle: "Working Environment - Overview:",
        explanation: [
          "There are a number of things that may lead people to express unhappiness over their working environment. It could be their journey to work, their ability to park, that the office is overcrowded or dark. If you score poorly on this question you should consider doing the following:"
        ],
        bulletPoints: [
          {
            text: "1. Investigate what the team might ask for to improve their working environment. You might not be able to improve the journey to work for instance but perhaps you can be more flexible over working hours."
          },
          {
            text: "2. Check your verbatim results to see if anything obvious rings out."
          },
          {
            text: "3. Decide which of those issues can be tackled by you or which you'll need to refer to somebody else."
          },
          {
            text: "4. Acknowledge to your team that you are aware of their concerns and are doing your utmost to improve things."
          },
          {
            text: "5. If the concern is related to a health and safety issue take immediate action. Notify all relevant office holders in your organisation. Stop work if there is an imminent danger to employees."
          }
        ]
      },
      {
        subtitle: "Anxious/depressed - Overview:",
        explanation: [
          "There are many reasons why people may feel anxious/depressed coming into work. It may be something to do with their home life and/or their time at work. Individuals who have taken the individual WorkL Workplace Engagement Survey and scored poorly for wellbeing will be directed to take the national health service anxiety depression test and given clinical advice. This is an area you should avoid."
        ],
        bulletPoints: [
          {
            text: "1. If an employee expresses concerns to you about their mental wellbeing suggest they independently take the workplace engagement survey including the NHS test."
          },
          {
            text: "2. Listen and be supportive, do not in the first instance try to manufacture a solution but rather ask what you can do to help."
          },
          {
            text: "3. If they tell you it's their job which is causing them concern discuss what support, training, resources, time etc they may need to feel better about what they are doing."
          },
          {
            text: "4. If you both conclude they are in the wrong job or role help them consider alternatives."
          },
          {
            text: "5. If it is your relationship with the individual which is causing the anxiety or depression, refer them to speak to a member of the HR team or a colleague."
          }
        ]
      }
    ],
    resources: [
      {
        type: "Research",
        title: "How to Promote Wellbeing and Tackle the Causes of Work-related Mental Health Problems",
        url: "https://app.workl.co/business-library/browse/research/5d74a5e2c289ff00166b4088"
      },
      {
        type: "Article",
        title: "Does Health Coaching Have a Role in Employee Wellbeing?",
        url: "https://app.workl.co/business-library/browse/articles/5d892185c289ff00166b44cd"
      },
      {
        type: "Book",
        title: "50 Top Tools for Employee Wellbeing",
        url: "https://app.workl.co/business-library/browse/books/101661"
      },
      {
        type: "Article",
        title: "10 Ways to Improve Wellbeing in the Workplace",
        url: "https://app.workl.co/business-library/browse/articles/5d890f7bc289ff00166b4475"
      }
    ]
  }
]

const morrisons = [
  {
    section: "reward_recognition",
    heading: "Action Plan to Improve Scores in Reward and Recognition",
    summary: {
      heading: "What Is Reward & Recognition?",
      infos: [
        {
          text: "Reward and recognition are both important factors to feeling happy at work, and everyone at Morrisons should enjoy the rewards of success.",
          type: "paragraph"
        },
        {
          text: "At Morrisons, we want everyone to receive a fair day’s pay for a fair day’s work, and if colleagues don’t feel this way it’s important to understand why. Is it that they don’t feel they’re earning a fair day’s pay, or are there other factors involved, such as feeling their workload is too much, or they aren’t happy with their shift patterns, or working hours?",
          type: "paragraph"
        },
        {
          text: "One way to help recognise colleagues is by thanking them when they go above and beyond to help ensure everyone at Morrisons feels their effort is worthwhile. Recognising a colleague’s performance with a thank you card, a note of congratulations or small gift of acknowledgement is also a powerful motivator.",
          type: "paragraph"
        },
        {
          text: "Recognising colleagues is also a great opportunity to help embed our Core Purpose and Ways of Working, by recognising colleagues who role model this. Sometimes, saying a simple ‘thank you’ for a job well done is enough to make a colleague feel valued. It’s also important that we celebrate events that are important to colleagues, such as religious events or festivals to help everyone feel welcome and that events important to them are recognised.",
          type: "paragraph"
        },
        {
          text: "These questions combine to make up your overall score for Reward and Recognition. They are:",
          type: "paragraph"
        },
        {
          points: [
            "I receive a fair day's pay for a fair day's work",
            "In my team we're thanked for doing a great job",
            "At Morrisons I can celebrate the events that matter to me"
          ],
          type: "bullets"
        }
      ]
    },
    dialHeading: "Reward & Recognition Score",
    planInfo: [
      {
        subtitle: "Score Improvement - Reward",
        explanation: [
          "If your score for Reward and Recognition indicates scope for improvement, you should look at the scores for each question to pinpoint any issues:"
        ],
        bulletPoints: [
          {
            text: "Discuss with the team and colleagues their thoughts on their pay"
          },
          {
            text: "For salaried colleagues, look at where an individual sits within the salary band and be prepared to explain why"
          },
          {
            text: "Consider what other opportunities exist for colleagues such as additional hours or further development into a higher paid role"
          },
          {
            text: "Are colleagues having regular performance reviews and career conversations?"
          },
          {
            text: "Are colleagues unhappy with their working hours because they're working too many?"
          },
          {
            text: "Are colleagues unhappy with their working hours because they're working too few?"
          },
          { text: "Would they like more flexibility in their working hours?" },
          {
            text: "Are colleagues unable to plan because they don’t know what their working hours will be?"
          },
          {
            text: "Discuss how you can help improve and alleviate concerns"
          }
        ]
      },
      {
        subtitle: "Score Improvement - Recognition",
        explanation: [
          "If your score for Reward and Recognition indicates scope for improvement, you should look at the scores for each question to pinpoint any issues:"
        ],
        bulletPoints: [
          {
            text: "Do colleagues feel they are recognised enough? "
          },
          {
            text: "How would they like to be recognised?"
          },
          {
            text: "As a leader, try and speak to each member of your team regularly and get to know them personally and what’s important to them"
          },
          {
            text: "Recognise and thank somebody when they do something well and utilise the recognition toolkit with thank you cards, thank you cheques or using the ‘Say it With’ budget to buy colleagues a gift when they go above and beyond"
          },
          {
            text: "Celebrate success in your teams "
          },
          {
            text: "What events do colleagues want to celebrate? Ask a range of diverse colleagues of different ages and backgrounds to help understand the diversity in your team"
          },
          {
            text: "How can you help your teams celebrate these events?"
          },
          {
            text: "If somebody completes work you think they can improve on, use it as an opportunity to coach (i.e how well did you feel that went, could you have done that differently, or what more support might you need if you were to do that task again etc.) so colleagues feel they are learning rather than being criticised"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Calender",
        title: "Diversity Calendar ",
        url: "https://sites.google.com/morrisonsplc.co.uk/diversitycalendar/My-handbook/diversity-calendar-desktop-view?authuser=0"
      },
      {
        type: "Internal Resource",
        title: "Recognition Toolkit",
        url: "https://docs.google.com/presentation/d/1MpkR5h7s_uPKA9tdpHSkT32RI138-FzJJn-Ky4df81E/edit?usp=sharing"
      },
      {
        type: "Internal Resource",
        title: "Internal Resource -My Handbook which can be found on MyMorri > My Info > My Handbook",
        url: "https://www.mymorri.com/"
      }
    ]
  },
  {
    section: "information_sharing",
    heading: "Action Plan to Improve Scores in Information Sharing",
    summary: {
      heading: "What is Information Sharing?",
      infos: [
        {
          text: "One of our main aims is for everyone to have the Information to do their job well. Information can be seen as different things to different people, so it’s important to understand more about what colleagues feel about this, and what else can be done to support colleagues in accessing the information they need.",
          type: "paragraph"
        },
        {
          text: "Sharing information with colleagues can help them understand how Morrisons is performing and operating and how changes and updates may affect their own role.",
          type: "paragraph"
        },
        {
          text: "The following question relates to information sharing:",
          type: "paragraph"
        },
        {
          points: [
            "I have the information I need to do my job well",
            "I know all the latest Company news is on MyMorri"
          ],
          type: "bullets"
        }
      ]
    },
    dialHeading: "Information Sharing Score",
    planInfo: [
      {
        subtitle: "Score Improvement - Information and latest news",
        explanation: [
          "You may find it helpful to explore responses to this question with your team to understand more about how colleagues feel, and what can be done to improve on this measure, by asking the following questions:"
        ],
        bulletPoints: [
          {
            text: "Do you have regular team meetings or huddles with colleagues to share updates affecting their jobs?"
          },
          {
            text: "Do you have routine meetings to share how the organisation is performing, its plans and your team's role within this?"
          },
          {
            text: "Discuss the different communication channels the business has, and important milestones during the year, such as quarterly results. Do you talk about these and encourage colleagues to access them?"
          },
          {
            text: "How can colleagues share their own feedback?"
          },
          {
            text: "How is new information and changes affecting colleague roles communicated, and is this effective?"
          },
          {
            text: "Is information colleagues need to complete their jobs easily accessible, and how can this be improved?"
          },
          {
            text: "You may find it helpful to explore responses to this question with your team to understand more about how colleagues feel, and what can be done to improve on this measure, by asking the following questions:"
          },
          {
            text: "How often do colleagues access MyMorri?"
          },
          {
            text: "Are colleagues familiar with the articles on MyMorri?"
          },
          {
            text: "Do colleagues listen / dial in / attend TeamTalks and read email updates?"
          },
          {
            text: "What else would colleagues like to be updated on?"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Internal Resources",
        title: "My Learning site for training and development",
        url: "https://sites.google.com/morrisonsplc.co.uk/mylearningdevelopment/home"
      },
      {
        type: "Internal Resource",
        title: "Keep updated on business updates on MyMorri",
        url: "https://www.mymorri.com/"
      },
      {
        type: "Video",
        title:
          "Watch our ‘Make Good Things Happen’ video where we reflected on everyone’s efforts during the Covid-19 pandemic",
        url: "https://www.youtube.com/watch?v=wAqUm9AE8bc"
      }
    ]
  },
  {
    section: "empowerment",
    heading: "Action Plan to Improve Scores in Empowerment",
    summary: {
      heading: "What Is Empowerment?",
      infos: [
        {
          text: "Empowerment is important in making colleagues feel engaged and involved in making a contribution to our business. It’s important colleagues are able to have their say on what matters to them, wherever they work in Morrisons, and understand how their role contributes to the overall success of the company.",
          type: "paragraph"
        },
        {
          text: "Empowerment is also about colleagues feeling they have freedom within the framework to make decisions on issues that affect themselves, customers and each other, and help make Morrisons a better place to work and shop.",
          type: "paragraph"
        },
        {
          text: "As a leader, listening and responding to colleagues and responding quickly wherever possible is an important part of your role, and one way to do this is to hold effective and regular Your Say forums and support colleagues to take ownership of local issues and make them feel trusted to make the right decision, while being given the opportunities to escalate those which cannot be resolved locally.",
          type: "paragraph"
        },
        {
          text: "The following question relates to empowerment:",
          type: "paragraph"
        },
        {
          points: [
            "I know how to share my views and ideas with my Your Say forum members",
            "I’m trusted to make the right decisions for customers"
          ],
          type: "bullets"
        }
      ]
    },
    dialHeading: "Empowerment Score",
    planInfo: [
      {
        subtitle: "Score Improvement - Sharing views and ideas and trusted to make the right decisions",
        explanation: ["If there’s room for improvement on this area, discuss the following with your team:"],
        bulletPoints: [
          {
            text: "Why do colleagues feel they can’t have their say on what matters in their store, site or team? What would help?"
          },
          {
            text: "Do they feel management takes the time to listen to their views?"
          },
          {
            text: "Do they feel management trust them to make the right decision?"
          },
          {
            text: "Do they feel management would support their decision?"
          },
          {
            text: "What will help the team feel supported in taking ownership of issues and opportunities?"
          },
          {
            text: "As a leader, how can you coach colleagues and facilitate the right conversations?"
          },
          {
            text: "Does the team understand the different channels they can share their feedback with leadership and the wider business?"
          },
          {
            text: "How can listening channels in your store, site or team be improved?"
          },
          {
            text: "Do leaders in your team take the time to listen and also respond to colleagues?"
          },
          {
            text: "Encourage the team and individual colleagues to express their views openly. Everyone has a different viewpoint because everyone has different experiences and backgrounds. Everyone’s welcome at Morrisons, and everyone’s views should be welcomed and heard to bring different views and perspectives"
          },
          {
            text: "What are you personally doing to encourage your team to share their views?"
          },
          {
            text: "When responding to colleagues, be honest in the answers you give"
          },
          {
            text: "To feel empowered, colleagues also need to feel trusted and supported in their role. Some helpful questions to ask are:"
          },
          {
            text: "Do colleagues know what is expected of them in their roles?"
          },
          {
            text: "Do colleagues feel trusted to be honest and have their say about issues and opportunities affecting them?"
          },
          {
            text: "Have you trained your team well enough to feel confident that they can make the right decisions?"
          },
          {
            text: "How can you build your team’s confidence by listening and coaching, especially when things don’t go to plan"
          }
        ]
      },
      {
        subtitle: "Helpful Questions",
        explanation: [
          "To feel empowered, colleagues also need to feel trusted and supported in their role. Some helpful questions to ask are:"
        ],
        bulletPoints: [
          {
            text: "Do colleagues know what is expected of them in their roles?"
          },
          {
            text: "Do colleagues feel trusted to be honest and have their say about issues and opportunities affecting them?"
          },
          {
            text: "Have you trained your team well enough to feel confident that they can make the right decisions?"
          },
          {
            text: "How can you build your team’s confidence by listening and coaching, especially when things don’t go to plan"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Article",
        title: "What it really means to lead more effectively through empowerment | McKinsey & Company",
        url: "https://www.mckinsey.com/business-functions/people-and-organizational-performance/our-insights/the-organization-blog/what-it-really-means-to-lead-more-effectively-through-empowerment"
      },
      {
        type: "Article",
        title: "Building Trust Inside Your Team",
        url: "https://www.mindtools.com/pages/article/building-trust-team.htm"
      },
      {
        type: "Article",
        title: "Leaders Can Cultivate True Employee Empowerment",
        url: "https://www.forbes.com/sites/strategyand/2019/02/19/leaders-can-cultivate-true-employee-empowerment/?sh=5640bea73ab1"
      },
      {
        type: "Article",
        title: "When Empowering Employees Works, and When It Doesn't",
        url: "https://hbr.org/2018/03/when-empowering-employees-works-and-when-it-doesnt"
      }
    ]
  },
  {
    section: "job_satisfaction",
    heading: "Action Plan to Improve Scores in Job Satisfaction",
    summary: {
      heading: "What Is Job Satisfaction?",
      infos: [
        {
          text: "There are many elements involved in a colleagues response to job satisfaction questions; having the right tools to do their job, feeling trained and confident in their role, being able to progress should they want to and being appreciated for their efforts, all make up how happy they are at work.",
          type: "paragraph"
        },
        {
          points: [
            "I am happy at work and enjoy my job",
            "I have the tools I need to do my job well",
            "I feel trained, and am confident in doing all aspects of my job",
            "If you want to, there are opportunities to progress your career at Morrisons",
            "I feel appreciated for the job that I do"
          ],
          type: "bullets"
        }
      ]
    },
    dialHeading: "Job Satisfaction Score",
    planInfo: [
      {
        subtitle: "Score Improvement - Opportunities to progress",
        explanation: [
          "For many people it’s important to their working lives that they feel they are being developed and have the opportunity to succeed in their current and future roles. It’s important everyone feels they are able to succeed at Morrisons and achieve their potential, and success means different things to different people.",
          "         As a leader you have a role to play in talking to colleagues about their own development and training needs as well as making sure that they have the tools to do their job to the best of their ability, and where you can support them in this:"
        ],
        bulletPoints: [
          {
            text: "Do colleagues feel like they have the opportunity to succeed in their role? If not, what are the barriers?"
          },
          {
            text: "Are you talking to your team regularly about their performance and where it might be improved?"
          },
          {
            text: "Are you then taking the time to coach members of your team to improve their performance and learn more on the job?"
          },
          {
            text: "Do you regularly check in with colleagues to discuss their training and development and share opportunities such as Pathways programmes and additional responsibilities?"
          }
        ]
      },
      {
        subtitle: "Score Improvement - Feeling appreciated and valued ",
        explanation: [
          "It’s hard to be satisfied at work if you do not feel respected and valued and there are lots of different elements which make colleagues feel respected. If you score poorly on this question consider:"
        ],
        bulletPoints: [
          {
            text: "Find out why the team or an individual feels there’s a lack of respect. There could be many reasons for this, so ask the team or individual open questions and explore the other measures on the six steps to workplace engagement, including the following:"
          },
          {
            text: "Do colleagues feel respected by Morrisons, their line manager, other colleagues or customers?"
          },
          {
            text: "Are colleagues recognised when they do something well?"
          },
          {
            text: "Do colleagues feel like information is shared with them?"
          },
          {
            text: "Are colleagues view’s heard?"
          },
          {
            text: "Are colleagues being developed?"
          }
        ]
      },
      {
        subtitle: "Score Improvement - Tools to do the job",
        explanation: [""],
        bulletPoints: [
          {
            text: "Do colleagues feel that they have the tools required to do their job well?"
          },
          {
            text: "What are the barriers?"
          },
          {
            text: "How do they escalate when something is not working?"
          },
          {
            text: "What do they do if they have a suggestion to work smarter and simpler?"
          }
        ]
      },
      {
        subtitle: "core Improvement - Training to do the job well",
        explanation: [""],
        bulletPoints: [
          {
            text: "Do colleagues feel they have been properly trained to do their job well?"
          },
          {
            text: "Are they aware of the courses / information on MyMorri?"
          },
          {
            text: "Is there a way to buddy up and learn from one another?"
          },
          {
            text: "Do they know how to escalate training requirements?"
          },
          {
            text: "Do they know to regularly check in their development tile on MyMorri"
          },
          {
            text: "Can additional coaching be provided?"
          }
        ]
      },
      {
        subtitle: "Score Improvement - Happiness at work",
        explanation: [
          "It’s possible to work for a great organisation, a great manager, have wonderful colleagues but still feel that you are in the wrong job. It’s hard to see, if that is the case, how someone can feel happy at work over the long term. If an individual or team score poorly on this question you should consider:"
        ],
        bulletPoints: [
          {
            text: "Find out why the team or an individual is not enjoying their job and check the previous responses to the survey questions to pinpoint where issues may lie"
          },
          {
            text: "What would make colleagues happier, and help them enjoy work more?"
          },
          {
            text: "For individual colleagues, is there an alternative role available which may make them happier?            "
          },
          {
            text: "What training or support is required to achieve their preferred role?"
          },
          {
            text: "How can you positively support colleagues in being more happy at work?"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Internal Resource",
        title: "My Learning site, including the Ways of Working Toolkit",
        url: "https://sites.google.com/morrisonsplc.co.uk/mylearningdevelopment/home"
      }
    ]
  },
  {
    section: "instilling_pride",
    heading: "Action Plan to Improve Scores in Instilling Pride",
    summary: {
      heading: "What Is Instilling Pride?",
      infos: [
        {
          text: "One of our People ambitions is for everyone to feel welcome and included and we're extremely pleased to have a diverse group of colleagues working here. We want to make sure we continue to help everyone on their journey while working at Morrisons to feel part of the team and proud of the work we do to support our customers, communities and each other both collectively and individually.",
          type: "paragraph"
        },
        {
          text: "We also want everyone to feel their effort is worthwhile, and that colleagues understand how they can work with purpose. Research suggests that where people feel a sense of purpose, they contribute more to an organisation’s success and are happier overall.",
          type: "paragraph"
        },
        {
          points: ["I'm proud to work for Morrisons", "My team makes everyone feel welcome"],
          type: "bullets"
        }
      ]
    },
    dialHeading: "Instilling Pride Score",
    planInfo: [
      {
        subtitle: "Score Improvement",
        explanation: ["It may be helpful to think about the following questions in your teams:"],
        bulletPoints: [
          {
            text: "Do you understand what Morrisons does to help society and our communities such as our charitable activity, social purpose and sustainability?"
          },
          {
            text: "How does your team support your local community?"
          },
          {
            text: "What makes you proud to work for Morrisons as a business, or in your team?"
          },
          {
            text: "What would make you prouder?"
          },
          {
            text: "As a leader how do you help our team understand this?"
          },
          {
            text: "How do you celebrate and share the success of local achievements in your team?"
          },
          {
            text: "Do you share the importance of the colleagues’ role to the success of Morrisons?"
          },
          {
            text: "Do you talk about diversity in your teams and Your Say forums?"
          },
          {
            text: "Do colleagues feel welcome as part of the team?"
          },
          {
            text: "How do you welcome them and make sure they feel part of the team from the start?"
          },
          {
            text: "What would help colleagues feel more welcome, including new starters and colleagues from different backgrounds?"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Internal Resources",
        title: "Everyone’s Welcome and celebrated",
        url: "https://sites.google.com/morrisonsplc.co.uk/everyoneswelcomeandcelebrated"
      },
      {
        type: "Internal Resource",
        title: "Morrisons Corporate Responsibility report",
        url: "https://www.morrisons-corporate.com/media-centre/corporate-news/"
      },
      {
        type: "Internal Resource",
        title: "Keep up to date on MyMorri",
        url: "https://www.mymorri.com/"
      },
      {
        type: "Internal Resource",
        title: "My Communities such as the Plastics Community",
        url: "https://currents.google.com/u/0/communities/109618369402999107264"
      }
    ]
  },
  {
    section: "well_being",
    heading: "Action Plan to Improve Scores in Wellbeing",
    summary: {
      heading: "What Is Wellbeing?",
      infos: [
        {
          text: "It’s important colleagues feel their wellbeing is cared for, and if you have a lower score on these questions, it’s important to understand why colleagues feel this way, what the cause is and whether their concerns are around Morrisons or something about their role, management or where they work and how things can be improved.",
          type: "paragraph"
        },
        {
          text: "There are a number of things that may lead people to express unhappiness over their working environment, for example their journey to work or their ability to park, their hours or shift-pattern, their break-time environment. It’s important we all work together to keep each other and our customers safe, so understanding where issues may lie is important.",
          type: "paragraph"
        },
        {
          points: [
            "My line manager provides help and support when I need it",
            "I'm aware of the wellbeing support available on MyMorri from Vita Health"
          ],
          type: "bullets"
        }
      ]
    },
    dialHeading: "Wellbeing Score",
    planInfo: [
      {
        subtitle: "My line manager helps and supports me",
        explanation: [
          "One of the most critical relationships in the workplace is between a colleague and their manager. One of our people ambitions is for all colleagues to have a line manager who helps and supports them. Often, people leave their jobs because of the poor relationship they have with their line manager. There can be many reasons for this, but if you have scored poorly in this area you may like to consider the following:"
        ],
        bulletPoints: [
          {
            text: "Take an active interest in each of your team members - finding out about their personal life and their hopes and ambitions for work and their wellbeing"
          },
          {
            text: "Recognise that we’re all different as we have different skills, experiences and backgrounds"
          },
          {
            text: "Coach your team to improve their performance"
          },
          {
            text: "Recognise and respect colleague contributions both individually and publicly"
          },
          {
            text: "Give individuals the information they need to do their job well and put that into context around how the whole of Morrisons is performing"
          },
          {
            text: "Create an environment where your team feel their jobs have a purpose within Morrisons and the wider community, so everyone can work with purpose"
          }
        ]
      },
      {
        subtitle: "Awareness of wellbeing support",
        explanation: [
          "There are several questions which may be helpful in asking your team or individual colleagues, to help understand the cause of this result:"
        ],
        bulletPoints: [
          {
            text: "Are working hours clearly defined with an understanding of the split between work time and home time?"
          },
          {
            text: "Are rotas and working patterns scheduled in advance? Do these change at the last minute?"
          },
          {
            text: "Are working hours too great and creating extra pressure and stress?"
          },
          {
            text: "Is the colleague or team equipped for their job with sufficient training, resources, support and skill?"
          },
          {
            text: "Do colleagues get to take appropriate breaks?"
          },
          {
            text: "Is there anything you’d like to see to help colleagues become healthier?"
          },
          {
            text: "Investigate what the team might ask for to improve their working environment. You might not be able to improve the journey to work for instance but perhaps you can be more flexible over working hours"
          },
          {
            text: "Decide which of those issues can be resolved by you or which you’ll need to refer to somebody else"
          },
          {
            text: "Do colleagues know where and how to look for support via MyMorri?"
          },
          {
            text: "Are colleagues aware of Vita Health and the support and guidance that it offers?"
          },
          {
            text: "If the concern is related to a health and safety issue you should take immediate action"
          },
          {
            text: "If a colleague expresses concerns to you about their mental wellbeing, signpost them to our MyWellbeing site and/or speak to Vita Health"
          },
          {
            text: "Listen and be supportive. It’s not your role to offer advice or find a solution, but rather ask what you can do to help"
          },
          {
            text: "If they tell you it's their job which is causing them concern, discuss what support, training, resources, time etc they may need to feel better about what they’re doing"
          }
        ]
      }
    ],
    resources: [
      {
        type: "Internal Resource",
        title: "Visit the My Wellbeing site",
        url: "https://sites.google.com/morrisonsplc.co.uk/mywellbeing/home"
      },
      {
        type: "Article",
        title:
          "Article -Colleagues can find support through Vita Health by contacting the helpline on 0800 111 6387. You can also find more information by visiting the website www.my-eap.com",
        url: "https://www.my-eap.com/access"
      },
      {
        type: "Video",
        title: "TED Talk - Workplace Mental Health",
        url: "https://www.youtube.com/watch?v=G0XUimJbz44"
      }
    ]
  }
]

export default { generic, morrisons }
