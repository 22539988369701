import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import ActionPlanView from "../../features/Reports/views/ActionPlan"
import ReportWrapper from "../../features/Reports/views/ReportWrapper"
import { OrgGuard } from "../../features/account"
import { setPdfDownloadKey } from "../../features/account/store/account.actions"
import ReportLayout from "../../layouts/ReportLayout"

const ActionPlan = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPdfDownloadKey("action-plan"))
  }, [])

  return (
    <OrgGuard>
      <ReportLayout>
        <ReportWrapper>
          <ActionPlanView />
        </ReportWrapper>
      </ReportLayout>
    </OrgGuard>
  )
}

export default ActionPlan
