import { Flex, H2, H4 } from "@engaging-tech/components"
import React from "react"

const MainHeader = ({ width }) => {
  return (
    <Flex
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      alignItems={["flex-start", "center"]}
      pb={3}
      px={[2, 0, 0]}
      width={width || "auto"}
    >
      <Flex flexDirection="column">
        <H2 fontWeight={700} width="auto" fontSize={28}>
          Action Plans
        </H2>
        <H4 width="auto" fontSize="15px" mt={2} mb={2}>
          View and manage your action plans
        </H4>
      </Flex>
    </Flex>
  )
}

export default MainHeader
