import { connect } from "react-redux"

import {
  canSendForm,
  postActionPlanStepData
} from "../../../../store/actionPlans/actionPlans.selectors"
import EditActionPlanHeader from "../../components/EditActionPlan/EditActionPlanHeader"

const mapState = state => ({
  postData: postActionPlanStepData(state),
  canSendForm: canSendForm(state)
})

const mapDispatch = dispatch => ({})

const EditActionPlanHeaderContainer = connect(
  mapState,
  mapDispatch
)(EditActionPlanHeader)

export default EditActionPlanHeaderContainer
