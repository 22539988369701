import { connect } from "react-redux"
import DownloadHeatMapPDFComponent from "../components/DownloadHeatMapPDF"
import * as FilterSelectors from "../../Filters/store/filters.selectors"
import {
  updateIsHeatMapPdfDownloading,
  updatePDFProgress
} from "../../store/report.actions"
import {
  getIsHeatMapPdfDownloading,
  getSurveyType,
  getReportResponseTitle
} from "../../store/report.selectors"

const mapState = state => ({
  filters: {
    current: FilterSelectors.getCurrentFilters(state),
    required: FilterSelectors.getRequiredFilters(state),
    isDirectReports: FilterSelectors.getIsCurrentDirectReports(state)
  },
  isHeatMapPdfDownloading: getIsHeatMapPdfDownloading(state),
  surveyType: getSurveyType(state),
  title: getReportResponseTitle(state)
})

const mapDispatch = dispatch => ({
  updateHeatMapPdfDownloading: payload =>
    dispatch(updateIsHeatMapPdfDownloading(payload)),
  updatePDFProgress: payload => dispatch(updatePDFProgress(payload))
})

const DownloadHeatMapPDF = connect(
  mapState,
  mapDispatch
)(DownloadHeatMapPDFComponent)

export default DownloadHeatMapPDF
