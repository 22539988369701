import React from "react"
import JoinBIPView from "../../features/account/views/JoinBIP"
import GradientLayout from "../../layouts/GradientLayout"

const JoinBIP = () => {
  return (
    <GradientLayout>
      <JoinBIPView />
    </GradientLayout>
  )
}

export default JoinBIP
