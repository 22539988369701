import { usePaths } from "@engaging-tech/routing"
import React from "react"
import JoinOurNetworkComponent from "../components/JoinOurNetwork"
import EBNBillingWrapper from "../containers/EBNBillingWrapper"

const JoinOurNetwork = () => {
  const paths = usePaths()

  return (
    <>
      <EBNBillingWrapper cancelRoute={paths.home.index}>
        {props => <JoinOurNetworkComponent {...props} />}
      </EBNBillingWrapper>
    </>
  )
}

export default JoinOurNetwork
