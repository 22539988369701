// import { gql } from "@apollo/client"
import { gql } from "@apollo/client"

const CREATE_OR_UPDATE_BIP = gql`
  mutation createOrUpdateBIP($input: BusinessInformationPageInput) {
    createOrUpdateBIP(input: $input) {
      statusCode
      body
      error
    }
  }
`

export default CREATE_OR_UPDATE_BIP
