import React, { useContext } from "react"
import TemplateQuestionContext from "../TemplateQuestionContext"
import DropdownQuestionEdit from "./DropdownQuestionEdit"
import RadioQuestionEdit from "./RadioQuestionEdit"
import ScaleQuestionEdit from "./ScaleQuestionEdit"
import ShortTextQuestionEdit from "./ShortTextQuestionEdit"

const EditCustomQuestionBody = ({
  deleteOption,
  changeScale,
  setValue,
  setField,
  setPageErrors
}) => {
  const { type } = useContext(TemplateQuestionContext)
  return (
    <>
      {type === "scale" && <ScaleQuestionEdit changeScale={changeScale} />}
      {type === "short-text" && (
        <ShortTextQuestionEdit
          deleteOption={deleteOption}
          onChange={setField}
          setPageErrors={setPageErrors}
        />
      )}
      {type === "dropdown" && (
        <DropdownQuestionEdit
          deleteOption={deleteOption}
          onChange={setValue}
          setPageErrors={setPageErrors}
        />
      )}
      {type === "radio" && (
        <RadioQuestionEdit
          deleteOption={deleteOption}
          onChange={setValue}
          setPageErrors={setPageErrors}
        />
      )}
    </>
  )
}

export default EditCustomQuestionBody
