import { Flex, Text } from "@engaging-tech/components"
import React, { useContext } from "react"
import styled from "styled-components"

import tabs from "../../lib/tabs"
import { ListActionPlansContext } from "../../views/ListActionPlans"

const ButtonText = styled(Text)`
  font-weight: ${({ selected }) => selected && 600};
  color: ${({ selected, theme }) => selected && theme.colors.primary[0]};
  border-bottom: ${({ selected, theme }) =>
    selected && `2px solid ${theme.colors.primary[0]}`};

  &:hover {
    cursor: pointer;
  }
`

const ListTabs = () => {
  const { selectedTab, handleTabClick } = useContext(ListActionPlansContext)

  return (
    <Flex width={1 / 1}>
      {tabs.map(({ name, type }) => (
        <ButtonText
          width="fit-content"
          px={2}
          pb="1px"
          key={type}
          onClick={() => handleTabClick(type)}
          selected={selectedTab === type}
        >
          {name}
        </ButtonText>
      ))}
    </Flex>
  )
}

export default ListTabs
