import { connect } from "react-redux"

import { sortAssignedSurveys } from "../../../store/actionPlans/actionPlans.actions"
import { getActionPlanData } from "../../../store/actionPlans/actionPlans.selectors"
import ViewActionPlan from "../views/ViewActionPlan"

const mapState = state => ({
  actionPlan: getActionPlanData(state)
})

const mapDispatch = dispatch => ({
  sortAssignedSurveys: sortBy => dispatch(sortAssignedSurveys(sortBy))
})

const ViewActionPlanContainer = connect(mapState, mapDispatch)(ViewActionPlan)

export default ViewActionPlanContainer
