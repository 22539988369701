import { Flex, Text, Spinner } from "@engaging-tech/components"
import React, { useState } from "react"
import styled from "styled-components"
import TemplateLoader from "../../loaders/TemplateLoader"
import ShowPreview from "./ShowPreview"

const RotatedText = styled(Text)`
  top: 250px;
  left: -28px;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
`

const BorderedFlex = styled(Flex)`
  border-top: 1px solid #e3e3e3;
  border-left: ${({ theme }) => `36px solid ${theme.colors.secondary[0]}`};
  border-bottom-left-radius: 5px;
  position: relative;
  height: auto;
`

const StyledText = styled(Text)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

const List = ({ displayQuestions, isLoading, error }) => {
  if (isLoading) return <Spinner color="primary.0" width={1 / 1} mt={50} />

  /* Error Generalised here due to errors coming from different component */
  if (error)
    return (
      <Text width={1 / 1} textAlign="center" mt={20}>
        Something Went Wrong
      </Text>
    )

  return (
    <Flex flexDirection="column" mt={3} width="95%" alignItems="flex-end">
      {displayQuestions?.map((question, i) => (
        <Text key={question} color="secondary.0" mt={1}>
          {`${i + 1}. ${question}`}
        </Text>
      ))}
    </Flex>
  )
}

const QuestionList = ({
  displayQuestions,
  templateId,
  isLoading,
  error,
  questionsPreview
}) => {
  const [showPreview, setShowPreview] = useState(false)
  const getTemplate = () =>
    templateId && <TemplateLoader templateId={templateId} />

  return (
    <>
      {getTemplate()}
      <BorderedFlex width={1 / 1} mt={4}>
        <RotatedText color="light.0" position="absolute" width="230px">
          {questionsPreview.title}
        </RotatedText>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          minHeight="300px"
        >
          <List
            displayQuestions={displayQuestions}
            isLoading={isLoading}
            error={error}
          />
          <Flex
            mt={[3, 0]}
            mb={2}
            pr={2}
            justifyContent="flex-end"
            width={1 / 1}
            alignSelf="flex-end"
          >
            <StyledText
              width="fit-content"
              fontSize="16px"
              color="secondary.0"
              fontWeight={600}
              onClick={() => setShowPreview(!showPreview)}
            >
              Click here to preview
            </StyledText>
          </Flex>
        </Flex>
      </BorderedFlex>
      {showPreview && (
        <ShowPreview
          setShowPreview={setShowPreview}
          link={questionsPreview.image}
        />
      )}
    </>
  )
}

export default QuestionList
