import {
  Box,
  CategorySelectSearch,
  Flex,
  Text
} from "@engaging-tech/components"
import { jobRoles } from "@engaging-tech/frontend-utils"
import React from "react"

const jobRoleCheckboxValues = jobRoles
  .map(({ value, id }) => ({
    name: value,
    id
  }))
  .sort((a, b) => a.name.localeCompare(b.name))

const sortArrayByNumber = (a, b) => {
  return a - b
}

const sortArrayByText = (a, b) => {
  return a.name.localeCompare(b.name)
}

const FilterGroup = ({
  title,
  filterOptions = [],
  handleChange,
  values,
  setDropDownList,
  dropDownList,
  sortByNumber
}) => {
  const toggleOtherDropDowns = id => {
    const newDropDownList = { ...dropDownList }
    // eslint-disable-next-line no-restricted-syntax
    for (const key in newDropDownList) {
      // eslint-disable-next-line no-prototype-builtins
      if (newDropDownList.hasOwnProperty(key)) {
        if (id !== key) {
          newDropDownList[key] = !newDropDownList[key]
        }
      }
    }
    setDropDownList(newDropDownList)
  }

  return (
    <Flex
      flexDirection={["column", filterOptions.length > 5 ? "row" : "column"]}
      flexWrap={filterOptions.length > 5 ? "wrap" : "nowrap"}
      alignItems="center"
      justifyContent="space-between"
    >
      <Text
        fontSize={5}
        fontWeight={500}
        color="dark.2"
        mb={3}
        width={100 / 100}
      >
        {title}
      </Text>
      {filterOptions.map((filterOption, index) => {
        if (filterOption?.values?.length < 1) return null
        const name = filterOption.questionId
        const checkboxValues = filterOption.values
          .map(val => ({
            name: val.value,
            id: val.valueId
          }))
          .sort(sortByNumber ? sortArrayByNumber : sortArrayByText)
        return (
          <Box
            onFocus={() => toggleOtherDropDowns(filterOption.questionId)}
            key={`${name}-${index.toString()}`}
            width={[1 / 1, filterOptions.length > 5 ? 49 / 100 : 1 / 1]}
          >
            <CategorySelectSearch
              dropdownRowHeight={80}
              dropdownBuffer={20}
              columsPerDeviceType={{ DESKTOP: 2 }}
              onChange={handleChange}
              name={name}
              closeDropdownTrigger={dropDownList[filterOption.questionId]}
              values={
                filterOption.questionId === "26"
                  ? jobRoleCheckboxValues
                  : checkboxValues
              }
              label="Type to search..."
              active="secondary"
              title={filterOption.question}
              checkboxColour="dark.2"
              showSelectAll
              selectAllButtonStyles={{
                width: "180px",
                fontSize: 3,
                height: "24px"
              }}
              width={1 / 1}
              selectedValues={values[filterOption.questionId]}
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default FilterGroup
