import { Flex, Text } from "@engaging-tech/components"
import React from "react"

export default function SurveyScore({ score, width }) {
  return (
    <Flex flexDirection="column" width={["100%", "100%", width]} minWidth={["100%", "100%", width]}>
      {(score || score === 0) && (
        <>
          <Text fontSize={3} color="dark.2" pb={2} width="auto">
            Survey Score
          </Text>
          <Text fontSize={6} fontWeight={800} color="dark.2" width="auto">
            {`${score}%`}
          </Text>
        </>
      )}
    </Flex>
  )
}
