import { Flex, Icon, Paragraph, Spinner, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import { OrgGuard } from "../../features/account"
import ChooseAward from "../../features/awards/components/WorkplaceAwards"
import { GET_ALL_ACTIVE_AWARDS } from "../../features/awards/services/awards.queries"
import { getAllActiveAwardsFail } from "../../features/awards/store/awards.actions"
import ErrorState from "../../features/business-information/components/error-state"
import useBusinessAppQuery from "../../hooks/useBusinessAppQuery"
import PageLayout from "../../layouts/PageLayout"

const copyLink = () => {
  navigator.clipboard.writeText(window.location.href)
}

const AwardsHomepage = () => {
  const { isLoading, errors, data } = useBusinessAppQuery(GET_ALL_ACTIVE_AWARDS, {
    failAction: getAllActiveAwardsFail
  })

  return (
    <OrgGuard>
      <PageLayout pt={0}>
        {isLoading && <Spinner justifyContent="center" color="primary.0" />}
        {errors && <ErrorState />}
        {data && (
          <Flex bg="secondary.0" flexDirection="column" width={1 / 1}>
            <Flex flexDirection="column" width="100%" mt={3}>
              <Text color="light.0" fontSize={[6, 7]} fontWeight="600" textAlign="center">
                Workplace Awards
              </Text>
              <Paragraph my={2} color="light.0" fontSize={[3, 4]} textAlign="center">
                Choose and enter your organisation into an award from the list below.
                <br /> If you have any questions about which awards are right for you contact us at{" "}
                <Link to="mailto:workplaceawards@workl.com" newTab external>
                  <b>workplaceawards@workl.com</b>
                </Link>
              </Paragraph>
            </Flex>
            <ChooseAward allAwards={data?.getAllActiveAwards} />
            <Flex color="light.0" justifyContent="flex-end" p={2} onClick={copyLink}>
              <Icon name="link" color="light.0" />
              copy link
            </Flex>
          </Flex>
        )}
      </PageLayout>
    </OrgGuard>
  )
}

export default AwardsHomepage
