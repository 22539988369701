import React from "react";

export const Tab = ({ title, active, onClick }) => (
  <button
    style={{
      height: 36,
      padding: "10px 20px",
      cursor: "pointer",
      backgroundColor: active ? "#173C5A" : "white",
      border: '1px solid #173C5A',
      borderBottom: 'none',
      borderRadius: '5px 15px 0px 0px',
      color: active ? "#fff" : "#000",
    }}
    onClick={onClick}
  >
    {title}
  </button>
);
