import { CustomHooks, Paragraph } from "@engaging-tech/components"
import React from "react"
import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import Nav from "../../surveyInfo/components/Nav"
import ReviewComponent from "../components/SurveyReview"
import SurveyLink from "../containers/SurveyLink"

const Review = ({ ...props }) => {
  CustomHooks.useFreshChatDeactivate(true)
  return (
    <CreationFormsLayout
      bg="#EEF4F9"
      maxWidth="1000px"
      BottomNav={() => <Nav {...props} />}
      mb={7}
    >
      <Paragraph mt={5} fontSize={[4, 5]}>
        Please review your survey before sending. You can test your survey with
        the survey link below to check you are happy with the experience.
      </Paragraph>
      <ReviewComponent mb={5} {...props} />
      <SurveyLink {...props} />
    </CreationFormsLayout>
  )
}

export default Review
