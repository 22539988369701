/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import ReportLayout from "../../layouts/ReportLayout"
import ReportWrapper from "../../features/Reports/views/ReportWrapper"
import SummaryView from "../../features/Reports/views/Summary"
import { OrgGuard } from "../../features/account"
import { getHasSliceAndDiceLicence } from "../../features/account/store/account.selectors"
import addParams from "../../utils/addParams"

const Summary = () => {
  const sliceAndDiceLicence = useSelector(getHasSliceAndDiceLicence)

  useEffect(() => {
    addParams(sliceAndDiceLicence, "slice-and-dice", "inactive")
  }, [])

  return (
    <OrgGuard>
      <ReportLayout>
        <ReportWrapper>
          <SummaryView />
        </ReportWrapper>
      </ReportLayout>
    </OrgGuard>
  )
}

export default Summary
