import React from "react"
import { Flex } from "@engaging-tech/components"
import { Formik } from "formik"
import styled from "styled-components"
import { object, string } from "yup"
import {
  FilePickerButton,
  TextField
} from "../../../../surveyManagement/components"

const StyledButton = styled.button`
  height: 56px;
  background: ${({ theme }) => theme.colors.secondary[0]};
  color: ${({ theme }) => theme.colors.light[0]};
  border-radius: 10px;
  width: 120px;
  position: absolute;
  right: 0;
  top: 0;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-weight: 600;
  font-size: 16px;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.dark[2]};
  }
`

const Form = styled.form`
  width: inherit;
`

const validation = object().shape({
  email: string().email("Please enter a valid email address")
})

const UploadCSV = ({ isDisabled, setRecipients }) => {
  const handleFileChange = e => {
    if (typeof e.target.files === "undefined") return
    const reader = new FileReader()
    reader.onloadend = event => {
      const emailAddrs = event.target.result.split("\r\n")
      emailAddrs.forEach(address => {
        setRecipients(address)
      })
    }
    const csv = e.target.files[0]
    reader.readAsText(csv)
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Uploaded survey CSV"])
    }
  }

  return (
    <FilePickerButton
      mb={2}
      width={145}
      accept=".csv"
      id="csv"
      name="csv"
      onChange={handleFileChange}
      disabled={isDisabled}
      leadingIcon="backup"
      text="Upload .CSV"
    />
  )
}

const AddEmail = ({ setRecipients, isDisabled }) => {
  const onSubmit = (value, { resetForm }) => {
    setRecipients(value?.email)
    resetForm()
  }

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, errors, values, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <Flex width={1 / 1} position="relative">
              <TextField
                width={0.9 / 1}
                type="email"
                name="email"
                error={errors?.email}
                value={values?.email}
                onChange={handleChange}
                placeholder="Participant email addresses"
                disabled={isDisabled}
              />
              <StyledButton
                type="submit"
                disabled={!values?.email || !!errors?.email || isDisabled}
              >
                Add email
              </StyledButton>
            </Flex>
          </Form>
        )}
      </Formik>
      <UploadCSV isDisabled={isDisabled} setRecipients={setRecipients} />
    </>
  )
}

export default AddEmail
