import { Chip, Flex, Text } from "@engaging-tech/components"
import { hideVisually } from "polished"
import React from "react"
import styled from "styled-components"

const HiddenInput = styled.input.attrs({ type: "file" })`
  ${hideVisually()}
`

export default function FilePicker({ id, name, accept, imageUrl, error = "", disabled, onChange, onRemove }) {
  if (imageUrl) {
    return (
      <Chip
        ml={4}
        trailingIcon="close"
        onClick={e => {
          e.preventDefault()

          onRemove(imageUrl)
        }}
      >
        {name}
      </Chip>
    )
  }

  return (
    <Flex as="label" htmlFor={`upload-image-${id}`} onChange={onChange}>
      <HiddenInput id={`upload-image-${id}`} name={name} accept={accept} error={error} />
      <Chip
        type="button"
        leadingIcon="cloud_upload"
        ml={4}
        color="primary.0"
        disabled={disabled}
        onClick={() => document.getElementById(`upload-image-${id}`).click()}
      >
        <Text color="dark.1" fontSize={3}>
          Upload .JPEG file
        </Text>
      </Chip>
    </Flex>
  )
}
