import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const customRadioDropdownQuestions = createSelector(
  ReportSelectors.getReportData,
  data =>
    data?.customQuestions?.filter(
      question => question.type === "radio" || question.type === "dropdown" || question.type === "smiley-face"
    ) || []
)

export const customScaleQuestions = createSelector(
  ReportSelectors.getReportData,
  data =>
    data?.customQuestions?.filter(question => question.type === "scale") || []
)

export const getStartExitCoreRadioDropdownQuestions = createSelector(
  ReportSelectors.getReportData,
  data =>
    data?.coreQuestionsBreakdown?.filter(
      question => question.type === "radio" || question.type === "dropdown" || question.type === "smiley-face"
    ) || []
)

export const getStartExitCoreScaleQuestions = createSelector(
  ReportSelectors.getReportData,
  data =>
    data?.coreQuestionsDetails?.filter(question => question.type === "scale") ||
    []
)
