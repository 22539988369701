import { connect } from "react-redux"
import { ReportActions, ReportSelectors } from ".."
import NoSubmissionsButtonComponent from "../components/NoSubmissionsButton"
import { getSurveyType } from "../store/report.selectors"

const mapState = state => ({
  isDefault: ReportSelectors.getIsDefault(state),
  surveyType: getSurveyType(state)
})

const mapDispatch = dispatch => ({
  reLoad: (surveyId, surveyType) => {
    dispatch(ReportActions.updateDirectReports(false))
    dispatch(ReportActions.updateFilters([]))
  }
})

const NotEnoughSubmissions = connect(
  mapState,
  mapDispatch
)(NoSubmissionsButtonComponent)

export default NotEnoughSubmissions
