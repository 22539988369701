import { call, put, takeLatest } from "redux-saga/effects"
import { loadCoreQuestions } from "../../services/coreQuestions/coreQuestions.service"
import {
  loadCoreQuestionsFailure,
  loadCoreQuestionsSuccess,
  types
} from "./coreQuestions.actions"

export function* loadCoreQuestionsSaga(action) {
  try {
    const res = yield call(loadCoreQuestions, action.payload)
    yield put(loadCoreQuestionsSuccess(res.data.getCoreQuestions))
  } catch {
    yield put(loadCoreQuestionsFailure())
  }
}

export function* saga() {
  yield takeLatest(types.LOAD_CORE_QUESTIONS, loadCoreQuestionsSaga)
}
