import React from "react"
import DashboardLayout from "../../../layouts/DashboardLayout"
import TemplatesView from "../../../features/surveyManagement/templateSelection/views/Templates"
import { OrgGuard } from "../../../features/account"

const TemplateSelection = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <TemplatesView />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default TemplateSelection
