import { Box, Button, Flex } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import styled from "styled-components"

import ActiveHeatMapFilters from "../ActiveHeatMapFilters"
import {
  useCheckIfCurrentTabIsSelected,
  useCheckIfGlobalTabIsSelected,
  useCheckIfPreviousTabIsSelected
} from "../HeatMapTabs/useCheckIfGlobalTabIsSelected"
import SixStepCollapseColumn from "../SixStepCollapseColumn"
import StepColumn from "../StepColumn"

const OverflowFlex = styled(Flex)`
  overflow: scroll;
  min-height: 70vh;
  max-height: 90vh;
  height: auto;
`

const BlockBox = styled(Box)`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.light[0]};
  width: 200px;
  height: 80px;
  visibility: hidden;
`

function filterStepsByQuestions(filteredQuestions, steps) {
  if (!filteredQuestions.length) {
    return steps
  }

  const stepsFiltered = {
    title: "Your Filtered Questions",
    questions: []
  }

  filteredQuestions.forEach(item => {
    steps.forEach(step => {
      const foundQuestion = step.questions.find(x => x.id === item)
      if (foundQuestion) {
        return stepsFiltered.questions.push({
          ...foundQuestion,
          step: step.step
        })
      }
    })
  })

  return [stepsFiltered]
}

function mapQuestionValuesByFilter(filters) {
  const questionsMapped = new Map()

  filters.forEach(filter => {
    filter?.response?.data?.sixStepsQuestions.forEach(sixStepQuestion => {
      sixStepQuestion.questions?.forEach(question => {
        questionsMapped.set(`${question.id}-${filter.id}`, question.values)
      })
    })
  })

  return questionsMapped
}

export const HeatMapResults = ({
  report: {
    data: { sixStepsQuestions, customSteps, sixStepsComparison }
  },
  heatmapFilters,
  directReports,
  surveyType,
  allBusinessAreas,
  setAllBusinessAreas,
  loadAllBusinessAreas,
  loadFilterData,
  clearAllHeatmapFilters,
  expand,
  exportingPDF,
  allHeatmapFilters,
  reportId,
  questionFilter,
  tabSelected,
  comparisonReport,
  isComparisonLoading,
  comparisonSurveyTitle,
  comparisonFilters
}) => {
  const { id } = useParams()
  const allSteps = [...sixStepsQuestions, ...customSteps]
  const allComparisonSteps = [
    ...(comparisonReport?.data?.sixStepsQuestions ?? []),
    ...(comparisonReport?.data.customSteps ?? [])
  ]

  const filteredSteps = filterStepsByQuestions(questionFilter, allSteps)
  const questionValuesMap = mapQuestionValuesByFilter(heatmapFilters)
  const showGlobalResult = useCheckIfGlobalTabIsSelected(tabSelected)
  const showPreviousComp = useCheckIfPreviousTabIsSelected(tabSelected)
  const showCurrentComp = useCheckIfCurrentTabIsSelected(tabSelected)

  const handleScroll = e => {
    const { scrollTop } = e.target
    const stickyFlex = document.getElementsByClassName(
      "heatmaps-questions-header"
    )
    const scrollBlock = document.getElementById("heatmaps-scroll-block")

    if (
      stickyFlex.length &&
      scrollTop > 50 &&
      !stickyFlex[0].style.borderBottom
    ) {
      for (let i = 0; i < stickyFlex.length; i++) {
        stickyFlex[i].style.borderBottom = "2px dashed #173c5a99"
      }
      scrollBlock.style.visibility = "visible"
    }

    if (
      stickyFlex.length &&
      scrollTop <= 50 &&
      stickyFlex[0].style.borderBottom
    ) {
      for (let i = 0; i < stickyFlex.length; i++) {
        stickyFlex[i].style.borderBottom = ""
      }

      scrollBlock.style.visibility = "hidden"
    }
  }

  useEffect(() => {
    setAllBusinessAreas({ loading: true, response: null })
    loadAllBusinessAreas(id, surveyType, directReports)
  }, [directReports, id, loadAllBusinessAreas, setAllBusinessAreas, surveyType])

  useEffect(() => {
    return () => {
      clearAllHeatmapFilters()
    }
  }, [clearAllHeatmapFilters, id])

  return (
    <Flex flexDirection="column" width="100%">
      <OverflowFlex
        mt={3}
        width="100%"
        justifyContent="flex-start"
        onScroll={handleScroll}
        pr={4}
        position="relative"
      >
        <BlockBox id="heatmaps-scroll-block" />

        {!exportingPDF && (
          <ActiveHeatMapFilters
            heatmapFilters={heatmapFilters}
            loadFilterData={loadFilterData}
            surveyType={surveyType}
            directReports={directReports}
            expand={expand}
            allHeatmapFilters={allHeatmapFilters}
            showGlobalResult={showGlobalResult}
            showPreviousComp={showPreviousComp}
          />
        )}
        {expand && (
          <>
            {filteredSteps.map((questionGroup, i) => {
              return (
                <StepColumn
                  showPreviousComp={showPreviousComp}
                  key={`StepColumn${questionGroup?.step}`}
                  questionGroup={questionGroup}
                  comparisonReport={comparisonReport}
                  isComparisonLoading={isComparisonLoading}
                  comparisonSurveyTitle={comparisonSurveyTitle}
                  allComparisonSteps={allComparisonSteps}
                  allBusinessAreas={allBusinessAreas}
                  heatmapFilters={heatmapFilters}
                  comparisonFilters={comparisonFilters}
                  reportId={reportId}
                  questionValuesMap={questionValuesMap}
                  showGlobalResult={showGlobalResult}
                  firstGroup={i === 0}
                  showCurrentComp={showCurrentComp}
                  allHeatmapFilters={allHeatmapFilters}
                  expand={expand}
                  directReports={directReports}
                  surveyType={surveyType}
                  loadFilterData={loadFilterData}
                  exportingPDF={exportingPDF}
                />
              )
            })}
          </>
        )}

        {!expand && (
          <>
            {filteredSteps.map((questionGroup, i) => {
              return (
                <SixStepCollapseColumn
                  key={`StepColumn${questionGroup?.step}`}
                  comparisonReport={comparisonReport}
                  isComparisonLoading={isComparisonLoading}
                  comparisonSurveyTitle={comparisonSurveyTitle}
                  allBusinessAreas={allBusinessAreas}
                  comparisonFilters={comparisonFilters}
                  heatmapFilters={heatmapFilters}
                  questionGroup={questionGroup}
                  stepIndex={i}
                  allSteps={allSteps}
                  sixStepsComparison={sixStepsComparison}
                  showGlobalResult={showGlobalResult}
                  showPreviousComp={showPreviousComp}
                  allHeatmapFilters={allHeatmapFilters}
                  expand={expand}
                  directReports={directReports}
                  surveyType={surveyType}
                  loadFilterData={loadFilterData}
                  exportingPDF={exportingPDF}
                />
              )
            })}
          </>
        )}
      </OverflowFlex>
      {heatmapFilters.length > 0 && !exportingPDF && (
        <Button onClick={clearAllHeatmapFilters} mt={4}>
          Clear Filters
        </Button>
      )}
    </Flex>
  )
}
