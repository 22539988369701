import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../lib/Apollo"
import {
  GET_ALL_COUNTRIES,
  GET_ALL_INDUSTRIES,
  SAVE_FEEDBACK
} from "./ui.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Loads all countries
 */
export const loadAllCountries = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_ALL_COUNTRIES
  })

/**
 * Loads all industries
 */
export const loadAllIndustries = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_ALL_INDUSTRIES
  })

export const submitFeeback = ({ data }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: SAVE_FEEDBACK,
    variables: { data }
  })
