import { Box, Flex, Text, TextField } from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React from "react"
import ReactPlayer from "react-player"

import ClickableIcon from "../../../../ui/components/ClickableIcon"
import ValueBadge from "../../../../ui/components/ValueBadge"
import SaveAndCancel from "../../../components/save-and-cancel"
import SectionCard from "../../../components/section-card"

export default function VideoLink({ isLoading }) {
  const { errors, handleChange, setFieldValue, values } = useFormikContext()

  const { videos } = values

  const handleSave = () => {
    setFieldValue("videos", [...videos, values.videoLinkTemp])
    setFieldValue("videoLinkTemp", "")
  }

  const deleteVideo = link => {
    setFieldValue(
      "videos",
      values.videos.filter(i => i !== link)
    )
  }

  return (
    <SectionCard title="Add video links">
      <Text color="#173C5A" mb="10px">
        Add video links from YouTube and Vimeo that tell your audience more about your business, culture,
        purpose and values
      </Text>
      <TextField
        id="videoLinkTemp"
        name="videoLinkTemp"
        type="text"
        width={1 / 1}
        error={errors?.videoLinkTemp}
        value={values.videoLinkTemp}
        onChange={handleChange}
        label="Add URL"
      />
      <SaveAndCancel
        handleSave={handleSave}
        cancelDisable={!(values.countryId || values.city) || isLoading}
        saveDisable={!values.videoLinkTemp || isLoading}
      />
      <Flex flexDirection="row" flexWrap="wrap">
        {videos?.map((video, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={index} mt="12px" flexDirection="column">
              <Box ml="4px">
                <ReactPlayer url={video} light width="600px" height="300px" />
              </Box>

              <ValueBadge>
                <Text color="dark.2" width="auto" fontSize={3}>
                  {video}
                </Text>
                <ClickableIcon color="primary.0" name="close" onClick={() => deleteVideo(video)} />
              </ValueBadge>
            </Flex>
          )
        })}
      </Flex>
      {errors?.videos && (
        <Text fontSize={3} color="error.0">
          {errors?.videos}
        </Text>
      )}
    </SectionCard>
  )
}
