import { connect } from "react-redux"

import BottomControls from "../../components/EditBody/BottomControls"
import {
  setBranchingView,
  setFilterOnReport,
  setRequired,
  switchQuestionType
} from "../../store/template.actions"
import {
  getAvailableCoreQuestionIds,
  getShowBranching
} from "../../store/template.selectors"

const mapState = state => ({
  availableCoreQuestionIds: getAvailableCoreQuestionIds(state),
  showBranching: getShowBranching(state)
})

const mapDispatch = dispatch => ({
  setFilterOnReport: (filterStatus, questionId) =>
    dispatch(setFilterOnReport(filterStatus, questionId)),
  setRequired: (isRequired, questionId) =>
    dispatch(setRequired(isRequired, questionId)),
  switchQuestionType: (questionId, availableCoreQuestionIds) =>
    dispatch(switchQuestionType(questionId, availableCoreQuestionIds)),
  setShowBranching: payload => dispatch(setBranchingView(payload))
})

const BottomControlContainer = connect(mapState, mapDispatch)(BottomControls)

export default BottomControlContainer
