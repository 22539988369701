import React from "react"
import { Button, Text } from "@engaging-tech/components"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"

const TwoBtnDialogue = props => {
  const {
    noAction,
    dialogue = "",
    yesBtnText,
    noBtnText,
    yesAction,
    children,
    disableButton
  } = props

  return (
    <Flex
      width={1 / 1}
      bg="white"
      flexDirection="column"
      alignItems="center"
      py={3}
    >
      <Text textAlign="center" p={2}>
        {dialogue}
      </Text>
      {children}
      <Flex
        width={1 / 1}
        mb={2}
        justifyContent="space-around"
        alignItems="center"
      >
        <Button
          bg="secondary.0"
          color="light.0"
          onClick={yesAction}
          disabled={disableButton}
          width={1 / 3}
        >
          {yesBtnText}
        </Button>
        <Button
          variant="outline.primary.0"
          elevation={0}
          onClick={noAction}
          width={1 / 3}
        >
          {noBtnText}
        </Button>
      </Flex>
    </Flex>
  )
}

export default TwoBtnDialogue
