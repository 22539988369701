import { connect } from "react-redux"
import { surveyInfoActions, surveyInfoSelectors } from "../../surveyInfo"
import ReviewForm from "../forms/Review"
import { reset } from "../store/surveyInfoReview.actions"

const mapState = state => ({
  initialValues: {
    title: surveyInfoSelectors.getTitle(state),
    template: surveyInfoSelectors.getTemplate(state),
    mode: surveyInfoSelectors.getMode(state),
    recipients: surveyInfoSelectors.getRecipients(state),
    amount: surveyInfoSelectors.getAmount(state),
    startDate: surveyInfoSelectors.getStartDate(state),
    endDate: surveyInfoSelectors.getEndDate(state)
  },
  meta: surveyInfoSelectors.getMeta(state),
  id: surveyInfoSelectors.getSurveyId(state),
  surveyIsLoading: surveyInfoSelectors.getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onSubmit: payload => dispatch(surveyInfoActions.saveSurvey(payload)),
  onClose: () => {
    dispatch(surveyInfoActions.closeSurvey())
    dispatch(reset())
  }
})

const Review = connect(mapState, mapDispatch)(ReviewForm)

export default Review
