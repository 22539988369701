import { Box, Button, Flex, Text, TextField } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

const MagicLinkFormContent = ({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <TextField
          width={1 / 1}
          id="send-magic-link-email"
          borderRadius="4px 4px 0px 0px"
          name="email"
          type="email"
          label="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={touched.email && errors.email}
        />
      </Box>
      <Box mb={3}>
        <Text fontSize={[4, 3]}>Remembered your password?</Text>
        <Link to="/account/sign-in">
          <Text fontSize={[4, 3]} color="primary.0">
            {" "}
            Go back to sign in
          </Text>
        </Link>
      </Box>
      <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
        <Button
          variant="primary.0"
          type="submit"
          disabled={!isValid || isLoading}
          ml={2}
          width="auto"
        >
          Email Magic Link
        </Button>

        <Link to="/account/sign-up" mr="auto">
          <Button variant="text.noBg.primary.0">Create new account</Button>
        </Link>
      </Flex>
    </form>
  )
}

export default MagicLinkFormContent
