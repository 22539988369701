const experienceLevels = [
  "Internship",
  "Entry level",
  "Associate",
  "Mid level",
  "Senior level",
  "Director",
  "Executive"
]

export default experienceLevels
