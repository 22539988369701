import React, { useContext } from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import TemplateQuestionContext from "../TemplateQuestionContext"
import Option from "./Option"

const CustomFlex = styled(Flex)`
  height: inherit;
  flex-direction: column;
  padding-top: ${({ theme }) => `${theme.space[4]}px`};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme, isCore }) =>
      isCore ? theme.colors.secondary[0] : theme.colors.primary[0]};
    border-radius: 5px;
  }
`

const RadioQuestionPreview = () => {
  const { values, isCore } = useContext(TemplateQuestionContext)

  return (
    <CustomFlex isCore={isCore}>
      {values?.map(({ value, id }) => (
        <Option key={id} value={value} isCore={isCore}>
          <Text
            width="100%"
            color="dark.2"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {value}
          </Text>
        </Option>
      ))}
    </CustomFlex>
  )
}

export default RadioQuestionPreview
