import { useLocation, useParams, usePaths, useRouter } from "@engaging-tech/routing"
import { Formik } from "formik"
import React, { useEffect } from "react"
import { object, string } from "yup"

import { LoadingState } from "../../components"
import TitleAndTemplateView from "../views/TitleAndTemplate"

const TitleAndTemplateComponent = ({
  handleSubmit,
  handleChange,
  validateForm,
  values,
  errors,
  onClose,
  readOnly,
  surveyId,
  setFieldValue,
  ...props
}) => {
  const router = useRouter()
  const paths = usePaths()

  const handleFormSubmit = async route => {
    const res = await validateForm()
    if (Object.keys(res).length === 0) {
      setFieldValue("onSaveSuccess", () => router.navigate(route))
      handleSubmit()
    }
  }

  const actions = {
    next: {
      onClick: e => {
        e.preventDefault()
        e.stopPropagation()
        if (readOnly) {
          router.navigate(paths.surveys.viewSurvey.datesAndBranding.replace(":id", surveyId))
        } else {
          handleFormSubmit(paths.surveys.surveyCreation.datesAndBranding)
        }
      }
    },
    saveAndClose: {
      onClick: () => {
        setFieldValue("closeSurvey", true)
        handleFormSubmit(paths.surveys.draftSurveys)
      }
    },
    close: {
      onClick: () =>
        onClose() && router.navigate(readOnly ? paths.surveys.liveSurveys : paths.surveys.draftSurveys)
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <TitleAndTemplateView
        handleChange={handleChange}
        values={values}
        errors={errors}
        readOnly={readOnly}
        setFieldValue={setFieldValue}
        {...actions}
        {...props}
      />
    </form>
  )
}

const validationSchema = object().shape({
  title: string().required("A title is required for the survey"),
  introduction: string(),
  template: object()
    .shape({
      id: string().required("A template must be selected")
    })
    .required("A template must be selected")
})

const TitleAndTemplateForm = ({
  onSubmit,
  onLoad,
  onClose,
  onNotify: notify,
  readOnly,
  initialValues,
  status,
  isLoading,
  isSaving
}) => {
  const { id } = useParams()
  const router = useRouter()
  const paths = usePaths()
  const location = useLocation()

  useEffect(() => {
    if (id) {
      onLoad(id)
    }
  }, [id, onLoad])

  useEffect(() => {
    if (readOnly) {
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", ["[WFB][App][Surveys] Viewed survey"])
      }
    } else if (status) {
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", ["[WFB][App][Surveys] Edited survey"])
      }
    }
  }, [readOnly, status])

  useEffect(() => {
    if (location.pathname.substring(location.pathname.lastIndexOf("/"), location.pathname.length) !== "/edit")
      return
    if (status === "completed" || status === "live") {
      notify()
      setTimeout(() => router.redirect(paths.surveys.viewSurvey.index.replace(":id", id)), 500)
    }
  }, [id, location.pathname, notify, paths.surveys.viewSurvey.index, router, status])

  if (isSaving) return <LoadingState message="Updating survey meta data..." />

  if (isLoading) return <LoadingState message="Loading new meta data..." />

  const handleSubmit = values => {
    const obj = {
      survey: {
        title: values.title,
        template: values.template
      },
      closeSurvey: values.closeSurvey,
      onSaveSuccess: values.onSaveSuccess
    }

    const descriptionLines = values.introduction.match(/[^\r\n]+/g) || []

    obj.survey.introduction = descriptionLines.map(d => ({
      type: "paragraph",
      content: d
    }))

    onSubmit(obj)
  }

  const constructInitialValues = () => {
    let parameterTemplateId
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search)
      parameterTemplateId = urlParams.get("templateId")
    }

    if (!initialValues.template?.id && parameterTemplateId) {
      return {
        ...initialValues,
        template: { ...initialValues.template, id: parameterTemplateId }
      }
    }

    return initialValues
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={constructInitialValues()}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {props => (
        <TitleAndTemplateComponent
          readOnly={readOnly}
          onClose={onClose}
          surveyId={id}
          status={status}
          {...props}
        />
      )}
    </Formik>
  )
}

export default TitleAndTemplateForm
