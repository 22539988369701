export const types = {
  LOAD: "[templates.allSurveyJobRoles] Load All Survey Job Roles",
  LOAD_SUCCESS:
    "[templates.allSurveyJobRoles] Load All Survey Job Roles Success",
  LOAD_FAILURE:
    "[templates.allSurveyJobRoles] Load All Survey Job Roles Failure"
}

export const load = () => ({
  type: types.LOAD
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFailure = () => ({
  type: types.LOAD_FAILURE
})
