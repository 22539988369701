import { connect } from "react-redux"
import SiteBannerComponent from "../components/SiteBanner"
import { load } from "../store/cms.actions"
import { getBanner, getIsLoading } from "../store/cms.selectors"

const mapState = state => ({
  isLoading: getIsLoading(state),
  banner: getBanner(state)
})

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(load("WFB_BANNER"))
})

const SiteBanner = connect(mapState, mapDispatch)(SiteBannerComponent)

export default SiteBanner
