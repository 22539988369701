import React from "react"
import ContentBlock from "../../ui/components/ContentBlock"

const Banner = () => (
  <ContentBlock
    width={1 / 1}
    alignItems="center"
    flexDirection="column"
    bg="secondary.0"
    title="Set up your Survey"
    titleStyles={{
      fontSize: 8,
      fontWeight: 600,
      textAlign: "center",
      color: "white"
    }}
    description="Set up and send out your survey with WorkL`s straightforward 4 step
  process."
    descriptionStyles={{
      textAlign: "center",
      color: "white",
      mt: 4
    }}
  />
)

export default Banner
