import { Flex } from "@engaging-tech/components"
import React from "react"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import { REMOVE_FROM_BASKET } from "../../../graphql/mutations"
import {
  removeFromBasketSuccess,
  removeFromBasketFail
} from "../../../store/basket/basket.actions"
import Item from "./Item"

const Items = ({ items, currency }) => {
  const { isLoading, post } = useBusinessAppMutation(REMOVE_FROM_BASKET, {
    successAction: removeFromBasketSuccess,
    failAction: removeFromBasketFail
  })

  return (
    <Flex width={[1 / 1, 1 / 2]} flexDirection="column">
      {items?.map(item => (
        <Item
          key={item.productId}
          item={item}
          currency={currency}
          handleRemoveItem={post}
          isLoading={isLoading}
        />
      ))}
    </Flex>
  )
}

export default Items
