import React from "react"
import ThanksForJoiningComponent from "../../features/account/components/ThanksForJoining"
import GradientLayout from "../../layouts/GradientLayout"

const ThanksForJoining = () => {
  return (
    <GradientLayout>
      <ThanksForJoiningComponent />
    </GradientLayout>
  )
}

export default ThanksForJoining
