/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../lib/Apollo"
import {
  CREATE_LATEST_REPORT,
  DELETE_REPORTS,
  GENERATE_REPORT,
  GET_SURVEY_COMPARISON_DATA,
  LOAD,
  LOAD_REPORTS
} from "./report.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = ({ surveyId, directReports, filters, surveyType }) =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD,
    variables: {
      surveyType,
      id: surveyId,
      directReports,
      reportFilters: filters?.map(({ questionId, values }) => ({
        questionId,
        values: values.map(({ valueId }) => valueId)
      }))
    },
    errorPolicy: "all"
  })

export const deleteReports = reportIds =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: DELETE_REPORTS,
    variables: {
      reportIds
    }
  })

export const generateReport = ({ title, surveyIds }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: GENERATE_REPORT,
    variables: {
      title,
      surveyIds
    }
  })

export const loadReports = reportSearchFilters => {
  return apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_REPORTS,
    variables: { reportSearchFilters }
  })
}

export const createLatestReport = surveyType => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: CREATE_LATEST_REPORT,
    variables: {
      surveyType
    }
  })
}

export const getSurveyComparisonData = ({ surveyType, startDate, endDate, subTypes }) => {
  return apollo.query({
    uri: GRAPHQL_API_URL,
    query: GET_SURVEY_COMPARISON_DATA,
    variables: {
      surveyType,
      startDate,
      endDate,
      subTypes
    }
  })
}
