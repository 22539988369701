import React from "react"
import { SamlAuth as SamlAuthView } from "../../features/account"

import GradientLayout from "../../layouts/GradientLayout"

const SamlAccount = () => (
  <GradientLayout>
    <SamlAuthView />
  </GradientLayout>
)

export default SamlAccount
