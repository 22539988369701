/* eslint-disable no-nested-ternary */
import { createSelector } from "reselect"

export const TemplateState = state => state?.template

export const getName = createSelector(TemplateState, state => state?.name)

export const getIsDraft = createSelector(TemplateState, state => state?.isDraft)

export const getTemplateId = createSelector(
  TemplateState,
  state => state?.templateId
)

export const getOpenPopUpMenu = createSelector(
  TemplateState,
  state => state?.openPopUpMenu
)

export const getBespokeSteps = createSelector(
  TemplateState,
  state => state?.bespokeSteps
)

export const getViewOnly = createSelector(
  TemplateState,
  state => state?.viewOnly
)

export const getDescription = createSelector(
  TemplateState,
  state => state?.description
)

export const getPageErrors = createSelector(TemplateState, state => {
  const errors = []
  const questionsInOrder = state?.sections?.reduce(
    (acc, section) => acc.concat(section.questions),
    []
  )

  Object.keys(state?.pageErrors).forEach(key => {
    const keyName =
      state.sections.findIndex(obj => obj.sectionId === key) !== -1
        ? `section ${parseInt(key, 10) + 1}`
        : questionsInOrder.indexOf(key) !== -1
        ? `question ${questionsInOrder.indexOf(key) + 1}`
        : key

    Object.keys(state?.pageErrors[key]).forEach(subKey => {
      errors.push(`${keyName}, ${subKey}`)
    })
  })

  return errors
})

export const getSections = createSelector(
  TemplateState,
  state => state?.sections
)

export const getSaveTemplateError = createSelector(
  TemplateState,
  state => state?.saveTemplateErrors?.general
)

export const getQuestion = createSelector(
  TemplateState,
  (_, { questionId }) => questionId,
  (state, questionId) =>
    state?.questions?.filter(question => question.id === questionId)[0]
)

export const getAvailableCoreQuestions = createSelector(
  TemplateState,
  state => {
    const allAvailableQuestions = state?.sections?.reduce((acc, section) => {
      acc.push(...section.questions)
      return acc
    }, [])

    const availableHappinesQuestions =
      state?.coreQuestions?.questions?.happinessQuestions?.reduce(
        (acc, question) => {
          if (!allAvailableQuestions.includes(question.id)) acc.push(question)
          return acc
        },
        []
      ) || []

    const availableAdditionalQuestions =
      state?.coreQuestions?.questions?.additionalQuestions?.reduce(
        (acc, question) => {
          if (!allAvailableQuestions.includes(question.id)) acc.push(question)
          return acc
        },
        []
      ) || []

    return [...availableHappinesQuestions, ...availableAdditionalQuestions]
  }
)

export const getAvailableCoreQuestionIds = createSelector(
  getAvailableCoreQuestions,
  questions => questions?.map(question => question.id)
)

export const getSaveTemplateVariables = createSelector(TemplateState, state => {
  const buildDescription = description => {
    const splitDescription = description.split("\n")

    return splitDescription.reduce((acc, entry) => {
      if (entry.length > 0) {
        acc.push({
          content: entry,
          type: "text"
        })
      }

      return acc
    }, [])
  }

  return {
    surveyTemplateId: state?.templateId,
    surveyTemplate: {
      name: state?.name,
      description: state?.description,
      type: state?.type,
      workFromHome: state?.workFromHome,
      questions: state?.questions,
      branchingLogic: state?.branchingLogic,
      sections: state?.sections?.map(section => ({
        name: section.name,
        visible: section.visible,
        questions: section.questions,
        description: buildDescription(section.description)
      }))
    }
  }
})

export const getShowBranching = createSelector(
  TemplateState,
  state => state.showBranching
)

// Sorting happens here due to pre-populated questions
export const getAvailableTargetQuestions = createSelector(
  TemplateState,
  (_, { currentQuestionId }) => currentQuestionId,
  (state, currentQuestionId) => {
    const sectionIdx = state?.sections?.findIndex(s =>
      s.questions.includes(currentQuestionId)
    )

    const questions = state?.sections[sectionIdx].questions?.map(id => {
      const questionIdx = state.questions.findIndex(q => q.id === id)
      return state?.questions[questionIdx]
    })

    return questions
  }
)

export const getBranchingQuestions = createSelector(TemplateState, state => {
  const orderedQuestionIds = state.sections.reduce((acc, section) => {
    acc.push(...section.questions)
    return acc
  }, [])

  return orderedQuestionIds.reduce((acc, questionId) => {
    const questionIdx = state.questions.findIndex(q => q.id === questionId)
    if (
      !state.questions[questionIdx].isCore &&
      ["dropdown", "scale"].includes(state.questions[questionIdx].type)
    ) {
      acc.push(state.questions[questionIdx])
    }
    return acc
  }, [])
})

export const getNextSection = createSelector(
  TemplateState,
  (_, { currentQuestionId }) => currentQuestionId,
  (state, currentQuestionId) => {
    const sectionIdx = state?.sections?.findIndex(s =>
      s.questions.includes(currentQuestionId)
    )

    return state?.sections[sectionIdx + 1]
  }
)

export const getTarget = createSelector(
  TemplateState,
  (_, { currentChoiceId, currentQuestionId }) => ({
    currentChoiceId,
    currentQuestionId
  }),
  (state, props) => {
    const branchingLogicObj = state?.branchingLogic.filter(
      entry =>
        entry.choice === props.currentChoiceId &&
        entry.currentQuestion === props.currentQuestionId
    )[0]

    if (branchingLogicObj?.targetQuestion) {
      return state?.questions?.filter(
        q => q.id === branchingLogicObj.targetQuestion
      )[0]
    }

    if (branchingLogicObj?.targetSection) {
      return state?.sections?.filter(
        s => s.sectionId === branchingLogicObj.targetSection
      )[0]
    }

    const section = state?.sections?.filter(s =>
      s.questions.includes(props.currentQuestionId)
    )[0]
    const questionIdx = section.questions.indexOf(props.currentQuestionId)
    const nextQuestionId = section.questions[questionIdx + 1]

    return state?.questions?.filter(q => q.id === nextQuestionId)[0]
  }
)

export const getTargetAnswers = createSelector(
  TemplateState,
  (_, props) => props,
  (state, props) => {
    const availableLogic = state?.branchingLogic?.filter(
      b => b.choice === props.currentChoice.id
    )[0]

    if (availableLogic?.targetQuestion)
      return availableLogic?.targetAnswers || []

    return props.target?.values?.map(value => value.id)
  }
)

export const areAllAnswersAvaialble = createSelector(
  TemplateState,
  (_, props) => props,
  (state, props) => {
    const availableLogic = state?.branchingLogic?.filter(
      b => b.choice === props.currentChoice.id
    )[0]

    if (availableLogic?.targetQuestion) {
      const question = state?.questions?.filter(
        q => q.id === availableLogic.targetQuestion
      )[0]

      return ["scale", "radio", "short-text"].includes(question?.type)
        ? true
        : question.values.length === availableLogic.targetAnswers.length
    }

    return true
  }
)

// Returns custom question from template
export const getTemplateCustomQuestions = createSelector(TemplateState, state =>
  state?.questions?.reduce((acc, question) => {
    if (!question.isCore && question.type === "scale") acc.push(question)
    return acc
  }, [])
)

export const getIsLastQuestion = createSelector(
  [TemplateState, (_, props) => props],
  (state, props) => {
    const foundSection = state?.sections.filter(section =>
      section.questions.includes(props.currentQuestion.id)
    )[0]

    const { length } = foundSection.questions
    const idx = foundSection.questions.indexOf(props.currentQuestion.id)
    if (length === idx + 1) return true
    return false
  }
)
