import { Chip, Flex, Spinner, Text, Utils } from "@engaging-tech/components"
import { getOrientation } from "get-orientation/browser"
import { hideVisually } from "polished"
import React from "react"
import styled from "styled-components"

const HiddenInput = styled.input.attrs({ type: "file" })`
  ${hideVisually()}
`

const ChipCustom = styled(Chip)`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
}

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default function FilePickerNew({
  id,
  name,
  accept,
  imageUrl,
  error = "",
  disabled,
  onRemove,
  imageUploadIsLoading,
  setFileDataTemp,
  setLocalImageUrl,
  setImageToCrop
}) {
  const handleImageChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      // store file data
      setFileDataTemp(file)

      let imageDataUrl = await readFile(file)
      setLocalImageUrl(imageDataUrl)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await Utils.canvasHelpers.getRotatedImage(
          imageDataUrl,
          rotation
        )
      }

      // set image to crop
      setImageToCrop(imageDataUrl, file.name)
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey logo"])
      }
    }
  }

  if (imageUrl) {
    return (
      <Flex as="label">
        <ChipCustom
          ml={4}
          mr={4}
          trailingIcon="close"
          disabled={imageUploadIsLoading}
          onClick={e => {
            e.preventDefault()

            if (!imageUploadIsLoading) {
              onRemove(imageUrl)
            }
          }}
        >
          {name}
        </ChipCustom>
        {imageUploadIsLoading ? <Spinner size={32} color="primary.0" /> : null}
      </Flex>
    )
  }

  return (
    <Flex
      as="label"
      htmlFor={`upload-image-${id}`}
      onChange={handleImageChange}
    >
      <HiddenInput
        id={`upload-image-${id}`}
        name={name}
        accept={accept}
        error={error}
      />
      <Chip
        type="button"
        leadingIcon="cloud_upload"
        ml={4}
        color="primary.0"
        disabled={disabled}
        onClick={() => document.getElementById(`upload-image-${id}`).click()}
      >
        <Text color="dark.1" fontSize={3}>
          Upload .JPEG file
        </Text>
      </Chip>
    </Flex>
  )
}
