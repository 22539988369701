import { Button, Flex, Paragraph, Spinner } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"

import { SAVE_BASKET } from "../../../graphql/mutations"
import { FETCH_PRODUCTS } from "../../../graphql/queries"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import {
  saveBasketFail,
  saveBasketReq,
  saveBasketSuccess
} from "../../../store/basket/basket.actions"
import {
  getProductsFail,
  getProductsReq,
  getProductsSuccess
} from "../../../store/products/products.actions"
import ErrorState from "../../business-information/components/error-state"
import SliceAndDiceCard from "../../selfServiceSurveys/components/Page2/SliceAndDiceCard"

const SliceAndDice = ({
  handleGoBack,
  handleSubmit,
  sliceAndDiceProduct,
  awardSurveyId,
  hideContinueButton
}) => {
  const objectType = useRef(
    new URLSearchParams(window.location.search).get("inactive")
  )
  const [productSelectedId, setProductSelectedId] = useState(undefined)

  const sdProducts = [
    {
      id: 1,
      title: "Standard Data Insight Package",
      description: "This package includes:",
      infoTopics: [
        "Overall Engagement Score",
        "Indicators for D&I, Flight Risk and more",
        "Net Promoter Score",
        "Industry and Global benchmarking Data"
      ],
      priceCurrency: sliceAndDiceProduct?.currency,
      priceValue: 0
    },
    {
      id: 2,
      title: "Enhanced Data Insight Package",
      description: "Includes all Standard insights, plus:",
      infoTopics: [
        "Instant Action Planning",
        "Filter results by demographics",
        "Heatmaps",
        "Detailed action plans and supportive resources"
      ],
      priceCurrency: sliceAndDiceProduct?.currency,
      priceValue: sliceAndDiceProduct?.unit_amount
    }
  ]

  useEffect(() => {
    objectType.current = new URLSearchParams(window.location.search).get(
      "inactive"
    )
  }, [])

  const { isLoading, errors } = useBusinessAppQuery(FETCH_PRODUCTS, {
    initAction: getProductsReq,
    successAction: getProductsSuccess,
    failAction: getProductsFail,
    variables: {
      object_type: objectType.current || "slice-and-dice",
      surveyId: awardSurveyId
    }
  })

  const { errors: basketErrors, post } = useBusinessAppMutation(SAVE_BASKET, {
    initAction: saveBasketReq,
    successAction: saveBasketSuccess,
    failAction: saveBasketFail
  })

  const handleSelect = id => {
    setProductSelectedId(id)
  }

  const saveProductsInBasket = async () => {
    await post(
      {
        productId: sliceAndDiceProduct.product_details.id,
        priceId: sliceAndDiceProduct.id,
        object_type: "slice-and-dice"
      },
      () => dispatch(setAddedToBasket(sliceAndDiceProduct.name))
    )
    handleSubmit()
  }

  const handleNext = () => {
    if (productSelectedId) {
      if (productSelectedId === 2) {
        return saveProductsInBasket()
      }
      return handleSubmit()
    }
  }

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />
  if (errors || basketErrors) return <ErrorState />

  const canContinue = !hideContinueButton && productSelectedId

  return (
    <>
      <Paragraph
        textAlign="center"
        color="secondary.0"
        fontWeight="700"
        fontSize={6}
        mt={4}
      >
        Choose a data package
      </Paragraph>
      <Flex mt={5}>
        {sdProducts.map(product => (
          <SliceAndDiceCard
            key={`SliceAndDiceCard-${product.id}`}
            id={product.id}
            selected={productSelectedId === product.id}
            title={product.title}
            description={product.description}
            infoTopics={product.infoTopics}
            priceValue={product.priceValue}
            priceCurrency={product.priceCurrency}
            surveyValue={product.surveyValue}
            dataValue={product.dataValue}
            handleSelect={handleSelect}
          />
        ))}
      </Flex>
      <Flex justifyContent="space-between" mt={4}>
        <Button
          color="light.0"
          bg="primary.0"
          width="120px"
          onClick={handleGoBack}
        >
          BACK
        </Button>
        <Button
          color="light.0"
          bg={canContinue ? "primary.0" : "dark.2"}
          width="120px"
          onClick={handleNext}
          disabled={!canContinue}
        >
          NEXT
        </Button>
      </Flex>
    </>
  )
}

export default SliceAndDice
