import React from "react"

import JoinLeadsView from "../../features/account/views/JoinLeads"
import GradientLayout from "../../layouts/GradientLayout"

const JoinLeads = () => {
  return (
    <GradientLayout>
      <JoinLeadsView />
    </GradientLayout>
  )
}

export default JoinLeads
