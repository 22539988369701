import {
  Flex,
  H2,
  MotionToggle,
  Paragraph,
  Text
} from "@engaging-tech/components"
import React from "react"
import styled, { css } from "styled-components"
import { Card as CardComponent, TextField } from "../../components"

const Card = styled(CardComponent)`
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.dark[4]};
      cursor: not-allowed;
    `}
`

const Counter = ({ onChange, value, error, disabled, ...props }) => {
  return (
    <TextField
      onChange={onChange}
      value={value}
      error={error}
      name="shareableLinks"
      type="number"
      disabled={disabled}
      {...props}
    />
  )
}

const ShareableLinks = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  readOnly,
  ...props
}) => {
  if (readOnly && values && values.mode !== "shareableLinks") return <></>

  const handleModeChange = active => {
    if (active) {
      setFieldValue("mode", "shareableLinks")
    } else {
      setFieldValue("mode", "uniqueSubmission")
    }
  }

  return (
    <Card disabled={values && values.mode !== "shareableLinks"} {...props}>
      {readOnly ? (
        <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
          Invite participants via shareable link
        </H2>
      ) : (
        <Flex>
          <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
            Invite participants via shareable link
          </H2>
          <MotionToggle
            name="mode"
            type="radio"
            value={values && values.mode === "shareableLinks"}
            onChange={handleModeChange}
          />
        </Flex>
      )}
      <Paragraph>
        Please enter the number of shareable links you would like to be
        generated. Each participant should use one shareable link. Your
        shareable link will be generated on the next screen. If you don’t wish
        to send the survey via shareable please toggle this off.
      </Paragraph>
      {readOnly ? (
        <Text fontSize={20} mb={3}>
          <b>{values && values.amount}</b>
        </Text>
      ) : (
        <Counter
          onChange={e => {
            setFieldValue("amount", +e.target.value)
            if (typeof window !== "undefined" && window.hj) {
              window.hj("tagRecording", [
                "[WFB][App][Surveys] Changed sharable links amount"
              ])
            }
          }}
          value={values && values.amount}
          error={errors && errors.amount}
          disabled={values && values.mode !== "shareableLinks"}
          min={5}
          width="auto"
          fontSize={20}
        />
      )}
      <Paragraph>
        The shareable link can be used until you reach the number of shareable
        links that you have set in the box above.
      </Paragraph>
    </Card>
  )
}

export default ShareableLinks
