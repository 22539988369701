import { Button, Flex, Text } from "@engaging-tech/components"
import React, { useState } from "react"

import { Card } from "../../components"
import EditableField from "../../survey-view-mode/components/editable-field"
import TemplateOptionsWrapper from "../../surveyInfo/components/TemplateOptionsWrapper"

export default function TemplateFormField(props) {
  const [templateIsOpen, setTemplateIsOpen] = useState(false)

  return (
    <Card bg="white" mt="10px">
      {templateIsOpen ? (
        <>
          <TemplateOptionsWrapper
            {...props}
            onSelectTemplate={template => {
              props.setFieldValue("template", template)
              setTemplateIsOpen(false)
            }}
          />
          <Flex flexDirection="row-reverse" mt="10px">
            <Button variant="secondary.0" onClick={() => setTemplateIsOpen(false)}>
              Close
            </Button>
          </Flex>
        </>
      ) : (
        <EditableField heading="Survey template" onEdit={() => setTemplateIsOpen(true)}>
          <Flex alignItems={["flex-start", "center"]} flexDirection={["column", "row"]}>
            <Text fontWeight="bold" width="auto" mr={5} mb={[3, 0]}>
              <b>{props.values?.template.name}</b>
            </Text>
          </Flex>
        </EditableField>
      )}
    </Card>
  )
}
