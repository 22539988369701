import { connect } from "react-redux"
import NetPromoterScoreComponent from "../components/NetPromoterScore"
import * as Selectors from "../store/netPromoterScore.selectors"

const mapState = state => ({
  data: Selectors.getNetPromoterData(state),
  scoreRepartitionGraphData: Selectors.getScoreRepartitionGraphData(state)
})

const NetPromoterScore = connect(mapState)(NetPromoterScoreComponent)

export default NetPromoterScore
