/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { Text } from "@engaging-tech/components"
import { useFormik } from "formik"
import { object, string } from "yup"
import { TextField } from "../../../components"
import TemplateQuestionContext from "../TemplateQuestionContext"
import useFreeze from "../../lib/useFreeze"

const EditQuestionLabel = ({ setQuestionLabel, setPageErrors }) => {
  const { id, label } = useContext(TemplateQuestionContext)

  const formik = useFormik({
    initialValues: {
      label: useFreeze(label)
    },
    validationSchema: object({
      label: string().required("Cannot be empty")
    }),
    validateOnMount: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setQuestionLabel(formik.values.label, id)
      setPageErrors(formik.errors?.label, "label", id)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.label, formik.errors])

  return (
    <>
      <TextField
        onChange={formik.handleChange}
        value={formik.values.label}
        width={1 / 1}
        height={46}
        error={formik.errors?.label}
        noLabel
        id="label"
        placeholder="Question label"
        // iconButton={iconButton}
        // iconButtonProps={iconButtonProps}
        // onClick={onClick}
        // hoverText={hoverText}
        // hoverTextProps={hoverTextProps}
        // disableIconButton={disableIconButton}
      />
      <Text color="red" fontSize={2} ml={2} height="8px" width={1 / 1}>
        {formik.errors?.label}
      </Text>
    </>
  )
}

export default EditQuestionLabel
