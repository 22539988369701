/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { Text } from "@engaging-tech/components"
import { object, string } from "yup"
import { useFormik } from "formik"
import { TextField } from "../../../components"
import TemplateQuestionContext from "../TemplateQuestionContext"
import useFreeze from "../../lib/useFreeze"

const EditQuestionTitle = ({ setQuestionTitle, setPageErrors }) => {
  const {
    id,
    title,
    type,
    setOpenBespokeStep,
    openBespokeStep,
    customStepName
  } = useContext(TemplateQuestionContext)

  const formik = useFormik({
    initialValues: {
      title: useFreeze(title)
    },
    validationSchema: object({
      title: string().required("Cannot be empty")
    }),
    validateOnMount: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setQuestionTitle(formik.values.title, id)
      setPageErrors(formik.errors?.title, "title", id)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.title, formik.errors])

  if (type === "scale")
    return (
      <>
        <TextField
          onChange={formik.handleChange}
          value={formik.values.title}
          width={1 / 1}
          id="title"
          height={46}
          error={formik.errors?.title}
          noLabel
          placeholder="Question title"
          iconButton={customStepName ? "brush" : "add"}
          iconButtonProps={{
            right: "16px",
            size: 40,
            top: "3px"
          }}
          onClick={() => setOpenBespokeStep(true)}
          hoverText={
            customStepName
              ? `Bespoke Step: ${customStepName}`
              : "Add to Your Bespoke Steps"
          }
          hoverTextProps={{
            bottom: "-40px",
            right: "-30px"
          }}
          disableIconButton={openBespokeStep}
        />
        <Text color="red" fontSize={2} ml={2} height="8px">
          {formik.errors?.title}
        </Text>
      </>
    )

  return (
    <>
      <TextField
        onChange={formik.handleChange}
        value={formik.values.title}
        width={1 / 1}
        id="title"
        height={46}
        error={formik.errors?.title}
        noLabel
        placeholder="Question title"
      />
      <Text color="red" fontSize={2} ml={2} height="8px">
        {formik.errors?.title}
      </Text>
    </>
  )
}

export default EditQuestionTitle
