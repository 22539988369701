import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const hasMatrices = createSelector(
  ReportSelectors.getReportData,
  data => data && data.matrices && !!data.matrices.length
)

const pipePassedInProp = (state, prop) => prop

const formatMatricesData = matrix => ({
  ...matrix,
  values:
    matrix.values &&
    matrix.values.map(datum => ({
      ...datum,
      x: datum.x * 100,
      y: datum.y * 100
    }))
})

const extractAxisInfo = matrix => {
  const hasAxis = matrix && matrix.labels

  const xAxis = hasAxis && hasAxis.x
  const yAxis = hasAxis && hasAxis.y

  const xAxisLabels = xAxis && {
    questions: xAxis.questions,
    heading: xAxis.heading
  }
  const yAxisLabels = yAxis && {
    questions: yAxis.questions,
    heading: yAxis.heading
  }
  return {
    xAxisLabels,
    yAxisLabels
  }
}

const getAllMatrices = createSelector(
  ReportSelectors.getReportData,
  data => data && data.matrices && data.matrices.map(formatMatricesData)
)

export const getFirtstMatrix = createSelector(getAllMatrices, matrices =>
  matrices?.find(matrix => matrix.values && matrix.values.length)
)

export const getAllMatricesValues = createSelector(
  getAllMatrices,
  matrices => matrices && matrices.map(matrix => matrix.values)
)

const handleMatrixValues = matrix => {
  if (matrix && matrix.values) {
    const orangeSet = []
    const redSet = []
    const greenSet = []

    matrix.values.forEach(value => {
      if (value.x < 50 && value.y < 50) {
        redSet.push(value)
      }
      if ((value.x > 50 && value.y < 50) || (value.x < 50 && value.y > 50)) {
        orangeSet.push(value)
      }
      if (value.x > 50 && value.y > 50) {
        greenSet.push(value)
      }
    })
   
    return {
      datasets: [
        {
          label: "red",
          data: redSet.map(value => ({
            x: value.x,
            y: value.y,
            r: value.z,
            label: `Number of respondents: ${value.z}`
          })),
          backgroundColor: "rgba(239, 70, 74, 0.5)",
          borderColor: "rgb(239, 70, 74)"
        },
        {
          label: "orange",
          data: orangeSet.map(value => ({
            x: value.x,
            y: value.y,
            r: value.z,
            label: `Number of respondents: ${value.z}`
          })),
          backgroundColor: "rgba(255, 222, 195, 0.5)",
          borderColor: "rgb(255, 152, 0)"
        },
        {
          label: "green",
          data: greenSet.map(value => ({
            x: value.x,
            y: value.y,
            r: value.z,
            label: `Number of respondents: ${value.z}`
          })),
          backgroundColor: "rgba(13,193,135, 0.5)",
          borderColor: "rgb(13,193,135)"
        }
      ]
    }
  }
  return []
}
export const getFirstMatrixValues = createSelector(getFirtstMatrix, matrix =>
  handleMatrixValues(matrix)
)

export const getFirstMatrixBands = createSelector(
  getFirtstMatrix,
  matrix => matrix && matrix.bands
)

export const getFirstMatrixAxisLabels = createSelector(
  getFirtstMatrix,
  matrix => matrix && extractAxisInfo(matrix)
)

export const getMatrixByName = createSelector(
  [getAllMatrices, pipePassedInProp],
  (matrices, name) => matrices?.find(matrix => matrix.name === name)
)

export const getMatrixValues = createSelector(getMatrixByName, matrix =>
  handleMatrixValues(matrix)
)

export const getMatrixBands = createSelector(
  getMatrixByName,
  matrix => matrix && matrix.bands
)

export const getMatrixLabels = createSelector(
  getMatrixByName,
  matrix => matrix && extractAxisInfo(matrix)
)
export const getMatrixTitle = createSelector(
  getMatrixByName,
  matrix => matrix && matrix.title
)

export const getMatrixDescription = createSelector(
  getMatrixByName,
  matrix => matrix && matrix.description
)
