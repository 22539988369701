import { connect } from "react-redux"

import FeatureListComponent from "../components/FeatureList"
import {
  getFeatures,
  getWonAward,
  getHasBespokeSurveyLicence
} from "../../account/store/account.selectors"

const mapState = state => ({
  features: getFeatures(state),
  wonAward: getWonAward(state),
  bespokeSurveyLicence: getHasBespokeSurveyLicence(state)
})

const FeatureList = connect(mapState)(FeatureListComponent)

export default FeatureList
