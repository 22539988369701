import { Box, Flex, Spinner } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect } from "react"

import DOWNLOAD_ASSETS from "../../../graphql/queries/downloadAssets"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"
import ErrorState from "../../business-information/components/error-state"
import MultiRowCard from "../components/MultiRowCard"
import generateDOM, { DomText } from "../lib/generateDom"

const headers = {
  awardWin: <DomText name="Award Win" color="secondary.0" fontSize="16pt" fontWeight={600} />,
  winCategories: <DomText name="Win Categories" color="secondary.0" fontSize="16pt" fontWeight={600} />,
  action: <DomText name="Action" color="secondary.0" fontSize="16pt" fontWeight={600} />
}

const ViewAllLogos = ({ data }) => {
  const router = useRouter()
  const paths = usePaths()

  const {
    data: assetData,
    isLoading,
    errors,
    getData
  } = useAppSyncQuery(DOWNLOAD_ASSETS, {
    enabledOnMount: false
  })

  useEffect(() => {
    if (assetData) {
      const parsedData = JSON.parse(assetData.downloadAssets.body)
      const binaryData = atob(parsedData.zipData)
      const arrayBuffer = new ArrayBuffer(binaryData.length)
      const uint8Array = new Uint8Array(arrayBuffer)
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i)
      }

      const blob = new Blob([uint8Array], { type: "application/zip" })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = parsedData.zipFileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }, [assetData])

  const domData = generateDOM(data, router, paths, getData)

  if (errors) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" width={1 / 1} />
  return (
    <Flex width={1 / 1} justifyContent="center">
      <Box width={1 / 2}>
        <MultiRowCard rowData={headers} bg="#fafafa" />
        {domData?.map(rowData => (
          <MultiRowCard rowData={rowData} margin="10px 0px 0px 0px" />
        ))}
      </Box>
    </Flex>
  )
}

export default ViewAllLogos
