/**
 * Adjusts a parsed in date to a new date.
 * Parse +ve/-ve values as parameters to adjust, use the 0 value to leave as is.
 * @param {Date} date date to be adjusted
 * @param {Number} days days to be added/subtracted
 * @param {Number} months months to be added/subtracted
 * @param {Number} years years to be added/subtracted
 * @returns the new Date object
 */
const adjustDate = (date, days, months, years) => {
  date.setDate(date.getDate() + days)
  date.setMonth(date.getMonth() + months)
  date.setFullYear(date.getFullYear() + years)
  return date
}

export { adjustDate }
