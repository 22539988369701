import { Flex, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"
import { Tooltip } from "react-tooltip"

export default function AwardsTitle({ title, subTitle, status, id, width }) {
  const paths = usePaths()

  const surveyTitleLink = () => {
    return paths.surveys.viewSurvey.replace(":id", id).replace(":status", status)
  }

  return (
    <Flex
      flexDirection="column"
      width={["100%", "100%", width]}
      maxWidth={["100%", "100%", 400]}
      mb={[3, 3, 0]}
      pr={3}
    >
      <Link to={surveyTitleLink()}>
        <Text
          color="primary.0"
          fontWeight={500}
          pb={2}
          width="auto"
          data-tooltip-id="survey-awards-title-tooltip"
          data-tooltip-content="Click here to view or edit your survey"
        >
          {titleCaps(title)}
        </Text>
      </Link>
      <Tooltip id="survey-awards-title-tooltip" />
      {subTitle ? <Text width="auto">{subTitle}</Text> : null}
    </Flex>
  )
}
