/* eslint-disable react/no-array-index-key */
import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import ReportCard from "../../components/ReportCard"

const BottomBorderedFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  :last-child {
    border-bottom: none;
  }
`

const OverFlowFlex = styled(Flex)`
  max-height: 468px;
  overflow-y: auto;

  @media print {
    max-height: initial;
    overflow-y: initial;
  }
`

const QuestionBreakdown = ({ question, score }) => (
  <BottomBorderedFlex key={question} width="360px">
    <Text
      fontSize={4}
      mb={1}
      fontWeight={500}
      mr={3}
      width="270px"
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
      }}
    >
      {question}
    </Text>
    <Text width={50} fontWeight={700} fontSize={4}>{`${score}%`}</Text>
  </BottomBorderedFlex>
)

const QuestionsBreakdownOnePager = ({
  sixStepsQuestions,
  customScaleQuestions,
  isPdfDownloading
}) => {
  const six = []
  sixStepsQuestions.forEach(step =>
    step.questions.forEach(question => six.push(question))
  )
  let fullQuestionsArray = [...six, ...customScaleQuestions]
  fullQuestionsArray = fullQuestionsArray.map(question => {
    if (question.score === undefined) {
      return { ...question, score: Number(question.values.score) }
    }
    return { ...question }
  })

  fullQuestionsArray = fullQuestionsArray.sort((a, b) => b.score - a.score)
  fullQuestionsArray = fullQuestionsArray.slice(0, 5)

  return (
    <ReportCard
      heading="Questions Breakdown"
      width={[1 / 1]}
      isPdfDownloading={isPdfDownloading}
    >
      <OverFlowFlex flexDirection="column" pr={3}>
        {fullQuestionsArray.map((quest, i) => (
          <QuestionBreakdown
            key={i}
            question={quest.question}
            score={quest.score}
          />
        ))}
      </OverFlowFlex>
    </ReportCard>
  )
}

export default QuestionsBreakdownOnePager
