import { CustomHooks, Paragraph } from "@engaging-tech/components"
import React from "react"

import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import Branding from "../components/Branding/Branding"
import Date from "../components/Date"
import Privacy from "../components/Privacy"
import Rules from "../components/Rules"
import Nav from "../containers/Nav"

const DatesAndBranding = ({ values, errors, handleChange, surveyId, template, ...props }) => {
  CustomHooks.useFreshChatDeactivate(true)

  return (
    <CreationFormsLayout bg="#EEF4F9" maxWidth="1000px" mx={20} mb={7} BottomNav={() => <Nav {...props} />}>
      <Paragraph mt={5} fontSize={[4, 5]}>
        Next step is to customise your survey - pick the start and end date, add your company branding and
        select if you want participants to see their personal survey results.
      </Paragraph>
      <Date
        mb={5}
        template={template}
        values={values}
        errors={errors}
        handleChange={handleChange}
        {...props}
      />
      {["employeeStarter", "employeeExit"].includes(template?.type) && (
        <Rules values={values} errors={errors} handleChange={handleChange} mb={5} {...props} />
      )}
      <Branding
        mb={5}
        values={values}
        surveyId={surveyId}
        errors={errors}
        handleChange={handleChange}
        {...props}
      />
      <Privacy values={values} errors={errors} {...props} />
    </CreationFormsLayout>
  )
}

export default DatesAndBranding
