const dotProp = require("dot-prop-immutable")

const insertIndexFunction = ({ layout, insertIndex, itemToInsert }) => {
  const outputObject = {}
  const keys = Object.keys(layout)
  const keysEqualAbove = keys.filter(key => key >= insertIndex)
  const keysBelow = keys.filter(key => key < insertIndex)

  keysBelow.forEach(key => {
    outputObject[key] = layout[key]
  })

  outputObject[insertIndex] = itemToInsert

  keysEqualAbove.forEach(key => {
    outputObject[parseInt(key, 10) + 1] = layout[key]
  })

  return outputObject
}

const removeIndexFunction = ({ layout, removeIndex }) => {
  const outputObject = {}

  const keys = Object.keys(layout)
  const keysAbove = keys.filter(key => key > removeIndex)
  const keysBelow = keys.filter(key => key < removeIndex)

  keysBelow.forEach(key => {
    outputObject[key] = layout[key]
  })

  keysAbove.forEach(key => {
    outputObject[parseInt(key, 10) - 1] = layout[key]
  })

  return outputObject
}

const moveQuestionFunction = ({
  layout,
  sectionIndex,
  questionIndex,
  desiredQuestionIndex,
  desiredSectionIndex,
  direction,
  validationCheck = false
}) => {
  let localDesiredQuestionIndex = desiredQuestionIndex
  let localDesiredSectionIndex = desiredSectionIndex
  try {
    const outputLayout = { ...layout }

    const questionToMove = dotProp.get(
      layout,
      `${sectionIndex}.questions.${questionIndex}`
    )
    if (!questionToMove) {
      throw Error
    }

    // if no precise desired position data given, attempt to move based of direction given
    if (
      typeof localDesiredQuestionIndex !== "number" ||
      typeof localDesiredSectionIndex !== "number"
    ) {
      if (direction === "UP") {
        // if position available above within section

        if (questionIndex - 1 >= 0) {
          localDesiredSectionIndex = sectionIndex
          localDesiredQuestionIndex = questionIndex - 1
          // can not move up within section, move to above section
        } else if (layout[sectionIndex - 1]) {
          localDesiredSectionIndex = sectionIndex - 1
          localDesiredQuestionIndex = Object.keys(
            layout[sectionIndex - 1].questions
          ).length
          // no section available to move up into
        } else {
          throw Error
        }
      } else if (direction === "DOWN") {
        // if position available down within section

        if (
          questionIndex + 1 <
          Object.keys(layout[sectionIndex].questions).length
        ) {
          localDesiredSectionIndex = sectionIndex
          localDesiredQuestionIndex = questionIndex + 1
          // can not move down within section, move to below section
        } else if (layout[sectionIndex + 1]) {
          localDesiredSectionIndex = sectionIndex + 1
          localDesiredQuestionIndex = 0
          // no section available to move down into
        } else {
          throw Error
        }
      }
    }

    // If still no desired move location, cancel move
    if (
      typeof localDesiredQuestionIndex !== "number" ||
      typeof localDesiredSectionIndex !== "number"
    ) {
      throw Error
    }
    if (validationCheck) {
      return (
        typeof localDesiredQuestionIndex === "number" &&
        typeof localDesiredSectionIndex === "number"
      )
    }
    const removedQuestionSet = removeIndexFunction({
      layout: outputLayout[sectionIndex].questions,
      removeIndex: questionIndex
    })
    outputLayout[sectionIndex].questions = removedQuestionSet

    const addedQuestionSet = insertIndexFunction({
      layout: outputLayout[localDesiredSectionIndex].questions,
      insertIndex: localDesiredQuestionIndex,
      itemToInsert: questionToMove
    })

    outputLayout[localDesiredSectionIndex].questions = addedQuestionSet

    return outputLayout
  } catch {
    if (validationCheck) {
      return false
    }
    return layout
  }
}

const moveSectionFunction = ({
  layout,
  sectionIndex,
  desiredSectionIndex,
  direction,
  validationCheck = false
}) => {
  let localDesiredSectionIndex = desiredSectionIndex
  try {
    let outputLayout = { ...layout }

    const sectionToMove = layout[sectionIndex]

    if (!sectionToMove) {
      throw Error
    }

    // if no precise desired position data given, attempt to move based of direction given
    if (typeof localDesiredSectionIndex !== "number") {
      if (direction === "UP") {
        // if position available above within section
        if (sectionIndex - 1 >= 0) {
          localDesiredSectionIndex = sectionIndex - 1

          // unable to move section up
        } else {
          throw Error
        }
      } else if (direction === "DOWN") {
        // if position available down within section
        if (layout[sectionIndex + 1]) {
          localDesiredSectionIndex = sectionIndex + 1

          // unable to move section down
        } else {
          throw Error
        }
      }
    }

    // If still no desired move location, cancel move
    if (typeof localDesiredSectionIndex !== "number") {
      throw Error
    }
    if (validationCheck) {
      return typeof localDesiredSectionIndex === "number"
    }
    const removedSectionSet = removeIndexFunction({
      layout: outputLayout,
      removeIndex: sectionIndex
    })
    outputLayout = removedSectionSet

    const addedSectionSet = insertIndexFunction({
      layout: outputLayout,
      insertIndex: localDesiredSectionIndex,
      itemToInsert: sectionToMove
    })

    outputLayout = addedSectionSet

    return outputLayout
  } catch {
    if (validationCheck) {
      return false
    }
    return layout
  }
}

export default {
  moveQuestion: moveQuestionFunction,
  moveSection: moveSectionFunction,
  insertIndex: insertIndexFunction,
  removeIndex: removeIndexFunction
}
