import { createSelector } from "reselect"

export const getGenericState = state =>
  state["surveyManagement.templates.generic"]

export const getCurrentEditableQuestion = createSelector(
  getGenericState,
  state => state.currentEditableQuestion
)

export const getShowValidationModal = createSelector(
  getGenericState,
  state => state.showValidationModal
)

export const getShowBranching = createSelector(
  getGenericState,
  state => state.showBranching
)
