import format from "date-fns/format"
import React from "react"

import SixStepsEditButton from "../components/SixStepsEditButton"

export const tableHeadings = [
  {
    id: "title",
    title: "Six Steps Action Plans"
  },
  {
    id: "updatedAt",
    title: "Last edited"
  },
  {
    id: "edit",
    title: "Edit"
  }
]

export const tableConfig = {
  stylesPerColumn: [
    {
      height: "45px",
      px: "15px",
      py: "0px",
      fontSize: 4
    },
    {
      height: "45px",
      pr: "15px",
      py: "0px",
      fontSize: 4
    },
    {
      height: "45px",
      px: "15px",
      py: "0px",
      fontSize: 4
    }
  ],
  columnWidths: ["70%", "auto", "100px"],
  main: {
    textAlign: "left"
  },
  headingPerColumn: [
    {
      py: "15px",
      px: "15px",
      fontSize: "16px",
      fontWeight: 600,
      color: "#282727"
    },
    {
      py: "15px",
      pr: "10px",
      fontSize: "16px",
      fontWeight: 600,
      color: "#282727"
    },
    {
      py: "15px",
      px: "10px",
      fontSize: "16px",
      fontWeight: 600,
      color: "#282727",
      textAlign: "center"
    }
  ],
  data: {
    borderTop: "1px solid",
    borderColor: "dark.4"
  }
}

export const addTableElements = (steps, handleEditClick) => {
  const rows = steps?.map(step => {
    const updatedTS = step?.updatedAt
      ? format(new Date(step.updatedAt), "HH:mm dd/MM/yyyy")
      : null

    return {
      ...step,
      updatedAt: updatedTS,
      edit: (
        <SixStepsEditButton
          id={step.id}
          title={step?.title || ""}
          handleEditClick={handleEditClick}
        />
      )
    }
  })

  return rows
}
