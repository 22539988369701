import { connect } from "react-redux"
import { setGenerateReport } from "../../store/report.actions"
import {
  getAllReports,
  getReportsToGenerateFrom
} from "../../store/report.selectors"
import EmployeeReportsList from "../components/EmployeeReportsList"

const mapState = state => ({
  reports: getAllReports(state),
  selectedReports: getReportsToGenerateFrom(state)
})

const mapDispatch = dispatch => ({
  setReportToBeSelected: reportArray => dispatch(setGenerateReport(reportArray))
})

const EmployeeReportListGenerateContainer = connect(
  mapState,
  mapDispatch
)(EmployeeReportsList)

export default EmployeeReportListGenerateContainer
