/* eslint-disable no-console */
import { connect } from "react-redux"
import {
  getNextSection,
  getAvailableTargetQuestions,
  getTarget,
  areAllAnswersAvaialble,
  getIsLastQuestion
} from "../../store/template.selectors"
import { setBranchingLogic } from "../../store/template.actions"
import ManageChoice from "../../components/Branching/ManageChoice"

const mapState = (state, { currentQuestion, currentChoice }) => ({
  nextSection: getNextSection(state, { currentQuestionId: currentQuestion.id }),
  availableTargetQuestions: getAvailableTargetQuestions(state, {
    currentQuestionId: currentQuestion.id
  }),
  target: getTarget(state, {
    currentChoiceId: currentChoice.id,
    currentQuestionId: currentQuestion.id
  }),
  areAllAnswersAvailable: areAllAnswersAvaialble(state, {
    currentChoice,
    currentQuestion
  }),
  isLastQuestion: getIsLastQuestion(state, { currentQuestion })
})

const mapDispatch = dispatch => ({
  setBranchingLogic: (choice, questionId, target, targetAnswers) =>
    dispatch(setBranchingLogic(choice, questionId, target, targetAnswers))
})

const ManageChoiceContainer = connect(mapState, mapDispatch)(ManageChoice)

export default ManageChoiceContainer
