import { Flex, Text } from "@engaging-tech/components"
import { Spinner } from "@engaging-tech/components"
import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useParams, usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { SAVE_ACTION_PLAN_STEP } from "../../../../graphql/mutations"
import useAppSyncMutation from "../../../../hooks/useAppSyncMutation"
import ActionPlanEditButtons from "../ActionPlanEditButtons"

const StickyHeader = styled(Flex)`
  top: 0px;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.light[0]};
`

const EditActionPlanHeader = ({
  handleOpenPreviewModal,
  postData,
  canSendForm
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const router = useRouter()
  const paths = usePaths()
  const { post, isLoading } = useAppSyncMutation(SAVE_ACTION_PLAN_STEP)

  const handleDiscard = () =>
    router.navigate(paths.actionPlans.viewActionPlan.replace(":id", id))

  const handleSave = () => {
    if (canSendForm)
      post(
        {
          input: postData
        },
        () =>
          dispatch(
            userInterfaceNotificationsStore.actions.addNotification({
              message: "Saved successfully"
            })
          )
      )
  }
  return (
    <StickyHeader
      width={1 / 1}
      justifyContent="space-between"
      position="sticky"
      pt={4}
      pb={2}
    >
      <Text width="fit-content" fontSize={6} fontWeight={600}>
        {postData?.title}
      </Text>
      {isLoading ? (
        <Spinner color="primary.0" justifyContent="right" width={1 / 2} />
      ) : (
        <ActionPlanEditButtons
          handleDiscard={handleDiscard}
          handleOpenPreviewModal={handleOpenPreviewModal}
          handleSave={handleSave}
          canSendForm={canSendForm}
        />
      )}
    </StickyHeader>
  )
}

export default EditActionPlanHeader
