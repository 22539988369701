import { createSelector } from "reselect"

export const awardsState = state => state && state.awards

export const getAwardsType = createSelector(
  awardsState,
  state => state?.awardsType
)

export const getAwardsDisclaimer = createSelector(
  awardsState,
  state => state?.awards?.data?.disclaimer
)

export const getAwardsEndDate = createSelector(
  awardsState,
  state => state?.awards?.data?.endDate
)

export const getAwardsTitle = createSelector(
  awardsState,
  state => state?.awards?.data?.title
)

export const getShareableLinks = createSelector(
  awardsState,
  state => state?.survey?.data?.shareableLinks
)

export const getAwardContactInfo = createSelector(
  awardsState,
  state => state?.awards?.data?.contactInfo
)

export const getParticipations = createSelector(
  awardsState,
  state => state?.awards?.data.participations
)

export const getAwardsCurrency = createSelector(
  awardsState,
  state => state?.awards?.data?.country?.currency
)

export const getSurveyId = createSelector(
  awardsState,
  state => state?.survey?.data?.id
)

export const getSliceAndDiceItp = createSelector(
  awardsState,
  state => state?.survey?.data?.sliceAndDiceItp
)

export const getTemplateName = createSelector(
  awardsState,
  state => state?.survey?.data?.templateName
)

export const getPageErrors = createSelector(
  awardsState,
  state => state?.pageErrors
)

export const getEndDate = createSelector(
  awardsState,
  state => state?.survey?.data?.endDate
)

export const getStartDate = createSelector(
  awardsState,
  state => state?.survey?.data?.startDate
)

export const getEmployeeCount = createSelector(
  awardsState,
  state => state?.survey?.data?.employeeCount
)

export const getIntroduction = createSelector(
  awardsState,
  state => state?.survey?.data?.introduction
)

export const getTitle = createSelector(
  awardsState,
  state => state?.survey?.data?.title
)

export const getCurrentPage = createSelector(
  awardsState,
  state => state?.survey?.data?.page
)

export const getPrivate = createSelector(
  awardsState,
  state => state?.survey?.data?.private
)

export const generateNextPage = createSelector(getCurrentPage, page => page + 1)
