import { Flex, RichTextEditor, Text } from "@engaging-tech/components"
import React from "react"

const SuggestedPhrases = ({
  suggestedPhrases,
  setSuggestedPhrases,
  stepQuestionId
}) => {
  const parsedSuggestedPhrases = !!suggestedPhrases
    ? JSON.parse(suggestedPhrases)
    : [
        {
          type: "paragraph",
          children: [
            {
              text: ""
            }
          ]
        }
      ]

  return (
    <Flex flexDirection="column">
      <Text fontSize={5} fontWeight={600} mt={5} mb={3}>
        Suggested Phrases
      </Text>
      <RichTextEditor
        handleChange={value => setSuggestedPhrases(value, stepQuestionId)}
        initialValue={parsedSuggestedPhrases}
      />
    </Flex>
  )
}

export default SuggestedPhrases
