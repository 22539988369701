/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../../lib/Apollo"
import { PURCHASE_CREDITS, SCHEDULE_SURVEY } from "./surveyInfoReview.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const purchaseCredits = payload =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: PURCHASE_CREDITS,
    variables: payload
  })

export const scheduleSurvey = surveyId =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: SCHEDULE_SURVEY,
    variables: { surveyId }
  })
