import { Flex, IconButton, Paragraph, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.secondary[0]};
  font-weight: 400;
  text-decoration: underline;
`

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.dark[3]};
  font-weight: 600;
`

const ViewResource = ({
  resource,
  deleteResource,
  resourceIndex,
  editableIndexes,
  stepQuestionId,
  setEditableIndexes
}) => {
  const handleDelete = () => {
    setEditableIndexes(editableIndexes.map(num => num - 1))
    deleteResource(resourceIndex, stepQuestionId)
  }

  return (
    <Flex height="330px" width="100%" bg="dark.4" p={4}>
      <Flex flexDirection="column">
        <Flex px={4}>
          {resource?.mediaType ? (
            <StyledSpan>
              {resource?.mediaType} -{" "}
              <StyledAnchor href={resource?.url}>
                {resource?.title}
              </StyledAnchor>
            </StyledSpan>
          ) : (
            <Text>
              <StyledAnchor href={resource?.url}>
                {resource?.title}
              </StyledAnchor>
            </Text>
          )}
        </Flex>
        <Flex mt={3} px={4}>
          <Paragraph color="secondary.0">{resource?.description}</Paragraph>
        </Flex>
      </Flex>

      <IconButton icon="delete" onClick={handleDelete} type="button" />
    </Flex>
  )
}

export default ViewResource
