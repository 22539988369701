import { Box, H2, List, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import { Card } from "../../components"

const StyledListWrapper = styled(List.Wrapper)`
  background: ${({ theme }) => theme.colors.primary[2]};
  border-radius: 10px;
  padding: ${({ theme }) => theme.space[4]}px;

  & > li {
    list-style-type: none;
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }

  & > li:before {
    content: "- ";
    margin-top: 0;
  }
`

const ParticipantsInfo = ({ ...props }) => (
  <Card {...props}>
    <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
      Survey Participant Information
    </H2>
    <Box>
      <StyledListWrapper my={0}>
        <Text>Please note:</Text>
        <List.Item>
          You cannot send a survey to fewer than 5 participants
        </List.Item>
        <List.Item>
          At least 5 participants will need to take the survey for results to
          show on your reports tab
        </List.Item>
        <List.Item>
          You should encourage as many participants to take the survey as
          possible to ensure accurate and meaningful results are obtained
        </List.Item>
        <List.Item>1 email or 1 shareable link = 1 credit</List.Item>
      </StyledListWrapper>
    </Box>
  </Card>
)

export default ParticipantsInfo
