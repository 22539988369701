import { gql } from "../../../../../lib/Apollo"

export const GET_ALL_SURVEY_JOB_ROLES= gql`
  query {
    allSurveyJobRoles {
      id
      name
    }
  }
`
