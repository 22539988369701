import React from "react"
import { MotionFlex, Flex } from "@engaging-tech/components"

const ConnectionLine = ({
  width,
  length,
  delay = 0,
  bg,
  duration = 0.3,
  ...props
}) => {
  return (
    <Flex width="auto" height="auto" {...props}>
      <MotionFlex
        height={width}
        initial={{ width: 0 }}
        bg={bg}
        animate={{ width: length, transition: { delay, duration } }}
        exit={{ width: 0 }}
        position="absolute"
      />
    </Flex>
  )
}

export default ConnectionLine
