import { MotionFlex, CustomHooks } from "@engaging-tech/components"

import React from "react"

import CreditsAnimatedIllustration from "./CreditsAnimatedIllustration"

const StepTwoAnimatedWrapper = ({}) => {
  const device = CustomHooks.useClientDevice()
  const dimensions = (() => {
    switch (device) {
      case "DESKTOP":
        return {
          width: 430,
          height: 260
        }
      case "TABLET":
        return {
          width: 430,
          height: 240
        }
      case "MOBILE":
        return {
          width: 340,
          height: 220
        }
      default:
        return {
          width: 430,
          height: 260
        }
    }
  })()

  return (
    <MotionFlex
      width="100%"
      height="100%"
      key={3}
      pl={10}
      mt={20}
      alignItems="flex-start"
      justifyContent="center"
      initial={{ opacity: 0, transition: { duration: 0.3 } }}
      animate={{
        opacity: 1,
        transition: { delay: 0.5, duration: 0.3 }
      }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <CreditsAnimatedIllustration
        width={dimensions.width}
        height={dimensions.height}
      />
    </MotionFlex>
  )
}

export default StepTwoAnimatedWrapper
