import { connect } from "react-redux"
import { ReportSelectors, ReportActions } from "../../index"
import SubtypesModal from "../components/SubtypesModal"

const mapState = state => ({
  subCheckedList: ReportSelectors.getSubCheckedList(state)
})

const mapDispatch = dispatch => ({
  setSubCheckedList: subCheckedList =>
    dispatch(ReportActions.setSubCheckedList(subCheckedList))
})

const SubtypesModalContainer = connect(mapState, mapDispatch)(SubtypesModal)

export default SubtypesModalContainer
