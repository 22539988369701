/* eslint-disable import/no-dynamic-require */
import {
  Box,
  Card,
  Flex,
  MobileOnly, // Button
  TabletUp,
  Text
} from "@engaging-tech/components"
import React from "react"
// import { getConfig } from "@engaging-tech/ssr-config"
// import { usePaths, Link } from "@engaging-tech/routing"
import styled from "styled-components"

import IconCircle from "../../ui/components/CircleIcon"
import ServiceHelperText from "../../ui/components/ServiceHelperText"
import LeadsGenerationCard from "./LeadGenerationCard"

const leadImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/lead-generation-image.png`)
const expertAdviceImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/ask-an-expert.png`)
const mobileImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/LeadGenBoth.png`)
// const notEBNLeadsImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/Leads.png`)

const LeadImage = styled.img`
  margin-top: 10px;
`
const AdviceImage = styled.img`
  margin: 20px;
`

// const BGImageBox = styled(Box)`
//   background-image: url(${notEBNLeadsImage});
//   background-repeat: no-repeat;
//   background-position: 90% 90%;
//   height: 500px;

//   @media only screen and (max-width: 830px) {
//     background-size: ${470 * 0.9}px ${440 * 0.9}px;
//     background-position: 50% 100%;
//     height: 700px;
//   }
//   @media only screen and (max-width: 600px) {
//     background-size: ${470 * 0.55}px ${440 * 0.55}px;
//     background-position: 50% 100%;
//     height: 600px;
//   }
// `

// const NotSubscrbedEmptyState = () => {
//   const config = getConfig()
//   const paths = usePaths()

//   return (
//     <Box px={[3, 3, 4]}>
//       <Text color="dark.2">
//         Discover leads so you can sell your services to happy customers
//       </Text>
//       <BGImageBox
//         width="100%"
//         justifyContent="center"
//         mt={5}
//         bg="dark.4"
//         p={[3, 5]}
//       >
//         <Flex
//           flexDirection={["column", "row"]}
//           width="100%"
//           justifyContent="space-between"
//           mx={[0, 4]}
//         >
//           <Box maxWidth={500}>
//             <Flex flexDirection="row" width={1 / 1} mb={3} mt={2}>
//               <IconCircle name="question_answer" mr={3} />
//               <Text fontSize={5} fontWeight={500} mt={2} width={1 / 1}>
//                 Connect With People Needing Expert Advice
//               </Text>
//             </Flex>
//             <Text
//               color="dark.2"
//               fontSize={4}
//               width={1}
//               display="block"
//               mb={3}
//               fontWeight={500}
//             >
//               Connect with thousands of individuals on our platform{" "}
//               <Link external newTab to={config.workL.app}>
//                 <Text color="primary.0" fontSize="16px">
//                   WorkL
//                 </Text>
//               </Link>{" "}
//               who are asking for expert advice in your field via the social feed{" "}
//               <Link to={`${config.workL.app}/global-hub`} external newTab>
//                 <Text fontSize={16} color="primary.0">
//                   Global Hub
//                 </Text>
//               </Link>
//               .
//             </Text>
//             <Text
//               color="dark.2"
//               fontSize={4}
//               width={1}
//               display="block"
//               mb={4}
//               fontWeight={500}
//             >
//               Follow unlimited leads when you sign up to the WorkL For Business
//               Network, or purchase Leads for £50 a month plus VAT.
//             </Text>
//             <Link to={paths.account.joinLeads}>
//               <Button variant="primary.0" width={["auto", 180]}>
//                 Follow Leads
//               </Button>
//             </Link>
//           </Box>
//         </Flex>
//       </BGImageBox>
//     </Box>
//   )
// }

const HELPER_TEXT_ITEMS = [
  "Choose a lead to follow from the list below",
  "View lead on the WorkL Global Hub",
  "Leave a comment in the post to connect with an individual"
]

const LeadGenerationList = ({ liveLeads }) => {
  if (liveLeads.length === 0) {
    return (
      <Box px={[3, 3, 4]}>
        <Text color="dark.2">
          There are currently no leads. Check back here soon!
        </Text>
        <Flex width="100%" justifyContent="center" mt={5}>
          <Box width={["auto", "955px"]}>
            <Box bg="dark.4" p={[3, 5]}>
              <Flex
                flexDirection={["column", "row"]}
                width="100%"
                justifyContent="space-between"
                mx={[0, 4]}
              >
                <Flex flexDirection="column">
                  <Flex flexDirection="row" width={1 / 1} mb={3} mt={2}>
                    <IconCircle name="question_answer" mr={3} />
                    <Text fontSize={4} fontWeight={500} mt={2} width={1 / 1}>
                      Connect with people who need your services
                    </Text>
                  </Flex>
                  <Text ml={1} color="dark.1" fontSize={4}>
                    Receive notifications and connect with our tens of thousands
                    of WorkL users (our platform for individuals) asking for
                    expertise in your field via the social feed Global Hub.
                  </Text>
                  <TabletUp width="100%">
                    <Flex width="100%" justifyContent="center" mt={2}>
                      <AdviceImage
                        src={expertAdviceImage}
                        alt=""
                        width="345px"
                      />
                    </Flex>
                  </TabletUp>
                </Flex>
                <MobileOnly width="100%">
                  <Flex width="100%" justifyContent="center" my={4}>
                    <img src={mobileImage} alt="" width="100%" />
                  </Flex>
                </MobileOnly>
                <TabletUp width="55%">
                  <Flex width="100%" justifyContent="center">
                    <LeadImage src={leadImage} alt="" width="214px" />
                  </Flex>
                </TabletUp>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <Flex flexDirection={["column", "column", "row"]} px={[3, 3, 4]}>
        <Text color="dark.2">
          Discover leads so you can sell your services to happy customers
        </Text>
      </Flex>
      <Flex mt={3} flexDirection="column" px={[3, 3, 4]}>
        <ServiceHelperText
          mb={[0, 3]}
          title="Follow a Lead:"
          items={HELPER_TEXT_ITEMS}
        />
        <Card mb={3} px={[3, 4]} py={[0, 0, 2]}>
          {liveLeads.map(leadItem => (
            <LeadsGenerationCard key={leadItem.hubPostId} leadItem={leadItem} />
          ))}
        </Card>
      </Flex>
    </>
  )
}

export default LeadGenerationList
