import React from "react"
import styled from "styled-components"
import { Text, Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { format } from "date-fns"

const CardContent = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};

  &:last-child {
    border-bottom: none;
  }
`

const transformDate = strDate => {
  const date = new Date(strDate)
  return `Published ${format(date, "MMMM do yyyy h:mm a")}`
}

const getLocation = location => {
  const { country, city } = location
  return `${city}, ${country.name}`
}

const LinkText = styled(Text)`
  text-transform: uppercase;
`

const getLeadUrl = leadItem =>
  `${getConfig().workL.app}/global-hub/${leadItem.hubPostId}`

const LeadCard = ({ leadItem }) => (
  <CardContent
    flexDirection={["column", "row"]}
    py={[4, 3]}
    alignItems="center"
  >
    <Flex flexDirection="column" mb={[4, 0]}>
      <Text fontSize={4}>{leadItem.expertType.name}</Text>
      <Text fontSize={3} color="dark.2">
        {getLocation(leadItem.location)}
      </Text>
      <Text fontSize={3} color="dark.2">
        {transformDate(leadItem.publishedAt)}
      </Text>
    </Flex>
    <Flex px={[0, 4]} mb={[4, 0]}>
      <Text fontSize={3} color="dark.2">
        {leadItem.question}
      </Text>
    </Flex>
    <Flex justifyContent={["flex-start", "flex-end"]} px={[0, 6]}>
      <a target="_blank" rel="noopener noreferrer" href={getLeadUrl(leadItem)}>
        <LinkText fontSize={3} fontWeight="500" color="primary.0">
          follow lead
        </LinkText>
      </a>
    </Flex>
  </CardContent>
)

export default LeadCard
