import React from "react"
import { CustomHooks, Flex, Utils } from "@engaging-tech/components"

const Option = ({ isCore, children }) => {
  const theme = CustomHooks.useStyledTheme()
  const color = isCore ? "secondary.0" : "primary.0"
  const borderColor = Utils.colorStringToTheme(theme, color)
  const optionCircleSize = 22
  return (
    <Flex
      width="100%"
      maxWidth={600}
      alignItems="center"
      justifyContent="center"
      my={2}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        borderRadius={99}
        border={`3px solid ${borderColor}`}
        width={optionCircleSize}
        height={optionCircleSize}
        minHeight={optionCircleSize}
        minWidth={optionCircleSize}
        mr={3}
      />
      {children}
    </Flex>
  )
}
export default Option
