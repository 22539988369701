import { createSelector } from "reselect"

export const templateSelectionState = state =>
  state && state["surveyManagement.templateSelection"]

export const getIsLoading = createSelector(
  templateSelectionState,
  state => state?.isLoading
)

export const getHasError = createSelector(
  templateSelectionState,
  state => state?.hasError
)

export const getDefaultTemplates = createSelector(
  templateSelectionState,
  state => state?.defaultTemplates
)

export const getDefaultTemplateType = createSelector(
  getDefaultTemplates,
  (_, id) => id,
  (state, id) => state?.find(data => data.id === id)?.type
)

export const getOrgTemplates = createSelector(
  templateSelectionState,
  state => state?.orgTemplates
)

export const getPermementTemplates = createSelector(getOrgTemplates, state =>
  state?.filter(template => !template.isDraft)
)

export const getDraftTemplates = createSelector(getOrgTemplates, state =>
  state?.filter(template => template.isDraft)
)

export const getOrgTemplateType = createSelector(
  getOrgTemplates,
  (_, id) => id,
  (state, id) => state?.find(data => data.id === id)?.type
)
