import { createSelector } from "reselect"
import { ReportSelectors } from "../.."
import { AccountSelectors } from "../../../account"
import createDatasetArray from "../../../utils/createDatasetArr"

const sixStepsLookup = {
  reward_recognition: "Reward & Recognition",
  information_sharing: "Information Sharing",
  empowerment: "Empowerment",
  well_being: "Wellbeing",
  instilling_pride: "Instilling Pride",
  job_satisfaction: "Job Satisfaction"
}
const labels = {
  score: "Your Score",
  global: "Global",
  industry: "Industry",
  rootReport: "Overall"
}

export const getSixStepsScores = createSelector(
  ReportSelectors.getReportData,
  scores => scores?.sixStepsComparison
)

export const getSixStepsComparisonScores = createSelector(
  [getSixStepsScores, AccountSelectors.getOrganisation],
  (sixStepsScores, org) => {
    if (sixStepsScores) {
      return {
        labels: sixStepsScores?.map(score => sixStepsLookup[score.name]),
        datasets: createDatasetArray(sixStepsScores).map(t => ({
          label: t === "rootReport" ? org?.name : labels[t],
          data: sixStepsScores?.map(score => score[t])
        }))
      }
    }

    return {
      labels: false,
      data: false
    }
  }
)

export const getCustomQuestions = createSelector(
  ReportSelectors.getReportData,
  data => data?.customQuestions
)
