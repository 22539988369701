/* eslint-disable react-hooks/exhaustive-deps */
import { API, Amplify, graphqlOperation } from "aws-amplify"
import { useCallback, useState } from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { useDispatch } from "react-redux"

const useAppSyncMutation = (mutation, options = {}) => {
  const {
    initAction = null,
    successAction = null,
    failAction = null,
    entitlement = null
  } = options

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [data, setData] = useState(null)

  const post = useCallback(
    async (variables, next) => {
      try {
        setIsLoading(true)

        Amplify.configure({
          aws_appsync_graphqlEndpoint: getConfig().appSync.privateGatewayURL,
          aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
          aws_appsync_region: getConfig().appSync.publicGatewayRegion
        })

        // const mutationOptions = {}

        // if (entitlement) mutationOptions.headers = { entitlement }

        const res = await API.graphql(
          graphqlOperation(mutation, { ...variables })
        )

        if (res?.data) {
          setData(res.data)
          setIsLoading(false)
        }
        if (initAction) dispatch(initAction(isLoading))
        if (res?.data && successAction) dispatch(successAction(res.data))
        if (res?.data && next) next()
      } catch (error) {
        setErrors(error)
        if (failAction) dispatch(failAction(error))
        setIsLoading(false)
      }
    },
    [mutation]
  )

  return { post, isLoading, errors, data }
}

export default useAppSyncMutation
