import { connect } from "react-redux"
import { EBNSelectors } from "../.."
import ResearchComponent from "../../components/Content/Research"

const mapState = (state, { id }) => ({
  research: EBNSelectors.getResearchById(state, id)
})

const Research = connect(mapState)(ResearchComponent)

export default Research
