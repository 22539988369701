import { connect } from "react-redux"
import QuestionsBreakdownComponent from "../components/QuestionsBreakdown"
import * as Selectors from "../store/questionsBreakdown.selectors"

const mapState = state => ({
  sixStepsQuestions: Selectors.getSixStepsQuestions(state),
  customScaleQuestions: Selectors.getCustomScaleQuestions(state)
})

const QuestionsBreakdown = connect(mapState)(QuestionsBreakdownComponent)

export default QuestionsBreakdown
