import React from "react"
import { useParams, usePaths } from "@engaging-tech/routing"
import { useSelector } from "react-redux"
import { Flex } from "@engaging-tech/components"
import ReportElementsLayout from "../components/ReportElementsLayout"
import * as ReportSelectors from "../store/report.selectors"
import ReportHeader from "../Header/containers/ReportHeader"
import BackButton from "../components/BackButton"

const Highlights = () => {
  const components = useSelector(
    ReportSelectors.getHappinessDataHighlightChildrenLayout
  )
  const { id, surveyType } = useParams()
  const paths = usePaths()

  return (
    <>
      <BackButton
        path={paths.reports.summary.index
          .replace(":surveyType", surveyType)
          .replace(":id", id)}
      />
      <ReportHeader
        subSection="Data Highlights"
        reportComponents={components}
      />
      <Flex
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        flexWrap="wrap"
        alignItems="stretch"
      >
        <ReportElementsLayout componentTags={components} />
      </Flex>
    </>
  )
}

export default Highlights
