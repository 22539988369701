const trophy = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/trophy.svg`)
const alarmClock = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/alarmClock.svg`)
const medal = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/medal.svg`)
const information = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/information.svg`)
const paperAirplane = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/paperAirplane.svg`)
const resources = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/resources.svg`)
const lightbulb = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/lightbulb.svg`)
const chainLink = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/chainLink.svg`)
const megaphone = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/megaphone.svg`)
const umbrella = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/umbrella.svg`)
const brainstorm = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/brainstorm.svg`)
const environment = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/environment.svg`)
const sun = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/sun.svg`)
const heart = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/heart.svg`)
const star = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/star.svg`)
const family = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/family.svg`)
const thumbsUp = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/thumbsUp.svg`)
const cake = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/cake.svg`)
const relationship = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/relationship.svg`)
const podium = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/podium.svg`)
const question = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/question.svg`)
const person = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/person.svg`)
const briefcase = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey-icons/briefcase.svg`)

const surveyIcons = {
  trophy,
  alarmClock,
  medal,
  information,
  paperAirplane,
  resources,
  lightbulb,
  chainLink,
  megaphone,
  umbrella,
  brainstorm,
  environment,
  sun,
  heart,
  star,
  family,
  thumbsUp,
  cake,
  relationship,
  podium,
  question,
  person,
  briefcase
}

const generateOptions = () =>
  Object.keys(surveyIcons).map(surveyIconKey => ({
    id: surveyIconKey,
    value: surveyIconKey
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, function (str) {
        return str.toUpperCase()
      }),
    image: surveyIcons[surveyIconKey]
  }))

const options = generateOptions()
export default options
