import { Flex, Text } from "@engaging-tech/components"
import React from "react"

import AbsoluteErrorText from "../../sharedStyling/AbsoluteErrorText"
import StyledTextField from "../../sharedStyling/StyledTextField"
import DeleteButton from "./DeleteButton"

const InputInterface = ({
  value,
  handleChange,
  isCore,
  handleDelete,
  i,
  errors
}) => {
  return (
    <Flex
      key={i}
      alignItems="flex-end"
      pr="10px"
      mb="6px"
      my={2}
      position="relative"
      width={1 / 1}
    >
      <Flex
        borderRadius="100%"
        bg="dark.4"
        width="30px"
        height="30px"
        alignItems="center"
        mr={2}
      >
        <Text width="30px" textAlign="center">
          {i + 1}
        </Text>
      </Flex>
      <StyledTextField
        type="text"
        height="100%"
        value={value}
        onChange={handleChange}
        placeholder="Short answer label (optional)"
        width="97%"
        id="shortText"
        noLabel
        disabled={isCore}
        focusedBackgroundColor="light.0"
        borderColor="#b5b5b5"
        padding="2px 5px"
        bg="light.0"
      />
      <AbsoluteErrorText right="47px">{errors}</AbsoluteErrorText>
      {!isCore && <DeleteButton onClick={handleDelete} />}
    </Flex>
  )
}

export default InputInterface
