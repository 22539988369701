import { connect } from "react-redux"
import { getCurrentPage, getSelfServeSurveyType } from "../store/selectors"
import { setCurrentPage } from "../store/actions"
import PageManager from "../views/PageManager"
import { getHasSliceAndDiceLicence } from "../../account/store/account.selectors"

const mapState = state => ({
  currentPage: getCurrentPage(state),
  selfServeSurveyType: getSelfServeSurveyType(state),
  sliceAndDiceLicence: getHasSliceAndDiceLicence(state)
})

const mapDispatch = dispatch => ({
  setCurrentPage: page => dispatch(setCurrentPage(page))
})

const PageManagerContainer = connect(mapState, mapDispatch)(PageManager)

export default PageManagerContainer
