import { Box, Button, Flex, Icon } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"

const SiteBanner = ({ onLoad, isLoading, banner, ...props }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const siteBannerRef = useRef()
  useEffect(() => {
    if (!hasLoaded) {
      onLoad()
    }
    setHasLoaded(true)
  }, [hasLoaded, onLoad])

  useEffect(() => {
    if (!isLoading && banner !== null && banner?.trim() !== "") {
      setIsVisible(true)
    }
  }, [setIsVisible, banner, isLoading])

  useEffect(() => {
    if (siteBannerRef.current) {
      siteBannerRef.current.innerHTML = banner
    }
  }, [banner])

  return (
    isVisible && (
      <Flex
        px={[3, 3, 4]}
        py={3}
        justifyContent="space-between"
        alignItems="center"
        width={1 / 1}
        bg="primary.1"
        {...props}
      >
        <Box id="site-banner" dangerouslySetInnerHTML={{ __html: banner }} />
        <Button
          variant="text.dark.2"
          elevation="0"
          width="auto"
          onClick={() => setIsVisible(false)}
          css={`
            margin-right: -${({ theme }) => theme.space[3]}px;
          `}
        >
          <Icon name="close" color="dark.1" />
        </Button>
      </Flex>
    )
  )
}

export default SiteBanner
