/* eslint-disable react/jsx-curly-newline */
import {
  Box,
  Flex,
  LocationAutocomplete,
  Paragraph,
  Spinner,
  TextArea,
  TextField
} from "@engaging-tech/components"
import { getCountries } from "@engaging-tech/frontend-utils"
import { getConfig } from "@engaging-tech/ssr-config"
import { set } from "date-fns"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { array, object, string } from "yup"

import CREATE_EVENT from "../../../graphql/mutations/createEvent"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import ErrorState from "../../business-information/components/error-state"
import DisplayProduct from "../../ui/components/DisplayProduct"
import ServiceHelperText from "../../ui/components/ServiceHelperText"
import CategorySelect from "./CategorySelect"
import FormCard from "./FormCard"
import Success from "./Success"

export const Field = ({ onSelect, ...props }) => {
  const { width } = props
  return (
    <Box width={width} mb={3}>
      <TextField onClick={onSelect} onFocus={onSelect} width={1 / 1} {...props} />
    </Box>
  )
}

const StyledTextArea = styled(TextArea)`
  textarea {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 100px;
  }
  padding: 0px;
`

const initialValues = {
  title: "",
  categories: [],
  summary: "",
  email: "",
  urllink: "",
  location: {
    countryId: "",
    name: "",
    coordinates: {
      lat: "",
      lng: ""
    }
  },
  startDate: "",
  timetarts: "",
  endDate: "",
  endTime: ""
}

const validation = object().shape({
  title: string().required("A title for your article is required"),
  categories: array().of(string()),
  summary: string().required("A short summary of your event is required"),
  email: string().email("Must be a valid email").required("An email is required"),
  urllink: string().url("Must be a valid URL"),
  location: object().shape({
    name: string().required("Type and select a location")
  }),
  startDate: string().required("A start date is required"),
  startTime: string().required("A start time is required"),
  endDate: string().required("A end date is required"),
  endTime: string().required("A end time is required")
})

const HELPER_TEXT_ITEMS = [
  "Fill in the form below",
  "Publish your event and view it on the WorkL Global Hub",
  "Publicise your event by sharing via social media"
]

const Form = ({
  handleSubmit,
  isValid,
  isLoading,
  values,
  handleChange,
  handleBlur,
  errors,
  setFieldValue,
  setFieldTouched
}) => {
  const [closeDropdown, setCloseDropdown] = useState(0)
  const [countries, setCountries] = useState([])

  useEffect(() => {
    setCountries(getCountries())
  }, [])

  const handleLocationChange = e => {
    if (e.geometry) {
      setFieldValue("location", {
        countryId: countries.filter(
          country =>
            country.code ===
            e.address_components.filter(component => component.types.includes("country"))[0].short_name
        )[0].id,
        name: e.formatted_address,
        coordinates: {
          lat: e.geometry.location.lat(),
          lng: e.geometry.location.lng()
        }
      })
    } else if (values.location.name) {
      if (e !== values.location.name.name)
        // this happens when they have not selected a dropdown object, and 'e' === formatted_address
        setFieldValue("location", "")
    }
  }

  const handleSelect = () => {
    setCloseDropdown(closeDropdown + 1)
  }

  return (
    <FormCard title="Publish an Event" onSubmit={handleSubmit} disableSubmitWhen={!isValid || isLoading}>
      <ServiceHelperText title="CREATE YOUR EVENT:" items={HELPER_TEXT_ITEMS} />
      <Field
        onSelect={handleSelect}
        id="event-title"
        name="title"
        type="text"
        label="Title of the event"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        error={errors?.title}
      />
      <CategorySelect
        label="Categories"
        name="categories"
        helperText="Select categories for your event (max 3)"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.categories.join()}
        closeDropdownTrigger={closeDropdown}
      />
      <StyledTextArea
        onClick={handleSelect}
        onFocus={handleSelect}
        id="event-summary"
        name="summary"
        type="text"
        label="Summary of the event"
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={250}
        value={values.summary}
        error={errors?.summary}
      />
      <Field
        width={1 / 1}
        onSelect={handleSelect}
        id="rsvp-email"
        name="email"
        type="text"
        label="RSVP Email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors?.email}
      />
      <Field
        width={1 / 1}
        onSelect={handleSelect}
        id="url-link"
        name="urllink"
        type="text"
        label="URL Link (Optional)"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.urllink}
        error={errors?.urllink}
      />
      <Paragraph>Where is your event located?</Paragraph>
      <LocationAutocomplete
        mt={2}
        width={1 / 1}
        id="location.name"
        name="location.name"
        type="text"
        initialValue={values.location.name || null}
        placeholder="Event Location"
        value={values.location.name || null}
        googleApiKey={getConfig().keys.googleApiKey}
        selectedLocationCallback={location => handleLocationChange(location)}
        onFocus={() => setFieldTouched("location.name")}
        onChange={handleLocationChange}
        error={errors?.location?.name}
      />
      <Paragraph>When is your event?</Paragraph>
      <Flex width={1 / 1} flexDirection={["column", "row"]}>
        <Box width={[1 / 1, 1 / 3]} mx={[0, 1]}>
          <Field
            mt="7px"
            height={57}
            wrapperWidth="100%"
            width={1 / 1}
            name="startDate"
            id="startDate"
            type="date"
            label="Date event starts"
            value={values.startDate}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.startDate}
          />
        </Box>
        <Box width={[1 / 1, 1 / 3]} mx={[0, 1]}>
          <Field
            mt="7px"
            height={57}
            wrapperWidth="100%"
            width={1 / 1}
            name="startTime"
            id="startTime"
            type="time"
            label="Time event starts"
            value={values.startTime}
            onChange={handleChange}
            disabled={!values.startDate}
            onBlur={handleBlur}
            error={errors?.startTime}
          />
        </Box>
      </Flex>
      <Flex width={1 / 1} flexDirection={["column", "row"]}>
        <Box width={[1 / 1, 1 / 3]} mx={[0, 1]}>
          <Field
            mt="7px"
            height={57}
            wrapperWidth="100%"
            width={1 / 1}
            name="endDate"
            id="endDate"
            type="date"
            label="Date event finishes"
            value={values.endDate}
            onChange={handleChange}
            disabled={!values.startDate}
            onBlur={handleBlur}
            error={errors?.endDate}
          />
        </Box>
        <Box width={[1 / 1, 1 / 3]} mx={[0, 1]}>
          <Field
            mt="7px"
            height={57}
            wrapperWidth="100%"
            width={1 / 1}
            name="endTime"
            id="endTime"
            type="time"
            label="Time event finishes"
            value={values.endTime}
            onChange={handleChange}
            disabled={!values.endDate}
            onBlur={handleBlur}
            error={errors?.endTime}
          />
        </Box>
      </Flex>
      <Flex width={1 / 1} flexDirection={["column", "row"]}>
        <Box width={[1 / 1, 1 / 3]} mx={[0, 1]}>
          <Field
            mt="7px"
            height={57}
            wrapperWidth="100%"
            width={1 / 1}
            name="attendees"
            id="attendees"
            type="Number"
            label="Max number of attendees (Optional)"
            value={values.attendees}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.attendees}
          />
        </Box>
      </Flex>
    </FormCard>
  )
}

const CreateForm = ({ orgId }) => {
  const [success, setSuccess] = useState(false)
  const { errors, isLoading, post } = useBusinessAppMutation(CREATE_EVENT)

  if (errors?.networkError?.result.errors.filter(error => error.extensions.code === "ERR403")[0])
    return <DisplayProduct />
  if (errors) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={data => {
        const values = data

        const startTimeParts = values.startTime.split(":")
        const endTimeParts = values.endTime.split(":")

        values.startDate = set(new Date(values.startDate), {
          hours: startTimeParts[0],
          minutes: startTimeParts[1]
        })

        values.endDate = set(new Date(values.endDate), {
          hours: endTimeParts[0],
          minutes: endTimeParts[1]
        })

        delete values.startTime
        delete values.endTime

        post({ ...values, organisationId: orgId }, () => setSuccess(true))
      }}
    >
      {props => (
        <>
          <Form {...props} values={props.values} isLoading={isLoading} />
          {success && <Success setSuccess={setSuccess} resetForm={props.resetForm} />}
        </>
      )}
    </Formik>
  )
}

export default CreateForm
