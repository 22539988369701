import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../../lib/Apollo"
import { LOAD_SURVEY } from "./survey-view-mode.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const load = surveyId =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_SURVEY,
    variables: { surveyId }
  })
