import { Flex, Text } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"

import LinkButton from "../../../ui/components/LinkButton"
import BarIndicator from "../../components/BarIndicator/BarIndicator"
import ReportCard from "../../components/ReportCard"
import { ScoreLabel } from "../../components/ScoreLabel"
import DiversityAndInclusionLogo from "../../reportLogos/DiversityAndInclusionLogo"
import IndustryAndGlobalScoreBox from "./IndustryAndGlobalScoreBox"

const barStructure = [
  { color: "#EF464A", percentage: 25, labelStart: "-100", labelEnd: "-50" },
  { color: "#FFB547", percentage: 15, labelEnd: "-20" },
  { color: "#0DC187", percentage: 10 },
  { color: "#0DC187", percentage: 10 },
  { color: "#FFB547", percentage: 15, labelStart: "20" },
  { color: "#EF464A", percentage: 25, labelStart: "50", labelEnd: "100" }
]

const DiversityAndInclusionIndicator = ({
  averageScoreData,
  exportingPDF,
  breakDown
}) => {
  const { id, surveyType } = useParams()
  const paths = usePaths()
  const { score, industry, global } = averageScoreData

  if (typeof score !== "number" || isNaN(score)) return <></>

  return (
    <ReportCard
      heading="Diversity & Inclusion"
      width={["100%", "100%", "40%"]}
      exportingPDF={exportingPDF}
      HeadingImageComponent={DiversityAndInclusionLogo}
      innerProps={{ justifyContent: "space-between" }}
    >
      <BarIndicator
        dataSet={{ score }}
        disableAnimation={exportingPDF}
        barStructure={barStructure}
        hideArrowLabels
        minValue={-100}
        maxValue={100}
        leftLabel="Majority"
        rightLabel="Minority"
      />

      <Flex flexDirection="column" mb={4}>
        <Text textAlign="center" fontSize={3} color="dark.2" mb={3}>
          Percentage point difference in average score between majority and
          minority groups
        </Text>
        <ScoreLabel showIcon score={100 - Math.abs(score)} />
      </Flex>

      <IndustryAndGlobalScoreBox
        globalScore={global ? Math.round(global) : null}
        industryScore={industry ? Math.round(industry) : null}
        mb={3}
        mt={[3, 0]}
      />
      {!exportingPDF && (
        <LinkButton
          variant="text.primary.0"
          color="primary.0"
          text="SEE RESULTS BREAKDOWN"
          path={`${paths.reports.summary.indicatorBreakdown.diversityAndInclusion
            .replace(":surveyType", surveyType)
            .replace(":id", id)}?tag=${breakDown}`}
        />
      )}
    </ReportCard>
  )
}

export default DiversityAndInclusionIndicator
