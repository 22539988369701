import { connect } from "react-redux"
import EBNBillingWrapperComponent from "../components/EBNBillingWrapper"
import { AccountSelectors } from ".."

const mapState = state => ({
  isLoading: AccountSelectors.getIsLoading(state),
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  isEBNMember: AccountSelectors.getIsEBNMember(state),
  hasPaid: AccountSelectors.getHasPaid(state)
})

const EBNBillingWrapper = connect(mapState)(EBNBillingWrapperComponent)

export default EBNBillingWrapper
