import { connect } from "react-redux"
import LeadGenerationList from "../components/LeadGenerationList"
import { getLiveLeads } from "../../../store/leads/leads.selector"

const mapState = state => ({
  liveLeads: getLiveLeads(state)
})

const LiveLeads = connect(mapState, null)(LeadGenerationList)

export default LiveLeads
