import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import DashboardLayout from "../../../layouts/DashboardLayout"
import { OrgGuard } from "../../../features/account"
import ReportDashboardLoader from "../../../features/Reports/containers/ReportDashboardLoader"
import HoldingPage from "../../../features/Reports/components/HoldingPage"
import EmployeeReportsDashboardContainer from "../../../features/Reports/containers/EmployeeReportsDashboardContainer"
import {
  setSurveyType,
  setDeleteReport,
  setGenerateReport
} from "../../../features/Reports/store/report.actions"
import { ReportSelectors } from "../../../features/Reports"

// Props to be passed

const StarterReports = () => {
  const isLoading = useSelector(ReportSelectors.getIsAllReportsLoading)
  const isLoadingReports = useSelector(ReportSelectors.getIsAllReportsLoading)
  const dispatch = useDispatch()
  dispatch(setSurveyType("employeeStarter"))
  // on page load we want to ensure this is empty
  dispatch(setDeleteReport([]))
  dispatch(setGenerateReport([]))
  // Set is loading to false on page load (and on the other pages as well)

  return (
    <OrgGuard>
      <DashboardLayout>
        <ReportDashboardLoader />
        {isLoading || isLoadingReports ? (
          <HoldingPage text="Reports" />
        ) : (
          <EmployeeReportsDashboardContainer />
        )}
      </DashboardLayout>
    </OrgGuard>
  )
}

export default StarterReports
