export const validateTemplateIntroduction = (
  templateIntroductionValues,
  totalSections
) => {
  const errorArray = []
  if (!templateIntroductionValues.title) {
    errorArray.push({
      message: "Template introduction title missing",
      identifier: "title"
    })
  }

  if (totalSections === 0) {
    errorArray.push({
      message: "No sections added to template",
      identifier: "no-sections"
    })
  }

  return errorArray
}

export const validateTemplateQuestion = templateQuestionValues => {
  const errorArray = []
  if (!templateQuestionValues.coreQuestion) {
    if (!templateQuestionValues.questionText) {
      errorArray.push({
        message: "Question title missing",
        identifier: "questionText"
      })
    }
    switch (templateQuestionValues.type) {
      case "dropdown":
        {
          const { options, label } = templateQuestionValues.parameters.dropdown
          if (options.find(o => !o.value)) {
            errorArray.push({
              message: "A option label is missing",
              identifier: "option-label"
            })
          }
          if (!label) {
            errorArray.push({
              message: "A question label is missing",
              identifier: "label"
            })
          }
          if (options.length === 0) {
            errorArray.push({
              message: "No options within dropdown",
              identifier: "label"
            })
          }
        }

        break
      case "multipleChoice":
        {
          const {
            label,
            options
          } = templateQuestionValues.parameters.multipleChoice
          if (!label) {
            errorArray.push({
              message: "A question label is missing",
              identifier: "label"
            })
          }
          if (options.length === 0) {
            errorArray.push({
              message: "No multiple choice options given",
              identifier: "label"
            })
          }
        }

        break
      case "smileys":
        {
          const { options, label } = templateQuestionValues.parameters.smileys
          if (options.find(o => !o.value)) {
            errorArray.push({
              message: "An option label is missing",
              identifier: "option-label"
            })
          }
          if (!label) {
            errorArray.push({
              message: "Question label is missing",
              identifier: "label"
            })
          }
          if (options.length === 0) {
            errorArray.push({
              message: "No options within smileys",
              identifier: "label"
            })
          }
        }

        break
      case "shortText":
        {
          const { fields } = templateQuestionValues.parameters.shortText

          if (fields.length === 0) {
            errorArray.push({
              message: "No short-text options given",
              identifier: "label"
            })
          }
          if (fields.find(f => !f.label)) {
            errorArray.push({
              message: "A field(s) label is missing",
              identifier: "field-label"
            })
          }
        }

        break
      default:
        break
    }
  }

  return errorArray
}

export const validateTemplateSection = templateSectionValues => {
  const errorArray = []
  if (!templateSectionValues.title) {
    errorArray.push({
      message: "Section title missing",
      identifier: "title"
    })
  }

  if (templateSectionValues.questions) {
    const questionErrorCollection = Object.keys(
      templateSectionValues.questions
    ).reduce((collector, questionKey) => {
      const questionValidationArray = validateTemplateQuestion(
        templateSectionValues.questions[questionKey]
      )
      if (questionValidationArray.length) {
        return [...collector, questionValidationArray]
      }
      return collector
    }, [])
    if (questionErrorCollection.length) {
      errorArray.push({
        message: "Questions within this section have error(s)",
        identifier: "section-questions"
      })
    }
  }

  return errorArray
}
