import { Button, Flex, Spinner, Text } from "@engaging-tech/components"
import React from "react"

const LoadingState = ({
  message = "Loading",
  hideMessage = false,
  onCancel
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={5}
    >
      {hideMessage ? null : (
        <Text mb={6} width="auto" fontWeight={700} fontSize={30}>
          {message}
        </Text>
      )}
      <Spinner size={70} color="primary.0" />
      {onCancel && (
        <Button
          width="auto"
          variant="secondary.0"
          px={3}
          mt={6}
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
    </Flex>
  )
}

export default LoadingState
