import React from "react"
import { Card } from "@engaging-tech/components"
import styled from "styled-components"
import SliceAndDiceContainer from "../../awards/containers/SliceAndDice/SliceAndDiceContainer"

const SliceCard = styled(Card)`
  padding: 5;
  background-color: #173c5a;
  position: absolute;
  margin-top: 30px;
  right: 0px;
  width: fit-content;
  height: auto;
  align-items: center;
  z-index: 999999;
`

const SliceAndDiceCard = () => {
  return (
    <SliceCard>
      <SliceAndDiceContainer minimized hideContinueButton />
    </SliceCard>
  )
}

export default SliceAndDiceCard
