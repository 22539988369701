import React from "react"
import styled from "styled-components"

const Wrapper = styled("div")`
  display: flex;
  color: rgb(112, 112, 112);
  font-size: 12px;
  width: fit-content;
`

const LeftText = styled("p")`
  margin: 0;
  padding: 2px 6px 2px 6px;
  border-radius: 6px 0 0 6px;
  border: solid 1px #ddd;
  background-color: rgb(239, 239, 239);
  transform: translate(2px, 0px);

  &:hover {
    border-color: #213140;
    cursor: pointer;
  }

  ${({ checked }) =>
    checked &&
    `
    {
      background-color: rgb(205, 234, 255);
      color: rgb(64, 64, 64);
      font-weight: 600;
      z-index: 1;
      border-radius: 6px;
    }
    `}
`

const RightText = styled("p")`
  margin: 0;
  padding: 2px 8px 2px 8px;
  border-radius: 0 6px 6px 0;
  border: solid 1px #ddd;
  background-color: rgb(239, 239, 239);
  transform: translate(-2px, 0px);

  &:hover {
    border-color: #213140;
    cursor: pointer;
  }

  ${({ checked }) =>
    !checked &&
    `
  {
    background-color: rgb(205, 234, 255);
    color: rgb(64, 64, 64);
    font-weight: 600;
    z-index: 1;
    border-radius: 6px;
  }
  `}
`

const DualToggle = ({ handleClick, checked }) => {
  return (
    <Wrapper onClick={handleClick}>
      <LeftText checked={checked}>AND</LeftText>
      <RightText checked={checked}>OR</RightText>
    </Wrapper>
  )
}

export default DualToggle
