/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Text } from "@engaging-tech/components"
import React, { useEffect, useMemo } from "react"
import { useFormik } from "formik"
import { object, string } from "yup"
import { TextField, TextArea } from "../../../surveyManagement/components"
import PopUpMenuControl from "../../../surveyManagement/template/components/PopUpMenu/PopUpMenuControl"

// viewOnly is needed if you want to enable the disabling of inputs
// type param is not needed if not using viewOnly
// id param is used for error handling
const SurveyIntroCard = ({
  titlePlaceholder = "",
  introPlaceholder = "",
  title,
  introduction,
  setTitle,
  setIntroduction,
  handleErrors,
  mt = 0,
  viewOnly = false,
  type,
  id,
  noTitleErrors = false,
  noIntroErrors = false
}) => {
  const initialValues = useMemo(
    () => ({
      title,
      introduction
    }),
    [title, introduction]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: object({
      title: noTitleErrors ? string() : string().required("Cannot be empty"),
      introduction: noIntroErrors
        ? string()
        : string().required("Cannot be empty")
    }),
    validateOnMount: true,
    enableReinitialize: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setTitle(formik.values.title)
      setIntroduction(formik.values.introduction)
      if (!noTitleErrors) handleErrors(formik.errors?.title, "title")
      if (!noIntroErrors)
        handleErrors(formik.errors?.introduction, "introduction")
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.introduction, formik.values.title, formik.errors])

  return (
    <Card
      width={1 / 1}
      alignItems="center"
      mt={mt}
      px={[2, 4]}
      py={[2, 5]}
      bg="light.0"
      borderRadius="10px"
      position="relative"
      mb={4}
    >
      <TextField
        name="title"
        id="title"
        placeholder={titlePlaceholder}
        alignItems="center"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik?.errors?.title}
        disabled={viewOnly}
        preview={viewOnly}
        fontSize={20}
        fontWeight={600}
        style={{
          fontWeight: 700
        }}
      />
      <TextArea
        style={{
          width: "100%",
          height: "150px"
        }}
        name="introduction"
        id="introduction"
        placeholder={introPlaceholder}
        value={formik.values.introduction}
        onChange={formik.handleChange}
        error={formik?.errors?.introduction}
        disabled={viewOnly}
        preview={viewOnly}
      />
      <Text
        textAlign="left"
        width={1 / 1}
        color="error.0"
        fontSize={2}
        ml={3}
        mt={1}
      >
        {formik?.errors?.introduction}
      </Text>
      {!viewOnly && <PopUpMenuControl type={type} currentSection={id} />}
    </Card>
  )
}

export default SurveyIntroCard
