import {
  Flex,
  H2,
  MotionToggle,
  Paragraph,
  Text
} from "@engaging-tech/components"
import { FieldArray } from "formik"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import ClickableIcon from "../../../ui/components/ClickableIcon"
import {
  Card as CardComponent,
  FilePickerButton,
  TextFieldAndButton
} from "../../components"

const Card = styled(CardComponent)`
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.dark[4]};
      cursor: not-allowed;
    `}
`

export const VerifiedEmail = ({ onDelete, children }) => {
  return (
    <Text
      bg="secondary.2"
      p={2}
      color="dark.0"
      fontSize={4}
      mr={2}
      mb={3}
      borderRadius={4}
    >
      {onDelete ? (
        <Flex>
          {children}
          <ClickableIcon name="close" onClick={onDelete} ml={2} />
        </Flex>
      ) : (
        children
      )}
    </Text>
  )
}

export const EmailTextInput = ({
  values,
  errors,
  push,
  handleChange,
  disabled
}) => {
  const handlePush = value => {
    if (values.recipients && values.recipients.includes(value)) return
    push(value)
  }

  const handleKeyPress = (e, errors) => {
    if (e.key !== "Enter") return
    e.preventDefault()
    if ((errors && errors.email) || e.target.value === "") return false
    handlePush(e.target.value)
    values.email = ""
    return false
  }

  const handleClick = () => {
    if (!values || !values.email) return
    if (errors && errors.email) return
    handlePush(values.email)
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Added email to survey"])
    }
  }

  return (
    <TextFieldAndButton
      onKeyPress={e => handleKeyPress(e, errors, push)}
      onclick={handleClick}
      value={values && values.email}
      error={errors && (errors.email || errors.recipients)}
      disabled={disabled}
      name="email"
      textFieldProps={{
        onChange: handleChange,
        type: "email",
        placeholder: "Participant email addresses"
      }}
      mb={3}
      buttonText="Add email"
    />
  )
}

const UploadCSV = ({ push, disabled }) => {
  const handleFileChange = e => {
    if (typeof e.target.files === "undefined") return
    const reader = new FileReader()
    reader.onloadend = e => {
      const emailAddrs = e.target.result.split(",")
      emailAddrs.forEach(address => {
        push(address)
      })
    }
    const csv = e.target.files[0]
    reader.readAsText(csv)
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Uploaded survey CSV"])
    }
  }

  return (
    <>
      <FilePickerButton
        width={145}
        accept=".csv"
        id="csv"
        name="csv"
        onChange={handleFileChange}
        disabled={disabled}
        leadingIcon="backup"
        text="Upload .CSV"
      />
      <Text fontSize={2} ml={1}>
        *Email addresses within a .CSV file are not validated
      </Text>
    </>
  )
}

const EmailInvite = ({
  values,
  errors,
  handleChange,
  validateField,
  setFieldValue,
  readOnly,
  template,
  ...props
}) => {
  const handleModeChange = active => {
    if (active) {
      setFieldValue("mode", "uniqueSubmission")
    } else {
      setFieldValue("mode", "shareableLinks")
    }
  }

  useEffect(() => {
    if (["employeeStarter", "employeeExit"].includes(template.type)) {
      handleModeChange("uniqueSubmission")
    }
  }, [template])

  if (readOnly && values && values.mode !== "uniqueSubmission") return <></>

  console.log("values", values)

  return (
    <Card disabled={values && values.mode !== "uniqueSubmission"} {...props}>
      {readOnly ||
      ["employeeStarter", "employeeExit"].includes(template.type) ? (
        <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
          Invite participants via email
        </H2>
      ) : (
        <Flex>
          <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
            Invite participants via email
          </H2>
          <MotionToggle
            name="mode"
            value={values && values.mode === "uniqueSubmission"}
            onChange={handleModeChange}
            type="radio"
          />
        </Flex>
      )}
      <Paragraph>
        Send participants the survey link by email by entering their email
        addresses in the text box below or by uploading a CSV file. If you don’t
        wish to send the survey via email please toggle this off. Please note:
        Email addresses within a .CSV are not validated. Please check before
        uploading.
      </Paragraph>
      <FieldArray name="recipients">
        {({ remove, push }) => (
          <>
            {!readOnly && (
              <EmailTextInput
                push={push}
                values={values}
                errors={errors}
                handleChange={handleChange}
                disabled={values && values.mode !== "uniqueSubmission"}
                {...props}
              />
            )}
            {values && values.recipients && (
              <Flex flexWrap="wrap" width="auto">
                {values.recipients.map((email, i) => {
                  return (
                    <VerifiedEmail onDelete={readOnly ? null : () => remove(i)}>
                      {email}
                    </VerifiedEmail>
                  )
                })}
              </Flex>
            )}
            {!readOnly && (
              <UploadCSV
                validateField={validateField}
                push={push}
                remove={remove}
                handleChange={handleChange}
                disabled={values && values.mode !== "uniqueSubmission"}
                {...props}
              />
            )}
          </>
        )}
      </FieldArray>
    </Card>
  )
}

export default EmailInvite
