import { connect } from "react-redux"
import SentimentAnalysisComponent from "../components/SentimentAnalysis"
import * as Selectors from "../store/sentimentAnalysis.selectors"

const mapState = state => ({
  wordCountQuestions: Selectors.getWordCountQuestions(state)
})

const SentimentAnalysis = connect(mapState)(SentimentAnalysisComponent)

export default SentimentAnalysis
