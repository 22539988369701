import { connect } from "react-redux"
import { EBNSelectors } from ".."
import WebinarsComponent from "../components/Webinars/Webinars"

const mapState = state => ({
  webinars: EBNSelectors.getLast4Webinars(state)
})

const Webinars = connect(mapState)(WebinarsComponent)

export default Webinars
