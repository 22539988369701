import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useRouter, useParams } from "@engaging-tech/routing"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getHasResetPassword } from "../../store/account.selectors"
import formSchema from "./form-schema"
import ResetPasswordFormContent from "./ResetPasswordFormContent"

const ResetPasswordForm = ({
  siteLogo,
  title,
  subtitle,
  redirectTo,
  cancelRoute
}) => {
  const router = useRouter()
  const { code, email } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const hasResetPassword = useSelector(getHasResetPassword)

  const dispatch = useDispatch()

  const onSubmit = async data => {
    try {
      setIsLoading(true)

      await Auth.forgotPasswordSubmit(data.email, data.code, data.password)

      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            "Your Password has been reset. Please login with your new password."
        })
      )

      router.redirect(redirectTo)
    } catch (error) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            typeof error.message === "string"
              ? error.message
              : "Failed to reset your password. Please try again!"
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (hasResetPassword) {
      router.redirect(redirectTo)
    } else if (!email) {
      router.redirect("/account/forgot-password")
    }
  }, [hasResetPassword, email, code, redirectTo, router])

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        code,
        email
      }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {props => (
        <ResetPasswordFormContent
          siteLogo={siteLogo}
          cancelRoute={cancelRoute}
          title={title}
          subtitle={subtitle}
          isLoading={isLoading}
          email={email}
          {...props}
        />
      )}
    </Formik>
  )
}

export default ResetPasswordForm
