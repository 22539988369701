import { Flex, Text } from "@engaging-tech/components"
import React from "react"

const UpgradedLabel = () => (
  <Flex bg="#39B086" justifyContent="center" alignItems="center" width={100} ml={10} py={1} px={2}>
    <Text color="light.0" fontWeight="700">
      UPGRADED
    </Text>
  </Flex>
)

export default UpgradedLabel
