import questionTypeOptions from "../lib/questionTypeOptions"
// eslint-disable-next-line import/no-cycle
import * as defaultObjects from "../store/activeTemplate/activeTemplate.reducer"
import otherHelper from "./otherHelper"

const convertDescription = descriptionArray => {
  return descriptionArray?.reduce((returnedString, { type, content }) => {
    switch (type) {
      case "paragraph": {
        return `${returnedString + content}\n`
      }
      case "text": {
        return `${returnedString + content}\n`
      }
      default:
        return returnedString
    }
  }, "")
}

const convertName = nameString => {
  const words = nameString.split("-")

  for (let i = 0; i < words.length; i += 1) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }

  return words.join(" ")
}

const getCoreQuestion = ({ coreQuestions, idToCheck }) =>
  coreQuestions.find(coreQuestion => coreQuestion?.id === idToCheck)

const getQuestionType = desiredType => {
  const matchedOption = questionTypeOptions.find(
    questionTypeOption => questionTypeOption.id === desiredType
  )
  if (matchedOption) {
    return matchedOption
  }
  return questionTypeOptions.find(
    questionTypeOption => questionTypeOption.id === "other"
  )
}

const getQuestionParameters = ({ type, backendQuestion }) => {
  let output = {
    ...defaultObjects.defaultParameters
  }

  switch (type) {
    case "scale":
      output = {
        ...output,
        scale: {
          startOfScale: backendQuestion.min,
          endOfScale: backendQuestion.max
        }
      }
      break
    case "dropdown":
      output = {
        ...output,
        dropdown: {
          filterReport: !!backendQuestion.isFilter,
          label: backendQuestion.label,
          options: backendQuestion.values
        }
      }
      break
    case "smileys":
      output = {
        ...output,
        smileys: {
          filterReport: !!backendQuestion.isFilter,
          label: backendQuestion.label,
          options: backendQuestion.values
        }
      }
      break
    case "multipleChoice":
      output = {
        ...output,
        multipleChoice: {
          label: backendQuestion.label,
          filterReport: !!backendQuestion.isFilter,
          options: backendQuestion.values
        }
      }

      break
    case "shortText":
      output = {
        ...output,
        shortText: {
          fields: backendQuestion.fields.map(field => ({
            id: otherHelper.generateRandomId().toString(),
            ...field
          }))
        }
      }
      break
    default:
      output = {
        ...output,
        other: {
          filterReport: !!backendQuestion.isFilter
        }
      }
      break
  }

  return output
}

export const createQuestionFromBackend = ({
  backendQuestion,
  coreQuestions,
  forceCoreQuestion
}) => {
  let foundCoreQuestion = null

  if (!forceCoreQuestion) {
    foundCoreQuestion = getCoreQuestion({
      coreQuestions,
      idToCheck: backendQuestion.id
    })
  }

  const type = getQuestionType(backendQuestion.type).componentType

  return {
    ...defaultObjects.defaultQuestionObject,
    type,
    trueType: backendQuestion.type,
    coreQuestion:
      typeof forceCoreQuestion === "boolean"
        ? forceCoreQuestion
        : !!foundCoreQuestion,
    parameters: getQuestionParameters({ type, backendQuestion }),
    questionText: backendQuestion.title,
    description: backendQuestion.title,
    coreQuestionId: (() => {
      if (forceCoreQuestion) {
        return backendQuestion.id
      }
      return foundCoreQuestion?.id || coreQuestions[0].id
    })(),
    icon: backendQuestion.icon,
    required: backendQuestion.required,
    id: backendQuestion.id,
    customStepName: backendQuestion.customStepName
  }
}

const generateQuestions = ({
  questionIdArray,
  questionArray,
  coreQuestions
}) => {
  return questionIdArray.map(questionId => {
    const foundQuestion = questionArray.find(
      question => question.id === questionId
    )
    if (foundQuestion) {
      return createQuestionFromBackend({
        backendQuestion: foundQuestion,
        coreQuestions
      })
    }

    throw new Error(
      `Unable to find question with ID ${questionId} within template`
    )
  })
}

export const mapBackendLayoutToFrontendLayout = ({
  backendTemplate,
  coreQuestions,
  baseTemplate
}) => {
  const outputLayout = JSON.parse(JSON.stringify(defaultObjects.initialState))

  backendTemplate.sections.forEach((section, index) => {
    outputLayout.sections[index] = {
      ...defaultObjects.defaultSectionObject,
      id: otherHelper.generateRandomId().toString(),
      title: convertName(section.name),
      description: convertDescription(section.description),
      questions: generateQuestions({
        questionIdArray: section.questions,
        questionArray: backendTemplate.questions,
        coreQuestions
      })
    }
  })

  outputLayout.type =
    backendTemplate?.type || baseTemplate?.type || "organisation"
  outputLayout.id = baseTemplate ? null : backendTemplate.id
  outputLayout.title = backendTemplate.name || ""
  outputLayout.description = backendTemplate.description || ""
  outputLayout.workFromHome = backendTemplate.workFromHome
  outputLayout.isDraft = baseTemplate ? true : backendTemplate.isDraft
  outputLayout.bespokeSteps = backendTemplate.bespokeSteps

  return outputLayout
}

const convertFrontendTypeToBackendType = frontendType => {
  switch (frontendType) {
    case "shortText": {
      return "short-text"
    }

    case "multipleChoice": {
      return "radio"
    }

    case "smileys": {
      return "smiley-face"
    }

    default:
      return frontendType
  }
}

const getQuestionsFromFrontendLayout = template => {
  const getParameters = (type, parameterCollection) => {
    const parameters = parameterCollection[type]

    switch (type) {
      case "scale": {
        return {
          min: parameters.startOfScale,
          max: parameters.endOfScale
        }
      }
      case "shortText": {
        return {
          fields: parameters.fields.map(f => ({
            label: f.label,
            limit: f.limit,
            type: f.type
          }))
        }
      }
      case "multipleChoice": {
        return {
          label: parameters.label || "f",
          isFilter: parameters.filterReport,
          values: parameters.options.map(o => ({ value: o.value, id: o.id }))
        }
      }
      case "dropdown": {
        return {
          isFilter: parameters.filterReport,
          label: parameters.label,
          values: parameters.options.map(o => ({ value: o.value, id: o.id }))
        }
      }
      case "smileys": {
        return {
          isFilter: parameters.filterReport,
          label: parameters.label,
          values: parameters.options
            // this filtering removes the nulls from the array
            .filter(o => o)
            .map(o => ({
              value: o.value,
              smileyValue: o.smileyValue,
              id: o.id
            }))
        }
      }
      case "other": {
        return {
          isFilter: parameters.filterReport
        }
      }
      default:
        return {}
    }
  }
  const allQuestions = Object.keys(template.sections).map(sectionKey => {
    const section = template.sections[sectionKey]

    return Object.keys(section.questions).map(questionKey => {
      const {
        id,
        trueType,
        type,
        coreQuestion,
        questionText,
        icon,
        required,
        parameters,
        customStepName
      } = section.questions[questionKey]
      const implementation = getParameters(type, parameters)
      return {
        id,
        type: coreQuestion ? trueType : convertFrontendTypeToBackendType(type),
        title: questionText,
        description: questionText,
        icon,
        enabled: true,
        required,
        isCore: coreQuestion,
        customStepName,
        ...implementation
      }
    })
  })
  return allQuestions.flat()
}
const getSectionsFromFrontendLayout = template =>
  Object.keys(template.sections).map(sectionKey => {
    const section = template.sections[sectionKey]
    // Separates by new line
    const descriptionLines = section.description.match(/[^\r\n]+/g)

    return {
      name: section.title,
      description: descriptionLines
        ? descriptionLines.map(s => ({ content: s, type: "text" }))
        : [],
      visible: true,
      questions: Object.keys(section.questions).map(
        questionKey => section.questions[questionKey].id
      )
    }
  })

export const mapFrontendLayoutToBackendLayout = template => {
  const questions = getQuestionsFromFrontendLayout(template)
  const sections = getSectionsFromFrontendLayout(template)

  return {
    name: template.title,
    description: template.description,
    workFromHome: template.workFromHome,
    sections,
    questions
  }
}
