import { connect } from "react-redux"
import { ReportSelectors, ReportActions } from "../index"
import SearchBar from "../components/Search/SearchBar"

const mapState = state => ({
  searchTerm: ReportSelectors.getSearchTerm(state)
})

const mapDispatch = dispatch => ({
  setSearchTerm: term => dispatch(ReportActions.setSearchTerm(term))
})

const SearchBarContainer = connect(mapState, mapDispatch)(SearchBar)

export default SearchBarContainer
