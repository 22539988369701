import React, { useContext } from "react"
import { string, object } from "yup"
import { Formik } from "formik"
import { Flex, TextArea, Button } from "@engaging-tech/components"
import StepBox from "../components/StepBox"
import PostJobContext from "../lib/postJobContext"
import FormWrapper, { Content, BottomBar } from "../components/FormWrapper"

const Form = ({
  handleSubmit,
  values,
  errors,
  handleChange,
  handleBlur,
  isValid
}) => {
  const { goForward, goBack } = useContext(PostJobContext)

  const handleFormSubmission = e => {
    handleSubmit(e)
    setTimeout(() => goForward(), 100) // force redux action dispatch before unmounting the component
  }

  return (
    <FormWrapper onSubmit={handleFormSubmission}>
      <Content>
        <StepBox step="4" title="Job description">
          <TextArea
            width={1 / 1}
            id="snippet"
            name="snippet"
            type="text"
            height="auto"
            style={{ maxWidth: "100%", minWidth: "100%", minHeight: "70px" }}
            label="Job description"
            value={values.snippet}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.snippet}
            helperText="Introduce the company, the service, the team, the projects, etc."
          />
        </StepBox>
        <StepBox step="5" title="Responsibilities">
          <TextArea
            width={1 / 1}
            id="responsibilities"
            name="responsibilities"
            type="text"
            height="auto"
            style={{ maxWidth: "100%", minWidth: "100%", minHeight: "70px" }}
            label="Responsibilities"
            value={values.responsibilities}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.responsibilities}
            helperText="Introduce the key responsibilities and the expectations of the role"
          />
        </StepBox>
        <StepBox step="6" title="Requirements">
          <TextArea
            width={1 / 1}
            id="requirements"
            name="requirements"
            height="auto"
            style={{ maxWidth: "100%", minWidth: "100%", minHeight: "70px" }}
            type="text"
            label="Requirements"
            value={values.requirements}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.requirements}
            helperText="Experience, skills, languages, etc."
          />
        </StepBox>
      </Content>
      <BottomBar alignItems={["flex-start", "center"]} elevation={6}>
        <Flex
          maxWidth="1080px"
          flexDirection="row-reverse"
          justifyContent="space-between"
        >
          <Button
            width={148}
            type="submit"
            variant="primary.0"
            disabled={!isValid}
          >
            Next
          </Button>
          <Button
            variant="text.primary.0"
            width="auto"
            elevation={0}
            onClick={goBack}
          >
            Back
          </Button>
        </Flex>
      </BottomBar>
    </FormWrapper>
  )
}

const validation = object().shape({
  snippet: string().required("A job description is required"),
  responsibilities: string().required("Responsibilities are required"),
  requirements: string().required("A list of requirements are needed")
})

const DescriptionForm = ({ job, onSubmit }) => {
  const initialValues = {
    snippet: job?.snippet || "",
    responsibilities: job?.responsibilities || "",
    requirements: job?.requirements || ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
      validateOnMount
    >
      {props => <Form {...props} />}
    </Formik>
  )
}
export default DescriptionForm
