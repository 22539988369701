import { Paragraph, BarChart } from "@engaging-tech/components"
import React from "react"
import ReportCard from "../../components/ReportCard"

const BreakdownChartWrapper = ({
  graphDataset,
  exportingPDF,
  heading,
  description,
  yAxisLabel,
  yAxisDecimalPlaces = 0,
  graphOptions,
  HeadingImageComponent
}) => {
  return (
    <ReportCard
      HeadingImageComponent={HeadingImageComponent}
      heading={heading}
      headingProps={{
        fontWeight: "normal",
        fontSize: 6
      }}
      width={1 / 1}
      metaProps={{
        mb: 2
      }}
      exportingPDF={exportingPDF}
    >
      <Paragraph color="dark.2" fontSize={3}>
        {description}
      </Paragraph>
      {graphDataset && (
        <BarChart
          disableAllAnimation={exportingPDF}
          dataset={graphDataset}
          mt={3}
          graphOptions={graphOptions}
          yAxisDecimalPlaces={yAxisDecimalPlaces}
          showDataLabels
          height={[250, 400, 550]}
          unit="%"
          yAxisLabel={yAxisLabel}
        />
      )}
    </ReportCard>
  )
}

export default BreakdownChartWrapper
