import { v4 as uuidv4 } from "uuid"

import tabs from "../../features/actionPlans/lib/tabs"
import { types } from "./actionPlans.actions"

const defaultActionPlansState = {
  list: [],
  count: 0,
  currentPage: 1,
  totalPages: 1,
  sort: {
    title: "date",
    order: -1
  },
  employeeActionPlans: null,
  managerActionPlans: null
}

export const initialState = {
  selectedTab: tabs[0].type,
  actionPlanStep: {
    formErrors: {}
  },
  selectedActionPlan: {
    sortOrder: {
      date: null,
      name: null
    }
  },
  actionPlans: defaultActionPlansState
}

const updateStepQuestionQuestion = (state, stepQuestionId, questions) => ({
  ...state,
  actionPlanStep: {
    ...state.actionPlanStep,
    questions: {
      ...state.actionPlanStep.questions,
      [stepQuestionId]: {
        ...state.actionPlanStep.questions[stepQuestionId],
        questions
      }
    }
  }
})

const updateStepQuestionSuggestions = (
  state,
  stepQuestionId,
  suggestion,
  suggestedLabel
) => ({
  ...state,
  actionPlanStep: {
    ...state.actionPlanStep,
    questions: {
      ...state.actionPlanStep.questions,
      [stepQuestionId]: {
        ...state.actionPlanStep.questions[stepQuestionId],
        [suggestedLabel]: JSON.stringify(suggestion)
      }
    }
  }
})

const updateStepQuestionResource = (state, stepQuestionId, resources) => ({
  ...state,
  actionPlanStep: {
    ...state.actionPlanStep,
    questions: {
      ...state.actionPlanStep.questions,
      [stepQuestionId]: {
        ...state.actionPlanStep.questions[stepQuestionId],
        resources
      }
    }
  }
})

const handleErrors = (field, error, stateErrors) => {
  if (stateErrors[field] && !error) {
    delete stateErrors[field]
    return stateErrors
  }
  if (!stateErrors[field] && error) return { ...stateErrors, [field]: error }

  return stateErrors
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.SET_FORM_ERRORS: {
      const { field, error } = payload

      return {
        ...state,
        actionPlanStep: {
          ...state.actionPlanStep,
          formErrors: handleErrors(
            field,
            error,
            state.actionPlanStep.formErrors
          )
        }
      }
    }
  }

  switch (type) {
    case types.SET_RESOURCE_DESCRIPTION: {
      const { description, resourceIndex, stepQuestionId } = payload

      const resourcesArray =
        state.actionPlanStep.questions[stepQuestionId].resources

      const resources = resourcesArray.map((resource, i) => {
        if (resourceIndex === i) return { ...resource, description }
        return resource
      })

      return updateStepQuestionResource(state, stepQuestionId, resources)
    }

    case types.SET_RESOURCE_MEDIA_TYPE: {
      const { mediaType, resourceIndex, stepQuestionId } = payload

      const resourcesArray =
        state.actionPlanStep.questions[stepQuestionId].resources

      const resources = resourcesArray.map((resource, i) => {
        if (resourceIndex === i) return { ...resource, mediaType }
        return resource
      })

      return updateStepQuestionResource(state, stepQuestionId, resources)
    }

    case types.SET_RESOURCE_URL: {
      const { url, resourceIndex, stepQuestionId } = payload

      const resourcesArray =
        state.actionPlanStep.questions[stepQuestionId].resources

      const resources = resourcesArray.map((resource, i) => {
        if (resourceIndex === i) return { ...resource, url }
        return resource
      })

      return updateStepQuestionResource(state, stepQuestionId, resources)
    }

    case types.SET_RESOURCE_TITLE: {
      const { title, resourceIndex, stepQuestionId } = payload

      const resourcesArray =
        state.actionPlanStep.questions[stepQuestionId].resources

      const resources = resourcesArray.map((resource, i) => {
        if (resourceIndex === i) return { ...resource, title }
        return resource
      })

      return updateStepQuestionResource(state, stepQuestionId, resources)
    }

    case types.DELETE_RESOURCE: {
      const { resourceIndex, stepQuestionId } = payload

      const resourcesArray =
        state.actionPlanStep.questions[stepQuestionId].resources

      return updateStepQuestionResource(state, stepQuestionId, [
        ...resourcesArray.slice(0, resourceIndex),
        ...resourcesArray.slice(resourceIndex + 1)
      ])
    }

    case types.ADD_NEW_RESOURCE: {
      const { emptyResource, stepQuestionId } = payload

      const resourcesArray =
        state.actionPlanStep.questions[stepQuestionId].resources

      return updateStepQuestionResource(state, stepQuestionId, [
        ...resourcesArray,
        emptyResource
      ])
    }

    case types.SET_DESCRIPTION: {
      const { description, stepQuestionId } = payload
      return updateStepQuestionSuggestions(
        state,
        stepQuestionId,
        description,
        "description"
      )
    }

    case types.SET_SUGGESTED_PHRASES: {
      const { phrases, stepQuestionId } = payload

      return updateStepQuestionSuggestions(
        state,
        stepQuestionId,
        phrases,
        "suggestedPhrases"
      )
    }

    case types.SET_SUGGESTED_ACTIONS: {
      const { actions, stepQuestionId } = payload
      return updateStepQuestionSuggestions(
        state,
        stepQuestionId,
        actions,
        "suggestedActions"
      )
    }

    case types.ADD_LABEL: {
      const { questionId, stepQuestionId } = payload

      // For some reason the state updates here right away, so we need to use JSON methods to decouple it from state
      const questionsArray = JSON.parse(
        JSON.stringify(state.actionPlanStep.questions[stepQuestionId].questions)
      )

      const questions = questionsArray.map(question => {
        if (question.id === questionId) {
          question.answers = [...question.answers, ""]
          return question
        }

        return question
      })

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.ADD_QUESTION: {
      const { questionIndex, stepQuestionId } = payload

      const questionsArray =
        state.actionPlanStep.questions[stepQuestionId].questions

      const questions = questionsArray.toSpliced(questionIndex + 1, 0, {
        answers: [""],
        title: "",
        id: uuidv4()
      })

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.SWAP_QUESTIONS: {
      const { aIndex, bIndex, stepQuestionId } = payload

      const questionsArray =
        state.actionPlanStep.questions[stepQuestionId].questions

      const questions = [...questionsArray]

      const swap = () =>
        ([questions[aIndex], questions[bIndex]] = [
          questions[bIndex],
          questions[aIndex]
        ])

      swap()

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.REMOVE_QUESTION: {
      const { index, stepQuestionId } = payload

      const questionsArray =
        state.actionPlanStep.questions[stepQuestionId].questions

      const questions = [
        ...questionsArray.slice(0, index),
        ...questionsArray.slice(index + 1)
      ]

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.DELETE_ANSWER: {
      const { index, questionId, stepQuestionId } = payload

      // For some reason the state updates here right away, so we need to use JSON methods to decouple it from state
      const questionsArray = JSON.parse(
        JSON.stringify(state.actionPlanStep.questions[stepQuestionId].questions)
      )

      const questions = questionsArray.map(question => {
        if (question.id === questionId) {
          question.answers = [
            ...question.answers.slice(0, index),
            ...question.answers.slice(index + 1)
          ]
          return question
        }

        return question
      })

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.SET_QUESTION_ANSWER: {
      const { answer, index, questionId, stepQuestionId } = payload

      const questionsArray =
        state.actionPlanStep.questions[stepQuestionId].questions

      const questions = questionsArray.map(question => {
        if (question.id === questionId) {
          question.answers[index] = answer
          return question
        }

        return question
      })

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.SET_QUESTION_TITLE: {
      const { title, id, stepQuestionId } = payload

      const questionsArray =
        state.actionPlanStep.questions[stepQuestionId].questions

      const questions = questionsArray.map(question => {
        if (question.id === id) return { ...question, title: title }
        return question
      })

      return updateStepQuestionQuestion(state, stepQuestionId, questions)
    }

    case types.SET_ACTION_PLAN_STEP: {
      const questions = payload?.step?.questions.reduce((acc, next) => {
        acc = {
          ...acc,
          [next.sixStepsQuestionId]: {
            title: next?.title,
            questions: next?.body?.questions,
            suggestedActions: next?.body?.suggestedActions,
            suggestedPhrases: next?.body?.suggestedPhrases,
            resources: next?.body?.resources,
            description: next?.body?.description
          }
        }

        return acc
      }, {})

      return {
        ...state,
        actionPlanStep: {
          ...state.actionPlanStep,
          ...payload.step,
          questions
        }
      }
    }

    case types.SET_CLONE_ACTION_PLAN: {
      return {
        ...state,
        actionPlans: {
          ...defaultActionPlansState,
          list:
            payload?.actionPlans?.createActionPlan?.actionPlans ||
            state.actionPlans.list
        }
      }
    }

    case types.SET_LIST_ACTION_PLANS_SORT: {
      const previousTitle = state.actionPlans.sort.title
      const newTitle = payload.title
      const differentOrder = state.actionPlans.sort.order === 1 ? -1 : 1

      return {
        ...state,
        actionPlans: {
          ...state.actionPlans,
          sort: {
            title: newTitle,
            order: previousTitle !== newTitle ? 1 : differentOrder
          }
        }
      }
    }

    case types.SET_LIST_ACTION_PLANS_PAGE: {
      return {
        ...state,
        actionPlans: {
          ...state.actionPlans,
          currentPage: payload.page + 1
        }
      }
    }

    case types.SET_TAB: {
      return {
        ...state,
        selectedTab: payload.type,
        actionPlans: defaultActionPlansState
      }
    }

    case types.SET_ACTION_PLAN_LIST: {
      const list = () => {
        if (!payload.actionPlans?.listActionPlans?.actionPlans)
          return state?.actionPlans?.list

        const remainingPlans = [
          ...payload.actionPlans?.listActionPlans?.actionPlans
        ].filter(
          obj =>
            !state?.actionPlans?.list
              ?.map(obj => JSON.stringify(obj))
              ?.includes(JSON.stringify(obj))
        )

        console.log(remainingPlans)
        if (state.actionPlans.currentPage === 1) {
          return payload.actionPlans?.listActionPlans?.actionPlans
        } else if (
          state.actionPlans.currentPage > 1 &&
          remainingPlans.length > 1
        ) {
          const res = payload.actionPlans?.listActionPlans?.actionPlans || []
          return [...state?.actionPlans?.list, ...res]
        } else {
          return state?.actionPlans?.list
        }
      }

      return {
        ...state,
        actionPlans: {
          ...state.actionPlans,
          list: list(),
          count: payload.actionPlans?.listActionPlans?.count,
          totalPages: payload.actionPlans?.listActionPlans?.totalPages,
          employeeActionPlans:
            payload.actionPlans?.listActionPlans?.employeeActionPlans,
          managerActionPlans:
            payload.actionPlans?.listActionPlans?.managerActionPlans
        }
      }
    }

    case types.SET_ACTION_PLAN: {
      return {
        ...state,
        selectedActionPlan: {
          ...payload?.actionPlan?.getActionPlan,
          ...state.selectedActionPlan
        }
      }
    }

    case types.SORT_ASSIGNED_SURVEYS: {
      const sortOrder = state.selectedActionPlan.sortOrder[payload.sortBy]

      const sortedAssignedTo = state.selectedActionPlan.assignedTo.sort(
        (a, b) => {
          const itemA = a[payload.sortBy]
          const itemB = b[payload.sortBy]

          if (itemA < itemB) {
            return sortOrder === null || sortOrder === 1 ? -1 : 1
          }

          if (itemA > itemB) {
            return sortOrder === null || sortOrder === 1 ? 1 : -1
          }
        }
      )

      return {
        ...state,
        selectedActionPlan: {
          ...state.selectedActionPlan,
          assignedTo: sortedAssignedTo,
          sortOrder: {
            ...state.selectedActionPlan.sortOrder,
            [payload.sortBy]: sortOrder === null || sortOrder === 1 ? -1 : 1
          }
        }
      }
    }

    default:
      return state
  }
}
