import { Flex, H2, Paragraph } from "@engaging-tech/components"
import React from "react"

import { Card } from "../../../components"
import LogoPicker from "../../../components/logo-picker"
import ColourPicker from "./ColourPicker"

const Branding = ({ values, errors, touched, handleChange, setFieldValue, surveyId, readOnly, ...props }) => {
  return (
    <Card {...props}>
      <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
        Your survey branding
      </H2>
      <Paragraph>
        Add company branding to your survey - add brand colours and your logo to make the survey feel
        personalised. As a default the WorkL branding will appear as below.
      </Paragraph>
      <Flex flexDirection={["column", "row"]} width={1 / 1}>
        <ColourPicker values={values} errors={errors} setFieldValue={setFieldValue} readOnly={readOnly} />
        <LogoPicker
          values={values}
          errors={errors}
          readOnly={readOnly}
          setFieldValue={setFieldValue}
          surveyId={surveyId}
        />
      </Flex>
    </Card>
  )
}

export default Branding
