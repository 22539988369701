import { Box, Paragraph, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { Card } from "../../components"
import TemplateOptionsComponent from "../../templateSelection/containers/TemplateOptions"

const TemplateOptionsWrapper = ({
  values,
  errors,
  setFieldValue,
  readOnly,
  ...props
}) => {
  // Disables rest of template options when navigating from telegraph template
  const { telegraphTemplateId } = getConfig().other
  let parameterTemplateId
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    parameterTemplateId = urlParams.get("templateId")
  }
  return (
    <Card {...props}>
      <Paragraph fontSize={[4, 5]}>
        Next step is to select a survey template. If you’d like to create your
        own bespoke survey template and add your own questions then visit the{" "}
        <Link to="/surveys/templates">
          <Text fontSize={[4, 5]} fontWeight={700} color="primary.0">
            Build a Template
          </Text>
        </Link>{" "}
        section.
      </Paragraph>
      <Box
        bg={(errors && errors.template && "#ffe7e7") || "transparent"}
        borderRadius={10}
        p={2}
      >
        <TemplateOptionsComponent
          addSelect
          disableIcons
          noHover={readOnly || parameterTemplateId === telegraphTemplateId}
          showViewOnlyButton={parameterTemplateId !== telegraphTemplateId}
          setTemplateSelected={template =>
            readOnly ? null : setFieldValue("template", template)
          }
          templateSelected={values && values.template}
        />
      </Box>
      {errors && errors?.template && (
        <Text color="error.0" fontSize={2} ml={10}>
          {errors?.template?.id}
        </Text>
      )}
    </Card>
  )
}

export default TemplateOptionsWrapper
