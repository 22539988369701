import { Button, Flex, Text } from "@engaging-tech/components"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { SAVE_BASKET } from "../../../../graphql/mutations"
import { FETCH_PRODUCTS } from "../../../../graphql/queries"
import useBusinessAppMutation from "../../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../../hooks/useBusinessAppQuery"
import {
  saveBasketFail,
  saveBasketReq,
  saveBasketSuccess
} from "../../../../store/basket/basket.actions"
import {
  getProductsFail,
  getProductsReq,
  getProductsSuccess
} from "../../../../store/products/products.actions"
import { getSliceAndDiceProduct } from "../../../../store/products/products.selectors"
import SliceAndDiceCard from "../../../selfServiceSurveys/components/Page2/SliceAndDiceCard"
import SliceAndDiceCompareModal from "../SliceAndDiceCompareModal"

const UnderlinedText = styled(Text)`
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`

const SliceAndDiceUpsellIAS = ({ reportId, saveUpgradeIdOnStorage }) => {
  const history = useHistory()
  const sliceAndDiceProduct = useSelector(getSliceAndDiceProduct)
  const [productSelectedId, setProductSelectedId] = useState(undefined)
  const [showCompareSDModal, setShowCompareSDModal] = useState(false)
  const product = {
    id: 2,
    title: "Enhanced Data Insight Package",
    description: "Includes all Standard insights, plus:",
    infoTopics: [
      "Instant Action Planning",
      "Filter results by demographics",
      "Heatmaps",
      "Detailed action plans and supportive resources"
    ],
    priceCurrency: sliceAndDiceProduct?.currency,
    priceValue: sliceAndDiceProduct?.unit_amount
  }

  const { errors, isLoading } = useBusinessAppQuery(FETCH_PRODUCTS, {
    initAction: getProductsReq,
    successAction: getProductsSuccess,
    failAction: getProductsFail,
    variables: { object_type: "slice-and-dice", surveyId: reportId }
  })

  const { errors: basketErrors, post } = useBusinessAppMutation(SAVE_BASKET, {
    initAction: saveBasketReq,
    successAction: saveBasketSuccess,
    failAction: saveBasketFail
  })

  const handleSelect = id => {
    setProductSelectedId(id)
  }

  const goToBasketPage = () => {
    if (saveUpgradeIdOnStorage) {
      window.localStorage.setItem("surveyIdUpgrade", reportId)
    }
    history.push("/basket")
  }

  const addProductAndGoToBasket = async () => {
    await post(
      {
        productId: sliceAndDiceProduct.product_details.id,
        priceId: sliceAndDiceProduct.id,
        object_type: "slice-and-dice"
      },
      () => dispatch(setAddedToBasket(sliceAndDiceProduct.name))
    )
    goToBasketPage()
  }

  if (
    errors ||
    basketErrors ||
    isLoading ||
    !sliceAndDiceProduct?.currency ||
    !sliceAndDiceProduct?.unit_amount
  ) {
    return null
  }

  return (
    <>
      <Flex
        justifyContent="center"
        mt={4}
        flexDirection="column"
        alignItems="center"
      >
        <Text
          width="fit-content"
          color="secondary.0"
          fontSize={5}
          fontWeight={600}
          mb={3}
        >
          Want more data and insight?
        </Text>
        <Text width="500px" color="secondary.0" mb={4} textAlign="center">
          Add the Enhanced Data Insight Package to access Action Plans and more
          detailed data and insight.
        </Text>
        <UnderlinedText
          width="fit-content"
          color="secondary.0"
          mb={4}
          onClick={() => setShowCompareSDModal(true)}
        >
          <u>What's Included?</u>
        </UnderlinedText>
        <Flex width={"520px"}>
          <SliceAndDiceCard
            key={`SliceAndDiceCard-${product.id}`}
            id={product.id}
            selected={productSelectedId === product.id}
            title={product.title}
            description={product.description}
            infoTopics={product.infoTopics}
            priceValue={product.priceValue}
            priceCurrency={product.priceCurrency}
            surveyValue={product.surveyValue}
            dataValue={product.dataValue}
            handleSelect={handleSelect}
          />
        </Flex>
        <Flex justifyContent="center">
          <Button
            mt={5}
            bg={productSelectedId ? "primary.0" : "dark.2"}
            color="light.0"
            width="fit-content"
            disabled={!productSelectedId}
            fontSize={3}
            height="30px"
            onClick={addProductAndGoToBasket}
          >
            ADD ENHANCED DATA PACKAGE
          </Button>
        </Flex>
      </Flex>
      {showCompareSDModal && (
        <SliceAndDiceCompareModal setShowPreview={setShowCompareSDModal} />
      )}
    </>
  )
}

export default SliceAndDiceUpsellIAS
