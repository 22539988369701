import { Button, Flex, Icon, Modal, Text } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

export default function ConfirmDiscardModal({ onConfirm, onCancel }) {
  return createPortal(
    <Modal
      bg="light.0"
      minWidth={900}
      animated
      disableScrollbar
      p={3}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Icon name="warning" size={60} color="primary.0" />
      <Text mb={2}>Are you sure you want to discard changes? All unsaved changes will be lost.</Text>

      <Flex justifyContent="space-around">
        <Button
          width={150}
          bg="secondary.0"
          color="light.0"
          onClick={e => {
            e.preventDefault()
            onConfirm()
          }}
        >
          Continue
        </Button>
        <Button
          width={150}
          bg="primary.0"
          color="light.0"
          onClick={e => {
            e.preventDefault()
            onCancel()
          }}
        >
          Cancel
        </Button>
      </Flex>
    </Modal>,
    appRoot()
  )
}
