import { connect } from "react-redux"
import FiltersButton from "../components/FiltersButton"
import * as Selectors from "../../Filters/store/filters.selectors"
import * as ReportSelectors from "../../store/report.selectors"

const mapState = state => ({
  activeFilters: ReportSelectors.getAppliedHeatmapFilters(state),
  availableFilters: Selectors.getAvailableFilters(state)
})

const Filters = connect(mapState)(FiltersButton)

export default Filters
