import { Auth } from 'aws-amplify';
import { ApolloLink, createHttpLink, from, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const LEGACY_API_URI = 'https://dev.api.workl.com/graphql';
const AMPLIFY_API_URI = 'https://rhslow3j6jec7gbq3sta5tzeqy.appsync-api.eu-west-2.amazonaws.com/graphql';

// Utility to create an authentication link based on the type
const createAuthLink = (type) =>
  setContext(async (_, { headers: _headers }) => {
    const headers = { ..._headers };

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const authScheme = type === 'legacy' ? 'JWT' : 'Bearer';
      return {
        headers: {
          ...headers,
          Authorization: `${authScheme} ${token}`,
        },
      };
    } catch (error) {
      return { headers };
    }
  });

// Utility to create a link for a specific URI and auth type
const createLinkForUri = (uri, authType) => {
  const httpLink = createHttpLink({ uri });
  const authLink = createAuthLink(authType);
  return from([authLink, httpLink]);
};

// Factory function to create an Apollo Client
export const createApolloClient = () => {
  const legacyLink = createLinkForUri(LEGACY_API_URI, 'legacy');
  const amplifyLink = createLinkForUri(AMPLIFY_API_URI, 'amplify');

  // Link selection based on operation context
  const linkSelection = new ApolloLink((operation, forward) => {
    const useAmplifyLink = Boolean(operation.getContext().useAmplifyLink);
    return (useAmplifyLink ? amplifyLink : legacyLink).request(operation, forward);
  });

  return new ApolloClient({
    link: from([linkSelection]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};
