import { put, takeLatest } from "redux-saga/effects"
import { loadAllSurveyJobRoles } from "../../services/allSurveyJobRoles/allSurveyJobRoles.service"
import * as allSurveyJobRolesActions from "./allSurveyJobRoles.actions"
import { types } from "./allSurveyJobRoles.actions"

export function* loadSaga() {
  try {
    const res = yield loadAllSurveyJobRoles()
    yield put(allSurveyJobRolesActions.loadSuccess(res.data.allSurveyJobRoles))
  } catch {
    yield put(allSurveyJobRolesActions.loadFailure())
  }
}

export function* saga() {
  yield takeLatest(types.LOAD, loadSaga)
}
