export const types = {
  LOAD_LECTURES: "[EBN] Load Lectures",
  LOAD_LECTURES_SUCCESS: "[EBN] Load Lectures Success",
  LOAD_LECTURES_FAIL: "[EBN] Load Lectures Fail",
  LOAD_RESEARCH: "[EBN] Load All Research",
  LOAD_RESEARCH_SUCCESS: "[EBN] Load All Research Success",
  LOAD_RESEARCH_FAIL: "[EBN] Load All Research Fail",
  LOAD_ARTICLES: "[EBN] Load Articles",
  LOAD_ARTICLES_SUCCESS: "[EBN] Load Articles Success",
  LOAD_ARTICLES_FAIL: "[EBN] Load Articles Fail",
  LOAD_WEBINARS: "[EBN] Load Webinars",
  LOAD_WEBINARS_SUCCESS: "[EBN] Load Webinars Success",
  LOAD_WEBINARS_FAIL: "[EBN] Load Webinars Fail",
  CLOSE_EBN_MODAL: "[EBN] Close EBN Modal"
}

export const loadLectures = payload => ({
  type: types.LOAD_LECTURES,
  payload
})

export const loadLecturesSuccess = payload => ({
  type: types.LOAD_LECTURES_SUCCESS,
  payload
})

export const loadLecturesFail = () => ({
  type: types.LOAD_LECTURES_FAIL
})

export const loadResearch = payload => ({
  type: types.LOAD_RESEARCH,
  payload
})

export const loadResearchSuccess = payload => ({
  type: types.LOAD_RESEARCH_SUCCESS,
  payload
})

export const loadResearchFail = () => ({
  type: types.LOAD_RESEARCH_FAIL
})

export const loadArticles = payload => ({
  type: types.LOAD_ARTICLES,
  payload
})

export const loadArticlesSuccess = payload => ({
  type: types.LOAD_ARTICLES_SUCCESS,
  payload
})

export const loadArticlesFail = () => ({
  type: types.LOAD_ARTICLES_FAIL
})

export const loadWebinars = payload => ({
  type: types.LOAD_WEBINARS,
  payload
})

export const loadWebinarsSuccess = payload => ({
  type: types.LOAD_WEBINARS_SUCCESS,
  payload
})

export const loadWebinarsFail = () => ({
  type: types.LOAD_WEBINARS_FAIL
})

export const closeEBNModal = () => ({
  type: types.CLOSE_EBN_MODAL
})
