import { Flex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { AccountSelectors } from "../../../../account"
import FormTitle from "../../../../ui/components/FormTitle"
import ImageUploadForm from "../../../components/image-upload-form"
import BasicInformationSection from "./basic-information-section"
import BusinessDetailsSection from "./business-details-section"
import BusinessLocationSection from "./business-location-section"
import BusinessSocialSection from "./business-social-section"

export default function BusinessInformationForm({ isLoading, onSubmit }) {
  const orgId = useSelector(AccountSelectors.getOrgId)

  return (
    <>
      <FormTitle text="Use this section to add the important information about your business.">
        Business Information
      </FormTitle>
      <Flex width={1 / 1} flexDirection="column" mt={20}>
        <ImageUploadForm
          id="logoUrl"
          title="Add Your Business Logo"
          subTitle="Upload a logo to make your profile more eye catching. Please make sure your image file is below 80MB before uploading."
          icon="logo"
          orgId={orgId}
          disabled={isLoading}
          onSubmit={onSubmit}
        />
        <ImageUploadForm
          id="banner"
          title="Banner"
          subTitle="Upload a banner image to make your profile more eye catching. Please make sure your image file is below 80MB before uploading."
          icon="banner"
          orgId={orgId}
          disabled={isLoading}
          onSubmit={onSubmit}
        />
        <BasicInformationSection isLoading={isLoading} />
        <BusinessDetailsSection isLoading={isLoading} />
        <BusinessLocationSection isLoading={isLoading} />
        <BusinessSocialSection isLoading={isLoading} />
      </Flex>
    </>
  )
}
