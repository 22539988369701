import { Box } from "@engaging-tech/components"
import { CookiePolicy } from "@engaging-tech/legal"
import React from "react"

import PageLayout from "../../layouts/PageLayout"
import { firstPartyCookies, thirdPartyCookies } from "../../lib/legal"

const Cookies = () => (
  <PageLayout>
    <Box pt={[3, 6]} px={[3, 4]} maxWidth={1080} mx="auto">
      <CookiePolicy
        date={new Date("2024-01-01")}
        siteName="Engaging Business"
        firstPartyCookies={firstPartyCookies}
        thirdPartyCookies={thirdPartyCookies}
      />
    </Box>
  </PageLayout>
)

export default Cookies
