import { Flex } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"
import TemplateOptions from "../containers/TemplateOptions"
import DashboardTopBanner from "../../components/DashboardTopBanner"

const Templates = () => {
  const paths = usePaths()

  return (
    <Flex flexDirection="column">
      <DashboardTopBanner
        title="Survey Templates Dashboard"
        subtitle="To create a new survey template, start with your own, WFB or create one from scratch."
        buttonPath={paths.surveys.buildATemplate.createTemplate}
        buttonText="Create Template"
      />
      <TemplateOptions />
    </Flex>
  )
}

export default Templates
