import { Flex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { Card } from "../components"
import LogoPicker from "../components/logo-picker"
import { getSurvey } from "../survey-view-mode/store/survey-view-mode.selectors"
import Privacy from "../surveyInfo/components/Privacy"
import ColourPickerFormField from "./components/colour-picker-form-field"
import SurveyDatesForm from "./components/survey-dates-form"
import TemplateFormField from "./components/template-form-field"
import TitleFormField from "./components/title-form-field"

export default function MainForm(props) {
  const survey = useSelector(getSurvey)

  return (
    <>
      <TitleFormField mt="20px" {...props} />

      {props.values?.status === "draft" ? <TemplateFormField mt="10px" {...props} /> : null}

      <SurveyDatesForm mt="10px" startDateDisabled={props.values?.status !== "draft"} {...props} />

      {props?.surveyType !== "awards" ? (
        <Card mt="10px">
          <Flex flexDirection={["column", "row"]} width={1 / 1}>
            <ColourPickerFormField {...props} />
            <LogoPicker {...props} />
          </Flex>
        </Card>
      ) : null}

      {survey?.type !== "awards" && !survey?.isSelfServe ? <Privacy mt="10px" {...props} /> : null}
    </>
  )
}
