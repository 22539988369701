import React from "react"
import AwardSignUpSuccessView from "../../features/account/views/AwardSignUpSuccessView"
import GradientLayout from "../../layouts/GradientLayout"

const AwardSignUpSuccess = () => (
  <GradientLayout>
    <AwardSignUpSuccessView />
  </GradientLayout>
)

export default AwardSignUpSuccess