const personalities = [
  {
    code: "ISTJ",
    description:
      "Responsible, sincere, analytical, reserved, realistic, systematic, hardworking and trustworthy"
  },
  {
    code: "ISFJ",
    description:
      "Warm, considerate, gentle, responsible, pragmatic and thorough"
  },
  {
    code: "INFJ",
    description:
      "Idealistic, organised, insightful, dependable, compassionate and gentle"
  },
  {
    code: "INTJ",
    description:
      "Innovative, independent, strategic, logical, reserved and insightful"
  },
  {
    code: "ISTP",
    description:
      "Action-orientated, logical, analytical, spontaneous, reserved and independent"
  },
  {
    code: "ISFP",
    description: "Gentle, sensitive, nurturing, helpful, flexible and realistic"
  },
  {
    code: "INFP",
    description: "Sensitive, creative, idealistic, perceptive, caring and loyal"
  },
  {
    code: "INTP",
    description:
      "Intellectual, logical, precise, reserved, flexible and imaginative"
  },
  {
    code: "ESTP",
    description:
      "Outgoing, realistic, action-orientated, curious, versatile and spontaneous"
  },
  {
    code: "ESFP",
    description:
      "Playful, enthusiastic, friendly, spontaneous, tactful and flexible"
  },
  {
    code: "ENFP",
    description:
      "Enthusiastic, creative, spontaneous, optimistic, supportive and playful"
  },
  {
    code: "ENTP",
    description:
      "Inventive, enthusiastic, strategic, enterprising, inquisitive and versatile"
  },
  {
    code: "ESTJ",
    description:
      "Efficient, outgoing, analytical, systemic, dependable and realistic"
  },
  {
    code: "ESFJ",
    description:
      "Friendly, outgoing, reliable, conscientious, organised and practical"
  },
  {
    code: "ENFJ",
    description:
      "Caring, enthusiastic, idealistic, organised, diplomatic and responsible"
  },
  {
    code: "ENTJ",
    description:
      "Strategic, logical, efficient, outgoing, ambitious and independent"
  }
]

export default personalities
