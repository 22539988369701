import { useParams } from "@engaging-tech/routing"
import { useEffect } from "react"
import { connect } from "react-redux"

import { ReportSelectors } from ".."
import { deleteTypeNames } from "../../../utils"
import deepEqual from "../../utils/deepEqual"
import * as FiltersSelectors from "../Filters/store/filters.selectors"
import { load } from "../store/report.actions"
import { surveyTypes } from "../views/ReportWrapper"

const areFiltersDifferent = (newFilters, currentFilters, requiredFilter, activeRequiredFilter) => {
  deleteTypeNames(newFilters)
  deleteTypeNames(currentFilters)
  const isEqual = deepEqual(newFilters, currentFilters)
  if (!isEqual) return true
  if (!requiredFilter || !activeRequiredFilter) return false
  return !(
    requiredFilter.questionId === activeRequiredFilter.questionId &&
    requiredFilter.valueId === activeRequiredFilter.valueId
  )
}

const LoaderComponent = ({
  currentSurveyId,
  onLoad,
  directReports,
  isCurrentDirectReports,
  newFilters,
  currentFilters,
  isLoading,
  activeRequiredFilter,
  surveyType
}) => {
  const { id, surveyType: type } = useParams()

  useEffect(() => {
    const diff = areFiltersDifferent(newFilters || [], currentFilters || [], activeRequiredFilter)

    if (currentSurveyId !== id || diff || isCurrentDirectReports !== directReports) {
      if (!isLoading) {
        onLoad(id, directReports, newFilters, surveyType || surveyTypes[type])
      }
    }
  }, [newFilters])

  return null
}

const mapState = state => ({
  isLoading: ReportSelectors.getIsLoading(state),
  currentSurveyId: ReportSelectors.getIdForFilters(state),
  currentFilters: FiltersSelectors.getCurrentFilters(state),
  newFilters: FiltersSelectors.getFilters(state),
  directReports: FiltersSelectors.getDirectReports(state),
  isCurrentDirectReports: FiltersSelectors.getIsCurrentDirectReports(state),
  requiredFilter: ReportSelectors.getRequiredFilter(state),
  activeRequiredFilter: ReportSelectors.getActiveRequiredFilter(state),
  surveyType: ReportSelectors.getSurveyType(state)
})

const mapDispatch = dispatch => ({
  onLoad: (id, directReports, filters, requiredFilter, surveyType) => {
    dispatch(load(id, directReports, filters, requiredFilter, surveyType))
  }
})

const Loader = connect(mapState, mapDispatch)(LoaderComponent)

export default Loader
