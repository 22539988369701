import { gql } from "@apollo/client"

const HANDLE_INVOICE = gql`
  mutation handleInvoice(
    $firstName: String!
    $lastName: String!
    $email: String!
    $addressFirstLine: String!
    $addressSecondLine: String
    $postcode: String!
    $city: String!
    $country: String!
    $companyName: String!
  ) {
    handleInvoice(
      firstName: $firstName
      lastName: $lastName
      email: $email
      addressFirstLine: $addressFirstLine
      addressSecondLine: $addressSecondLine
      postcode: $postcode
      city: $city
      country: $country
      companyName: $companyName
    )
  }
`

export default HANDLE_INVOICE
