import React from "react"
import ReportLayout from "../../layouts/ReportLayout"
import ReportWrapper from "../../features/Reports/views/ReportWrapper"
import { OrgGuard } from "../../features/account"

const Empowerment = () => {
  return (
    <OrgGuard>
      <ReportLayout>
        <ReportWrapper>
          <button>Add import here</button>
        </ReportWrapper>
      </ReportLayout>
    </OrgGuard>
  )
}

export default Empowerment
