/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query getSurveyReport(
    $id: ID!
    $directReports: Boolean!
    $reportFilters: [ReportFilterInput]
    $surveyType: [String!]
  ) {
    getSurveyReport(
      # the ID accepted is either surveyID for organisation or reportId for NS/EE reports
      id: $id
      directReports: $directReports
      reportFilters: $reportFilters
      surveyType: $surveyType
    ) {
      id
      reportId
      type
      isDefault
      title
      meta {
        startDate
        endDate
      }
      status
      layout {
        name
        children {
          name
          children {
            name
          }
        }
      }
      ... on EmployeeStarterSurveyReport {
        data {
          happinessDataHighlightOverview {
            score
            industry
            global
          }
          coreQuestionsDetails {
            question
            type
            score
          }
        }
      }
      ... on EmployeeExitSurveyReport {
        data {
          happinessDataHighlightOverview {
            score
            industry
            global
          }
          coreQuestionsDetails {
            question
            type
            score
          }
        }
      }

      ... on OrganisationSurveyReport {
        sliceAndDice {
          id
          active
          features
        }
        isDirectReport
        directReportsAvailable
        matchedSurveys {
          _id
          title
        }
        data {
          nps {
            question
            questionId
            average {
              median
              mode
              mean
            }
            answers {
              answer
              count
              percentage
            }
            totalRespondents
            scores {
              promoters
              passives
              detractors
              index
            }
          }
          wordCounts {
            questionId
            question
            words {
              value
              count
            }
          }
          happinessDataHighlight {
            partial
            score
            rootReport
            trend
            industry
            global
          }
          sixStepsComparison {
            name
            partial
            score
            rootReport
            industry
            global
          }
          surveySixSteps {
            actionPlanStepId
            name
            partial
            score
            industry
            questions
          }
          sixStepsBreakdown {
            step
            title
            description
            categories {
              type
              labels {
                x
                y
              }
              values {
                name
                value
              }
            }
          }
          customSteps {
            step
            score
            questions {
              id
              question
              values {
                score
              }
            }
          }
          customQuestions {
            id
            question
            type
            score
            values {
              score
              answer
            }
          }
          sixStepsQuestions {
            step
            title
            description
            questions {
              id
              question
              values {
                score
                rootReport
                industry
                global
              }
            }
            labels {
              x
              y
            }
          }
          lowestStep {
            score
            step
          }
          matrices {
            name
            title
            description
            labels {
              x {
                heading
                questions
              }
              y {
                heading
                questions
              }
            }
            bands {
              topLeft {
                title
                description
              }
              topRight {
                title
                description
              }
              bottomLeft {
                title
                description
              }
              bottomRight {
                title
                description
              }
            }
            values {
              x
              y
              z
            }
          }
          indicators {
            diIndex {
              section
              minority {
                score
                global
                industry
              }
              majority {
                score
                global
                industry
              }
              questions {
                questionId
                question
              }
            }
            flightRisk {
              total
              count
              industry
              global
              percentage
              average {
                score
                global
                industry
              }
              questions {
                score
                global
                industry
                questionId
                question
              }
            }
            managementConfidence {
              average {
                score
                global
                industry
              }
              questions {
                score
                global
                industry
                questionId
                question
              }
            }
            wellBeingRisk {
              total
              count
              industry
              global
              percentage
              average {
                score
                global
                industry
              }
              questions {
                score
                global
                industry
                questionId
                question
              }
            }
          }
        }
        responseSize
        responseRate
        textResponsesUrl
        availableFilters {
          questionId
          question
          values {
            value
            valueId
          }
        }
        filters {
          applied {
            questionId
            question
            values {
              value
              valueId
            }
          }
        }
      }
    }
  }
`

export const LOAD_REPORTS = gql`
  query listSurveyReports($reportSearchFilters: ReportSearchFilters!) {
    listSurveyReports(reportSearchFilters: $reportSearchFilters) {
      id
      reportId
      status
      title
      ... on AwardsSurveyReport {
        id
        responseSize
        responseRate
        meta {
          startDate
          endDate
        }
        data {
          languages {
            language
            count
          }
          happinessDataHighlight {
            score
          }
        }
      }
      ... on OrganisationSurveyReport {
        id
        responseSize
        responseRate
        meta {
          startDate
          endDate
        }
        data {
          languages {
            language
            count
          }
          happinessDataHighlight {
            score
          }
        }
      }
      ... on EmployeeStarterSurveyReport {
        isRoot
        createdAt
        data {
          languages {
            language
            count
          }
          happinessDataHighlightOverview {
            score
          }
        }
      }
      ... on EmployeeExitSurveyReport {
        isRoot
        createdAt
        data {
          languages {
            language
            count
          }
          happinessDataHighlightOverview {
            score
          }
        }
      }
    }
  }
`

export const LOAD_REPORTS_V2 = gql`
  query listSurveyReportsV2(
    $limit: Int
    $skip: Int
    $reportSearchFilters: ReportSearchFilters!
  ) {
    listSurveyReportsV2(
      limit: $limit
      skip: $skip
      reportSearchFilters: $reportSearchFilters
    ) {
      id
      reportId
      status
      title
      ... on AwardsSurveyReport {
        id
        responseSize
        responseRate
        meta {
          startDate
          endDate
        }
        data {
          languages {
            language
            count
          }
          happinessDataHighlight {
            score
          }
        }
      }
      ... on OrganisationSurveyReport {
        id
        responseSize
        responseRate
        meta {
          startDate
          endDate
        }
        data {
          languages {
            language
            count
          }
          happinessDataHighlight {
            score
          }
        }
      }
      ... on EmployeeStarterSurveyReport {
        isRoot
        createdAt
        data {
          languages {
            language
            count
          }
          happinessDataHighlightOverview {
            score
          }
        }
      }
      ... on EmployeeExitSurveyReport {
        isRoot
        createdAt
        data {
          languages {
            language
            count
          }
          happinessDataHighlightOverview {
            score
          }
        }
      }
    }
  }
`

export const DELETE_REPORTS = gql`
  mutation deleteEmployeeReports($reportIds: [String]!) {
    deleteEmployeeReports(reportIds: $reportIds)
  }
`

export const CREATE_LATEST_REPORT = gql`
  mutation createLatestReport($surveyType: String!) {
    createLatestReport(surveyType: $surveyType) {
      id
    }
  }
`

export const GENERATE_REPORT = gql`
  mutation createEmployeeReport($title: String!, $surveyIds: [String]!) {
    createEmployeeReport(title: $title, surveyIds: $surveyIds) {
      id
    }
  }
`

export const GET_SURVEY_COMPARISON_DATA = gql`
  query getSurveyComparisonData(
    $surveyType: [String]!
    $startDate: Date
    $endDate: Date
    $subTypes: [String]
  ) {
    getSurveyComparisonData(
      surveyType: $surveyType
      startDate: $startDate
      endDate: $endDate
      subTypes: $subTypes
    ) {
      reportId
      title
      scores {
        score
        section
      }
      date
    }
  }
`
