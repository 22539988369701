import { Spinner } from "@engaging-tech/components"
import React from "react"

import { GET_LIVE_JOBS } from "../../../graphql/queries"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"
import { getLiveJobsFail, getLiveJobsSuccess } from "../../../store/jobs/jobs.actions"
import ErrorState from "../../business-information/components/error-state"
import DisplayProduct from "../../ui/components/DisplayProduct"
import LiveJobs from "../containers/LiveJobs"

const Home = () => {
  const { errors, isLoading } = useAppSyncQuery(GET_LIVE_JOBS, {
    successAction: getLiveJobsSuccess,
    failAction: getLiveJobsFail
  })

  if (errors?.errors.filter(error => error.errorType === "ERR403")[0]) return <DisplayProduct />
  if (errors) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return <LiveJobs />
}

export default Home
