import React from "react"
import { Paragraph } from "@engaging-tech/components"
import ReportCard from "../../components/ReportCard"
import SplitByCategory from "./SplitByCategory"

const PDFView = ({ categories, title, description }) => {
  return categories.map(category =>
    category.type ? (
      <ReportCard heading={title} width={1 / 1} exportingPDF>
        <Paragraph color="dark.2" fontSize={3}>
          {description}
        </Paragraph>
        <SplitByCategory key={category.type} category={category} exportingPDF />
      </ReportCard>
    ) : (
      <></>
    )
  )
}

const Breakdowns = ({ title, description, categories, exportingPDF }) => {
  if (exportingPDF && categories && categories.length > 0) {
    return (
      <PDFView
        categories={categories}
        title={title}
        description={description}
      />
    )
  }

  return (
    categories &&
    categories.length > 0 && (
      <ReportCard heading={title} width={1 / 1}>
        <Paragraph color="dark.2" fontSize={3}>
          {description}
        </Paragraph>
        {categories.map(
          category =>
            category.type && (
              <SplitByCategory key={category.type} category={category} />
            )
        )}
      </ReportCard>
    )
  )
}

export default Breakdowns
