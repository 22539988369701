/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { OrgGuard } from "../../features/account"
import Banner from "../../features/selfServiceSurveys/components/Banner"
import PageManagerContainer from "../../features/selfServiceSurveys/containers/PageManager"
import { engagementPages } from "../../features/selfServiceSurveys/lib/pageControl"
import {
  setObjectType,
  setSurveyType
} from "../../features/selfServiceSurveys/store/actions"
import PageLayout from "../../layouts/PageLayout"

const OrganisationSurvey = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setSurveyType("organisationSurvey"))
    dispatch(setObjectType(history.location.pathname.split("/")[2]))
  }, [])

  return (
    <OrgGuard>
      <PageLayout banner={Banner}>
        <PageManagerContainer pages={engagementPages} />
      </PageLayout>
    </OrgGuard>
  )
}

export default OrganisationSurvey
