import React from "react"
import { OrgGuard } from "../../features/account"
import PageLayout from "../../layouts/PageLayout"
import BasketView from "../../features/basket/views/BasketView"
import { PaddedBox } from "../awards/EnterAwards"

const Basket = () => {
  return (
    <OrgGuard>
      <PageLayout>
        <PaddedBox>
          <BasketView />
        </PaddedBox>
      </PageLayout>
    </OrgGuard>
  )
}

export default Basket
