import { call, delay, put, select, takeLatest } from "redux-saga/effects"
import {
  createQuestionFromBackend,
  mapBackendLayoutToFrontendLayout,
  mapFrontendLayoutToBackendLayout
} from "../../helpers/layoutMapperHelper"
import otherHelper from "../../helpers/otherHelper"
import questionIconOptions from "../../lib/questionIconOptions"
import {
  loadTemplate,
  saveTemplate
} from "../../services/activeTemplate/activeTemplate.service"
import { CoreQuestionsActions, CoreQuestionsSelectors } from "../coreQuestions"
import { GenericTemplateActions } from "../generic"
import * as Actions from "./activeTemplate.actions"
import { defaultQuestionObject } from "./activeTemplate.reducer"
import * as Selectors from "./activeTemplate.selectors"

const getRandomIcon = () => {
  const maxNumber = questionIconOptions.length - 1
  return questionIconOptions[Math.floor(Math.random() * maxNumber)]
}

export function* scrollToElement(action) {
  yield delay(1100)

  if (typeof action.payload.sectionIndex === "number") {
    let element
    if (action.payload.type === "CORE" || action.payload.type === "CUSTOM") {
      element = document.getElementById(
        `section-${action.payload.sectionIndex}-question-${parseInt(
          action.payload.questionIndex,
          10
        ) + 1}`
      )
    } else if (action.payload.type === "section") {
      element = document.getElementById(
        `section-${action.payload.sectionIndex + 1}`
      )
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" })
    } else if (!action.secondTry) {
      scrollToElement(action.type, action.payload, true)
    }
  }
}

function* getCoreQuestions(attempt = 0) {
  let coreQuestions = []
  coreQuestions = yield select(CoreQuestionsSelectors.getAllCoreQuestions)

  if (coreQuestions.length === 0 && attempt < 5) {
    yield delay(1000)
    coreQuestions = yield call(getCoreQuestions, attempt + 1)
  } else if (coreQuestions.length === 0) {
    throw new Error()
  }

  return coreQuestions
}

export function* loadTemplateSaga(action) {
  try {
    const res = yield call(loadTemplate, { id: action.payload.templateId })
    if (res.data) {
      const { type = "organisation" } = res.data.getSurveyTemplate
      yield put(CoreQuestionsActions.loadCoreQuestions(type))
      const coreQuestions = yield call(getCoreQuestions)
      const frontendFormattedLayout = yield call(
        mapBackendLayoutToFrontendLayout,
        {
          backendTemplate: res.data.getSurveyTemplate,
          baseTemplate: action.payload.baseTemplate,
          coreQuestions
        }
      )
      yield put(Actions.loadTemplateSuccess(frontendFormattedLayout))
    }
  } catch (error) {
    console.log("err", error)
    yield put(Actions.loadTemplateFailure())
  }
}

export function* setCoreQuestionSaga(action) {
  try {
    let desiredCoreQuestionId = null
    const availableCoreQuestionOptions = yield select(
      CoreQuestionsSelectors.getFilteredCoreQuestionOptions,
      {
        sectionIndex: null,
        questionIndex: null
      }
    )
    // If core question not available, select next available question id
    if (
      availableCoreQuestionOptions.find(
        option => option.id === action.payload.coreQuestionId
      )
    ) {
      desiredCoreQuestionId = action.payload.coreQuestionId
    } else {
      const filteredOptions = availableCoreQuestionOptions.filter(
        option => option.id !== action.payload.coreQuestionId
      )
      desiredCoreQuestionId = filteredOptions[0].id
    }
    const coreQuestionBackendData = yield select(
      CoreQuestionsSelectors.getCoreQuestionById,
      {
        id: desiredCoreQuestionId
      }
    )
    const coreQuestionData = createQuestionFromBackend({
      backendQuestion: coreQuestionBackendData,
      forceCoreQuestion: true
    })
    if (coreQuestionData) {
      yield put(
        Actions.setCoreQuestionSuccess({
          ...action.payload,
          coreQuestionData
        })
      )
    } else {
      throw new Error()
    }
  } catch {
    yield put(Actions.setCoreQuestionFailure())
  }
}

// Create new question. If CORE question, creates core question based on available options. If custom question, uses default question object
export function* addQuestionSaga(action) {
  try {
    if (action.payload.type === "CORE") {
      let desiredCoreQuestionId = null
      const availableCoreQuestionOptions = yield select(
        CoreQuestionsSelectors.getFilteredCoreQuestionOptions,
        {
          sectionIndex: null,
          questionIndex: null
        }
      )
      // If core question not available, select next available question id
      if (
        availableCoreQuestionOptions.find(
          option => option.id === defaultQuestionObject.coreQuestionId
        )
      ) {
        desiredCoreQuestionId = defaultQuestionObject.coreQuestionId
      } else {
        const filteredOptions = availableCoreQuestionOptions.filter(
          option => option.id !== defaultQuestionObject.coreQuestionId
        )
        desiredCoreQuestionId = filteredOptions[0].id
      }
      const coreQuestionBackendData = yield select(
        CoreQuestionsSelectors.getCoreQuestionById,
        {
          id: desiredCoreQuestionId
        }
      )
      const coreQuestionData = createQuestionFromBackend({
        backendQuestion: coreQuestionBackendData,
        forceCoreQuestion: true
      })
      if (coreQuestionData) {
        yield put(
          Actions.addQuestionSuccess({
            ...action.payload,
            newQuestion: coreQuestionData
          })
        )
      } else {
        throw new Error()
      }
    } else {
      yield put(
        Actions.addQuestionSuccess({
          ...action.payload,
          newQuestion: {
            ...defaultQuestionObject,
            id: otherHelper.generateRandomId().toString(),
            coreQuestion: false,
            icon: getRandomIcon().id
          }
        })
      )
    }
    yield put(
      GenericTemplateActions.setCurrentEditableQuestion(
        `${action.payload.sectionIndex}${action.payload.questionIndex + 1}`
      )
    )
  } catch {
    yield put(Actions.addQuestionFailure())
  }
}

// Saves template to backend endpoint
export function* saveTemplateSaga(action) {
  try {
    const templateValidation = yield select(
      Selectors.getValidationObjectForTemplate
    )
    if (!templateValidation.valid) {
      yield put(GenericTemplateActions.setShowValidationModal(true))
      throw new Error()
    } else {
      const template = yield select(Selectors.getActiveTemplate)
      const preparedBackendData = mapFrontendLayoutToBackendLayout(template)
      const res = yield call(saveTemplate, {
        surveyTemplateId: template.id,
        surveyTemplate: preparedBackendData
      })
      if (!res.data.saveOrganisationTemplate) {
        throw new Error()
      }
      if (action.payload.onSuccess) {
        action.payload.onSuccess()
        yield put(Actions.saveTemplateSuccuess())
      }
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", [
          "[WFB][App][Survey Templates] Saved survey template"
        ])
      }
    }
  } catch {
    yield put(Actions.saveTemplateFailure())
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", [
        "[WFB][App][Survey Templates] Error saving survey template"
      ])
    }
  }
}

export function* saga() {
  yield takeLatest(Actions.types.ADD_QUESTION, addQuestionSaga)
  yield takeLatest(Actions.types.ADD_QUESTION_SUCCESS, scrollToElement)
  yield takeLatest(Actions.types.ADD_SECTION, scrollToElement)
  yield takeLatest(Actions.types.SET_CORE_QUESTION, setCoreQuestionSaga)
  yield takeLatest(Actions.types.SAVE_TEMPLATE, saveTemplateSaga)
  yield takeLatest(Actions.types.LOAD_TEMPLATE, loadTemplateSaga)
}
