import { createSelector } from "reselect"

export const selfServeOrgState = state => state["selfServe.organisationSurvey"]

export const getCurrentPage = createSelector(
  selfServeOrgState,
  state => state?.currentPage
)

export const getTitle = createSelector(
  selfServeOrgState,
  state => state?.survey?.title
)

export const getMode = createSelector(selfServeOrgState, state => {
  return state?.survey?.mode
})

export const getShareableLinks = createSelector(
  selfServeOrgState,
  state => state?.survey?.shareableLinks
)

export const getAmount = createSelector(
  selfServeOrgState,
  state => state?.survey?.creditsPaid
)
export const getRecipients = createSelector(
  selfServeOrgState,
  state => state?.survey?.recipients
)

export const getIntroduction = createSelector(
  selfServeOrgState,
  state => state?.survey?.introduction
)

export const getError = createSelector(selfServeOrgState, state => state?.error)

export const getSelfServeSurveyId = createSelector(
  selfServeOrgState,
  state => state?.survey?.id
)

export const getSelfServeSurveyType = createSelector(
  selfServeOrgState,
  state => state?.selfServeSurveyType
)

export const getTemplateId = createSelector(
  selfServeOrgState,
  state => state?.survey?.templateId
)

export const getDates = createSelector(
  selfServeOrgState,
  ({ survey: { startDate, endDate } }) => ({
    startDate,
    endDate
  })
)

export const getPrivate = createSelector(
  selfServeOrgState,
  state => state?.survey?.private
)

export const getSurveyObj = createSelector(selfServeOrgState, state => {
  const surveyObj = JSON.parse(JSON.stringify(state.survey))
  delete surveyObj.templateId
  return surveyObj
})

export const getQuestionsPreview = createSelector(
  selfServeOrgState,
  state => state?.questionsPreview
)

export const getObjectType = createSelector(
  selfServeOrgState,
  state => state.objectType
)
