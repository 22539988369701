import { titleCaps } from "@engaging-tech/frontend-utils"
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer"
import React from "react"

export const logoImg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkLBusinessLogo.png`)

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  image: {
    width: "auto",
    height: "95%",
    objectFit: "contain"
  },
  filterSection: {
    paddingTop: 12
  }
})

const Header = ({ title, subSection }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "flex-end"
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Image
          src={logoImg}
          style={{ width: 250, objectFit: "contain", height: "auto" }}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 5
        }}
      >
        <Text style={{ width: "auto" }}>{title} </Text>
      </View>

      <Text style={{ textAlign: "right" }}>{subSection}</Text>
    </View>
  )
}

const SurveyReport = ({ images, title, subSection, filters, theme }) => {
  const { required, current, isDirectReports } = filters
  const activeRequiredFilter = required?.find(f => f.isActive)
  return (
    <Document title={title} author="WorkL for Business" subject="Survey Report">
      {(activeRequiredFilter || current?.length) && (
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Header title={title} subSection={subSection} />
            <Text style={{ marginTop: 20 }}>Filters: </Text>
            <View style={styles.filterSection}>
              {activeRequiredFilter ? (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: theme.space[3]
                  }}
                >
                  <Text
                    style={{
                      fontSize: theme.fontSizes[3],
                      marginRight: theme.space[1],
                      width: "auto"
                    }}
                  >
                    Viewing results from:
                  </Text>
                  <Text
                    style={{
                      fontSize: theme.fontSizes[3],
                      marginRight: theme.space[1],
                      width: "auto",
                      fontWeight: 800
                    }}
                  >
                    {`${activeRequiredFilter.question} - ${activeRequiredFilter.value}`}
                  </Text>
                </View>
              ) : null}
              {isDirectReports ? (
                <Text
                  style={{
                    color: theme.colors.primary[0],
                    width: "100%",
                    fontSize: theme.fontSizes[3],
                    marginBottom: theme.space[3]
                  }}
                >
                  This report is made up of responses from people who directly
                  report to you.
                </Text>
              ) : null}

              {current?.length ? (
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center"
                  }}
                >
                  <Text
                    style={{
                      width: "auto",
                      fontWeight: 500,
                      fontSize: theme.fontSizes[3],
                      marginBottom: theme.space[3]
                    }}
                  >
                    Active Filters:
                  </Text>
                  {current.map(({ question, values }) => {
                    return (
                      <View style={{ width: "100%" }}>
                        <Text
                          style={{
                            width: "100%",
                            fontSize: theme.fontSizes[3],
                            textAlign: "left",
                            color: theme.colors.primary[0],
                            fontWeight: 700
                          }}
                        >
                          {question}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            width: "100%",
                            marginTop: theme.space[2]
                          }}
                        >
                          {values.map(({ value }) => (
                            <View
                              key={value}
                              style={{
                                backgroundColor: theme.colors.secondary[0],
                                borderRadius: "16pt",
                                width: "auto",
                                paddingTop: "6pt",
                                paddingBottom: "6pt",
                                paddingLeft: "12pt",
                                paddingRight: "12pt",
                                margin: theme.space[1]
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: theme.fontSizes[2],
                                  color: theme.colors.light[0]
                                }}
                              >
                                {titleCaps(value)}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    )
                  })}
                </View>
              ) : null}
            </View>
          </View>
        </Page>
      )}
      {images.map(image => (
        <Page size="A4" style={styles.page} orientation="landscape" key={image}>
          <View style={styles.section}>
            <Header title={title} subSection={subSection} />

            <Image src={image} style={styles.image} />
          </View>
        </Page>
      ))}
    </Document>
  )
}

export default SurveyReport
