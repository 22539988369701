import { format } from "date-fns"

const buildDataset = (surveyComparisonData, allDatasets, newDataset) => {
  const availableSections = []

  surveyComparisonData.forEach(({ date, scores, title }) => {
    const formattedDate = format(new Date(date), "dd/MM/yyyy")
    allDatasets.data.push({ x: formattedDate, y: null })
    scores.forEach(({ score, section }) => {
      if (section) {
        if (!availableSections.includes(section))
          availableSections.push(section)

        const sectionIndex = newDataset.datasets.findIndex(
          item => item.label === section
        )

        if (sectionIndex === -1) {
          newDataset.datasets.push({
            label: section,
            titles: [title],
            data: [{ x: formattedDate, y: score }]
          })
        } else {
          newDataset.datasets[sectionIndex].data.push({
            x: formattedDate,
            y: score
          })

          newDataset.datasets[sectionIndex].titles.push(title)
        }
      }
    })
  })

  return availableSections
}

export default buildDataset
