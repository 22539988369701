import { connect } from "react-redux"
import { ReportSelectors, ReportActions } from "../../index"
import ComparisonOptions from "../components/ComparisonOptions"

const mapState = state => ({
  startDate: ReportSelectors.getSurveyComparisonStartDate(state),
  endDate: ReportSelectors.getSurveyComparisonEndDate(state),
  checkedList: ReportSelectors.getSurveyComparisonCheckedList(state)
})

const mapDispatch = dispatch => ({
  setStartDate: startDate =>
    dispatch(ReportActions.setSurveyComparisonStartDate(startDate)),
  setEndDate: endDate =>
    dispatch(ReportActions.setSurveyComparisonEndDate(endDate))
})

const ComparisonOptionsContainer = connect(
  mapState,
  mapDispatch
)(ComparisonOptions)

export default ComparisonOptionsContainer
