import { TextField } from "@engaging-tech/components"
import styled from "styled-components"

const objectToCssString = style =>
  Object.entries(style)
    .map(([k, v]) => `${k}:${v}`)
    .join(";")

// eslint-disable-next-line import/prefer-default-export
export const StyledTextField = styled(TextField)`
  align-items: ${({ alignItems }) => alignItems || "flex-start"};

  input {
    border: ${({ error, theme }) =>
      error
        ? `1px solid ${theme.colors.error[0]}`
        : "1px solid rgba(99,152,195,1)"};
    padding: 15px;
    background-color: #f4f4f4;

    color: ${({ theme }) => theme.colors.dark[0]};

    &:disabled {
      cursor: ${({ preview }) => {
        if (preview) {
          return "default"
        }
        return "inherit"
      }};
      opacity: ${({ preview }) => {
        if (preview) {
          return 1
        }
        return "inherit"
      }};

      background-color: ${({ preview }) => {
        if (preview) {
          return "#f5f5f5"
        }
        return "inherit"
      }};
    }

    &:focus {
      border: ${({ error, theme, preview }) => {
        if (preview) {
          return "2px solid rgba(99,152,195,1)"
        }
        return error
          ? `2px solid ${theme.colors.error[0]}`
          : "2px solid rgba(99,152,195,1)"
      }};

      padding: 14px;
      outline: 0;
      background-color: #eaeaea;
    }

    ${({ placeholderStyles }) =>
      placeholderStyles &&
      `
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        ${objectToCssString(placeholderStyles)}
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        ${objectToCssString(placeholderStyles)}
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        ${objectToCssString(placeholderStyles)}
      }
      :-moz-placeholder {
        /* Firefox 18- */
        ${objectToCssString(placeholderStyles)}
      }
  `}
  }
`
