import React from "react"
import { Card, Text, Flex, Icon } from "@engaging-tech/components"

const StepBox = ({ children, step, title, isReview }) => {
  return (
    <Card px={3} p={3} mb={3} maxWidth="1080px">
      <Flex alignItems="center" mb={3} minHeight={40}>
        <Flex mr={2} bg="dark.2" borderRadius={99} width="auto">
          <Icon
            name="check-circle"
            mr={0}
            color="light.0"
            bg="secondary.0"
            borderRadius={30}
            size={20}
          />
        </Flex>
        <Text fontSize={3} color="dark.2" fontWeight="500">
          {isReview && "Review"} Step {step} - {title}
        </Text>
      </Flex>
      {children}
    </Card>
  )
}

export const ReviewStepBox = props => <StepBox isReview {...props} />

export default StepBox
