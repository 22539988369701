import { useEffect } from "react"
import { connect } from "react-redux"
import {
  loadLectures,
  loadResearch,
  loadArticles,
  loadWebinars
} from "../store/EBN.actions"
import { load as loadOrganisation } from "../../account/store/account.actions"

const EBNLoaderComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => {
    dispatch(loadLectures())
    dispatch(loadResearch())
    dispatch(loadArticles())
    dispatch(loadWebinars())
    dispatch(loadOrganisation())
  }
})

const EBNLoader = connect(null, mapDispatch)(EBNLoaderComponent)

export default EBNLoader
