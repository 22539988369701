import React from "react"
import { Flex, Card, Paragraph, Text } from "@engaging-tech/components"
import styled from "styled-components"

const statuses = {
  1: "You're in a queue - please bear with us",
  2: "We're processing your results",
  3: "Your results are now available"
}

const Bubble = styled(Flex)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary[0]};

  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 3s infinite ease-in-out;
  animation: sk-bounce 3s infinite ease-in-out;

  -webkit-animation-delay: ${({ delay }) => (delay ? "-1.5s" : "0s")};
  animation-delay: ${({ delay }) => (delay ? "-1.5s" : "0s")};

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`

const NumText = styled(Text)`
  position: absolute;
  left: 21px;
`

const getBG = (active, completed) => {
  if (completed) return "primary.0"
  if (active) return null
  return "dark.4"
}

const Step = ({ num, active, completed }) => (
  <Flex flexDirection="column" alignItems="center">
    <Flex
      width={50}
      bg={getBG(active, completed)}
      height={50}
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      position="relative"
      my={3}
    >
      {active && (
        <>
          <Bubble />
          <Bubble delay />
        </>
      )}
      <NumText position="absolute" zIndex={100}>
        {num}
      </NumText>
    </Flex>
    <Paragraph
      width="auto"
      maxWidth={120}
      fontSize={3}
      color="dark.2"
      textAlign="center"
    >
      {statuses[num]}
    </Paragraph>
  </Flex>
)
const STAGES = {
  first: ["readyToProcess", "queued"],
  second: ["processing"],
  last: ["processed"]
}

const HoldingPage = ({ status = "readyToProcess" }) => {
  return (
    <Flex
      width={1 / 1}
      maxWidth={740}
      flexDirection="column"
      alignItems="center"
      mx="auto"
    >
      <Card
        width={1 / 1}
        justifyContent="flex-start"
        alignItems="center"
        elevation={2}
        mb={[3, 5]}
        p={5}
        bg="dark.7"
      >
        {STAGES.first.concat(STAGES.last).includes(status) && (
          <>
            <Paragraph width="auto" fontSize={3} color="dark.2">
              Please hold tight, we’re processing your report.
            </Paragraph>
            <Paragraph width="auto" fontSize={3} color="dark.2">
              Please remember to leave the page open.
            </Paragraph>
          </>
        )}

        {STAGES.second.includes(status) && (
          <>
            <Paragraph width="auto" fontSize={3} color="dark.2">
              We are currently calculating your report
            </Paragraph>

            <Paragraph width="auto" fontSize={3} color="dark.2">
              Please leave this page open and the report will be ready in a few
              minutes
            </Paragraph>
          </>
        )}
        <Flex>
          <Step
            num={1}
            active={STAGES.first.includes(status)}
            completed={STAGES.second.includes(status)}
          />
          <Step
            num={2}
            active={STAGES.second.includes(status)}
            completed={STAGES.last.includes(status)}
          />
          <Step num={3} active={STAGES.last.includes(status)} />
        </Flex>
      </Card>
    </Flex>
  )
}

export default HoldingPage
