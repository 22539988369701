import { connect } from "react-redux"
import Page3 from "../views/Page3"
import { setCurrentPage } from "../store/actions"

const mapDispatch = dispatch => ({
  setCurrentPage: page => dispatch(setCurrentPage(page))
})

const Page3Container = connect(null, mapDispatch)(Page3)

export default Page3Container
