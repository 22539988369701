import { TextField } from "@engaging-tech/components"
import styled from "styled-components"

const StyledTextField = styled(TextField)`
  &:disabled {
    cursor: inherit;
    opacity: 0.8;
  }
`

export default StyledTextField
