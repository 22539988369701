/* eslint-disable react/destructuring-assignment */
import { createSelector } from "reselect"

/**
 * Recursively searches the array to find the first layout with the name associated
 * Work still needs to be done!!!
 * @param {Object[]} layout - The root layout to be searched
 * @param {string} name - The name of the layout
 * @returns The layout that has name {name} | Null if it has not been found
 */
// eslint-disable-next-line no-underscore-dangle
const _getLayoutByNameRec = (layout, name) => {
  if (layout.length === 0) return null
  if (layout[0].children) return _getLayoutByNameRec(layout[0].children, name)
  if (name === layout[0].name) {
    return layout[0]
  }
  return _getLayoutByNameRec(layout.slice(1, layout.length), name)
}

const pipePassedInProp = (state, prop) => prop

export const getSurveyReportState = state => state?.surveyReport

export const getPdfProgress = createSelector(getSurveyReportState, state => {
  const pdfGenerated = state.pdfProgress.pdfGenerated ? 1 : 0
  const isAllImagesGenerated = state.pdfProgress.isAllImagesGenerated ? 1 : 0

  return (
    ((state.pdfProgress.completedPages + isAllImagesGenerated + pdfGenerated) /
      (state.pdfProgress.totalPages + 2)) *
      100 || 0
  ).toFixed(0)
})

export const getIsPdfDownloading = createSelector(
  getSurveyReportState,
  state => state.isPdfDownloading
)
export const getIsHeatMapPdfDownloading = createSelector(
  getSurveyReportState,
  state => state.isHeatMapPdfDownloading
)

export const getDeleteError = createSelector(
  getSurveyReportState,
  state => state.deleteError
)

export const getGenerateError = createSelector(
  getSurveyReportState,
  state => state.generateError
)

export const getGenerateReportTitle = createSelector(
  getSurveyReportState,
  state => state.generateReportTitle
)

export const getSurveyType = createSelector(
  getSurveyReportState,
  state => state?.surveyType
)

export const getIsAllReportsLoading = createSelector(
  getSurveyReportState,
  dashboard => dashboard && !!dashboard.isLoadingReports
)

export const hasData = createSelector(
  getSurveyReportState,
  dashboard => dashboard && !!dashboard.reports && !!dashboard.reports.length
)

export const getAllReports = createSelector(
  getSurveyReportState,
  dashboard => dashboard && dashboard.reports
)

export const getSearchTerm = createSelector(
  getSurveyReportState,
  state => state && state.getSearchTerm
)

export const getFilteredReports = createSelector(
  getSurveyReportState,
  state => {
    if (!state?.searchTerm) {
      return state?.reports
    }

    const filteredData = state?.reports?.filter(obj =>
      obj.title
        .toLowerCase()
        .replace(/[^\w\s]|_/g, "")
        .includes(state?.searchTerm.toLowerCase().replace(/[^\w\s]|_/g, ""))
    )
    return filteredData
  }
)

export const getIsDeleteBarOpen = createSelector(
  getSurveyReportState,
  state => state && state.reportsToDelete.length
)

export const getReportResponse = createSelector(
  getSurveyReportState,
  state => state && state.response
)

export const getReportResponseReportId = createSelector(
  getSurveyReportState,
  state => state && state.response?.reportId
)

export const getReportResponseTitle = createSelector(
  getSurveyReportState,
  state => state && state.response?.title
)
export const getReportResponseId = createSelector(
  getSurveyReportState,
  state => state && state.response?.id
)
export const getReportResponseSixQuestions = createSelector(
  getSurveyReportState,
  state => state && state.response?.data?.sixStepsQuestions
)

export const allBusinessAreas = createSelector(
  getSurveyReportState,
  state => state && state.allBusinessAreas
)
export const getIdForFilters = createSelector(getSurveyReportState, state =>
  state?.response?.type === "organisation"
    ? state?.response?.id
    : state?.response?.reportId
)

export const getHasLoaded = createSelector(
  getSurveyReportState,
  state => state.hasLoaded
)

export const getOpenModal = createSelector(
  getSurveyReportState,
  state => state.openModal
)

export const getFilters = createSelector(
  getSurveyReportState,
  state => state.filters
)

export const getReportsToDelete = createSelector(
  getSurveyReportState,
  state => state && state.reportsToDelete
)

export const getReportsToGenerateFrom = createSelector(
  getSurveyReportState,
  state => state && state.reportsToGenerateFrom
)

export const getGenerateParameters = createSelector(
  getSurveyReportState,
  state =>
    state && {
      title: state.generateReportTitle,
      surveyIds: state.reportsToGenerateFrom.map(item => item.surveyId)
    }
)

export const getRedirectLink = createSelector(
  getSurveyReportState,
  state => state?.redirectLink
)

export const getTextResponsesUrl = createSelector(
  getReportResponse,
  response => response?.textResponsesUrl
)

export const getReportData = createSelector(
  getReportResponse,
  response => response && response.data
)

export const getHasError = createSelector(
  getSurveyReportState,
  state => state.hasError
)

export const getIsLoading = createSelector(
  getSurveyReportState,
  state => state.isLoading
)

export const getReportLayout = createSelector(
  getReportResponse,
  report => report && report.layout
)

export const getSummaryLayout = createSelector(
  getReportLayout,
  layout => layout && layout.map(element => element.name)
)

export const gethappinessDataHighlightLayout = createSelector(
  getReportLayout,
  layout =>
    layout && layout.find(element => element.name === "happinessDataHighlight")
)

export const getHeatmapFilters = createSelector(
  getSurveyReportState,
  state => state && state.heatmapFilters
)

export const getAllHeatmapFilters = createSelector(
  getSurveyReportState,
  state => state && state.allHeatmapFilters
)

// RAG - UPDATE - TEST
export const getInstantActionPlan = createSelector(getReportResponse, state => {
  if (!state) return null
  const { sixStepsQuestions, surveySixSteps } = state.data
  if (!sixStepsQuestions || sixStepsQuestions.length < 1) return null
  const sortedSteps = [...surveySixSteps].sort((a, b) => {
    return a.score - b.score
  })
  const lowest = sortedSteps[0]
  const highest = sortedSteps[sortedSteps.length - 1]
  const lowestQuestionGroup = sixStepsQuestions.find(
    question => question.step === lowest.name
  )
  const highestQuestionGroup = sixStepsQuestions.find(
    question => question.step === highest.name
  )
  const instantActionPlan = {
    low: {
      name: lowest.name,
      score: lowest.score,
      questionGroup: {
        ...lowestQuestionGroup,
        questions: lowestQuestionGroup.questions.map(q => {
          return { ...q, score: q.values.score }
        })
      }
    },
    high: {
      name: highest.name,
      score: highest.score,
      questionGroup: {
        ...highestQuestionGroup,
        questions: highestQuestionGroup.questions.map(q => {
          return { ...q, score: q.values.score }
        })
      }
    }
  }
  return instantActionPlan
})

export const getAppliedHeatmapFilters = createSelector(
  getSurveyReportState,
  state => {
    const appliedHeatmapFilters = state.allHeatmapFilters.reduce(
      (acc, next) => {
        const allCombinations = next.values.map(({ valueId }) => ({
          questionId: next.questionId,
          value: valueId
        }))

        acc.push(...allCombinations)
        return acc
      },
      []
    )

    if (state.questionFilter.length) {
      state.questionFilter.forEach(item => {
        appliedHeatmapFilters.push({
          questionId: "question",
          value: item
        })
      })
    }

    return appliedHeatmapFilters
  }
)

export const getAppliedHeatmapFilterFormValues = createSelector(
  getAppliedHeatmapFilters,
  state => {
    const obj = {}
    // eslint-disable-next-line array-callback-return
    state.map(filter => {
      const { questionId, value } = filter
      if (obj[questionId]) {
        obj[questionId] = [...obj[questionId], value]
      } else {
        obj[questionId] = [value]
      }
    })
    return obj
  }
)

export const getHappinessDataHighlightChildrenLayout = createSelector(
  gethappinessDataHighlightLayout,
  dataHighlightLayout =>
    dataHighlightLayout && dataHighlightLayout.children.map(child => child.name)
)

export const getMatricesLayout = createSelector(
  getReportLayout,
  layout => layout && layout.find(layer => layer.name === "matrices")
)

export const getMatricesChildrenLayout = createSelector(
  getMatricesLayout,
  layout =>
    layout && layout.children && layout.children.map(child => child.name)
)

export const getIndicatorsLayout = createSelector(
  getReportLayout,
  layout => layout && layout.find(layer => layer.name === "indicators")
)

export const getIndicatorsChildrenLayout = createSelector(
  getIndicatorsLayout,
  layout => layout.children.map(child => child.name)
)

export const getSixStepsScoresLayout = createSelector(getReportLayout, layout =>
  layout.find(widget => widget.name === "sixStepsScores")
)

export const getSixStepsScoresChildrenLayout = createSelector(
  getSixStepsScoresLayout,
  layout => layout.children.map(child => child.name)
)

export const getLayoutByName = createSelector(
  [getReportLayout, pipePassedInProp],
  (layout, name) => _getLayoutByNameRec(layout, name)
)

export const getResponseSize = createSelector(
  getReportResponse,
  report => report?.responseSize
)

export const getReportStatus = createSelector(
  getReportResponse,
  report => report?.status
)

export const getIsDefault = createSelector(
  getReportResponse,
  report => report?.isDefault
)

export const getSliceAndDiceReport = createSelector(
  getReportResponse,
  report => report?.sliceAndDice
)

export const getHasResults = createSelector(getReportResponse, report => {
  if (report?.type === "organisation") return report.responseSize >= 5
  if (["employeeStarter", "employeeExit"].includes(report?.type)) return true
  return false
})

export const getReportSurveyId = createSelector(
  getSurveyReportState,
  state => state?.response?.id
)

export const getRequiredFilter = createSelector(
  getSurveyReportState,
  state => state?.requiredFilter
)

export const getActiveRequiredFilter = createSelector(
  getReportResponse,
  response => response?.filters?.required?.find(({ isActive }) => isActive)
)

export const getSurveyComparisonStage = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.stage
)

export const getSurveyComparisonBreadcrumbs = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.breadcrumbs
)

export const getSurveyComparisonCheckedList = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.checkedList
)

export const getSurveyComparisonData = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.data
)

export const getComparisonDataset = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.dataset
)

export const getSurveyComparisonStartDate = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.startDate
)

export const getSurveyComparisonEndDate = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.endDate
)

export const getSubCheckedList = createSelector(
  getSurveyReportState,
  state => state?.surveyComparison?.subCheckedList
)

export const getQuestionFilter = createSelector(
  getSurveyReportState,
  state => state?.questionFilter
)

export const getHeatmapTabSelected = createSelector(
  getSurveyReportState,
  state => state?.heatmapTabSelected
)

export const getMatchedSurveys = createSelector(
  getSurveyReportState,
  state => state?.response?.matchedSurveys
)

export const getHeatmapsSurveyComparison = createSelector(
  getSurveyReportState,
  state => state?.heatmapsSurveyComparison
)

export const getComparisonReport = createSelector(
  getHeatmapsSurveyComparison,
  state => state?.response
)

export const getComparisonIsLoading = createSelector(
  getHeatmapsSurveyComparison,
  state => state?.loading
)

export const getComparisonSurveyId = createSelector(
  getHeatmapsSurveyComparison,
  state => state?.id
)

export const getComparisonSurveyTitle = createSelector(
  getHeatmapsSurveyComparison,
  state => state?.title
)

export const getComparisonFilters = createSelector(
  getHeatmapsSurveyComparison,
  state => state?.heatmapFilters
)
