/* eslint-disable react/no-array-index-key */

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik"
import React, { useContext, useEffect } from "react"
import { object, string } from "yup"

import useFreeze from "../../lib/useFreeze"
import EditBodyFlex from "../../sharedStyling/EditBodyFlex"
import TemplateQuestionContext from "../TemplateQuestionContext"
import InputInterface from "./InputInterface"

const Input = ({ shortText, onChange, i, deleteOption, setPageErrors }) => {
  const { isCore, id } = useContext(TemplateQuestionContext)

  const formik = useFormik({
    initialValues: {
      shortText: useFreeze(shortText)
    },
    validationSchema: object({
      shortText: string().required("Cannot be empty")
    }),
    validateOnMount: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isCore) onChange(formik.values.shortText, i, id)
      if (!isCore) setPageErrors(formik.errors?.shortText, "shortText", id)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.shortText, formik.errors])

  return (
    <InputInterface
      value={formik.values.shortText}
      handleChange={formik.handleChange}
      isCore={isCore}
      i={i}
      id={id}
      errors={formik?.errors.shortText}
      handleDelete={() => deleteOption(i, id)}
    />
  )
}

const ShortTextQuestionEdit = ({ deleteOption, onChange, setPageErrors }) => {
  const { fields } = useContext(TemplateQuestionContext)

  return (
    <EditBodyFlex>
      {fields?.map((field, i) => (
        <Input
          key={i}
          shortText={field.label}
          onChange={onChange}
          i={i}
          deleteOption={deleteOption}
          setPageErrors={setPageErrors}
        />
      ))}
    </EditBodyFlex>
  )
}

export default ShortTextQuestionEdit
