import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import { getLabelData } from "../../components/ScoreLabel"

const Questions = ({ questions }) => {
  return (
    <Flex flexDirection="column" ml={4}>
      {questions.map(({ question, values }, idx) => (
        <Flex my={1}>
          <Text fontSize={4} fontWeight={500}>{`${idx + 1}. ${question}`}</Text>
          <Text
            fontSize={5}
            width="20%"
            fontWeight={600}
            style={{ textAlign: "center" }}
            color={getLabelData(values.score).color}
          >
            {`${values.score} %`}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}

export default Questions
