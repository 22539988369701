import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import { ResetPassword as ResetPasswordView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import GradientLayout from "../../layouts/GradientLayout"

const ResetPassword = () => (
  <GradientLayout>
    <ResetPasswordView
      siteLogo={SiteLogo}
      cancelRoute={`${getConfig().publicSite}`}
      redirectTo="/account/sign-in"
    />
  </GradientLayout>
)

export default ResetPassword
