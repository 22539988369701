import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import SubscriptionLayout from "../../../layouts/SubscriptionLayout"
import JoinOurNetworkDetail from "./JoinOurNetworkDetail"
import JoinOurNetworkHeader from "./JoinOurNetworkHeader"

const JoinJobs = ({ handleAction, handleCancel }) => {
  const router = useRouter()
  const paths = usePaths()

  return (
    <SubscriptionLayout>
      <JoinOurNetworkHeader
        subscriptionType="Jobs Board"
        description="Post unlimited jobs on the WorkL jobs Marketplace, in addition to promotion across our social media channels and newsletters."
        handleSubscribeClick={() => {
          return router.navigate(paths.home.jobs.subscription)
        }}
      />

      <JoinOurNetworkDetail
        handleCancel={handleCancel}
        handleAction={handleAction}
        individualService
      />
    </SubscriptionLayout>
  )
}

export default JoinJobs
