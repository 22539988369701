import { connect } from "react-redux"
import {
  getIsDraft,
  getSaveTemplateVariables,
  getTemplateId,
  getSaveTemplateError,
  getPageErrors
} from "../store/template.selectors"
import BottomStickyNavComponent from "../components/BottomStickyNav"
import { clearApiErrors } from "../store/template.actions"

const mapState = state => ({
  errors: getSaveTemplateError(state),
  templateId: getTemplateId(state),
  isDraft: getIsDraft(state),
  saveTemplateVariables: getSaveTemplateVariables(state),
  pageErrors: getPageErrors(state)
})

const mapDispatch = dispatch => ({
  clearApiErrors: () => dispatch(clearApiErrors())
})

const BottomStickyNav = connect(mapState, mapDispatch)(BottomStickyNavComponent)

export default BottomStickyNav
