import { createSelector } from "reselect"

export const surveyInfoState = state => {
  return state && state["surveyManagement.surveyView"]
}

export const getSurveyId = createSelector(surveyInfoState, state => state.surveyId)

export const getIsLoading = createSelector(surveyInfoState, state => state.isLoading)

export const getHasError = createSelector(surveyInfoState, state => state.hasError)

export const getSurvey = createSelector(surveyInfoState, state => state.survey)

export const getTitle = createSelector(getSurvey, survey => survey?.title)

export const getIntroduction = createSelector(getSurvey, survey => {
  return survey?.introduction.reduce(
    (returnString, i, index) =>
      `${returnString + i.content}${index !== survey?.introduction.length - 1 ? "\n" : ""}`,
    ""
  )
})

export const getTemplate = createSelector(getSurvey, survey => survey?.template)

export const getStatus = createSelector(getSurvey, survey => survey?.status)

export const getMeta = createSelector(getSurvey, survey => survey?.meta)

export const getStartDate = createSelector(getMeta, meta => meta?.startDate)

export const getEndDate = createSelector(getMeta, meta => meta?.endDate)

export const getPrivate = createSelector(getMeta, meta => meta?.private)

export const getCreditsPaid = createSelector(getMeta, meta => meta?.creditsPaid)

export const getResultsEmails = createSelector(getMeta, meta => meta?.resultsEmails)

export const getFrequencySettings = createSelector(getMeta, meta => meta?.frequencySettings)

export const getFrequency = createSelector(
  getFrequencySettings,
  frequencySettings => frequencySettings?.frequency
)

export const getFrequencyEndDate = createSelector(
  getFrequencySettings,
  frequencySettings => frequencySettings?.endDate
)

export const getBrand = createSelector(getMeta, meta => meta?.brand)

export const getColour = createSelector(getBrand, brand => brand?.colour)

export const getLogo = createSelector(getBrand, brand => brand?.logo)

export const getModeObj = createSelector(getMeta, meta => meta?.mode)

export const getMode = createSelector(getModeObj, mode => mode?.mode)

export const getRecipients = createSelector(getModeObj, mode => mode?.recipients)

export const getAmount = createSelector(getModeObj, mode => mode?.amount)
