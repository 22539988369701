/* eslint-disable import/no-cycle */
import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { Auth } from "aws-amplify"
import React, { createContext, useState } from "react"
import { useDispatch } from "react-redux"

import { createOrganisation } from "../../services/account.service"
import { signUpFail, signUpSuccess } from "../../store/account.actions"
import SignUpFormContent from "./SignUpFormContent"

export const SignUpNavContext = createContext()
const config = getConfig()
const SignUpForm = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  let desiredLocation = null
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    desiredLocation = urlParams.get("desiredLocation")
  }

  const handleNext = () => setCurrentPage(current => current + 1)
  const handleBack = () => setCurrentPage(current => current - 1)

  const onSubmit = async values => {
    try {
      setIsLoading(true)

      const email = values.email.toLowerCase()

      const result = await Auth.signUp({
        username: email,
        password: values.password,
        autoSignIn: {
          enabled: true
        }
      })

      if (result.userConfirmed) {
        const signInResult = await Auth.signIn({
          username: email,
          password: values.password
        })

        const organisationResult = await createOrganisation({
          name: values.businessName,
          admin: {
            firstName: values.firstName,
            lastName: values.lastName
          },
          countryId: values.country,
          industryId: values.industry,
          allowMarketing: values.allowMarketing,
          email: email,
          websiteURL: values.website,
          phoneNumber: values.phone,
          accountPreferences: {
            language: values.language,
            countryId: values.country,
            timezone: values.timezone,
            currency: "GBP" // hardcoded for now
          }
        })
        if (organisationResult?.data?.createOrganisation?.id) {
          setIsLoading(false)
          dispatch(
            signUpSuccess({
              token: signInResult.signInUserSession.accessToken.payload.sub,
              organisation: organisationResult.data.createOrganisation
            })
          )

          if (window?.gtag && config.isProd) {
            console.log("has gtag")
            window.gtag("event", "sign_up_success_new", {
              event_category: "page_view",
              event_label: "Signup Success New"
            })
          }
          router.redirect(desiredLocation || "/account/success")
        }
      }
    } catch (error) {
      setIsLoading(false)
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            error?.message === "APP_ERROR"
              ? "Failed to create an account"
              : error.message
        })
      )
      dispatch(signUpFail(error.code))
    }
  }

  const contextValue = {
    currentPage,
    handleBack,
    handleNext,
    onSubmit
  }

  return (
    <SignUpNavContext.Provider value={contextValue}>
      <SignUpFormContent
        isLoading={isLoading}
        currentPage={currentPage}
        desiredLocation={desiredLocation}
      />
    </SignUpNavContext.Provider>
  )
}

export default SignUpForm
