import { CustomHooks } from "@engaging-tech/components"
import React from "react"

import { SendMagicLink as SendMagicLinkView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import GradientLayout from "../../layouts/GradientLayout"

const SendMagicLink = () => {
  CustomHooks.useFreshChatDeactivate(true)

  return (
    <GradientLayout>
      <SendMagicLinkView
        redirectTo="/account/magic-link/sent"
        siteLogo={SiteLogo}
        cancelRoute="/"
      />
    </GradientLayout>
  )
}

export default SendMagicLink
