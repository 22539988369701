export const types = {
  GET_PRODUCTS_REQ: "[Products] Get Products Req",
  GET_PRODUCTS_SUCCESS: "[Products] Get Products Success",
  GET_PRODUCTS_FAIL: "[Products] Get Products Fail"
}

export const getProductsFail = payload => ({
  type: types.GET_PRODUCTS_FAIL,
  payload
})

export const getProductsSuccess = payload => ({
  type: types.GET_PRODUCTS_SUCCESS,
  payload
})

export const getProductsReq = payload => ({
  type: types.GET_PRODUCTS_REQ,
  payload
})
