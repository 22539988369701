import { Flex, Text } from "@engaging-tech/components"
import React from "react"

export function Tab({ isSelected, keyValue, onClick, value }) {
  return (
    <Flex
      alignItems="center"
      bg={isSelected ? "secondary.0" : "transparent"}
      borderRadius={4}
      justifyContent="center"
      mr={4}
      p={2}
      onClick={() => (isSelected ? {} : onClick(keyValue))}
      style={{ cursor: isSelected ? "not-allowed" : "pointer" }}
      width="initial"
    >
      <Text
        color={isSelected ? "white" : "secondary.0"}
        fontSize={3}
        fontWeight="bold"
        style={{ userSelect: "none" }}
        width="initial"
      >
        {value}
      </Text>
    </Flex>
  )
}
