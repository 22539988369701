const addParams = (licence, objectType, param) => {
  const url = new URL(window.location)
  if (!licence) {
    url.searchParams.set(param, objectType)
    window.history.replaceState(null, "", url.toString())
  } else if (licence && url.searchParams.get(param)) {
    window.history.replaceState(null, "", url)
  }
}

export default addParams
