import { connect } from "react-redux"

import { getSliceAndDiceProduct } from "../../../../store/products/products.selectors"
import SliceAndDiceComponent from "../../components/SliceAndDiceComponent"
import { getSurveyId } from "../../store/awards.selectors"

const mapState = state => ({
  awardSurveyId: getSurveyId(state),
  sliceAndDiceProduct: getSliceAndDiceProduct(state)
})

const SliceAndDiceContainer = connect(mapState, null)(SliceAndDiceComponent)

export default SliceAndDiceContainer
