import { Flex, PieChartScalable, Text } from "@engaging-tech/components"
import React from "react"
import EmptyCustomQuestion from "./EmptyCustomQuestion"

const formatting = values => {
  return {
    labels: values.map(v => v.answer),
    datasets: [
      {
        data: values.map(v => Math.round(v.score))
      }
    ]
  }
}

const CustomQuestionPieChart = ({ question, exportingPDF }) => {
  return (
    <Flex flexDirection="column" justifyContent="flex-start" key={question.id}>
      <Flex
        width={1 / 1}
        bg="dark.4"
        p={4}
        mb={4}
        alignItems="center"
        flexDirection="column"
      >
        <Text
          color="dark.2"
          fontWeight={500}
          width="auto"
          mb={2}
          textAlign="center"
        >
          {question.question}
        </Text>
        {question && question?.values?.length > 0 ? (
          <Flex flexDirection={["column", "row"]} width={1 / 1}>
            {/* <PieChart
              dataset={formatting(question?.values)}
              disableAllAnimation={exportingPDF}
              showDataLabels={exportingPDF}
              height={400}
            /> */}
            <PieChartScalable
              dataset={formatting(question?.values)}
              disableAllAnimation={exportingPDF}
              showDataLabels={exportingPDF}
            />
          </Flex>
        ) : (
          <EmptyCustomQuestion />
        )}
      </Flex>
    </Flex>
  )
}
export default CustomQuestionPieChart
