import { useEffect } from "react"
import { connect } from "react-redux"
import { useRouter, useParams } from "@engaging-tech/routing"

import { confirmEmail } from "../store/account.actions"

const OnLoad = ({ redirectTo = "/", onLoad }) => {
  const router = useRouter()
  const { confirmToken } = useParams()
  useEffect(() => {
    onLoad(confirmToken)
    router.navigate(redirectTo)
  }, [onLoad, redirectTo, router, confirmToken])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: confirmToken => dispatch(confirmEmail(confirmToken))
})

/**
 * Confirms a user's email address.
 */
const ConfirmEmail = connect(null, mapDispatch)(OnLoad)

export default ConfirmEmail
