import {
  Button,
  Flex,
  Spinner,
  Text,
  TextField
} from "@engaging-tech/components"
import React from "react"

import ErrorState from "../../../business-information/components/error-state"
import HandleUnauthorised from "../HandleUnauthorised"

const CreateActionPlanModal = ({
  title,
  errors,
  handleCreate,
  closeModal,
  value,
  handleChange,
  isLoading,
  apiErrors
}) => {
  if (isLoading)
    return <Spinner color="primary.0" justifyContent="center" width={1 / 1} />

  if (apiErrors && apiErrors.includes("ERR403")) {
    return <HandleUnauthorised onCloseModal={closeModal} />
  }

  if (apiErrors === "ERR400") {
    return (
      <Flex flexDirection="column" width={1 / 1} alignItems="center">
        <Text textAlign="center">
          The title provided has already been used in another action plan.
        </Text>
        <Button
          width="120px"
          bg="primary.0"
          color="light.0"
          mt={3}
          onClick={closeModal}
        >
          Ok
        </Button>
      </Flex>
    )
  }

  if (apiErrors) return <ErrorState />

  return (
    <Flex flexDirection="column" width={1 / 1}>
      <Flex width={1 / 1} alignItems="center" mb={2}>
        <Text fontSize={5} fontWeight={600}>
          {title}
        </Text>
      </Flex>
      <Text>Name your new Action Plan</Text>
      <TextField
        mt={2}
        height={36}
        type="text"
        name="title"
        value={value}
        onChange={handleChange}
        error={errors}
      />
      {isLoading ? (
        <Spinner color="primary.0" justifyContent="right" />
      ) : (
        <Flex justifyContent="space-between" mt={2}>
          <Button width="120px" variant="text.secondary.0" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            width="120px"
            bg="primary.0"
            color="light.0"
            onClick={handleCreate}
            disabled={!!errors}
          >
            Create
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

export default CreateActionPlanModal
