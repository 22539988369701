import { connect } from "react-redux"
import { setDeleteReport } from "../../store/report.actions"
import {
  getFilteredReports,
  getReportsToDelete
} from "../../store/report.selectors"
import EmployeeReportsList from "../components/EmployeeReportsList"

const mapState = state => ({
  reports: getFilteredReports(state),
  selectedReports: getReportsToDelete(state)
})

const mapDispatch = dispatch => ({
  setReportToBeSelected: reportArray => dispatch(setDeleteReport(reportArray))
})

const EmployeeReportListContainer = connect(
  mapState,
  mapDispatch
)(EmployeeReportsList)

export default EmployeeReportListContainer
