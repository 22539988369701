import { connect } from "react-redux"
import SurveyIntroCard from "../../../selfServiceSurveys/components/Page2/SurveyIntroCard"
import {
  setSectionIntro,
  setSectionTitle,
  setPageErrors
} from "../store/template.actions"
import { getViewOnly } from "../store/template.selectors"

const mapState = state => ({
  noIntroErrors: true,
  viewOnly: getViewOnly(state),
  type: "section"
})

const mapDispatch = (dispatch, { id }) => ({
  setTitle: title => dispatch(setSectionTitle(title, id)),
  setIntroduction: intro => dispatch(setSectionIntro(intro, id)),
  handleErrors: (errors, type) => dispatch(setPageErrors(errors, type, id))
})

const TemplateSectionCard = connect(mapState, mapDispatch)(SurveyIntroCard)

export default TemplateSectionCard
