import { gql } from "../../../lib/Apollo"

export const GET_ALL_COUNTRIES = gql`
  query {
    allCountries {
      id
      name
    }
  }
`

export const GET_ALL_INDUSTRIES = gql`
  query {
    allIndustries {
      id
      name
    }
  }
`

export const SAVE_FEEDBACK = gql`
  mutation createFeedback($data: FeedbackDataInput!) {
    createFeedback(data: $data)
  }
`
