import { Flex } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { HeatmapHeader } from "../Heatmaps/components/HeatMapHeader"
import { HeatMapResultsContainer } from "../Heatmaps/components/HeatMapResults/HeatMapResultsContainer"
import BackButton from "../components/BackButton"
import { fullHeatmapsClear } from "../store/report.actions"
import { getQuestionFilter } from "../store/report.selectors"

const Heatmap = ({ exportingPDF = true }) => {
  const { id, surveyType } = useParams()
  const dispatch = useDispatch()
  const paths = usePaths()
  const [expand, setExpand] = useState(true)
  const questionFilter = useSelector(getQuestionFilter)
  const hideCollapseButton = !!questionFilter.length
  const showExpand = !expand && !hideCollapseButton ? false : true

  const handleBackClick = () => {
    dispatch(fullHeatmapsClear())
  }

  return (
    <>
      <BackButton
        onClick={handleBackClick}
        path={paths.reports.summary.index
          .replace(":surveyType", surveyType)
          .replace(":id", id)}
      />

      <Flex
        justifyContent="space-between"
        flexDirection={["row", "column", "row"]}
        flexWrap="wrap"
        alignItems="stretch"
        width="100%"
      >
        <HeatmapHeader
          expand={showExpand}
          setExpand={setExpand}
          hideCollapseButton={hideCollapseButton}
        />

        <Flex
          width="100%"
          justifyContent="flex-start"
          className={
            exportingPDF
              ? "report-heatMap-item-exporting"
              : "report-heatMap-item"
          }
        >
          <HeatMapResultsContainer expand={showExpand} />
        </Flex>
      </Flex>
    </>
  )
}

export default Heatmap
