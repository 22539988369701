import React from "react"
import { Text, Flex, Card } from "@engaging-tech/components"

const EmptyCustomQuestion = () => (
  <Card mt={3} elevation={0} width={1 / 1} bg="dark.4" alignItems="center">
    <Flex flexDirection="column" alignItems="center" px={[0, 3]} width={5 / 6}>
      <Text fontWeight="500" mb={3} width="auto" textAlign="center">
        It looks like you are searching for results with fewer than five
        submissions, so we are unable to produce this report.
      </Text>
      <Text fontSize={3} mb={3} width="auto" textAlign="center" color="dark.2">
        Please try updating your filters to create a broader range.
      </Text>
    </Flex>
  </Card>
)

export default EmptyCustomQuestion
