import { connect } from "react-redux"
import { ReportSelectors, ReportActions } from "../../index"
import Breadcrumbs from "../components/Breadcrumbs"

const mapState = state => ({
  stage: ReportSelectors.getSurveyComparisonStage(state),
  breadcrumbs: ReportSelectors.getSurveyComparisonBreadcrumbs(state),
  checkedList: ReportSelectors.getSurveyComparisonCheckedList(state)
})

const mapDispatch = dispatch => ({
  setBreadcrumbs: crumb =>
    dispatch(ReportActions.setSurveyComparisonBreadcrumbs(crumb))
})

const BreadcrumbsContainer = connect(mapState, mapDispatch)(Breadcrumbs)

export default BreadcrumbsContainer
