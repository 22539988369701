import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import Complete from "../containers/create/Complete"
import Description from "../containers/create/Description"
import Details from "../containers/create/Details"
import Personality from "../containers/create/Personality"
import Review from "../containers/create/Review"
import PostJobContext from "../lib/postJobContext"

const PostJob = () => {
  const steps = [
    <Details />,
    <Description />,
    <Personality />,
    <Review />,
    <Complete />
  ]

  const [activeStep, setActiveStep] = useState(0)
  const [totalSteps] = useState(steps.length - 1)
  const paths = usePaths()
  const router = useRouter()

  const goForward = () => setActiveStep(activeStep + 1)
  const goBack = () => setActiveStep(activeStep - 1)

  const goToLastStep = () => setActiveStep(totalSteps)
  const goToFirstStep = () => setActiveStep(0)
  const cancel = () => router.navigate(paths.home.jobs.liveJobs)

  return (
    <PostJobContext.Provider
      value={{
        goForward,
        goBack,
        goToLastStep,
        goToFirstStep,
        cancel
      }}
    >
      {steps[activeStep]}
    </PostJobContext.Provider>
  )
}

export default PostJob
