import { connect } from "react-redux"
import { userInterfaceNotificationsStore } from "@engaging-tech/components"

import { surveyInfoSelectors } from "../../surveyInfo"
import SurveyLinkComponent from "../components/SurveyLink"

const mapState = state => ({
  id: surveyInfoSelectors.getSurveyId(state)
})

const mapDispatch = dispatch => ({
  onCopy: text =>
    dispatch(userInterfaceNotificationsStore.actions.addNotification({ message: text }))
})

const SurveyLink = connect(mapState, mapDispatch)(SurveyLinkComponent)

export default SurveyLink
