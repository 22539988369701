/**
 * The categories of user-generated content that can be present on the business library.
 */
const categories = [
  {
    id: "5d149ad7dcb3ad8a5440ecc6",
    name: "Art"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecc7",
    name: "Beauty"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecc8",
    name: "Books"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecc9",
    name: "Comics"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecca",
    name: "Culture"
  },
  {
    id: "5d149ad7dcb3ad8a5440eccb",
    name: "Fiction"
  },
  {
    id: "5d149ad7dcb3ad8a5440eccc",
    name: "Film"
  },
  {
    id: "5d149ad7dcb3ad8a5440eccd",
    name: "Food"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecce",
    name: "Gaming"
  },
  {
    id: "5d149ad7dcb3ad8a5440eccf",
    name: "Humor"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd0",
    name: "Music"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd1",
    name: "Photography"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd2",
    name: "Podcasts"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd3",
    name: "Poetry"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd4",
    name: "Social media"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd5",
    name: "Sports"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd6",
    name: "Style"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd7",
    name: "True crime"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd8",
    name: "TV"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecd9",
    name: "Writing"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecda",
    name: "Business"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecdb",
    name: "Design"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecdc",
    name: "Economy"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecdd",
    name: "Freelancing"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecde",
    name: "Product Management"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecdf",
    name: "Productivity"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece0",
    name: "Startups"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece1",
    name: "Venture Capital"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece2",
    name: "Accessibility"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece3",
    name: "Android Dev"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece4",
    name: "Artificial Intelligence"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece5",
    name: "Blockchain"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece6",
    name: "Cryptocurrency"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece7",
    name: "Cybersecurity"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece8",
    name: "Data Science"
  },
  {
    id: "5d149ad7dcb3ad8a5440ece9",
    name: "Digital Life"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecea",
    name: "Gadgets"
  },
  {
    id: "5d149ad7dcb3ad8a5440eceb",
    name: "iOS Dev"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecec",
    name: "Javascript"
  },
  {
    id: "5d149ad7dcb3ad8a5440eced",
    name: "Machine Learning"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecee",
    name: "Math"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecef",
    name: "Neuroscience"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf0",
    name: "Programming"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf1",
    name: "Science"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf2",
    name: "Self-Driving Cars"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf3",
    name: "Software Engineering"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf4",
    name: "Space"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf5",
    name: "Technology"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf6",
    name: "UX"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf7",
    name: "Visual Design"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf8",
    name: "Addiction"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecf9",
    name: "Creativity"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecfa",
    name: "Disability"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecfb",
    name: "Technology"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecfc",
    name: "Family"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecfd",
    name: "Health"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecfe",
    name: "Lifestyle"
  },
  {
    id: "5d149ad7dcb3ad8a5440ecff",
    name: "Mental Health"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed00",
    name: "Mindfulness"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed01",
    name: "Money"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed02",
    name: "Parenting"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed03",
    name: "Pets"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed04",
    name: "Psychedelics"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed05",
    name: "Psychology"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed06",
    name: "Relationships"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed07",
    name: "Self"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed08",
    name: "Sexuality"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed09",
    name: "Spirituality"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed0a",
    name: "Travel"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed0b",
    name: "Basic Income"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed0c",
    name: "Cities"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed0d",
    name: "Education"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed0e",
    name: "Environment"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed0f",
    name: "Equality"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed10",
    name: "Future"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed11",
    name: "History"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed12",
    name: "Immigration"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed13",
    name: "Justice"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed14",
    name: "Language"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed15",
    name: "LGBTQIA"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed16",
    name: "Media"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed17",
    name: "Philosophy"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed18",
    name: "Politics"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed19",
    name: "Privacy"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed1a",
    name: "Race"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed1b",
    name: "Religion"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed1c",
    name: "Transportation"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed1d",
    name: "Women"
  },
  {
    id: "5d149ad7dcb3ad8a5440ed1e",
    name: "World"
  },
  {
    id: "5d80b3e9aabef3ae1a7b971a",
    name: "Management"
  }
]

export default categories.sort(
  (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
)
