import React from "react"
import {
  Flex,
  Paragraph,
  Text,
  CircularProgress
} from "@engaging-tech/components"
import { ScoreLabel, getLabelData } from "../../components/ScoreLabel"
import ExplanationBox from "./ExplanationBox"
import ReportCard from "../../components/ReportCard"

const LowestScore = ({ score, stepInfo, exportingPDF }) => {
  return (
    <ReportCard
      heading="Actions to Improve Your Scores"
      width={[1 / 1, 1 / 1, 59 / 100]}
      exportingPDF={exportingPDF}
    >
      <Paragraph color="dark.2" fontSize={3}>
        Our experts have offered advice to improve the six categories of the Six
        Steps to workplace engagement. Each of your scores will be graded
        excellent, good or improvement required, with an accompanying action
        plan.
      </Paragraph>
      {score ? (
        <Flex
          flexDirection={["column", "row"]}
          justifyContent="space-around"
          alignItems="center"
          mt={[0, 0, 3]}
        >
          <Flex flexDirection="column" alignItems="center">
            <CircularProgress
              percentage={score}
              width={200}
              disableAllAnimation={exportingPDF}
              strokeColor={getLabelData(score).color}
            />
            {stepInfo && (
              <Paragraph
                textAlign="center"
                fontSize={3}
                fontWeight="500"
                color="dark.2"
                mb={3}
                mt={3}
              >
                {`Your Average Engagement Score for ${stepInfo.section}`}
              </Paragraph>
            )}
            <ScoreLabel showIcon score={score} />
            <Text
              textAlign="center"
              color="#BF4630"
              fontSize={3}
              fontWeight={500}
              mt={3}
            >
              LOWEST SCORE
            </Text>
          </Flex>
          {stepInfo && (
            <ExplanationBox
              width={7 / 10}
              lowestScore={score}
              section={stepInfo.section}
              explanation={stepInfo.explanation}
            />
          )}
        </Flex>
      ) : (
        <Flex width="auto" justifyContent="center" mt={7}>
          <Text fontSize={6} color="dark.3" textAlign="center" width={4 / 5}>
            Oh no, something has gone wrong! If this continues, please let us
            know
          </Text>
        </Flex>
      )}
    </ReportCard>
  )
}

export default LowestScore
