import { Button, CircularProgress } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import { getLabelData } from "../../components/ScoreLabel"
import SixStepsChartReportCard from "./six-steps-chart-report-card"

const SixStepsIndicatorMessage = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ color }) => color};
`

export default function StepChart({
  exportingPDF,
  stepIndex,
  section,
  score,
  name,
  reportId,
  tag
}) {
  const router = useRouter()

  return (
    <SixStepsChartReportCard
      width="100%"
      title={section}
      stepIndex={stepIndex}
      exportingPDF={exportingPDF}
      onClick={() =>
        router.navigate(`${reportId}/action-plan/${name}?tag=${tag}`)
      }
    >
      <CircularProgress
        percentage={score}
        // disableAllAnimation={exportingPDF}
        strokeColor={getLabelData(score).color}
        width="70%"
        maxWidth={200}
      />
      <SixStepsIndicatorMessage color={getLabelData(score).color}>
        {getLabelData(score).text}
      </SixStepsIndicatorMessage>
      <Button
        variant="secondary.0"
        onClick={() =>
          router.navigate(`${reportId}/action-plan/${name}?tag=${tag}`)
        }
      >
        VIEW DATA
      </Button>
    </SixStepsChartReportCard>
  )
}
