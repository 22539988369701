import { CustomHooks, Flex, Text } from "@engaging-tech/components"
import React, { useRef } from "react"
import { SizeMe } from "react-sizeme"
import usePercentageGraphicAnimation from "../../../../hooks/usePercentageGraphicAnimation"
import BarArrow from "./BarArrow"
import BarShape from "./BarShape"
import BarScale from "./BarScale"
import { getColor } from "./lib/getColorValue"

const arrowSize = 40

const BarIndicatorInner = ({
  containerDimensions,
  dataSet,
  containerRef,
  disableAnimation,
  hideArrowLabels,
  noData,
  leftLabel,
  rightLabel,
  barStructure,
  minValue,
  maxValue,
  unit
}) => {
  const mainScore = dataSet ? dataSet[Object.keys(dataSet)[0]] : 0

  const animatedValue = usePercentageGraphicAnimation({
    scoreToAnimateTo: mainScore,
    delay: 100,
    containerRef
  })
  const indicatorVisible = CustomHooks.useElementVisibleTrigger({
    componentRef: containerRef,
    delay: 100
  })

  const cappedScore = (() => {
    if (animatedValue > maxValue) {
      return maxValue
    }
    if (animatedValue < minValue) {
      return minValue
    }
    return animatedValue
  })()

  const mainNumberFontColor = getColor({
    pointValue: disableAnimation ? mainScore : cappedScore,
    minValue,
    maxValue,
    containerDimensions,
    arrowSize,
    barStructure
  })

  return (
    <>
      {noData ? (
        <Text
          color="dark.1"
          fontSize={6}
          width="100%"
          mb={3}
          style={{ textAlign: "center" }}
        >
          No Data
        </Text>
      ) : (
        <Text
          fontSize={55}
          width="100%"
          style={{ textAlign: "center" }}
          color={mainNumberFontColor}
        >
          {disableAnimation ? Math.round(mainScore) : Math.round(animatedValue)}{unit}
        </Text>
      )}
      <Flex width="100%" justifyContent="space-between" mb={1}>
        <Text width="auto" fontSize="12px" color="dark.2">
          {leftLabel}
        </Text>
        <Text width="auto" fontSize="12px" color="dark.2">
          {rightLabel}
        </Text>
      </Flex>
      <BarShape barStructure={barStructure} />
      <BarScale barStructure={barStructure} />
      {!noData &&
        Object.keys(dataSet).map((key, index) => (
          <BarArrow
            score={dataSet[key]}
            label={key}
            key={key}
            index={index}
            arrowSize={arrowSize}
            minValue={minValue}
            maxValue={maxValue}
            hideArrowLabels={hideArrowLabels}
            containerDimensions={containerDimensions}
            indicatorVisible={indicatorVisible}
            barStructure={barStructure}
          />
        ))}
    </>
  )
}

const BarIndicator = ({
  dataSet,
  disableAnimation,
  hideArrowLabels,
  leftLabel,
  rightLabel,
  minValue = 0,
  maxValue = 100,
  barStructure,
  unit = ""
}) => {
  const containerRef = useRef()

  return (
    <SizeMe>
      {({ size }) => (
        <Flex
          width="calc(100% - 40px)"
          flexDirection="column"
          ref={containerRef}
          mb={`${dataSet && Object.keys(dataSet).length * 5 + 25}px`}
        >
          <BarIndicatorInner
            unit={unit}
            hideArrowLabels={hideArrowLabels}
            containerDimensions={size}
            dataSet={dataSet}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            noData={!dataSet}
            minValue={minValue}
            maxValue={maxValue}
            barStructure={barStructure}
            disableAnimation={disableAnimation}
            containerRef={containerRef}
          />
        </Flex>
      )}
    </SizeMe>
  )
}

export default BarIndicator
