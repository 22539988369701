// Converts a color string into a color value from frontend theme
const colorStringToTheme = (theme, colorString) => {
  if (colorString.includes("rgb(") || colorString.includes("rgba(")) {
    return colorString
  }
  const [, group, shade] = colorString.match(/([a-zA-Z]*).([\d])/)

  if (group && shade) {
    return theme.colors[group][shade]
  }
  return colorString
}
export default colorStringToTheme
