/* eslint-disable prefer-destructuring */
import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import ActiveHeatMapFilters from "./ActiveHeatMapFilters"
import SixStepColumn from "./SixStepColumn"

const DashedFlex = styled(Flex)`
  border-left: 2px dashed #173c5a99;
`

export const OverflowHeatmapHeaderText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.dark[3]};
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const SixStepCollapseColumn = ({
  questionGroup,
  stepIndex,
  allBusinessAreas,
  heatmapFilters,
  allSteps,
  sixStepsComparison,
  showGlobalResult,
  comparisonReport,
  isComparisonLoading,
  comparisonSurveyTitle,
  showPreviousComp,
  comparisonFilters,
  allHeatmapFilters,
  expand,
  directReports,
  surveyType,
  loadFilterData,
  exportingPDF
}) => {
  return (
    <Flex flexDirection="row" className="report-heatMap-item-exporting-child">
      {exportingPDF && (
        <ActiveHeatMapFilters
          heatmapFilters={heatmapFilters}
          loadFilterData={loadFilterData}
          surveyType={surveyType}
          directReports={directReports}
          expand={expand}
          allHeatmapFilters={allHeatmapFilters}
          showGlobalResult={showGlobalResult}
          showPreviousComp={showPreviousComp}
        />
      )}
      <Flex flexDirection="column" width="auto" height="100%" mr={2}>
        <OverflowHeatmapHeaderText
          color="#173C5A99"
          fontWeight={500}
          fontSize={14}
          minWidth={200}
          height={40}
        >
          {questionGroup.title ? questionGroup.title : questionGroup.step}
        </OverflowHeatmapHeaderText>

        <DashedFlex flexDirection="column">
          <SixStepColumn
            questionGroup={questionGroup}
            allBusinessAreas={allBusinessAreas}
            heatmapFilters={heatmapFilters}
            allSteps={allSteps}
            sixStepsComparison={sixStepsComparison}
            showGlobalResult={showGlobalResult}
            firstGroupAndQuestion={stepIndex === 0}
            comparisonReport={comparisonReport}
            isComparisonLoading={isComparisonLoading}
            comparisonSurveyTitle={comparisonSurveyTitle}
            showPreviousComp={showPreviousComp}
            comparisonFilters={comparisonFilters}
          />
        </DashedFlex>
      </Flex>
    </Flex>
  )
}

export default SixStepCollapseColumn
