import { getConfig } from "@engaging-tech/ssr-config"
import { createSelector } from "reselect"

export const accountState = state => state && state.account

export const getShouldClose = createSelector(
  accountState,
  state => state?.shouldClose
)

export const getHasError = createSelector(
  accountState,
  state => state?.hasError
)

export const getIsAuthenticated = createSelector(
  accountState,
  state => state && state.isAuthenticated
)

export const getActiveCrumb = createSelector(
  accountState,
  state => state.formNav.activeCrumb
)

export const getToken = createSelector(
  accountState,
  state => (state && state.token) || null
)

export const getIsLoading = createSelector(
  accountState,
  state => state && state.isLoading
)

export const getIsAuthenticationLoading = createSelector(
  accountState,
  state => state && state.isAuthenticationLoading
)

export const getFeatures = createSelector(
  accountState,
  state => state && state?.organisation?.features
)

export const hasAnAwardsSurvey = createSelector(getFeatures, features => {
  const awardsSurvey = features.some(feature =>
    Object.values(getConfig().awardTypes).includes(feature.object_type)
  )

  return awardsSurvey
})

export const getWonAward = createSelector(
  accountState,
  state => state?.organisation?.wonAward
)

export const getHeaderLogo = createSelector(getFeatures, features => {
  const headerLogo = features?.filter(
    entry => entry.object_type === "header-logo"
  )

  if (headerLogo?.length) {
    return headerLogo[0]?.url
      ? headerLogo[0].url.includes("https")
        ? headerLogo[0].url
        : `${getConfig().awsImageEndpoint}${headerLogo[0].url}`
      : ""
  }

  return ""
})

export const getPurchasedCurrentLogo = createSelector(getFeatures, features => {
  const logo = features?.filter(
    entry => entry.object_type === "happiness-workplace-awards"
  )

  return !!logo?.length
})

export const getHasResetPassword = createSelector(
  accountState,
  state => state && state.hasResetPassword
)

export const getIsNotificationVisible = createSelector(
  accountState,
  state => !!state.errorCode
)

export const getOrganisation = createSelector(
  accountState,
  state => state && state.organisation
)

export const getOrganisationCurrentUser = createSelector(
  getOrganisation,
  organisation => organisation && organisation.currentUser
)

export const isCurrentUseradmin = createSelector(
  getOrganisationCurrentUser,
  user => user?.isAdmin
)

export const getHasOrganisations = createSelector(
  getOrganisation,
  organisation => !!organisation && !!organisation.id
)
export const getOrgId = createSelector(
  getOrganisation,
  organisation => !!organisation && organisation.id
)

export const getOrgName = createSelector(
  getOrganisation,
  organisation => !!organisation && organisation.name
)

export const getIndustryId = createSelector(
  getOrganisation,
  organisation => organisation && organisation.industryId
)

export const getCurrentCredits = createSelector(
  getOrganisation,
  organisation => organisation && organisation.credits
)

export const getIsConfirmed = createSelector(
  getOrganisation,
  organisation => organisation && organisation.confirmed
)

export const getServices = createSelector(
  getOrganisation,
  organisation => organisation && organisation.services
)

export const getSurveyLicences = createSelector(
  getServices,
  services =>
    services &&
    services.filter(service => service.name.includes("SurveyLicence"))
)

export const getHasStandardSurveyLicence = createSelector(
  getSurveyLicences,
  licences =>
    !!licences?.find(licence => licence.name === "standardSurveyLicence")
)

export const getHasBespokeSurveyLicence = createSelector(
  getSurveyLicences,
  licences =>
    !!licences?.find(licence => licence.name === "bespokeSurveyLicence")
)

export const getHasStarterSurveyLicence = createSelector(
  getSurveyLicences,
  licences =>
    !!licences?.find(licence => licence.name === "employeeStarterSurveyLicence")
)

export const getHasExitSurveyLicence = createSelector(
  getSurveyLicences,
  licences =>
    !!licences?.find(licence => licence.name === "employeeExitSurveyLicence")
)

export const getHasSliceAndDiceLicence = createSelector(
  getFeatures,
  features =>
    !!features?.find(feature => feature.object_type === "slice-and-dice")
)

export const getIsEBNMember = createSelector(getServices, services =>
  services?.find(service => service.name === "ebn" && service.active)
)

export const getHasLeadsSubscriptionService = createSelector(
  getServices,
  services =>
    services?.find(service => service.name === "leads" && service.active)
)

export const getHasEventsSubscription = createSelector(getServices, services =>
  services?.find(service => service.name === "events" && service.active)
)

export const getHasJobBoardSubscription = createSelector(
  getServices,
  services =>
    services?.find(service => service.name === "jobBoard" && service.active)
)
export const getHasBIPSubscription = createSelector(getServices, services =>
  services?.find(service => service.name === "bpi" && service.active)
)

export const getCheckout = createSelector(
  accountState,
  state => state && state.checkout
)

export const getIntentSecret = createSelector(
  getCheckout,
  checkout => checkout?.intentSecret
)

export const getPaymentStatus = createSelector(
  getCheckout,
  checkout => checkout?.status
)

export const getHasPaid = createSelector(
  getCheckout,
  checkout => checkout && checkout.hasPaid
)

export const getDiscountState = createSelector(
  accountState,
  account => account?.discount
)

export const getAccountCurrency = createSelector(
  accountState,
  account => account?.organisation?.accountPreferences?.currency || "gbp"
)

export const getAccountStateErrorCode = createSelector(
  accountState,
  account => account?.errorCode
)

export const isMagicLinkSent = createSelector(
  accountState,
  state => state?.magicLinkSent
)

export const getPdfDownloadKey = createSelector(
  accountState,
  state => state?.pdfKey
)
