import { Flex, Text } from "@engaging-tech/components"
import React from "react"

const lineWidth = 2
const lineHeight = 8

const BarScaleLabel = ({ label }) => {
  return (
    <Flex
      bg="dark.3"
      height={lineHeight}
      width={`${lineWidth}px`}
      position="relative"
      style={{ transform: `translate(0px, -${lineHeight}px)` }}
    >
      <Text
        position="absolute"
        width={40}
        height={20}
        color="dark.3"
        noSelect
        fontSize="14px"
        style={{ transform: "translate(-20px, 10px)", textAlign: "center" }}
      >
        {label}
      </Text>
    </Flex>
  )
}

const generateBarLabels = barStructure => {
  return barStructure.map((s, i) => {
    return (
      <Flex
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        width={`${s.percentage}%`}
        height="100%"
        justifyContent="space-between"
      >
        <Flex width="auto">
          {s.labelStart && <BarScaleLabel label={s.labelStart} />}
        </Flex>
        <Flex width="auto">
          {s.labelEnd && <BarScaleLabel label={s.labelEnd} />}
        </Flex>
      </Flex>
    )
  })
}

const BarScale = ({ barStructure }) => {
  return (
    <Flex height={lineHeight} width="100%">
      <Flex
        height={`${lineWidth}px`}
        width="100%"
        bg="dark.3"
        mt="11px"
        justifyContent="space-between"
      >
        {generateBarLabels(barStructure)}
      </Flex>
    </Flex>
  )
}

export default BarScale
