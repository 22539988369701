import { gql } from "@apollo/client"

const CREATE_ACTION_PLAN = gql`
  mutation createActionPlan($title: String!, $planType: String!, $id: String) {
    createActionPlan(title: $title, planType: $planType, id: $id) {
      actionPlans {
        _id
        default
        organisationId
        planType
        status
        surveyCount
        surveyTitle
        title
      }
      status
      message
    }
  }
`

export default CREATE_ACTION_PLAN
