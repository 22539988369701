import { Button, Flex, Icon, Modal, Text } from "@engaging-tech/components"
import { CircleButton } from "@engaging-tech/components/dist/lib/IconButton/IconButton"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const listItems = [
  "*Full breakdowns* of *Survey Questions* and *Employee Experience Matrices*",
  "*Heatmaps* for your Six Steps Scores",
  "*Instant Action Planning*",
  "*Comment Analysis*"
]

const ListItem = styled.li`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary[0]};
  margin-bottom: 16px;
`

const BoldText = styled(Text)`
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary[0]};
`

const CustomModal = styled(Modal)`
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const SliceAndDiceUpgradeModalComponent = ({
  closeModal,
  price,
  currency,
  makeUpgrade
}) => {
  return createPortal(
    <CustomModal bg="secondary.0" maxWidth="max-content" width={1 / 1}>
      <Flex flexDirection="column" width={1 / 1} p={30}>
        <Flex justifyContent="flex-end">
          <CircleButton size={32} onClick={closeModal}>
            <Icon name="close" size={32} color="#fff" />
          </CircleButton>
        </Flex>
        <Flex mb={20}>
          <Text fontSize="24px" color="light.0" fontWeight="700">
            Upgrade your package
          </Text>
        </Flex>
        <Flex
          bg="light.0"
          pl={20}
          pr={60}
          py={10}
          flexDirection="column"
          borderRadius="10px"
        >
          <Text color="black" fontSize={5} fontWeight="700" mt={10}>
            You'll need to upgrade your data package to see this feature.{" "}
          </Text>
          <Flex flexDirection="column" ml={10} mt={10}>
            <Text fontSize={4} color="secondary.0">
              Upgrade to the Enhanced Data Package to get access to the
              following additional data:{" "}
            </Text>
            <Flex flexDirection="column" my={20}>
              <ul>
                {listItems.map(item => {
                  const splitBoldText = item.split("*")
                  return (
                    <ListItem>
                      {splitBoldText.map(text => {
                        if (text) {
                          if (text[0] === " ") {
                            return text
                          } else {
                            return <BoldText>{text}</BoldText>
                          }
                        }
                        return <></>
                      })}
                    </ListItem>
                  )
                })}
              </ul>
            </Flex>
            <Flex flexDirection="column">
              <Flex alignItems="center" mb={20}>
                <Text fontSize={5} color="secondary.0" fontWeight="700">
                  Upgrade prices start from{" "}
                  {price && currency
                    ? new Intl.NumberFormat("en-EN", {
                        style: "currency",
                        minimumFractionDigits: 0,
                        currency
                      }).format(price)
                    : null}
                </Text>
                <Button
                  bg={"primary.0"}
                  color="light.0"
                  width="140px"
                  fontSize="18px"
                  onClick={makeUpgrade}
                >
                  UPGRADE
                </Button>
              </Flex>
              <Text color="secondary.0" fontSize={3}>
                For more information about additional packages and survey
                support please email support@workl.com
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomModal>,
    appRoot()
  )
}

export default SliceAndDiceUpgradeModalComponent
