import { Flex, IconButton, Text } from "@engaging-tech/components"
import React, { useContext } from "react"

import { ListActionPlansContext } from "../../views/ListActionPlans"

const AssignSurveysHeader = ({ setOpenModal }) => {
  const { refetchListActionPlans } = useContext(ListActionPlansContext)

  function closeModal() {
    setOpenModal(false)
    refetchListActionPlans()
  }

  return (
    <Flex
      height="80px"
      bg="secondary.0"
      width={1 / 1}
      justifyContent="space-between"
      p={4}
      alignItems="center"
    >
      <Text color="light.0" fontWeight={600}>
        Assigned to
      </Text>
      <IconButton icon="close" onClick={closeModal} iconColor="light.0" />
    </Flex>
  )
}

export default AssignSurveysHeader
