import { connect } from "react-redux"
import DownloadPDFComponent from "../components/DownloadPDF"
import { ReportSelectors } from ".."
import * as FilterSelectors from "../Filters/store/filters.selectors"
import {
  updateIsPdfDownloading,
  updatePDFProgress
} from "../store/report.actions"
import { getIsPdfDownloading, getSurveyType } from "../store/report.selectors"

const mapState = state => ({
  reportData: ReportSelectors.getReportData(state),
  components: ReportSelectors.getSummaryLayout(state),
  filters: {
    current: FilterSelectors.getCurrentFilters(state),
    required: FilterSelectors.getRequiredFilters(state),
    isDirectReports: FilterSelectors.getIsCurrentDirectReports(state)
  },
  isPdfDownloading: getIsPdfDownloading(state),
  surveyType: getSurveyType(state)
})

const mapDispatch = dispatch => ({
  updatePdfDownloading: payload => dispatch(updateIsPdfDownloading(payload)),
  updatePDFProgress: payload => dispatch(updatePDFProgress(payload))
})

const DownloadPDF = connect(mapState, mapDispatch)(DownloadPDFComponent)

export default DownloadPDF
