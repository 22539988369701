import { Flex, Text } from "@engaging-tech/components"
import React from "react"

export default function StarterExitLabel({ type, width }) {
  return (
    <Flex
      flexDirection="column"
      width={["100%", "100%", width]}
      minWidth={["100%", "100%", width]}
      justifyContent="center"
      mb={[3, 3, 0]}
    >
      <Text fontSize={3} color="dark.2" pb={2} width="auto">
        {type === "employeeStarter" ? "starter survey" : "exit survey"}
      </Text>
    </Flex>
  )
}
