import { usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { OrgGuard } from "../../features/account"
import { PostJob } from "../../features/jobs"
import BackBar from "../../features/ui/components/BackBar"
import CreationFormsLayout from "../../layouts/CreationFormsLayout"
import addParams from "../../utils/addParams"

const Create = () => {
  const paths = usePaths()

  useEffect(() => {
    addParams(false, getConfig().entitlements.jobsBoard, "type")
  }, [])

  return (
    <OrgGuard>
      <CreationFormsLayout
        banner={() => <BackBar to={paths.home.index} text="Back" visible />}
      >
        <PostJob />
      </CreationFormsLayout>
    </OrgGuard>
  )
}

export default Create
