import { usePaths, useRouter } from "@engaging-tech/routing"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { array, number, object, string } from "yup"
import { LoadingState } from "../../components"
import ParticipantsView from "../views/Participants"

const ParticipantsComponent = ({
  handleSubmit,
  handleChange,
  validateForm,
  values,
  errors,
  onClose,
  readOnly,
  surveyId,
  setFieldValue,
  ...props
}) => {
  const router = useRouter()
  const paths = usePaths()

  const handleFormSubmit = async route => {
    const res = await validateForm()
    if (Object.keys(res).length === 0) {
      setFieldValue("onSaveSuccess", () => router.navigate(route))
      handleSubmit()
    }
  }

  const actions = {
    prev: {
      onClick: () =>
        readOnly
          ? router.navigate(
              paths.surveys.viewSurvey.datesAndBranding.replace(":id", surveyId)
            )
          : handleFormSubmit(paths.surveys.surveyCreation.datesAndBranding)
    },
    next: {
      onClick: () =>
        readOnly
          ? onClose() && router.navigate(paths.surveys.liveSurveys)
          : handleFormSubmit(paths.surveys.reviewSurvey),
      children: readOnly ? "Finish" : "Next",
      props: {
        trailingIcon: readOnly ? "done" : "chevron_right"
      }
    },
    saveAndClose: {
      onClick: () => {
        setFieldValue("closeSurvey", true)
        handleFormSubmit(paths.surveys.draftSurveys)
      }
    },
    close: {
      onClick: () =>
        onClose() &&
        router.navigate(
          readOnly ? paths.surveys.liveSurveys : paths.surveys.draftSurveys
        )
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <ParticipantsView
        handleChange={handleChange}
        values={values}
        errors={errors}
        saveAndClose={actions.saveAndClose}
        readOnly={readOnly}
        setFieldValue={setFieldValue}
        {...actions}
        {...props}
      />
    </form>
  )
}

const ParticipantsForm = ({
  onSubmit,
  onClose,
  initialValues,
  meta,
  readOnly,
  surveyId,
  isLoading,
  template,
  isSaving,
  onLoad
}) => {
  const router = useRouter()
  const paths = usePaths()
  const [miniumumSubmissions, setMiniumumSubmissions] = useState(1)
  const [hasLoaded, setHasLoaded] = useState(false)

  // load survey data once, to avoid multiple requests
  useEffect(() => {
    setHasLoaded(true)
  }, [])

  const load = async () => {
    await onLoad(surveyId)
  }

  if (!hasLoaded) load()

  useEffect(() => {
    if (!surveyId) {
      router.redirect(paths.surveys.index)
    }
  }, [paths.surveys.index, router, surveyId])

  useEffect(() => {
    if (template) {
      setMiniumumSubmissions(
        ["employeeStarter", "employeeExit"].includes(template.type) ? 1 : 5
      )
    }
  }, [template])

  if (isSaving) return <LoadingState message="Updating survey meta data..." />

  if (isLoading) return <LoadingState message="Loading new meta data..." />

  const handleSubmit = values =>
    onSubmit({
      meta: {
        ...meta,
        mode: {
          recipients: values.recipients,
          amount: values.amount,
          mode: values.mode
        }
      },
      closeSurvey: values.closeSurvey,
      onSaveSuccess: values.onSaveSuccess
    })

  const validationSchema = object().shape({
    recipients: array().when("mode", {
      is: "uniqueSubmission",
      then: array().min(
        miniumumSubmissions,
        `Please enter at least ${miniumumSubmissions} email addresses`
      )
    }),
    amount: number().when("mode", {
      is: "shareableLinks",
      then: number()
        .min(5, "Please enter at least 5 submissions")
        .integer("Please give a whole number")
        .required("Please specify a number")
    }),
    email: string().email("Please enter a valid email address")
  })

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {props => (
        <ParticipantsComponent
          readOnly={readOnly}
          surveyId={surveyId}
          onClose={onClose}
          template={template}
          {...props}
        />
      )}
    </Formik>
  )
}

export default ParticipantsForm
