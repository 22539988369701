import { gql } from "@apollo/client"

const LOAD_LEADS = gql`
  query getMyLeads {
    getMyLeads {
      id
      expertType {
        id
        name
      }
      location {
        country {
          id
          name
        }
        city
      }
      question
      hubPostId
      publishedAt
    }
  }
`

export default LOAD_LEADS
