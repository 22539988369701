import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { signInFail, signInSuccess } from "../../store/account.actions"
import {
  getHasOrganisations,
  getIsAuthenticated
} from "../../store/account.selectors"
import SignInFormContent from "./SignInFormContent"
import formSchema from "./form-schema"

const SignIn = ({
  cancelRoute,
  redirectTo,
  siteLogo,
  footer,
  title,
  subtitle,
  domain
}) => {
  const router = useRouter()
  let desiredLocation = ""
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    desiredLocation = urlParams.get("desiredLocation")
  }

  const [isLoading, setIsLoading] = useState(false)

  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasOrganisation = useSelector(getHasOrganisations)

  const dispatch = useDispatch()

  const onSubmit = async data => {
    try {
      setIsLoading(true)

      const result = await Auth.signIn({
        username: data.email.toLowerCase(),
        password: data.password
      })

      dispatch(signInSuccess(result.signInUserSession.accessToken.payload.sub))
      router.redirect(desiredLocation || redirectTo)
    } catch (error) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            typeof error.message === "string"
              ? error.message
              : "Unable to sign in"
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onGetCurrentSession = useCallback(async () => {
    try {
      const result = await Auth.currentSession()

      if (result?.accessToken?.payload?.sub) {
        dispatch(signInSuccess(result.accessToken.payload.sub))
      }
    } catch {
      dispatch(signInFail())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onGetCurrentSession()
  }, [onGetCurrentSession])

  useEffect(() => {
    if (isAuthenticated && hasOrganisation) {
      router.redirect(desiredLocation || redirectTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, hasOrganisation])

  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={formSchema}
      onSubmit={accountDetails => onSubmit({ ...accountDetails, domain })}
    >
      {props => (
        <SignInFormContent
          domain={domain}
          cancelRoute={cancelRoute}
          siteLogo={siteLogo}
          title={title}
          subtitle={subtitle}
          footer={footer}
          isAuthenticationLoading={isLoading}
          signUpRoute={
            desiredLocation
              ? "/account/sign-up?desiredLocation=/account/success"
              : "/account/sign-up"
          }
          {...props}
        />
      )}
    </Formik>
  )
}

export default SignIn
