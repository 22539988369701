import {
  Box,
  Button,
  Flex,
  Modal,
  Text,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import { Formik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import { feedbackDialogClose } from "../store/ui.actions"
import CheckboxCustom from "./checkbox"
import { submitFeeback } from "../services/ui.services"
import { getFeedbackData } from "../store/ui.selectors"

const Container = styled(Flex)`
  padding-top: 30px;
  padding-bottom: 70px;
  margin-left: 50px;
  margin-right: 50px;
  width: 95%;
  flex-direction: column;
  align-items: center;
`

const Title = styled(Text)`
  font-size: 21px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;

  color: #173c5a;
`

const Subtitle = styled(Text)`
  margin-top: 30px;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;

  color: #173c5a;
`

const ButtonText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
`

const Form = styled("form")`
  display: flex;
  width: 100%;
  justify-content: center;
`

const feebackOptions = [
  {
    label: "Received an email",
    value: "email"
  },
  {
    label: "Social Media",
    value: "social"
  },
  {
    label: "Referral",
    value: "referral"
  },
  {
    label: "Google/Search engine",
    value: "search_engine"
  },
  {
    label: "Word of mouth",
    value: "word_of_mouth"
  },
  {
    label: "Other",
    value: "other"
  }
]

export default function FeedbackDialog({ isOpen, onClose }) {
  const feedbackData = useSelector(getFeedbackData)

  const dispatch = useDispatch()

  return (
    <Modal
      toggle={isOpen}
      animated
      bg="#F0F8FF"
      maxWidth={550}
      width={1 / 1}
      flexDirection="column"
      alignItems="center"
      onToggle={onClose}
    >
      <Formik
        initialValues={{
          feedbackItem: null
        }}
        onSubmit={async data => {
          try {
            const result = await submitFeeback({
              data: {
                label: data.feedbackItem,
                paymentId: feedbackData?.paymentId
              }
            })

            if (result?.data?.createFeedback) {
              dispatch(feedbackDialogClose())
              dispatch(
                userInterfaceNotificationsStore.actions.addNotification({
                  message: "Feedback submitted successfully"
                })
              )
            }
          } catch (error) {
            dispatch(
              userInterfaceNotificationsStore.actions.addNotification({
                message: "Something went wrong. Please try again."
              })
            )
          }
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Container>
              <Title>A little ask from us</Title>
              <Subtitle>How did you hear about us?</Subtitle>
              <Flex flexDirection="column" justifyContent="flex-start" mt={30}>
                {feebackOptions.map((item, index) => (
                  <Box
                    key={item.value.replace("_", "")}
                    mt={index === 0 ? 0 : 14}
                  >
                    <CheckboxCustom
                      id={`checkbox${index}`}
                      name="feedbackItem"
                      label={item.label}
                      checked={props.values.feedbackItem === item.value}
                      onClick={() => {
                        if (
                          (props.values.feedbackItem === item.value) ===
                          item.value
                        ) {
                          props.setFieldValue("feedbackItem", null)
                        } else {
                          props.setFieldValue("feedbackItem", item.value)
                        }
                      }}
                    />
                  </Box>
                ))}
              </Flex>
              <Button mt={50} variant="primary.0" type="submit">
                <ButtonText color="light.0" type="submit">
                  Submit
                </ButtonText>
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
