import React from "react"
import { Box, Button, Flex, Icon, Text } from "@engaging-tech/components"
import styled from "styled-components"

const HoverBox = styled(Box)`
  display: none;
`

const StyledBox = styled(Box)`
  position: relative;
  &:hover {
    cursor: pointer;
    ${HoverBox} {
      display: block;
      position: absolute;
      top: -23px;
      left: 140px;
      border: ${({ theme }) => `1px solid ${theme.colors.primary[0]}`};
      padding: 12px;
      border-radius: 16px;
      background-color: #fafafa
  }
`

export const DomText = ({ name, fontSize, color, fontWeight }) => (
  <Text
    width="fit-content"
    fontSize={fontSize || 4}
    color={color || "dark.1"}
    fontWeight={fontWeight || 400}
    textAlign="center"
  >
    {name}
  </Text>
)

const generateDOM = (data, router, paths, downloadLogo) =>
  data?.map(object => {
    return Object.keys(object).reduce((acc, key) => {
      const { name, winCategories, status } = object
      const domObj = {}

      if (key === "name")
        domObj.name = (
          <DomText name={name} color="secondary.0" margin="10px 0px 0px 0px" />
        )

      if (key === "winCategories")
        domObj.winCategories = (
          <Flex alignItems="center" flexDirection="column">
            {winCategories?.map(win => (
              <DomText
                key={win}
                name={win}
                color="secondary.0"
                margin="10px 0px 0px 0px"
                fontSize="16px"
              />
            ))}
          </Flex>
        )

      if (key === "status" && status === "AVAILABLE")
        domObj.status = (
          <Button
            width="150px"
            bg="primary.0"
            color="light.0"
            onClick={() => router.navigate(paths.logosAndCertifications.buyNow)}
          >
            Buy Now
          </Button>
        )

      if (key === "status" && status === "PURCHASED")
        domObj.status = (
          <Button
            width="150px"
            bg="primary.0"
            color="light.0"
            onClick={downloadLogo}
          >
            Download
          </Button>
        )
      if (key === "status" && status === "UNAVAILABLE")
        domObj.status = (
          <StyledBox>
            <Icon name="warning" size="40" />
            <HoverBox>
              <Text color="secondary.0">
                Unfortunately you have not won an award for this year.
              </Text>
            </HoverBox>
          </StyledBox>
        )

      if (key === status && status === "NOTPURCHASED")
        domObj.status = (
          <StyledBox>
            <Icon name="warning" size="40" />
            <HoverBox>
              <Text color="secondary.0">
                You did not purchase the logo package for this year.
              </Text>
            </HoverBox>
          </StyledBox>
        )

      return {
        ...acc,
        ...domObj
      }
    }, {})
  })

export default generateDOM
