import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { AccountSelectors } from "../../../../account"
import MultipleImageUploader from "../../../components/image-upload-form/multiple-image-uploader"
import SectionCard from "../../../components/section-card"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  font-size: 16px;
  line-height: 20.8px;
  color: #173c5a;
`

const CustomText = styled.span`
  margin-right: 5px;
`

const TextLink = styled(CustomText)`
  text-decoration: underline;
  white-space: nowrap;
`

export default function WorkLAwardsWins({ isLoading }) {
  const orgId = useSelector(AccountSelectors.getOrgId)

  return (
    <SectionCard title="WorkL Awards Wins">
      <Container>
        <CustomText>
          Your WorkL award wins will be automatically added to your BIP. You can promote and display your wins
          by
        </CustomText>
        <TextLink>purchasing a logo.</TextLink>
      </Container>

      <MultipleImageUploader
        id="awardsLogoList"
        icon="banner"
        title="Add your other award wins"
        subTitle="You can upload awards you’ve won elsewhere by uploading them using the upload tool."
        orgId={orgId}
        many
        disabled={isLoading}
      />
    </SectionCard>
  )
}
