/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from "react"
import { string, object, number, ref, boolean, date } from "yup"
import { getConfig } from "@engaging-tech/ssr-config"
import { Formik } from "formik"
import {
  Flex,
  TextField,
  Select,
  Box,
  Button,
  Checkbox,
  Text,
  DatePicker,
  LocationAutocomplete
} from "@engaging-tech/components"
import StepBox from "../components/StepBox"
import FormWrapper, { Content, BottomBar } from "../components/FormWrapper"
import jobTypes from "../lib/jobTypes"
import experienceLevels from "../lib/experienceLevels"
import PostJobContext from "../lib/postJobContext"
import positions from "../lib/positions"

const Form = ({
  handleSubmit,
  values,
  errors,
  handleChange,
  handleBlur,
  isValid,
  setFieldValue,
  setFieldTouched
}) => {
  const { goForward, cancel } = useContext(PostJobContext)

  const handleFormSubmission = e => {
    handleSubmit(e)
    setTimeout(() => goForward(), 100) // force redux action dispatch before unmounting the component
  }

  const handleLocationChange = e => {
    if (e?.geometry) {
      setFieldValue("lat", e.geometry.location.lat())
      setFieldValue("lang", e.geometry.location.lng())
      setFieldValue("location", e.formatted_address)
      setFieldValue(
        "country_code",
        e.address_components.filter(component =>
          component.types.includes("country")
        )[0].short_name
      )
      setFieldValue(
        "postcode",
        e.address_components.filter(component =>
          component.types.includes("postal_code")
        )[0]?.long_name
      )
    } else if (values.location) {
      if (e !== values.location.name)
        // this happens when they have not selected a dropdown object, and 'e' === formatted_address
        setFieldValue("location", "")
    }
  }

  return (
    <FormWrapper onSubmit={handleFormSubmission}>
      <Content>
        <StepBox step="1" title="Admin email">
          <TextField
            width={1 / 1}
            id="contact_email"
            name="contact_email"
            type="contact_email"
            label="Email Address"
            value={values.contact_email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.contact_email}
            helperText="This person will manage the recruitment of the candidate and will be visible on the jobs marketplace"
          />
          <TextField
            width={1 / 1}
            id="url"
            name="url"
            type="text"
            label="URL (optional)"
            value={values.url}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.url}
            helperText="Hyperlink to the company website or the specific job application page"
          />
        </StepBox>
        <StepBox step="2" title="Job role">
          <TextField
            width={1 / 1}
            id="title"
            name="title"
            type="text"
            label="Job role title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.title}
          />
          <Select
            mb={3}
            id="job_type"
            name="job_type"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.job_type}
            error={errors?.job_type}
            label="Job Type"
            defaultValue=""
          >
            <option disabled value="" />
            {jobTypes.map(jobType => (
              <option key={jobType} value={jobType}>
                {jobType}
              </option>
            ))}
          </Select>
          <Select
            mb={3}
            id="experience_level"
            name="experience_level"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.experience_level}
            label="Experience Level"
            defaultValue=""
          >
            <option disabled value="" />
            {experienceLevels.map(level => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </Select>
          <Select
            mb={3}
            colour="dark.2"
            id="position"
            name="position"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.position}
            label="Position"
            defaultValue=""
          >
            <option disabled value="" />
            {positions.map(position => (
              <option key={position} value={position}>
                {position}
              </option>
            ))}
          </Select>
          <Box width={1 / 1}>
            <LocationAutocomplete
              id="location"
              name="location"
              type="text"
              label="Location"
              placeholder=""
              value={values.location || ""}
              googleApiKey={getConfig().keys.googleApiKey}
              width={1 / 1}
              selectedLocationCallback={handleLocationChange}
              onFocus={() => setFieldTouched("location")}
              onChange={handleLocationChange}
              error={errors?.location}
            />
          </Box>
          <Flex
            width={[1 / 1, 1 / 1]}
            flexDirection={["column", "row"]}
            alignItems="center"
          >
            <Box width={[1 / 1, 1 / 2]} mr={[0, 2]}>
              <TextField
                wrapperWidth="100%"
                width={1 / 1}
                id="min_salary"
                name="min_salary"
                type="number"
                label="Minimum salary"
                value={values.min_salary}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.min_salary}
              />
            </Box>
            <Box width={[1 / 1, 1 / 2]} mr={[0, 2]}>
              <TextField
                wrapperWidth="100%"
                width={1 / 1}
                id="max_salary"
                name="max_salary"
                type="number"
                label="Maximum salary"
                value={values.max_salary}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.max_salary}
              />
            </Box>
            <Box width={1 / 1}>
              <Checkbox
                mb={3}
                colour="dark.2"
                id="salary_private"
                checked={values.salary_private}
                onChange={handleChange}
                onBlur={handleBlur}
                label={() => (
                  <Text color="dark.2" fontSize={[4, 3]}>
                    Hide salary on post (will display as{" "}
                    <strong>competitive salary</strong>)
                  </Text>
                )}
              />
            </Box>
          </Flex>
          <Box width={1}>
            <DatePicker
              width={[1 / 1, 1 / 3]}
              onClick={() => setFieldTouched("age", true, true)}
              name="age"
              id="age"
              type="date"
              label="Vacancy expiry date (Optional)"
              format="dd/MM/yyyy"
              value={values.age}
              handleChange={val => setFieldValue("age", val)}
              helperText="Once this date has passed, your job vacancy will be removed from the Job Marketplace and/or Job Matcher"
              error={errors?.age}
            />
          </Box>
        </StepBox>
        <StepBox step="3" title="Candidate skills and interests">
          <TextField
            width={1 / 1}
            name="skills"
            id="skills"
            type="text"
            label="Key skills"
            helperText="Separate skills with a comma e.g. Communication, Teamwork, etc."
            value={values.skills}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.skills}
          />
          <TextField
            width={1 / 1}
            name="hobbies"
            id="hobbies"
            type="text"
            label="Hobbies (optional)"
            helperText="Separate hobbies with a comma e.g. Kayaking, Archery, etc."
            value={values.hobbies}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.hobbies}
          />
        </StepBox>
      </Content>
      <BottomBar alignItems={["flex-start", "center"]} elevation={6}>
        <Flex
          maxWidth="1080px"
          flexDirection="row-reverse"
          justifyContent="space-between"
        >
          <Button
            width={148}
            type="submit"
            disabled={!isValid}
            variant="primary.0"
          >
            Next
          </Button>
          <Button
            variant="text.primary.0"
            width="auto"
            elevation={0}
            onClick={cancel}
          >
            Cancel
          </Button>
        </Flex>
      </BottomBar>
    </FormWrapper>
  )
}

const validation = object().shape({
  age: date()
    .min(new Date(), "Expiry date must be in the future")
    .nullable(),
  contact_email: string()
    .email("Please enter a valid email address")
    .required("A valid email address is required"),
  job_type: string().required("A job type is required"),
  location: string().required("Type and select a location"),
  salary_private: boolean(),
  min_salary: number().when("salary_private", {
    is: false,
    then: number()
      .required("Minimum salary is required")
      .integer("Must not contain a decimal")
      .positive("Must be positive")
      .typeError("Must be a number")
  }),
  max_salary: number().when("salary_private", {
    is: false,
    then: number()
      .required("Maximum salary is required")
      .integer("Must not contain a decimal")
      .positive("Must be positive")
      .typeError("Must be a number")
      .moreThan(ref("min_salary"), "Must be more than minimum salary")
  }),
  title: string().required("A job role title is required"),
  url: string(),
  skills: string().required("A list of skills are required"),
  hobbies: string()
})

// job is a null parameter, that can be later on used if we want to run "update" action on job posts
const DetailsForm = ({ job, onSubmit }) => {
  const initialValues = {
    age: job?.age || null,
    contact_email: job?.contact_email || "",
    job_type: job?.job_type || "",
    location: job?.location || "",
    lat: job?.lat || null,
    lang: job?.lang || null,
    postcode: job?.postcode || "",
    country_code: job?.country_code || "",
    position: job?.position || "",
    max_salary: job?.max_salary || 0,
    min_salary: job?.min_salary || 0,
    salary_private: job?.salary_private || false,
    title: job?.title || "",
    url: job?.url || "",
    skills: job?.skills || "",
    hobbies: job?.hobbies || "",
    experience_level: job?.experience_level || ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
      validateOnMount
      enableReinitialize
    >
      {props => <Form {...props} />}
    </Formik>
  )
}

export default DetailsForm
