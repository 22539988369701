import { Flex, MotionFlex, Spinner, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"

const AnimatedAnswerOption = ({ text, index }) => {
  const spacing = 40
  const yPosition = (index + 1) * spacing
  return (
    <MotionFlex
      key={text}
      variants={{
        entrance: { y: yPosition, opacity: 0 },
        active: { y: yPosition - spacing, opacity: 1 - index * 0.2 },
        exit: { y: yPosition - spacing * 2, opacity: 0 }
      }}
      initial="entrance"
      animate="active"
      exit="exit"
      position="absolute"
      width="95%"
    >
      <Text
        fontSize={["14px", "14px", "16px"]}
        width="95%"
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}
      >
        {text}
      </Text>
    </MotionFlex>
  )
}
let localIndexCount = 0

const TextInputDemo = ({ coreQuestionValues }) => {
  const [indexCursor, setIndexCursor] = useState(localIndexCount)
  const amountToShow = 4
  const getVisibleAnswers = () => {
    const returnArray = coreQuestionValues.slice(
      indexCursor,
      indexCursor + amountToShow
    )
    // If at end of textArray, gather answers from the beginning of the array
    const missingCount = amountToShow - returnArray.length
    const extraArray = coreQuestionValues.slice(0, missingCount)
    returnArray.push(extraArray)

    return returnArray.flat()
  }

  const updateIndexCursor = () => {
    if (coreQuestionValues.length - 1 === localIndexCount) {
      setIndexCursor(0)
      localIndexCount = 0
    } else {
      setIndexCursor(localIndexCount + 1)
      localIndexCount += 1
    }
  }
  useEffect(() => {
    let isCancelled = false
    let interval = null
    const runAsync = async () => {
      if (!isCancelled) {
        interval = setInterval(updateIndexCursor, 1700)
      }
    }

    runAsync()

    return () => {
      isCancelled = true
      if (interval) {
        clearInterval(interval)
      }
      localIndexCount = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex
      border="2px solid #193c5a"
      borderRadius={10}
      height={50}
      width={["100%", "90%", "90%"]}
      position="relative"
      pt="12px"
      pl="8px"
      flexDirection="column"
    >
      <AnimatePresence>
        {getVisibleAnswers().map((t, i) => (
          <AnimatedAnswerOption text={t} key={t} index={i} />
        ))}
      </AnimatePresence>
    </Flex>
  )
}

const CoreQuestionAnimatedIllustration = ({
  coreQuestionValues,
  onLoad,
  isLoading,
  delay,
  ...props
}) => {
  const tabWidth = 30

  useEffect(() => {
    onLoad()
  }, [])
  if (isLoading) return <Spinner size={20} color="primary.0" />
  return (
    <MotionFlex
      width="100%"
      maxWidth={800}
      height={180}
      key={1}
      alignItems="flex-start"
      bg="light.0"
      elevation={3}
      borderRadius="10px"
      flexDirection="row"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { delay } }}
      exit={{ opacity: 0, x: 0 }}
      {...props}
    >
      <MotionFlex
        borderRadius="10px 0px 0px 10px"
        height="100%"
        width={tabWidth}
        justifyContent="center"
        alignItems="center"
        bg="secondary.0"
      >
        <Text
          height="auto"
          width="auto"
          color="light.0"
          fontWeight="700"
          fontSize={14}
          noSelect
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            writingMode: "vertical-lr",
            textAlign: "center",
            transform: "rotate(180deg)"
          }}
        >
          Core Question
        </Text>
      </MotionFlex>
      <Flex width={`calc(100% - ${tabWidth}px)`} p={2}>
        <TextInputDemo coreQuestionValues={coreQuestionValues} />
      </Flex>
    </MotionFlex>
  )
}

export default CoreQuestionAnimatedIllustration
