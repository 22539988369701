import React from "react"
import { Paragraph, Box } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import ReportCard from "../../components/ReportCard"
import PlanItem from "./PlanItem"
import Resources from "./Resources"
import LinkButton from "../../../ui/components/LinkButton"
import ActionPlanItems from "../../lib/ActionPlanItems"

const PDFView = ({ actionPlan, path }) => {
  const { heading, planInfo, resources } = actionPlan
  return (
    <>
      {planInfo.map(planPoint => (
        <ReportCard width={1 / 1} heading={heading} exportingPDF>
          <PlanItem planPoint={planPoint} key={planPoint.subtitle} />
        </ReportCard>
      ))}

      <ReportCard width={1 / 1} heading={heading} exportingPDF>
        <Paragraph fontWeight={500}>Resources</Paragraph>
        <Box bg="primary.5" width="100%" px={3} mb={4}>
          {resources.map(resource => (
            <Resources resource={resource} key={resource.url} />
          ))}
        </Box>
        {path && (
          <LinkButton
            variant="text.primary.0"
            color="primary.0"
            text="Get Training"
            path={path}
          />
        )}
      </ReportCard>
    </>
  )
}

const SixStepsActionPlans = ({
  path = "",
  actionPlanGroup = "generic",
  exportingPDF
}) => {
  const { step } = useParams()

  const actionPlan = ActionPlanItems[actionPlanGroup].find(
    plan => plan.section === step
  )
  if (exportingPDF) {
    return <PDFView actionPlan={actionPlan} path={path} />
  }
  const { heading, planInfo, resources } = actionPlan
  return (
    <ReportCard width={1 / 1} heading={heading}>
      {planInfo.map(planPoint => (
        <PlanItem planPoint={planPoint} key={planPoint.subtitle} />
      ))}
      <Paragraph fontWeight={500}>Resources</Paragraph>
      <Box bg="primary.5" width="100%" px={3} mb={4}>
        {resources.map(resource => (
          <Resources resource={resource} key={resource.url} />
        ))}
      </Box>
      {path && (
        <LinkButton
          variant="text.primary.0"
          color="primary.0"
          text="Get Training"
          path={path}
        />
      )}
    </ReportCard>
  )
}

export default SixStepsActionPlans
