import { Box, Flex, MobileOnly, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import JoinOurNetworkDetail from "./JoinOurNetworkDetail"

const Container = styled(Flex)`
  background-color: white;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
`

const BorderBottomBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
  margin-bottom: 15px;
  width: 100%;
`

const EBlogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/EBlogo.png`)

const JoinOurNetwork = ({ handleCancel, handleAction }) => (
  <Flex width={["95%", "560px"]} ml={[3, 0]}>
    <Container>
      <MobileOnly alignItems="center" justifyContent="center" pl="15%" pb={3}>
        <img src={EBlogo} alt="EB logo" width="225px" />
      </MobileOnly>
      <Text
        textAlign="center"
        fontSize={5}
        fontWeight={600}
        mb={3}
        color="dark.2"
      >
        Join the WorkL Business Network
      </Text>
      <Text
        width="100%"
        textAlign="center"
        fontSize={3}
        px={3}
        mb={3}
        color="dark.2"
      >
        Discover and share ideas to improve the engagement, happiness, wellbeing
        and success of your organisation. Just £500 + VAT per year.
      </Text>
      <BorderBottomBox />
      <JoinOurNetworkDetail
        handleCancel={handleCancel}
        handleAction={handleAction}
      />
    </Container>
  </Flex>
)

export default JoinOurNetwork
