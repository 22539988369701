const currenciesList = [
  {
    code: "GBP",
    title: "Pound Sterling"
  },
  {
    code: "EUR",
    title: "Euro"
  },
  {
    code: "AUD",
    title: "Australian Dollar"
  }
]

export default currenciesList
