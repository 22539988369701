import { connect } from "react-redux"
import OrganisationData from "../../views/OrganisationData"
import {
  getTemplateName,
  getEndDate,
  getStartDate,
  getEmployeeCount,
  getTitle,
  getIntroduction,
  getPageErrors,
  getAwardsEndDate,
  getPrivate
} from "../../store/awards.selectors"
import { setPage, setPrivate } from "../../store/awards.actions"

const mapState = state => ({
  templateName: getTemplateName(state),
  organisationData: {
    endDate: getEndDate(state),
    startDate: getStartDate(state),
    employeeCount: getEmployeeCount(state),
    title: getTitle(state),
    introduction: getIntroduction(state),
    private: getPrivate(state)
  },
  awardsEndDate: getAwardsEndDate(state),
  pageErrors: getPageErrors(state)
})

const mapDispatch = dispatch => ({
  setPage: page => dispatch(setPage(page)),
  setPrivate: (key, value) => dispatch(setPrivate(value))
})

const OrganisationDataContainer = connect(
  mapState,
  mapDispatch
)(OrganisationData)

export default OrganisationDataContainer
