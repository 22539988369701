import { Flex, MotionFlex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"

import React, { useState, useEffect } from "react"

const questionTypeOptions = ["Dropdown", "Multiple Choice", "Radio", "Scale", "Smileys"]

const textInputExamples = [
  "What is your favorite aspect of working at Machinos?",
  "How long is your travel to work?",
  "What social activities would you like added to Social Fridays?",
  "Would you like Design London to go to a flexible working model?",
  "What would you think of a new company wide newsletter?",
  "What would improve your happiness within your team?"
]

const AnimatedQuestionTypeOption = ({ text, index }) => {
  const spacing = 40
  const yPosition = (index + 1) * spacing
  return (
    <MotionFlex
      key={text}
      variants={{
        entrance: { y: yPosition, opacity: 0 },
        active: { y: yPosition - spacing, opacity: 1 - index * 0.2 },
        exit: { y: yPosition - spacing * 2, opacity: 0 }
      }}
      initial="entrance"
      animate="active"
      exit="exit"
      position="absolute"
      width="95%"
    >
      <Text
        fontSize={["14px", "14px", "16px"]}
        width="95%"
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}
      >
        {text}
      </Text>
    </MotionFlex>
  )
}
let localIndexCount = 0

const TypeInputDemo = () => {
  const [indexCursor, setIndexCursor] = useState(localIndexCount)
  const amountToShow = 2
  const getVisibleOptions = () => {
    const returnArray = questionTypeOptions.slice(
      indexCursor,
      indexCursor + amountToShow
    )
    // If at end of questionTypeOptions array, gather options from the beginning of the array
    const missingCount = amountToShow - returnArray.length
    const extraArray = questionTypeOptions.slice(0, missingCount)
    returnArray.push(extraArray)

    return returnArray.flat()
  }

  const updateIndexCursor = () => {
    if (questionTypeOptions.length - 1 === localIndexCount) {
      setIndexCursor(0)
      localIndexCount = 0
    } else {
      setIndexCursor(localIndexCount + 1)
      localIndexCount += 1
    }
  }
  useEffect(() => {
    let isCancelled = false
    let interval = null
    const runAsync = async () => {
      if (!isCancelled) {
        interval = setInterval(updateIndexCursor, 3400)
      }
    }

    runAsync()

    return () => {
      isCancelled = true
      if (interval) {
        clearInterval(interval)
      }
      localIndexCount = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex
      border="2px solid #193c5a"
      borderRadius={10}
      height={50}
      width={180}
      minWidth={180}
      position="relative"
      pt="12px"
      pl="8px"
      flexDirection="column"
    >
      <AnimatePresence>
        {getVisibleOptions().map((t, i) => (
          <AnimatedQuestionTypeOption text={t} key={t} index={i} />
        ))}
      </AnimatePresence>
    </Flex>
  )
}

const AnimatedTextInput = ({ text }) => {
  return (
    <Flex alignItems="center" width="calc(100% - 20px)">
      <Text
        width="auto"
        noSelect
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}
        fontSize={["14px", "14px", "16px"]}
      >
        {text}
      </Text>
      <MotionFlex
        height={20}
        width={2}
        ml={1}
        bg="dark.2"
        animate={{
          opacity: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1],
          transition: {
            type: "tween",
            repeatType: "loop",
            ease: "anticipate",
            duration: 1.4,
            repeat: Infinity
          }
        }}
      />
    </Flex>
  )
}

let localTextIndexCursor = 0
let localTextIndex = 0
let textModeForward = true

const QuestionTitleInputDemo = () => {
  const [textIndexCursor, setTextIndexCursor] = useState(0)
  const getTextState = () => {
    return textInputExamples[localTextIndex].slice(0, textIndexCursor)
  }

  const updateTextIndexCursorForward = () => {
    if (textModeForward) {
      if (textInputExamples[localTextIndex].length === localTextIndexCursor) {
        setTimeout(() => {
          textModeForward = false
        }, 2000)
      } else {
        setTextIndexCursor(localTextIndexCursor + 1)
        localTextIndexCursor += 1
      }
    }
  }

  const updateTextIndexCursorBackwards = () => {
    if (!textModeForward) {
      if (localTextIndexCursor === 0) {
        if (localTextIndex + 1 === textInputExamples.length) {
          localTextIndex = 0
        } else {
          localTextIndex += 1
        }
        setTimeout(() => {
          textModeForward = true
        }, 1000)
      } else {
        setTextIndexCursor(localTextIndexCursor - 1)
        localTextIndexCursor -= 1
      }
    }
  }

  useEffect(() => {
    let isCancelled = false
    let typeInterval = null
    let deleteInterval = null
    const runAsync = async () => {
      if (!isCancelled) {
        typeInterval = setInterval(updateTextIndexCursorForward, 150)
        deleteInterval = setInterval(updateTextIndexCursorBackwards, 30)
      }
    }

    runAsync()

    return () => {
      isCancelled = true
      if (typeInterval) {
        clearInterval(typeInterval)
      }
      if (deleteInterval) {
        clearInterval(deleteInterval)
      }
      localTextIndexCursor = 0
      localTextIndex = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex
      border="2px solid #193c5a"
      borderRadius={10}
      height={50}
      width={["100%", "calc(100% - 210px)", "calc(100% - 210px)"]}
      maxWidth={["100%", "calc(100% - 210px)", "calc(100% - 210px)"]}
      position="relative"
      pt="12px"
      pl="8px"
      mb={[40, 0, 0]}
      flexDirection="column"
    >
      <AnimatedTextInput text={getTextState()} />
    </Flex>
  )
}

const CustomQuestionAnimatedIllustration = ({ delay, ...props }) => {
  const tabWidth = 30

  return (
    <MotionFlex
      width="100%"
      maxWidth={800}
      height={180}
      key={1}
      alignItems="flex-start"
      bg="light.0"
      elevation={3}
      borderRadius="10px"
      flexDirection="row"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { delay } }}
      exit={{ opacity: 0, x: 0 }}
      {...props}
    >
      <MotionFlex
        borderRadius="10px 0px 0px 10px"
        height="100%"
        width={tabWidth}
        justifyContent="center"
        alignItems="center"
        bg="primary.0"
      >
        <Text
          height="auto"
          width="auto"
          color="light.0"
          fontWeight="700"
          fontSize={14}
          noSelect
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            writingMode: "vertical-lr",
            textAlign: "center",
            transform: "rotate(180deg)"
          }}
        >
          Custom Question
        </Text>
      </MotionFlex>
      <Flex
        width={`calc(100% - ${tabWidth}px)`}
        p={2}
        justifyContent="space-between"
        flexDirection={["column", "row", "row"]}
      >
        <QuestionTitleInputDemo />
        <TypeInputDemo />
      </Flex>
    </MotionFlex>
  )
}

export default CustomQuestionAnimatedIllustration
