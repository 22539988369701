import { Box, H2 } from "@engaging-tech/components"
import {
  BillingForm as BillingFormComponent,
  StripeWrapper
} from "@engaging-tech/payments"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import styled from "styled-components"
import { Card } from "../../components"

const STRIPE_KEY = () => getConfig().keys.stripe

const StyledBillingContainer = styled(Box)`
  & input:not(:last-child),
  & div:nth-child(7) > div > div:first-child,
  & select {
    border: ${({ error, theme }) =>
      error
        ? `1px solid ${theme.colors.error[0]}`
        : "1px solid rgba(99,152,195,1)"};
    padding: 15px;
    background-color: #f4f4f4;
    color: ${({ theme }) => theme.colors.dark[0]};
    &:focus {
      border: ${({ error, theme }) =>
        error
          ? `2px solid ${theme.colors.error[0]}`
          : "2px solid rgba(99,152,195,1)"};
      padding: 14px;
      outline: 0;
      background-color: #eaeaea;
    }
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.dark[0]};
  }

  & label {
    color: ${({ theme }) => theme.colors.dark[3]};
  }

  & button:not([type="submit"]) {
    display: none;
  }

  & span {
    font-family: Centra;
  }

  & .StripeElement {
    margin-top: 8px;
  }

  & div:nth-child(7) input[type="text"] {
    padding: 0;
  }
`

const BillingForm = ({
  onSubmit,
  isLoading,
  isPaying,
  intentSecret,
  paymentActionSuccess,
  paymentActionFail,
  setActions,
  actions,
  onClose,
  previousRoute
}) => {
  const router = useRouter()
  const paths = usePaths()
  useEffect(
    () =>
      setActions({
        ...actions,
        prev: {
          onClick: () => router.navigate(previousRoute)
        },
        close: {
          onClick: () => {
            onClose()
            router.navigate(paths.surveys.index)
          }
        }
      }),
    [previousRoute]
  )

  const paymentAction = intentSecret
    ? { action: "handle_card_payment", intentSecret }
    : null

  return (
    <Card>
      <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={5}>
        Billing Information
      </H2>
      <StripeWrapper apiKey={STRIPE_KEY()}>
        <StyledBillingContainer>
          <BillingFormComponent
            isLoading={isLoading || isPaying}
            paymentAction={paymentAction}
            onSubmit={{
              action: onSubmit,
              text: "Purchase",
              variant: "primary.0"
            }}
            onCancel={{
              action: () => router.navigate(previousRoute),
              text: "Cancel",
              variant: "outline.primary.0"
            }}
            onPaymentActionSuccess={{
              action: () => {
                paymentActionSuccess()
                router.navigate(previousRoute)
              },
              text: "Success Payment Action"
            }}
            onPaymentActionFail={{
              action: paymentActionFail,
              text: "Failed Payment Action"
            }}
          />
        </StyledBillingContainer>
      </StripeWrapper>
    </Card>
  )
}

export default BillingForm
