import { gql } from "../../../../lib/Apollo"

export const LOAD_TEMPLATE = gql`
  query ($id: ID!) {
    getSurveyTemplate(id: $id) {
      id
      type
      isDraft
      name
      type
      workFromHome
      bespokeSteps
      branchingLogic {
        targetAnswers
        targetQuestion
        targetSection
        choice
        currentQuestion
      }
      questions {
        description
        id
        isCore
        type
        title
        enabled
        required
        icon
        ... on MiscQuestion {
          isFilter
          values {
            value
            id
          }
        }
        ... on DropdownQuestion {
          isFilter
          label
          values {
            id
            value
            archived
            parentValue
          }
        }
        ... on SmileyFaceQuestion {
          # isFilter
          label
          values {
            id
            value
            smileyValue
          }
        }
        ... on RadioQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }

        ... on ScaleQuestion {
          min
          max
          customStepName
        }
        ... on ShortTextQuestion {
          fields {
            label
            limit
            type
          }
        }
      }
      sections {
        sectionId
        questions
        description {
          content
        }
        visible
        name
      }
    }
  }
`

export const SAVE_ORGANISATION_TEMPLATE = gql`
  mutation (
    $surveyTemplateId: ID
    $surveyTemplate: OrganisationSurveyTemplateInput
  ) {
    saveOrganisationTemplate(
      surveyTemplateId: $surveyTemplateId
      surveyTemplate: $surveyTemplate
    ) {
      id
    }
  }
`

export const GET_CORE_QUESTIONS = gql`
  query ($type: TemplateType!) {
    getCoreQuestions(type: $type) {
      happinessQuestions {
        id
        type
        title
        description
        enabled
        required
        icon
        ... on DropdownQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }
        ... on RadioQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }

        ... on ScaleQuestion {
          min
          max
        }
        ... on ShortTextQuestion {
          fields {
            label
            limit
            type
          }
        }
      }
      additionalQuestions {
        id
        type
        title
        description
        enabled
        required
        icon
        ... on DropdownQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }
        ... on RadioQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }

        ... on ScaleQuestion {
          min
          max
        }
        ... on ShortTextQuestion {
          fields {
            label
            limit
            type
          }
        }
      }
    }
  }
`
