import { Flex, Icon, Text } from "@engaging-tech/components"
import React, { Fragment } from "react"
import styled from "styled-components"

const BreadcumbsWrapper = styled(Flex)`
  justify-content: center;
`

const BreadcumbsContainer = styled(Flex)`
  justify-content: space-around;
  align-items: center;

  max-width: 60%;
  width: 100%;
`

const BreadcumbsItemTitle = styled(Text)`
  text-transform: uppercase;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
`

/**
 *
 * @param {{id: string, title: string}[]} items - Array of breadcrumb items
 * @param {String} active - The active breadcrumb item
 * @param {Function} onActive - Callback function when a breadcrumb item is clicked
 */
export default function Breadcumbs({ items, active }) {
  return (
    <BreadcumbsWrapper>
      <BreadcumbsContainer>
        {items.map((item, index) => {
          return (
            <Fragment key={item.id}>
              {index !== 0 ? <Icon name="chevron_right" /> : null}
              <BreadcumbsItemTitle active={active === item.id}>{item.title}</BreadcumbsItemTitle>
            </Fragment>
          )
        })}
      </BreadcumbsContainer>
    </BreadcumbsWrapper>
  )
}
