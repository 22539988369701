/* eslint-disable no-param-reassign */
import { PopUpMenuButton } from "@engaging-tech/components"
import React, { useState } from "react"

import useGenerateMenu from "../../lib/useGenerateMenu"
import PopUpMenu from "./PopUpMenu"

const PopUpMenuControl = ({
  type,
  setIsEditMode,
  isEditMode,
  currentQuestion,
  currentSection
}) => {
  const [openPopUpMenu, setPopUpMenu] = useState(false)
  const menu = useGenerateMenu(
    type,
    setIsEditMode,
    isEditMode,
    currentQuestion,
    currentSection
  )

  return (
    <>
      {
        <PopUpMenuButton
          onClick={() => setPopUpMenu(!openPopUpMenu)}
          size={0}
          icon={openPopUpMenu ? "close" : "more_vert"}
        />
      }
      {openPopUpMenu && <PopUpMenu menu={menu} />}
    </>
  )
}

export default PopUpMenuControl
