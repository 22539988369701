import { connect } from "react-redux"

import {
  addNewResource,
  deleteResource,
  setFormErrors,
  setResourceDescription,
  setResourceMediaType,
  setResourceTitle,
  setResourceUrl
} from "../../../../store/actionPlans/actionPlans.actions"
import Resources from "../../components/EditActionPlan/Resources"

const mapState = state => ({})

const mapDispatch = dispatch => ({
  addNewResource: (emptyResource, stepQuestionId) =>
    dispatch(addNewResource(emptyResource, stepQuestionId)),
  deleteResource: (resourceIndex, stepQuestionId) =>
    dispatch(deleteResource(resourceIndex, stepQuestionId)),
  setResourceTitle: (title, index, stepQuestionId) =>
    dispatch(setResourceTitle(title, index, stepQuestionId)),
  setResourceMediaType: (type, index, stepQuestionId) =>
    dispatch(setResourceMediaType(type, index, stepQuestionId)),
  setResourceUrl: (url, index, stepQuestionId) =>
    dispatch(setResourceUrl(url, index, stepQuestionId)),
  setResourceDescription: (description, index, stepQuestionId) =>
    dispatch(setResourceDescription(description, index, stepQuestionId)),
  setFormErrors: (field, error) => dispatch(setFormErrors(field, error))
})

const ResourcesContainer = connect(mapState, mapDispatch)(Resources)

export default ResourcesContainer
