import React from "react"

import AddResource from "./AddResource"
import ViewResource from "./ViewResource"

const ResourceItem = ({
  resource,
  editableIndexes,
  setEditableIndexes,
  deleteResource,
  resourceIndex,
  stepQuestionId,
  setResourceTitle,
  setResourceUrl,
  setResourceMediaType,
  setResourceDescription,
  setFormErrors
}) => {
  const isEditable = editableIndexes.includes(resourceIndex)

  return (
    <>
      {isEditable ? (
        <AddResource
          resource={resource}
          setResourceTitle={setResourceTitle}
          setResourceUrl={setResourceUrl}
          setResourceMediaType={setResourceMediaType}
          setResourceDescription={setResourceDescription}
          resourceIndex={resourceIndex}
          stepQuestionId={stepQuestionId}
          setEditableIndexes={setEditableIndexes}
          editableIndexes={editableIndexes}
          setFormErrors={setFormErrors}
        />
      ) : (
        <ViewResource
          resource={resource}
          deleteResource={deleteResource}
          resourceIndex={resourceIndex}
          setEditableIndexes={setEditableIndexes}
          editableIndexes={editableIndexes}
          stepQuestionId={stepQuestionId}
        />
      )}
    </>
  )
}

export default ResourceItem
