export const types = {
  LOAD_TEMPLATE: "[templates.activeTemplates] Load Template",
  LOAD_TEMPLATE_SUCCESS: "[templates.activeTemplates] Load Template Success",
  LOAD_TEMPLATE_FAILURE: "[templates.activeTemplates] Load Template Failure",
  UPDATE_TEMPLATE_VALUE: "[templates.activeTemplate] Update Value",
  SAVE_TEMPLATE: "[templates.activeTemplate] Save Template",
  SAVE_TEMPLATE_SUCCESS: "[templates.activeTemplate] Save Template Success",
  SAVE_TEMPLATE_FAILURE: "[templates.activeTemplate] Save Template Failure",
  UPDATE_SECTION_VALUE: "[templates.activeTemplate] Update Section Value",
  UPDATE_QUESTION_VALUE: "[templates.activeTemplate] Update Question Value",
  DELETE_QUESTION: "[templates.activeTemplate] Delete Question",
  DELETE_SECTION: "[templates.activeTemplate] Delete Section",
  ADD_QUESTION: "[templates.activeTemplate] Add Template Question To Section",
  ADD_QUESTION_SUCCESS:
    "[templates.activeTemplate] Add Template Question To Section Success",
  ADD_QUESTION_FAILURE:
    "[templates.activeTemplate] Add Template Question To Section Failure",
  MOVE_QUESTION: "[templates.activeTemplate] Move Question",
  MOVE_SECTION: "[templates.activeTemplate] Move template section to new index",
  ADD_SECTION: "[templates.activeTemplates] Add template section",
  SET_STACK_ALL_SECTIONS:
    "[templates.activeTemplate] Set Stack To All Sections",
  SET_CORE_QUESTION: "[templates.activeTemplate] Set Core Question",
  SET_CORE_QUESTION_SUCCESS:
    "[templates.activeTemplate] Set Core Question Success",
  SET_CORE_QUESTION_FAILURE:
    "[templates.activeTemplate] Set Core Question Failure",
  SORT_QUESTION_DROPDOWN_ALPHABETICAL:
    "[templates.activeTemplate] Sort Question Dropdown Alphabetically",
  MAP_BACKEND_DATA_TO_FRONTEND:
    "[templates.activeTemplate] Map Backend Data To Frontend",
  RESET: "[templates.activeTemplate] Reset Store",
  SET_BESPOKE_STEPS: "[templates.activeTemplate] Set Bespoke Step"
}

export const setBespokeSteps = payload => ({
  type: types.SET_BESPOKE_STEPS,
  payload
})

export const saveTemplate = onSuccess => ({
  type: types.SAVE_TEMPLATE,
  payload: onSuccess
})

export const saveTemplateSuccuess = () => ({
  type: types.SAVE_TEMPLATE_SUCCESS
})

export const saveTemplateFailure = () => ({
  type: types.SAVE_TEMPLATE_FAILURE
})

export const setCoreQuestion = payload => ({
  type: types.SET_CORE_QUESTION,
  payload
})

export const setCoreQuestionSuccess = payload => ({
  type: types.SET_CORE_QUESTION_SUCCESS,
  payload
})

export const setCoreQuestionFailure = () => ({
  type: types.SET_CORE_QUESTION_FAILURE
})

export const updateTemplateValue = payload => ({
  type: types.UPDATE_TEMPLATE_VALUE,
  payload
})

export const updateSectionValue = payload => ({
  type: types.UPDATE_SECTION_VALUE,
  payload
})

export const updateQuestionValue = payload => ({
  type: types.UPDATE_QUESTION_VALUE,
  payload
})

export const sortQuestionDropdownOptions = payload => ({
  type: types.SORT_QUESTION_DROPDOWN_ALPHABETICAL,
  payload
})

export const deleteQuestion = payload => ({
  type: types.DELETE_QUESTION,
  payload
})

export const moveQuestion = payload => ({
  type: types.MOVE_QUESTION,
  payload
})

export const moveSection = payload => ({
  type: types.MOVE_SECTION,
  payload
})

export const deleteSection = payload => ({
  type: types.DELETE_SECTION,
  payload
})

export const addSection = payload => ({
  type: types.ADD_SECTION,
  payload
})

export const addQuestion = payload => ({
  type: types.ADD_QUESTION,
  payload
})

export const addQuestionSuccess = payload => ({
  type: types.ADD_QUESTION_SUCCESS,
  payload
})

export const addQuestionFailure = () => ({
  type: types.ADD_QUESTION_FAILURE
})

export const setStackAllSections = payload => ({
  type: types.SET_STACK_ALL_SECTIONS,
  payload
})

export const loadTemplate = payload => ({
  type: types.LOAD_TEMPLATE,
  payload
})

export const loadTemplateSuccess = payload => ({
  type: types.LOAD_TEMPLATE_SUCCESS,
  payload
})

export const loadTemplateFailure = () => ({
  type: types.LOAD_TEMPLATE_FAILURE
})

export const reset = () => ({
  type: types.RESET
})
