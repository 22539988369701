import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import AwardsTitle from "./awards-title"
import Title from "./title"

/**
 *
 * @param {string} id
 * @param {string} title
 * @param {string} type
 * @param {string} status
 * @param {boolean} isAwards
 * @returns
 */
export default function SurveyTitle({ id, title, type, status, isAwards }) {
  const config = getConfig()

  if (type === "awards" || isAwards) {
    return (
      <AwardsTitle
        id={id}
        width={1}
        title={title || "Survey Title"}
        subTitle={config.surveyTypes[type]}
        status={status}
      />
    )
  }

  return (
    <Title
      id={id}
      width={1}
      title={title || "Survey Title"}
      subTitle={config.surveyTypes[type]}
      status={status}
      isAwards={type === "awards" || isAwards}
    />
  )
}
