/* eslint-disable no-async-promise-executor */
import { getConfig } from "@engaging-tech/ssr-config"
import { CognitoUser } from "amazon-cognito-identity-js"
import { API, Amplify, Auth } from "aws-amplify"
import axios from "axios"

import { apollo } from "../../../lib/Apollo"
import {
  APPLY_DISCOUNT,
  CONFIRM_EMAIL,
  CREATE_ORGANISATION,
  LOAD_FULL_ORGANISATION,
  LOAD_ORGANISATION,
  PUBLISH_BIP,
  PURCHASE_EBN,
  SEND_CONFIRMATION_EMAIL,
  SEND_MAGIC_LINK_EMAIL,
  UPDATE_BUSINESS_INFO
} from "./account.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const signUp = ({ email, password }) => {
  return new Promise((resolve, reject) =>
    apollo.UserPool().signUp(email, password, [], null, (err, data) => {
      if (err) reject(err)
      resolve(data)
    })
  )
}

export const signIn = ({ email, password }) => {
  return Auth.signIn({ username: email, password })
}

export const getCurrentUser = async () => {
  const user = await Auth.currentSession()
  return user.accessToken.payload
}

export const getSessionToken = async () => {
  const user = await Auth.currentSession()
  return user.getAccessToken().getJwtToken()
}

export const getSessionExpiration = async () => {
  const user = await Auth.currentSession()
  return user.getAccessToken().getExpiration()
}

export const getIdToken = async () => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

export const signOut = async () => {
  await Auth.signOut()
  window.localStorage.removeItem("surveyIdUpgrade")
}

export const requestPasswordReset = ({ email }) => {
  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: apollo.UserPool()
  })
  return new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
      inputVerificationCode: resolve
    })
  )
}

export const resetPassword = ({ email, code, password }, UserPool = apollo.UserPool()) => {
  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: UserPool
  })

  return new Promise((resolve, reject) =>
    cognitoUser.confirmPassword(code, password, {
      onSuccess: resolve,
      onFailure: reject
    })
  )
}

//* organisation queries *//

export const createOrganisation = ({
  name,
  admin,
  countryId,
  industryId,
  allowMarketing,
  email,
  websiteURL,
  phoneNumber,
  accountPreferences
}) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: CREATE_ORGANISATION,
    variables: {
      name,
      admin,
      countryId,
      industryId,
      allowMarketing,
      email,
      websiteURL,
      phoneNumber,
      accountPreferences
    }
  })

export const load = () => {
  return apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_ORGANISATION
  })
}

export const fullLoad = () => {
  return apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_FULL_ORGANISATION
  })
}

export const requestConfirmEmail = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: SEND_CONFIRMATION_EMAIL
  })

export const confirmEmail = confirmToken => {
  return apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: CONFIRM_EMAIL,
    variables: { confirmToken }
  })
}

export const createIntent = ({ source, promotionCodeId }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: PURCHASE_EBN,
    variables: { source, promotionCodeId }
  })

const UPLOAD_PROFILE_IMAGE_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.upload}profileLogo`

export const upload = async (file, url = UPLOAD_PROFILE_IMAGE_URL(), api = axios) => {
  if (!file) return null
  const formData = new FormData()
  formData.append("file", file)
  return api({
    method: "post",
    headers: {
      Authorization: `JWT ${await getSessionToken()}`
    },
    url,
    data: formData
  })
}

export const update = ({ name, industryId, websiteURL, culture, businessInformation }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: UPDATE_BUSINESS_INFO,
    variables: { name, industryId, websiteURL, culture, businessInformation }
  })

export const publish = () =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: PUBLISH_BIP
  })

export const getCognitoTokens = async (code, api = axios) => {
  const { ClientId, RedirectUri, TokenUrl } = getConfig().cognito
  const params = new URLSearchParams()
  params.append("grant_type", "authorization_code")
  params.append("code", code)
  params.append("client_id", ClientId)
  params.append("redirect_uri", RedirectUri)
  return api({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    url: TokenUrl,
    data: params
  })
}

export const applyDiscount = ({ service, discountCode }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: APPLY_DISCOUNT,
    variables: { service, discountCode }
  })

export const sendMagicLinkService = ({ email }) => {
  const config = getConfig()
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.appSync.publicGatewayURL,
    aws_appsync_authenticationType: config.appSync.publicGatewayAuthType,
    aws_appsync_apiKey: config.appSync.publicGatewayApiKey,
    aws_appsync_region: config.appSync.publicGatewayRegion
  })

  return API.graphql({
    query: SEND_MAGIC_LINK_EMAIL,
    variables: { email }
  })
}
