import { getConfig } from "@engaging-tech/ssr-config"
import axios from "axios"

import { apollo } from "../../../../lib/Apollo"
import { getIdToken } from "../../../account/services/account.service"
import { UPDATE_SURVEY } from "./survey-edit-mode.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

// query string section is added at the end, value of variable must be called also
const UPLOAD_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.upload}`

export const updateSurveyService = ({ surveyId, survey }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: UPDATE_SURVEY,
    variables: {
      surveyId,
      survey
    }
  })

const upload = async (file, url = UPLOAD_API_URL(), api = axios) => {
  const tokenString = await getIdToken()

  return api({
    method: "post",
    url,
    data: file,
    headers: { Authorization: `JWT ${tokenString}` }
  })
}

export const uploadLogo = async ({ logo, surveyId }) => {
  const formData = new FormData()
  formData.append("file", logo, surveyId + ".png")
  return await upload(formData, `${UPLOAD_API_URL()}surveyLogo`)
}
