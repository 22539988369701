import { connect } from "react-redux"

import { setActionPlanStep } from "../../../../store/actionPlans/actionPlans.actions"
import { getOrgId } from "../../../account/store/account.selectors"
import { getQuestionScore } from "../../SixStepsQuestions/store/SixStepsQuestions.selectors"
import PageManagementComponent from "../components/SixStepsPagesComponents/PageManagement"

const mapState = (state, { step }) => ({
  stepQuestions: getQuestionScore(state, step),
  orgId: getOrgId(state)
})

const mapDispatch = dispatch => ({
  setActionPlanStep: step => dispatch(setActionPlanStep(step))
})

const PageManagement = connect(mapState, mapDispatch)(PageManagementComponent)

export default PageManagement
