/* eslint-disable camelcase */
import { Button, Flex, Icon, Table, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import { Link, usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import {
  liveJobsTableConfig,
  liveJobsTableHeadings
} from "../lib/jobMatchTableConfig"
import EmptyJobState from "./EmptyJobState"
import NewPostButton from "./NewPostButton"

const getSelectedIcon = selected =>
  selected && (
    <Flex justifyContent="center">
      <Icon name="check" />
    </Flex>
  )

const getMatchesComponent = (jobMatcherEnabled, hasMatches, job_id) =>
  hasMatches ? (
    <Link to={`list/matches/${job_id}`}>
      <Flex justifyContent="center">
        <Button
          borderRadius={4}
          width="auto"
          variant="secondary.0"
          px={[2, 3]}
          py={2}
        >
          <Text width={1} color="dark.0" fontWeight={500} fontSize={3}>
            View Matches
          </Text>
        </Button>
      </Flex>
    </Link>
  ) : (
    <Text fontSize={3} color="dark.2">
      {jobMatcherEnabled ? "Waiting for matches" : "Not Matching"}
    </Text>
  )

const FlexTable = styled(Flex)`
  overflow-x: auto;
`

const formattedRows = rows =>
  rows &&
  rows.map(row => {
    const { title, location, status, matches, job_id } = row
    return {
      jobTitle: titleCaps(title),
      location: titleCaps(location),
      jobsBoard: getSelectedIcon(
        ["PUBLISHED_MARKETPLACE", "PUBLISHED_MARKETPLACE_MATCHER"].includes(
          status
        )
      ),
      jobMatching: getSelectedIcon(
        ["PUBLISHED_MATCHER", "PUBLISHED_MARKETPLACE_MATCHER"].includes(status)
      ),
      matchingStatus: getMatchesComponent(
        ["PUBLISHED_MATCHER", "PUBLISHED_MARKETPLACE_MATCHER"].includes(status),
        matches > 0,
        job_id
      )
    }
  })

const LiveJobs = ({ liveJobs }) => {
  const router = useRouter()
  const paths = usePaths()

  const handleClick = () => router.redirect(paths.home.jobs.postAJob)

  return (
    <>
      <Flex flexDirection={["column", "row"]}>
        {liveJobs && liveJobs.length > 0 ? (
          <Text fontSize={3} pr={[2, 0]} pl={[3, 4]} color="dark.2">
            View your live job posts and create new posts.
          </Text>
        ) : (
          <Text fontSize={3} pr={[2, 0]} pl={[3, 4]} color="dark.2">
            You currently don't have any live job posts. Get posting today! If
            you need any support with posting a job please email{" "}
            <a href="mailto:support@workl.com">
              <u>support@workl.com</u>
            </a>
            .
          </Text>
        )}
        <NewPostButton handleClick={handleClick} title="Post new job" />
      </Flex>
      <Flex justifyContent="center" width="100%" mt={5}>
        <Flex flexDirection="column" width={["auto", "auto", "955px"]}>
          {liveJobs && liveJobs.length ? (
            <FlexTable flexDirection="column" mt={[4, 4, 0]}>
              <Table
                headings={liveJobsTableHeadings}
                rows={formattedRows(liveJobs)}
                config={liveJobsTableConfig}
              />
            </FlexTable>
          ) : (
            <EmptyJobState />
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default LiveJobs
