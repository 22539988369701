import React from "react"

import { Button, Text } from "@engaging-tech/components"
import { useParams, usePaths, useRouter } from "@engaging-tech/routing"

const NoSubmissionsButton = ({ reLoad, isDefault, surveyType }) => {
  const { id } = useParams()
  const router = useRouter()
  const paths = usePaths()

  return (
    <Button
      variant="primary.0"
      width="240px"
      onClick={() => {
        if (isDefault) router.navigate(paths.reports.index)
        else reLoad(id, surveyType)
      }}
    >
      <Text color="light.0">
        Return To {isDefault ? "Dashboard" : "Results"}
      </Text>
    </Button>
  )
}

export default NoSubmissionsButton
