export const types = {
  SET_EDITABLE_QUESTION: "[templates.generic] Set Editable Question",
  SET_SHOW_VALIDATION_MODAL: "[templates.generic] Show Validation Modal",
  RESET: "[templates.generic] Reset",
  SET_BRANCHING_VIEW: "[templates.generic] Set Branching View",
}

export const setCurrentEditableQuestion = sectionQuestionIndex => ({
  type: types.SET_EDITABLE_QUESTION,
  payload: sectionQuestionIndex
})

export const setShowValidationModal = boolean => ({
  type: types.SET_SHOW_VALIDATION_MODAL,
  payload: boolean
})

export const reset = () => ({
  type: types.RESET
})

export const setBranchingView = boolean => ({
  type: types.SET_BRANCHING_VIEW,
  payload: boolean
})
