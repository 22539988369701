import React from "react"
import EBNLoader from "../features/EBN/containers/EBNLoader"
import MinifiedNavLayout from "../layouts/MinifiedNavLayout"
import { OrgGuard } from "../features/account"
import WelcomeView from "../features/welcome/containers/WelcomeView"

const Welcome = () => {
  return (
    <OrgGuard>
      <MinifiedNavLayout bg="#EEEEEE">
        <EBNLoader />
        <WelcomeView />
      </MinifiedNavLayout>
    </OrgGuard>
  )
}

export default Welcome
