import { heatMapTabsValues } from "./heatMapTabsValues"

export function useCheckIfGlobalTabIsSelected(tabSelected) {
  return tabSelected === heatMapTabsValues[heatMapTabsValues.length - 1].key
}

export function useCheckIfPreviousTabIsSelected(tabSelected) {
  return tabSelected === heatMapTabsValues[heatMapTabsValues.length - 2].key
}

export function useCheckIfCurrentTabIsSelected(tabSelected) {
  return tabSelected === heatMapTabsValues[heatMapTabsValues.length - 3].key
}
