/* eslint-disable import/prefer-default-export */
export const types = {
  LOAD: "[CMS] Load CMS",
  LOAD_SUCCESS: "[CMS] Load Success",
  LOAD_FAIL: "[CMS] Load Fail"
}

export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = payload => ({
  type: types.LOAD_SUCCESS,
  payload
})

export const loadFail = payload => ({
  type: types.LOAD_FAIL,
  payload
})
