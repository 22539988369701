import { CustomHooks } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import Nav from "../../surveyInfo/components/Nav"
import OrderSummaryComponent from "../containers/OrderSummary"

const OrderSummary = () => {
  const router = useRouter()
  const paths = usePaths()
  const [actions, setActions] = useState({
    prev: {
      onClick: () => router.navigate(paths.surveys.reviewSurvey)
    }
  })
  CustomHooks.useFreshChatDeactivate(true)
  return (
    <CreationFormsLayout
      bg="#EEF4F9"
      maxWidth="1000px"
      BottomNav={() => <Nav {...actions} />}
      mb={6}
    >
      <OrderSummaryComponent actions={actions} setActions={setActions} />
    </CreationFormsLayout>
  )
}

export default OrderSummary
