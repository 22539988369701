import { Spinner } from "@engaging-tech/components"
import React from "react"

import { GET_MY_EVENTS } from "../../../graphql/queries"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { getMyEventsFail, getMyEventsSuccess } from "../../../store/events/events.actions"
import ErrorState from "../../business-information/components/error-state"
import DisplayProduct from "../../ui/components/DisplayProduct"
import MyEventsContainer from "../containers/MyEventsContainer"

const Home = () => {
  const { errors, isLoading } = useBusinessAppQuery(GET_MY_EVENTS, {
    successAction: getMyEventsSuccess,
    failAction: getMyEventsFail
  })

  if (errors?.errors.filter(error => error.errorType === "ERR403")[0]) return <DisplayProduct />
  if (errors) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return <MyEventsContainer />
}

export default Home
