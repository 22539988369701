export const types = {
  SET_SELECTED_PRODUCT: "[Basket] Set Selected Product",
  SAVE_BASKET_REQ: "[Basket] Save Basket Req",
  SAVE_BASKET_FAIL: "[Basket] Save Basket Fail",
  SAVE_BASKET_SUCCESS: "[Basket] Save Basket Success",
  GET_BASKET_REQ: "[Basket] Get Basket Req",
  GET_BASKET_SUCCESS: "[Basket] Get Basket Success",
  GET_BASKET_FAIL: "[Basket] Get Basket Fail",
  REMOVE_FROM_BASKET_SUCCESS: "[Basket] Remove From Basket Success",
  REMOVE_FROM_BASKET_FAIL: "[Basket] Remove From Basket Fail",
  HANDLE_STRIPE_PAYMENT_FAIL: "[Basket] Handle Stripe Payment Fail",
  HANDLE_INVOICE_FAIL: "[Basket] Handle Invoice Fail",
  SET_ADDED_TO_BASKET: "[Basket] Set Added To Basket"
}

export const setAddedToBasket = payload => ({
  type: types.SET_ADDED_TO_BASKET,
  payload
})

export const handleStripePaymentFail = payload => ({
  type: types.HANDLE_STRIPE_PAYMENT_FAIL,
  payload
})

export const handleInvoiceFail = payload => ({
  type: types.HANDLE_INVOICE_FAIL,
  payload
})

export const getBasketReq = payload => ({
  type: types.GET_BASKET_REQ,
  payload
})

export const getBasketSuccess = data => ({
  type: types.GET_BASKET_SUCCESS,
  payload: data.getBasket
})

export const getBasketFail = payload => ({
  type: types.GET_BASKET_FAIL,
  payload
})

export const setSelectedProduct = payload => ({
  type: types.SET_SELECTED_PRODUCT,
  payload
})

export const saveBasketReq = payload => ({
  type: types.SAVE_BASKET_REQ,
  payload
})

export const saveBasketFail = payload => ({
  type: types.SAVE_BASKET_FAIL,
  payload
})

export const saveBasketSuccess = data => ({
  type: types.SAVE_BASKET_SUCCESS,
  payload: data.saveBasket
})

export const removeFromBasketSuccess = data => ({
  type: types.REMOVE_FROM_BASKET_SUCCESS,
  payload: data.removeFromBasket
})

export const removeFromBasketFail = payload => ({
  type: types.REMOVE_FROM_BASKET_FAIL,
  payload
})
