import { connect } from "react-redux"

import DatesAndBrandingForm from "../forms/DatesAndBranding"
import {
  closeSurvey,
  loadSurvey,
  saveSurveyBrand,
  saveSurveyMeta,
  uploadLogo
} from "../store/surveyInfo.actions"
import {
  getColour,
  getEndDate,
  getFrequency,
  getFrequencyEndDate,
  getIsLoading,
  getIsSaving,
  getLogo,
  getMeta,
  getPrivate,
  getResultsEmails,
  getStartDate,
  getSurveyId,
  getTemplate
} from "../store/surveyInfo.selectors"

const mapDispatch = dispatch => ({
  onSubmit: async payload => dispatch(saveSurveyMeta(payload)),
  onLoad: surveyId => dispatch(loadSurvey(surveyId)),
  onClose: () => dispatch(closeSurvey())
})

const mapState = state => ({
  initialValues: {
    id: getSurveyId(state),
    startDate: getStartDate(state),
    endDate: getEndDate(state),
    colour: getColour(state),
    logo: getLogo(state),
    private: getPrivate(state),
    frequency: getFrequency(state),
    frequencyEndDate: getFrequencyEndDate(state),
    resultsEmails: getResultsEmails(state),
    closeSurvey: false
  },
  isLoading: getIsLoading(state),
  isSaving: getIsSaving(state),
  meta: getMeta(state),
  surveyId: getSurveyId(state),
  template: getTemplate(state)
})

const DatesAndBranding = connect(mapState, mapDispatch)(DatesAndBrandingForm)

export default DatesAndBranding
