import { Box, Button, Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import CircleIcon from "../../ui/components/CircleIcon"
import SiteLogo from "../../ui/components/SiteLogo"

const Container = styled(Flex)`
  background-color: white;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
`

const Column = styled(Flex)`
  flex-direction: column;
  margin-left: 22px;
  margin-right: 22px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
`

const BorderBottomBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
  margin-bottom: 15px;
  width: 100%;
`

const column1 = [
  {
    header: "Complete your business profile today",
    icon: "conference_bullets"
  },
  {
    header: "Post unlimited job vacancies",
    icon: "work"
  },
  {
    header: "Join our monthly topical webinar",
    icon: "forum"
  }
]

const column2 = [
  {
    header: "Connect with other EBN members",
    icon: "message"
  },
  {
    header: "Receive our research packs & 20% off business books",
    icon: "research"
  },
  {
    header: "Attend our Workplace Happiness Summits",
    icon: "event"
  }
]

const ThanksForJoining = ({ handleGetStarted }) => (
  <Flex width={["95%", "560px"]} ml={[3, 0]}>
    <Container>
      <Flex justifyContent="center" pb={3}>
        <SiteLogo />
      </Flex>
      <Text
        textAlign="center"
        fontSize={5}
        fontWeight={600}
        mb={3}
        ml={[1, 0]}
        color="dark.1"
      >
        Thank you for joining our network!
      </Text>
      <Text width="100%" textAlign="center" fontSize={3} px={3} mb={3}>
        A warm welcome to the Engaging Business Network.
      </Text>
      <BorderBottomBox />
      <Flex
        flexDirection={["column", "row"]}
        justifyContent={["center", "space-between"]}
        px={[0, 3]}
        alignItems={["start", "stretch"]}
        mt={2}
      >
        <Column>
          {column1.map((data, index) => (
            <Flex key={index.toString} mb={2}>
              <CircleIcon name={data.icon} size={40} mb={2} />
              <Text fontSize={2} fontWeight={500} ml={2} mb={1} mt={2}>
                {data.header}
              </Text>
            </Flex>
          ))}
        </Column>
        <Column>
          {column2.map((data, index) => (
            <Flex key={index.toString} mb={2}>
              <CircleIcon name={data.icon} size={40} mb={2} />
              <Text fontSize={2} fontWeight={500} ml={2} mb={1} mt={2}>
                {data.header}
              </Text>
            </Flex>
          ))}
        </Column>
      </Flex>
      <Flex justifyContent="center">
        <Button
          variant="secondary.0"
          width={[114, 180]}
          onClick={handleGetStarted}
          mr={[0, 4]}
          my={3}
        >
          Get Started
        </Button>
      </Flex>
    </Container>
  </Flex>
)

export default ThanksForJoining
