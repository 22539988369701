import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const SixStepsChartReportCardTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
`

const SixStepsChartReportCardSubtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.65);
  margin: 0;
`

export default function SixStepsChartReportCard({
  children,
  stepIndex,
  title,
  exportingPDF,
  isPdfDownloading,
  width
}) {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width={1 / 1}
      height="100%"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        mb={4}
      >
        <SixStepsChartReportCardSubtitle>
          Step {stepIndex}
        </SixStepsChartReportCardSubtitle>
        <SixStepsChartReportCardTitle>{title}</SixStepsChartReportCardTitle>
      </Flex>
      {children}
    </Flex>
  )
}
