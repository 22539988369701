import {
  Flex,
  MotionFlex,
  Text,
  TabletUp,
  CustomHooks
} from "@engaging-tech/components"

import React from "react"

import SurveyAnimatedIllustration from "./SurveyAnimatedIllustration"

const StepTwoAnimatedWrapper = ({}) => {
  const device = CustomHooks.useClientDevice()
  const dimensions = (() => {
    switch (device) {
      case "DESKTOP":
        return {
          width: 220,
          height: 310
        }
      case "TABLET":
        return {
          width: 210,
          height: 280
        }
      case "MOBILE":
        return {
          width: 170,
          height: 260
        }
      default:
        return {
          width: 220,
          height: 300
        }
    }
  })()

  return (
    <MotionFlex
      width="100%"
      height="100%"
      key={2}
      pl={10}
      mt={20}
      alignItems="flex-start"
      justifyContent="center"
      initial={{ opacity: 0, transition: { duration: 0.3 } }}
      animate={{
        opacity: 1,
        transition: { delay: 0.5, duration: 0.3 }
      }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <SurveyAnimatedIllustration
        width={dimensions.width}
        height={dimensions.height}
      />
    </MotionFlex>
  )
}

export default StepTwoAnimatedWrapper
