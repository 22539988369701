import { Flex, Text } from "@engaging-tech/components"
import React from "react"

const AverageScore = ({ score, ...props }) => {
  return (
    <Flex
      bg="dark.4"
      height="auto"
      width="auto"
      borderRadius={10}
      flexDirection="column"
      justifyContent={["center", "space-around"]}
      p={props.p ?? 3}
      {...props}
    >
      <Text fontWeight={400} fontSize={3} mb={props.mb ?? 3} color="dark.2" width="auto">
        Average Response Score
      </Text>
      <Text fontWeight={700} fontSize={5} color="#006D2F" mb={props.mb ?? 3} width="auto">
        {score}
      </Text>
    </Flex>
  )
}

export default AverageScore
