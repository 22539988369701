import { connect } from "react-redux"
import SixStepsQuestionsComparison from "../components/SixStepsQuestionsComparison"
import * as Selectors from "../store/SixStepsQuestions.selectors"

const mapState = (state, { step }) => ({
  questionData: Selectors.getQuestionsByStep(state, step)
})

const SixStepsQuestionsChart = connect(mapState)(SixStepsQuestionsComparison)

export default SixStepsQuestionsChart
