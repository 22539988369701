import { connect } from "react-redux"

import {
  setActionPlanList,
  setCloneActionPlan,
  setListActionPlansPage,
  setListActionPlansSort,
  setTab
} from "../../../store/actionPlans/actionPlans.actions"
import {
  getActionPlansList,
  getListActionPlansPage,
  getListActionPlansSort,
  getSelectedTab,
  showListLoadMorePlans
} from "../../../store/actionPlans/actionPlans.selectors"
import ListActionPlans from "../views/ListActionPlans"

const mapState = state => ({
  selectedTab: getSelectedTab(state),
  actionPlansList: getActionPlansList(state),
  page: getListActionPlansPage(state),
  sort: getListActionPlansSort(state),
  showLoadMore: showListLoadMorePlans(state)
})

const mapDispatch = dispatch => ({
  setSelecctedTab: type => dispatch(setTab(type)),
  setActionPlanList: actionPlans => dispatch(setActionPlanList(actionPlans)),
  setPage: page => dispatch(setListActionPlansPage(page)),
  setSort: title => dispatch(setListActionPlansSort(title)),
  setCloneActionPlan: actionPlans => dispatch(setCloneActionPlan(actionPlans))
})

const ListActionPlansContainer = connect(mapState, mapDispatch)(ListActionPlans)

export default ListActionPlansContainer
