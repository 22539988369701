import { connect } from "react-redux"
import WordCloudComponent from "../components/WordCloud"
import * as Selectors from "../store/sentimentAnalysis.selectors"

const mapState = (state, { questionId }) => ({
  wordCountForQuestion: Selectors.getWordCountForQuestion(
    state,
    questionId
  )
})

const WordCloud = connect(mapState)(WordCloudComponent)

export default WordCloud
