import React from "react"
import styled from "styled-components"
import { Box } from "@engaging-tech/components"

const OverflowContainerBox = styled(Box)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const ScrollOverflowBox = ({ minWidth, children }) => (
  <OverflowContainerBox width={1 / 1}>
    <Box minWidth={minWidth}>{children}</Box>
  </OverflowContainerBox>
)

export default ScrollOverflowBox
