import React from "react"
import { Text, Flex } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import styled from "styled-components"
import CircleIcon from "../../../ui/components/CircleIcon"

const SmallImage = styled.img`
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid black;
`

const PlayButton = styled(CircleIcon)`
  position: absolute;
  top: 40%;
  left: 45%;
`

const TinyWebinar = ({
  coverImage,
  title,
  titleFontSize = [3, 4, 4],
  linkTo
}) => {
  const smallImageSizing = {
    height: ["112", "112", "92"]
  }
  return (
    <Link to={linkTo}>
      <Flex
        flexDirection="row"
        alignItems="center"
        width={1 / 1}
        mt={[2, 2, 0]}
      >
        <Flex width={1 / 2} mr={1} justifyContent="center">
          <Flex
            mr={3}
            ml={[0, 6, 0]}
            flexDirection="column"
            width={[1 / 1, 1 / 2, 1 / 1]}
            style={{ position: "relative" }}
          >
            <PlayButton
              bg="dark.0"
              iconColor="light.0"
              name="play_arrow"
              width="28px"
              height="28px"
            />
            <SmallImage
              src={coverImage}
              maxHeight={smallImageSizing.height}
              width="100%"
              alt="Webinar"
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" width={1 / 2}>
          <Text
      
            fontWeight={700}
            fontSize={titleFontSize}
            width={1 / 1}
            textAlign="left"
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    </Link>
  )
}

export default TinyWebinar
