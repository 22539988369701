import * as y from "yup"

const formSchema = y.object().shape({
  password: y
    .string()
    .required("A password is required")
    .min(8, "At least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: y
    .string()
    .oneOf([y.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
  code: y.string().required("A code is required")
})

export default formSchema
