import React, { useContext } from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import TemplateQuestionContext from "./TemplateQuestionContext"

const TransformedText = styled(Text)`
  transform: rotate(180deg);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  writing-mode: vertical-lr;
  color: ${({ theme }) => theme.colors.light[0]};
  font-weight: 700;
  font-size: ${({ theme }) => `${theme.fontSizes[3]}px`};
  line-height: 24px;
`

const QuestionCategory = () => {
  const { isCore } = useContext(TemplateQuestionContext)

  return (
    <Flex
      bg={isCore ? "secondary.0" : "primary.0"}
      width={24}
      borderRadius="10px 0px 0px 10px"
      alignItems="center"
      height="inherit"
    >
      <TransformedText transform="rotate(180deg)">
        {isCore ? "Core Question" : "Custom Question"}
      </TransformedText>
    </Flex>
  )
}

export default QuestionCategory
