import { gql } from "../../../../lib/Apollo"

export const LOAD_SURVEY = gql`
  query ($surveyId: ID!) {
    getOrganisationSurvey(surveyId: $surveyId) {
      title
      introduction {
        type
        content
      }
      type
      status
      meta {
        private
        startDate
        endDate
        brand {
          logo
          colour
        }
        mode {
          shareableLinks {
            enabled
            amount
          }
          uniqueSubmission {
            enabled
            recipients
          }
        }
        ... on OrganisationMeta {
          creditsPaid
        }
        ... on EmployeeStarterMeta {
          frequencySettings {
            frequency
            endDate
          }
          resultsEmails
        }
        ... on EmployeeExitMeta {
          frequencySettings {
            frequency
            endDate
          }
          resultsEmails
        }
      }
      template {
        id
        name
        isBespoke
        type
      }
      isSelfServe
      awardsInfo {
        startDate
        endDate
      }
    }
  }
`
