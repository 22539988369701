import React from "react"
import styled from "styled-components"

import DataHighlightsOnePager from "../../DataHighlights/components/DataHighlightsOnePager"
import DiversityAndInclusionOnePager from "../../DataHighlights/components/DiversityAndInclusionOnePager"
import IndicatorOnePager from "../../DataHighlights/components/IndicatorOnePager"
import NetPromoterScoreOnePager from "../../NetPromoterScore/components/NetPromoterOnePager"
import QuestionsBreakdownOnePager from "../../QuestionsBreakdown/components/QuestionsBreakdownOnePager"
import SixStepsOnePager from "../../SixSteps/components/SixStepsOnePager"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 120px);
  grid-auto-rows: min-content;
`

const MissingData = styled.p`
  width: 100%;
  text-align: center;
`

const OnePageReport = ({
  dataHighlightsScore,
  lowestScore,
  lowestScoreStepInfo,
  highestScore,
  highestScoreStepInfo,
  sixSteps,
  wellBeingAverageScoreData,
  wellBeingMainScore,
  wellBeingLabelScore,
  flightRiskAverageScoreData,
  flightRiskMainScore,
  flightRiskLabelScore,
  managementConfidenceAverageScoreData,
  managementConfidenceMainScore,
  managementConfidenceLabelScore,
  netPromoterData,
  diversityAndInclusionData,
  sixStepsQuestions,
  customScaleQuestions,
  isPdfDownloading,
  hasAreaForCelebration,
  isSliceAndDiceMember
}) => {
  return (
    isPdfDownloading && (
      <div style={{ display: "block" }} className="one-page-exporting">
        <Grid>
          <div
            style={{
              gridColumn: hasAreaForCelebration ? "1 / 3" : "1 / 5",
              gridRow: "1 / 2"
            }}
          >
            {dataHighlightsScore !== null ? (
              <DataHighlightsOnePager
                isPdfDownloading={isPdfDownloading}
                score={dataHighlightsScore}
                heading="Your Data Highlights"
                text="Average Happiness"
              />
            ) : (
              <MissingData>Highlights data missing</MissingData>
            )}
          </div>
          {isSliceAndDiceMember && (
            <div
              style={{
                gridColumn: hasAreaForCelebration ? "3 / 6" : "5 / 9",
                gridRow: "1 / 2"
              }}
            >
              {lowestScore !== null && lowestScoreStepInfo !== null ? (
                <DataHighlightsOnePager
                  isPdfDownloading={isPdfDownloading}
                  score={lowestScore}
                  heading="Area for Improvement"
                  text={lowestScoreStepInfo}
                  isLowest={false}
                />
              ) : (
                <MissingData>Areas for improvement data missing</MissingData>
              )}
            </div>
          )}
          {isSliceAndDiceMember && hasAreaForCelebration && (
            <div style={{ gridColumn: "6 / 9", gridRow: "1 / 2" }}>
              {highestScore !== null && highestScoreStepInfo !== null ? (
                <DataHighlightsOnePager
                  isPdfDownloading={isPdfDownloading}
                  score={highestScore}
                  heading="Area for celebration"
                  text={highestScoreStepInfo}
                  isLowest={false}
                />
              ) : (
                <MissingData>Areas for improvement data missing</MissingData>
              )}
            </div>
          )}
          <div style={{ gridColumn: "9 / 13", gridRow: "1 / 3" }}>
            {sixSteps !== null ? (
              <SixStepsOnePager
                isPdfDownloading={isPdfDownloading}
                sixSteps={sixSteps}
              />
            ) : (
              <MissingData>Six steps data missing</MissingData>
            )}
          </div>
          <div style={{ gridColumn: "1 / 5", gridRow: "2 / 3" }}>
            {wellBeingAverageScoreData !== null &&
            wellBeingMainScore !== null ? (
              <IndicatorOnePager
                isPdfDownloading={isPdfDownloading}
                heading="Wellbeing Risk Index"
                averageScoreData={wellBeingAverageScoreData}
                mainScore={wellBeingMainScore}
                labelScore={wellBeingLabelScore}
              />
            ) : (
              <MissingData>Wellbeing data missing</MissingData>
            )}
          </div>
          <div style={{ gridColumn: "5 / 9", gridRow: "2 / 3" }}>
            {flightRiskAverageScoreData !== null &&
            flightRiskMainScore !== null ? (
              <IndicatorOnePager
                isPdfDownloading={isPdfDownloading}
                heading="Flight Risk"
                averageScoreData={flightRiskAverageScoreData}
                mainScore={flightRiskMainScore}
                labelScore={flightRiskLabelScore}
              />
            ) : (
              <MissingData>Flight risk data missing</MissingData>
            )}
          </div>
          <div style={{ gridColumn: "1 / 4", gridRow: "3 / 4" }}>
            {managementConfidenceAverageScoreData !== null &&
            managementConfidenceMainScore !== null ? (
              <IndicatorOnePager
                isPdfDownloading={isPdfDownloading}
                heading="Confidence in Management"
                averageScoreData={managementConfidenceAverageScoreData}
                mainScore={managementConfidenceMainScore}
                labelScore={managementConfidenceLabelScore}
              />
            ) : (
              <MissingData>Confidence in management data missing</MissingData>
            )}
          </div>
          <div style={{ gridColumn: "4 / 7", gridRow: "3 / 4" }}>
            {netPromoterData !== null ? (
              <NetPromoterScoreOnePager
                isPdfDownloading={isPdfDownloading}
                data={netPromoterData}
              />
            ) : (
              <MissingData>Net promoter score data missing</MissingData>
            )}
          </div>
          <div style={{ gridColumn: "7 / 10", gridRow: "3 / 4" }}>
            {diversityAndInclusionData?.score !== null ? (
              <DiversityAndInclusionOnePager
                isPdfDownloading={isPdfDownloading}
                averageScoreData={diversityAndInclusionData}
              />
            ) : (
              <MissingData>Diversity and inclusion data missing</MissingData>
            )}
          </div>
          <div style={{ gridColumn: "10 / 13", gridRow: "3 / 4" }}>
            {sixStepsQuestions !== null || customScaleQuestions !== null ? (
              <QuestionsBreakdownOnePager
                isPdfDownloading={isPdfDownloading}
                width={["100%"]}
                sixStepsQuestions={sixStepsQuestions}
                customScaleQuestions={customScaleQuestions}
              />
            ) : (
              <MissingData>Question breakdown data missing</MissingData>
            )}
          </div>
        </Grid>
      </div>
    )
  )
}
export default OnePageReport
