import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import SubscriptionLayout from "../../../layouts/SubscriptionLayout"
import JoinOurNetworkDetail from "./JoinOurNetworkDetail"
import JoinOurNetworkHeader from "./JoinOurNetworkHeader"

const JoinLeads = ({ handleCancel, handleAction }) => {
  const router = useRouter()
  const paths = usePaths()
  return (
    <SubscriptionLayout>
      <JoinOurNetworkHeader
        subscriptionType="Leads"
        description="Receive leads from people requesting ‘Ask An Expert’ advice on the WorkL site."
        handleSubscribeClick={() =>
          router.navigate(paths.home.leadGeneration.subscription)
        }
      />
      <JoinOurNetworkDetail
        handleCancel={handleCancel}
        handleAction={handleAction}
        individualService
      />
    </SubscriptionLayout>
  )
}

export default JoinLeads
