import {
  Box,
  Button,
  Flex,
  Icon,
  Paragraph,
  Text
} from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import SiteLogo from "../../ui/components/SiteLogo"

const BorderFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
`

const Grid = ({ icon, title, paragraph }) => (
  <Box
    bg="secondary.4"
    width={[1 / 1, "244px"]}
    m={2}
    px={3}
    py={2}
    borderRadius={4}
    height="140px"
  >
    <Flex flexDirection="row" alignItems="center">
      <Icon name={icon} mr={2} color="dark.2" />
      <Text fontSize={2} color="dark.1" fontWeight={500}>
        {title}
      </Text>
    </Flex>
    <Text fontSize={2} color="dark.2" fontWeight={300}>
      {paragraph}
    </Text>
  </Box>
)

const gridInfo = [
  {
    title: "1. Select your Company Size",
    icon: "store_mall_directory",
    paragraph:
      "Select if you are a small, medium, big or very big company based on your number of employees. "
  },
  {
    title: "2. Set up your Survey",
    icon: "format_list_bulleted",
    paragraph:
      "Select when you’d like your survey to start end and how many employees you have. "
  },
  {
    title: "3. Purchase your Awards Package",
    icon: "payment",
    paragraph:
      "Pay on credit card or generate an invoice to purchase your package and be able to send your survey out. "
  },
  {
    title: "4. You’re Entered!",
    icon: "smiley",
    paragraph:
      "Once your survey results have been collected and the entry period closes, a panel of experts will evaluate your entry and determine the award results."
  }
]

const AwardSuccess = ({
  navigateTo = null,
  buttonText = "Enter the Awards"
}) => {
  const paths = usePaths()
  const router = useRouter()

  const nav = navigateTo ?? paths.awards.index

  return (
    <Flex p={3} justifyContent="center" alignItems="center" mb={[6, 0]}>
      <Flex
        maxWidth={560}
        elevation={12}
        p={3}
        bg="light.0"
        borderRadius={3}
        flexDirection="column"
        alignItems="center"
      >
        <BorderFlex flexDirection="column" alignItems="center">
          <SiteLogo />
          <Text
            color="dark.3"
            fontSize={5}
            fontWeight={600}
            textAlign="center"
            py={3}
          >
            Thank you for creating your account
          </Text>
          <Paragraph textAlign="center" fontSize={[4, 3, 3]} color="dark.2">
            You’re now ready to set up your survey
          </Paragraph>
        </BorderFlex>
        <Flex flexWrap="wrap" py={3} justifyContent="center" width={1 / 1}>
          {gridInfo.map(point => (
            <Grid
              title={point.title}
              icon={point.icon}
              paragraph={point.paragraph}
            />
          ))}
        </Flex>
        <Button
          variant="secondary.0"
          px={5}
          onClick={() => router.navigate(nav)}
        >
          <Text fontSize={3} color="light.0">
            {buttonText}
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default AwardSuccess
