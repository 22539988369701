import React from "react"
import styled from "styled-components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import {
  Button,
  Flex,
  Box,
  Text,
  Icon,
  ShapedSelect
} from "@engaging-tech/components"
import ToggleLogic from "../../../ui/containers/ToggleLogic"

const FilterBadge = styled(Box)`
  border-radius: 16px;
  width: auto;
  padding: 6px 12px;
`

const StyledButton = styled(Button)`
  @media print {
    display: none;
  }
`

const ClickableFlex = styled(Flex)`
  cursor: pointer;
`

const OverFlowFlex = styled(Flex)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: ${({ maxHeight }) => maxHeight || "100px"};
`

const CurrentFilters = ({ currentFilters, onUpdateFilters }) => (
  <ToggleLogic>
    {({ on, toggle }) => (
      <Box>
        {currentFilters && currentFilters.length >= 1 && (
          <OverFlowFlex
            maxHeight="800px"
            flexDirection="row"
            flexWrap="wrap"
            ml={[2, 0]}
            alignItems="center"
            style={{ overflowY: "none" }}
          >
            <ClickableFlex onClick={toggle}>
              <Text width="auto" fontWeight={500} fontSize={3} mr={1}>
                Active Filters:
              </Text>
              {!on &&
                currentFilters.map(({ question }, i) => (
                  <Text
                    color="primary.0"
                    fontSize={3}
                    fontWeight={700}
                    mr={1}
                    width="auto"
                  >
                    {question}
                    {i === currentFilters.length - 1 ? "" : ","}
                  </Text>
                ))}
              <Icon name={on ? "arrow_drop_up" : "arrow_drop_down"} pb={1} />
            </ClickableFlex>

            {on &&
              currentFilters.map(({ question, values }) => {
                return (
                  <Box width={1}>
                    <Text
                      width={1}
                      textAlign="left"
                      fontSize={3}
                      color="primary.0"
                      fontWeight={700}
                    >
                      {question}
                    </Text>
                    <OverFlowFlex
                      flexDirection="row"
                      flexWrap="wrap"
                      width={1}
                      mt={2}
                    >
                      {values.map(({ value }) => (
                        <FilterBadge key={value} bg="secondary.0" m={1}>
                          <Text fontSize={2} color="light.0">
                            {titleCaps(value)}
                          </Text>
                        </FilterBadge>
                      ))}
                    </OverFlowFlex>
                  </Box>
                )
              })}
          </OverFlowFlex>
        )}
        {currentFilters.length >= 1 && (
          <StyledButton
            bg="dark.4"
            color="dark.1"
            my={3}
            px={3}
            trailingIcon="close"
            onClick={() => onUpdateFilters([])}
            borderRadius={40}
            width={170}
            elevation={0}
          >
            Clear Search
          </StyledButton>
        )}
      </Box>
    )}
  </ToggleLogic>
)

const RequiredFilters = ({ requiredFilters, setRequiredFilter }) => {
  const handleChange = e => {
    const [questionId, valueId] = e.target.value.split("_")
    const newRequiredFilter = requiredFilters.find(
      filter => filter.questionId === questionId && filter.valueId === valueId
    )
    setRequiredFilter(newRequiredFilter)
  }

  if (!requiredFilters?.length) return null

  const activeFilter = requiredFilters.find(({ isActive }) => isActive)

  return (
    <Flex width={1} alignItems="center" mb={3} ml={[2, 0]}>
      <Text width="auto" mr={1} fontSize={3}>
        Viewing results from:
      </Text>
      <ShapedSelect
        width="auto"
        onChange={handleChange}
        maxWidth="300px"
        value={`${activeFilter.questionId}_${activeFilter.valueId}`}
      >
        {requiredFilters.map(filter => (
          <option value={`${filter.questionId}_${filter.valueId}`}>
            {filter.question} - {filter.value}
          </option>
        ))}
      </ShapedSelect>
    </Flex>
  )
}

const DirectReports = () => (
  <Text width={1} color="primary.0" fontSize={3} mb={3}>
    This report is made up of responses from people who directly report to you.
  </Text>
)

const ActiveFilters = ({
  currentFilters = [],
  onUpdateFilters,
  requiredFilters = [],
  setRequiredFilter,
  isDirectReports
}) => {
  return (
    <>
      <RequiredFilters
        requiredFilters={requiredFilters}
        setRequiredFilter={setRequiredFilter}
      />
      {isDirectReports && <DirectReports />}
      <CurrentFilters
        currentFilters={currentFilters}
        onUpdateFilters={onUpdateFilters}
      />
    </>
  )
}

export default ActiveFilters
