import { connect } from "react-redux"
import { removeRecipients } from "../../../store/actions"
import { getRecipients } from "../../../store/selectors"
import EmailList from "../../../components/Page2/Participants/EmailList"

const mapState = state => ({
  recipients: getRecipients(state)
})

const mapDispatch = dispatch => ({
  removeRecipients: recipients => dispatch(removeRecipients(recipients))
})

const EmailListContainer = connect(mapState, mapDispatch)(EmailList)

export default EmailListContainer
