import { Flex, Icon, Modal, Spinner, Text } from "@engaging-tech/components"
import { CircleButton } from "@engaging-tech/components/dist/lib/IconButton/IconButton"
import React from "react"
import { createPortal } from "react-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"

import GET_SCORING_AREAS from "../../../../graphql/queries/getScoringAreas"
import useAppSyncQuery from "../../../../hooks/useAppSyncQuery"
import ErrorState from "../../../business-information/components/error-state"
import { getAvailableFilters } from "../../Filters/store/filters.selectors"

const CustomModal = styled(Modal)`
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

export function HighLowAreasModal({
  closeModal,
  highScore,
  questionGroupStep,
  questionId,
  reportId
}) {
  const availableFilters = useSelector(getAvailableFilters)
  const { data, errors, isLoading } = useAppSyncQuery(GET_SCORING_AREAS, {
    variables: {
      reportId: reportId,
      section: questionGroupStep,
      findLowest: !highScore,
      questionId
    }
  })

  const formatDataResponse =
    data && availableFilters
      ? data.getScoringAreas.map(item => {
          const foundArea = availableFilters.find(
            filter => filter.questionId === item.questionId
          )

          if (foundArea) {
            const foundValue = foundArea.values.find(
              value => value.valueId === item.answerId
            )

            if (foundValue) {
              return {
                area: foundArea.question,
                value: foundValue.value
              }
            }
          }
        })
      : []

  return createPortal(
    <CustomModal
      bg="white"
      maxWidth="max-content"
      width={1 / 1}
      onToggle={closeModal}
    >
      <Flex flexDirection="column" width={1 / 1} p={30}>
        <Flex justifyContent="flex-end">
          <Text fontSize="22px" color="#333" fontWeight="bold">
            {highScore ? "Highest" : "Lowest"} scoring areas
          </Text>
          <CircleButton size={32} onClick={closeModal}>
            <Icon name="close" size={32} color="#000" />
          </CircleButton>
        </Flex>
        <Flex flexDirection="column" mt={40}>
          {isLoading ? (
            <Flex p={200} justifyContent="center" alignItems="center">
              <Spinner color="primary.0" />
            </Flex>
          ) : errors ? (
            <ErrorState />
          ) : (
            <>
              <Text mb={40}>
                The following groups had the{" "}
                {highScore ? "highest " : "lowest "}
                average scores in the filters you selected
              </Text>
              {formatDataResponse.map(item => (
                <Flex mb={40} key={item.area}>
                  <Text fontSize="18px">
                    <Text fontSize="18px" fontWeight="bold">
                      {item.area}:{" "}
                    </Text>
                    {item.value}
                  </Text>
                </Flex>
              ))}
            </>
          )}
        </Flex>
      </Flex>
    </CustomModal>,
    appRoot()
  )
}
