import React from "react"

import { SignUp as SignUpView } from "../../features/account"
import GradientLayout from "../../layouts/GradientLayout"

const SignUp = () => (
  <GradientLayout>
    <SignUpView />
  </GradientLayout>
)

export default SignUp
