import React from "react"
import { Box, Text, Select } from "@engaging-tech/components"

const SignUpSelect = ({
  onChange,
  onBlur,
  value,
  name,
  label,
  error,
  children,
  ...props
}) => (
  <Box {...props}>
    <Select
      width={1 / 1}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {children}
    </Select>
    {error && (
      <Text color="error.0" fontSize={2} pl={3}>
        {error}
      </Text>
    )}
  </Box>
)

export default SignUpSelect
