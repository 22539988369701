import { Flex } from "@engaging-tech/components"
import React from "react"
import EBNCard from "../../../ui/components/EBNCard"
import Header from "../Header"
import TinyWebinar from "./TinyWebinar"

const SeeAllWebinars = ({ webinars }) => (
  <>
    <Header title="All Webinars and Podcasts" />
    <EBNCard isSubscribed height="100%">
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        width={1 / 1}
        justifyContent="space-around"
        alignItems="center"
      >
        {webinars.map(webinar => (
          <Flex width={[1 / 1, 1 / 1, 48 / 100]} mb={[3, 3, 4]}>
            <TinyWebinar
              {...webinar}
              linkTo={`webinars/${webinar.id}`}
              titleFontSize={[3, 4, 5]}
            />
          </Flex>
        ))}
      </Flex>
    </EBNCard>
  </>
)

export default SeeAllWebinars
