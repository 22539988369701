/* eslint-disable react-hooks/exhaustive-deps */
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import BespokeOrderSummary from "./BespokeOrderSummary"
import ExitOrderSummary from "./ExitOrderSummary"
import {
  CREDIT_PRICE,
  getTotal,
  getVatPrice,
  hasCorrectSurveyLicence
} from "./lib"
import StandardOrderSummary from "./StandardOrderSummary"
import StarterOrderSummary from "./StarterOrderSummary"

const OrderSummary = ({
  template,
  surveyLicences,
  creditsToPurchase,
  setActions,
  actions,
  bypassPayment,
  onClose: close,
  scheduleSurvey,
  id,
  ...props
}) => {
  const router = useRouter()
  const paths = usePaths()

  useEffect(() => {
    if (!id) {
      router.redirect(paths.surveys.liveSurveys)
    }
  }, [id])

  /**
   * Large arse useEffect to determine EVERY state the organisation can be in (apolgies to the poor souls who have to edit this (and the code below (this comment was generated by Github Copilot LMAO)))
   * Depends on their:
   * - surveyLicences
   * - creditsToPurchase
   * - template
   */
  useEffect(() => {
    // if organisation has survey licence and does not need to purchase credits, bypass payment
    if (
      hasCorrectSurveyLicence(template, surveyLicences) &&
      creditsToPurchase > 0 &&
      CREDIT_PRICE === 0
    ) {
      setActions({
        ...actions,
        next: {
          ...actions.next,
          onClick: () => {
            bypassPayment({
              onSuccess: () => router.navigate(paths.surveys.liveSurveys)
            })
          },
          children: "Finish",
          props: {
            trailingIcon: "done"
          }
        },
        close: {
          onClick: () => close() && router.navigate(paths.surveys.liveSurveys)
        }
      })
      // if organisation does not have enough credits, they need to pay for credits
    } else if (
      hasCorrectSurveyLicence(template, surveyLicences) &&
      creditsToPurchase > 0
    ) {
      setActions({
        ...actions,
        next: {
          ...actions.next,
          onClick: () => router.navigate(paths.surveys.billingForm),
          children: "Purchase Credits",
          props: {
            trailingIcon: "arrow_right_alt"
          }
        },
        close: {
          onClick: () => close() && router.navigate(paths.surveys.liveSurveys)
        }
      })
      // if organisation does not have correct survey licence, they need to purchase a licence
    } else if (
      !hasCorrectSurveyLicence(template, surveyLicences) &&
      (creditsToPurchase <= 0 || CREDIT_PRICE === 0)
    ) {
      setActions({
        ...actions,
        next: {
          ...actions.next,
          onClick: () => router.navigate(paths.surveys.billingForm),
          children: "Purchase Survey License",
          props: {
            trailingIcon: "arrow_right_alt"
          }
        },
        close: {
          onClick: () => close() && router.navigate(paths.surveys.liveSurveys)
        }
      })
      // if organisation does not have correct survey licence and credits, they need to pay for both
    } else if (
      !hasCorrectSurveyLicence(template, surveyLicences) &&
      creditsToPurchase > 0
    ) {
      setActions({
        ...actions,
        next: {
          ...actions.next,
          onClick: () => router.navigate(paths.surveys.billingForm),
          children: "Purchase Survey License and Credits",
          props: {
            trailingIcon: "arrow_right_alt"
          }
        },
        close: {
          onClick: () => close() && router.navigate(paths.surveys.liveSurveys)
        }
      })
      // if organisation has correct survey licence and credits, they can schedule survey
    } else if (
      hasCorrectSurveyLicence(template, surveyLicences) &&
      creditsToPurchase <= 0
    ) {
      setActions({
        ...actions,
        next: {
          ...actions.next,
          onClick: () => {
            scheduleSurvey(id)
            router.navigate(paths.surveys.liveSurveys)
          },
          children: "Finish",
          props: {
            trailingIcon: "done"
          }
        },
        close: {
          onClick: () => close() && router.navigate(paths.surveys.liveSurveys)
        }
      })
    }
  }, [creditsToPurchase, surveyLicences, template])

  if (template.isBespoke)
    return (
      <BespokeOrderSummary
        template={template}
        hasCorrectSurveyLicence={hasCorrectSurveyLicence}
        surveyLicences={surveyLicences}
        creditsToPurchase={creditsToPurchase}
        setActions={setActions}
        actions={actions}
        bypassPayment={bypassPayment}
        close={close}
        scheduleSurvey={scheduleSurvey}
        id={id}
        total={getTotal(template, surveyLicences, creditsToPurchase)}
        vatPrice={getVatPrice(template, surveyLicences, creditsToPurchase)}
        {...props}
      />
    )
  switch (template.type) {
    case "employeeStarter":
      return (
        <StarterOrderSummary
          template={template}
          hasCorrectSurveyLicence={hasCorrectSurveyLicence}
          surveyLicences={surveyLicences}
          creditsToPurchase={creditsToPurchase}
          setActions={setActions}
          actions={actions}
          bypassPayment={bypassPayment}
          close={close}
          scheduleSurvey={scheduleSurvey}
          id={id}
          noCredits
          total={getTotal(template, surveyLicences)}
          vatPrice={getVatPrice(template, surveyLicences)}
          {...props}
        />
      )
    case "employeeExit":
      return (
        <ExitOrderSummary
          template={template}
          hasCorrectSurveyLicence={hasCorrectSurveyLicence}
          surveyLicences={surveyLicences}
          creditsToPurchase={creditsToPurchase}
          setActions={setActions}
          actions={actions}
          bypassPayment={bypassPayment}
          close={close}
          scheduleSurvey={scheduleSurvey}
          id={id}
          noCredits
          total={getTotal(template, surveyLicences)}
          vatPrice={getVatPrice(template, surveyLicences)}
          {...props}
        />
      )
    default:
      return (
        <StandardOrderSummary
          template={template}
          hasCorrectSurveyLicence={hasCorrectSurveyLicence}
          surveyLicences={surveyLicences}
          creditsToPurchase={creditsToPurchase}
          setActions={setActions}
          actions={actions}
          bypassPayment={bypassPayment}
          close={close}
          scheduleSurvey={scheduleSurvey}
          id={id}
          total={getTotal(template, surveyLicences, creditsToPurchase)}
          vatPrice={getVatPrice(template, surveyLicences, creditsToPurchase)}
          {...props}
        />
      )
  }
}

export default OrderSummary
