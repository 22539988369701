import { Box, Checkbox, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React, { useContext } from "react"
import { useSelector } from "react-redux"
import timezones from "timezones-list"

import {
  getAllCountryOptions,
  getAllIndustryOptions
} from "../../ui/store/ui.selectors"
import languagesList from "../../utils/languagesList"
import FormTextField from "./FormTextField"
import SignUpNavigation from "./SignUpNavigation"
import SignUpSelect from "./SignUpSelect"

const TermsAndConditionsLabel = () => (
  <Text color="dark.2" fontSize={[4, 3]}>
    I accept the{" "}
    <Link to="/legal/terms-and-conditions">
      <Text color="primary.0" fontWeight="500" fontSize={[4, 3]}>
        terms and conditions
      </Text>
    </Link>{" "}
    and{" "}
    <Link to="/legal/privacy-policy">
      <Text color="primary.0" fontWeight="500" fontSize={[4, 3]}>
        privacy policy
      </Text>
    </Link>
    , including receiving notifications and other relevant emails that I may
    subscribe to.
  </Text>
)

const MarketingLabel = () => (
  <Text color="dark.2" fontSize={[4, 3]}>
    I’d like to keep informed with latest news from WorkL for Business and their
    Awards partners about my awards entry.
  </Text>
)

const BusinessDetailsForm = ({
  context,
  handleChange,
  touched,
  values,
  handleBlur,
  errors,
  isValid
}) => {
  const { handleBack, onSubmit } = useContext(context)

  const allIndustryOptions = useSelector(getAllIndustryOptions)?.sort((a, b) =>
    a.value.localeCompare(b.value)
  )
  const allCountryOptions = useSelector(getAllCountryOptions)?.sort((a, b) =>
    a.value.localeCompare(b.value)
  )

  return (
    <>
      <Box my={4} px={4}>
        <FormTextField
          width={1 / 1}
          id="sign-up-businessname"
          name="businessName"
          type="text"
          borderRadius="4px 4px 0px 0px"
          label="Business Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.businessName}
          error={touched.businessName && errors.businessName}
        />
        <SignUpSelect
          width={1 / 1}
          name="industry"
          mb={3}
          label="Industry"
          value={values.industry}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="disabled" disabled>
            Industry
          </option>
          {allIndustryOptions?.map(industry => (
            <option key={industry.id} value={industry.id}>
              {industry.value}
            </option>
          ))}
        </SignUpSelect>
        <SignUpSelect
          width={1 / 1}
          mb={3}
          name="country"
          label="Country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="disabled" disabled>
            Country
          </option>
          {allCountryOptions?.map(country => (
            <option key={country.id} value={country.id}>
              {country.value}
            </option>
          ))}
        </SignUpSelect>
        <SignUpSelect
          width={1 / 1}
          mb={3}
          name="language"
          label="Language"
          value={values.language}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="disabled" disabled>
            Language
          </option>
          {languagesList.map(language => (
            <option key={language.code} value={language.code}>
              {language.title}
            </option>
          ))}
        </SignUpSelect>
        {/* For now commented out while we are not 100% on currencies */}
        {/* <SignUpSelect
          width={1 / 1}
          mb={3}
          name="currency"
          label="Currency"
          value={values.currency}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="disabled" disabled>
            Currency
          </option>
          {currenciesList.map(currency => (
            <option key={currency.code} value={currency.code}>
              {currency.code} - {currency.title}
            </option>
          ))}
        </SignUpSelect> */}
        <SignUpSelect
          width={1 / 1}
          mb={3}
          name="timezone"
          label="Timezone"
          value={values.timezone}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="disabled" disabled>
            Timezone
          </option>
          {timezones.map(timezone => (
            <option key={timezone.tzCode} value={timezone.tzCode}>
              {timezone.name}
            </option>
          ))}
        </SignUpSelect>
        <FormTextField
          width={1 / 1}
          id="sign-up-website"
          name="website"
          type="text"
          borderRadius="4px 4px 0px 0px"
          label="Website (Optional)"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.website}
          error={touched.website && errors.website}
        />
        <FormTextField
          width={1 / 1}
          id="sign-up-phone"
          name="phone"
          borderRadius="4px 4px 0px 0px"
          type="tel"
          label="Phone Number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          error={touched.phone && errors.phone}
        />
        <Checkbox
          mb={3}
          colour="dark.2"
          id="allowMarketing"
          checked={values.allowMarketing}
          onChange={handleChange}
          onBlur={handleBlur}
          label={MarketingLabel}
        />
        <Checkbox
          mb={3}
          colour="dark.2"
          id="acceptConditions"
          checked={values.acceptConditions}
          onChange={handleChange}
          onBlur={handleBlur}
          label={TermsAndConditionsLabel}
        />
        <Text fontSize={3} color="dark.3" mb={2}>
          *PLEASE NOTE If you are entering any awards please create an account
          to set up your awards survey
        </Text>
      </Box>
      <SignUpNavigation
        primary={{
          label: "Sign Up",
          action: () => onSubmit(values),
          disabled: !isValid
        }}
        secondary={{
          label: "Back",
          action: handleBack,
          disabled: false
        }}
      />
    </>
  )
}
export default BusinessDetailsForm
