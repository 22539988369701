import { Button, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

const copy = require("copy-text-to-clipboard")

let timeout

const CopyLinkButton = ({ status, width = 1 / 1, directLink = false, id }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => timeout && clearTimeout(timeout)
  }, [])

  const orgSurvey = `${getConfig().workL.app}/organisationSurvey/${id}`
  const link = directLink || orgSurvey

  const handleCopy = () => {
    const isCopySuccessful = copy(link)
    if (!isCopySuccessful) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: `Either Your browser does not support copy to clipboard functionality or something else went wrong. Please copy this link directly: ${link}`
        })
      )
    } else {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Link Copied!"
        })
      )
    }
  }

  return (
    <>
      <Button
        variant="outline.textSecondary.0"
        elevation={0}
        width={width}
        minWidth={width}
        height="auto"
        py={[2, 1]}
        disabled={status === "draft"}
        onClick={handleCopy}
      >
        Copy Link
      </Button>
    </>
  )
}

export default CopyLinkButton
