import React from "react"
import { useSelector } from "react-redux"

import { ReportSelectors } from "../.."
import FilterGroup from "./FilterGroup"

export function QuestionFilter({
  handleChange,
  handleBlur,
  values,
  dropDownList,
  setDropDownList
}) {
  const sixQuestions = useSelector(
    ReportSelectors.getReportResponseSixQuestions
  )

  const questionsArray = []
  sixQuestions.map(step => {
    step.questions.map(item => {
      questionsArray.push({
        valueId: item.id,
        value: `${questionsArray.length + 1}. ${item.question}`
      })
    })
  })
  const questionFormatted = {
    question: "Select questions to add",
    questionId: "question",
    values: questionsArray
  }

  return (
    <>
      {questionsArray.length ? (
        <FilterGroup
          title="Question Filter"
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          filterOptions={[questionFormatted]}
          dropDownList={dropDownList}
          setDropDownList={setDropDownList}
          sortByNumber
        />
      ) : null}
    </>
  )
}
