import React from "react"
import { Flex } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { ConfirmBanner, OrgGuard } from "../features/account"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import Nav from "../features/ui/containers/Nav"
import AddedToBasket from "../features/ui/components/AddedToBasket"

const CreationFormsLayout = ({
  banner: Banner,
  BottomNav,
  children,
  bg,
  ...props
}) => {
  const router = useRouter()
  const { downForMaintenance } = getConfig()
  if (downForMaintenance) {
    router.navigate("/")
  }

  return (
    <Flex
      width="100vw"
      minHeight="100vh"
      bg={bg}
      flexDirection="column"
      alignItems="center"
    >
      <OrgGuard>
        <TestingSiteBanner />
        <Nav />
        <ConfirmBanner />
        {Banner && <Banner />}

        <Flex
          as="main"
          pb={[3, 4]}
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          {...props}
        >
          <AddedToBasket />
          {children}
        </Flex>
        {BottomNav && <BottomNav />}
      </OrgGuard>
    </Flex>
  )
}

export default CreationFormsLayout
