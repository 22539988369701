import { connect } from "react-redux"
import { AccountSelectors } from "../../account"

import MinifiedNavComponent from "../components/MinifiedNav"
import { getBasketLength } from "../../../store/basket/basket.selectors"

const mapState = state => ({
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  organisationDetails: AccountSelectors.getOrganisation(state),
  isEBNMember: AccountSelectors.getIsEBNMember(state),
  headerLogo: AccountSelectors.getHeaderLogo(state),
  basketLength: getBasketLength(state)
})

const MinifiedNav = connect(mapState)(MinifiedNavComponent)

export default MinifiedNav
