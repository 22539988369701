import { emailEB } from "./globalConstants"

export const firstPartyCookies = {
  headings: ["Name", "Use for the cookie", "Type"],
  rows: [
    [
      "_ewsid",
      "Session Id for your user account when you are logged in",
      "Session"
    ],
    [
      "TRACKED_SURVEY_IDS",
      "Tracks engagement surveys completed by you while logged out, so that when you sign up, you can see your results.",
      "Persistent"
    ]
  ]
}

export const thirdPartyCookies = {
  headings: ["Name", "Use for the cookie", "Type", "More info"],
  rows: [
    [
      "Google",
      "Used for analytics and service improvement",
      "Persistent",
      "https://tools.google.com/dlpage/gaoptout"
    ],
    [
      "Facebook Pixel",
      "Used for analytics and service improvement",
      "Persistent",
      "https://en-gb.facebook.com/policies/cookies/"
    ],
    [
      "Stripe",
      "Used for payments and premium subscriptions",
      "Persistent",
      "https://stripe.com/cookies-policy/legal"
    ]
  ]
}

export const collectedInformation = [
  "information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views, website navigation);",
  "information that you provide to us for the purpose of registering with us (including name, age, date of birth, address, contact number, email, payment or credit card information, username and password);",
  "information that you provide to us for the purpose of subscribing to our website services, email notifications and/or newsletters; and",
  "any other information that you choose to send to us such as answering the Workplace Happiness questions."
]

export const contactEmail = emailEB
export const registeredAddress =
  "Mole End Shorts Green Lane, Motcombe, Shaftesbury, Dorset, England, SP7 9PA."

export const dataController = {
  name: "Stour Publishing Limited",
  registrationNumber: "07946689"
}
