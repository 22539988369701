import { createSelector } from "reselect"

export const allSurveyJobRolesState = state =>
  state && state["surveyManagement.templates.allSurveyJobRoles"]

export const getAllSurveyJobRoleOptions = createSelector(
  allSurveyJobRolesState,
  state => {
    return state.jobRoles.map(jobRole => ({
      id: jobRole.id,
      value: jobRole.name
    }))
  }
)
