import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import React from "react"

import EmployeeReportItem from "./EmployeeReportItem"

const EmployeeReportsList = ({
  reports,
  surveyType,
  surveyDetails,
  selectedReports,
  setSelectedReports,
  showCheckBox,
  checkBoxLabelText
}) => {
  const handleSelect = ({ reportId, surveyId }) => {
    const isSelected = selectedReports?.find(
      selected => selected.reportId === reportId
    )
    let newSelectedReports

    if (!isSelected) {
      newSelectedReports = [...selectedReports, { reportId, surveyId }]
    } else {
      newSelectedReports = selectedReports.filter(
        selected => selected.reportId !== reportId
      )
    }

    setSelectedReports(newSelectedReports)
  }

  return (
    <Flex width={1 / 1} flexDirection="column">
      {reports?.map(entry => (
        <EmployeeReportItem
          key={entry.reportId}
          reportData={entry}
          surveyDetails={surveyDetails}
          surveyType={surveyType}
          isChecked={selectedReports.find(
            selected => selected.reportId === entry.reportId
          )}
          showCheckBox={showCheckBox}
          checkBoxLabelText={checkBoxLabelText}
          onChange={() => {
            handleSelect({ reportId: entry.reportId, surveyId: entry.id })
          }}
        />
      ))}
    </Flex>
  )
}

export default EmployeeReportsList
