import { gql } from "@apollo/client"

const GET_SURVEY_LAYOUT = gql`
  query getSurveyLayout($id: ID!, $parent: String) {
    getSurveyLayout(id: $id, parent: $parent) {
      name
      child
      visible
    }
  }
`

export default GET_SURVEY_LAYOUT
