/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { useFormik } from "formik"
import { Flex } from "@engaging-tech/components"
import { object, string } from "yup"
import EditBodyFlex from "../../sharedStyling/EditBodyFlex"
import StyledTextField from "../../sharedStyling/StyledTextField"
import Option from "../PreviewBody/Option"
import TemplateQuestionContext from "../TemplateQuestionContext"
import DeleteButton from "./DeleteButton"
import useFreeze from "../../lib/useFreeze"
import AbsoluteErrorText from "../../sharedStyling/AbsoluteErrorText"

const Input = ({ value, onChange, i, deleteOption, setPageErrors }) => {
  const { isCore, id } = useContext(TemplateQuestionContext)

  const formik = useFormik({
    initialValues: {
      radio: useFreeze(value)
    },
    validationSchema: object({
      radio: string().required("Cannot be empty")
    }),
    validateOnMount: true
  })

  useEffect(() => {
    if (!isCore) {
      const handler = setTimeout(() => {
        onChange(formik.values.radio, i, id)
        setPageErrors(formik.errors?.radio, "radio", id)
      }, 500)

      return () => clearTimeout(handler)
    }
  }, [formik.values.radio, formik.errors])

  return (
    <Flex alignItems="center" position="relative">
      <Option isCore={isCore}>
        <StyledTextField
          id="radio"
          type="text"
          height="100%"
          value={formik.values.radio}
          onChange={formik.handleChange}
          placeholder="Short answer label (optional)"
          width="97%"
          noLabel
          disabled={isCore}
          focusedBackgroundColor="light.0"
          borderColor="#b5b5b5"
          padding="2px 5px"
          bg="light.0"
        />
      </Option>
      <AbsoluteErrorText right="47px">{formik.errors?.radio}</AbsoluteErrorText>
      {!isCore && <DeleteButton onClick={() => deleteOption(i, id)} />}
    </Flex>
  )
}

const RadioQuestionEdit = ({ deleteOption, onChange, setPageErrors }) => {
  const { values } = useContext(TemplateQuestionContext)

  return (
    <EditBodyFlex>
      {values?.map(({ value, id }, i) => (
        <Input
          key={id}
          value={value}
          i={i}
          deleteOption={deleteOption}
          onChange={onChange}
          setPageErrors={setPageErrors}
        />
      ))}
    </EditBodyFlex>
  )
}

export default RadioQuestionEdit
