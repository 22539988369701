export const types = {
  LOAD_CORE_QUESTIONS: "[templates.coreQuestions] Load Core Questions",
  LOAD_CORE_QUESTIONS_SUCCESS:
    "[templates.coreQuestions] Load Core Questions Success",
  LOAD_CORE_QUESTIONS_FAILURE:
    "[templates.coreQuestions] Load Core Questions Failure",
  RESET: "[templates.coreQuestions] Reset"
}

export const loadCoreQuestions = payload => ({
  type: types.LOAD_CORE_QUESTIONS,
  payload
})

export const loadCoreQuestionsSuccess = payload => ({
  type: types.LOAD_CORE_QUESTIONS_SUCCESS,
  payload
})

export const loadCoreQuestionsFailure = () => ({
  type: types.LOAD_CORE_QUESTIONS_FAILURE
})

export const reset = () => ({
  type: types.RESET
})
