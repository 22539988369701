/* eslint-disable no-nested-ternary */

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Spinner } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React, { cloneElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { OrgGuard } from "../../features/account"
import { getHasSliceAndDiceLicence } from "../../features/account/store/account.selectors"
import Warning from "../../features/awards/components/Warning"
import pages from "../../features/awards/lib/awardsPages"
import {
  GET_AWARDS_BY_TYPE,
  SAVE_AWARDS_SURVEY
} from "../../features/awards/services/awards.queries"
import {
  getAwardsByTypeFail,
  getAwardsByTypeSuccess,
  saveAwardsSurveyFail,
  saveAwardsSurveySuccess,
  setAwardsType
} from "../../features/awards/store/awards.actions"
import { getCurrentPage } from "../../features/awards/store/awards.selectors"
import ErrorState from "../../features/business-information/components/error-state"
import Intro from "../../features/selfServiceSurveys/components/Intro"
import useBusinessAppMutation from "../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../hooks/useBusinessAppQuery"
import PageLayout from "../../layouts/PageLayout"
import { awardInBasket } from "../../store/basket/basket.selectors"
import addParams from "../../utils/addParams"
import AwardsBanner from "./AwardsBanner"

export const PaddedBox = styled(Box)`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1150px) {
    padding: 0px 24px;
  }
`

const EnterAwards = ({ match }) => {
  const awardsType = match.params.awardType
  const dispatch = useDispatch()
  dispatch(setAwardsType(awardsType))

  const paths = usePaths()
  const history = useHistory()

  const foundAward = useSelector(awardInBasket)
  const currentPage = useSelector(getCurrentPage)
  const sliceAndDiceLicence = useSelector(getHasSliceAndDiceLicence)

  const [displayWarning, setDisplayWarning] = useState(false)

  const {
    isLoading: loading,
    errors: isErrors,
    data
  } = useBusinessAppQuery(GET_AWARDS_BY_TYPE, {
    successAction: getAwardsByTypeSuccess,
    failAction: getAwardsByTypeFail,
    variables: { type: awardsType }
  })

  const { post, isLoading, errors } = useBusinessAppMutation(
    SAVE_AWARDS_SURVEY,
    {
      successAction: saveAwardsSurveySuccess,
      failAction: saveAwardsSurveyFail
    }
  )

  const saveAwardsSurvey = async input => {
    await post({
      type: awardsType,
      input
    })
  }

  useEffect(() => {
    post({ type: awardsType })
    addParams(sliceAndDiceLicence, "slice-and-dice", "inactive")
    if (foundAward && foundAward.object_type !== awardsType) {
      setDisplayWarning(true)
    }
  }, [])

  const resetToFirstPage = () => {
    post({
      type: awardsType,
      input: {
        page: 1
      }
    })
  }

  useEffect(() => {
    if (!foundAward && currentPage > 1) {
      resetToFirstPage()
    }
  }, [foundAward, currentPage])

  const forbiddenError = isErrors?.networkError?.result.errors.filter(
    error => error.extensions.code === "ERR403"
  )[0]

  if (forbiddenError)
    history.replace(
      `${paths.awards.enterAwards.entryForbidden.replace(
        ":awardType",
        awardsType
      )}?email=${forbiddenError.extensions.email}`
    )

  return (
    <OrgGuard>
      <PageLayout
        pt={0}
        banner={() => (
          <AwardsBanner
            title={data?.getAwardsByType[0]?.title}
            logo={data?.getAwardsByType[0]?.logo}
            description={data?.getAwardsByType[0]?.description}
          />
        )}
      >
        {isLoading || loading ? (
          <Spinner justifyContent="center" color="primary.0" />
        ) : errors || isErrors ? (
          <ErrorState />
        ) : (
          <PaddedBox>
            <Intro
              totalPages={Object.keys(pages).length}
              currentPage={currentPage}
              intro={pages[currentPage]?.intro}
              isAwards={true}
            />
            {currentPage &&
              cloneElement(pages[currentPage]?.component, {
                saveSurvey: saveAwardsSurvey
              })}
          </PaddedBox>
        )}
        {displayWarning && (
          <Warning
            setDisplayWarning={setDisplayWarning}
            basketProductId={foundAward?.productId}
            basketTitle={foundAward?.productName}
          />
        )}
      </PageLayout>
    </OrgGuard>
  )
}

export default EnterAwards
