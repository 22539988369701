/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect"

const getCMSState = state => state?.cms

export const getIsLoading = createSelector(getCMSState, cms => cms?.isLoading)

export const getHasError = createSelector(getCMSState, cms => cms?.hasError)

export const getBanner = createSelector(getCMSState, cms => cms?.banner)
