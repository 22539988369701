/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { SAVE_BASKET } from "../../../graphql/mutations"
import { FETCH_PRODUCTS } from "../../../graphql/queries"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import {
  saveBasketFail,
  saveBasketReq,
  saveBasketSuccess,
  setAddedToBasket
} from "../../../store/basket/basket.actions"
import { getBasketItems } from "../../../store/basket/basket.selectors"
import ErrorState from "../../business-information/components/error-state"
import ChooseSurveySize from "../../selfServiceSurveys/components/Page1/ChooseSurveySize"

const DisplayProduct = ({
  hideMessage,
  actionButtonText = "",
  openBasketWhenAddProduct
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null)
  const dispatch = useDispatch()
  const router = useRouter()
  const paths = usePaths()
  const history = useHistory()
  const basket = useSelector(getBasketItems)
  const entitlement =
    new URLSearchParams(window.location.search).get("type") ||
    new URLSearchParams(window.location.search).get("inactive")
  const buttonText = actionButtonText
    ? actionButtonText
    : selectedProduct && basketItem
    ? "Go to Basket"
    : "Add to basket"

  const { data, errors, isLoading } = useBusinessAppQuery(FETCH_PRODUCTS, {
    variables: { object_type: entitlement }
  })

  const { errors: basketErrors, post } = useBusinessAppMutation(SAVE_BASKET, {
    initAction: saveBasketReq,
    successAction: saveBasketSuccess,
    failAction: saveBasketFail
  })

  const findItem = () => {
    const priceIds = data?.fetchProducts?.prices.map(price => price.id)
    const foundItem = basket.filter(item => priceIds?.includes(item.priceId))[0]
    return foundItem
  }

  useEffect(() => {
    const item = findItem()
    if (item)
      setSelectedProduct({
        productId: item.productId,
        priceId: item.priceId,
        object_type: item.object_type
      })
  }, [data, basket])

  const handleClick = () => {
    post(
      {
        productId: selectedProduct.productId,
        priceId: selectedProduct.priceId,
        object_type: selectedProduct.object_type
      },
      () => {
        dispatch(setAddedToBasket(selectedProduct.name))
        if (openBasketWhenAddProduct) {
          router.navigate(
            `${paths.yourBasket.index}?from=${history.location.pathname}`
          )
        }
      }
    )
  }

  const basketItem = selectedProduct?.priceId === findItem()?.priceId

  if (basketErrors) return <ErrorState />

  return (
    <Flex width={1 / 1} alignItems="center" flexDirection="column" p={2}>
      {!hideMessage && (
        <Text width="fit-content" mb={3} textAlign="center">
          {`In order to use ${data?.fetchProducts?.prices[0].product_details.name}, you need to purchase the annual licence first:`}
        </Text>
      )}
      <Flex width="fit-content">
        <ChooseSurveySize
          setSelectedProduct={setSelectedProduct}
          products={data?.fetchProducts?.prices}
          selectedPriceId={selectedProduct?.priceId}
          isLoading={isLoading}
          error={errors}
          objectType={data?.fetchProducts.object_type}
          showProductName={true}
        />
      </Flex>
      <Button
        mt={5}
        bg={selectedProduct ? "primary.0" : "dark.2"}
        color="light.0"
        width="fit-content"
        disabled={!selectedProduct}
        fontSize={3}
        height="30px"
        onClick={
          basketItem
            ? () =>
                router.navigate(
                  `${paths.yourBasket.index}?from=${history.location.pathname}`
                )
            : () => handleClick()
        }
      >
        {buttonText}
      </Button>
    </Flex>
  )
}

export default DisplayProduct
