import React, { useState, useEffect, useMemo } from "react"
import {
  Flex,
  MotionFlex,
  Icon,
  Text,
  CustomHooks
} from "@engaging-tech/components"
import styled from "styled-components"

import SurveyAnimatedIllustration from "../stepTwo/SurveyAnimatedIllustration"

const StyledMotionFlex = styled(MotionFlex)`
  align-content: baseline;
`

const Credits = ({
  sequenceStart,
  currentSequenceNumber,
  size,
  totalParticipants,
  containerWidth,
  device,
  ...props
}) => {
  return (
    <MotionFlex
      width="auto"
      height="auto"
      initial="unActive"
      animate={currentSequenceNumber >= sequenceStart ? "active" : "unActive"}
      variants={{
        unActive: {
          opacity: 0,
          x: 0,
          transition: { opacity: { duration: 0 } }
        },
        active: {
          opacity: 1,
          scale: 0,
          x: [0, containerWidth * (device === "MOBILE" ? 0.5 : 0.64)],
          transition: {
            x: { delay: 1.4 },
            scale: { delay: 1.6, duration: 0.5 }
          }
        }
      }}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Icon name="fiber_smart_record" size={size * 1.3} mr={2} />
      <Text fontSize={size} fontWeight={700}>
        {totalParticipants} Credits
      </Text>
    </MotionFlex>
  )
}

const generateParticipants = ({
  startAnimation,
  iconSize,
  setTotal,
  totalParticipants
}) => {
  const symbolOutput = []
  // eslint-disable-next-line no-plusplus
  for (let step = 0; step <= totalParticipants; step++) {
    symbolOutput.push(
      <MotionFlex
        width="auto"
        height="auto"
        key={step}
        initial={{ scale: 0 }}
        animate={
          startAnimation
            ? {
                scale: 1,
                transition: { delay: 0.7 + (0.03 + step * 0.002) * step }
              }
            : { scale: 0 }
        }
        onAnimationComplete={() => setTotal(step)}
      >
        <Icon name="person" size={iconSize} />
      </MotionFlex>
    )
  }
  return symbolOutput
}

const ParticipantsGroup = ({
  sequenceStart,
  currentSequenceNumber,
  totalParticipants = 30,
  iconSize,
  ...props
}) => {
  const [total, setTotal] = useState(0)
  const startAnimation = currentSequenceNumber >= sequenceStart
  useEffect(() => {
    if (startAnimation) {
      setTotal(0)
    }
  }, [startAnimation])

  const memoizedValue = useMemo(
    () =>
      generateParticipants({
        startAnimation,
        iconSize,
        setTotal,
        totalParticipants
      }),
    [startAnimation, iconSize]
  )
  return (
    <StyledMotionFlex
      alignItems="center"
      flexWrap="wrap"
      initial={{ opacity: 0 }}
      animate={startAnimation ? { opacity: 1 } : { opacity: 0 }}
      {...props}
    >
      <Text
        fontSize={[4, 5, 5]}
        fontWeight={700}
        width="100%"
      >{`Total Participants: ${total}`}</Text>
      {memoizedValue}
    </StyledMotionFlex>
  )
}

const ApproveTick = ({
  sequenceStart,
  currentSequenceNumber,
  size,
  ...props
}) => {
  return (
    <MotionFlex
      width={size}
      height={size}
      borderRadius={size / 2}
      bg="light.0"
      initial="unActive"
      animate={currentSequenceNumber >= sequenceStart ? "active" : "unActive"}
      variants={{
        unActive: {
          scale: 0
        },
        active: {
          scale: 1
        }
      }}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Icon name="check_circle" color="green" size={size + 13} />
    </MotionFlex>
  )
}

let localSequenceCount = 1

const CreditsAnimatedIllustration = ({
  width = 220,
  height = 320,
  cycle = true,
  speed = 900,
  noAnimation = false,
  forceSequenceCount = null,
  ...props
}) => {
  const device = CustomHooks.useClientDevice()
  const [sequenceNumber, setSequenceNumber] = useState(
    (() => {
      if (forceSequenceCount) {
        return forceSequenceCount
      }
      return noAnimation ? 10 : localSequenceCount
    })()
  )

  const updateSequence = () => {
    if (localSequenceCount === 10) {
      if (cycle) {
        setSequenceNumber(0)
        localSequenceCount = 0
      }
    } else {
      setSequenceNumber(localSequenceCount + 1)
      localSequenceCount += 1
    }
  }

  useEffect(() => {
    let isCancelled = false
    let interval = null
    const runAsync = async () => {
      if (!isCancelled && !noAnimation) {
        interval = setInterval(updateSequence, speed)
      }
    }

    runAsync()

    return () => {
      isCancelled = true
      if (interval) {
        clearInterval(interval)
      }
      localSequenceCount = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const surveyHeight = height - 30
  const approveTickSize = device === "MOBILE" ? 30 : 46
  const totalParticipants = device === "MOBILE" ? 8 : 30
  return (
    <MotionFlex
      width={width}
      height={height}
      key={1}
      alignItems="flex-start"
      position="relative"
      flexDirection="column"
      {...props}
    >
      <ParticipantsGroup
        width={
          width - (surveyHeight / 100) * 70 - (device === "MOBILE" ? 105 : 50)
        }
        height={surveyHeight}
        iconSize={30}
        sequenceStart={3}
        totalParticipants={totalParticipants}
        currentSequenceNumber={sequenceNumber}
        style={{ position: "absolute", top: 20, left: 0 }}
      />
      <Credits
        size={device === "MOBILE" ? 16 : 20}
        sequenceStart={7}
        totalParticipants={totalParticipants}
        currentSequenceNumber={sequenceNumber}
        containerWidth={width}
        device={device}
        style={{ position: "absolute", bottom: 20, left: 0 }}
      />
      <MotionFlex
        initial="unActive"
        animate={sequenceNumber >= 1 ? "active" : "unActive"}
        exit="exit"
        variants={{
          unActive: {
            x: 50,
            opacity: 0,
            transition: { delay: 0.2 }
          },
          active: {
            x: 0,
            opacity: 1
          }
        }}
        style={{
          position: "absolute",
          top: approveTickSize / 2,
          right: approveTickSize / 2
        }}
        width="auto"
        height="auto"
      >
        <SurveyAnimatedIllustration
          noAnimation
          forceSequenceCount={9}
          width={(surveyHeight / 100) * 69}
          height={surveyHeight}
        />
      </MotionFlex>
      <ApproveTick
        position="absolute"
        top={0}
        right={0}
        size={approveTickSize}
        sequenceStart={2}
        currentSequenceNumber={sequenceNumber}
      />
    </MotionFlex>
  )
}

export default CreditsAnimatedIllustration
