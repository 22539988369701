import React, { useState, useEffect, useCallback } from "react"
import { Modal, Spinner, Button, Text, Flex } from "@engaging-tech/components"

import { createPortal } from "react-dom"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const loadingCycle = [
  "Gathering Data",
  "Calculating Graphs",
  "Importing Responses",
  "Processing Answers",
  "Translating Scores",
  "Inputting Response Rate"
]

const CycleTextLoading = () => {
  const [currentText, setCurrentText] = useState(loadingCycle[0])

  const nextCycle = useCallback(() => {
    const currentIndex = loadingCycle.findIndex(el => el === currentText)
    const nextIndex =
      currentIndex + 1 > loadingCycle.length - 1 ? 0 : currentIndex + 1
    setCurrentText(loadingCycle[nextIndex])
  }, [currentText])

  useEffect(() => {
    let isCancelled = false
    setTimeout(() => {
      if (!isCancelled) nextCycle()
    }, 3000)
    return () => {
      isCancelled = true
    }
  }, [currentText, nextCycle])

  return (
    <Flex justifyContent="center" alignText="center" position="relative">
      <Flex width="auto" position="absolute">
        <Text fontSize={20}>{currentText}</Text>
      </Flex>
    </Flex>
  )
}

const CancelPDFModal = ({ updatePdfDownloading, pdfProgress }) => {
  const [canceledPressed, setCanceledPressed] = useState(false)

  return createPortal(
    <Modal
      bg="light.0"
      minWidth={900}
      minHeight={700}
      animated
      disableScrollbar
      p={3}
      id="cancel-pdf-modal"
      overlayProps={{ id: "cancel-pdf-modal-overlay" }}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text
        fontSize={7}
        mb={6}
        fontWeight={700}
        width="auto"
        textAlign="center"
      >
        Generating PDF
      </Text>
      <Flex
        position="relative"
        justifyContent="center"
        alignItems="center"
        width="10vw"
        height="10vw"
        minWidth={200}
        minHeight={200}
      >
        <Spinner color="primary.0" position="absolute" size={150} />
        <Text
          fontSize={7}
          fontWeight={700}
          width="auto"
          textAlign="center"
          position="relative"
        >
          {pdfProgress}%
        </Text>
      </Flex>
      <CycleTextLoading />
      <Button
        bg="primary.0"
        onClick={() => {
          setCanceledPressed(true)
          updatePdfDownloading(false)
        }}
        fontSize={4}
        px={4}
        fontWeight={500}
        mt={6}
        mb={3}
        style={{ cursor: "pointer" }}
      >
        {canceledPressed ? "Canceling" : "Cancel"}
      </Button>
    </Modal>,
    appRoot()
  )
}

export default CancelPDFModal
