import React from "react"

const NetPromoterScoreLogo = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z"
        fill="#E6F0EA"
      />
      <path
        d="M22.3479 9.53203L21.1179 11.382C21.7111 12.5651 22.0015 13.8769 21.9631 15.1998C21.9247 16.5227 21.5587 17.8154 20.8979 18.962H7.03795C6.17911 17.4721 5.82324 15.7452 6.02309 14.0371C6.22293 12.3291 6.96786 10.7309 8.14742 9.47946C9.32699 8.22806 10.8784 7.39008 12.5717 7.08973C14.265 6.78939 16.0099 7.04267 17.5479 7.81204L19.3979 6.58204C17.5144 5.37425 15.2803 4.83304 13.0528 5.04497C10.8254 5.2569 8.73339 6.20973 7.11144 7.75105C5.48949 9.29236 4.43131 11.3331 4.10616 13.5469C3.78102 15.7606 4.2077 18.0194 5.31795 19.962C5.49246 20.2643 5.74303 20.5157 6.04478 20.6911C6.34652 20.8665 6.68892 20.9599 7.03795 20.962H20.8879C21.2404 20.9634 21.5869 20.8717 21.8925 20.6961C22.198 20.5205 22.4517 20.2672 22.6279 19.962C23.5493 18.366 24.0117 16.5463 23.9641 14.704C23.9164 12.8617 23.3606 11.0684 22.3579 9.52204L22.3479 9.53203Z"
        fill="#006D2F"
      />
      <path
        d="M12.5578 16.3718C12.7435 16.5578 12.9641 16.7053 13.2069 16.806C13.4497 16.9066 13.7099 16.9584 13.9728 16.9584C14.2356 16.9584 14.4958 16.9066 14.7386 16.806C14.9814 16.7053 15.202 16.5578 15.3878 16.3718L21.0478 7.88184L12.5578 13.5418C12.3718 13.7276 12.2243 13.9482 12.1236 14.191C12.023 14.4338 11.9712 14.694 11.9712 14.9568C11.9712 15.2197 12.023 15.4799 12.1236 15.7227C12.2243 15.9655 12.3718 16.1861 12.5578 16.3718Z"
        fill="#006D2F"
      />
    </svg>
  )
}
export default NetPromoterScoreLogo
