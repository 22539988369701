import React from "react"
import { Flex, Text } from "@engaging-tech/components"

const colors = ["#0DC187", "#FFB547", "#EF464A"]

const BreakdownScore = ({ title, score, index }) => {
  return (
    <Flex
      borderRadius={5}
      width="100%"
      mt={index === 0 ? 0 : 2}
      p={2}
      flexDirection="column"
      bg={colors[index]}
    >
      <Text fontWeight={400} mb={2} fontSize={3}>
        {title}
      </Text>
      <Text fontWeight={700} fontSize={5}>
        {score}
      </Text>
    </Flex>
  )
}

const ThreeWayBreakdown = ({
  promotersPercentage,
  passivesPercentage,
  detractorsPercentage,
  ...props
}) => {
  const scores = [
    { title: "Promoters", score: promotersPercentage },
    { title: "Passives", score: passivesPercentage },
    { title: "Detractors", score: detractorsPercentage }
  ]
  return (
    <Flex
      height="auto"
      width="auto"
      color="dark.3"
      bg="dark.4"
      p={props.p ?? 3}
      flexDirection="column"
      borderRadius={10}
      justifyContent="space-around"
      {...props}
    >
      {scores.map(({ title, score }, index) => (
        <BreakdownScore title={title} score={score} key={title} index={index} />
      ))}
    </Flex>
  )
}

export default ThreeWayBreakdown
