import { connect } from "react-redux"
import NewSurveyReportCreationViewComponent from "../views/NewSurveyReportCreationView"
import { loadReports } from "../store/report.actions"
import {
  getFilteredReports,
  getHasError,
  getIsAllReportsLoading,
  getSurveyType
} from "../store/report.selectors"

const mapState = state => ({
  reports: getFilteredReports(state),
  isLoading: getIsAllReportsLoading(state),
  hasError: getHasError(state),
  surveyType: getSurveyType(state)
})

const mapDispatch = dispatch => ({
  onSubmit: reportSearchFilters => dispatch(loadReports(reportSearchFilters))
})

const NewSurveyReportCreationView = connect(
  mapState,
  mapDispatch
)(NewSurveyReportCreationViewComponent)

export default NewSurveyReportCreationView
