import React from "react"
import { useParams } from "@engaging-tech/routing"

import Article from "../containers/Content/Article"
import Lecture from "../containers/Content/Lecture"
import Research from "../containers/Content/Research"
import Webinar from "../containers/Content/Webinar"

const ContentView = () => {
  const { contentType, id } = useParams()
  switch (contentType) {
    case "articles":
      return <Article id={id} />

    case "webinars":
      return <Webinar id={id} />

    case "research":
      return <Research id={id} />

    case "lectures":
      return <Lecture id={id} />

    default:
      return null
  }
}

export default ContentView
