import { createSelector } from "reselect"
import questionTypeOptions from "../../lib/questionTypeOptions"
import {
  getCoreQuestionIds,
  getTemplateQuestionValues
} from "../activeTemplate/activeTemplate.selectors"

const coreQuestionState = state =>
  state && state["surveyManagement.templates.coreQuestions"]

const mapCoreQuestionOptions = ({
  coreQuestions,
  type,
  coreQuestionIdBlackList = []
}) => {
  let output = []

  const getTypeIcon = coreQuestion => {
    const foundOption = questionTypeOptions.find(
      option => option.id === coreQuestion.type
    )
    if (foundOption) {
      return foundOption.icon
    }
    return questionTypeOptions.find(option => option.id === "other").icon
  }

  if (!type) {
    output.push(coreQuestions.happinessQuestions)
    output.push(coreQuestions.additionalQuestions)
  } else if (type === "other") {
    const mainOptionTypes = questionTypeOptions.reduce(
      (returnedArray, option) => {
        const localReturnedArray = returnedArray
        if (option.id !== "other") {
          localReturnedArray.push(option.id)
        }
        return localReturnedArray
      },
      []
    )

    const filteredHappinessQuestions = coreQuestions.happinessQuestions.filter(
      coreQuestion => !mainOptionTypes.find(t => t === coreQuestion.type)
    )
    if (filteredHappinessQuestions.length !== 0) {
      output.push(filteredHappinessQuestions)
    }

    const filteredAdditionalQuestions = coreQuestions.additionalQuestions.filter(
      coreQuestion => !mainOptionTypes.find(t => t === coreQuestion.type)
    )

    if (filteredAdditionalQuestions.length !== 0) {
      output.push(filteredAdditionalQuestions)
    }
  } else {
    const filteredHappinessQuestions = coreQuestions.happinessQuestions.filter(
      coreQuestion => coreQuestion.type === type
    )
    if (filteredHappinessQuestions.length !== 0) {
      output.push(filteredHappinessQuestions)
    }
    const filteredAdditionalQuestions = coreQuestions.additionalQuestions.filter(
      coreQuestion => coreQuestion.type === type
    )

    if (filteredAdditionalQuestions.length !== 0) {
      output.push(filteredAdditionalQuestions)
    }
  }
  output = output.flat()
  if (coreQuestionIdBlackList.length > 0) {
    output = output.filter(
      coreQuestion => !coreQuestionIdBlackList.includes(coreQuestion.id)
    )
  }

  return output.map(coreQuestion => ({
    id: coreQuestion.id,
    value: coreQuestion.title,
    icon: getTypeIcon(coreQuestion)
  }))
}

export const getIsLoading = createSelector(
  coreQuestionState,
  state => state?.isLoading
)

export const getHasError = createSelector(
  coreQuestionState,
  state => state?.hasError
)

// Gets all core questions
export const getAllCoreQuestions = createSelector(
  coreQuestionState,
  state => state && [...state.additionalQuestions, ...state.happinessQuestions]
)

// Gets a specific core question from ID
export const getCoreQuestionById = createSelector(
  getAllCoreQuestions,
  (_, { id }) => id,
  (allCoreQuestions, id) =>
    allCoreQuestions.find(coreQuestion => coreQuestion.id === id)
)

// Gets all core questions in dropdown ready format
export const getCoreQuestionOptions = createSelector(
  coreQuestionState,
  coreQuestions => {
    if (
      coreQuestions?.happinessQuestions.length &&
      coreQuestions?.additionalQuestions.length
    ) {
      return mapCoreQuestionOptions({ coreQuestions })
    }
    return []
  }
)

// Gets all core questions values
export const getCoreQuestionValues = createSelector(
  getCoreQuestionOptions,
  state => {
    return state.map(coreQuestion => coreQuestion.value)
  }
)

// Returns core question options with used options removed. If sectionIndex and questionIndex are not passed in, the current question coreQuestionId will be included in the blacklist.
export const getFilteredCoreQuestionOptions = createSelector(
  state => state,
  (_, { sectionIndex, questionIndex }) => ({
    sectionIndex,
    questionIndex
  }),
  (state, { sectionIndex, questionIndex }) => {
    const coreQuestions = coreQuestionState(state)
    let coreQuestionIdBlackList = getCoreQuestionIds(state)

    let currentQuestion = { coreQuestionId: null }
    if (typeof questionIndex === "number" && typeof sectionIndex === "number") {
      currentQuestion = getTemplateQuestionValues(state, {
        questionIndex,
        sectionIndex
      })
    }

    // Removes current question core question id from blacklist if a core question
    if (currentQuestion.coreQuestion) {
      coreQuestionIdBlackList = coreQuestionIdBlackList.filter(
        id => id !== currentQuestion.coreQuestionId
      )
    }

    if (state && state.length !== 0) {
      return mapCoreQuestionOptions({
        coreQuestions,
        coreQuestionIdBlackList
      })
    }
    return []
  }
)
