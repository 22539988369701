import React from "react"
import { Text, Flex, Icon, Button } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

export const IconWrapper = ({ iconConfig }) => {
  return (
    <Flex
      bg={iconConfig.backgroundColor}
      borderRadius={99}
      justifyContent="center"
      alignItems="center"
      width="auto"
      height="auto"
      p="10px"
    >
      <Icon name={iconConfig.name} color={iconConfig.color} size={30} />
    </Flex>
  )
}

const buttonMargin = { marginRight: "12px", marginTop: "7px" }
const FeatureBox = ({
  name,
  iconConfig,
  lockedButtonConfig,
  description,
  primaryButtonConfig,
  secondaryButtonConfig,
  tertiaryButtonConfig,
  unLocked,
  bgColor
}) => {
  return (
    <Flex
      elevation={3}
      flexDirection="column"
      p={[3, 4]}
      m={[2, 3]}
      bg={bgColor || "#FFFFFF"}
      borderRadius={10}
      width={["90%", "370px"]}
      height={["auto", "260px"]}
      justifyContent="space-between"
    >
      <Flex flexDirection="row" alignItems="center" width="100%" mb={3}>
        <IconWrapper iconConfig={iconConfig} />
        <Text ml={3} fontSize={5} fontWeight={700} width="auto">
          {name}
        </Text>
      </Flex>
      <Text width="100%" mb={1}>
        {description}
      </Text>
      <Flex flexDirection={["column", "row"]} width="100%">
        {unLocked && primaryButtonConfig && (
          <Link to={primaryButtonConfig.linkTo} style={buttonMargin}>
            <Button variant="primary.0" elevation={0}>
              <Text color="light.0" style={{ textTransform: "none" }}>
                {primaryButtonConfig.text}
              </Text>
            </Button>
          </Link>
        )}
        {unLocked && secondaryButtonConfig && !secondaryButtonConfig?.hidden && (
          <Link to={secondaryButtonConfig.linkTo} style={buttonMargin}>
            <Button variant="outline.secondary.0" elevation={0}>
              <Text style={{ textTransform: "none" }} color="secondary.0">
                {secondaryButtonConfig.text}
              </Text>
            </Button>
          </Link>
        )}
        {unLocked && tertiaryButtonConfig && (
          <Link to={tertiaryButtonConfig.linkTo} style={buttonMargin}>
            <Button variant="outline.secondary.0" elevation={0}>
              <Text style={{ textTransform: "none" }} color="secondary.0">
                {tertiaryButtonConfig.text}
              </Text>
            </Button>
          </Link>
        )}
        {!unLocked && lockedButtonConfig && (
          <Link
            to={lockedButtonConfig.linkTo}
            style={buttonMargin}
            external={lockedButtonConfig?.external}
          >
            <Button
              variant="secondary.0"
              leadingIcon="lock"
              color="light.0"
              elevation={0}
            >
              <Text style={{ textTransform: "none" }} color="light.0">
                {lockedButtonConfig.text}
              </Text>
            </Button>
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export default FeatureBox
