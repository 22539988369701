import { Card, Text, Button } from "@engaging-tech/components"
import { useRouter, usePaths } from "@engaging-tech/routing"
import React from "react"
import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"

const NoMobileDevice = () => {
  const router = useRouter()
  const paths = usePaths()

  return (
    <CreationFormsLayout bg="#EEF4F9" maxWidth={1064} mx={20} my={5}>
      <Card bg="light.0" alignItems="center" p={2}>
        <Text width="fit-content" textAlign="center">
          Unfortunately this feature is not availble on a mobile device. Please
          try again with a different device.
        </Text>
        <Button
          mt={2}
          bg="secondary.0"
          width="100px"
          color="light.0"
          onClick={() => router.navigate(paths.surveys.buildATemplate.index)}
        >
          Back
        </Button>
      </Card>
    </CreationFormsLayout>
  )
}

export default NoMobileDevice
