import { call, put, select, takeLatest } from "redux-saga/effects"
import * as AccountActions from "../../account/store/account.actions"
import features from "../lib/siteFeatures"
import { loadAllCountries, loadAllIndustries } from "../services/ui.services"
import * as Actions from "./ui.actions"
import * as Selectors from "./ui.selectors"

export function* markLoadedSaga() {
  yield put(Actions.markLoadedFeature(features.account))
}

export function* markNotLoadedSaga() {
  yield put(Actions.markNotLoadedFeature(features.account))
}

export function* loadAllCountriesSaga() {
  try {
    const countries = yield select(Selectors.getAllCountries)
    if (countries.length) {
      yield put(Actions.loadAllCountriesSuccess(countries))
    } else {
      const res = yield call(loadAllCountries)
      yield put(Actions.loadAllCountriesSuccess(res.data.allCountries))
    }
  } catch {
    yield put(Actions.loadAllCountriesFailure())
  }
}

export function* loadAllIndustriesSaga() {
  try {
    const industries = yield select(Selectors.getAllIndustries)
    if (industries.length) {
      yield put(Actions.loadAllIndustriesSuccess(industries))
    } else {
      const res = yield call(loadAllIndustries)
      yield put(Actions.loadAllIndustriesSuccess(res.data.allIndustries))
    }
  } catch {
    yield put(Actions.loadAllIndustriesFailure())
  }
}

export function* saga() {
  yield takeLatest(
    [
      AccountActions.types.TOKEN_SIGN_IN_FAIL,
      AccountActions.types.LOAD_SUCCESS,
      AccountActions.types.SIGN_OUT_SUCCESS,
      AccountActions.types.SIGN_IN_FAIL
    ],
    markLoadedSaga
  )
  yield takeLatest([AccountActions.types.TOKEN_SIGN_IN], markNotLoadedSaga)
  yield takeLatest(Actions.types.LOAD_ALL_COUNTRIES, loadAllCountriesSaga)
  yield takeLatest(Actions.types.LOAD_ALL_INDUSTRIES, loadAllIndustriesSaga)
}
