export const types = {
  GET_SURVEYS_LIST_REQ: "[SDUpsell] Get surveys list",
  GET_SURVEYS_LIST_SUCCESS: "[SDUpsell] Get surveys list Success",
  GET_SURVEYS_LIST_FAIL: "[SDUpsell] Get surveys list Fail"
}

export const getSurveysListReq = payload => ({
  type: types.GET_SURVEYS_LIST_REQ,
  payload
})

export const getSurveysListSuccess = payload => ({
  type: types.GET_SURVEYS_LIST_SUCCESS,
  payload
})

export const getSurveysListFail = payload => ({
  type: types.GET_SURVEYS_LIST_FAIL,
  payload
})
