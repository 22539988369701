export const jobMatcherTableConfig = {
  stylesPerColumn: [
    {
      fontSize: 3,
      fontWeight: 500
    },
    { fontSize: 3 },
    { fontSize: 3 },
    { fontSize: 3 },
    { fontSize: 3 },
    { fontSize: 3 }
  ],
  columnWidths: [12 / 100, 12 / 100, 30 / 100, 12 / 100, 12 / 100, 18 / 100],
  main: {
    borderRadius: "8px"
  },
  heading: {
    fontSize: [1, 3, 3],
    fontWeight: 500,
    color: "dark.2",
    py: 3
  },
  head: {
    bg: "dark.6"
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "dark.4"
  }
}

export const jobMatcherTableHeadings = [
  {
    id: "name",
    title: "Name"
  },
  {
    id: "currentRole",
    title: "Current role"
  },
  {
    id: "desiredRoles",
    title: "Desired roles"
  },
  {
    id: "salary",
    title: "Salary expectation"
  },
  {
    id: "skills",
    title: "Skill/Experience Match"
  },
  {
    id: "contact",
    title: "Contact details"
  }
]

export const liveJobsTableConfig = {
  stylesPerColumn: [
    {
      fontSize: 3,
      fontWeight: 500
    },
    { fontSize: 3 },
    { fontSize: 3 },
    { fontSize: 3 },
    { fontSize: 3 },
    { fontSize: 3 }
  ],
  main: {
    borderRadius: "8px"
  },
  heading: {
    fontSize: [1, 3, 3],
    fontWeight: 500,
    color: "dark.2",
    py: 3
  },
  head: {
    bg: "dark.4"
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "dark.4"
  }
}

export const liveJobsTableHeadings = [
  {
    id: "jobTitle",
    title: "Job Title"
  },
  {
    id: "location",
    title: "Location"
  },
  {
    id: "jobsBoard",
    title: "Jobs Marketplace"
  },
  {
    id: "jobMatching",
    title: "Job Matching"
  },
  {
    id: "matchingStatus",
    title: "Matching Status"
  }
]
