import { Flex, Text } from "@engaging-tech/components"
import { getCountries, getCountryById } from "@engaging-tech/frontend-utils"
import { useFormikContext } from "formik"
import React from "react"
import styled from "styled-components"

import ClickableIcon from "../../../../ui/components/ClickableIcon"
import { SelectInput } from "../../../components/custom"
import LocationInput from "../../../components/location-input"
import SaveAndCancel from "../../../components/save-and-cancel"
import SectionCard from "../../../components/section-card"

const ValueBadge = styled(Flex)`
  background: ${({ theme }) => theme.colors.dark[4]};
  font-size: 14px;
  border-radius: 5px;
  min-width: ${({ minWidth }) => minWidth || 192}px;
  width: auto;
  padding: 2px 8px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin: 4px;
`

export default function BusinessLocationSection({ isLoading }) {
  const { errors, values, setFieldValue } = useFormikContext()
  const [newCountryId, setNewCountryId] = React.useState("")
  const [newCity, setNewCity] = React.useState("")
  const [inputValue, setInputValue] = React.useState("")

  const { locations } = values

  const handleSave = () => {
    setFieldValue("locations", [
      ...locations,
      {
        id: `location-${Date.now()}`,
        countryId: newCountryId,
        city: newCity
      }
    ])
    setNewCountryId("")
    setNewCity("")
    setInputValue("")
  }

  const deleteLocation = location => {
    setFieldValue(
      "locations",
      values.locations.filter(loc => loc.id !== location.id)
    )
  }

  return (
    <SectionCard title="Locations">
      <Text color="dark.2" fontSize={3} mb={3}>
        Add between 1-8 business locations.
      </Text>
      <Flex flexDirection={["column", "row"]}>
        <SelectInput
          id="countryId"
          name="countryId"
          type="text"
          label="Country"
          mr={[0, 3]}
          mb={[3, 0]}
          width={[1, 0.5]}
          disabled={isLoading}
          value={newCountryId}
          onChange={e => setNewCountryId(e.target.value)}
        >
          <option disabled value="" />
          {getCountries().map(count => (
            <option key={count.id} value={count.id}>
              {count.name}
            </option>
          ))}
        </SelectInput>
        <LocationInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          selectedLocationCallback={value => {
            setNewCity(value?.vicinity ?? "")
          }}
        />
      </Flex>
      <SaveAndCancel
        handleSave={handleSave}
        cancelDisable={!(values.countryId || values.city) || isLoading}
        saveDisable={
          !newCountryId || !newCity || (values.locations && values.locations.length > 7) || isLoading
        }
      />
      <Flex flexDirection="row" flexWrap="wrap">
        {locations?.map((location, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ValueBadge key={index}>
              <Text color="dark.2" width="auto" fontSize={3}>
                {`${location.city}, ${
                  location.country ? location.country.name : getCountryById(location.countryId).name
                }`}
              </Text>
              <ClickableIcon color="primary.0" name="close" onClick={() => deleteLocation(location)} />
            </ValueBadge>
          )
        })}
      </Flex>
      {errors?.locations && (
        <Text fontSize={3} color="error.0">
          {errors?.locations}
        </Text>
      )}
    </SectionCard>
  )
}
