import { connect } from "react-redux"
import { getMyEvents } from "../../../store/events/events.selectors"
import MyEvents from "../components/MyEvents"

const mapState = state => ({
  events: getMyEvents(state)
})

const MyEventsContainer = connect(mapState, null)(MyEvents)

export default MyEventsContainer
