import { Accordion, Box } from "@engaging-tech/components"
import useStyledTheme from "@engaging-tech/components/dist/customHooks/useStyledTheme"
import React from "react"

import EditDescriptionContainer from "../../containers/EditActionPlan/EditDescriptionContainer"
import ResourcesContainer from "../../containers/EditActionPlan/ResourcesContainer"
import SuggestedActionsContainer from "../../containers/EditActionPlan/SuggestedActionsContainer"
import SuggestedPhrasesContainer from "../../containers/EditActionPlan/SuggestedPhrasesContainer"
import ActionPlanQuestions from "./ActionPlanQuestions"

const AccordionWrapper = ({ stepQuestions = {} }) => {
  const theme = useStyledTheme()

  return (
    <Box mt={4}>
      {Object.keys(stepQuestions)?.map(key => (
        <Accordion
          title={stepQuestions[key].title}
          paddingY={2}
          px={4}
          key={key}
          outerBorder={`1px solid ${theme.colors.dark[3]}`}
        >
          <EditDescriptionContainer
            description={stepQuestions[key].description}
            stepQuestionId={key}
          />
          <ActionPlanQuestions
            questions={stepQuestions[key].questions}
            stepQuestionId={key}
          />
          <SuggestedActionsContainer
            suggestedActions={stepQuestions[key].suggestedActions}
            stepQuestionId={key}
          />
          <SuggestedPhrasesContainer
            suggestedPhrases={stepQuestions[key].suggestedPhrases}
            stepQuestionId={key}
          />
          <ResourcesContainer
            resources={stepQuestions[key].resources}
            stepQuestionId={key}
          />
        </Accordion>
      ))}
    </Box>
  )
}

export default AccordionWrapper
