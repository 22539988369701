const lineGraphColors = [
  "#FFE000",
  "#FABC60",
  "#3A9679",
  "#11144C",
  "#A3F7BF",
  "#29A19C",
  "#435055",
  "#27323A"
]

export default lineGraphColors
