import { Button, Flex } from "@engaging-tech/components"
import React from "react"

const ActionPlanEditButtons = ({
  handleOpenPreviewModal,
  handleSave,
  handleDiscard,
  canSendForm
}) => {
  return (
    <Flex width="460px" justifyContent="space-between">
      <Button
        width="150px"
        variant="outline.secondary.0"
        bg="light.0"
        color="secondary.0"
        onClick={handleDiscard}
      >
        Discard Changes
      </Button>
      <Button
        width="150px"
        bg="secondary.0"
        color="light.0"
        onClick={handleOpenPreviewModal}
      >
        Preview
      </Button>
      <Button
        width="150px"
        bg="primary.0"
        color="light.0"
        onClick={handleSave}
        disabled={!canSendForm}
      >
        Save
      </Button>
    </Flex>
  )
}

export default ActionPlanEditButtons
