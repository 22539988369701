import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import SubscriptionLayout from "../../../layouts/SubscriptionLayout"
import JoinOurNetworkDetail from "./JoinOurNetworkDetail"
import JoinOurNetworkHeader from "./JoinOurNetworkHeader"

const JoinBIP = ({ handleAction, handleCancel }) => {
  const router = useRouter()
  const paths = usePaths()

  return (
    <SubscriptionLayout>
      <JoinOurNetworkHeader
        headerText="Get Promoted to our Members"
        description="Promote your company to all Engaging Business Members. We will promote a new member daily across our network."
        handleSubscribeClick={() =>
          router.navigate(
            paths.home.businessInformationHub.subscription
          )
        }
      />

      <JoinOurNetworkDetail
        handleCancel={handleCancel}
        handleAction={handleAction}
        individualService
      />
    </SubscriptionLayout>
  )
}

export default JoinBIP
