import { connect } from "react-redux"
import TemplateQuestion from "../components/TemplateQuestion"
import { getQuestion, getViewOnly } from "../store/template.selectors"

const mapState = (state, { questionId }) => ({
  question: getQuestion(state, { questionId }),
  viewOnly: getViewOnly(state)
})

const TemplateQuestionContainer = connect(mapState, null)(TemplateQuestion)

export default TemplateQuestionContainer
