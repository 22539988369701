import { types } from "./survey-view-mode.actions"

export const initialState = {
  surveyId: "",
  isLoading: false,
  survey: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_VIEW_SURVEY:
      return { ...state, isLoading: true, surveyId: action.payload }

    case types.LOAD_VIEW_SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        survey: { ...state.survey, ...action.payload },
        hasError: false
      }

    case types.LOAD_VIEW_SURVEY_FAIL:
      return { ...state, isSaving: false, isLoading: false, hasError: true }

    default:
      return state
  }
}
