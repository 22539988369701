import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"

import { ConfirmBanner } from "../features/account"
import MinifiedNav from "../features/ui/containers/MinifiedNav"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"

const MinifiedNavLayout = ({ children, bg, ...props }) => {
  const router = useRouter()
  const { downForMaintenance } = getConfig()
  if (downForMaintenance) {
    router.navigate("/")
  }
  return (
    <Flex
      width="100vw"
      minHeight="100vh"
      bg={bg}
      flexDirection="column"
      alignItems="center"
    >
      <TestingSiteBanner />
      <MinifiedNav />
      <ConfirmBanner />
      <Flex
        as="main"
        pt={[3, 4]}
        pb={[70, 5]}
        px={["5px", "20px"]}
        maxWidth={1280}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default MinifiedNavLayout
