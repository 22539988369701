import { Table } from "@engaging-tech/components"
import { useParams, usePaths, useRouter } from "@engaging-tech/routing"
import React, { useContext } from "react"
import styled from "styled-components"

import {
  addTableElements,
  tableConfig,
  tableHeadings
} from "../lib/listSixStepsTable"
import { ViewActionPlanContext } from "../views/ViewActionPlan"

const StyledTable = styled(Table)`
  border: ${({ theme }) => `1px solid #EAE9E9`};
  border-radius: 4px;
`

const SixStepsTable = () => {
  const router = useRouter()
  const paths = usePaths()
  const { id } = useParams()
  const handleEditClick = (stepId, stepTitle) =>
    router.navigate(
      paths.actionPlans.editActionPlanStep
        .replace(":id", id)
        .replace(":stepId", stepId),
      { stepTitle }
    )

  const { actionPlan } = useContext(ViewActionPlanContext)
  const rows = addTableElements(actionPlan?.steps, handleEditClick)
  return (
    <StyledTable
      headings={tableHeadings}
      rows={rows}
      config={tableConfig}
      mt={4}
    />
  )
}

export default SixStepsTable
