// import React from "react"
import { useSelector } from "react-redux"
import { getSliceAndDiceReport } from "../features/Reports/store/report.selectors"

const SliceAndDiceFeatureCheck = item => {
  // Input: String, Output: Boolean
  // Check if item is in the sliceAndDice.features[] array, feature contains:
  // features[filters, sentimentAnalysis, instantActionPlanning, heatmaps, matricesDiscoverButton]
  const featuresArray = useSelector(getSliceAndDiceReport)?.features
  return featuresArray?.includes(item)
}

export default SliceAndDiceFeatureCheck
