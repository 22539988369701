import React from "react"
import { Box, Flex, Paragraph } from "@engaging-tech/components"
import styled from "styled-components"
import { cardData } from "../lib/pageControl"

const CustomGrid = styled(Box)`
  display: grid;
  width: fit-content;
  margin-top: 24px;
  grid-gap: 16px;
  margin-left: auto;
  margin-right: auto;

  grid-template-columns: repeat(4, minmax(160px, 258px));

  @media (max-width: 764px) {
    grid-template-columns: repeat(2, minmax(160px, 210px));
    grid-template-rows: auto;
    grid-row-gap: 16px;
    justify-items: center;
  }
`
const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  border: ${({ isBordered, theme }) =>
    isBordered && `4px solid ${theme.colors.primary[0]}`};
`
const SurveyFlowCard = ({
  data: { title, paragraph, img, id },
  currentPage
}) => (
  <Flex width="100%" height="fit-content" flexDirection="column">
    <Flex height="45px">
      <Paragraph textAlign="center" color="secondary.0">
        {title}
      </Paragraph>
    </Flex>
    <Image src={img} isBordered={currentPage === id} />
    <Paragraph textAlign="center" fontSize={2} mt={2} color="secondary.0">
      {paragraph}
    </Paragraph>
  </Flex>
)

const SurveyFlow = ({ currentPage }) => {
  return (
    <CustomGrid>
      {cardData.map(data => (
        <SurveyFlowCard data={data} currentPage={currentPage} key={data.id} />
      ))}
    </CustomGrid>
  )
}

export default SurveyFlow
