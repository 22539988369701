import styled from "styled-components"
import { TextField } from "@engaging-tech/components"

const FormTextField = styled(TextField)`
  .text-field {
    border-radius: 4px 4px 0px 0px;
  }
  margin-bottom: 10px;
  .helper-text-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
  }
`
export default FormTextField
