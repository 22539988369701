import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer"
import React from "react"

import store from "../../../store"
import { logoImg } from "./SurveyReport"

const styles = StyleSheet.create({
  page: {
    fontSize: 14
  },
  headerView: {
    display: "flex",
    flexDirection: "row",
    margin: 10,
    marginBottom: 30
  },
  headerLogo: {
    width: 250,
    objectFit: "contain",
    height: "auto"
  },
  headerTextWrapper: {
    marginLeft: 20
  },
  headerTitle: {
    fontSize: 20,
    color: "#E66F3D"
  },
  headerStepName: {
    fontSize: 14
  },
  view: {
    fontSize: 14,
    margin: 10
  },
  questionTitle: {
    fontSize: 16,
    marginBottom: 14
  },
  score: {
    fontSize: 14
  }
})

const Header = ({ title, stepName }) => {
  return (
    <View style={styles.headerView} fixed>
      <Image src={logoImg} style={styles.headerLogo} />
      <View style={styles.headerTextWrapper}>
        <Text style={styles.headerTitle}>{title}</Text>
        <Text style={styles.headerStepName}>{stepName}</Text>
      </View>
    </View>
  )
}

const ActionPlan = ({ title }) => {
  const data = store.getState().actionPlans.actionPlanStep

  return (
    <Document title={title} author="WorkL for Business" subject={data?.title}>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Header title={title} stepName={data?.title} />
        {Object.keys(data?.questions)?.map(key => (
          <View style={styles.view}>
            <Text style={styles.questionTitle}>
              {data?.questions[key]?.title}
            </Text>
            <Text style={styles.score}>Your score: 45%</Text>

            {data?.questions[key]?.description &&
              {}
              // <Html>{data?.questions[key]?.description}</Html>
            }

            {/* {data?.questions[key]?.questions?.map(
              (suggestedQuestion, sqIdx) => (
                <>
                  <Text>
                    {sqIdx}. {suggestedQuestion?.title}
                  </Text>
                  {suggestedQuestion?.answers?.map(answer => (
                    <Text>{answer}</Text>
                  ))}
                </>
              )
            )} */}
          </View>
        ))}
      </Page>
      {/* {images.map(image => (
        <Page size="A4" style={styles.page} orientation="landscape" key={image}>
          <View style={styles.section}>
            <Header title={title} subSection={subSection} />

            <Image src={image} style={styles.image} />
          </View>
        </Page>
      ))} */}
    </Document>
  )
}

export default ActionPlan
