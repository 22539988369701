import { Utils } from "@engaging-tech/components"
import { createSelector } from "reselect"
import generalConfig from "../../components/generic/generalConfig"
import {
  validateTemplateIntroduction,
  validateTemplateQuestion,
  validateTemplateSection
} from "../../helpers/validationHelper"
// eslint-disable-next-line import/no-cycle
import * as coreQuestionSelectors from "../coreQuestions/coreQuestions.selectors"
import * as genericSelectors from "../generic/generic.selectors"

const cardHeight = (() => {
  if (Utils.getClientDevice() === "MOBILE") {
    return generalConfig.questionCardHeight.mobile
  }
  return generalConfig.questionCardHeight.tabletUp
})()

/**
 * Calculates the height of the layout based on the number of sections
 * Also uses the genearlConfig, and paramters can be changed in the generalConfig to change the layout
 * @param {Object} state - State of the active template store
 * @param {Object} sectionIndex - Index of the section
 * @returns The height of the layout
 */
const calculateLayoutHeight = ({ state, sectionIndex }) => {
  if (!state) return 0
  let total = 0
  const sectionsAbove = []
  Object.keys(state.sections).forEach(sectionKey => {
    if (parseInt(sectionKey, 10) < sectionIndex) {
      sectionsAbove.push(state.sections[sectionKey])
    }
  })

  sectionsAbove.forEach(section => {
    if (section.stackMode) {
      total +=
        cardHeight +
        generalConfig.spaceBetweenCards +
        60 +
        Object.keys(section.questions).length * 3
    } else {
      total =
        total +
        (cardHeight + generalConfig.spaceBetweenCards) *
          (Object.keys(section.questions).length + 1) +
        50
    }
  })

  return total
}

const activeTemplateState = state =>
  state && state["surveyManagement.templates.activeTemplate"]

export const getBespokeSteps = createSelector(
  activeTemplateState,
  state => state.bespokeSteps
)

export const getActiveTemplate = createSelector(
  activeTemplateState,
  state => state
)

export const getIsLoading = createSelector(
  activeTemplateState,
  state => state?.isLoading
)

export const getHasError = createSelector(
  activeTemplateState,
  state => state?.hasError
)

// Get overall layout height based on how many sections, questions and expanded states
export const getLayoutHeight = createSelector(activeTemplateState, state => {
  const total = calculateLayoutHeight({
    state,
    sectionIndex: Object.keys(state.sections).length
  })
  return total + 100
})

// Get section position within window based on section index
export const getTemplateSectionTopDistance = createSelector(
  activeTemplateState,
  (_, { sectionIndex }) => sectionIndex,
  (activeTemplate, sectionIndex) => {
    return calculateLayoutHeight({ state: activeTemplate, sectionIndex })
  }
)

// Get total amount of sections
export const getTotalSections = createSelector(
  activeTemplateState,
  state => Object.keys(state.sections).length
)

// Returns if the template is in draft status
export const getIsDraft = createSelector(
  activeTemplateState,
  state => state.isDraft
)

// Get template id
export const getTemplateId = createSelector(
  activeTemplateState,
  state => state.id
)

export const getSections = createSelector(
  activeTemplateState,
  state => state?.sections
)

export const getSectionQuestions = createSelector(
  activeTemplateState,
  (_, { sectionId }) => sectionId,
  (state, sectionId) =>
    Object.values(state?.sections).reduce((result, { questions, id }) => {
      if (id === sectionId) {
        return Object.values(questions).map(({ id: questionId }) => questionId)
      }
      return result
    }, [])
)

export const getSectionIds = createSelector(activeTemplateState, state =>
  Object.keys(state?.sections).map(key => state?.sections[key].id)
)

export const getType = createSelector(activeTemplateState, state => state?.type)

// Maps active template sections and sections into layout consisting of just IDs
export const getLayout = createSelector(activeTemplateState, state =>
  Object.keys(state.sections).map(sectionKey => {
    return {
      sectionId: state.sections[sectionKey].id,
      questionIds: Object.keys(state.sections[sectionKey].questions).map(
        questionKey => state.sections[sectionKey].questions[questionKey].id
      )
    }
  })
)

// Gets total of all questions across all sections
export const getTotalQuestions = createSelector(activeTemplateState, state => {
  let total = 0
  Object.keys(state.sections).forEach(sectionKey => {
    total += Object.keys(state.sections[sectionKey].questions).length
  })
  return total
})

// Get all active core question IDs from all questions within active template
export const getCoreQuestionIds = createSelector(activeTemplateState, state => {
  const output = []
  Object.keys(state.sections).forEach(sectionKey => {
    Object.keys(state.sections[sectionKey].questions).forEach(questionKey => {
      const question = state.sections[sectionKey].questions[questionKey]
      if (question.coreQuestion) {
        output.push(
          state.sections[sectionKey].questions[questionKey].coreQuestionId
        )
      }
    })
  })
  return output
})

// Get specific template section values, manipulated specifically for section component, for optimisation
export const getTemplateSectionValuesForSectionComponent = createSelector(
  activeTemplateState,
  (_, { sectionIndex }) => sectionIndex,
  (state, sectionIndex) => {
    const section = state.sections[sectionIndex]
    const { questions } = section
    const output = { ...section }
    delete output.questions
    output.questionCount = Object.keys(questions).length
    return output
  }
)

// Get specific template section values
export const getTemplateSectionValues = createSelector(
  activeTemplateState,
  (_, { sectionIndex }) => sectionIndex,
  (state, sectionIndex) => {
    return state.sections[sectionIndex]
  }
)

// Get specific template section values, manipulated specifically for question component, for optimisation
export const getTemplateSectionStackMode = createSelector(
  activeTemplateState,
  (_, { sectionIndex }) => sectionIndex,
  (state, sectionIndex) => {
    const section = state.sections[sectionIndex]
    return section.stackMode
  }
)

// Get specific template question values
export const getTemplateQuestionValues = createSelector(
  activeTemplateState,
  (_, { sectionIndex, questionIndex }) => ({ sectionIndex, questionIndex }),
  (state, { sectionIndex, questionIndex }) =>
    state.sections[sectionIndex]?.questions[questionIndex] || {}
)

// Get template introduction section values
export const getTemplateIntroductionValues = createSelector(
  activeTemplateState,
  state => ({
    title: state.title,
    description: state.description
  })
)

// Returns a boolean to indicate if core question toggle should be visible based on current redux state
export const getShowCoreQuestionToggle = createSelector(
  state => state,
  (_, { sectionIndex, questionIndex }) => ({
    sectionIndex,
    questionIndex
  }),
  (state, { sectionIndex, questionIndex }) => {
    const currentQuestion = getTemplateQuestionValues(state, {
      questionIndex,
      sectionIndex
    })
    if (currentQuestion.coreQuestion) {
      return true
    }

    const filteredCoreQuestions = coreQuestionSelectors.getFilteredCoreQuestionOptions(
      state,
      {
        questionIndex,
        sectionIndex
      }
    )

    return filteredCoreQuestions.length > 0
  }
)

// Returns validation errors for the whole template
export const getValidationObjectForTemplate = createSelector(
  state => state,
  state => {
    const templateValidationObject = {
      sections: [],
      introduction: [],
      questions: []
    }
    const layout = getLayout(state)

    const templateIntroductionValues = getTemplateIntroductionValues(state)

    // Complete validation for introduction
    const introductionValidationArray = validateTemplateIntroduction(
      templateIntroductionValues,
      layout.length
    )
    templateValidationObject.introduction = introductionValidationArray

    layout.forEach((section, sectionIndex) => {
      // Complete validation for section
      const templateSectionValues = getTemplateSectionValuesForSectionComponent(
        state,
        {
          sectionIndex
        }
      )
      const sectionValidationArray = validateTemplateSection(
        templateSectionValues
      )
      if (sectionValidationArray.length > 0) {
        const manipulatedSectionValidationArray = sectionValidationArray.map(
          v => ({ ...v, location: `Section ${sectionIndex + 1}` })
        )
        templateValidationObject.sections.push(
          manipulatedSectionValidationArray
        )
      }

      // Complete validation for question
      section.questionIds.forEach((_, questionIndex) => {
        const templateQuestionValues = getTemplateQuestionValues(state, {
          questionIndex,
          sectionIndex
        })
        const questionValidationArray = validateTemplateQuestion(
          templateQuestionValues
        )
        if (questionValidationArray.length > 0) {
          const manipulatedQuestionValidationArray = questionValidationArray.map(
            v => ({
              ...v,
              location: `Section ${sectionIndex + 1}, Question ${questionIndex +
                1}`
            })
          )
          templateValidationObject.questions.push(
            manipulatedQuestionValidationArray
          )
        }
      })
    })

    templateValidationObject.sections = templateValidationObject.sections.flat()
    templateValidationObject.questions = templateValidationObject.questions.flat()

    const getIsValid = () => {
      if (
        templateValidationObject.introduction.length > 0 ||
        templateValidationObject.sections.length > 0 ||
        templateValidationObject.questions.length > 0
      ) {
        return false
      }
      return true
    }

    return { validationObject: templateValidationObject, valid: getIsValid() }
  }
)

// Returns custom question from template
export const getTemplateCustomQuestions = createSelector(
  activeTemplateState,
  state => {
    const customQuestions = []
    Object.keys(state.sections).forEach(sectionIndex => {
      Object.keys(state.sections[sectionIndex].questions).forEach(
        questionIndex => {
          const question = state.sections[sectionIndex].questions[questionIndex]
          if (!question.coreQuestion) {
            customQuestions.push({ ...question, sectionIndex, questionIndex })
          }
        }
      )
    })

    return customQuestions
  }
)

// Returns if the template is bespoke
export const getIsBespoke = createSelector(
  getTemplateCustomQuestions,
  customQuestionCount => {
    return customQuestionCount.length > 2
  }
)

// Returns a boolean to indicate if question is in edit mode
export const getIsEditMode = createSelector(
  genericSelectors.getCurrentEditableQuestion,
  (_, { sectionIndex, questionIndex }) => ({
    sectionIndex,
    questionIndex
  }),
  (currentEditableSectionQuestionIndex, { sectionIndex, questionIndex }) => {
    return (
      currentEditableSectionQuestionIndex === `${sectionIndex}${questionIndex}`
    )
  }
)

export const getSelfServeDisplayQuestions = createSelector(
  activeTemplateState,
  state =>
    Object.keys(state?.sections[0]?.questions).length > 0
      ? state?.sections[0]?.questions?.map(question => question?.questionText)
      : []
)
