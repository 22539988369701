import { connect } from "react-redux"
import { EBNSelectors } from "../.."
import ArticleComponent from "../../components/Content/Article"

const mapState = (state, { id }) => ({
  article: EBNSelectors.getArticleById(state, id)
})

const Article = connect(mapState)(ArticleComponent)

export default Article
