import { Card, Flex, H2, ImageIcon, Text } from "@engaging-tech/components"
import React from "react"

const surveyOnSurvey2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/SurveyOnSurvey2.svg`)

const ComparisonViewWrapper = ({ title, subheading, children }) => {
  return (
    <Card alignItems="center" py={30}>
      <Flex width={1 / 1} flexWrap="wrap">
        <ImageIcon src={surveyOnSurvey2} width={[1 / 10, 1 / 20]} />
        <Flex width={(8 / 10, 18 / 20)} flexDirection="column">
          <H2 fontSize={[4, 5]} fontWeight="600">
            {title}
          </H2>
          <Text mt={10} mb={20}>
            {subheading}
          </Text>
        </Flex>
      </Flex>
      <Flex width={[8 / 10, 18 / 20]}>{children}</Flex>
    </Card>
  )
}

export default ComparisonViewWrapper
