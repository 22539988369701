import { Flex, H2, H4 } from "@engaging-tech/components"
import React from "react"
import LinkToBtn from "./LinkToBtn"

const DashboardTopBanner = ({
  title,
  subtitle,
  ExtraButton,
  buttonPath,
  buttonText
}) => {
  return (
    <Flex
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      alignItems={["flex-start", "center"]}
      pb={3}
      px={[2, 0, 0]}
    >
      <Flex flexDirection="column">
        <H2 fontWeight={700} width="auto" fontSize={28}>
          {title}
        </H2>
        <H4 width="auto" fontSize="15px" mt={2} mb={2}>
          {subtitle}
        </H4>
      </Flex>
      <Flex
        flexDirection={["column-reverse", "row", "row"]}
        width="auto"
        alignItems={["flex-start", "center", "center"]}
        height={["auto", 40, 40]}
      >
        {ExtraButton && <ExtraButton />}
        {buttonPath && buttonText && (
          <LinkToBtn path={buttonPath} text={buttonText} />
        )}
      </Flex>
    </Flex>
  )
}

export default DashboardTopBanner
