import { connect } from "react-redux"
import { getReportTypename } from "../../Header/store/report.header.selectors"
import CustomQuestionsComponent from "../components/CustomQuestions"
import * as Selectors from "../store/customquestions.selectors"

const mapState = state => {
  return {
    customRadioDropdownQuestions: Selectors.customRadioDropdownQuestions(state),
    customScaleQuestions: Selectors.customScaleQuestions(state),
    startExitCoreRadioDropdownQuestions: Selectors.getStartExitCoreRadioDropdownQuestions(
      state
    ),
    startExitCoreScaleQuestions: Selectors.getStartExitCoreScaleQuestions(
      state
    ),
    reportTypename: getReportTypename(state)
  }
}

const CustomQuestions = connect(mapState)(CustomQuestionsComponent)

export default CustomQuestions
