import { connect } from "react-redux"
import CancelPDFModalComponent from "../components/CancelPDFModal"

import { updateIsPdfDownloading } from "../store/report.actions"
import { getPdfProgress } from "../store/report.selectors"

const mapState = state => ({
  pdfProgress: getPdfProgress(state)
})
const mapDispatch = dispatch => ({
  updatePdfDownloading: payload => dispatch(updateIsPdfDownloading(payload))
})

const CancelPDFModal = connect(mapState, mapDispatch)(CancelPDFModalComponent)

export default CancelPDFModal
