import { createSelector } from "reselect"

import { ReportSelectors } from "../.."

const pipePassedInProp = (state, prop) => prop

const questionIdsBySection = {
  sixSteps_reward: ["01", "02", "03"],
  sixSteps_information: ["04", "05", "06"],
  sixSteps_empowerment: ["07", "08", "09"],
  sixSteps_wellBeing: ["10", "11", "12", "13"],
  sixSteps_pride: ["14", "15", "16"],
  sixSteps_jobSatisfaction: ["17", "18", "19", "20"]
}

export const getSurveySixSteps = createSelector(
  ReportSelectors.getReportData,
  data => data && data.surveySixSteps
)

const getTemplate = createSelector(ReportSelectors.getReportResponse, report => report && report.template)

const getTemplateMainQuestions = createSelector(
  getTemplate,
  template => template && template.sections && template.sections[0] && template.sections[0].questions
)

const getQuestionsForStepFromTemplate = (questions, section) =>
  questionIdsBySection[section] &&
  questionIdsBySection[section].map(id => questions.find(question => question.id === id))

export const getCurrentStep = createSelector(
  [getSurveySixSteps, pipePassedInProp],
  (surveySixSteps, stepName) => surveySixSteps?.find(step => step.name === stepName)
)

export const getCurrentStepScore = createSelector(getCurrentStep, step => step && step.score && step.score)
export const getCurrentStepIndustry = createSelector(
  getCurrentStep,
  step => step && step.industry && step.industry
)

export const getQuestionsForStep = createSelector(
  [getCurrentStep, pipePassedInProp, getTemplateMainQuestions],
  (currentStep, stepName, templateQuestions) => {
    // Fallback to select questions from template if chart data is not provided from BE
    if (currentStep && currentStep.questions && currentStep.questions.length > 0) return currentStep.questions
    return getQuestionsForStepFromTemplate(templateQuestions, stepName)
  }
)
