import { Flex, RoundedDropdown, Text } from "@engaging-tech/components"
import React, { useContext } from "react"

import TemplateQuestionContext from "../TemplateQuestionContext"

const DropdownQuestionPreview = () => {
  const { values } = useContext(TemplateQuestionContext)

  return (
    <Flex flexDirection="column">
      <Text fontSize={3} mt={3} mb={1} style={{ fontStyle: "italic" }}>
        Preview dropdown
      </Text>
      <RoundedDropdown>
        {values?.map(obj => {
          if (obj?.archived) return null
          return <option key={obj?.id}>{obj?.value}</option>
        })}
      </RoundedDropdown>
    </Flex>
  )
}

export default DropdownQuestionPreview
