import features from "../lib/siteFeatures"
import { types } from "./ui.actions"

export const initialState = {
  isLoading: false,
  hasError: false,
  loadedFeatures: {
    [features.account]: false
  },
  currentRoute: "/",
  backBar: {
    visible: false,
    text: null,
    to: null
  },
  allCountries: [],
  allIndustries: [],
  feebackDialogIsOpen: false,
  feedbackData: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FEEDBACK_DIALOG_OPEN:
      return {
        ...state,
        feebackDialogIsOpen: true,
        feedbackData: action.payload
      }
    case types.FEEDBACK_DIALOG_CLOSE:
      return {
        ...state,
        feebackDialogIsOpen: false
      }

    case types.MARK_LOADED:
      return {
        ...state,
        loadedFeatures: {
          ...state.loadedFeatures,
          [action.payload]: true
        }
      }

    case types.MARK_NOT_LOADED:
      return {
        ...state,
        loadedFeatures: {
          ...state.loadedFeatures,
          [action.payload]: false
        }
      }
    case types.SET_ROUTE:
      return {
        ...state,
        previousRoute: state.currentRoute,
        currentRoute: action.payload
      }

    case types.SET_BACKBAR:
      return {
        ...state,
        backBar: {
          ...state.backBar,
          ...action.payload
        }
      }

    case types.LOAD_ALL_COUNTRIES:
    case types.LOAD_ALL_INDUSTRIES:
      return {
        ...state,
        isLoading: true,
        hasError: false
      }

    case types.LOAD_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        allCountries: [...action.payload]
      }

    case types.LOAD_ALL_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        allIndustries: [...action.payload]
      }

    case types.LOAD_ALL_INDUSTRIES_FAILURE:
    case types.LOAD_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true
      }

    default:
      return state
  }
}
