import { Card, Flex, Radio, Text } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const FlexDropdown = styled(Card)`
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 2;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.light[0]};
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[1]}`};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`

const BorderedFlex = styled(Flex)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: ${({ openDropdown, theme }) =>
    openDropdown && `1px solid ${theme.colors.secondary[0]}`};
`

const BorderBottomText = styled(Text)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.secondary[1]}`};
`

const CustomText = styled(Text)`
  display: -webkit-box;
  overflow: hidden;
  height: 33px;
  line-height: 2;
  padding-top: 3px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  cursor: pointer;
  width: 100%;

  &:hover {
    top: 0;
    width: max-content;
    position: absolute;
    z-index: 3;
    padding-right: 8px;
  }
`

export const DropdownTab = ({
  isSelected,
  keyValue,
  onClick,
  value,
  dropdownData,
  load,
  comparisonSurveyId
}) => {
  const ref = useRef()
  const [openDropdown, setOpenDropdown] = useState(false)

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (!isSelected) setOpenDropdown(false)
  }, [isSelected])

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenDropdown(false)
    }
  }

  const handleOnClick = e => {
    setOpenDropdown(true)
  }

  const handleLoad = (e, _id, title) => {
    onClick(keyValue)
    setOpenDropdown(false)
    load(_id, false, [], ["organisation", "awards"], title)
  }

  return (
    <BorderedFlex
      alignItems="center"
      bg={isSelected ? "secondary.0" : "transparent"}
      justifyContent="center"
      mr={4}
      p={2}
      style={{ cursor: "pointer" }}
      width="initial"
      flexDirection="column"
      position="relative"
      ref={ref}
      openDropdown={openDropdown}
    >
      <Text
        color={isSelected ? "white" : "secondary.0"}
        fontSize={3}
        fontWeight="bold"
        style={{ userSelect: "none" }}
        width="initial"
        onClick={handleOnClick}
      >
        {value}
      </Text>

      {openDropdown && (
        <FlexDropdown>
          <BorderBottomText fontSize={2} p={2} width={1 / 1}>
            {!!dropdownData.length
              ? "Select historic results to compare"
              : "No results to compare"}
          </BorderBottomText>
          {!!dropdownData.length && (
            <>
              {dropdownData.map(({ _id, title }, i) => (
                <Flex
                  px={2}
                  key={_id}
                  position="relative"
                  height="33px"
                  alignItems="center"
                  bg={comparisonSurveyId === _id ? "secondary.1" : "light.0"}
                  width={1 / 1}
                  onClick={e => handleLoad(e, _id, title)}
                >
                  <CustomText
                    fontSize={3}
                    bg={comparisonSurveyId === _id ? "secondary.1" : "light.0"}
                  >
                    {title}
                  </CustomText>
                </Flex>
              ))}
            </>
          )}
        </FlexDropdown>
      )}
    </BorderedFlex>
  )
}
