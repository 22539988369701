import { Box, Button, Flex, H2, ImageIcon } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { LoadingState } from "../../surveyManagement/components"
import EmployeeReportsList from "../EmployeeReports/components/EmployeeReportsList"
import EmptyDashboard from "../components/EmptyDashboard"
import { fullHeatmapsClear, setSurveyType } from "../store/report.actions"

const newIcon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/newIcon.svg`)

const ReportsDashboard = ({
  page,
  isLoading,
  reports,
  isThereMore,
  onLoad
}) => {
  const dispatch = useDispatch()
  const paths = usePaths()
  const router = useRouter()

  const [selectedReports, setSelectedReports] = useState([])

  useEffect(() => {
    dispatch(setSurveyType(["organisationSurvey", "awards"]))
    dispatch(fullHeatmapsClear())
  }, [])

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={["column", "row"]}
        width={1 / 1}
        justifyContent="space-between"
        mb={[3, 0]}
        height={["80px", "60px"]}
      >
        <H2 fontWeight={700} fontSize={[5, 6]} width={[1 / 1, "fit-content"]}>
          Engagement Reports
        </H2>

        <Box mt={[20, 0]} width="fit-content">
          <Button
            width="fit-content"
            variant="outline.primary.0"
            color="light.0"
            onClick={() => {
              router.navigate(
                paths.reports.engagementSurveys.compareEngagementSurveys
              )
            }}
          >
            <ImageIcon src={newIcon} width={40} name="compare" /> Compare Survey
            Results
          </Button>
        </Box>
      </Flex>
      <EmployeeReportsList
        reports={reports}
        checkBoxLabelText="Language BreakDown"
        selectedReports={selectedReports}
        setSelectedReports={setSelectedReports}
        surveyDetails={{
          title: "Engagement Survey",
          createLink: "engagement-surveys"
        }}
        showCheckBox={isRoot => false}
      />
      <Flex flexDirection="column" width={1 / 1} alignItems="center">
        {!reports?.length ? (
          <EmptyDashboard />
        ) : isLoading ? (
          <LoadingState firstPage={page === 0} hideMessage />
        ) : (
          <Button
            bg={isThereMore ? "primary.0" : "primary.2"}
            color="light.0"
            mt={4}
            width="300px"
            disabled={isLoading || !isThereMore}
            onClick={() => onLoad()}
          >
            Load more
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default ReportsDashboard
