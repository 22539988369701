import { Box, CustomHooks, Flex } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import SliceAndDiceUpsellIAS from "../../../../sliceAndDice/components/SliceAndDiceUpsell/SliceAndDiceUpsellIAS"
import {
  getReportResponse,
  getReportResponseId,
  getSliceAndDiceReport
} from "../../../store/report.selectors"
import actionPlanItems from "../../lib/actionPlan.json"
import retailTrustOrganisations from "../../lib/retailTrustOrganisations"
import retailTrustAPI from "../../lib/retailTrust_actionPlan.json"
import ActionPlanStep from "./ActionPlanStep"

const SectionGrid = styled(Box)`
  padding: 40px 0px;
  align-items: flex-start;
  display: grid;
  grid-template-columns: ${({ device }) =>
    device === "MOBILE" ? "100%" : "70% 30%"};
  grid-template-rows: auto auto;
  grid-template-areas: ${({ device }) =>
    device === "MOBILE"
      ? `"B"
   "A"
   "C"`
      : `"A B"
   "A C"`};
  grid-gap: ${({ device }) => (device === "MOBILE" ? "24px" : "0px")};
`

const CustomFlex = styled(Flex)`
  grid-area: A;
`

const PDFView = ({
  heading,
  surveyReportSixStep,
  stepQuestions,
  exportingPDF
}) => {
  return (
    <Flex width={1 / 1} className="report-item-exporting">
      {surveyReportSixStep?.actionPlanStepId ? (
        <ActionPlanStep
          actionPlanId={surveyReportSixStep.actionPlanStepId}
          score={surveyReportSixStep?.score ?? -1}
          stepQuestions={stepQuestions}
          exportingPDF={exportingPDF}
          heading={heading}
        />
      ) : null}
    </Flex>
  )
}

const PageManagement = ({
  step,
  stepQuestions,
  orgId,
  exportingPDF,
  setActionPlanStep
}) => {
  const [questionList, setQuestionList] = useState([])
  const isSliceAndDiceMember = useSelector(getSliceAndDiceReport)?.active
  const reportId = useSelector(getReportResponseId)

  const device = CustomHooks.useClientDevice()

  const surveyReport = useSelector(getReportResponse)
  const surveyReportSixStep = surveyReport?.data?.surveySixSteps.find(
    i => i.name === step
  )

  if (!isSliceAndDiceMember) {
    return <SliceAndDiceUpsellIAS reportId={reportId} saveUpgradeIdOnStorage />
  }

  const rtChecker = () => {
    if (retailTrustOrganisations.includes(orgId)) {
      return retailTrustAPI[step]
    }
    return actionPlanItems[step] || {}
  }

  const { heading = "", questions } = rtChecker(orgId)

  useEffect(() => {
    const availableQuestions = stepQuestions?.reduce((p, q) => {
      p[q.id] = q.values.score
      return p
    }, {})

    const extractedQuestions = questions?.filter(q => {
      if (Object.keys(availableQuestions).includes(q.id)) {
        q.score = availableQuestions[q.id]
        return q
      }
    })

    setQuestionList(extractedQuestions)
  }, [])

  if (exportingPDF) {
    return (
      <PDFView
        heading={heading}
        device={device}
        step={step}
        questionList={questionList}
        surveyReportSixStep={surveyReportSixStep}
        stepQuestions={stepQuestions}
        exportingPDF={exportingPDF}
      />
    )
  }

  return (
    <Flex width={1 / 1}>
      {surveyReportSixStep?.actionPlanStepId ? (
        <ActionPlanStep
          actionPlanId={surveyReportSixStep.actionPlanStepId}
          score={surveyReportSixStep?.score ?? -1}
          stepQuestions={stepQuestions}
          setActionPlanStep={setActionPlanStep}
        />
      ) : null}
    </Flex>
  )
}

export default PageManagement
