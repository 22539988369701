import { types } from "./events.actions"

// TODO: Remove placeholders
export const initialState = {
  error: null,
  data: []
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        data: payload
      }

    case types.GET_MY_EVENTS_FAIL: {
      return {
        ...state,
        error: payload.error
      }
    }

    default:
      return state
  }
}
