import { Box, Text } from "@engaging-tech/components"
import React, { useContext } from "react"
import styled from "styled-components"
import SignUpNavigation from "./SignUpNavigation"
import FormTextField from "./FormTextField"

const StyledBox = styled(Box)`
  background-color: rgba(0, 225, 255, 0.15);
  padding: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`

const EmailForm = ({
  context,
  handleChange,
  touched,
  values,
  handleBlur,
  errors
}) => {
  const { handleNext, handleBack } = useContext(context)

  const isValid = !errors.email && !errors.password && !errors.confirmPassword

  return (
    <>
      <Box my={4} px={4}>
        <FormTextField
          width={1 / 1}
          id="sign-up-email"
          name="email"
          type="text"
          label="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={touched.email && errors.email}
        />
        <FormTextField
          width={1 / 1}
          id="sign-up-password"
          name="password"
          type="password"
          label="Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password}
        />
        <FormTextField
          width={1 / 1}
          id="sign-up-confirmPassword"
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          error={touched.confirmPassword && errors.confirmPassword}
        />
        <StyledBox mb={2}>
          Your password must be at least 8 characters long and contain at least
          1 number, 1 special character, and 1 uppercase letter
        </StyledBox>
        <Text fontSize={3} color="dark.3">
          *PLEASE NOTE If you are entering any awards please create an account
          to set up your awards survey
        </Text>
      </Box>
      <SignUpNavigation
        primary={{
          label: "Next",
          action: handleNext,
          disabled: !isValid
        }}
        secondary={{
          label: "Back",
          action: handleBack,
          disabled: false
        }}
      />
    </>
  )
}
export default EmailForm
