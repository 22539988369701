import React from "react"
import { Box } from "@engaging-tech/components"

const Content = ({ children }) => (
  <Box width={1 / 1} px={3} py={2}>
    {children}
  </Box>
)

export default Content
