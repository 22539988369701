/* eslint-disable import/prefer-default-export */
export const companySizes = [
  {
    id: "1 - 9 employees",
    name: "1 - 9 employees"
  },
  {
    id: "10 - 19 employees",
    name: "10 - 19 employees"
  },
  {
    id: "20 - 49 employees",
    name: "20 - 49 employees"
  },
  {
    id: "50 - 249 employees",
    name: "50 - 249 employees"
  },
  {
    id: "250 - 499 employees",
    name: "250 - 499 employees"
  },
  {
    id: "500 - 999 employees",
    name: "500 - 999 employees"
  },
  {
    id: "1000 - 4999 employees",
    name: "1000 - 4999 employees"
  },
  {
    id: "5000 - 9999 employees",
    name: "5000 - 9999 employees"
  },
  {
    id: "10,000 - 49,999 employees",
    name: "10,000 - 49,999 employees"
  },
  {
    id: "50,000 - 99,999 employees",
    name: "50,000 - 99,999 employees"
  },
  {
    id: "100,000+ employees",
    name: "100,000+ employees"
  }
]
