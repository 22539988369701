import { connect } from "react-redux"
import { EBNSelectors } from "../.."
import LectureComponent from "../../components/Content/Lecture"

const mapState = (state, { id }) => ({
  lecture: EBNSelectors.getLectureById(state, id)
})

const Lecture = connect(mapState)(LectureComponent)

export default Lecture
