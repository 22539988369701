import { Flex, RichTextEditor, Text } from "@engaging-tech/components"
import React from "react"

const EditDescription = ({ description, setDescription, stepQuestionId }) => {
  const parsedDescription = !!description
    ? JSON.parse(description)
    : [
        {
          type: "paragraph",
          children: [
            {
              text: ""
            }
          ]
        }
      ]

  console.log(parsedDescription)
  return (
    <Flex flexDirection="column">
      <Text fontSize={5} fontWeight={600} mt={5} mb={3}>
        Description
      </Text>
      <RichTextEditor
        handleChange={value => setDescription(value, stepQuestionId)}
        initialValue={parsedDescription}
      />
    </Flex>
  )
}
export default EditDescription
