import { Spinner } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React from "react"

import { ActionPlanStepsPreview } from "../../components/action-plan"
import { OrgGuard } from "../../features/account"
import GET_ACTION_PLAN_PREVIEW from "../../graphql/queries/get-action-plan-preview"
import useAppSyncQuery from "../../hooks/useAppSyncQuery"
import DashboardLayout from "../../layouts/DashboardLayout"

const ActionPlanPreview = () => {
  const { id } = useParams()
  const { isLoading, data } = useAppSyncQuery(GET_ACTION_PLAN_PREVIEW, {
    variables: { actionPlanId: id }
  })

  return (
    <OrgGuard>
      <DashboardLayout>
        {isLoading ? (
          <Spinner color="secondary.0" justifyContent="center" width={1 / 1} />
        ) : data && data?.previewActionPlan?.body ? (
          <ActionPlanStepsPreview
            surveyTypeTitle="Action Plan Preview"
            data={data.previewActionPlan.body}
            actionPlanId={id}
          />
        ) : null}
      </DashboardLayout>
    </OrgGuard>
  )
}

export default ActionPlanPreview
