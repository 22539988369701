import { Button, Checkbox, Flex, Text } from "@engaging-tech/components"
import React from "react"

const normaliseText = text => {
  let words = text
    .replace("matrices_", "")
    .split(/(?=[A-Z-])/)
    .map(word => word.replace("-", ""))

  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
}

const ConfigureLayoutForm = ({ values, setFieldValue, setOpenModal, handleSubmit, handleBlur }) => {
  return (
    <>
      <Flex width={1 / 1} flexDirection="column">
        {Object.keys(values)?.map(key => (
          <Checkbox
            key={key}
            py={2}
            px={3}
            borderRadius="5px"
            label={() => <Text color="secondary.0">{normaliseText(key)}</Text>}
            checked={values[key]}
            colour="dark.2"
            onChange={() => setFieldValue(key, !values[key])}
            onBlur={handleBlur}
          />
        ))}
      </Flex>
      <Flex width={1 / 1} justifyContent="space-around" mt={3}>
        <Button bg="primary.0" width="150px" onClick={handleSubmit}>
          Confirm
        </Button>
        <Button width="150px" onClick={() => setOpenModal(false)}>
          Cancel
        </Button>
      </Flex>
    </>
  )
}

export default ConfigureLayoutForm
