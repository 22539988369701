import { connect } from "react-redux"
import DownloadShortTextComponent from "../components/DownloadShortText"
import { ReportSelectors } from ".."

const mapState = state => ({
  link: ReportSelectors.getTextResponsesUrl(state)
})

const DownloadShortText = connect(mapState)(DownloadShortTextComponent)

export default DownloadShortText
