import { Box, Flex, Icon, Paragraph, Text } from "@engaging-tech/components"
import React, { useEffect } from "react"
import LoadingState from "../../components/LoadingState"
import TemplateItem from "./TemplateItem"

const ErrorState = ({ message = "There was an error" }) => (
  <Flex flexDirection="row" mb={3} alignItems="center">
    <Icon name="warning" size={30} />
    <Text ml={3}>{message}</Text>
  </Flex>
)

const TemplateOptions = ({
  addSelect,
  isLoading,
  hasError,
  templateSelected,
  setTemplateSelected,
  myPermanentTemplates,
  myDraftTemplates,
  defaultTemplates,
  loadAll,
  deleteTemplate,
  disableIcons = false,
  showViewOnlyButton = false,
  noHover = false
}) => {
  useEffect(() => {
    loadAll()
  }, [loadAll])

  if (isLoading) {
    return <LoadingState message="Loading templates" />
  }

  if (hasError) {
    return (
      <ErrorState message="There has been an error loading your templates" />
    )
  }

  return (
    <Flex flexDirection="column" px={[2, 0, 0]}>
      {!!myDraftTemplates.length && (
        <Box>
          <Text fontSize={["18px", 5]} mb={4} fontWeight={700}>
            Your draft survey templates
          </Text>
          <Flex flexWrap="wrap">
            {myDraftTemplates.map(template => (
              <TemplateItem
                template={template}
                disableIcons={disableIcons}
                bg="linear-gradient(to bottom right, #009cb8, #0a6a87)"
                addSelect={addSelect}
                templateSelected={templateSelected}
                setTemplateSelected={setTemplateSelected}
                deleteTemplate={deleteTemplate}
                key={template.id}
                showViewOnlyButton={showViewOnlyButton}
                noHover={noHover}
              />
            ))}
          </Flex>
        </Box>
      )}
      {!!myPermanentTemplates.length && (
        <Box>
          <Paragraph fontSize={["18px", 5]} mb={3} fontWeight={700}>
            Your Saved Survey Templates
          </Paragraph>
          <Paragraph fontSize={["18px", 5]} mb={3} color="dark.3">
            Templates you have previously created, and used for previous
            surveys.
          </Paragraph>
          <Flex flexWrap="wrap">
            {myPermanentTemplates.map(template => (
              <TemplateItem
                template={template}
                disableIcons={disableIcons}
                bg="linear-gradient(to right bottom, rgb(255 141 151), rgb(216 36 36))"
                addSelect={addSelect}
                templateSelected={templateSelected}
                setTemplateSelected={setTemplateSelected}
                deleteTemplate={deleteTemplate}
                showViewOnlyButton={showViewOnlyButton}
                key={template.id}
                noHover={noHover}
              />
            ))}
          </Flex>
        </Box>
      )}
      {defaultTemplates && (
        <Box>
          <Paragraph fontSize={["18px", 5]} mb={3} fontWeight={700}>
            WorkL for Business Standard Templates
          </Paragraph>
          <Paragraph fontSize={["18px", 5]} mb={3} color="dark.3">
            Templates created by the WorkL for Business team with standard
            question sets to help make setting up a survey as straightforward as
            can be.
          </Paragraph>
          <Flex flexWrap="wrap">
            {defaultTemplates.map(template => (
              <TemplateItem
                template={template}
                disableIcons={disableIcons}
                bg="linear-gradient(to bottom right, #003e4c, #098a61)"
                addSelect={addSelect}
                templateSelected={templateSelected}
                setTemplateSelected={setTemplateSelected}
                deleteTemplate={deleteTemplate}
                showViewOnlyButton={showViewOnlyButton}
                key={template.id}
                noHover={noHover}
              />
            ))}
          </Flex>
        </Box>
      )}
    </Flex>
  )
}

export default TemplateOptions
