import { Navbar } from "@engaging-tech/navigation"
import { usePaths, useRoutes } from "@engaging-tech/routing"
import { ThirdPartyActions } from "@engaging-tech/third-party-cookies"
import { Auth } from "aws-amplify"
import React from "react"
import { useDispatch } from "react-redux"

import { GET_BASKET } from "../../../graphql/queries"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import {
  getBasketFail,
  getBasketReq,
  getBasketSuccess
} from "../../../store/basket/basket.actions"
import { signOutSuccess } from "../../account/store/account.actions"
import SiteLogo from "./SiteLogo"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const MinifiedNav = ({
  isAuthenticated,
  disableAccountRoutes,
  organisationDetails,
  isEBNMember,
  headerLogo,
  basketLength
}) => {
  const routes = useRoutes()
  const paths = usePaths()

  const allAccountRoutes = routes.filter(route =>
    route.path.includes(paths.account.index)
  )[0].children

  const userAccountRoutes = allAccountRoutes.filter(
    route => route.path !== paths.account.forBusiness
  )

  const signedOutRoutes = userAccountRoutes.filter(
    route =>
      route.path === paths.account.signIn || route.path === paths.account.signUp
  )

  const dispatch = useDispatch()

  const onSignOut = async () => {
    await Auth.signOut()
    dispatch(ThirdPartyActions.setClearConsent())
    dispatch(signOutSuccess())
    window.localStorage.removeItem("surveyIdUpgrade")
  }

  const signedInRoutes = [
    {
      linkProps: {
        onClick: onSignOut
      },
      description: "Sign Out",
      icon: "meeting_room"
    }
  ]

  useBusinessAppQuery(GET_BASKET, {
    initAction: getBasketReq,
    successAction: getBasketSuccess,
    failAction: getBasketFail
  })

  const basketData = {
    items: basketLength,
    route: paths.yourBasket.index
  }

  return (
    <Navbar
      siteLogo={SiteLogo}
      headerLogo={headerLogo}
      accountRoutes={(() => {
        if (disableAccountRoutes) {
          return []
        }
        return isAuthenticated ? signedInRoutes : signedOutRoutes
      })()}
      userDetails={{
        isAuthenticated,
        details: organisationDetails
          ? { ...organisationDetails.currentUser, premium: isEBNMember }
          : null,
        nonPremiumColor: "dark.4",
        premiumColor: "primary.1"
      }}
      accountRoutesProps={{
        bg: "secondary.1",
        color: "dark.2",
        textStyle: {
          color: "dark.2"
        }
      }}
      tabBarColor="primary.1"
      navbarItemColor={{ selected: "primary.0", unSelected: "dark.2" }}
      tabBarItemColor={{ selected: "dark.0", unSelected: "dark.2" }}
      elevation={3}
      height={100}
      mobileNavbarHeight={60}
      holdingImage={profilePlaceholder}
      cartData={basketData}
    />
  )
}

export default MinifiedNav
