import React from "react"
import { Flex, Button } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

const SignUpNavigation = ({ primary, secondary }) => {
  let desiredLocation = ""
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    desiredLocation = urlParams.get("desiredLocation")
  }

  return (
    <Flex
      width={1 / 1}
      flexDirection="row"
      justifyContent="space-between"
      p={3}
    >
      <Link to={desiredLocation || "/account/sign-in"}>
        <Button elevation={0} type="button" variant="text.noBg.dark.2">
          Sign In
        </Button>
      </Link>
      <Flex width="auto">
        <Button
          type="button"
          disabled={secondary.disabled}
          onClick={secondary.action}
          width="auto"
          elevation={0}
          variant="text.noBg.dark.2"
        >
          {secondary.label}
        </Button>
        <Button
          variant="secondary.0"
          type="button"
          ml={2}
          onClick={primary.action}
          disabled={primary.disabled}
          width={["auto", 168]}
        >
          {primary.label}
        </Button>
      </Flex>
    </Flex>
  )
}

export default SignUpNavigation
