import { Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React, { useState } from "react"
import styled from "styled-components"

import DisplayProduct from "../../../ui/components/DisplayProduct"
import SliceAndDiceCompareModal from "../SliceAndDiceCompareModal"

const UnderlinedText = styled(Text)`
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`

const SliceAndDiceUpsellChooseCompanySize = () => {
  const [showCompareSDModal, setShowCompareSDModal] = useState(false)

  return (
    <>
      <Flex
        justifyContent="center"
        mt={4}
        flexDirection="column"
        alignItems="center"
      >
        <Text
          width="fit-content"
          color="secondary.0"
          fontSize={5}
          fontWeight={600}
          mb={3}
        >
          Upgrade your data package
        </Text>
        <Text width="500px" color="secondary.0" mb={3} textAlign="center">
          Measure, track and improve your employee experience with an Enhanced
          Data Insight Package.
        </Text>
        <UnderlinedText
          width="fit-content"
          color="secondary.0"
          mb={3}
          onClick={() => setShowCompareSDModal(true)}
        >
          <u>What's Included?</u>
        </UnderlinedText>
        <Text width="fit-content" color="secondary.0" fontWeight={600}>
          Choose your organisation size
        </Text>
        <DisplayProduct
          hideMessage
          actionButtonText="Upgrade to Enhanced Data Package"
          openBasketWhenAddProduct={true}
        />
        <Text
          width="fit-content"
          color="secondary.0"
          mt={3}
          fontSize={3}
          fontWeight={600}
        >
          <Link to="/network">
            <u>Click here</u>
          </Link>{" "}
          to return to the WorkL for Business homepage
        </Text>
      </Flex>
      {showCompareSDModal && (
        <SliceAndDiceCompareModal setShowPreview={setShowCompareSDModal} />
      )}
    </>
  )
}

export default SliceAndDiceUpsellChooseCompanySize
