import React from "react"
import { List, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

const Resources = ({ resource }) => {
  return (
    <List.Wrapper>
      <List.Item as="li" color="dark.2" fontSize={3} fontWeight={500}>
        {`${resource.type} - `}
        <Link external newTab to={resource.url}>
          <Text fontSize={14} fontWeight={700} ml="3px" color="primary.0">
            {" "}
            {resource.title}
          </Text>
        </Link>
      </List.Item>
    </List.Wrapper>
  )
}

export default Resources
