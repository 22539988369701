import { connect } from "react-redux"
import {
  getGenerateError,
  getGenerateParameters,
  getIsLoading,
  getRedirectLink,
  getReportsToGenerateFrom
} from "../../store/report.selectors"
import { generateReport } from "../../store/report.actions"
import ReportsDialogue from "../components/ReportsDialogue"

const mapState = state => ({
  selectedReports: getReportsToGenerateFrom(state),
  yesActionParameters: getGenerateParameters(state),
  isLoading: getIsLoading(state),
  successActionParameters: getRedirectLink(state),
  disableButton: !getGenerateParameters(state)?.title,
  Error: getGenerateError(state)
})

const mapDispatch = dispatch => ({
  yesAction: ({ title, surveyIds }) =>
    dispatch(generateReport(title, surveyIds))
})

const GenerateReportsDialogueContainer = connect(
  mapState,
  mapDispatch
)(ReportsDialogue)

export default GenerateReportsDialogueContainer
