import React from "react"
import { useLocation } from "@engaging-tech/routing"
import { OrgGuard } from "../../features/account"

import IndicatorsView from "../../features/Reports/views/Indicators"
import ReportWrapper from "../../features/Reports/views/ReportWrapper"
import ReportLayout from "../../layouts/ReportLayout"

const IndicatorBreakdown = () => {
  const location = useLocation()
  const matchedType = location.pathname.match(/([^\/]+$)/g)
  return (
    <OrgGuard>
      <ReportLayout>
        <ReportWrapper>
          <IndicatorsView type={matchedType[0]} />
        </ReportWrapper>
      </ReportLayout>
    </OrgGuard>
  )
}

export default IndicatorBreakdown
