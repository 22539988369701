import { Flex, Text } from "@engaging-tech/components"
import { differenceInDays } from "date-fns"
import React from "react"
import { Tooltip } from "react-tooltip"

import TooltipIcon from "../tooltip-icon"

export default function SurveyDaysToClose({ meta }) {
  const daysToClose = differenceInDays(new Date(meta.endDate), new Date())

  return (
    <Flex flexDirection="column" alignItems="left">
      <Flex>
        <a
          data-tooltip-id="survey-dates-to-close-tooltip"
          data-tooltip-content="Remaining days before the survey closes"
        >
          <Text fontSize={3} color="dark.2" pb={2} width="fit-content">
            Survey ends in
          </Text>
          <TooltipIcon />
        </a>
        <Tooltip id="survey-dates-to-close-tooltip" />
      </Flex>
      <Text fontSize={6} fontWeight={800} color="dark.2" width="fit-content">
        {daysToClose <= 0 ? 0 : daysToClose} days
      </Text>
    </Flex>
  )
}
