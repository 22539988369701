import { gql } from "@apollo/client"

const GET_LIVE_JOBS = gql`
  query getLiveJobs {
    getLiveJobs {
      data {
        job_id
        contact_email
        job_type
        title
        position
        experience_level
        status
        location
        lat
        lang
        posted_at
        age
      }
    }
  }
`

export default GET_LIVE_JOBS

// jobId //
// role //
// email //
// jobType //
// remote //
// experienceLevel //
// target //
// location {
//   name //
//   country {
//     name
//   }
//   coordinates {
//     lat //
//     lng //
//   }
// }
// status //
// userId
// matches
// postedDate //
// expireAt //
