import {
  CustomHooks,
  Flex,
  Icon,
  Paragraph,
  Text
} from "@engaging-tech/components"
import React, { useState } from "react"
import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import Nav from "../../surveyInfo/components/Nav"
import BillingFormComponent from "../containers/BillingForm"

const BillingForm = () => {
  const [actions, setActions] = useState({})
  CustomHooks.useFreshChatDeactivate(true)
  return (
    <CreationFormsLayout
      bg="#EEF4F9"
      maxWidth="1000px"
      BottomNav={() => <Nav {...actions} />}
      mt={5}
      mb={7}
      ml={3}
      mr={4}
    >
      <Flex alignItems="center" py={3} px={4} bg="error.4" borderRadius={10}>
        <Icon name="warning" color="error.0" mr={[3, 4]} />
        <Text fontSize={3}>
          Once you have paid for the survey, you will no longer be able to make
          changes. You have until 24 hours before the start date to pay.
        </Text>
      </Flex>
      <Paragraph my={5} fontSize={[4, 5]}>
        Please enter your payment details to purchase the necessary credits for
        your survey. Any unused credits will be stored on your account.
      </Paragraph>
      <BillingFormComponent actions={actions} setActions={setActions} />
    </CreationFormsLayout>
  )
}

export default BillingForm
