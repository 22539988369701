import React from "react"
import { Button, Flex } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"

const StartSurveyButtons = () => {
  const paths = usePaths()

  return (
    <Flex
      width={1 / 1}
      justifyContent="center"
      alignItems="center"
      flexDirection={["column", "row"]}
    >
      <Link to={paths.selfServe.selfServeEngagement}>
        <Button
          width="250px"
          bg="primary.0"
          color="light.0"
          mr={["0px", "8px"]}
          mb={["16px", "0px"]}
        >
          Start Engagement Survey
        </Button>
      </Link>
      <Link to={paths.selfServe.selfServeWellbeing}>
        <Button bg="primary.0" color="light.0" width="250px">
          Start Wellbeing Survey
        </Button>
      </Link>
    </Flex>
  )
}

export default StartSurveyButtons
