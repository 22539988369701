import React from "react"

import { OrgGuard } from "../../features/account"
import MainHeader from "../../features/actionPlans/components/MainHeader"
import EditActionPlanStepContainer from "../../features/actionPlans/containers/EditActionPlanStepContainer"
import DashboardLayout from "../../layouts/DashboardLayout"

const ActionPlanStepEdit = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <MainHeader />
        <EditActionPlanStepContainer />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default ActionPlanStepEdit
