import { Flex, Button } from "@engaging-tech/components"
import React, { useState } from "react"
import ComparisonViewTimePickers from "./ComparisonViewTimePickers"
import SubtypesModalContainer from "../containers/SubtypesModalContainer"

const ComparisonOptions = ({
  checkedList,
  handleGetComparisonData,
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <Flex
        width={[1 / 1, 1 / 5]}
        height={["40vh", "80%"]}
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        mb={[20, 0]}
      >
        <ComparisonViewTimePickers
          handleGetComparisonData={handleGetComparisonData}
          checkedList={checkedList}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <Button
          bg="primary.0"
          color="light.0"
          width="170px"
          onClick={() => setOpenModal(true)}
        >
          Refine results
        </Button>
      </Flex>
      {openModal && (
        <SubtypesModalContainer
          handleGetComparisonData={handleGetComparisonData}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}
    </>
  )
}

export default ComparisonOptions
