import { Flex, H2, Icon, Paragraph, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { Card, TextFieldAndButton } from "../../components"

const SurveyLink = ({ id, onCopy, ...props }) => {
  const link = `${getConfig().workL.app}/organisationSurvey/${id}`
  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    onCopy("Your Survey Link has been copied!")
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Copied survey link"])
    }
  }

  return (
    <Card {...props}>
      <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={5}>
        Your Survey Link
      </H2>
      <Paragraph>
        Your survey link is below. You can test it out until your launch date,
        when this link will go live.
      </Paragraph>
      <TextFieldAndButton buttonText="Copy" value={link} onclick={handleCopy} />
      <Flex
        alignItems="center"
        py={3}
        px={4}
        bg="error.4"
        mt={5}
        borderRadius={10}
      >
        <Icon name="warning" color="error.0" mr={[3, 4]} />
        <Text fontSize={3}>
          Do not send this link to any survey participants before the launch
          date as their scores will not be counted.
        </Text>
      </Flex>
    </Card>
  )
}

export default SurveyLink
