import { Modal } from "@engaging-tech/components"
import { useFormik } from "formik"
import React, { useContext, useEffect } from "react"
import { createPortal } from "react-dom"
import { object, string } from "yup"

import { CREATE_ACTION_PLAN } from "../../../../graphql/mutations"
import useAppSyncMutation from "../../../../hooks/useAppSyncMutation"
import tabs from "../../lib/tabs"
import { ListActionPlansContext } from "../../views/ListActionPlans"
import CreateActionPlanModal from "./CreateActionPlanModal"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const CloneActionPlan = ({ setOpenModal, actionPlanId, setActionPlanId }) => {
  const { selectedTab, setCloneActionPlan } = useContext(ListActionPlansContext)
  const tabName = tabs.filter(tab => tab.type === selectedTab)

  const formik = useFormik({
    initialValues: {
      title: ""
    },
    validationSchema: object().shape({
      title: string().required("Required")
    }),
    validateOnMount: true
  })

  const { post, isLoading, errors, data } = useAppSyncMutation(
    CREATE_ACTION_PLAN,
    {
      successAction: setCloneActionPlan
    }
  )

  const requestStatus = data?.createActionPlan?.status
  const apiErrorCode =
    (errors && typeof errors !== "undefined") ||
    (requestStatus && requestStatus !== "200")
      ? requestStatus
      : null

  const closeModal = () => {
    setOpenModal(false)
    setActionPlanId("")
  }

  const handleCreate = () => {
    post({
      title: formik.values.title,
      planType: selectedTab,
      id: actionPlanId
    })
  }

  useEffect(() => {
    if (requestStatus === "200") {
      closeModal()
    }
  }, [requestStatus])

  return createPortal(
    <Modal
      bg="light.0"
      width="360px"
      p={3}
      onToggle={() => setOpenModal(false)}
    >
      <CreateActionPlanModal
        handleCreate={handleCreate}
        title={`New ${tabName[0].name} Action Plan`}
        errors={formik.errors?.title}
        closeModal={closeModal}
        value={formik.values.title}
        handleChange={formik.handleChange}
        isLoading={isLoading}
        apiErrors={apiErrorCode}
      />
    </Modal>,
    appRoot()
  )
}

export default CloneActionPlan
