import React from "react"
import EBNCard from "../../ui/components/EBNCard"
import Articles from "../containers/Articles"

const ArticlesView = () => (
  <EBNCard title="Articles For You" height="auto" mb={5} isSubscribed>
    <Articles />
  </EBNCard>
)

export default ArticlesView
