import { connect } from "react-redux"
import {
  getIsLoading,
  getReportsToDelete,
  getDeleteError,
  getSurveyType
} from "../../store/report.selectors"
import { deleteReports, loadReports } from "../../store/report.actions"
import ReportsDialogue from "../components/ReportsDialogue"

const mapState = state => ({
  selectedReports: getReportsToDelete(state),
  isLoading: getIsLoading(state),
  Error: getDeleteError(state),
  successActionParameters: { surveyType: getSurveyType(state) },
  yesActionParameters: getReportsToDelete(state)
})

const mapDispatch = dispatch => ({
  yesAction: reportArray =>
    dispatch(deleteReports(reportArray.map(report => report.reportId))),
  successAction: reportSearchFilters =>
    dispatch(loadReports(reportSearchFilters))
})

const DeleteReportsDialogueContainer = connect(
  mapState,
  mapDispatch
)(ReportsDialogue)

export default DeleteReportsDialogueContainer
