import { Flex, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import ListSurveys from "../../ui/components/ListSurveys"
import { IconWrapper } from "./FeatureBox"

const BodyFlex = styled(Flex)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
`

const TabFlex = styled(Flex)`
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: -20px;
  position: relative;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);

  &:before {
    background: white;
    display: block;
    content: ".";
    font-size: 0;
    width: 524px;
    height: 9px;
    position: absolute;
    left: -1px;
    bottom: -9px;
  }
`

const InnerFlex = styled(Flex)`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`

const HoverText = styled(Text)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

const SurveyQuickview = ({ isLoading, surveysError, showLatestSurveys }) => {
  const paths = usePaths()
  const router = useRouter()

  return (
    <Flex flexDirection="column" width={1}>
      <Text
        fontSize={24}
        fontWeight={700}
        lineHeight="30px"
        color="secondary.0"
        mt="32px"
      >
        Look at your current surveys
      </Text>
      <BodyFlex
        bg="light.0"
        width={1}
        mb={3}
        mt={4}
        flexDirection="row"
        flexWrap="wrap"
      >
        <InnerFlex py={2} width={1} bg="dark.4" px={4} alignItems="center">
          <Text color="secondary.0" fontSize={5} fontWeight={600} width={1 / 1}>
            Your latest live and scheduled surveys
          </Text>
          <HoverText
            color="primary.0"
            width="100px"
            textAlign="right"
            fontSize="14px"
            onClick={() => router.navigate(paths.surveys.liveSurveys)}
          >
            View all
          </HoverText>
        </InnerFlex>
        <Flex px={4}>
          <ListSurveys
            isLoading={isLoading}
            surveysError={surveysError}
            surveysToShow={showLatestSurveys}
            minimized
          />
        </Flex>
      </BodyFlex>
    </Flex>
  )
}

export default SurveyQuickview
