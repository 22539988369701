/* eslint-disable import/no-dynamic-require */
import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const logoUrl = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkLBusinessLogo.png`)

const LogoWrapper = styled(Flex)`
  user-select: none;
  position: relative;
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  ${({ props }) => props?.imgProps}
`

const SiteLogo = (props, imgProps) => (
  <LogoWrapper
    alignItems="center"
    justifyContent="flex-start"
    width={250}
    {...props}
  >
    <Logo src={logoUrl} {...imgProps} />
  </LogoWrapper>
)

export default SiteLogo
