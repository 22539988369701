import { useEffect } from "react"
import { connect } from "react-redux"
import { loadAllCountries, loadAllIndustries } from "../store/ui.actions"

const UiLoaderComponent = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])

  return null
}

const mapDispatch = dispatch => ({
  onLoad: () => [dispatch(loadAllCountries()), dispatch(loadAllIndustries())]
})

const UiLoader = connect(null, mapDispatch)(UiLoaderComponent)

export default UiLoader
