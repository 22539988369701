import React, { useContext } from "react"
import { RoundedDropdown } from "@engaging-tech/components"
import TemplateQuestionContext from "../TemplateQuestionContext"
import ShortTextQuestionEdit from "./ShortTextQuestionEdit"
import RadioQuestionEdit from "./RadioQuestionEdit"
import DropdownQuestionEdit from "./DropdownQuestionEdit"
import ScaleQuestionEdit from "./ScaleQuestionEdit"

const EditCoreQuestion = ({ availableCoreQuestions, changeCoreQuestion }) => {
  const { title, id, type } = useContext(TemplateQuestionContext)
  return (
    <>
      <RoundedDropdown
        width="100%"
        height="46px"
        onChange={e => changeCoreQuestion(e.target.value, id)}
      >
        <option defaultValue value={id}>
          {title}
        </option>
        {availableCoreQuestions?.map(question => (
          <option key={question.id} value={question.id}>
            {question.title}
          </option>
        ))}
      </RoundedDropdown>

      {type === "scale" && <ScaleQuestionEdit />}
      {type === "short-text" && <ShortTextQuestionEdit />}
      {type === "radio" && <RadioQuestionEdit />}
      {["other", "dropdown"].includes(type) && <DropdownQuestionEdit />}
    </>
  )
}

export default EditCoreQuestion
