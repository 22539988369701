import {
  CustomHooks,
  Icon,
  MotionFlex,
  Spinner,
  Text
} from "@engaging-tech/components"
// import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
// import PurchaseCreditsModal from "../containers/components/PurchaseCreditsModal"

// Add back in functionality after Awards Survey

const CreditsDisplay = ({ isLoading, amountOfCredits }) => {
  const device = CustomHooks.useClientDevice()
  const [show, setShow] = useState(false)

  return (
    <MotionFlex
      width="auto"
      borderRadius={99}
      px={[3, 4]}
      py={2}
      bg="dark.4"
      alignItems="center"
      mx={[0, 3, 4]}
      mt={[2, 0, 0]}
      // onClick={() => setShow(true)}
      // whileHover={{ scale: 1.02, cursor: "pointer" }}
      onToggle={() => null}
    >
      <Text
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "inherit"
        }}
      >
        {isLoading
          ? [
              "Credits:",
              <Spinner key="credits" ml={2} size={16} color="primary.0" />
            ]
          : `${
              device === "DESKTOP" ? "Amount of credits" : "Credits"
            }: ${amountOfCredits}`}
      </Text>

      <Icon name="fiber_smart_record" ml={3} />
      {/* <AnimatePresence>
        {show && !isLoading && (
          <PurchaseCreditsModal
            credits={amountOfCredits}
            toggle={() => setShow(false)}
          />
        )}
      </AnimatePresence> */}
    </MotionFlex>
  )
}
export default CreditsDisplay
