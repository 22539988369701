import React from "react"
import {
  Text,
  Paragraph,
  CircularProgress,
  Flex
} from "@engaging-tech/components"
import { ScoreLabel, getLabelData } from "../../components/ScoreLabel"
import ReportCard from "../../components/ReportCard"

const DataHighlightsOnePager = ({
  score,
  heading,
  text,
  isLowest = false,
  isPdfDownloading
}) => {
  return (
    <ReportCard
      heading={heading}
      width={["100%"]}
      isPdfDownloading={isPdfDownloading}
    >
      <Flex
        flexDirection={["column"]}
        justifyContent="space-around"
        alignItems="center"
      >
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            percentage={score}
            disableAllAnimation={true}
            width={150}
            strokeColor={getLabelData(score).color}
          />
          {!score && score !== 0 && (
            <Text textAlign="center">No data for this step</Text>
          )}
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <ScoreLabel
            showIcon
            score={score}
            fontSize={5}
            fontWeight={700}
            mt={isLowest ? 40 : 0}
          />
          {isLowest && (
            <Text
              textAlign="center"
              color="#BF4630"
              fontSize={4}
              fontWeight={500}
              mt={3}
            >
              LOWEST SCORE
            </Text>
          )}
        </Flex>
      </Flex>
      <Paragraph
        textAlign="center"
        fontSize={5}
        fontWeight="700"
        color="dark.2"
        mb={3}
        mt={3}
      >
        {text}
      </Paragraph>
    </ReportCard>
  )
}

export default DataHighlightsOnePager
