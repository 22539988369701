import { connect } from "react-redux"
import TemplateOptionsComponent from "../components/TemplateOptions"
import {
  deleteOrgTemplate,
  loadDefaultTemplates,
  loadOrgTemplates
} from "../store/templateSelection.actions"
import {
  getDefaultTemplates,
  getDraftTemplates,
  getHasError,
  getIsLoading,
  getPermementTemplates
} from "../store/templateSelection.selectors"

const loader = dispatch => {
  dispatch(loadOrgTemplates())
  dispatch(loadDefaultTemplates())
}

const mapState = state => ({
  myDraftTemplates: getDraftTemplates(state),
  defaultTemplates: getDefaultTemplates(state),
  myPermanentTemplates: getPermementTemplates(state),
  isLoading: getIsLoading(state),
  hasError: getHasError(state)
})

const mapDispatch = dispatch => ({
  loadAll: () => loader(dispatch),
  deleteTemplate: templateId => dispatch(deleteOrgTemplate(templateId))
})

const TemplateOptions = connect(mapState, mapDispatch)(TemplateOptionsComponent)

export default TemplateOptions
