import { Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const Select = styled.select`
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
  padding: 5px 8px;
  padding-right: 30px;
  border-radius: 5px;
  border-color: #a9a9a9;
  width: 200px;
  height: 36px;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.colors.light[0]};
  color: ${({ theme }) => theme.colors.dark[1]};
`

export function SelectOption({ label, onChange, options, value }) {
  function changeSelect(e) {
    onChange(e.target.value)
  }

  console.log(value)
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text fontSize={3} color="dark.3">
        {label}
      </Text>
      <div style={{ position: "relative", display: "flex" }}>
        <Select value={value} onChange={changeSelect}>
          {options.map(item => (
            <option key={item._id} value={item._id}>
              {item.title}
            </option>
          ))}
        </Select>
        <div style={{ position: "absolute", right: 5, top: 12 }}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5893 13.9226C10.2638 14.248 9.73619 14.248 9.41075 13.9226L4.41076 8.92259C4.08532 8.59715 4.08532 8.06951 4.41076 7.74408C4.73619 7.41864 5.26383 7.41864 5.58927 7.74408L10 12.1548L14.4108 7.74408C14.7362 7.41864 15.2638 7.41864 15.5893 7.74408C15.9147 8.06952 15.9147 8.59715 15.5893 8.92259L10.5893 13.9226Z"
              fill="#E66F3D"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
