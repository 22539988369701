import React from "react"
import styled from "styled-components"
import { H1, Flex, Box, Text, ProgressBar } from "@engaging-tech/components"

const LOGO_DIMENSION = 34

const Dialog = styled(Flex)`
  width: 90%;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.space[7]}px;
  margin-top: ${({ theme }) => theme.space[6]}px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-top: ${({ theme }) => theme.space[6]}px;
    margin-bottom: ${({ theme }) => theme.space[6]}px;
    width: 50%;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    margin-top: ${({ theme }) => theme.space[5]}px;
    margin-bottom: ${({ theme }) => theme.space[5]}px;
    max-width: 560px;
  }
`
const BorderBottomBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
`

/**
 * Primative dialog for account-related UI. This is used with all of the account
 * forms. (sign in, sign up, etc.)
 * @param {React.Component} renderLogo A brand logo to render at the top of the dialog.
 * @param {string} title The title text for the dialog.
 * @param {string} subtitle Optional subtitle text for the dialog.
 * @param {React.Component} children Child components to be rendered as the body of the dialog.
 * @package
 */
const SignUpDialog = ({ renderLogo: Logo, children, pageNum, totalPages }) => (
  <Dialog bg="light.0" borderRadius={4} elevation={12}>
    <Flex flexDirection="column" alignItems="center" width="100%">
      {Logo && (
        <Flex width="auto" mt={5} mb={3}>
          <Logo
            logoWidth={LOGO_DIMENSION}
            logoHeight={LOGO_DIMENSION}
            fontSize={6}
          />
        </Flex>
      )}
      <Flex justifyContent="center" mb={1} alignItems="flex-end">
        <Text
          textAlign="center"
          width="auto"
          fontWeight={500}
          color="primary.0"
          fontSize={5}
        >
          {`0${pageNum}`}
        </Text>
        <Text
          textAlign="center"
          width="auto"
          fontSize={2}
          fontWeight={600}
          color="dark.2"
          mb="2px"
        >
          {`/0${totalPages}`}
        </Text>
      </Flex>
      <ProgressBar
        bg="dark.4"
        color="primary.0"
        progress={(pageNum / totalPages) * 100}
      />
      <BorderBottomBox px={[4, 5]} py={3}>
        <H1 fontSize={5} color="dark.2" fontWeight="700" textAlign="center">
          Sign up for a free account
        </H1>
        <Flex pt={1} pb={3}>
          <Text
            color="dark.2"
            fontSize={[4, 3]}
            textAlign="center"
            width={1 / 1}
          >
            Join our Business Network and access all of our services.
          </Text>
        </Flex>
      </BorderBottomBox>
      {children}
    </Flex>
  </Dialog>
)

export default SignUpDialog
