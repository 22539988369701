import React from "react"
import { Paragraph, BarChart } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import ReportCard from "../../components/ReportCard"

const getWord = (reportTypename, starterTypename, exitTypename) => {
  if (reportTypename === starterTypename) {
    return "New Starter Survey"
  }
  if (reportTypename === exitTypename) {
    return "Exit Survey"
  }
  return "Average Engagement"
}

const getChartWord = (reportTypename, starterTypename, exitTypename) => {
  if (reportTypename === starterTypename) {
    return "New Starter"
  }
  if (reportTypename === exitTypename) {
    return "Exit"
  }
  return "Engagement"
}

const getParagraph = (reportTypename, starterTypename, exitTypename) => {
  if (reportTypename === starterTypename) {
    return "Compare how your companies average New Starter score compares to the Industry and Global average score."
  }
  if (reportTypename === exitTypename) {
    return "Compare how your companies average Exit score compares to the Industry and Global average score."
  }
  return "Compare how your companies average engagement score for this survey compares to the average score for your industry and the average engagement score globally."
}

const CompareAverageHappinessScores = ({
  score,
  industry,
  global,
  rootReport,
  orgName,
  exportingPDF,
  reportTypename
}) => {
  const { starterReportTypename, exitReportTypename } = getConfig().other
  const labelHeadings = ["Your Score", "Global", "Industry"]
  if (rootReport) {
    labelHeadings.push(orgName || "Overall")
  }
  return (
    <ReportCard
      heading={`Compare ${getWord(
        reportTypename,
        starterReportTypename,
        exitReportTypename
      )} Scores`}
      width={[1 / 1, 1 / 1, 59 / 100]}
      exportingPDF={exportingPDF}
    >
      <Paragraph color="dark.2" fontSize={3}>
        {getParagraph(
          reportTypename,
          starterReportTypename,
          exitReportTypename
        )}
      </Paragraph>
      <BarChart
        disableAllAnimation={exportingPDF}
        dataset={{
          labels: labelHeadings,
          datasets: [
            {
              data: [score, global, industry, rootReport]
            }
          ]
        }}
        graphOptions={{
          scales: {
            y: {
              min: 0,
              max: 100
            }
          }
        }}
        mt={3}
        showDataLabels
        height={[250, 350, 350]}
        unit="%"
        yAxisLabel={`Your Average ${getChartWord(
          reportTypename,
          starterReportTypename,
          exitReportTypename
        )} Scores`}
      />
    </ReportCard>
  )
}

export default CompareAverageHappinessScores
