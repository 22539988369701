import { Flex } from "@engaging-tech/components"
import React from "react"

const borderRadius = 13

const getRadiusObject = (i, segmantAmount) => {
  if (i === 0) {
    return {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius
    }
  }
  if (i + 1 === segmantAmount) {
    return {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius
    }
  }
  return {}
}

const generateBarSegments = barStructure => {
  return barStructure.map((s, i) => {
    return (
      <Flex
        width={`${s.percentage}%`}
        bg={s.color}
        height="100%"
        style={getRadiusObject(i, barStructure.length)}
      />
    )
  })
}

const BarShape = ({ barStructure }) => {
  return (
    <Flex width="100%" height={26} justifyContent="start">
      {generateBarSegments(barStructure)}
    </Flex>
  )
}
export default BarShape
