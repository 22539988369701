import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import {
  analyticsSaga,
  thirdPartyReducer,
  thirdPartySaga
} from "@engaging-tech/third-party-cookies"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import createSagaMiddleware from "redux-saga"
import { spawn } from "redux-saga/effects"

import { EBNReducer, EBNSaga } from "./features/EBN"
import { reportReducer, reportSaga } from "./features/Reports"
import { accountReducer, accountSaga } from "./features/account"
import { awardsReducer } from "./features/awards"
import { cmsReducer, cmsSaga } from "./features/cms"
import { selfServeOrgReducer } from "./features/selfServiceSurveys"
import { sdUpgradeModalReducer } from "./features/sliceAndDice/components/SliceAndDiceUpgradeModal/store"
import { sdUpsellReducer } from "./features/sliceAndDice/components/SliceAndDiceUpsell/SliceAndDiceUpsellGetLastSurveyContainer/store"
import * as surveyEditModeReducer from "./features/surveyManagement/survey-edit-mode/store/survey-edit-mode.reducer"
import * as surveyEditModeSaga from "./features/surveyManagement/survey-edit-mode/store/survey-edit-mode.saga"
import {
  surveyViewModeReducer,
  surveyViewModeSaga
} from "./features/surveyManagement/survey-view-mode"
import {
  surveyInfoReducer,
  surveyInfoSaga
} from "./features/surveyManagement/surveyInfo"
import {
  surveyInfoReviewReducer,
  surveyInfoReviewSaga
} from "./features/surveyManagement/surveyInfoReview"
import { surveysStore as surveySelectionSurveysStore } from "./features/surveyManagement/surveySelection"
import { TemplateReducer } from "./features/surveyManagement/template/store/index"
import {
  templateSelectionReducer,
  templateSelectionSaga
} from "./features/surveyManagement/templateSelection"
import {
  activeTemplateStore as templatesActiveTemplateStore,
  allSurveyJobRolesStore as templatesAllSurveyJobRolesStore,
  coreQuestionsStore as templatesCoreQuestionsStore,
  genericStore as templatesGenericStore
} from "./features/surveyManagement/templates"
import { uiReducer, uiSaga } from "./features/ui"
import { actionPlansReducer } from "./store/actionPlans"
import { basketReducer } from "./store/basket"
import { eventsReducer } from "./store/events"
import { jobsReducer } from "./store/jobs"
import { leadsReducer } from "./store/leads"
import { productsReducer } from "./store/products"

const sagaMiddleware = createSagaMiddleware()

const withDevTools =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const appReducer = combineReducers({
  account: accountReducer,
  basket: basketReducer,
  products: productsReducer,
  awards: awardsReducer,
  EBN: EBNReducer,
  events: eventsReducer,
  jobs: jobsReducer,
  leads: leadsReducer,
  surveyReport: reportReducer,
  template: TemplateReducer,
  "surveyManagement.surveyInfo": surveyInfoReducer.reducer,
  "surveyManagement.surveyView": surveyViewModeReducer.reducer,
  "surveyManagement.surveyEdit": surveyEditModeReducer.reducer,
  "surveyManagement.templates.activeTemplate":
    templatesActiveTemplateStore.activeTemplateReducer,
  "surveyManagement.templates.coreQuestions":
    templatesCoreQuestionsStore.coreQuestionsReducer,
  "surveyManagement.templates.allSurveyJobRoles":
    templatesAllSurveyJobRolesStore.allSurveyJobRolesReducer,
  "surveyManagement.templates.generic":
    templatesGenericStore.genericTemplateReducer,
  "surveyManagement.surveySelection.surveys":
    surveySelectionSurveysStore.reducer,
  "surveyManagement.surveyInfoReview": surveyInfoReviewReducer,
  "surveyManagement.templateSelection": templateSelectionReducer,
  thirdParty: thirdPartyReducer,
  ui: uiReducer,
  userInterfaceNotifications: userInterfaceNotificationsStore.reducer,
  cms: cmsReducer,
  "selfServe.organisationSurvey": selfServeOrgReducer,
  sdUpgradeModal: sdUpgradeModalReducer,
  sdUpsell: sdUpsellReducer,
  actionPlans: actionPlansReducer
})

const rootReducer = (state, action) => {
  if (action.type === "[Account] Sign Out Success") {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return appReducer(state, action)
}

const store =
  typeof window !== "undefined"
    ? createStore(rootReducer, withDevTools(applyMiddleware(sagaMiddleware)))
    : createStore(rootReducer, withDevTools(applyMiddleware(sagaMiddleware)))

function* rootSaga() {
  yield spawn(thirdPartySaga)
  yield spawn(analyticsSaga)
  yield spawn(accountSaga)
  yield spawn(templateSelectionSaga)
  yield spawn(templatesCoreQuestionsStore.coreQuestionsSaga)
  yield spawn(uiSaga)
  yield spawn(templatesAllSurveyJobRolesStore.allSurveyJobRolesSaga)
  yield spawn(surveySelectionSurveysStore.saga)
  yield spawn(userInterfaceNotificationsStore.saga)
  yield spawn(EBNSaga)
  yield spawn(reportSaga)
  yield spawn(templatesActiveTemplateStore.activeTemplateSaga)
  yield spawn(surveyInfoSaga.saga)
  yield spawn(surveyViewModeSaga.saga)
  yield spawn(surveyEditModeSaga.saga)
  yield spawn(surveyInfoReviewSaga)
  yield spawn(cmsSaga)
}

sagaMiddleware.run(rootSaga)

export default store
