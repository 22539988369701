import { Button, Flex, Text } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { getSurvey } from "../store/survey-view-mode.selectors"

const NormalisedText = styled(Text)`
  text-transform: initial;
`

export default function BottomNav({ onEdit, onGoBack }) {
  const survey = useSelector(getSurvey)

  return (
    <Flex
      width="100vw"
      height={[100, 70]}
      bg="light.0"
      elevation={3}
      zIndex={99999999}
      position="fixed"
      style={{ bottom: "0px" }}
      alignItems="center"
      justifyContent="center"
      px={5}
      py={[3, 0]}
      flexDirection={["column", "row", "row"]}
    >
      <Flex justifyContent={["center", "unset"]} maxWidth="1000px" width="100%">
        <Button
          variant="secondary.0"
          style={{ minHeight: 32 }}
          disabled={survey?.template.type === "awards"}
          onClick={e => {
            e.preventDefault()
            onEdit()
          }}
          mx={2}
          px={4}
        >
          <NormalisedText color="light.0">Edit Survey</NormalisedText>
        </Button>
        <Button
          variant="secondary.0"
          style={{ minHeight: 32 }}
          disabled={survey?.template.type === "awards"}
          onClick={e => {
            e.preventDefault()
            onGoBack()
          }}
          mx={2}
          px={4}
        >
          <NormalisedText color="light.0">Go Back</NormalisedText>
        </Button>
      </Flex>
    </Flex>
  )
}
