import React from "react"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"

import DashboardLayout from "./DashboardLayout"
import BlankLayout from "./BlankLayout"

const ReportLayout = ({ children, ...props }) => {
  const router = useRouter()
  const { downForMaintenance } = getConfig()
  if (downForMaintenance) {
    router.navigate("/")
  }
  return (
    <DashboardLayout>
      <BlankLayout {...props}>{children}</BlankLayout>
    </DashboardLayout>
  )
}

export default ReportLayout
