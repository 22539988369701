import { gql } from "@apollo/client"

const PUBLISH_BIP = gql`
  mutation(
    $name: String!
    $industryId: ID!
    $websiteURL: String
    $culture: CultureInput
    $businessInformation: BusinessInformationInput
  ) {
    publishBIP(
      name: $name
      industryId: $industryId
      websiteURL: $websiteURL
      culture: $culture
      businessInformation: $businessInformation
    )
  }
`

export default PUBLISH_BIP
