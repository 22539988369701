import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const ButtonText = styled.button`
  width: fit-content;
  text-decoration: underline;
  border: none;
  background: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.dark[3] : theme.colors.secondary[0]};
  margin: ${({ m }) => m};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
`

const StepSuccessMsg = ({ selectedStep, clearModal }) => {
  return (
    <Flex width="350px" height="125px" flexWrap="wrap" justifyContent="center">
      <Text textAlign="center" p={2} fontSize={3} color="secondary.0">
        Question added to Bespoke Step:
      </Text>
      <Text textAlign="center" p={2} fontSize={5} color="secondary.0">
        {selectedStep}
      </Text>
      <ButtonText p={2} onClick={clearModal}>
        Done
      </ButtonText>
    </Flex>
  )
}

export default StepSuccessMsg
