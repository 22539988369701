import { usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { OrgGuard } from "../../features/account"
import CreateEventContainer from "../../features/events/containers/CreateEvent"
import BackBar from "../../features/ui/components/BackBar"
import PageLayout from "../../layouts/PageLayout"
import addParams from "../../utils/addParams"

const PostEvent = () => {
  const paths = usePaths()
  useEffect(() => {
    addParams(false, getConfig().entitlements.events, "type")
  }, [])

  return (
    <OrgGuard>
      <PageLayout
        title="Publish Events"
        banner={() => <BackBar to={paths.home.index} text="Back" visible />}
      >
        <CreateEventContainer />
      </PageLayout>
    </OrgGuard>
  )
}

export default PostEvent
