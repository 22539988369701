import React from "react"

import HeatmapView from "../../features/Reports/views/Heatmap"
import ReportWrapper from "../../features/Reports/views/ReportWrapper"
import { OrgGuard } from "../../features/account"
import BlankLayout from "../../layouts/BlankLayout"

const Heatmap = () => {
  return (
    <OrgGuard>
      <BlankLayout maxWidth="100%" width="100%">
        <ReportWrapper>
          <HeatmapView />
        </ReportWrapper>
      </BlankLayout>
    </OrgGuard>
  )
}

export default Heatmap
