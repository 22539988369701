import React, { useState } from "react"

import UPDATE_SURVEY_ACTION_PLAN from "../../../../../../graphql/mutations/updateSurveyActionPlan"
import useAppSyncMutation from "../../../../../../hooks/useAppSyncMutation"
import { SelectOption } from "./select-option"

export function ActionPlanSelect({
  label,
  options,
  surveyActionPlan,
  surveyId
}) {
  const [actionPlanIdSelected, setActionPlanIdSelected] = useState(
    surveyActionPlan?.id
  )
  const { post } = useAppSyncMutation(UPDATE_SURVEY_ACTION_PLAN)

  async function changeActionPlan(newActionPlanId) {
    setActionPlanIdSelected(newActionPlanId)

    await post({
      input: {
        surveyId,
        action: "assign",
        actionPlanId: newActionPlanId
      }
    })
  }

  return (
    <SelectOption
      label={label}
      onChange={changeActionPlan}
      options={options}
      value={actionPlanIdSelected}
    />
  )
}
