import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../../lib/Apollo"
import { LOAD_SURVEY, SAVE_SURVEY } from "./surveyInfo.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const save = ({ surveyId, survey }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: SAVE_SURVEY,
    variables: {
      surveyId,
      survey
    }
  })

export const load = surveyId =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LOAD_SURVEY,
    variables: { surveyId }
  })
