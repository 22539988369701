export const types = {
  SET_SUB_CHECKED_LIST: "[REPORT] Set Sub Checked List",
  SET_SURVEY_COMPARISON_START_DATE: "[REPORT] Set Survey Comparison Start Date",
  SET_SURVEY_COMPARISON_END_DATE: "[REPORT] Set Survey Comparison End Date",
  SET_COMPARISON_DATASET: "[REPORT] Set Comparison Dataset",
  GET_SURVEY_COMPARISON_DATA_FAIL: "[REPORT] Get Survey Comparison Data Fail",
  GET_SURVEY_COMPARISON_DATA_SUCCESS:
    "[REPORT Get Survey Comparison Data Success",
  GET_SURVEY_COMPARISON_DATA: "[REPORT] Get Survey Comparison Data",
  SET_SURVEY_COMPARISON_STAGE: "[REPORT] Set Survey Comparison Stage",
  SET_SURVEY_COMPARISON_BREADCRUMBS:
    "[REPORT] Set Survey Comparison Breadcrumbs",
  SET_SURVEY_COMPARISON_CHECKED_LIST:
    "[REPORT] Set Survey Comparison Report List",
  SET_HAS_ERROR: "[REPORT] Set Has Error",
  SET_SURVEY_TYPE: "[REPORT] Set Survey Type",
  SET_SEARCH_TERM: "[REPORT] Set Search Term",
  DELETE_REPORTS: "[REPORT] Delete Reports",
  DELETE_REPORTS_SUCCESS: "[REPORT] Delete Reports Success",
  DELETE_REPORTS_FAIL: "[REPORT] Delete Reports Fail",
  LOAD_REPORTS: "[REPORT] Load All Reports",
  SET_FILTERED_REPORTS: "[REPORT] Set Filtered Reports",
  SET_OPEN_MODAL: "[REPORT] Open Modal",
  SET_DELETE_REPORT: "[REPORT] Set Delete Report",
  SET_GENERATE_REPORT: "[REPORT] Set Generate Report",
  LOAD_REPORTS_SUCCESS: "[REPORT] Load All Reports Success",
  LOAD_REPORTS_FAIL: "[REPORT] Load All Reports Fail",
  LOAD: "[REPORT] Load",
  LOAD_SUCCESS: "[REPORT] Load Success",
  LOAD_FAIL: "[REPORT] Load Fail",
  RETRY_LOAD: "[REPORT] Retry Load",
  UPDATE_FILTERS: "[REPORT] Update Filters",
  UPDATE_DIRECT_REPORTS: "[REPORT] Update Direct Reports",
  HANDLE_SURVEY_ERRORS: "[REPORT] Handle Survey Errors",
  SET_REQUIRED_FILTER: "[REPORT] Set Required Filter",
  UPDATE_PDF_DOWNLOADING: "[Report] Update PDF downloading status",
  UPDATE_HEATMAP_PDF_DOWNLOADING: "[HeatMap] Update PDF downloading status",
  UPDATE_PDF_PROGRESS: "[Report] Update PDF creation progress",
  RESET_PDF_PROGRESS: "[Report] Resets PDF progress",
  GENERATE_REPORT: "[REPORT] Generate Reports",
  GENERATE_REPORT_SUCCESS: "[REPORT] Generate Reports Success",
  GENERATE_REPORT_FAIL: "[REPORT] Generate Reports Fail",
  SET_GENERATE_REPORT_TITLE: "[REPORT] Set Generate Reports Title",
  CREATE_LATEST_REPORT: "[REPORT] Creates latest report",
  CREATE_LATEST_REPORT_SUCCESS: "[REPORT] Create report success",
  CREATE_LATEST_REPORT_FAIL: "[REPORT] Create report fail",
  EMPTY_REPORT_LIST: "[REPORT] Empty report list",
  ADD_HEATMAP_FILTER: "[REPORT] Add Heatmap Filter",
  SET_ALL_BUSINESS_AREAS: "[REPORT] Set All Business Areas",
  LOAD_ALL_BUSINESS_AREAS: "[REPORT] Load All Business Areas",
  LOAD_ALL_BUSINESS_AREAS_SUCCESS: "[REPORT] Load All Business Areas Success",
  RETRY_LOAD_ALL_BUSINESS_AREAS: "[REPORT] Retry Load All Business Areas",
  HANDLE_ALL_BUSINESS_AREAS_ERRORS: "[REPORT] Handle All Business Areas Errors",
  LOAD_HEATMAP_FILTER_DATA: "[REPORT] Load Heatmap Filter Data",
  LOAD_HEATMAP_FILTER_DATA_SUCCESS: "[REPORT] Load Heatmap Filter Data Success",
  RETRY_LOAD_HEATMAP_FILTER_DATA: "[REPORT] Retry Load Heatmap Filter Data",
  SET_HEATMAP_FILTER_DATA: "[REPORT] Set Heatmap Filter Data",
  HANDLE_LOAD_HEATMAP_FILTER_DATA_ERRORS:
    "[REPORT] Handle Load Heatmap Filter Data Errors",
  CLEAR_ALL_HEATMAP_FILTERS: "[REPORT] Clear All Heatmap Filters",
  COMBINE_HEATMAP_FILTERS: "[REPORT] Combine Heatmap Filters",
  SET_QUESTION_FILTER: "[REPORT] Set Question Filter",
  SET_HEATMAP_TAB: "[REPORT] Set Heatmap Tab",
  COMPARISON_SUCCESS: "[REPORT] Comparison Success",
  LOAD_COMPARISON: "[REPORT] Load Comparison",
  COMPARISON_FAIL: "[REPORT] Comparison Fail",
  LOAD_HEATMAP_COMPARISON_SUCCESS: "[REPORT] Load Heatmap Comparison Success",
  LOAD_HEATMAP_COMPARISON_FAIL: "[REPORT] Load Heatmap Comparison Fail",
  FULL_HEATMAPS_CLEAR: "[REPORT], Full Heatmaps Clear"
}

export const LoadHeatmapComparisonSuccess = (response, filterId) => ({
  type: types.LOAD_HEATMAP_COMPARISON_SUCCESS,
  payload: { response, filterId }
})

export const LoadHeatmapComparisonFail = () => ({
  type: types.LOAD_HEATMAP_COMPARISON_FAIL
})

export const setSubCheckedList = payload => ({
  type: types.SET_SUB_CHECKED_LIST,
  payload
})

export const setSurveyComparisonEndDate = payload => ({
  type: types.SET_SURVEY_COMPARISON_END_DATE,
  payload
})

export const setSurveyComparisonStartDate = payload => ({
  type: types.SET_SURVEY_COMPARISON_START_DATE,
  payload
})

export const setComparisonDataset = payload => ({
  type: types.SET_COMPARISON_DATASET,
  payload
})

export const getSurveyComparisonDataFail = payload => ({
  type: types.GET_SURVEY_COMPARISON_DATA_FAIL,
  payload
})

export const getSurveyComparisonDataSuccess = payload => ({
  type: types.GET_SURVEY_COMPARISON_DATA_SUCCESS,
  payload
})

export const getSurveyComparisonData = options => ({
  type: types.GET_SURVEY_COMPARISON_DATA,
  payload: options
})

export const setSurveyComparisonCheckedList = list => ({
  type: types.SET_SURVEY_COMPARISON_CHECKED_LIST,
  payload: list
})

export const setSurveyComparisonBreadcrumbs = crumbs => ({
  type: types.SET_SURVEY_COMPARISON_BREADCRUMBS,
  payload: crumbs
})

export const setSurveyComparisonStage = stage => ({
  type: types.SET_SURVEY_COMPARISON_STAGE,
  payload: stage
})

export const setHasError = payload => ({
  type: types.SET_HAS_ERROR,
  payload
})

export const emptyReportList = () => ({
  type: types.EMPTY_REPORT_LIST
})

export const setSurveyType = surveyType => ({
  type: types.SET_SURVEY_TYPE,
  payload: surveyType
})

export const setGenerateReportTitle = title => ({
  type: types.SET_GENERATE_REPORT_TITLE,
  payload: title
})

export const setSearchTerm = term => ({
  type: types.SET_SEARCH_TERM,
  payload: term
})

export const deleteReports = reportsToDelete => ({
  type: types.DELETE_REPORTS,
  payload: reportsToDelete
})

export const deleteReportsSuccess = () => ({
  type: types.DELETE_REPORTS_SUCCESS
})

export const deleteReportsFail = error => ({
  type: types.DELETE_REPORTS_FAIL,
  payload: error
})

export const generateReport = (title, surveyIds) => ({
  type: types.GENERATE_REPORT,
  payload: { title, surveyIds }
})

export const generateReportSuccess = payload => ({
  type: types.GENERATE_REPORT_SUCCESS,
  payload
})

export const generateReportFail = error => ({
  type: types.GENERATE_REPORT_FAIL,
  payload: error
})

export const loadReports = payload => ({
  type: types.LOAD_REPORTS,
  payload
})

export const setDeleteReport = reportArray => ({
  type: types.SET_DELETE_REPORT,
  payload: reportArray
})

export const setGenerateReport = reportArray => ({
  type: types.SET_GENERATE_REPORT,
  payload: reportArray
})

export const setOpenModal = modalState => ({
  type: types.SET_OPEN_MODAL,
  payload: modalState
})

export const setFilteredReports = filteredReports => ({
  type: types.SET_FILTERED_REPORTS,
  payload: filteredReports
})

export const loadReportsSuccess = reports => ({
  type: types.LOAD_REPORTS_SUCCESS,
  payload: reports
})

export const loadReportsFail = () => ({
  type: types.LOAD_REPORTS_FAIL
})
export const addHeatmapFilter = (filter, filters) => {
  return {
    type: types.ADD_HEATMAP_FILTER,
    payload: {
      appliedFilter: filter,
      filters
    }
  }
}
export const setAllBusinessAreas = allBusinessAreas => {
  return {
    type: types.SET_ALL_BUSINESS_AREAS,
    payload: {
      allBusinessAreas
    }
  }
}
export const loadAllBusinessAreas = (surveyId, surveyType, directReports) => ({
  type: types.LOAD_ALL_BUSINESS_AREAS,
  payload: { surveyId, directReports, filters: [], surveyType }
})
export const retryLoadAllBusinessAreas = payload => ({
  type: types.RETRY_LOAD_ALL_BUSINESS_AREAS,
  ...payload
})
export const loadAllBusinessAreasSuccess = payload => ({
  type: types.LOAD_ALL_BUSINESS_AREAS_SUCCESS,
  response: payload
})
export const handleAllBusinessAreasSurveyErrors = payload => ({
  type: types.HANDLE_ALL_BUSINESS_AREAS_ERROR,
  ...payload
})

export const loadFilterData = (
  surveyId,
  surveyType,
  filters,
  directReports,
  filterId
) => ({
  type: types.LOAD_HEATMAP_FILTER_DATA,
  payload: { surveyId, directReports, filters, surveyType, filterId }
})
export const retryloadFilterData = (payload, filterId) => ({
  type: types.RETRY_LOAD_HEATMAP_FILTER_DATA,
  filterId,
  ...payload
})
export const loadFilterDataSuccess = (payload, filterId) => ({
  type: types.LOAD_HEATMAP_FILTER_DATA_SUCCESS,
  response: payload,
  filterId
})
export const handleLoadFilterDataErrors = (payload, filterId) => ({
  type: types.HANDLE_LOAD_HEATMAP_FILTER_DATA_ERRORS,
  filterId,
  ...payload
})
export const clearAllHeatmapFilters = () => ({
  type: types.CLEAR_ALL_HEATMAP_FILTERS
})
export const load = (surveyId, directReports, filters, surveyType) => ({
  type: types.LOAD,
  payload: { surveyId, directReports, filters, surveyType }
})

export const loadComparison = (
  surveyId,
  directReports,
  filters,
  surveyType,
  title
) => ({
  type: types.LOAD_COMPARISON,
  payload: {
    surveyId,
    directReports,
    filters,
    surveyType,
    title,
    comparison: true
  }
})

export const comparisonSuccess = report => ({
  type: types.COMPARISON_SUCCESS,
  payload: report
})

export const comparisonFail = () => ({
  type: types.COMPARISON_FAIL
})

export const loadSuccess = report => ({
  type: types.LOAD_SUCCESS,
  payload: report
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const retryLoad = payload => ({
  type: types.RETRY_LOAD,
  ...payload
})

export const updateFilters = filters => ({
  type: types.UPDATE_FILTERS,
  payload: filters
})

export const handleSurveyErrors = (code, survey) => ({
  type: types.HANDLE_SURVEY_ERRORS,
  payload: { code, survey }
})

export const updateDirectReports = directReports => ({
  type: types.UPDATE_DIRECT_REPORTS,
  payload: directReports
})

export const setRequiredFilter = ({ questionId, valueId }) => ({
  type: types.SET_REQUIRED_FILTER,
  payload: { questionId, valueId }
})

export const updatePDFProgress = payload => ({
  type: types.UPDATE_PDF_PROGRESS,
  payload
})

export const resetPDFProgress = () => ({
  type: types.RESET_PDF_PROGRESS
})

export const updateIsPdfDownloading = downloading => ({
  type: types.UPDATE_PDF_DOWNLOADING,
  payload: downloading
})
export const updateIsHeatMapPdfDownloading = downloading => ({
  type: types.UPDATE_HEATMAP_PDF_DOWNLOADING,
  payload: downloading
})

export const createLatestReport = surveyType => ({
  type: types.CREATE_LATEST_REPORT,
  payload: surveyType
})

export const createLatestReportSuccess = link => ({
  type: types.CREATE_LATEST_REPORT_SUCCESS,
  payload: link
})

export const createLatestReportFail = error => ({
  type: types.CREATE_LATEST_REPORT_FAIL,
  payload: error
})

export const combineHeatmapFilters = (dropdownOptions, filterId) => ({
  type: types.COMBINE_HEATMAP_FILTERS,
  payload: { dropdownOptions, filterId }
})

export const setQuestionFilter = questions => ({
  type: types.SET_QUESTION_FILTER,
  payload: { questions }
})

export const setHeatMapTab = tabKey => ({
  type: types.SET_HEATMAP_TAB,
  payload: tabKey
})

export const fullHeatmapsClear = () => ({
  type: types.FULL_HEATMAPS_CLEAR
})
