import { connect } from "react-redux"
import SurveyIntroCard from "../../../selfServiceSurveys/components/Page2/SurveyIntroCard"
import { getTitle, getIntroduction } from "../../store/awards.selectors"
import {
  setAwardsIntroduction,
  setAwardsTitle,
  setPageErrors
} from "../../store/awards.actions"

const mapState = state => ({
  title: getTitle(state),
  introduction: getIntroduction(state),
  type: "introduction"
})

const mapDispatch = (dispatch, { id }) => ({
  setTitle: title => dispatch(setAwardsTitle(title)),
  setIntroduction: intro => dispatch(setAwardsIntroduction(intro)),
  handleErrors: (errors, type) => dispatch(setPageErrors(errors, type, id))
})

const SurveyIntroCardContainer = connect(mapState, mapDispatch)(SurveyIntroCard)

export default SurveyIntroCardContainer
