import React from "react"
import { Box, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { ConfirmBanner } from "../features/account"
import Nav from "../features/ui/containers/Nav"
import MinifiedNav from "../features/ui/containers/MinifiedNav"
import Footer from "../features/ui/components/Footer"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"
import DownForMaintenance from "../features/ui/components/DownForMaintenance"
import AddedToBasket from "../features/ui/components/AddedToBasket"

const Title = ({ title }) => (
  <Text
    fontSize={[5, 28]}
    fontWeight={700}
    as="h1"
    px={[3, 3, 4]}
    mb={[3, 3, 4]}
  >
    {title}
  </Text>
)

const Heading = ({ heading }) => (
  <Text fontSize={4} fontWeight={400} as="h2" px={[3, 3, 4]} mb={[3, 3, 4]}>
    {heading}
  </Text>
)

const PageLayout = ({
  title,
  heading,
  promotionalBanner: Promotion,
  children,
  banner: Banner,
  bg = "white",
  pt = (!Banner && [3, 4]) || 0,
  ...props
}) => {
  const { downForMaintenance } = getConfig()

  return (
    <>
      <TestingSiteBanner />
      {Promotion && <Promotion />}
      {downForMaintenance ? <MinifiedNav disableAccountRoutes /> : <Nav />}
      <ConfirmBanner />
      {Banner && <Banner />}
      <Box as="main" width="100%" {...props} pb={[3, 4]} pt={pt} bg={bg}>
        {downForMaintenance ? (
          <DownForMaintenance />
        ) : (
          <Box margin="0 auto" width={1 / 1}>
            {title && <Title title={title} />}
            {heading && <Heading heading={heading} />}
            <AddedToBasket />
            {children}
          </Box>
        )}
      </Box>
      <Footer />
    </>
  )
}

export default PageLayout
