

export default [
  {
    id: "scale",
    value: "Linear scale",
    icon: "linear_scale",
    componentType: "scale"
  },
  {
    id: "short-text",
    value: "Short text",
    icon: "short_text",
    componentType: "shortText"
  },
  {
    id: "radio",
    value: "Multiple choice",
    icon: "radio_button_checked",
    componentType: "multipleChoice"
  },
  {
    id: "dropdown",
    value: "Dropdown",
    icon: "arrow_drop_down_circle",
    componentType: "dropdown"
  },
  {
    id: "smiley-face",
    value: "Smileys",
    icon: "smiley",
    componentType: "smileys"
  },
  { id: "other", value: "Other", icon: "live_help", componentType: "other" }
]