/* eslint-disable no-console */
import { connect } from "react-redux"
import BranchingCardComponent from "../../components/Branching/BranchingCard"
import { setBranchingView } from "../../store/template.actions"
import {
  getBranchingQuestions,
  getShowBranching
} from "../../store/template.selectors"

const mapState = state => ({
  showBranching: getShowBranching(state),
  branchingQuestion: getBranchingQuestions(state)
})

const mapDispatch = dispatch => ({
  setShowBranching: payload => dispatch(setBranchingView(payload))
})

const BranchingCard = connect(mapState, mapDispatch)(BranchingCardComponent)

export default BranchingCard
