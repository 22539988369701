import React from "react"
import { OrgGuard } from "../../features/account"
import PageLayout from "../../layouts/PageLayout"
import { PaddedBox } from "../awards/EnterAwards"
import Invoice from "../../features/basket/views/Invoice"

const InvoicePage = () => {
  return (
    <OrgGuard>
      <PageLayout>
        <PaddedBox>
          <Invoice />
        </PaddedBox>
      </PageLayout>
    </OrgGuard>
  )
}

export default InvoicePage
