import { createSelector } from "reselect"

const EBNState = state => state.EBN

export const getShowEBNModal = createSelector(
  EBNState,
  state => state?.showEBNModal
)

export const getRecentLectures = createSelector(
  EBNState,
  state => state?.lectures
)

export const getRecentResearch = createSelector(
  EBNState,
  state => state?.research
)

export const getRecentArticles = createSelector(
  EBNState,
  state => state?.articles
)

export const getAllWebinars = createSelector(EBNState, state => state?.webinars)
export const getLast4Webinars = createSelector(EBNState, state =>
  state?.webinars?.slice(0, 4)
)

const getArticle = (state, id) => {
  const {
    EBN: { articles }
  } = state
  return articles?.find(article => article.id === id)
}

export const getArticleById = createSelector(
  getArticle,
  article => article && article
)

const getLecture = (state, id) => {
  const {
    EBN: { lectures }
  } = state
  return lectures?.find(lecture => lecture.id === id)
}

export const getLectureById = createSelector(
  getLecture,
  lecture => lecture && lecture
)

const getResearch = (state, id) => {
  const {
    EBN: { research }
  } = state
  return research?.find(res => res.id === id)
}

export const getResearchById = createSelector(getResearch, res => res && res)
const getWebinar = (state, id) => {
  const {
    EBN: { webinars }
  } = state
  return webinars?.find(web => web.id === id)
}

export const getWebinarById = createSelector(
  getWebinar,
  webinar => webinar && webinar
)
