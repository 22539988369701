import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import { Tooltip } from "react-tooltip"

import TooltipIcon from "../tooltip-icon"

export default function SurveyDataPackage({ sliceAndDice }) {
  return (
    <Flex flexDirection="column" alignItems="left">
      <Flex>
        <a
          data-tooltip-id="survey-data-package-tooltip"
          data-tooltip-content={
            sliceAndDice
              ? "Enjoy enhanced report insights with your data package"
              : "Upgrade via our Live Surveys dashboard or contact customer care for more info"
          }
        >
          <Text fontSize={3} color="dark.2" pb={2} width="auto">
            Data & Insights package
          </Text>
          <TooltipIcon />
        </a>
        <Tooltip id="survey-data-package-tooltip" />
      </Flex>
      <Text fontSize={6} fontWeight={800} color="dark.2" width="auto">
        {sliceAndDice ? "Active" : "Inactive"}
      </Text>
    </Flex>
  )
}
