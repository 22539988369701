import { Flex, IconButton } from "@engaging-tech/components"
import React from "react"

const SixStepsEditButton = ({ id, title, handleEditClick }) => {
  return (
    <Flex justifyContent="center">
      <IconButton
        icon="edit"
        hoverColor="#B9C5CE"
        id={id}
        className="action-plan-edit"
        iconColor="#173C5A"
        onClick={() => handleEditClick(id, title)}
      />
    </Flex>
  )
}

export default SixStepsEditButton
