import React from "react"
import styled from "styled-components"
import ISO6391 from 'iso-639-1'
import { Box, Text, MobileOnly, Flex, TabletUp, Paragraph } from "@engaging-tech/components"

const BottomBorderedFlex = styled(Flex)`
border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
margin-bottom: ${({ theme }) => theme.space[3]}px;
/* :last-child {
  border-bottom: none;
} */
`

const ItalicText = styled(Text)`
  font-style: italic;
`

const LanguageBreakdown = ({ language, number }) => (
  <BottomBorderedFlex
    key={language}
    width="100%"
    justifyContent="space-between"
  >
    <Paragraph fontSize={3} mb={1} width="calc(100% - 50px)">
      {ISO6391.getName(language)}
    </Paragraph>
    <Text width={50} fontSize={4}>{number < 5 ? '< 5' : number}</Text>
  </BottomBorderedFlex>
)

const LanguageAccordion = ({ languages }) => {
  return (
    <Box p={3}>
      <Text fontSize={5}>Language Analysis</Text>
      <br />
      <ItalicText fontSize={3}>The following languages were detected in your survey.</ItalicText>
      <TabletUp mt={3}>
        <Flex flexDirection={'row'}>
          <Box width={'100%'} mr={2}>
            {languages.slice(0, Math.ceil(languages.length / 2)).map((lang, i) => (
              <LanguageBreakdown
                key={i.toString()}
                language={lang.language}
                number={lang.count}
              />
            ))}
          </Box>
          <Box width={'100%'}>
            {languages.slice(Math.ceil(languages.length / 2), languages.length).map((lang, i) => (
              <LanguageBreakdown
                key={i.toString()}
                language={lang.language}
                number={lang.count}
              />
            ))}
          </Box>
        </Flex>
      </TabletUp>
      <MobileOnly mt={3}>
        {languages.map((lang, i) => (
          <LanguageBreakdown
            key={i.toString()}
            language={lang.language}
            number={lang.count}
          />
        ))}
      </MobileOnly>
    </Box >
  )
}

export default LanguageAccordion