export const types = {
  LOAD_VIEW_SURVEY: "[surveyInfo] Load Survey on View Mode",
  LOAD_VIEW_SURVEY_SUCCESS: "[surveyInfo] Load Survey on View Mode Success",
  LOAD_VIEW_SURVEY_FAIL: "[surveyInfo] Load Survey on View Mode Fail"
}

export const loadSurvey = payload => ({
  type: types.LOAD_VIEW_SURVEY,
  payload
})

export const loadSurveySuccess = payload => ({
  type: types.LOAD_VIEW_SURVEY_SUCCESS,
  payload
})

export const loadSurveyFail = () => ({
  type: types.LOAD_VIEW_SURVEY_FAIL
})
