import { connect } from "react-redux"
import Basket from "../components/Basket"
import {
  getAcceptedCurrencies,
  getBasketErrors,
  getBasketItems,
  getBasketTotals,
  getDisclaimers,
  getIsBasketLoading
} from "../../../store/basket/basket.selectors"
import { getAccountCurrency } from "../../account/store/account.selectors"
import { load } from "../../account/store/account.actions"

const mapState = state => ({
  items: getBasketItems(state),
  basketTotals: getBasketTotals(state),
  acceptedCurrencies: getAcceptedCurrencies(state),
  currency: getAccountCurrency(state),
  isLoading: getIsBasketLoading(state),
  errors: getBasketErrors(state),
  disclaimers: getDisclaimers(state)
})

const mapDispatch = dispatch => ({
  loadOrg: () => dispatch(load())
})

const BasketContainer = connect(mapState, mapDispatch)(Basket)

export default BasketContainer
