import { types } from "./allSurveyJobRoles.actions"

export const initialState = {
  isLoading: false,
  hasError: false,
  jobRoles: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return {
        ...state,
        isLoading: true,
        hasError: false
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        jobRoles: [...action.payload]
      }

    case types.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true
      }

    default:
      return state
  }
}
