import {
  Box,
  Flex,
  MobileOnly,
  TabletUp,
  Text
} from "@engaging-tech/components"
import { Item } from "@engaging-tech/components/dist/lib/List/List"
import React, { Fragment, useState } from "react"
import styled from "styled-components"

import SliceAndDiceCompareModal from "../../../sliceAndDice/components/SliceAndDiceCompareModal"
import CircleIcon from "../../../ui/components/CircleIcon"

const UList = styled.ul`
  margin: 5px 0px;
  padding-left: 20px;
`

const UnderlinedText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`

const List = ({ awardsTitle, openModal }) => {
  const benefits = [
    `Get recognition by ${awardsTitle} for the employee experience you provide`,
    "Retain employees",
    "Attract new talent",
    "Build your brand as a great place to work"
  ]

  function handleClick(e) {
    e.preventDefault()
    openModal()
  }

  return (
    <UList>
      {benefits.map(text => (
        <Fragment key={text}>
          <Item>
            <Text key={text} color="secondary.0" fontSize={[4, 5]}>
              {text}
            </Text>
          </Item>
        </Fragment>
      ))}
      <Item>
        <Text color="secondary.0" fontSize={[4, 5]}>
          Choose between our Standard Package or our enhanced Slice and Dice
          Package. See the upgrade options available{" "}
          <a role="button" href="#" onClick={handleClick}>
            <UnderlinedText color="secondary.0" fontSize={[4, 5]}>
              here
            </UnderlinedText>
          </a>
          .
        </Text>
      </Item>
      {/* <Item>
        <Text color="secondary.0" fontSize={[4, 5]}>
          Receive a complimentary 12 month overall digital subscription for The
          Times and The Sunday Times:
        </Text>
        <Box mt={"2px"}>
          <Text color="secondary.0" fontSize={[4, 5]}>
            - Unrivalled business coverage, with news, analysis and comment on
            UK and international markets to ensure you’re always in the know
          </Text>
          <br />
          <Text color="secondary.0" fontSize={[4, 5]}>
            - Briefing emails and access to the biggest business section across
            all UK newspapers every day
          </Text>
        </Box>
      </Item> */}
    </UList>
  )
}

const WhyToEnter = ({ awardsTitle }) => {
  const [showCompareSDModal, setShowCompareSDModal] = useState(false)

  function openModal() {
    setShowCompareSDModal(true)
  }

  return (
    <>
      <MobileOnly>
        <Flex flexDirection="column" mt="80px">
          <Flex flexDirection="column">
            <Flex>
              <CircleIcon
                name="help"
                bg="primary.0"
                iconColor="light.0"
                mr={2}
                size={30}
              />
              <Text fontWeight={700} fontSize={6} mb={5} color="secondary.0">
                {`Why Should we enter ${awardsTitle}?`}
              </Text>
            </Flex>
            <List awardsTitle={awardsTitle} openModal={openModal} />
          </Flex>
        </Flex>
      </MobileOnly>
      <TabletUp>
        <Flex width="auto" pt={80}>
          <Flex width="40px">
            <CircleIcon
              name="help"
              bg="primary.0"
              iconColor="light.0"
              mr={2}
              size={30}
            />
          </Flex>
          <Flex flexDirection="column">
            <Text
              fontWeight={700}
              fontSize={6}
              mb={5}
              lineHeight="-5px"
              color="secondary.0"
            >
              {`Why Should we enter ${awardsTitle}?`}
            </Text>
            <List awardsTitle={awardsTitle} openModal={openModal} />
          </Flex>
        </Flex>
      </TabletUp>
      {showCompareSDModal && (
        <SliceAndDiceCompareModal
          setShowPreview={setShowCompareSDModal}
          showSurveyLabel={true}
        />
      )}
    </>
  )
}

export default WhyToEnter
