/* eslint-disable react/jsx-curly-newline */
import { Button, Flex } from "@engaging-tech/components"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import SliceAndDiceFeatureCheck from "../../../../helpers/SliceAndDiceFeatureCheck"
import SliceAndDiceUpgradeModal from "../../../sliceAndDice/components/SliceAndDiceUpgradeModal"
import ToggleLogic from "../../../ui/containers/ToggleLogic"
import { getSliceAndDiceReport } from "../../store/report.selectors"
import Filters from "../containers/Filters"

const FiltersButton = ({ activeFilters, availableFilters }) => {
  const check = SliceAndDiceFeatureCheck("filters")
  const isSliceAndDiceMember = useSelector(getSliceAndDiceReport)?.active
  const [showUpgradeSDModal, setShowUpgradeSDModal] = useState(false)

  const toggleUpgradeModal = () => {
    setShowUpgradeSDModal(prevState => !prevState)
  }

  if (
    !availableFilters ||
    (isSliceAndDiceMember && availableFilters.length === 0)
  )
    return null

  return (
    <>
      <ToggleLogic>
        {({ on, toggle, toggleOn }) => (
          <Flex mt={1} width="fit-content">
            <Button
              minWidth={[175, 200]}
              onClick={
                check && !isSliceAndDiceMember ? toggleUpgradeModal : toggleOn
              }
              leadingIcon="filter_list"
              elevation={0}
              color="light.0"
              bg={"primary.0"}
              fontSize={[12, 3]}
              m={["6px"]}
              px={3}
              position="relative"
            >
              {activeFilters && activeFilters.length > 1
                ? "EDIT RESULTS"
                : "FILTER RESULTS"}
            </Button>
            {on && <Filters toggle={toggle} />}
          </Flex>
        )}
      </ToggleLogic>
      {showUpgradeSDModal && (
        <SliceAndDiceUpgradeModal closeModal={toggleUpgradeModal} />
      )}
    </>
  )
}

export default FiltersButton
