const { gql } = require("@apollo/client")

const DOWNLOAD_ASSETS = gql`
  query downloadAssets {
    downloadAssets {
      body
    }
  }
`

export default DOWNLOAD_ASSETS
