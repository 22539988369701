import { Box, Text, Utils } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import { Card, ColourPicker } from "../../components"

const GradientCard = styled(Card)`
  background: linear-gradient(
    122deg,
    ${({ colourPalette }) => `
      ${colourPalette.secondary[1]} 0%,
      ${colourPalette.secondary[0]} 50%
    `}
  );
  border-radius: 10px;
`

const StyledText = styled(Text)`
  text-transform: capitalize;
`

export default function ColourPickerFormField({ values, errors, setFieldValue, readOnly }) {
  const handleColourChange = colour => {
    if (typeof colour === "string") {
      setFieldValue("colour", colour)
    }
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey colour"])
    }
  }

  return (
    <Box mr={[0, 4]} width={[1 / 1, 1 / 2]} mb={[4, 0]}>
      <Text>Brand Colour</Text>
      {values && values.colour && (
        <GradientCard
          height="146px"
          mt={3}
          colourPalette={Utils.createColourPalette(values && values.colour)}
        />
      )}

      <Text color={errors && errors.logo && "errors.0"} fontSize={2} ml={10}>
        *WorkL tip: If a text input is shown, please enter a hex value (e.g. #013e4c).
      </Text>
      <ColourPicker
        value={values && values.colour}
        error={errors && errors.colour}
        mt={4}
        bg="secondary.0"
        borderRadius={4}
        id="colour"
        name="colour"
        onChange={colour => handleColourChange(colour)}
      >
        <StyledText fontSize={3} color="light.0" fontWeight="Bold">
          Change Colour
        </StyledText>
      </ColourPicker>
    </Box>
  )
}
