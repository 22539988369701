import React, { createContext } from "react"

import LIST_ACTION_PLANS from "../../../graphql/queries/listActionPlans"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"
import ErrorState from "../../business-information/components/error-state"
import ListActionPlansTable from "../components/ListActionPlans/ListActionPlansTable"
import ListTabs from "../components/ListActionPlans/ListTabs"

export const ListActionPlansContext = createContext()

const ListActionPlans = ({
  selectedTab,
  setSelecctedTab,
  setActionPlanList,
  actionPlansList,
  page,
  setPage,
  sort,
  setSort,
  showLoadMore,
  setCloneActionPlan
}) => {
  const limit = 10
  const {
    isLoading,
    errors,
    getData: refetchListActionPlans
  } = useAppSyncQuery(LIST_ACTION_PLANS, {
    variables: { planType: selectedTab, limit, page, sort },
    successAction: setActionPlanList,
    reRender: [selectedTab, page, sort]
  })

  const handleTabClick = type => setSelecctedTab(type)

  const contextValues = {
    showLoadMore,
    selectedTab,
    setPage,
    page,
    isLoading,
    handleTabClick,
    actionPlansList,
    setSort,
    setCloneActionPlan,
    refetchListActionPlans
  }

  if (errors) return <ErrorState />

  return (
    <ListActionPlansContext.Provider value={contextValues}>
      <ListTabs />
      <ListActionPlansTable />
    </ListActionPlansContext.Provider>
  )
}

export default ListActionPlans
