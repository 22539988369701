import { usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { OrgGuard } from "../../features/account"
import BackBar from "../../features/ui/components/BackBar"
import PageLayout from "../../layouts/PageLayout"
import LeadsHome from "../../features/leadGeneration/views/LeadsHome"
import addParams from "../../utils/addParams"

const Leads = () => {
  const paths = usePaths()
  useEffect(() => {
    addParams(false, getConfig().entitlements.leads, "type")
  }, [])

  return (
    <OrgGuard>
      <PageLayout
        title="Lead Generation"
        banner={() => <BackBar to={paths.home.index} text="Back" visible />}
      >
        <LeadsHome />
      </PageLayout>
    </OrgGuard>
  )
}
export default Leads
