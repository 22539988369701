import { connect } from "react-redux"
import { getAvailableCoreQuestions } from "../../store/template.selectors"
import EditCoreQuestion from "../../components/EditBody/EditCoreQuestion"
import { changeCoreQuestion } from "../../store/template.actions"

const mapState = state => ({
  availableCoreQuestions: getAvailableCoreQuestions(state)
})

const mapDispatch = dispatch => ({
  changeCoreQuestion: (selectedId, currentId) =>
    dispatch(changeCoreQuestion(selectedId, currentId))
})

const EditCoreQuestionContainer = connect(
  mapState,
  mapDispatch
)(EditCoreQuestion)

export default EditCoreQuestionContainer
