import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

const getLowestStep = createSelector(
  ReportSelectors.getReportData,
  data => data && data.lowestStep
)

export const getScore = createSelector(
  getLowestStep,
  lowestStep => lowestStep && lowestStep.score && lowestStep.score
)

export const getLowestStepName = createSelector(
  getLowestStep,
  lowestStep => lowestStep && lowestStep.step
)
