import { types } from "./cms.actions"

export const intiialState = {
  isLoading: false,
  hasError: false,
  banner: null
}

export const reducer = (state = intiialState, action) => {
  switch (action.type) {
    case types.LOAD:
      return { ...state, isLoading: true }

    case types.LOAD_SUCCESS:
      return { ...state, isLoading: false, banner: action.payload.content }

    case types.LOAD_FAIL:
      return { ...state, isLoading: false, hasError: true }

    default:
      return state
  }
}
