import { connect } from "react-redux"
import BespokeStepHomepage from "../../components/BespokeStep/BespokeStepHomepage"
import {
  getBespokeSteps,
  getTemplateCustomQuestions
} from "../../store/template.selectors"
import {
  setBespokeSteps,
  addStepToQuestion,
  deleteStepFromQuestion,
  setQuestionTitle
} from "../../store/template.actions"

const mapState = state => ({
  bespokeSteps: getBespokeSteps(state),
  templateCustomQuestions: getTemplateCustomQuestions(state)
})

const mapDispatch = dispatch => ({
  setBespokeSteps: steps => dispatch(setBespokeSteps(steps)),
  addStepToQuestion: (step, questionId) =>
    dispatch(addStepToQuestion(step, questionId)),
  deleteStepFromQuestion: (step, questionId) =>
    dispatch(deleteStepFromQuestion(step, questionId)),
  setQuestionTitle: (title, questionId) =>
    dispatch(setQuestionTitle(title, questionId))
})

const HomepageContainer = connect(mapState, mapDispatch)(BespokeStepHomepage)

export default HomepageContainer
