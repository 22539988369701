import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { connect } from "react-redux"

import TitleAndTemplateForm from "../forms/TitleAndTemplate"
import { closeSurvey, loadSurvey, saveSurvey } from "../store/surveyInfo.actions"
import {
  getIntroduction,
  getIsLoading,
  getIsSaving,
  getStatus,
  getTemplate,
  getTitle
} from "../store/surveyInfo.selectors"

const mapDispatch = dispatch => ({
  onSubmit: payload => dispatch(saveSurvey(payload)),
  onLoad: surveyId => dispatch(loadSurvey(surveyId)),
  onClose: () => dispatch(closeSurvey()),
  onNotify: () =>
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "You are not able to edit live or completed surveys."
      })
    )
})

const mapState = state => ({
  initialValues: {
    title: getTitle(state),
    introduction: getIntroduction(state),
    template: getTemplate(state),
    closeSurvey: false
  },
  status: getStatus(state),
  isLoading: getIsLoading(state),
  isSaving: getIsSaving(state)
})

const TitleAndTemplate = connect(mapState, mapDispatch)(TitleAndTemplateForm)

export default TitleAndTemplate
