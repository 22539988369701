import React from "react"

import DashboardLayout from "../../../layouts/DashboardLayout"
import SurveySelectionDashboard from "../../../features/surveyManagement/surveySelection/containers/views/SurveySelectionDashboard"
import { OrgGuard } from "../../../features/account"

const LiveSurveySelectionDashboard = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <SurveySelectionDashboard />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default LiveSurveySelectionDashboard
