import React from "react"
import { TermsAndConditions } from "@engaging-tech/legal"
import { Box } from "@engaging-tech/components"

import { terms, header } from "../../lib/terms"

import PageLayout from "../../layouts/PageLayout"

const Terms = () => (
  <PageLayout>
    <Box pt={[3, 6]} px={[3, 4]} maxWidth={1080} mx="auto">
      <TermsAndConditions header={header} terms={terms} />
    </Box>
  </PageLayout>
)

export default Terms
