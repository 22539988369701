import { Box, Flex, Text } from "@engaging-tech/components"
import { getCountryById, getIndustryById } from "@engaging-tech/frontend-utils"
import { Link, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { useFormikContext } from "formik"
import React from "react"
import ReactPlayer from "react-player"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import ClickableIcon from "../../../ui/components/ClickableIcon"
import FormTitle from "../../../ui/components/FormTitle"
import ValueBadge from "../../../ui/components/ValueBadge"
import BannerImage from "../../components/image-upload-form/banner-image"
import LogoImage from "../../components/image-upload-form/logo-image"
import SectionCard from "../../components/section-card"

const BarBox = styled(Box)`
  padding: ${({ theme }) => theme.space[3]}px 0px;
`

const ReviewVideoListContainer = styled(Flex)`
  gap: 10px;
`

const TextLink = styled.a`
  margin-left: 4px;

  text-decoration: underline;
  color: blue;
`

function ReviewBar({ title, children, onClick }) {
  return (
    <BarBox>
      <Flex>
        <Text color="dark.2" fontSize={2}>
          {title}
        </Text>
        <ClickableIcon name="edit" color="primary.0" onClick={onClick} />
      </Flex>
      {children}
    </BarBox>
  )
}

export default function Review() {
  const history = useHistory()
  const paths = usePaths()

  const { values } = useFormikContext()

  const onEdit = tab => {
    history.push(`${paths.home.businessInformationHub}?activeTab=${tab}`)
  }

  const hasSocialLinks =
    values.social?.linkedIn ||
    values.social?.facebook ||
    values.social?.twitter ||
    values.social?.instagram ||
    values.social?.youtube

  return (
    <>
      <FormTitle text="Please review your BIP. You can change this at any time.">Review</FormTitle>
      <Box bg="dark.7" borderRadius={3} py={3} mb={3}>
        <Flex flexDirection="column">
          <Text fontSize={3} mb={1}>
            WHERE WILL THIS PAGE BE DISPLAYED?
          </Text>
          <Text fontSize={3}>
            <Link color="primary.0" to={getConfig().workL.app} external newTab>
              <Text fontWeight={700} fontSize={14} color="primary.0">
                WorkL
              </Text>
            </Link>{" "}
            users will be able to access this page from the{" "}
            <Link color="primary.0" to={`${getConfig().workL.app}/happiest-companies`} external newTab>
              <Text fontWeight={700} fontSize={14} color="primary.0">
                Happiest Companies
              </Text>
            </Link>{" "}
            ranking table, and also from the{" "}
            <Link color="primary.0" to={`${getConfig().workL.app}/find-work`} external newTab>
              <Text fontWeight={700} fontSize={14} color="primary.0">
                Jobs Marketplace
              </Text>
            </Link>{" "}
            if you have posted any job vacancies.
          </Text>
        </Flex>
      </Box>
      <SectionCard title="Business Information">
        {values?.logoUrl ? (
          <ReviewBar title="LOGO" onClick={() => onEdit("business-information")}>
            <LogoImage imageUrl={values.logoUrl} />
          </ReviewBar>
        ) : null}
        {values?.banner ? (
          <ReviewBar title="BANNER" onClick={() => onEdit("business-information")}>
            <BannerImage imageUrl={values.banner} />
          </ReviewBar>
        ) : null}
        <ReviewBar title="BUSINESS NAME" onClick={() => onEdit("business-information")}>
          <Text color="dark.1">{values.name}</Text>
        </ReviewBar>
        {values?.websiteURL ? (
          <ReviewBar title="WEBSITE" onClick={() => onEdit("business-information")}>
            <Text color="dark.1">{values.websiteURL}</Text>
          </ReviewBar>
        ) : null}
        {values?.contactEmail ? (
          <ReviewBar title="EMAIL" onClick={() => onEdit("business-information")}>
            <Text color="dark.1">{values.contactEmail}</Text>
          </ReviewBar>
        ) : null}
        <ReviewBar title="SHORT STATEMENT" onClick={() => onEdit("business-information")}>
          <Text color="dark.1">{values.culture.shortStatement}</Text>
        </ReviewBar>
        <ReviewBar title="SERVICE" onClick={() => onEdit("business-information")}>
          <Text color="dark.1">{values.services}</Text>
        </ReviewBar>
        <ReviewBar title="SIZE" onClick={() => onEdit("business-information")}>
          <Text color="dark.1">{values.size}</Text>
        </ReviewBar>
        <ReviewBar title="INDUSTRY" onClick={() => onEdit("business-information")}>
          <Text color="dark.1">{getIndustryById(values.industryId)?.name || ""}</Text>
        </ReviewBar>
        <ReviewBar title="FOUNDED" onClick={() => onEdit("business-information")}>
          <Text color="dark.1">{values.foundedAt}</Text>
        </ReviewBar>
        <ReviewBar title="LOCATIONS" onClick={() => onEdit("business-information")}>
          <Flex flexDirection="row" flexWrap="wrap">
            {values?.locations?.length &&
              values?.locations?.map((location, index) => (
                <ValueBadge key={index}>
                  <Text color="dark.2" width="auto" fontSize={3}>
                    {location.city}, {getCountryById(location?.countryId)?.name}
                  </Text>
                </ValueBadge>
              ))}
          </Flex>
        </ReviewBar>
        {hasSocialLinks ? (
          <ReviewBar title="SOCIAL MEDIA PROFILES" onClick={() => onEdit("business-information")}>
            <Flex flexDirection="column">
              {values.social?.linkedIn ? (
                <Text>
                  LinkedIn:
                  <TextLink href={values.social.linkedIn} target="_blank" rel="noreferrer">
                    {values.social.linkedIn}
                  </TextLink>
                </Text>
              ) : null}
              {values.social?.facebook ? (
                <Text>
                  Facebook:
                  <TextLink href={values.social.facebook} target="_blank" rel="noreferrer">
                    {values.social.facebook}
                  </TextLink>
                </Text>
              ) : null}
              {values.social?.twitter ? (
                <Text>
                  Twitter/X:
                  <TextLink href={values.social.twitter} target="_blank" rel="noreferrer">
                    {values.social.twitter}
                  </TextLink>
                </Text>
              ) : null}
              {values.social?.instagram ? (
                <Text>
                  Instagram:
                  <TextLink href={values.social.instagram} target="_blank" rel="noreferrer">
                    {values.social.instagram}
                  </TextLink>
                </Text>
              ) : null}
              {values.social?.youtube ? (
                <Text>
                  Youtube:
                  <TextLink href={values.social.youtube} target="_blank" rel="noreferrer">
                    {values.social.youtube}
                  </TextLink>
                </Text>
              ) : null}
            </Flex>
          </ReviewBar>
        ) : null}
      </SectionCard>
      <SectionCard title="Your Culture">
        <ReviewBar title="ABOUT YOUR ORGANISATION" onClick={() => onEdit("your-culture")}>
          <Text color="dark.1">{values.culture.statement}</Text>
        </ReviewBar>
        {values.videos?.length ? (
          <ReviewBar title="VIDEO LINKS" onClick={() => onEdit("your-culture")}>
            <ReviewVideoListContainer flexDirection="column">
              {values.videos?.map((link, index) => (
                <ReactPlayer key={index} url={link} light width="600px" height="300px" />
              ))}
            </ReviewVideoListContainer>
          </ReviewBar>
        ) : null}
      </SectionCard>
      {values.awardsLogoList?.length ? (
        <SectionCard title="Awards">
          <ReviewBar title="OTHER AWARDS WINS" onClick={() => onEdit("your-culture")}>
            {values.awardsLogoList?.map((image, index) => (
              <BannerImage key={index} imageUrl={image.link} />
            ))}
          </ReviewBar>
        </SectionCard>
      ) : null}
    </>
  )
}
