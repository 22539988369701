import { connect } from "react-redux"
import * as DataHighlightSelectors from "../store/datahighlights.selectors"
import IndicatorComponent from "../components/Indicator"
import FlightRiskLogo from "../../reportLogos/FlightRiskLogo"

const mapState = state => ({
  averageScoreData: DataHighlightSelectors.getIndicatorAverageScores(
    state,
    "flightRisk"
  ),
  mainScore: DataHighlightSelectors.getFlightRiskMainScore(state),
  labelScore: 100 - DataHighlightSelectors.getFlightRiskMainScore(state),
  heading: "Flight Risk",
  text: "of employees have a high chance of leaving in the next 9 months",
  logo: FlightRiskLogo,
  breakDown: "flightRisk"
})

const FlightRiskIndicator = connect(mapState)(IndicatorComponent)

export default FlightRiskIndicator
