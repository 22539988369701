import {
  Flex,
  ImageCropModal,
  Text,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import axios from "axios"
import { useFormikContext } from "formik"
import { AnimatePresence } from "framer-motion"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { UPLOAD_FILE } from "../../../../graphql/mutations"
import useAppSyncMutation from "../../../../hooks/useAppSyncMutation"
import SectionCard from "../section-card"
import BannerImage from "./banner-image"
import FilePickerNew from "./file-picker-new"
import LogoImage from "./logo-image"

/**
 *
 * @param {String} id
 * @param {String} title
 * @param {String} subTitle
 * @param {('logo'|'banner')} icon
 */
export default function ImageUploadForm({
  id,
  title,
  subTitle,
  icon,
  orgId,
  disabled,
  onSubmit
}) {
  const { errors, values, setFieldValue } = useFormikContext()

  const { data: uploadedImageData, post: uploadImage } =
    useAppSyncMutation(UPLOAD_FILE)

  const dispatch = useDispatch()

  const [fileDataTemp, setFileDataTemp] = useState(null)
  const [fileDataFinal, setFileDataFinal] = useState(null)
  const [imageToCrop, setImageToCrop] = useState(null)
  const [localImageURL, setLocalImageUrl] = useState(null)
  const [imageUploadIsLoading, setImageUploadIsLoading] = useState(false)

  useEffect(() => {
    if (values[id]) {
      setLocalImageUrl(values[id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (fileDataFinal) {
      uploadImage({
        fileName: `${Date.now()}_${fileDataFinal.name}`,
        service: id === "logoUrl" ? "PROFILE_PIC" : "BANNER",
        uniqueId: orgId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDataFinal])

  // upload image
  const onUploadFile = useCallback(
    async (url, fileKey) => {
      setImageUploadIsLoading(true)
      try {
        const res = await axios({
          method: "put",
          url,
          data: fileDataFinal,
          headers: {
            "Content-Type": "binary/octet-stream"
          }
        })

        if (res.status === 200) {
          setFileDataTemp(null)
          setFileDataFinal(null)
          setImageToCrop(null)
          setLocalImageUrl(null)

          setFieldValue(id, fileKey)
          onSubmit(values)
          dispatch(
            userInterfaceNotificationsStore.actions.addNotification({
              message: `${
                id === "logoUrl" ? "Logo" : "Banner"
              } image uploaded successfully`
            })
          )
          setImageUploadIsLoading(false)
        }
      } catch (error) {
        console.error(error)
        dispatch(
          userInterfaceNotificationsStore.actions.addNotification({
            message: `Error uploading ${
              id === "logoUrl" ? "Logo" : "Banner"
            } uploading image`
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileDataFinal]
  )

  useEffect(() => {
    if (uploadedImageData) {
      onUploadFile(
        uploadedImageData.uploadFile.url,
        uploadedImageData.uploadFile.key
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImageData])

  return (
    <>
      <SectionCard title={title}>
        {/* subtitle */}
        <Text color="dark.2" fontSize={3}>
          {subTitle}
        </Text>
        {/* image preview | upload image button */}
        <Flex flexDirection="row" alignItems="center" mt={3}>
          {icon === "banner" ? (
            <BannerImage
              imageUrl={localImageURL}
              imageUploadIsLoading={imageUploadIsLoading}
            />
          ) : (
            <LogoImage
              imageUrl={localImageURL}
              imageUploadIsLoading={imageUploadIsLoading}
            />
          )}
          <FilePickerNew
            id={id}
            name={id}
            accept="image/png, image/jpeg, image/jpg"
            imageUrl={localImageURL}
            disabled={disabled}
            error={errors && errors[id]}
            imageUploadIsLoading={imageUploadIsLoading}
            setFileDataTemp={setFileDataTemp}
            setLocalImageUrl={setLocalImageUrl}
            setImageToCrop={setImageToCrop}
            onRemove={() => {
              setLocalImageUrl(null)
              setFieldValue(id, "")
            }}
          />
        </Flex>
      </SectionCard>
      <AnimatePresence>
        {imageToCrop && (
          <ImageCropModal
            cancelCrop={() => setImageToCrop(null)}
            imageToCrop={imageToCrop}
            aspectRatio={icon === "logo" ? 1 : 4 / 1}
            saveCroppedImage={imageToSave => {
              if (imageToSave) {
                setFileDataFinal(
                  new File([imageToSave], fileDataTemp.name, {
                    type: fileDataTemp.type
                  })
                )
                setImageToCrop(null)
              } else {
                setLocalImageUrl(null)
              }
            }}
            imageName={`image-${id}`}
          />
        )}
      </AnimatePresence>
    </>
  )
}
