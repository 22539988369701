/* eslint-disable import/prefer-default-export */
import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  logoUrl: Yup.string(),
  banner: Yup.string(),
  name: Yup.string().required("Required").max(50, "Business Name must be less than 50 characters"),
  websiteURL: Yup.string()
    .url("Business Website must be a valid URL (including 'https://' or 'http://')")
    .optional(),
  contactEmail: Yup.string().email("Contact email must be a valid email").optional(),
  culture: Yup.object().shape({
    statement: Yup.string().max(1000, "Mission Statement must be less than 1000 characters"),
    shortStatement: Yup.string().max(130, "Short Statement must be less than 130 characters")
  }),
  services: Yup.string(),
  size: Yup.string().required("Required"),
  industryId: Yup.string(),
  foundedAt: Yup.number(),
  locations: Yup.array()
    .min(1, "At least one location is required")
    .required("Required")
    .of(
      Yup.object().shape({
        countryId: Yup.string(),
        city: Yup.string()
      })
    ),
  social: Yup.object().shape({
    twitter: Yup.string()
      .url("Twitter Link must be a valid URL (including 'https://' or 'http://')")
      .test(
        "contains-twitter",
        "URL must contain 'twitter' or 'x'",
        value => !value || value.includes("twitter.") || value.includes("x.")
      )
      .optional(),
    linkedIn: Yup.string()
      .url("LinkedIn Link must be a valid URL (including 'https://' or 'http://')")
      .test(
        "contains-linkedin",
        "URL must contain 'linkedin'",
        value => !value || value.includes("linkedin.")
      )
      .optional(),
    pinterest: Yup.string()
      .url("Pinterest Link must be a valid URL (including 'https://' or 'http://')")
      .test(
        "contains-pinterest",
        "URL must contain 'pinterest'",
        value => !value || value.includes("pinterest.")
      )
      .optional(),
    facebook: Yup.string()
      .url("Facebook Link must be a valid URL (including 'https://' or 'http://')")
      .test(
        "contains-facebook",
        "URL must contain 'facebook'",
        value => !value || value.includes("facebook.")
      )
      .optional(),
    instagram: Yup.string()
      .url("Instagram Link must be a valid URL (including 'https://' or 'http://')")
      .test(
        "contains-instagram",
        "URL must contain 'instagram'",
        value => !value || value.includes("instagram.")
      )
      .optional(),
    youtube: Yup.string()
      .url("Youtube Link must be a valid URL (including 'https://' or 'http://')")
      .test(
        "contains-youtube",
        "URL must contain 'youtube.com/channel/' or 'youtube.com/user/'",
        value => !value || value.includes("youtube.com/channel/") || value.includes("youtube.com/user/")
      )
      .optional()
  }),
  awardsLogo: Yup.array().of(Yup.string().nullable()),
  videoLinkTemp: Yup.string()
    .url("Video URL must be a valid URL (including 'https://' or 'http://')")
    .test(
      "contains-youtube-or-vimeo",
      "URL must contain 'youtube' or 'vimeo'",
      value =>
        !value ||
        value.includes("youtube.com/watch") ||
        value.includes("youtu.be/") ||
        value.includes("vimeo.com/") ||
        value.includes(".vimeopro.com/") ||
        value.includes("player.vimeo.com/video/")
    ),
  videos: Yup.array().of(Yup.string())
})
