import React from "react"
import EBNCard from "../../ui/components/EBNCard"
import Resources from "../components/Resources"
import research from "../../../lib/research"

const ResourcesView = () => (
  <EBNCard title="WorkL for Business Reports" mb={5} height={400} isSubscribed>
    <Resources resources={research} />
  </EBNCard>
)

export default ResourcesView
