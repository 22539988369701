/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { Flex, Icon, Text } from "@engaging-tech/components"
import styled from "styled-components"
import customHooks from "@engaging-tech/components/dist/customHooks"
import { useDispatch, useSelector } from "react-redux"
import { getAddedToBasket } from "../../../store/basket/basket.selectors"
import { setAddedToBasket } from "../../../store/basket/basket.actions"

const CustomFlex = styled(Flex)`
  position: fixed;
  top: 20px;
  right: ${({ mobile }) => (mobile ? "50%" : "20px")};
  border: ${({ theme }) => `3px solid ${theme.colors.secondary[0]}`};
  margin-right: ${({ mobile }) => (mobile ? "-150px" : "auto")};
  width: ${({ visibility }) => (visibility ? "300px" : "0px")};
  overflow: hidden;
  height: 90px;
  border-radius: 10px;
  z-index: 999999;
  visibility: ${({ visibility }) => (visibility ? "visible" : "hidden")};
  transition: width 0.2s;
`

const HiddenText = styled(Text)`
  overflow: hidden;
`

const AddedToBasket = () => {
  const dispatch = useDispatch()
  const addedToBasket = useSelector(getAddedToBasket)
  const device = customHooks.useClientDevice()

  useEffect(() => {
    const timeout = setTimeout(() => dispatch(setAddedToBasket(null)), 2000)
    return () => clearTimeout(timeout)
  }, [addedToBasket])

  return (
    <CustomFlex
      py={2}
      px={3}
      alignItems="center"
      bg="light.0"
      mobile={device === "MOBILE"}
      visibility={addedToBasket}
    >
      <Icon
        name="shopping_cart"
        size="35"
        mr={3}
        color="primary.0"
        overflow="hidden"
      />
      <HiddenText color="secondary.0">
        <b>{addedToBasket}</b> has been added to your basket!
      </HiddenText>
    </CustomFlex>
  )
}

export default AddedToBasket
