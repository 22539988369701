import { usePaths, useRouter } from "@engaging-tech/routing"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { array, bool, date, object, ref, string } from "yup"

import { LoadingState } from "../../components"
import DatesAndBrandingView from "../views/DatesAndBranding"

const DatesAndBrandingComponent = ({
  handleSubmit,
  handleChange,
  validateForm,
  values,
  errors,
  onClose,
  surveyId,
  readOnly,
  template,
  setFieldValue,
  onLoad,
  ...props
}) => {
  const router = useRouter()
  const paths = usePaths()

  const handleFormSubmit = async route => {
    const res = await validateForm()
    if (Object.keys(res).length === 0) {
      setFieldValue("onSaveSuccess", () => router.navigate(route))
      handleSubmit()
    } else {
      console.log(res)
    }
  }

  const actions = {
    prev: {
      onClick: () =>
        readOnly
          ? router.navigate(paths.surveys.viewSurvey.titleAndTemplate.replace(":id", surveyId))
          : handleFormSubmit(paths.surveys.surveyCreation.titleAndTemplate)
    },
    next: {
      onClick: () =>
        readOnly
          ? router.navigate(paths.surveys.viewSurvey.participants.replace(":id", surveyId))
          : handleFormSubmit(paths.surveys.surveyCreation.participants)
    },
    saveAndClose: {
      onClick: () => {
        setFieldValue("closeSurvey", true)
        handleFormSubmit(paths.surveys.draftSurveys)
      }
    },
    close: {
      onClick: () =>
        onClose() && router.navigate(readOnly ? paths.surveys.liveSurveys : paths.surveys.draftSurveys)
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <DatesAndBrandingView
        handleChange={handleChange}
        values={values}
        surveyId={surveyId}
        errors={errors}
        readOnly={readOnly}
        template={template}
        setFieldValue={setFieldValue}
        {...actions}
        {...props}
      />
    </form>
  )
}

const validationSchema = object().shape({
  startDate: date()
    .min(new Date(new Date().setHours(24, 0, 0, 0)), "The start date can not be in the past or today")
    .required("Please enter a start date"),
  endDate: date()
    .min(ref("startDate"), "The end date must be after the start date")
    .required("Please enter an end date"),
  logo: string() || object(),
  colour: string().trim().required("Please enter a colour to use (must be a hex colour)"),
  private: bool().required("Please select either 'Yes' or 'No'"),
  frequency: string(),
  frequencyEndDate: date().min(new Date()).nullable(),
  resultsEmails: array().min(0),
  email: string().email("Please enter a valid email.")
})

const DatesAndBrandingForm = ({
  onSubmit,
  onClose,
  initialValues,
  meta,
  surveyId,
  readOnly,
  isLoading,
  template,
  isSaving,
  onLoad
}) => {
  const router = useRouter()
  const paths = usePaths()
  const [hasLoaded, setHasLoaded] = useState(false)

  // load survey data once, to avoid multiple requests
  useEffect(() => {
    setHasLoaded(true)
  }, [])

  const load = async () => {
    await onLoad(surveyId)
  }

  if (!hasLoaded) load()

  useEffect(() => {
    if (!surveyId) {
      router.redirect(paths.surveys.index)
    }
  }, [paths.surveys.index, router, surveyId])

  if (isSaving) return <LoadingState message="Updating survey meta data..." />

  if (!hasLoaded || isLoading) return <LoadingState message="Loading new meta data..." />

  const handleSubmit = values =>
    onSubmit({
      meta: {
        ...meta,
        startDate: values.startDate,
        endDate: values.endDate,
        private: values.private,
        frequencySettings: {
          frequency: values.frequency,
          endDate: values.frequencyEndDate
        },
        resultsEmails: values.resultsEmails
      },
      brand: {
        colour: values.colour
      },
      logo: values.logo,
      closeSurvey: values.closeSurvey,
      onSaveSuccess: values.onSaveSuccess
    })

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={readOnly ? {} : validationSchema}
    >
      {props => (
        <DatesAndBrandingComponent
          onClose={onClose}
          isLoading={isLoading}
          surveyId={surveyId}
          readOnly={readOnly}
          template={template}
          onLoad={onLoad}
          {...props}
        />
      )}
    </Formik>
  )
}

export default DatesAndBrandingForm
