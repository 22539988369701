import React, { useState } from "react"
import { Button, Card, Icon, Text, Flex } from "@engaging-tech/components"
import styled, { css } from "styled-components"
import SliceAndDiceCard from "./SliceAndDiceCard"

const StyledButton = styled(Button)`
  text-transform: none;
`
const blurred = css`
  filter: blur(5px);
`

const StyledCard = styled(Card)`
  border-radius: 8px;

  ${({ isBlurred }) => isBlurred && blurred};
`

const LockedOverlay = ({
  width,
  handleUnlock,
  unlockText,
  height = "100%",
  BIP,
  bg,
  title,
  showSlicePopup = false
}) => {
  const [openCard, setOpenCard] = useState(false)

  return (
    <>
      <StyledCard
        bg={bg || "secondary.0"}
        width={width}
        elevation={0}
        height={height}
        px={[3, 4, 4]}
        py={BIP ? "0" : "3"}
        justifyContent="center"
        flexDirection={BIP ? "row" : "column"}
        alignItems="center"
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px"
        }}
      >
        {title && (
          <Flex width="fit-content" mb={3}>
            <Text color="light.0" fontSize={6}>
              {title}
            </Text>
          </Flex>
        )}
        <Icon
          name="lock"
          color="white"
          size={BIP ? "36" : "73"}
          mb={BIP ? "0" : "5"}
          mr={BIP ? "2" : "0"}
        />

        <Flex
          onMouseEnter={() => showSlicePopup && setOpenCard(true)}
          onMouseLeave={() => setOpenCard(false)}
          width="fit-content"
          position="relative"
        >
          <Button
            onClick={handleUnlock}
            color="black"
            fontWeight={700}
            width={200}
            fontSize={BIP ? "2" : "3"}
            mx={BIP ? "1" : "3"}
          >
            {unlockText}
          </Button>
          {openCard && <SliceAndDiceCard />}
        </Flex>
      </StyledCard>
    </>
  )
}

const EBNCard = ({
  width = "100%",
  height = "280px",
  title,
  mb = 3,
  justifyContent = "flex-start",
  isSubscribed,
  unlockText = "Unlock",
  handleUnlock,
  showSeeAll,
  seeAllAction,
  BIP,
  children,
  bg,
  showSlicePopup,
  props
}) => {
  return (
    <>
      <StyledCard
        bg="white"
        width={width}
        elevation={0}
        height={height}
        px={[3, 4, 4]}
        py={BIP ? "0" : "3"}
        mb={mb}
        justifyContent={justifyContent}
        style={{ position: "relative" }}
        {...props}
      >
        <StyledCard
          width="100%"
          elevation={0}
          height="100%"
          isBlurred={!isSubscribed}
        >
          {(title || showSeeAll) && (
            <Flex
              justifyContent="space-between"
              width={1 / 1}
              flexDirection="row"
              alignItems="center"
              pb={3}
            >
              {title && (
                <Text fontWeight={700} fontSize={5} width="auto">
                  {title}
                </Text>
              )}
              {showSeeAll && (
                <StyledButton
                  fontSize={[4, 5, 5]}
                  width="auto"
                  onClick={seeAllAction}
                  elevation={0}
                  variant="text.dark.0"
                >
                  See All
                </StyledButton>
              )}
            </Flex>
          )}
          {children}
        </StyledCard>
        {!isSubscribed && (
          <LockedOverlay
            width={1 / 1}
            handleUnlock={handleUnlock}
            unlockText={unlockText}
            BIP={BIP}
            bg={bg}
            title={title}
            showSlicePopup={showSlicePopup}
          />
        )}
      </StyledCard>
    </>
  )
}

export default EBNCard
