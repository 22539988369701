import { Button, Flex } from "@engaging-tech/components"
import { useParams, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import SliceAndDiceFeatureCheck from "../../../../helpers/SliceAndDiceFeatureCheck"
import SliceAndDiceUpgradeModal from "../../../sliceAndDice/components/SliceAndDiceUpgradeModal"
import UpgradedLabel from "../../../ui/components/UpgradedLabel"
import { getSliceAndDiceReport } from "../../store/report.selectors"
import SixStepsReportCard from "./six-steps-report-card"
import StepChart from "./step-chart"

const ResponsiveGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const HeatmapsTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[1]};
  margin: 0;
`

const HeatmapsSubtitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[2]};
  margin: 0;
`

const HeatmapsButton = styled(Button)`
  padding: 10px 24px;

  font-size: 20px;
  font-weight: 700;
  line-height: 20px;

  background-color: #e66f3d;
  color: ${({ theme }) => theme.colors.light[0]};

  border-radius: 5px;
`

const SixSteps = ({ componentTags, exportingPDF, sixSteps, tag }) => {
  const router = useRouter()
  const [showUpgradeSDModal, setShowUpgradeSDModal] = useState(false)
  const { id, surveyType } = useParams()
  const isSliceAndDiceMember = useSelector(getSliceAndDiceReport)?.active
  const upgradedReport = new URLSearchParams(window.location.search).get(
    "upgraded"
  )
  const check = SliceAndDiceFeatureCheck("heatmaps")
  const inactiveSliceAndDice = !isSliceAndDiceMember && check

  const toggleUpgradeModal = () => {
    setShowUpgradeSDModal(prevState => !prevState)
  }

  return (
    <>
      <SixStepsReportCard
        title="Six Steps to Workplace Engagement Scores"
        subtitle="The Six Steps are the six categories you need address and work on to engage your staff and improve workplace engagement."
        exportingPDF={exportingPDF}
      >
        <ResponsiveGrid>
          {sixSteps &&
            sixSteps.map((step, index) => (
              <StepChart
                key={step.name}
                exportingPDF={exportingPDF}
                stepIndex={index + 1}
                section={step.info.sectionTitle}
                score={step.score}
                tag={tag}
                name={step.name}
                reportId={id}
              />
            ))}
        </ResponsiveGrid>
        {componentTags.includes("heatmaps") && (
          <Flex mt="50px">
            {inactiveSliceAndDice ? (
              <Button
                variant={"text.secondary.0"}
                color={"secondary.0"}
                trailingIcon="chevron_right"
                elevation={0}
                disabled={false}
                onClick={toggleUpgradeModal}
              >
                VIEW HEATMAP
              </Button>
            ) : (
              <Flex flexDirection="column">
                <HeatmapsTitle>Heatmaps</HeatmapsTitle>
                <HeatmapsSubtitle>
                  Produce visual comparisons and granular breakdowns of your
                  data
                </HeatmapsSubtitle>
                <Flex justifyContent="center" mt="30px">
                  <HeatmapsButton
                    onClick={() =>
                      router.navigate(
                        `/reports/${surveyType}/${id}/heat-map?tag=${tag}`
                      )
                    }
                  >
                    VIEW HEATMAPS
                  </HeatmapsButton>
                  {upgradedReport && <UpgradedLabel />}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </SixStepsReportCard>
      {showUpgradeSDModal && (
        <SliceAndDiceUpgradeModal closeModal={toggleUpgradeModal} />
      )}
    </>
  )
}

export default SixSteps
