/* eslint-disable import/no-cycle */
import { useFormik } from "formik"
import React from "react"
import styled from "styled-components"

import SiteLogo from "../../../ui/components/SiteLogo"
import SignUpDialog from "../../components/SignUpDialog"
import { initialValues, validationSchema } from "../../lib/signUpForm"
import NameForm from "../../components/NameForm"
import EmailForm from "../../components/EmailForm"
import BusinessDetailsForm from "../../components/BusinessDetailsForm"
import { SignUpNavContext } from "."

const StyledForm = styled.form`
  width: 100%;
`

const SignUpFormContent = ({ currentPage, desiredLocation }) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isValid
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true
  })

  return (
    <SignUpDialog renderLogo={SiteLogo} pageNum={currentPage} totalPages={3}>
      <StyledForm onSubmit={() => {}}>
        {currentPage === 1 && (
          <NameForm
            context={SignUpNavContext}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
          />
        )}
        {currentPage === 2 && (
          <EmailForm
            context={SignUpNavContext}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
          />
        )}
        {currentPage === 3 && (
          <BusinessDetailsForm
            context={SignUpNavContext}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            isValid={isValid}
            onSuccessRedirectPath={desiredLocation}
          />
        )}
      </StyledForm>
    </SignUpDialog>
  )
}

export default SignUpFormContent
