import { Flex, Text } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { surveyTypes } from "../../../../lib/surveyTypes"

const Breadcrumbs = ({ breadcrumbs, stage, setBreadcrumbs, checkedList }) => {
  useEffect(() => {
    const newBreadcrumbs = checkedList
      ?.map(id => {
        return surveyTypes[id].label
      })
      .join(", ")
    setBreadcrumbs([...breadcrumbs, newBreadcrumbs])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage])

  return (
    <Flex flexDirection={["column", "row"]} width={1 / 1} mb={20}>
      {breadcrumbs.map(entry => (
        <Text
          color="primary.0"
          fontWeight="600"
          width="fit-content"
          mr={5}
          mb={[10, 0]}
        >
          {entry}
        </Text>
      ))}
    </Flex>
  )
}

export default Breadcrumbs
