import React from "react"
import { Flex, Text } from "@engaging-tech/components"

const IndustryAndGlobalScoreBox = ({
  globalScore,
  industryScore,
  addPlusSymbol,
  ...props
}) => {
  if (
    typeof industryScore !== "number" &&
    typeof industryScore !== "string" &&
    typeof globalScore !== "number" &&
    typeof globalScore !== "string"
  ) {
    return <></>
  }
  return (
    <Flex
      justifyContent="space-between"
      borderRadius={4}
      border="1px solid #727272"
      width="auto"
      p={2}
      {...props}
    >
      {(typeof industryScore === "number" ||
        typeof industryScore === "string") && (
        <Flex>
          <Flex height={20} width={20} borderRadius={20} bg="#BFA9F0" mr={2} />
          <Text width="auto">{`${
            addPlusSymbol && industryScore > 0 ? "+" : ""
          }${industryScore}% Industry`}</Text>
        </Flex>
      )}
      {(typeof globalScore === "number" || typeof globalScore === "string") && (
        <Flex ml={3}>
          <Flex height={20} width={20} borderRadius={20} bg="#A9C7F0" mr={2} />
          <Text width="auto">{`${
            addPlusSymbol && globalScore > 0 ? "+" : ""
          }${globalScore}% Global`}</Text>
        </Flex>
      )}
    </Flex>
  )
}

export default IndustryAndGlobalScoreBox
