import { Utils } from "@engaging-tech/components"
import { createSelector } from "reselect"

const { getDefaultSelectors } = Utils.coreStore

const defaultSelectors = getDefaultSelectors(
  "surveyManagement.surveySelection.surveys"
)

export const { getData, getError, getIsLoading, getStore } = defaultSelectors

export const getDraftSurveys = createSelector(getData, surveys =>
  surveys.filter(
    survey => survey.status === "draft" && survey.type !== "awards"
  )
)

export const getLiveSurveys = createSelector(getData, surveys =>
  surveys.filter(survey => survey.status === "live")
)

export const getCompletedSurveys = createSelector(getData, surveys =>
  surveys.filter(survey => survey.status === "completed")
)

export const getScheduledSurveys = createSelector(getData, surveys =>
  surveys.filter(survey => survey.status === "scheduled")
)

export const getLastTwoSurveys = createSelector(getData, surveys => {
  if (!surveys.length || !surveys) return null

  const listSurveys = surveys.filter(survey =>
    ["live", "scheduled"].includes(survey.status)
  )

  const sortedList = listSurveys.sort(
    (a, b) =>
      new Date(a.meta.startDate).valueOf() -
      new Date(b.meta.startDate).valueOf()
  )

  return sortedList.splice(sortedList.length - 2)
})
