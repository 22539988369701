/* eslint-disable import/prefer-default-export */

import { gql } from "../../../../lib/Apollo"

export const PURCHASE_CREDITS = gql`
  mutation($credits: Float!, $surveyId: String) {
    purchaseSurveyCredits(credits: $credits, surveyId: $surveyId)
  }
`

export const SCHEDULE_SURVEY = gql`
  mutation($surveyId: ID!) {
    scheduleOrganisationSurvey(surveyId: $surveyId) {
      id
    }
  }
`
