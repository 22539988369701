import {
  Flex,
  MotionFlex,
  Text,
  TabletUp,
  CustomHooks
} from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"

import React, { useState, useEffect } from "react"

const TitleTextLine = ({ width, height, index }) => {
  return (
    <MotionFlex
      bg="#e6e6e6"
      height={height}
      borderRadius={height / 2}
      variants={{
        unActive: {
          width: "0%"
        },
        active: {
          width,
          transition: {
            delay: 0.2 * index
          }
        }
      }}
    />
  )
}

const TemplateTitle = ({
  sequenceStart,
  currentSequenceNumber,
  height,
  ...props
}) => {
  const titleHeight = height / 6
  return (
    <MotionFlex
      bg="#909090"
      width="90%"
      height={titleHeight}
      mt={10}
      borderRadius={2}
      initial={{ opacity: 0 }}
      animate={currentSequenceNumber >= sequenceStart ? "active" : "unActive"}
      variants={{
        unActive: {
          opacity: 0
        },
        active: {
          opacity: 1
        }
      }}
      flexDirection="column"
      justifyContent="space-evenly"
      pl={2}
      exit={{ opacity: 0 }}
      {...props}
    >
      <TitleTextLine width="70%" index={1} height={titleHeight / 6} />
      <TitleTextLine width="50%" index={2} height={titleHeight / 6} />
    </MotionFlex>
  )
}

const TemplateQuestion = ({
  sequenceStart,
  currentSequenceNumber,
  height,
  ...props
}) => {
  return (
    <MotionFlex
      bg="#909090"
      width="80%"
      height={height / 9}
      mt={10}
      initial={{ opacity: 0 }}
      animate={
        currentSequenceNumber >= sequenceStart ? { opacity: 1 } : { opacity: 0 }
      }
      exit={{ opacity: 0 }}
      borderRadius={2}
      {...props}
    />
  )
}
let localSequenceCount = 1
const TemplateAnimatedIllustration = ({
  width = 220,
  height = 290,
  cycle = true,
  speed = 600,
  noAnimation = false,
  ...props
}) => {
  const [sequenceNumber, setSequenceNumber] = useState(
    noAnimation ? 10 : localSequenceCount
  )

  const updateSequence = () => {
    if (localSequenceCount === 10) {
      if (cycle) {
        setSequenceNumber(0)
        localSequenceCount = 0
      }
    } else {
      setSequenceNumber(localSequenceCount + 1)
      localSequenceCount += 1
    }
  }

  useEffect(() => {
    let isCancelled = false
    let interval = null
    const runAsync = async () => {
      if (!isCancelled && !noAnimation) {
        interval = setInterval(updateSequence, speed)
      }
    }

    runAsync()

    return () => {
      isCancelled = true
      if (interval) {
        clearInterval(interval)
      }
      localSequenceCount = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MotionFlex
      width={width}
      height={height}
      key={1}
      alignItems="flex-start"
      p={2}
      bg="#d8d8d8"
      borderRadius={4}
      flexDirection="column"
      {...props}
    >
      <TemplateTitle
        mt={1}
        height={height}
        sequenceStart={3}
        currentSequenceNumber={sequenceNumber}
      />
      <TemplateQuestion
        sequenceStart={4}
        height={height}
        currentSequenceNumber={sequenceNumber}
      />
      <TemplateQuestion
        sequenceStart={5}
        height={height}
        currentSequenceNumber={sequenceNumber}
      />
      <TemplateTitle
        sequenceStart={6}
        currentSequenceNumber={sequenceNumber}
        height={height}
      />
      <TemplateQuestion
        sequenceStart={7}
        height={height}
        currentSequenceNumber={sequenceNumber}
      />
    </MotionFlex>
  )
}

export default TemplateAnimatedIllustration
