import { gql } from "@apollo/client"

const CREATE_BUSINESS_JOB = gql`
  mutation createBusinessJob($input: PutBusinessJobInput) {
    createBusinessJob(input: $input) {
      id
      status
    }
  }
`

export default CREATE_BUSINESS_JOB
