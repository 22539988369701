import * as y from "yup"

const formSchema = y.object().shape({
  email: y
    .string()
    .email("Please enter a valid email address")
    .required("A valid email address is required"),
  password: y.string().required("A password is required")
})

export default formSchema
