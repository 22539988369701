import { Flex, CustomHooks, Text } from "@engaging-tech/components"
import ReactWordcloud from "react-wordcloud"
import equal from "fast-deep-equal/es6/react"

import React, { useRef } from "react"

const areEqual = (prevProps, nextProps) => equal(prevProps, nextProps)

const WordCloud = React.memo(
  ({ setWordCount, wordCountForQuestion, exportingPDF }) => {
    const containerRef = useRef()
    const cloudVisible = CustomHooks.useElementVisibleTrigger({
      componentRef: containerRef
    })

    return (
      <Flex
        width="100%"
        height="100%"
        ref={containerRef}
        justifyContent="center"
        alignItems="center"
      >
        {wordCountForQuestion ? (
          <ReactWordcloud
            words={
              cloudVisible || exportingPDF ? wordCountForQuestion.words : []
            }
            callbacks={{
              onWordMouseOver: word => setWordCount(word.value),
              onWordMouseOut: () => setWordCount(null)
            }}
            options={{
              transitionDuration: exportingPDF ? 0 : 700,
              fontSizes: [20, 100],
              rotationAngles: [0, 0],
              fontFamily: exportingPDF ? "Arial" : "Centra",
              rotations: 0,
              colors: ["#173C5A"],
              tooltipOptions: false,
              enableTooltip: false
            }}
          />
        ) : (
          <Text fontSize={6} width="auto">
            Unable to create word cloud
          </Text>
        )}
      </Flex>
    )
  },
  areEqual
)

export default WordCloud
