/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from "@engaging-tech/routing"
import React, { useCallback, useEffect, useState } from "react"

import ReportDashboardLoader from "../features/Reports/containers/ReportDashboardLoader"
import { LOAD_REPORTS_V2 } from "../features/Reports/services/report.queries"
import ReportsDashboardView from "../features/Reports/views/ReportsDashboard"
import { OrgGuard } from "../features/account"
import useBusinessAppQuery from "../hooks/useBusinessAppQuery"
import DashboardLayout from "../layouts/DashboardLayout"

const ReportsDashboard = () => {
  const location = useLocation()

  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(0)
  const [isThereMore, setIsThereMore] = useState(true)

  const { isLoading, getData, data } = useBusinessAppQuery(LOAD_REPORTS_V2, {
    enabledOnMount: false
  })

  const onLoad = useCallback(pageProps => {
    getData({
      limit: 10,
      skip: pageProps ? pageProps * 10 : 0,
      reportSearchFilters: {
        surveyType:
          location.pathname === "/reports"
            ? ["organisation", "awards"]
            : location.pathname.includes("engagement-surveys")
            ? ["organisation"]
            : ["awards"],
        isRoot: true
      }
    })
  }, [])

  const onLoadMore = useCallback(() => {
    const currentPage = page + 1
    setPage(currentPage)
    onLoad(currentPage)
  }, [page])

  useEffect(() => {
    onLoad(0)
  }, [])

  useEffect(() => {
    const listSurveyReports = data?.listSurveyReportsV2 ?? []

    setDataList(
      page === 0
        ? listSurveyReports
        : dataList
        ? [...dataList, ...listSurveyReports]
        : listSurveyReports
    )

    if (data?.listSurveyReportsV2?.length === 10) {
      setIsThereMore(true)
    } else {
      setIsThereMore(false)
    }
  }, [data])

  return (
    <OrgGuard>
      <DashboardLayout>
        <ReportDashboardLoader />

        <ReportsDashboardView
          page={page}
          isLoading={isLoading}
          reports={dataList}
          isThereMore={isThereMore}
          onLoad={onLoadMore}
        />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default ReportsDashboard
