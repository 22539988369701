const { gql } = require("@apollo/client")

const GET_SCORING_AREAS = gql`
  query getScoringAreas(
    $reportId: ID!
    $section: String!
    $findLowest: Boolean
    $questionId: String!
  ) {
    getScoringAreas(
      reportId: $reportId
      section: $section
      findLowest: $findLowest
      questionId: $questionId
    ) {
      answerId
      questionId
      score
    }
  }
`

export default GET_SCORING_AREAS
