import React from "react"
import { H2, Checkbox, Flex } from "@engaging-tech/components"
import styled from "styled-components"

const Label = styled.label``

const CheckboxItem = ({ id, isChecked, onChange, labelText }) => {
  return (
    <Flex width={1 / 1} justifyContent="flex-start">
      <Checkbox
        id={id}
        checked={isChecked}
        pb={2}
        onChange={onChange}
        width={30}
      />
      <Label fontSize={4} color="dark.1" pb={2} htmlFor={id}>
        {labelText}
      </Label>
    </Flex>
  )
}

const CheckboxList = ({
  title,
  itemList,
  checkedList,
  setCheckedList,
  children
}) => {
  const handleCheckboxItem = id => {
    if (checkedList.includes(id)) {
      const newList = checkedList.filter(listId => listId !== id)
      setCheckedList(newList)
    } else {
      setCheckedList([...checkedList, id])
    }
  }

  return (
    <Flex width={1 / 1} flexDirection="column" height="max-content">
      {title && (
        <H2 fontSize={[4, 5]} fontWeight="600" mb={10}>
          {title}
        </H2>
      )}
      {Object.keys(itemList).map(key => (
        <CheckboxItem
          key={key}
          id={key}
          labelText={itemList[key].label}
          isChecked={checkedList.includes(key)}
          onChange={() => handleCheckboxItem(key)}
        />
      ))}
      {children}
    </Flex>
  )
}

export default CheckboxList
