import { connect } from "react-redux"
import {
  CoreQuestionsActions,
  CoreQuestionsSelectors
} from "../../../templates/store/coreQuestions"
import CoreQuestionAnimatedIllustrationComponent from "../../components/stepOne/CoreQuestionAnimatedIllustration"

const mapState = state => ({
  coreQuestionValues: CoreQuestionsSelectors.getCoreQuestionValues(state),
  isLoading: CoreQuestionsSelectors.getIsLoading(state)
})

const mapDispatch = dispatch => ({
  onLoad: () => dispatch(CoreQuestionsActions.loadCoreQuestions())
})

const CoreQuestionAnimatedIllustration = connect(
  mapState,
  mapDispatch
)(CoreQuestionAnimatedIllustrationComponent)

export default CoreQuestionAnimatedIllustration
