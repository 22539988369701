import React, { useContext } from "react"
import { Flex } from "@engaging-tech/components"
import TemplateQuestionContext from "../TemplateQuestionContext"
import CircledNumber from "./CircledNumber"

const ScaleQuestionPreview = () => {
  const { min, max } = useContext(TemplateQuestionContext)
  const renderedScale = []
  for (let i = min; i <= max; i++) {
    renderedScale.push(i)
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" height="inherit">
      {renderedScale.map(number => (
        <CircledNumber key={number} number={number} />
      ))}
    </Flex>
  )
}

export default ScaleQuestionPreview
