import React from "react"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import styled from "styled-components"
import { Flex } from "@engaging-tech/components"

import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"

const Container = styled(Flex)`
  background-color: white;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
`

const SubscriptionLayout = ({ children }) => {
  const router = useRouter()
  const { downForMaintenance } = getConfig()
  if (downForMaintenance) {
    router.navigate("/")
  }
  return (
    <Flex width={["95%", "560px"]} ml={[3, 0]}>
      <TestingSiteBanner />
      <Container>{children}</Container>
    </Flex>
  )
}

export default SubscriptionLayout
