import { call, put, takeLatest } from "redux-saga/effects"
import {
  deleteTemplate,
  loadDefaultSurveyTemplate,
  loadOrgSurveyTemplates
} from "../services/templateSelection.service"
import * as Actions from "./templateSelection.actions"

export function* loadDefaultTemplatesSaga() {
  try {
    const res = yield call(loadDefaultSurveyTemplate)
    yield put(
      Actions.loadDefaultTemplatesSuccess(res.data.listDefaultSurveyTemplates)
    )
  } catch {
    yield put(Actions.loadDefaultTemplatesFailure())
  }
}

export function* loadOrgTemplatesSaga(action) {
  try {
    const res = yield call(loadOrgSurveyTemplates, action.payload)
    yield put(Actions.loadOrgTemplatesSuccess(res.data.listSurveyTemplates))
  } catch {
    yield put(Actions.loadOrgTemplatesFailure())
  }
}

export function* deleteOrgTemplateSaga(action) {
  try {
    yield call(deleteTemplate, action.payload)
    yield put(Actions.loadOrgTemplates())
    yield put(Actions.deleteOrgTemplateSuccess())
  } catch {
    yield put(Actions.deleteOrgTemplateFailure())
  }
}

export function* saga() {
  yield takeLatest(
    Actions.types.LOAD_DEFAULT_TEMPLATES,
    loadDefaultTemplatesSaga
  )
  yield takeLatest(Actions.types.LOAD_ORG_TEMPLATES, loadOrgTemplatesSaga)
  yield takeLatest(Actions.types.DELETE_ORG_TEMPLATE, deleteOrgTemplateSaga)
}
