/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable react/jsx-curly-newline */
import { Button, Flex, Spinner, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import { HANDLE_STRIPE_PAYMENT } from "../../../graphql/mutations"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import { handleStripePaymentFail } from "../../../store/basket/basket.actions"
import ErrorState from "../../business-information/components/error-state"
import { UnderlinedText } from "./Item"
import Items from "./Items"
import Summary from "./Summary"

const BackButton = ({ onBack, handleBack }) => {
  const router = useRouter()
  const urlParams = new URLSearchParams(window.location.search)
  const from = urlParams.get("from")

  return (
    <UnderlinedText
      fontSize={5}
      color="secondary.0"
      width="fit-content"
      mt={[5, 0]}
      onClick={from ? () => router.navigate(from) : () => handleBack()}
    >
      {onBack || from ? "Back" : "Back to homepage"}
    </UnderlinedText>
  )
}

const BasketButtons = ({ onBack, post, handleBack }) => {
  const router = useRouter()
  const paths = usePaths()
  const history = useHistory()

  return (
    <Flex
      width={1 / 1}
      mt={[3, 5]}
      justifyContent="space-between"
      alignItems="center"
      flexDirection={["column-reverse", "row"]}
    >
      <BackButton onBack={onBack} handleBack={handleBack} />
      <Flex width={[1 / 1, "fit-content"]} flexDirection={["column", "row"]} alignItems="center">
        <Button
          width="190px"
          mr={["0px", "20px"]}
          bg="secondary.0"
          color="light.0"
          onClick={() =>
            router.navigate(`${paths.yourBasket.payByInvoice}?from=${history.location.pathname}`)
          }
        >
          Generate invoice
        </Button>
        <Button width="190px" bg="primary.0" color="light.0" mt={[4, 0]} onClick={() => post()}>
          Pay by card
        </Button>
      </Flex>
    </Flex>
  )
}

const Basket = ({
  items,
  basketTotals,
  acceptedCurrencies,
  currency,
  onBack,
  isLoading,
  errors,
  cancelUrl,
  disclaimers,
  loadOrg
}) => {
  const router = useRouter()
  const paths = usePaths()

  useEffect(() => {
    loadOrg()
  }, [])

  const {
    data,
    errors: stripeErrors,
    isLoading: stripeLoading,
    post
  } = useBusinessAppMutation(HANDLE_STRIPE_PAYMENT, {
    failAction: handleStripePaymentFail,
    variables: { cancelUrl },
    enabledOnMount: false
  })

  const handleBack = () => (onBack ? onBack() : router.navigate(paths.welcome))

  if (isLoading || stripeLoading) return <Spinner justifyContent="center" color="primary.0" />

  if (errors || stripeErrors) {
    return <ErrorState />
  }

  if (data?.handleStripePayment) window.location.href = data?.handleStripePayment

  if (items.length === 0)
    return (
      <Flex flexDirection="column">
        <Text mb={5}>Your WorkL For Business Basket is currently empty</Text>
        <BackButton onBack={onBack} handleBack={handleBack} />
      </Flex>
    )

  return (
    <Flex flexDirection={["column", "row"]} width={1 / 1} mt={4} flexWrap="wrap">
      <Items items={items} currency={currency} />
      <Summary basketTotals={basketTotals} acceptedCurrencies={acceptedCurrencies} currency={currency} />
      <Flex mt={6} mb={2} width={1 / 1}>
        {disclaimers.map(disclaimer => (
          <Text mt={2} textAlign="center" with={1 / 1}>
            <b>Disclaimer:</b> {disclaimer}
          </Text>
        ))}
      </Flex>
      <BasketButtons onBack={onBack} post={post} handleBack={handleBack} />
    </Flex>
  )
}

export default Basket
