/* eslint-disable no-nested-ternary */
import { Card, CustomHooks, Modal } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import StepManagement from "./StepManagement"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const BespokeFlex = styled(Card)`
  position: absolute;
  top: 10px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  z-index: 9999;
  border: 1px solid grey;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

const BespokeStepHomepage = ({
  customStepName,
  bespokeSteps,
  setBespokeSteps,
  templateCustomQuestions,
  addStepToQuestion,
  deleteStepFromQuestion,
  setQuestionTitle
}) => {
  const device = CustomHooks.useClientDevice()

  if (device === "MOBILE")
    return createPortal(
      <Modal bg="white">
        <StepManagement
          customStepName={customStepName}
          bespokeSteps={bespokeSteps}
          setBespokeSteps={setBespokeSteps}
          templateCustomQuestions={templateCustomQuestions}
          addStepToQuestion={addStepToQuestion}
          deleteStepFromQuestion={deleteStepFromQuestion}
          setQuestionTitle={setQuestionTitle}
        />
      </Modal>,
      appRoot()
    )

  return (
    <BespokeFlex>
      <StepManagement
        customStepName={customStepName}
        bespokeSteps={bespokeSteps}
        setBespokeSteps={setBespokeSteps}
        templateCustomQuestions={templateCustomQuestions}
        addStepToQuestion={addStepToQuestion}
        deleteStepFromQuestion={deleteStepFromQuestion}
        setQuestionTitle={setQuestionTitle}
      />
    </BespokeFlex>
  )
}

export default BespokeStepHomepage
