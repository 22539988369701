/* eslint-disable react/jsx-curly-brace-presence */
import React from "react"
import { Spinner } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import PageLayout from "../../layouts/PageLayout"
import { OrgGuard } from "../../features/account"
import { GET_AWARDS_BY_TYPE } from "../../features/awards/services/awards.queries"
import {
  getAwardsByTypeFail,
  getAwardsByTypeSuccess
} from "../../features/awards/store/awards.actions"

import AwardsBanner from "./AwardsBanner"
import Success from "../../features/basket/components/Success"
import useBusinessAppQuery from "../../hooks/useBusinessAppQuery"

const PaymentSuccess = ({ location }) => {
  const { awardType } = useParams()
  const params = new URLSearchParams(location.search)
  const paymentId = params.get("paymentId")

  const { isLoading, data } = useBusinessAppQuery(GET_AWARDS_BY_TYPE, {
    successAction: getAwardsByTypeSuccess,
    failAction: getAwardsByTypeFail,
    variables: { type: awardType }
  })

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return (
    <OrgGuard>
      <PageLayout
        pt={0}
        banner={() => (
          <AwardsBanner
            title={data?.getAwardsByType[0]?.title}
            logo={data?.getAwardsByType[0]?.logo}
            description={data?.getAwardsByType[0]?.description}
          />
        )}
      >
        <Success paymentId={paymentId} />
      </PageLayout>
    </OrgGuard>
  )
}

export default PaymentSuccess
