const { gql } = require("@apollo/client")

const GET_ALL_LOGOS = gql`
  query getAllLogos {
    getAllLogos {
      name
      winCategories
      status
    }
  }
`

export default GET_ALL_LOGOS
