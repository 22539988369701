import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useRouter } from "@engaging-tech/routing"
import { Button, H2 } from "@engaging-tech/components"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import EmployeeReportListContainer from "../EmployeeReports/containers/EmployeeReportListContainer"
import EmptyDashboard from "../components/EmptyDashboard"
import DeleteReportsBarContainer from "../EmployeeReports/containers/DeleteReportsBarContainer"
import DeleteReportsDialogueContainer from "../EmployeeReports/containers/DeleteReportsDialogueContainer"
import SearchBarContainer from "../containers/SearchBarContainer"
import LoadingButton from "../components/LoadingButton"
import ErrorState from "../../ui/components/ErrorState"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const EmployeeReportsDashboard = ({
  surveyType,
  filteredReports,
  openLatestReport,
  isLoading,
  hasError,
  setHasError,
  url
}) => {
  const [openModal, setOpenModal] = useState(false)
  const router = useRouter()

  const dashboardAttributes = {
    employeeStarter: {
      title: "New Starter Survey",
      createLink: "new-starter-surveys"
    },
    employeeExit: {
      title: "Exit Survey",
      createLink: "exit-surveys"
    }
  }

  useEffect(() => {
    if (url && !hasError) {
      router.navigate(
        `/reports/${dashboardAttributes[surveyType].createLink}/${url}`
      )
    }
  }, [url])

  const navigateToMergeReports = () =>
    router.navigate(
      `/reports/${dashboardAttributes[surveyType].createLink}/create`
    )

  return (
    <>
      <Flex width={1 / 1} flexDirection="column" position="relative">
        <Flex
          justifyContent="space-between"
          py={3}
          flexWrap="wrap"
          width={1 / 1}
        >
          <H2
            fontWeight={700}
            width={[1 / 1, 1 / 1, 2 / 5]}
            fontSize={[5, 6]}
            ml={[1, 1, 2]}
            mb={2}
          >
            {`${dashboardAttributes[surveyType]?.title}s`}
          </H2>
          <Flex
            alignItems="center"
            width={[1 / 1, 1 / 1, 2 / 5]}
            justifyContent={["center", "flex-end", "flex-end"]}
          >
            <Button
              variant="outline.secondary.0"
              mr={1}
              color="light.0"
              maxWidth="250px"
              width={6 / 13}
              onClick={() => navigateToMergeReports()}
            >
              Create New Report
            </Button>
            <LoadingButton
              bg="secondary.0"
              maxWidth="250px"
              width={6 / 13}
              label="Open latest report"
              isLoading={isLoading}
              onClick={() => openLatestReport(surveyType)}
            />
          </Flex>
        </Flex>
        <Flex py={3}>
          <SearchBarContainer placeholder="Search..." width="100%" />
        </Flex>
        {filteredReports?.length === 0 ? (
          <EmptyDashboard reportStatus />
        ) : (
          <>
            <EmployeeReportListContainer
              checkBoxLabelText="Delete"
              surveyDetails={dashboardAttributes[surveyType]}
              showCheckBox={isRoot => !isRoot}
            />
            <DeleteReportsBarContainer
              buttonText="delete report(s)"
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
            {openModal && (
              <Modal>
                <DeleteReportsDialogueContainer
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  dialogueText="This action will delete the selected reports. Do you wish to continue?"
                  successMessage="The reports have been deleted"
                />
              </Modal>
            )}
          </>
        )}
      </Flex>
      {hasError &&
        createPortal(
          <Modal>
            <Flex
              bg="white"
              alignItems="center"
              justifyContent="center"
              width={1 / 1}
              p={3}
              overflow="none"
            >
              <ErrorState
                message={hasError}
                onClick={() => {
                  setHasError(false)
                }}
              />
            </Flex>
          </Modal>,
          appRoot()
        )}
    </>
  )
}

export default EmployeeReportsDashboard
