import React from "react"
import styled from "styled-components"
import { Text, Flex, CircularProgress } from "@engaging-tech/components"
import { ScoreLabel, getLabelData } from "../../components/ScoreLabel"

const BorderFlex = styled(Flex)`
  border-bottom: ${({ theme, noBorder }) =>
    noBorder ? "none" : `1px solid ${theme.colors.dark[4]}`};
`

const StepBarOnePager = ({
  score,
  section,
  noBorder
}) => (
  <div style={{ width: "100%" }}>
    <BorderFlex
      py={2}
      width={1 / 1}
      alignItems="center"
      noBorder={noBorder}
      justifyContent="space-between"
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        width="calc(60% - 25px)"
        mr={2}
      >
        <Text
          width="auto"
          fontSize={[5]}
          color="dark.3"
          fontWeight={700}
          noSelect
        >
          {section}
        </Text>
      </Flex>
      <Flex justifyContent="flex-start" alignItems="center" width={70}>
        <CircularProgress
          percentage={score}
          width={70}
          height={70}
          disableAllAnimation={true}
          strokeColor={getLabelData(score).color}
          mr={3}
        />
      </Flex>
      <Flex
        width="calc(40% - 25px)"
        justifyContent="center"
        alignItems="center"
      >
        <ScoreLabel noIcon score={score} width="auto" fontSize={[5]} fontWeight={700} />
      </Flex>
    </BorderFlex>
  </div>
)

export default StepBarOnePager