import { Card, Flex, H2, Paragraph } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import { ReportSelectors } from ".."
import BackButton from "../components/BackButton"
import indicatorBreakdownInfos from "../Indicators/lib/indicatorBreakdownInfos"

import ReportHeader from "../Header/containers/ReportHeader"

const Indicators = ({ type }) => {
  const { id, surveyType } = useParams()
  const indicators = useSelector(ReportSelectors.getIndicatorsChildrenLayout)
  const paths = usePaths()
  const indicatorBreakdownInfo = indicatorBreakdownInfos[type]
  return (
    <>
      <BackButton
        path={paths.reports.summary.index
          .replace(":surveyType", surveyType)
          .replace(":id", id)}
      />
      <ReportHeader
        subSection="Identify employee groups most in need of support"
        reportComponents={indicators}
      />
      <Card
        width={1 / 1}
        p={3}
        justifyContent="center"
        flexDirection="column"
        elevation={2}
        alignItems="center"
      >
        <Flex flexDirection="column" alignItems="center" width={1 / 1} px={2}>
          <H2 color="dark.1" fontWeight="500" fontSize={5} mb={4}>
            How to Use This Data
          </H2>
          {indicatorBreakdownInfo.howToText.map(t => (
            <Paragraph color="dark.2" fontSize={3} key={t}>
              {t}
            </Paragraph>
          ))}
        </Flex>
      </Card>

      <Flex
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        flexWrap="wrap"
        alignItems="stretch"
      >
        <indicatorBreakdownInfo.Component {...indicatorBreakdownInfo.props} />
      </Flex>
    </>
  )
}

export default Indicators
