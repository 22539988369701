import { Button, Flex } from "@engaging-tech/components"
import { Spinner } from "@engaging-tech/components"
import React, { useContext } from "react"

import { ViewActionPlanContext } from "../views/ViewActionPlan"

const SixStepsButtons = ({ isMutationSaving }) => {
  const { handlePreviewClick, handlePublishClick } = useContext(
    ViewActionPlanContext
  )

  return (
    <Flex width={1 / 1} justifyContent="flex-end" mt={5} mb={6}>
      <Flex width="260px" justifyContent="space-between">
        {isMutationSaving ? (
          <Spinner color="primary.0" justifyContent="right" width={1 / 2} />
        ) : (
          <>
            <Button
              width="120px"
              bg="secondary.0"
              color="light.0"
              onClick={handlePreviewClick}
            >
              Preview
            </Button>

            <Button
              width="120px"
              bg="primary.0"
              color="light.0"
              onClick={handlePublishClick}
            >
              Publish
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default SixStepsButtons
