import { Redirect } from "@engaging-tech/routing"
import React from "react"

import Pages from "./pages"

const reportHighlightPaths = link => {
  return [
    `${link}/:id`,
    `${link}/:id/action-plan/:step`,
    `${link}/:id/matrices`,
    `${link}/:id/indicator-breakdown`,
    `${link}/:id/indicator-breakdown/diversity-and-inclusion`,
    `${link}/:id/indicator-breakdown/flight-risk`,
    `${link}/:id/indicator-breakdown/well-being`,
    `${link}/:id/indicator-breakdown/confidence-in-management`,
    `${link}/:id/highlights`,
    `${link}/:id/information-sharing`
  ]
}

const routes = [
  {
    path: "/",
    description: "Index",
    component: () => <Redirect from="/" to="/network" exact />
  },
  {
    path: "/down-for-maintenance",
    description: "Down For Maintenance",
    component: Pages.DownForMaintenance
  },
  {
    path: "/welcome",
    description: "Welcome",
    component: () => <Redirect from="/welcome" to="/network" exact />
  },
  {
    path: "/basket",
    description: "Your Basket",
    component: Pages.basket.index,
    children: [
      {
        path: "/basket/pay-by-invoice",
        description: "Pay By Invoice",
        component: Pages.basket.invoice
      },
      {
        path: "/basket/success",
        description: "Success",
        component: Pages.basket.success
      }
    ]
  },
  {
    path: "/awards",
    description: "Awards",
    component: Pages.awards.index,
    children: [
      {
        path: "/awards/:awardType",
        description: "Enter Awards",
        component: Pages.awards.enterAwards,
        children: [
          {
            path: "/awards/:awardType/success",
            description: "Payment Success",
            component: Pages.awards.paymentSuccess
          },
          {
            path: "/awards/:awardType/forbidden",
            description: "Entry Forbidden",
            component: Pages.awards.entryForbidden
          }
        ]
      }
    ]
  },
  {
    path: "/sliceAndDice/success",
    description: "Payment Success",
    component: Pages.sliceAndDice.success
  },
  {
    path: "/network",
    description: "Home",
    highlightPaths: ["/"],
    component: Pages.EBN.index,
    children: [
      {
        path: "/network/leads",
        description: "Lead Generation",
        component: Pages.leads.index,
        private: true,
        icon: "supervised_user_circle"
      },
      {
        path: "/network/events",
        description: "Events",
        private: true,
        component: Pages.events.index,
        icon: "event",
        children: [
          {
            path: "/network/events/post",
            private: true,
            description: "Post New Event",
            component: Pages.events.post
          }
        ]
      },
      {
        path: "/network/jobs",
        description: "Jobs",
        private: true,
        component: Pages.jobs.live,
        icon: "work",
        children: [
          {
            path: "/network/jobs/create",
            description: "Post a Job",
            component: Pages.jobs.create,
            icon: "create"
          },
          {
            path: "/network/jobs/live",
            description: "Live Jobs",
            component: Pages.jobs.live,
            icon: "assignment_ind"
          }
        ]
      },
      {
        path: "/network/business-information",
        description: "Business Information Hub",
        private: true,
        component: Pages.businessInformation.BIP
      },
      {
        path: "/network/webinars",
        description: "Webinars",
        private: true,
        component: Pages.EBN.webinars
      },
      {
        path: "/network/:contentType/:id",
        description: "Content",
        private: true,
        component: Pages.EBN.content
      }
    ]
  },
  {
    path: "/surveys",
    description: "Surveys",
    icon: "assessment",
    component: Pages.surveys.surveySelection.LiveSurveySelectionDashboard,
    children: [
      {
        component: Pages.surveys.surveySelection.LiveSurveySelectionDashboard,
        path: "/surveys/live",
        description: "Live Surveys",
        highlightPaths: [
          "/surveys/:id/view",
          "/surveys/:id/view/title-and-template",
          "/surveys/:id/view/datesAndBranding",
          "/surveys/:id/view/participants",
          "/surveys"
        ]
      },
      {
        path: "/surveys/drafts",
        description: "Draft Surveys",
        highlightPaths: [
          "/surveys/create",
          "/surveys/create/title-and-template",
          "/surveys/create/dates-and-branding",
          "/surveys/create/participants",
          "/surveys/:id/edit"
        ],
        component: Pages.surveys.surveySelection.DraftSurveySelectionDashboard
      },
      {
        component: Pages.surveys.templateSelection.TemplateSelectionIndex,
        path: "/surveys/templates",
        description: "Build a Template",
        children: [
          {
            private: true,
            path: "/surveys/templates/create",
            description: "Create Template",
            component: Pages.surveys.templates.TemplateCreate
          },
          {
            private: true,
            path: "/surveys/templates/:id",
            description: "View Template",
            component: Pages.surveys.templates.TemplateView
          },
          {
            private: true,
            path: "/surveys/templates/:id/edit",
            description: "Edit Template",
            component: Pages.surveys.templates.TemplateEdit
          },
          {
            private: true,
            path: "/surveys/templates/:id/clone",
            description: "Clone Template",
            component: Pages.surveys.templates.TemplateClone
          }
        ]
      },
      {
        private: true,
        path: "/surveys/create",
        description: "Survey Creation",
        component: Pages.surveys.surveyCreate.TitleAndTemplate,
        children: [
          {
            private: true,
            path: "/surveys/create/title-and-template",
            description: "Title And Template",
            component: Pages.surveys.surveyCreate.TitleAndTemplate
          },
          {
            private: true,
            path: "/surveys/create/dates-and-branding",
            description: "Dates And Branding",
            component: Pages.surveys.surveyCreate.DatesAndBranding
          },
          {
            private: true,
            path: "/surveys/create/participants",
            description: "Participants",
            component: Pages.surveys.surveyCreate.Participants
          }
        ]
      },
      {
        private: true,
        path: "/surveys/:id/view",
        description: "View Survey",
        component: Pages.surveys.reviewSurvey.TitleAndTemplateReview,
        children: [
          {
            private: true,
            path: "/surveys/:id/view/title-and-template",
            description: "Title And Template",
            component: Pages.surveys.reviewSurvey.TitleAndTemplateReview
          },
          {
            private: true,
            path: "/surveys/:id/view/datesAndBranding",
            description: "Dates And Branding",
            component: Pages.surveys.reviewSurvey.DatesAndBrandingReview
          },
          {
            private: true,
            path: "/surveys/:id/view/participants",
            description: "Participants",
            component: Pages.surveys.reviewSurvey.ParticipantsReview
          }
        ]
      },
      {
        private: true,
        path: "/surveys/:id/edit",
        description: "Edit Draft Survey",
        component: Pages.surveys.surveyCreate.TitleAndTemplate
      },
      {
        private: true,
        path: "/surveys/:id/:status/view",
        description: "View Survey",
        component: Pages.surveys.surveyViewMode.SurveyViewMode
      },
      {
        private: true,
        path: "/surveys/:id/:status/edit",
        description: "Edit Survey",
        component: Pages.surveys.surveyViewMode.SurveyEditMode
      },
      {
        private: true,
        path: "/surveys/review",
        description: "Review Survey",
        component: Pages.surveys.SurveyReview
      },
      {
        private: true,
        path: "/surveys/order-summary",
        description: "Order Summary",
        component: Pages.surveys.OrderSummary
      },
      {
        private: true,
        path: "/surveys/billing-form",
        description: "Billing Form",
        component: Pages.surveys.SurveyCreateBillingForm
      },
      {
        component: Pages.surveys.surveyInformation.SurveyInformation,
        path: "/surveys/information",
        description: "Survey FAQs"
      }
    ]
  },
  {
    path: "/reports",
    description: "Reports",
    component: Pages.reports.ReportsDashboard,
    children: [
      {
        component: Pages.reports.ReportsDashboard,
        path: "/reports/engagement-surveys",
        description: "Engagement Surveys",
        highlightPaths: reportHighlightPaths("/reports/engagement-surveys"),
        children: [
          {
            path: "/reports/engagement-surveys/compare",
            description: "Compare Engagement Surveys",
            component: Pages.reports.SurveyComparison
          },
          {
            path: "/reports/engagement-surveys/:id/information-sharing",
            description: "Information Sharing",
            component: Pages.reports.InformationSharing
          },
          {
            path: "/reports/engagement-surveys/:id/empowerment",
            description: "Empowerment",
            component: Pages.reports.Empowerment
          },
          {
            path: "/reports/engagement-surveys/:id/wellbeing",
            description: "Wellbeing",
            component: Pages.reports.Wellbeing
          },
          {
            path: "/reports/engagement-surveys/:id/instilling-pride",
            description: "Instilling Pride",
            component: Pages.reports.InstillingPride
          },
          {
            path: "/reports/engagement-surveys/:id/job-satisfaction",
            description: "Job Satisfaction",
            component: Pages.reports.JobSatisfaction
          }
        ]
      },
      {
        component: Pages.reports.employeeReports.StarterReports,
        path: "/reports/new-starter-surveys",
        description: "New Starter Surveys",
        highlightPaths: reportHighlightPaths("/reports/new-starter-surveys"),
        children: [
          {
            component: Pages.reports.employeeReports.NewStarterReportCreation,
            path: "/reports/new-starter-surveys/create",
            description: "New Survey Report Creation",
            private: true
          }
        ]
      },
      {
        component: Pages.reports.employeeReports.ExitReports,
        path: "/reports/exit-surveys",
        description: "Exit Surveys",
        highlightPaths: reportHighlightPaths("/reports/exit-surveys"),
        children: [
          {
            component: Pages.reports.employeeReports.ExitReportCreation,
            path: "/reports/exit-surveys/create",
            description: "New Survey Report Creation",
            private: true
          }
        ]
      },
      {
        path: "/reports/:surveyType/:id",
        private: true,
        description: "Summary",
        component: Pages.reports.Summary,
        children: [
          {
            path: "/reports/:surveyType/:id/action-plan/:step",
            description: "Action Plan",
            component: Pages.reports.ActionPlan
          },

          {
            path: "/reports/:surveyType/:id/matrices",
            description: "Matrices Page",
            component: Pages.reports.Matrices
          },

          {
            path: "/reports/:surveyType/:id/indicator-breakdown",
            description: "Indicator Breakdown",
            children: [
              {
                path: "/reports/:surveyType/:id/indicator-breakdown/diversity-and-inclusion",
                description: "Diversity And Inclusion",
                component: Pages.reports.IndicatorBreakdown
              },
              {
                path: "/reports/:surveyType/:id/indicator-breakdown/flight-risk",
                description: "Flight Risk",
                component: Pages.reports.IndicatorBreakdown
              },
              {
                path: "/reports/:surveyType/:id/indicator-breakdown/well-being",
                description: "Well being",
                component: Pages.reports.IndicatorBreakdown
              },
              {
                path: "/reports/:surveyType/:id/indicator-breakdown/confidence-in-management",
                description: "Confidence In Management",
                component: Pages.reports.IndicatorBreakdown
              }
            ]
          },
          {
            path: "/reports/:surveyType/:id/highlights",
            description: "Data Highlights Page",
            component: Pages.reports.Highlights
          },
          {
            path: "/reports/:surveyType/:id/heat-map",
            description: "Heat Map",
            component: Pages.reports.Heatmap
          }
        ]
      }
    ]
  },
  {
    path: "/jobs/list/matches/:jobId",
    description: "List Job Matches",
    component: Pages.jobs.jobMatches
  },
  {
    path: "/account",
    description: "Account",
    icon: "user-circle",
    component: Pages.account.signIn,
    children: [
      {
        path: "/account/joined",
        description: "Thank You for Joining",
        component: Pages.account.joined
      },
      {
        path: "/account/success",
        description: "Thank You for Signing Up",
        component: Pages.account.success
      },
      {
        path: "/account/awards",
        description: "Thank You for Signing Up for the Awards",
        component: Pages.account.awards
      },
      {
        path: "/account/join-us",
        description: "Join Our Network",
        component: Pages.account.joinournetwork.index
      },
      {
        path: "/account/join-us-success",
        description: "EBN Success",
        component: Pages.account.joinournetwork.success
      },
      {
        path: "/account/join-us/events",
        description: "Join Events",
        component: Pages.account.joinournetwork.events
      },
      {
        path: "/account/join-us/leads",
        description: "Join Leads",
        component: Pages.account.joinournetwork.leads
      },
      {
        path: "/account/join-us/jobs",
        description: "Join Jobs",
        component: Pages.account.joinournetwork.jobs
      },
      {
        path: "/account/join-us/business-information",
        description: "Join BIP",
        component: Pages.account.joinournetwork.BIP
      },
      {
        path: "/account/sign-in",
        description: "Sign In",
        icon: "account_circle",
        component: Pages.account.signIn
      },
      {
        path: "/account/sign-up",
        description: "Sign Up",
        icon: "add_circle",
        component: Pages.account.signUp
      },
      {
        path: "/account/forgot-password",
        description: "Forgot Password",
        component: Pages.account.forgotPassword
      },
      {
        path: "/account/magic-link",
        description: "Magic Link",
        component: Pages.account.sendMagicLink
      },
      {
        path: "/account/magic-link/sent",
        description: "Magic Link Sent",
        component: Pages.account.magicLinkMessage
      },
      {
        path: "/account/magic-link/redirect",
        description: "Magic Link Redirect",
        component: Pages.account.magicLinkRedirect
      },
      {
        path: "/account/reset-password/:email/:code",
        description: "Reset Password",
        component: Pages.account.resetPassword
      },
      {
        path: "/account/confirm-email/:confirmToken",
        description: "Confirm Email",
        component: Pages.account.confirmEmail
      }
    ]
  },
  {
    path: "/legal",
    description: "Legal",
    component: Pages.legal.terms,
    children: [
      {
        path: "/legal/cookies",
        description: "Cookies",
        component: Pages.legal.cookies
      },
      {
        path: "/legal/privacy-policy",
        description: "Privacy Policy",
        component: Pages.legal.privacy
      },
      {
        path: "/legal/terms-and-conditions",
        description: "Terms and Conditions",
        component: Pages.legal.terms
      }
    ]
  },
  {
    path: "/saml",
    description: "Saml",
    component: Pages.account.saml
  },
  {
    path: "/self-serve",
    description: "Self Serve",
    component: Pages.selfServe.index,
    children: [
      {
        path: "/self-serve/engagement",
        description: "Self Serve Engagement",
        component: Pages.selfServe.organisation
      },
      {
        path: "/self-serve/wellbeing",
        description: "Self Serve Wellbeing",
        component: Pages.selfServe.wellbeing
      }
    ]
  },
  {
    path: "/logos-and-certifications",
    description: "Logos and Certifications",
    component: Pages.logos.index,
    children: [
      {
        path: "/logos-and-certifications/buy-now",
        description: "Buy Now",
        component: Pages.logos.purchase
      }
    ]
  },
  {
    path: "/action-plans",
    description: "Action Plans",
    component: Pages.actionPlans.index,
    children: [
      {
        path: "/action-plans/:id",
        description: "View Action Plan",
        component: Pages.actionPlans.view,
        private: true
      },
      {
        path: "/action-plans/:id/preview",
        description: "Preview Action Plan",
        component: Pages.actionPlans.preview,
        private: true
      },
      {
        path: "/action-plans/:id/edit/:stepId",
        description: "Edit Action Plan Step",
        component: Pages.actionPlans.edit,
        private: true
      },
      {
        path: "/action-plans/:id/preview/:stepId",
        description: "Preview Action Plan Step",
        component: Pages.actionPlans.preview,
        private: true
      }
    ]
  }
]

export default routes
