import React, { cloneElement, useState } from "react"

import SliceAndDiceFeatureCheck from "../../../helpers/SliceAndDiceFeatureCheck"
import { ImageLocked } from "../../ui/components/ImageLocked"
import SliceAndDiceUpgradeModal from "./SliceAndDiceUpgradeModal"

const blurredImageFromFeature = {
  instantActionPlanning: "blurred_data",
  sentimentAnalysis: "blurred_keywords"
}

const SliceAndDiceReportWrapper = ({ isSAndDMember, feature, children }) => {
  const check = SliceAndDiceFeatureCheck(feature)
  const [showUpgradeSDModal, setShowUpgradeSDModal] = useState(false)

  const toggleUpgradeModal = () => {
    setShowUpgradeSDModal(prevState => !prevState)
  }

  return (
    <>
      {!isSAndDMember && check ? (
        <ImageLocked
          imageName={blurredImageFromFeature[feature]}
          onClick={toggleUpgradeModal}
          unlockText="Unlock Insights"
        />
      ) : (
        cloneElement(children)
      )}
      {showUpgradeSDModal && (
        <SliceAndDiceUpgradeModal closeModal={toggleUpgradeModal} />
      )}
    </>
  )
}
export default SliceAndDiceReportWrapper
