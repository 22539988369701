import React, { useState } from "react"
import { Flex, Text, Paragraph } from "@engaging-tech/components"
import styled from "styled-components"
import ShowPreview from "./Page2/ShowPreview"
import ContentBlock from "../../ui/components/ContentBlock"

const StyledImg = styled.img`
  width: 450px;
  height: auto;
  max-width: 100%;
  @media only screen and (min-width: 768px) {
    margin-top: 32px;
  }
  @media only screen and (min-width: 1028px) {
    margin-top: 52px;
  }
`

const StyledText = styled(Text)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

const SurveyQuestions = () => {
  // const [showPreview, setShowPreview] = useState(false)
  const [showEngagement, setShowEngagement] = useState(false)
  const [showWellbeing, setShowWellbeing] = useState(false)
  // const handleClick = check => {
  //   if (check) {
  //     return setShowPreview(!showPreview) && setShowEngagement(!showEngagement)
  //   }
  //   return null
  // }
  return (
    <ContentBlock bg="secondary.3">
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection={["column", "row"]}>
          <Flex flexDirection="column" mr={[0, 2, 4]}>
            <Text
              color="secondary.0"
              fontSize={7}
              fontWeight="700"
              mt={[0, 4, 5]}
              mb={3}
              ml={[0, 2, 4]}
            >
              Engagement Survey
            </Text>
            <Paragraph color="secondary.0" fontSize={4} mb={4} ml={[0, 2, 4]}>
              Our employee experience survey measures the key components which
              influence employee experience. From this we then deliver easy to
              use, practical insights and actions to improve performance. The
              survey has been built behavioural scientists, academics and HR
              experts. The questions measure employee experience, covering
              WorkL’s six key drivers of engagement; Reward and Recognition,
              Information Sharing, Empowerment, Pride, Job Satisfaction and
              Wellbeing. The questions also provide insight on metrics including
              D&I, flight risk, wellbeing and confidence in management.
            </Paragraph>
            <Paragraph color="secondary.0" fontSize={4} mb={4} ml={[0, 2, 4]}>
              <StyledText
                width="fit-content"
                fontSize="16px"
                color="secondary.0"
                fontWeight={600}
                onClick={() => setShowEngagement(true)}
              >
                <u>
                  <b>Click here to view the questions list</b>
                </u>
              </StyledText>
            </Paragraph>
          </Flex>
          <Flex flexDirection="column" mb={["10px", "0px"]}>
            <Text
              color="secondary.0"
              fontSize={7}
              fontWeight="700"
              mt={[0, 4, 5]}
              mb={3}
              ml={[0, 2, 4]}
            >
              Wellbeing Survey
            </Text>
            <Paragraph color="secondary.0" fontSize={4} mb={4} ml={[0, 2, 4]}>
              Our wellbeing survey allows organisations to measures the mental,
              physical and financial wellbeing of their employee. From this we
              then deliver easy to use, practical insights and actions to
              improve the wellbeing of your employees. The survey has been built
              behavioural scientists, psychologists, academics and HR experts
              who specialise in wellbeing.
            </Paragraph>

            <StyledText
              width="fit-content"
              fontSize="16px"
              color="secondary.0"
              fontWeight={600}
              onClick={() => setShowWellbeing(true)}
            >
              <u>
                <b>Click here to view the questions list</b>
              </u>
            </StyledText>
          </Flex>
        </Flex>
        <StyledImg src="https://d19vbgnwz7jfjm.cloudfront.net/Survey_questions.svg" />

        {showEngagement && (
          <ShowPreview
            setShowPreview={setShowEngagement}
            link="https://d19vbgnwz7jfjm.cloudfront.net/fix_new_q_preview.png"
          />
        )}
        {showWellbeing && (
          <ShowPreview
            setShowPreview={setShowWellbeing}
            link="https://d19vbgnwz7jfjm.cloudfront.net/wellbeing_questions.png"
          />
        )}
      </Flex>
    </ContentBlock>
  )
}

export default SurveyQuestions
