/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from "react"
import { useFormik } from "formik"
import { object, number } from "yup"
import { Card, H4, Text } from "@engaging-tech/components"
import { TextField } from "../../../surveyManagement/components"

const textColor = "secondary.0"

const EmployeeCount = ({
  shareableLinks,
  employeeCount,
  handleErrors,
  setEmployeeCount
}) => {
  const initialValues = useMemo(
    () => ({
      employeeCount,
      shareableLinks
    }),
    [employeeCount, shareableLinks]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: object({
      employeeCount: number()
        .min(5, "Cannot be less than 5")
        .max(
          shareableLinks,
          `Cannot be greater than licence size (${shareableLinks})`
        )
        .required("Cannot be empty")
    }),
    validateOnMount: true,
    enableReinitialize: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setEmployeeCount(formik.values.employeeCount)
      handleErrors(formik.errors?.employeeCount, "employeeCount")
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.employeeCount, formik.errors])

  return (
    <Card width={1 / 1} maxWidth="1165px" p={25} mb={4}>
      <H4
        width={1 / 1}
        fontSize={[4, 5]}
        mb={20}
        fontWeight={600}
        color={textColor}
      >
        Enter your employee count
      </H4>
      <Text mb={20} color={textColor} width={1 / 1}>
        To get an accurate response rate, please enter the total amount of
        employees your organisation has.
      </Text>
      <TextField
        name="employeeCount"
        id="employeeCount"
        type="number"
        placeholder={`Maximum company size: ${shareableLinks}`}
        value={formik.values.employeeCount}
        error={formik.errors?.employeeCount}
        onChange={formik.handleChange}
      />
    </Card>
  )
}

export default EmployeeCount
