/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-multi-assign */
import { v4 as uuidv4 } from "uuid"
import { types } from "./template.actions"
import questionTemplate from "../lib/questionTemplate"

const general = "Something went wrong"

const getOrderedQuestionsSections = (sections, currentQuestion, order) => {
  let sectionIdxToMoveFrom = null

  const newSections = sections.map((section, idx) => {
    /* 
    1. If the question is in the section 
    and the question is not the last question and
    not the first question.
    2. The question is in the section and the
    question is the last question in the section 
    and the order is -1 (up) and its not the only question in the section
    3. The question is in the section and the 
    question is the first question in the section
    and the order is 1 (down) and its not the only question in the section
    */

    if (
      (section.questions.includes(currentQuestion) &&
        section.questions.indexOf(currentQuestion) !==
          section.questions.length - 1 &&
        section.questions.indexOf(currentQuestion) !== 0) ||
      (section.questions.includes(currentQuestion) &&
        section.questions.indexOf(currentQuestion) ===
          section.questions.length - 1 &&
        order === -1 &&
        section.questions.length > 1) ||
      (section.questions.includes(currentQuestion) &&
        section.questions.indexOf(currentQuestion) === 0 &&
        order === 1 &&
        section.questions.length > 1)
    ) {
      const ordSection = { ...section }
      const selectedQuestionIdx = ordSection.questions.indexOf(currentQuestion)
      ordSection.questions = [...ordSection.questions]
      ordSection.questions[selectedQuestionIdx] =
        ordSection.questions[selectedQuestionIdx + order]
      ordSection.questions[selectedQuestionIdx + order] = currentQuestion
      return ordSection
    }

    /* 
    1. If the section includes the question moved 
    and the question is the last question in the section
    and the order is 1 (down) 
    2. If the section includes the question moved
    and the question is the first question in the section
    and the order is -1 (up)
    */
    if (
      (section.questions.includes(currentQuestion) &&
        section.questions.indexOf(currentQuestion) ===
          section.questions.length - 1 &&
        order === 1) ||
      (section.questions.includes(currentQuestion) &&
        section.questions.indexOf(currentQuestion) === 0 &&
        order === -1)
    ) {
      const ordSection = { ...section }
      ordSection.questions.splice(
        ordSection.questions.indexOf(currentQuestion),
        1
      )

      sectionIdxToMoveFrom = idx
      return ordSection
    }

    return section
  })

  if (sectionIdxToMoveFrom !== null) {
    if (order === 1) {
      newSections[sectionIdxToMoveFrom + 1].questions.unshift(currentQuestion)
    }

    if (order === -1) {
      newSections[sectionIdxToMoveFrom - 1].questions.push(currentQuestion)
    }

    sectionIdxToMoveFrom = null
  }

  return newSections
}

const getOrderedSections = (sections, currentSection, order) => {
  const sectionIds = sections?.map(section => section.sectionId)
  const selectedSectionIdx = sectionIds.indexOf(currentSection)
  sectionIds[selectedSectionIdx] = sectionIds[selectedSectionIdx + order]
  sectionIds[selectedSectionIdx + order] = currentSection
  return sectionIds
}

const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
]

/* 
There are three modes:
  - view: No Editing allowed,
  - create: Used when cloning template, we pull the cloned template from the database for user to edit,
  but when we save, we save it with a new ID.
  - edit: Allows editing.
*/

export const initialState = {
  type: "organisation",
  showBranching: false,
  templateId: "",
  viewOnly: false,
  name: "",
  description: "",
  pageErrors: false,
  openPopUpMenu: "",
  apiError: { general: "", error: {} },
  saveTemplateErrors: { general: "", error: {} },
  sections: [],
  coreQuestions: {
    isLoading: false,
    apiError: { general: "", error: {} },
    questions: {}
  },
  questions: [],
  branchingLogic: [],
  bespokeSteps: []
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CLEAR_API_ERRORS: {
      return {
        ...state,
        apiError: { general: "", error: {} },
        saveTemplateErrors: { general: "", error: {} }
      }
    }

    case types.SAVE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        showBranching: false
      }
    }

    case types.SAVE_TEMPLATE_FAIL: {
      return {
        ...state,
        saveTemplateErrors: {
          general: "Something went wrong. Your template was not saved.",
          error: payload
        }
      }
    }

    case types.DELETE_STEP_FROM_QUESTION: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const question = questions.find(q => q.id === payload.questionId)
      delete question.customStepName
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)
      questions[questionIdx] = question

      return {
        ...state,
        questions
      }
    }

    case types.ADD_STEP_TO_QUESTION: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)
      questions[questionIdx] = {
        ...questions[questionIdx],
        customStepName: payload.step
      }

      return {
        ...state,
        questions
      }
    }

    case types.SET_BESPOKE_STEPS: {
      return {
        ...state,
        bespokeSteps: payload
      }
    }

    case types.CLEAR_TEMPLATE_DATA: {
      return {
        type: "organisation",
        showBranching: false,
        templateId: "",
        viewOnly: false,
        name: "",
        description: "",
        pageErrors: false,
        openPopUpMenu: "",
        apiError: { general: "", error: {} },
        sections: [],
        coreQuestions: {
          isLoading: false,
          apiError: { general: "", error: {} },
          questions: {}
        },
        questions: [],
        branchingLogic: [],
        bespokeSteps: []
      }
    }

    case types.SET_VIEW_ONLY: {
      return {
        ...state,
        viewOnly: payload
      }
    }

    case types.SET_BRANCHING_LOGIC: {
      // Seeing if the logic already exists for the answer selected.
      const branchingLogic = JSON.parse(JSON.stringify(state.branchingLogic))
      const choiceIdx = branchingLogic.findIndex(
        q => q.choice === payload.choice
      )

      // Find the target question and decide whats the target variable
      const targetQuestion = state?.questions?.filter(
        q => q.id === payload.target
      )

      const targetVariable = targetQuestion.length
        ? "targetQuestion"
        : "targetSection"

      // Find the section which contains the question of the answer.
      const section = state.sections.filter(s =>
        s.questions.includes(payload.questionId)
      )[0]
      const questionIdx = section.questions.indexOf(payload.questionId)

      // Find the last question in the survey
      const lastQuestion = state?.sections[
        state?.sections.length - 1
      ].questions.slice(-1)[0]

      if (
        // Answer exists
        (choiceIdx !== -1 &&
          // The target question is the next question of current question
          payload.target === section.questions[questionIdx + 1] &&
          // And there are no targetAnswers in the current object.
          payload.targetAnswers.length === 0) ||
        // OR
        // Answer exists
        (choiceIdx !== -1 &&
          // The target question is the next question of current question
          payload.target === section.questions[questionIdx + 1] &&
          // all available values === Ticked values by user, we delete the logic
          targetQuestion[0]?.values?.length === payload.targetAnswers.length) ||
        // OR
        // Answer exists
        (choiceIdx !== -1 &&
          // This is the last question
          lastQuestion === payload.questionId &&
          // And target is end
          payload.target === "end")
      ) {
        branchingLogic.splice(choiceIdx, 1)
        // If answer exists, we update the logic
      } else if (choiceIdx !== -1) {
        branchingLogic[choiceIdx] = {
          currentQuestion: payload.questionId,
          choice: payload.choice,
          [targetVariable]: payload.target,
          targetAnswers: payload.targetAnswers.length
            ? payload.targetAnswers
            : targetQuestion[0]?.values?.map(value => value.id)
        }
      } else if (
        // If answer does not exist
        (choiceIdx === -1 &&
          // and the question is not next question
          payload.target !== section.questions[questionIdx + 1]) ||
        // OR
        // If answer does not exists
        (choiceIdx === -1 &&
          // and all available values !== ticked values by user, we add the new logic
          targetQuestion[0]?.values?.length !== payload.targetAnswers.length)
      ) {
        branchingLogic.push({
          currentQuestion: payload.questionId,
          choice: payload.choice,
          [targetVariable]: payload.target,
          targetAnswers: payload.targetAnswers.length
            ? payload.targetAnswers
            : targetQuestion[0]?.values?.map(value => value.id)
        })
      }

      return {
        ...state,
        branchingLogic
      }
    }

    case types.SET_BRANCHING_VIEW: {
      return { ...state, showBranching: payload }
    }

    case types.SET_SECTION_INTRO: {
      const sections = JSON.parse(JSON.stringify(state.sections))

      const sectionIdx = sections.findIndex(
        s => s.sectionId === payload.sectionId
      )

      sections[sectionIdx].description = payload.intro

      return {
        ...state,
        sections
      }
    }

    case types.SET_SECTION_TITLE: {
      const sections = JSON.parse(JSON.stringify(state.sections))
      const sectionIdx = sections.findIndex(
        s => s.sectionId === payload.sectionId
      )
      sections[sectionIdx].name = payload.title

      return {
        ...state,
        sections
      }
    }

    case types.SET_QUESTION_ICON: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].icon = payload.icon

      return {
        ...state,
        questions
      }
    }

    case types.CHANGE_CORE_QUESTION: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.currentId)
      const sections = JSON.parse(JSON.stringify(state.sections))
      const sectionIdx = sections.findIndex(s =>
        s.questions.includes(payload.currentId)
      )
      const sectionQuestionIdx = sections[sectionIdx].questions.indexOf(
        payload.currentId
      )

      // Replace the selected Core question ID in sections
      sections[sectionIdx].questions[sectionQuestionIdx] = payload.selectedId

      // Replace the core question in questions array with new core question object from all core questions
      const allCoreQuestions = [
        ...state?.coreQuestions?.questions?.additionalQuestions,
        ...state?.coreQuestions?.questions?.happinessQuestions
      ]
      const selectedCoreQuestion = allCoreQuestions.filter(
        question => question.id === payload.selectedId
      )[0]

      questions[questionIdx] = { ...selectedCoreQuestion, isCore: true }

      return {
        ...state,
        sections,
        questions
      }
    }

    case types.SWITCH_QUESTION_TYPE: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)
      const sections = JSON.parse(JSON.stringify(state.sections))
      const sectionIdx = sections.findIndex(s =>
        s.questions.includes(payload.questionId)
      )
      const sectionQuestionIdx = sections[sectionIdx].questions.indexOf(
        payload.questionId
      )

      // Core Question => Custom Question
      if (questions[questionIdx].isCore) {
        const id = uuidv4()
        questions[questionIdx].id = id
        questions[questionIdx].isCore = false

        sections[sectionIdx].questions[sectionQuestionIdx] = id
      } else {
        // Custom Question => Core Question
        // Match core question by title string
        const allCoreQuestions = [
          ...state?.coreQuestions?.questions?.additionalQuestions,
          ...state?.coreQuestions?.questions?.happinessQuestions
        ]

        const matchedCoreQuestion = allCoreQuestions.filter(
          q => q.title === questions[questionIdx].title
        )[0]

        // Check if the core question is used
        const isCoreQuestionUsed = sections.findIndex(s =>
          s.questions.includes(matchedCoreQuestion?.id)
        )

        if (isCoreQuestionUsed === -1 && matchedCoreQuestion) {
          // If Core question is not used, switch Custom Question to matched Core Question
          questions[questionIdx] = {
            ...matchedCoreQuestion,
            isCore: true
          }
          sections[sectionIdx].questions[sectionQuestionIdx] =
            matchedCoreQuestion.id
        } else {
          // If Core question is used, switch Custom Question to the next Core Question on available Core questions List
          const coreQuestion = allCoreQuestions.filter(
            q => q.id === payload.availableCoreQuestionIds[0]
          )[0]

          questions[questionIdx] = { ...coreQuestion, isCore: true }
          sections[sectionIdx].questions[sectionQuestionIdx] = coreQuestion.id
        }
      }

      return {
        ...state,
        questions,
        sections
      }
    }

    case types.SET_FIELD: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].fields[payload.fieldIdx].label = payload.field

      return {
        ...state,
        questions
      }
    }

    case types.SET_VALUE: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].values[payload.valueIdx].value = payload.value

      return {
        ...state,
        questions
      }
    }

    case types.SET_QUESTION_LABEL: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].label = payload.label

      return {
        ...state,
        questions
      }
    }

    case types.SET_QUESTION_TITLE: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].title = payload.title
      questions[questionIdx].description = payload.title

      return {
        ...state,
        questions
      }
    }

    case types.CHANGE_SCALE: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx][payload.type] = payload.value

      return {
        ...state,
        questions
      }
    }

    case types.SET_REQUIRED: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].required = payload.isRequired

      return {
        ...state,
        questions
      }
    }

    case types.SET_FILTER_ON_REPORT: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)

      questions[questionIdx].isFilter = payload.filterStatus

      return {
        ...state,
        questions
      }
    }

    case types.DELETE_OPTION: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.questionId)
      const key =
        questions[questionIdx].type === "short-text" ? "fields" : "values"

      questions[questionIdx][key] = questions[questionIdx][key]
        .slice(0, payload.valueIdx)
        .concat(questions[questionIdx][key].slice(payload.valueIdx + 1))

      return {
        ...state,
        questions
      }
    }

    case types.ADD_OPTION: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.id)

      if (["dropdown", "radio"].includes(questions[questionIdx].type)) {
        questions[questionIdx].values.push({
          value: "",
          id: uuidv4()
        })
      }

      if (questions[questionIdx].type === "short-text") {
        questions[questionIdx].fields.push({
          label: "",
          limit: 250,
          type: "text"
        })
      }

      return {
        ...state,
        questions
      }
    }

    case types.SET_CUSTOM_QUESTION_TYPE: {
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload.id)
      const newType = questionTemplate.filter(
        q => q.config.type === payload.type
      )

      const { id, description, title, icon } = questions[questionIdx]

      questions[questionIdx] = {
        ...newType[0].config,
        id,
        title,
        description,
        icon
      }

      if (["dropdown", "radio"].includes(payload.type))
        questions[questionIdx].values = [{ value: "", id: uuidv4() }]

      const pageErrors = JSON.parse(JSON.stringify(state.pageErrors))

      if (pageErrors[payload.id]) {
        Object.keys(pageErrors[payload.id]).forEach(key => {
          // label is not deleted if the type is radio or dropdown (becasue they have label)
          // title is not deleted on change of type (its indepenedant of type)
          // any other keys that are not same as type get deleted on type change
          // delete pageErrors[payload.id][key] if key is label and type is not radio or dropdown
          if (
            (key === "label" &&
              !["dropdown", "radio"].includes(payload.type)) ||
            (key !== payload.type && !["title", "label"].includes(key))
          ) {
            delete pageErrors[payload.id][key]
          }
        })
      }

      return {
        ...state,
        questions,
        pageErrors
      }
    }

    case types.ADD_CUSTOM_QUESTION: {
      const sections = JSON.parse(JSON.stringify(state.sections))
      const id = uuidv4()

      if (Object.keys(payload).includes("section")) {
        const sectionIdx = sections.findIndex(
          s => s.sectionId === payload.section
        )

        const newQuestions = [id, ...sections[sectionIdx].questions]
        sections[sectionIdx].questions = newQuestions
      }

      if (Object.keys(payload).includes("question")) {
        const sectionIdx = sections.findIndex(s =>
          s.questions.includes(payload.question)
        )

        const questionIdx = sections[sectionIdx].questions.indexOf(
          payload.question
        )

        sections[sectionIdx].questions = insert(
          sections[sectionIdx].questions,
          questionIdx + 1,
          id
        )
      }

      return {
        ...state,
        sections,
        questions: [...state.questions, { id, ...questionTemplate[0].config }]
      }
    }

    case types.ADD_CORE_QUESTION: {
      const sections = JSON.parse(JSON.stringify(state.sections))

      if (Object.keys(payload).includes("section")) {
        const sectionIdx = sections.findIndex(
          s => s.sectionId === payload.section
        )

        sections[sectionIdx].questions.unshift(
          payload.availableCoreQuestionIds[0]
        )
      }

      if (Object.keys(payload).includes("question")) {
        const sectionIdx = sections.findIndex(s =>
          s.questions.includes(payload.question)
        )

        const questionIdx = sections[sectionIdx].questions.indexOf(
          payload.question
        )

        sections[sectionIdx].questions = insert(
          sections[sectionIdx].questions,
          questionIdx + 1,
          payload.availableCoreQuestionIds[0]
        )
      }

      const allCoreQuestions = [
        ...state.coreQuestions.questions.happinessQuestions,
        ...state.coreQuestions.questions.additionalQuestions
      ]

      return {
        ...state,
        sections,
        questions: [
          ...state.questions,
          {
            ...allCoreQuestions.filter(
              q => q.id === payload.availableCoreQuestionIds[0]
            )[0],
            isCore: true
          }
        ]
      }
    }

    case types.LOAD_CORE_QUESTIONS_SUCCESS: {
      const questions = {
        additionalQuestions: payload.additionalQuestions.map(question => {
          const copiedQuestion = JSON.parse(JSON.stringify(question))
          // eslint-disable-next-line no-underscore-dangle
          delete copiedQuestion.__typename

          if (copiedQuestion.values) {
            const newValues = []

            copiedQuestion.values.forEach(value => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              delete value.__typename
              newValues.push(value)
            })

            copiedQuestion.values = newValues
          }

          if (copiedQuestion.fields) {
            const newFields = []

            copiedQuestion.fields.forEach(field => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              delete field.__typename
              newFields.push(field)
            })

            copiedQuestion.fields = newFields
          }
          return copiedQuestion
        }),

        happinessQuestions: payload.happinessQuestions.map(question => {
          const copiedQuestion = JSON.parse(JSON.stringify(question))
          // eslint-disable-next-line no-underscore-dangle
          delete copiedQuestion.__typename

          if (copiedQuestion.values) {
            const newValues = []

            copiedQuestion.values.forEach(value => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              delete value.__typename
              newValues.push(value)
            })

            copiedQuestion.values = newValues
          }

          if (copiedQuestion.fields) {
            const newFields = []

            copiedQuestion.fields.forEach(field => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              delete field.__typename
              newFields.push(field)
            })

            copiedQuestion.fields = newFields
          }

          return copiedQuestion
        })
      }

      return {
        ...state,
        coreQuestions: {
          ...state.coreQuestions,
          questions
        }
      }
    }

    case types.LOAD_CORE_QUESTIONS_FAIL: {
      return {
        ...state,
        coreQuestions: {
          ...state.coreQuestions,
          apiError: { general, error: payload }
        }
      }
    }

    case types.DELETE_SECTION: {
      const sections = JSON.parse(JSON.stringify(state.sections))
      const sectionIdx = sections.findIndex(s => s.sectionId === payload)
      const questionsToDelete = sections[sectionIdx].questions
      const questions = JSON.parse(JSON.stringify(state.questions))

      questionsToDelete.forEach(questionId => {
        const questionIdx = questions.findIndex(q => q.id === questionId)
        questions.splice(questionIdx, 1)
      })

      sections.splice(sectionIdx, 1)

      // Delete section and relevant questions from pageErrors
      const pageErrors = JSON.parse(JSON.stringify(state.pageErrors))
      delete pageErrors[payload]
      questionsToDelete.forEach(questionId => {
        delete pageErrors[questionId]
      })

      return {
        ...state,
        sections,
        questions,
        pageErrors
      }
    }

    case types.DELETE_QUESTION: {
      // Delete questionId from sections
      const sections = JSON.parse(JSON.stringify(state.sections))
      const newSections = sections.map(section => {
        const idx = section.questions.indexOf(payload)
        if (idx !== -1) {
          const newSection = { ...section }
          newSection.questions = newSection.questions
            .slice(0, idx)
            .concat(newSection.questions.slice(idx + 1))
          return newSection
        }
        return section
      })

      // Delete question from questions
      const questions = JSON.parse(JSON.stringify(state.questions))
      const questionIdx = questions.findIndex(q => q.id === payload)
      questions.splice(questionIdx, 1)

      // Delete question from pageErrors
      const pageErrors = JSON.parse(JSON.stringify(state.pageErrors))
      delete pageErrors[payload]

      // Delete question from branchingLogic
      const branchingLogic = state.branchingLogic.filter(logic => {
        if (logic.currentQuestion === payload) return false
        if (logic.targetQuestion === payload) return false
        return true
      })

      return {
        ...state,
        sections: newSections,
        questions,
        branchingLogic,
        pageErrors
      }
    }

    case types.ADD_SECTION: {
      const tempSectionObj = {
        sectionId: uuidv4(),
        title: "",
        description: "",
        questions: [],
        visible: true
      }

      let sections = JSON.parse(JSON.stringify(state.sections))
      const payloadType = Object.keys(payload)

      if (payloadType.includes("introduction")) sections.unshift(tempSectionObj)

      if (payloadType.includes("section")) {
        const idx = sections.findIndex(s => s.sectionId === payload.section)
        sections = insert(sections, idx + 1, tempSectionObj)
      }

      if (payloadType.includes("question")) {
        const idx = sections.findIndex(s =>
          s.questions.includes(payload.question)
        )
        sections = insert(sections, idx + 1, tempSectionObj)
      }

      return {
        ...state,
        sections
      }
    }

    case types.MOVE_SECTION: {
      const sections = JSON.parse(JSON.stringify(state.sections))
      const sectionIds = getOrderedSections(
        sections,
        payload.currentSection,
        payload.order
      )

      return {
        ...state,
        sections: sectionIds.map(id => {
          const idx = state.sections.findIndex(x => x.sectionId === id)
          return state.sections[idx]
        })
      }
    }

    case types.MOVE_QUESTION: {
      const sections = JSON.parse(JSON.stringify(state.sections))
      const orderedQuestionsSections = getOrderedQuestionsSections(
        sections,
        payload.currentQuestion,
        payload.order
      )

      return {
        ...state,
        sections: orderedQuestionsSections
      }
    }

    case types.OPEN_POP_UP_MENU:
      return {
        ...state,
        openPopUpMenu: payload
      }

    case types.LOAD_TEMPLATE_FAIL:
      return {
        ...state,
        apiError: {
          general,
          error: payload
        }
      }

    case types.LOAD_TEMPLATE_SUCCESS: {
      const sections = JSON.parse(JSON.stringify(payload?.sections))

      for (let i = 0; i < sections.length; i++) {
        let sectionDescription = ""

        sections[i].description.forEach(description => {
          sectionDescription += `${description.content}\n`
        })

        sections[i].description = sectionDescription
      }

      // delete __typename is neccesarry because it's something that is not being accepted back by the API when posting this data.
      return {
        ...state,
        ...payload,
        sections,
        branchingLogic: payload.branchingLogic.map(logic => {
          const branchingLogic = JSON.parse(JSON.stringify(logic))
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          delete branchingLogic.__typename
          return branchingLogic
        }),
        questions: payload.questions.map(question => {
          const questionCopy = JSON.parse(JSON.stringify(question))
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          delete questionCopy.__typename

          if (questionCopy.values) {
            const newValues = []

            questionCopy.values.forEach(value => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              delete value.__typename
              newValues.push(value)
            })

            questionCopy.values = newValues
          }

          if (questionCopy.fields) {
            const newFields = []

            questionCopy.fields.forEach(field => {
              // eslint-disable-next-line no-underscore-dangle, no-param-reassign
              delete field.__typename
              newFields.push(field)
            })

            questionCopy.fields = newFields
          }

          return questionCopy
        })
      }
    }

    case types.SET_TEMPLATE_ID:
      return {
        ...state,
        templateId: payload
      }

    case types.SET_NAME:
      return {
        ...state,
        name: payload
      }

    case types.SET_DESCRIPTION:
      return {
        ...state,
        description: payload
      }

    case types.SET_PAGE_ERRORS: {
      let pageErrors = JSON.parse(JSON.stringify(state.pageErrors))

      pageErrors = {
        ...pageErrors,
        [payload.id]: {
          ...pageErrors[payload.id],
          [payload.type]: payload.errors
        }
      }

      // Clean up empty errors
      if (!payload.errors) delete pageErrors[payload.id][payload.type]
      Object.keys(pageErrors).forEach(key => {
        if (Object.keys(pageErrors[key]).length === 0) delete pageErrors[key]
      })

      return {
        ...state,
        pageErrors
      }
    }

    default:
      return state
  }
}
