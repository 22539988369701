import { connect } from "react-redux"
import { AccountSelectors } from "../../../account"
import { surveyInfoActions, surveyInfoSelectors } from "../../surveyInfo"
import { getTemplate } from "../../surveyInfo/store/surveyInfo.selectors"
import OrderSummaryComponent from "../components/OrderSummary/index"
import {
  createIntent,
  reset,
  scheduleSurvey,
  setCreditsToPurchase
} from "../store/surveyInfoReview.actions"
import {
  getCreditsToPurchase,
  getScheduleStatus
} from "../store/surveyInfoReview.selectors"

const mapState = state => ({
  mode: surveyInfoSelectors.getModeObj(state),
  creditsPaid: surveyInfoSelectors.getCreditsPaid(state),
  currentCredits: AccountSelectors.getCurrentCredits(state),
  surveyLicences: AccountSelectors.getSurveyLicences(state),
  id: surveyInfoSelectors.getSurveyId(state),
  accountIsLoading: AccountSelectors.getIsLoading(state),
  surveyIsLoading: surveyInfoSelectors.getIsLoading(state),
  scheduleStatus: getScheduleStatus(state),
  creditsToPurchase: getCreditsToPurchase(state),
  template: getTemplate(state)
})

const mapDispatch = dispatch => ({
  scheduleSurvey: surveyId => dispatch(scheduleSurvey(surveyId)),
  onClose: () => {
    dispatch(surveyInfoActions.closeSurvey())
    dispatch(reset())
  },
  bypassPayment: ({ onSuccess }) =>
    dispatch(createIntent({ bypassPayment: true, onSuccess })),
  setCredits: credits => dispatch(setCreditsToPurchase(credits))
})

const OrderSummary = connect(mapState, mapDispatch)(OrderSummaryComponent)

export default OrderSummary
