import { connect } from "react-redux"

import { setActionPlanList } from "../../../../../store/actionPlans/actionPlans.actions"
import {
  getHasSliceAndDiceLicence,
  hasAnAwardsSurvey
} from "../../../../account/store/account.selectors"
import { surveysStore } from "../../index"
import SurveySelectionDashboardComponent from "../../views/SurveySelectionDashboard"

const mapState = state => {
  return {
    draftSurveys: surveysStore.selectors.getDraftSurveys(state),
    scheduledSurveys: surveysStore.selectors.getScheduledSurveys(state),
    liveSurveys: surveysStore.selectors.getLiveSurveys(state),
    isLoading: surveysStore.selectors.getIsLoading(state),
    surveysError: surveysStore.selectors.getError(state),
    hasSliceAndDiceLicence: getHasSliceAndDiceLicence(state),
    hasAnAwardSurvey: hasAnAwardsSurvey(state)
  }
}

const onLoad = dispatch => {
  dispatch(surveysStore.actions.loadData())
}

const mapDispatch = dispatch => ({
  onLoad: () => onLoad(dispatch),
  setActionPlanList: actionPlans => dispatch(setActionPlanList(actionPlans))
})

const SurveySelectionDashboard = connect(
  mapState,
  mapDispatch
)(SurveySelectionDashboardComponent)

export default SurveySelectionDashboard
