import React from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import { SignIn as SignInView } from "../../features/account"
import GradientLayout from "../../layouts/GradientLayout"
import SiteLogo from "../../features/ui/components/SiteLogo"

const cookieDomain = () => {
  if (typeof window !== "undefined") {
    return window.location?.hostname
  }
  return null
}

const SignIn = () => {
  return (
    <GradientLayout>
      <SignInView
        siteLogo={SiteLogo}
        title="Sign in"
        subtitle="to continue your journey to workplace engagement"
        redirectTo="/welcome"
        cancelRoute={getConfig().publicSite}
        domain={cookieDomain()}
      />
    </GradientLayout>
  )
}

export default SignIn
