import { Button } from "@engaging-tech/components"
import React from "react"

const ExpandCollapseButton = ({ expand, setExpand }) => {
  return (
    <>
      <Button
        bg="primary.0"
        minWidth={[175, 200]}
        // minWidth={[ 100]}
        onClick={() => setExpand(!expand)}
        leadingIcon={expand ? "expand_less" : "expand_more"}
        elevation={0}
        color="light.0"
        fontSize={[12, 3]}
        m={["6px"]}
        px={3}
      >
        {expand ? "COLLAPSE" : "EXPAND"}
      </Button>
    </>
  )
}

export default ExpandCollapseButton
