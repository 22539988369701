import { gql } from "../../../lib/Apollo"

export const LOAD_ORGANISATION = gql`
  query {
    getMyOrganisation {
      id
      name
      email
      features {
        object_type
        url
      }
      industry {
        id
        name
      }
      companyDetails {
        companyNameKey
        surveyCompanyId
      }
      credits
      accountPreferences {
        currency
      }
      country {
        name
        id
      }
      confirmed
      currentUser {
        firstName
        lastName
        isAdmin
      }
      services {
        name
        active
        expireDate
      }
      websiteURL
      wonAward
      accountPreferences {
        language
        countryId
        timezone
        currency
      }
    }
  }
`

export const LOAD_FULL_ORGANISATION = gql`
  query {
    getMyOrganisation {
      id
      name
      email
      industry {
        id
        name
      }
      credits
      country {
        name
        id
      }
      confirmed
      currentUser {
        firstName
        lastName
      }
      services {
        name
        active
        expireDate
      }
      accountPreferences {
        currency
      }
      websiteURL
      businessInformation {
        logoUrl
        contactEmail
        services
        size
        foundedAt
        locations {
          id
          country {
            id
            name
          }
          city
        }
        social {
          twitter
          youtube
          pinterest
          linkedIn
          facebook
          instagram
        }
      }
      culture {
        statement {
          content
          type
        }
      }
    }
  }
`

export const CREATE_ORGANISATION = gql`
  mutation (
    $name: String!
    $admin: adminUser!
    $countryId: ID!
    $industryId: ID!
    $allowMarketing: Boolean!
    $email: String!
    $websiteURL: String
    $phoneNumber: String
    $accountPreferences: AccountPreferencesInput!
  ) {
    createOrganisation(
      name: $name
      admin: $admin
      countryId: $countryId
      industryId: $industryId
      allowMarketing: $allowMarketing
      email: $email
      websiteURL: $websiteURL
      phoneNumber: $phoneNumber
      accountPreferences: $accountPreferences
    ) {
      id
      name
      email
      industry {
        id
        name
      }
      credits
      country {
        name
        id
      }
      confirmed
      currentUser {
        firstName
        lastName
      }
    }
  }
`

export const SEND_CONFIRMATION_EMAIL = gql`
  query {
    sendEmailConfirmation
  }
`

export const CONFIRM_EMAIL = gql`
  mutation ($confirmToken: String!) {
    verifyEmailConfirmation(confirmToken: $confirmToken)
  }
`

export const PURCHASE_EBN = gql`
  mutation ($source: String!, $promotionCodeId: String) {
    purchaseEBN(source: $source, promotionCodeId: $promotionCodeId) {
      status
      intentSecret
      action
    }
  }
`

export const UPDATE_BUSINESS_INFO = gql`
  mutation (
    $name: String!
    $industryId: ID!
    $websiteURL: String
    $culture: CultureInput
    $businessInformation: BusinessInformationInput
  ) {
    updateBusinessInformation(
      name: $name
      industryId: $industryId
      websiteURL: $websiteURL
      culture: $culture
      businessInformation: $businessInformation
    ) {
      id
      name
      email
      industry {
        id
        name
      }
      credits
      country {
        name
        id
      }
      confirmed
      currentUser {
        firstName
        lastName
      }
      services {
        name
        active
        expireDate
      }
      websiteURL
      businessInformation {
        logoUrl
        contactEmail
        services
        size
        foundedAt
        locations {
          id
          country {
            id
            name
          }
          city
        }
        social {
          twitter
          youtube
          pinterest
          linkedIn
          facebook
          instagram
        }
      }
      culture {
        statement {
          content
          type
        }
      }
    }
  }
`

export const PUBLISH_BIP = gql`
  mutation {
    publishBIP
  }
`

export const APPLY_DISCOUNT = gql`
  mutation ($service: String!, $discountCode: String!) {
    applyDiscount(service: $service, discountCode: $discountCode) {
      promotionCodeId
      percentOff
      amountOff
    }
  }
`

export const UPDATE_ACCOUNT_PREFERENCES = gql`
  mutation updateAccountPreferences($currency: String) {
    updateAccountPreferences(currency: $currency) {
      currency
    }
  }
`

export const SEND_MAGIC_LINK_EMAIL = gql`
  query getMagicLink($email: String) {
    getMagicLink(email: $email) {
      statusCode
    }
  }
`
