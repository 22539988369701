import { Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const ActionPlanTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  width: 688px;
`

export function ActionPlanTitleComponent({ surveyTypeTitle, title }) {
  return (
    <ActionPlanTitleContainer>
      <ActionPlanTitleComponentSurveyTitle surveyTypeTitle={surveyTypeTitle} />
      <ActionPlanTitleComponentTitle title={title} />
    </ActionPlanTitleContainer>
  )
}

export function ActionPlanTitleComponentSurveyTitle({ surveyTypeTitle }) {
  return (
    <Text fontSize="32px" fontWeight={700} lineHeight="32px" color="#013E4CDE">
      {surveyTypeTitle}
    </Text>
  )
}

export function ActionPlanTitleComponentTitle({ title }) {
  return (
    <Text
      mt="20px"
      fontSize="23px"
      fontWeight={400}
      lineHeight="20px"
      color="#013E4C"
    >
      {title}
    </Text>
  )
}
