import { connect } from "react-redux"
import MatricesComponent from "../components/Matrix"
import * as Selectors from "../store/matrices.selectors"

const mapState = (state, { name }) => ({
  matrixData: Selectors.getMatrixValues(state, name),
  quadrantDescriptions: Selectors.getMatrixBands(state, name),
  axisLabels: Selectors.getMatrixLabels(state, name),
  title: Selectors.getMatrixTitle(state, name),
  description: Selectors.getMatrixDescription(state, name)
})

const Matrices = connect(mapState)(MatricesComponent)

export default Matrices
