import { connect } from "react-redux"
import DataHighlightsComponent from "../components/DataHighlights"
import * as Selectors from "../store/datahighlights.selectors"
import { getReportsSurveyStartDate } from "../../Header/store/report.header.selectors"

const mapState = state => ({
  score: Selectors.getScore(state),
  industry: Selectors.getIndustryScore(state),
  trend: Selectors.getTrend(state),
  surveyResponseSize: Selectors.getResponseSize(state),
  surveyResponseRate: Selectors.getSurveyResponseRate(state),
  surveyStartDate: getReportsSurveyStartDate(state)
})

const DataHighlights = connect(mapState)(DataHighlightsComponent)

export default DataHighlights
