/* eslint-disable camelcase */
import { getConfig } from "@engaging-tech/ssr-config"
import { createSelector } from "reselect"

export const basketState = state => state && state.basket

export const getIsBasketLoading = createSelector(
  basketState,
  state => state?.isLoading
)

export const getBasketErrors = createSelector(
  basketState,
  state => state?.error.error
)

export const getBasketItems = createSelector(basketState, state => state?.data)

export const awardInBasket = createSelector(getBasketItems, basketItems =>
  basketItems.reduce((acc, next) => {
    if (Object.values(getConfig()?.awardTypes).includes(next.object_type)) {
      return next
    }
    return acc
  }, undefined)
)

export const getBasketLength = createSelector(
  basketState,
  state => state?.data?.length
)

export const getBasketTotals = createSelector(basketState, state => ({
  price: state?.price,
  totalPrice: state?.totalPrice,
  tax: state?.tax
}))

export const getAcceptedCurrencies = createSelector(
  basketState,
  state => state?.acceptedCurrencies
)

export const getDisclaimers = createSelector(getBasketItems, items =>
  items?.reduce((arr, item) => {
    if (item.disclaimer) arr.push(item.disclaimer)
    return arr
  }, [])
)

export const getAddedToBasket = createSelector(
  basketState,
  state => state?.addedToBasket
)
