 import React from "react"

import { OrgGuard } from "../../features/account"
import MainHeader from "../../features/actionPlans/components/MainHeader"
import ListActionPlansContainer from "../../features/actionPlans/containers/ListActionPlansContainer"
import DashboardLayout from "../../layouts/DashboardLayout"

const ActionPlansHome = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <MainHeader />
        <ListActionPlansContainer />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default ActionPlansHome
