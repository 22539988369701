import { connect } from "react-redux"
import WhyToEnter from "../../components/SelectProduct/WhyToEnter"
import { getAwardsTitle } from "../../store/awards.selectors"

const mapState = state => ({
  awardsTitle: getAwardsTitle(state)
})

const WhyToEnterContainer = connect(mapState)(WhyToEnter)

export default WhyToEnterContainer
