import React from "react"
import { Flex, Card, Box, Text } from "@engaging-tech/components"
import NoSubmissionsButton from "../containers/NoSubmissionsButton"

const NotEnoughSubmissions = () => {
  return (
    <Card
      mt={[5, 5, 6]}
      mb={[6, 5]}
      elevation={8}
      bg="light.0"
      alignItems="center"
      maxWidth="800px"
    >
      <Box bg="dark.4" py={[2, 3]} px={3} width={1 / 1} mb={5}>
        <Text
          fontSize={[3, 6]}
          textAlign="center"
          fontWeight="500"
          display="block"
        >
          Not Enough Submissions
        </Text>
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        px={3}
        pb={3}
        width={3 / 5}
      >
        <Text fontWeight="500" mb={3} width="auto" textAlign="center">
          It looks like you are searching for results with fewer than five
          submissions, so we are unable to produce this report.
        </Text>
        <Text
          fontSize={3}
          mb={3}
          width="auto"
          textAlign="center"
          color="dark.2"
        >
          Please try updating your filters to create a broader range.
        </Text>

        <NoSubmissionsButton />
      </Flex>
    </Card>
  )
}

export default NotEnoughSubmissions
