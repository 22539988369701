import React from "react"
import { useRouter, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import styled from "styled-components"
import { Flex } from "@engaging-tech/components"
import { linearGradient } from "polished"

import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"

const gradientCss = theme =>
  linearGradient({
    colorStops: ['#173F5A99', '#F0F8FFFF'],
    toDirection: "122deg",
    fallback: "transparent"
  })

const GradientBox = styled(Flex)`
  ${({ theme }) => gradientCss(theme)}
`

const GradientLayout = ({ children }) => {
  const router = useRouter()
  const paths = usePaths()
  const { downForMaintenance } = getConfig()
  if (downForMaintenance) {
    router.navigate(paths.downForMaintenance)
  }
  return (
    <>
      <TestingSiteBanner />
      <GradientBox
        as="main"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        {children}
      </GradientBox>
    </>
  )
}

export default GradientLayout
