import { types } from "./generic.actions"

export const initialState = {
  currentEditableQuestion: null,
  showValidationModal: false,
  showBranching: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EDITABLE_QUESTION: {
      return { ...state, currentEditableQuestion: action.payload }
    }

    case types.SET_SHOW_VALIDATION_MODAL: {
      return { ...state, showValidationModal: action.payload }
    }

    case types.RESET: {
      return initialState
    }
    case types.SET_BRANCHING_VIEW: {
      return { ...state, showBranching: action.payload }
    }

    default:
      return state
  }
}
