import { connect } from "react-redux"
import ReviewForm from "../../forms/Review"
import { getPostJob } from "../../../../store/jobs/jobs.selector"

const mapState = state => ({
  jobPost: getPostJob(state)
})

const Review = connect(mapState, null)(ReviewForm)

export default Review
