import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "@engaging-tech/routing"
import HoldingPage from "../containers/HoldingPage"

import Loader from "../containers/Loader"
import { ReportSelectors } from ".."
import ErrorView from "./ErrorView"
import NotEnoughSubmissionsView from "./NotEnoughSubmissions"
import { setSurveyType } from "../store/report.actions"
import { getSurveyType, getIsPdfDownloading } from "../store/report.selectors"
import OnePageReport from "../OnePageReport/containers/OnePageReport"

export const surveyTypes = {
  "engagement-surveys": ["organisation", "awards"],
  "new-starter-surveys": ["employeeStarter"],
  "exit-surveys": ["employeeExit"]
}

const ReportWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const { id, surveyType: type } = useParams()
  const surveyType = useSelector(getSurveyType)
  const isPdfDownloading = useSelector(getIsPdfDownloading)
  const hasResults = useSelector(ReportSelectors.getHasResults)
  const error = useSelector(ReportSelectors.getHasError)
  const isLoading = useSelector(ReportSelectors.getIsLoading)

  const hasDirectReports = false // how to get this?
  useEffect(() => {
    if (!surveyType) dispatch(setSurveyType(surveyTypes[type]))
  }, [dispatch, type, surveyType])
  const getView = () => {
    if (error) return <ErrorView errorType={error} />
    if (isLoading) return <HoldingPage />
    if (!hasResults && !isLoading) return <NotEnoughSubmissionsView />
    if (hasResults && !isLoading) return children
    return <ErrorView />
  }

  const getOnePager = () => {
    if (
      (surveyType.includes("organisation") &&
        isPdfDownloading &&
        hasResults &&
        !isLoading) ||
      (surveyType.includes("awards") &&
        isPdfDownloading &&
        hasResults &&
        !isLoading)
    )
      return <OnePageReport />
  }

  return (
    <>
      <Loader surveyId={id} directReports={hasDirectReports} />
      {getView()}
      {getOnePager()}
    </>
  )
}

export default ReportWrapper
