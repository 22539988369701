import { connect } from "react-redux"
import ActiveFiltersComponent from "../components/ActiveFilters"
import * as Selectors from "../store/filters.selectors"
import { ReportActions } from "../.."

const mapState = state => ({
  currentFilters: Selectors.getCurrentFilters(state),
  requiredFilters: Selectors.getRequiredFilters(state),
  isDirectReports: Selectors.getIsCurrentDirectReports(state)
})

const mapDispatch = dispatch => ({
  onUpdateFilters: filters => dispatch(ReportActions.updateFilters(filters)),
  setRequiredFilter: requiredFilter => {
    dispatch(ReportActions.updateFilters([]))
    dispatch(ReportActions.setRequiredFilter(requiredFilter))
  }
})

const ActiveFilters = connect(mapState, mapDispatch)(ActiveFiltersComponent)

export default ActiveFilters
