import React from "react"
import {
  Box,
  Button,
  Flex,
  TextField,
  CustomHooks
} from "@engaging-tech/components"
import styled from "styled-components"

/**
 * A form with a visibility toggle. We need to use this because Stripe
 * will break if we use conditional rendering.
 */
const HidableForm = styled.form`
  display: ${({ isVisible }) => (isVisible ? "block" : "none ")};
  width: 100%;
`

const InvoiceForm = ({
  handleSubmit,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  isValid,
  dirty,
  onBack
}) => {
  const device = CustomHooks.useClientDevice()

  return (
    <HidableForm onSubmit={handleSubmit} isVisible>
      <Flex
        mb={2}
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <TextField
          label="First name"
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          name="firstName"
          id="cardholder-name"
          borderRadius="4px 4px 0px 0px"
          type="text"
          value={values.firstName}
          error={touched.firstName && errors.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          label="Last name"
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          name="lastName"
          id="cardholder-name"
          borderRadius="4px 4px 0px 0px"
          type="text"
          value={values.lastName}
          error={touched.lastName && errors.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Flex>
      <Flex
        mb={2}
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <TextField
          label="Email Address "
          width={1 / 1}
          borderRadius="4px 4px 0px 0px"
          name="email"
          id="email"
          type="email"
          value={values.emailAddress}
          error={touched.emailAddress && errors.emailAddress}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Flex>
      <Box mb={2}>
        <TextField
          width={1 / 1}
          borderRadius="4px 4px 0px 0px"
          name="companyName"
          type="text"
          id="companyName"
          error={touched.companyName && errors.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.companyName}
          label="Company Name"
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Address line 1"
          width={1 / 1}
          name="addressFirstLine"
          borderRadius="4px 4px 0px 0px"
          id="address-first-line"
          type="text"
          value={values.addressFirstLine}
          error={touched.addressFirstLine && errors.addressFirstLine}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Address line 2 (optional)"
          width={1 / 1}
          borderRadius="4px 4px 0px 0px"
          name="addressSecondLine"
          id="address-second-line"
          type="text"
          value={values.addressSecondLine}
          error={touched.addressSecondLine && errors.addressSecondLine}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>
      <Flex
        mb={2}
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <TextField
          label="Post code"
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          borderRadius="4px 4px 0px 0px"
          name="postcode"
          id="postcode"
          type="text"
          value={values.postcode}
          error={touched.postcode && errors.postcode}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          label="Town / City"
          wrapperProps={{ width: device === "MOBILE" ? "100%" : "48%" }}
          name="city"
          borderRadius="4px 4px 0px 0px"
          id="city"
          type="text"
          value={values.city}
          error={touched.city && errors.city}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Flex>
      <Box mb={2}>
        <TextField
          width={1 / 1}
          borderRadius="4px 4px 0px 0px"
          name="country"
          id="country"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.country}
          label="Country"
        />
      </Box>
      <Flex
        width={1 / 1}
        flexDirection="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
      >
        <Button type="submit" disabled={!(isValid && dirty)}>
          Complete Purchase
        </Button>
        <Button type="button" variant="text.noBg.dark.1" onClick={onBack}>
          Cancel
        </Button>
      </Flex>
    </HidableForm>
  )
}

export default InvoiceForm
