import { Box, Card, Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const SixStepsReportCardTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[1]};
  margin: 0;
`

const SixStepsReportCardSubtitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[2]};
  margin: 0;
`

export default function SixStepsReportCard({
  title,
  subtitle,
  exportingPDF,
  isPdfDownloading,
  width,
  children,
  ...props
}) {
  return (
    <Card
      width={exportingPDF ? "1000px" : width ?? "100%"}
      p="24px"
      style={{ flexGrow: 1 }}
      justifyContent="flex-start"
      flexDirection="column"
      elevation={exportingPDF || isPdfDownloading ? 0 : 2}
      alignItems="center"
      m={2}
      position="relative"
      className={exportingPDF ? "report-item-exporting" : "report-item"}
      bg="light.0"
      {...props}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        width={1 / 1}
        height="100%"
      >
        <Flex flexDirection="column" width="100%">
          {title && <SixStepsReportCardTitle>{title}</SixStepsReportCardTitle>}
          {subtitle && (
            <SixStepsReportCardSubtitle
              color="dark.2"
              fontSize="18px"
              fontWeight="700"
              textAlign="left"
              style={{ margin: 0 }}
            >
              {subtitle}
            </SixStepsReportCardSubtitle>
          )}
        </Flex>
        <Box mt="20px" mb="10px">
          {children}
        </Box>
      </Flex>
    </Card>
  )
}
