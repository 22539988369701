import { connect } from "react-redux"
import AddOptionButton from "../../components/EditBody/AddOptionButton"
import { addOption } from "../../store/template.actions"

const mapDispatch = (dispatch, { id }) => ({
  onClick: () => dispatch(addOption(id))
})

const AddOptionContainer = connect(null, mapDispatch)(AddOptionButton)

export default AddOptionContainer
