import React from "react"
import { Flex, Text, Paragraph } from "@engaging-tech/components"
import styled from "styled-components"
import ContentBlock from "../../ui/components/ContentBlock"
import StartSurveyButtons from "./StartSurveyButtons"

const StyledImg = styled.img`
  width: 400px;
  height: auto;
  max-width: 100%;
`

const FlexibleSelfServeSurveys = () => {
  return (
    <ContentBlock pb={2} px={6}>
      <Flex flexDirection="column">
        <Flex flexDirection={["column", "row", "row"]} width={1 / 1}>
          <StyledImg src="https://d19vbgnwz7jfjm.cloudfront.net/Wellbeing_Six_Step.svg" />
          <Flex flexDirection="column">
            <Text
              color="secondary.0"
              fontSize={7}
              fontWeight="700"
              mt={[0, 4, 6]}
              mb={3}
              ml={[0, 2, 4]}
            >
              Keep your finger on the pulse
            </Text>
            <Paragraph
              color="secondary.0"
              fontSize={4}
              mb={4}
              ml={[0, 2, 4]}
              width={["100%", "90%", "80%"]}
            >
              Perfect for small and medium organisations, and as pulse surveys
              for large organisations, our self serve surveys are designed with
              ease, speed and flexibility in mind, without compromising on data,
              insight and support.
              <br />
              <br />
              Set up your survey in less than 10 minutes and get results
              instantly through our intuitive dashboards.
            </Paragraph>
          </Flex>
        </Flex>
        <Flex flexDirection="column" alignItems="center" mt={3}>
          <Text
            color="secondary.0"
            fontWeight={700}
            fontSize={6}
            textAlign="center"
            my={3}
          >
            Ready to get started?
          </Text>
          <StartSurveyButtons />
        </Flex>
      </Flex>
    </ContentBlock>
  )
}

export default FlexibleSelfServeSurveys
