import { gql } from "@apollo/client"

const LIST_PURCHASES = gql`
  query listPurchases($paymentId: String!) {
    listPurchases(paymentId: $paymentId) {
      name
      text
      faq
      welcomePack
    }
  }
`
export default LIST_PURCHASES
