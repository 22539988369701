/* eslint-disable prefer-destructuring */
import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import StepQuestionResultBlock from "./StepQuestionResultBlock"

const CustomFlex = styled(Flex)`
  position: sticky;
  background-color: white;
  top: 0;
  height: 73px;
  margin-bottom: 10px;
  padding-top: 7px;
  width: 100%;

  &:hover {
    z-index: 1;
  }
`

const StepQuestionText = styled.p`
  height: 63px;
  margin: 0;

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  color: ${({ theme }) => theme.colors.dark[1]};

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    height: auto;
    position: absolute;
    overflow: visible;
    width: 170px;
    z-index: 1;
    text-overflow: clip;
    background-color: ${({ theme }) => theme.colors.dark[4]};
    padding: 8px;
    top: -1px;
    left: -8px;
    border-radius: 4px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
`

const getComparisonData = (
  allComparisonSteps,
  questionGroupStep,
  stepQuestion
) => {
  const foundStep = allComparisonSteps?.filter(
    ({ step }) => questionGroupStep === step.trim()
  )

  if (foundStep?.length) {
    const foundQuestion = foundStep[0]?.questions?.filter(
      ({ id }) => stepQuestion?.id === id
    )

    if (foundQuestion?.length)
      return {
        score: foundQuestion[0]?.values?.score,
        questionId: foundQuestion[0]?.id
      }
  }

  return { score: null, questionId: null }
}

const StepQuestionColumn = ({
  stepQuestion,
  allBusinessAreas,
  comparisonReport,
  isComparisonLoading,
  comparisonSurveyTitle,
  heatmapFilters,
  questionGroupStep,
  reportId,
  questionValuesMap,
  showGlobalResult,
  firstGroupAndQuestion,
  allComparisonSteps,
  showPreviousComp,
  comparisonFilters,
  showCurrentComp
}) => {
  const allBusinessloading = allBusinessAreas.loading
  const { score, questionId } = getComparisonData(
    allComparisonSteps,
    questionGroupStep,
    stepQuestion
  )

  return (
    <Flex borderRadius={5} width={150} flexDirection="column" p={2}>
      <CustomFlex className="heatmaps-questions-header">
        <StepQuestionText>{stepQuestion.question}</StepQuestionText>
      </CustomFlex>

      {allBusinessloading ? (
        <>
          <StepQuestionResultBlock
            loading
            score={null}
            nodata={false}
            labelToRender="Your Score"
            renderLabel={showGlobalResult || showPreviousComp}
            firstGroupAndQuestion={firstGroupAndQuestion}
          />

          {showGlobalResult ? (
            <>
              <StepQuestionResultBlock
                loading
                score={null}
                nodata={false}
                labelToRender="Industry"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
              <StepQuestionResultBlock
                loading
                score={null}
                nodata={false}
                labelToRender="Global"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            </>
          ) : null}
        </>
      ) : (
        <>
          <StepQuestionResultBlock
            loading={false}
            score={stepQuestion?.values?.score}
            nodata={false}
            questionId={stepQuestion.id}
            questionGroupStep={questionGroupStep}
            reportId={reportId}
            firstGroupAndQuestion={firstGroupAndQuestion}
            labelToRender="Your Score"
            renderLabel={showGlobalResult || showPreviousComp}
            showCurrentComp={showCurrentComp}
          />
          {showGlobalResult ? (
            <>
              <StepQuestionResultBlock
                loading={false}
                score={stepQuestion?.values?.industry}
                nodata={!stepQuestion?.values?.industry}
                questionId={stepQuestion.id}
                questionGroupStep={questionGroupStep}
                reportId={reportId}
                labelToRender="Industry"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
              <StepQuestionResultBlock
                loading={false}
                score={stepQuestion?.values?.global}
                nodata={!stepQuestion?.values?.global}
                questionId={stepQuestion.id}
                questionGroupStep={questionGroupStep}
                reportId={reportId}
                labelToRender="Global"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            </>
          ) : null}
        </>
      )}

      {isComparisonLoading && showPreviousComp && (
        <StepQuestionResultBlock
          loading
          score={null}
          nodata={false}
          renderLabel={isComparisonLoading}
          firstGroupAndQuestion={firstGroupAndQuestion}
          labelToRender={"Previous Data" || comparisonSurveyTitle}
        />
      )}

      {comparisonReport && showPreviousComp && (
        <StepQuestionResultBlock
          loading={false}
          score={score}
          nodata={!score}
          questionId={questionId}
          questionGroupStep={questionGroupStep}
          reportId={comparisonReport?.reportId}
          renderLabel={!!comparisonReport}
          firstGroupAndQuestion={firstGroupAndQuestion}
          labelToRender={"Previous Data" || comparisonSurveyTitle}
        />
      )}

      {heatmapFilters.map(filter => {
        const comparisonFilter = comparisonFilters.filter(
          compFilter =>
            JSON.stringify(filter.heatmapFilter) ===
            JSON.stringify(compFilter.heatmapFilter)
        )

        if (
          (showPreviousComp && filter.loading) ||
          (showPreviousComp && comparisonFilter[0].loading)
        ) {
          return (
            <>
              <StepQuestionResultBlock
                key={filter.id}
                loading={true}
                score={null}
                nodata={false}
                labelToRender="Your Score"
                renderLabel={true}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />

              <StepQuestionResultBlock
                key={`${filter.id}-${comparisonFilter[0].id}`}
                loading={true}
                score={null}
                nodata={false}
                labelToRender={"Previous Data" || comparisonSurveyTitle}
                renderLabel={true}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            </>
          )
        } else if (filter.loading) {
          return (
            <>
              <StepQuestionResultBlock
                key={`StepQuestionResultBlock-${filter.id}`}
                loading
                score={null}
                nodata={false}
                labelToRender="Your Score"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
              {showGlobalResult ? (
                <>
                  <StepQuestionResultBlock
                    key={`StepQuestionResultBlock-${filter.id}-industry`}
                    loading
                    score={null}
                    nodata={false}
                    labelToRender="Industry"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                  <StepQuestionResultBlock
                    key={`StepQuestionResultBlock-${filter.id}-global`}
                    loading
                    score={null}
                    nodata={false}
                    labelToRender="Global"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                </>
              ) : null}
            </>
          )
        }

        if (filter.response?.data?.sixStepsQuestions?.length > 0) {
          const questionScore = questionValuesMap.get(
            `${stepQuestion.id}-${filter.id}`
          )

          const mainScoresComb = [
            ...(filter.response?.data?.sixStepsQuestions ?? []),
            ...(filter.response?.data?.customSteps ?? [])
          ]

          const mainScore = mainScoresComb.filter(
            ({ step }) => step === questionGroupStep
          )

          const mainQuestion =
            mainScore?.length &&
            mainScore[0]?.questions.filter(({ id }) => id === stepQuestion.id)

          const comparisonScoresComb = [
            ...(comparisonFilter[0]?.response.data.sixStepsQuestions ?? []),
            ...(comparisonFilter[0]?.response.data.customSteps ?? [])
          ]

          const comparisonScore = comparisonScoresComb.filter(
            ({ step }) => step === questionGroupStep
          )

          const comparisonQuestion =
            comparisonScore?.length &&
            comparisonScore[0]?.questions.filter(
              ({ id }) => id === stepQuestion.id
            )

          return (
            <>
              <StepQuestionResultBlock
                key={`StepQuestionResultBlock-${filter.id}`}
                loading={false}
                score={mainQuestion[0]?.values.score}
                nodata={
                  mainQuestion.length && mainQuestion[0]?.values.score
                    ? false
                    : true
                }
                questionId={stepQuestion.id}
                questionGroupStep={questionGroupStep}
                reportId={filter?.response?.reportId}
                labelToRender="Your Score"
                renderLabel={showGlobalResult || showPreviousComp}
                firstGroupAndQuestion={firstGroupAndQuestion}
                showCurrentComp={showCurrentComp}
              />
              {!!comparisonFilter.length && showPreviousComp && (
                <StepQuestionResultBlock
                  key={`${filter.id}-${comparisonFilter[0]?.id}`}
                  loading={false}
                  score={comparisonQuestion[0]?.values.score}
                  nodata={
                    comparisonQuestion.length &&
                    comparisonQuestion[0]?.values.score
                      ? false
                      : true
                  }
                  questionId={stepQuestion.id}
                  questionGroupStep={questionGroupStep}
                  reportId={comparisonFilter[0].response?.reportId}
                  labelToRender="Previous Score"
                  renderLabel={true}
                  firstGroupAndQuestion={firstGroupAndQuestion}
                />
              )}
              {showGlobalResult ? (
                <>
                  <StepQuestionResultBlock
                    key={`StepQuestionResultBlock-${filter.id}-industry`}
                    loading={false}
                    score={questionScore?.industry}
                    nodata={!questionScore?.industry}
                    questionId={stepQuestion.id}
                    questionGroupStep={questionGroupStep}
                    reportId={filter?.response?.reportId}
                    labelToRender="Industry"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                  <StepQuestionResultBlock
                    key={`StepQuestionResultBlock-${filter.id}-global`}
                    loading={false}
                    score={questionScore?.global}
                    nodata={!questionScore?.global}
                    questionId={stepQuestion.id}
                    questionGroupStep={questionGroupStep}
                    reportId={filter?.response?.reportId}
                    labelToRender="Global"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                </>
              ) : null}
            </>
          )
        }
        return (
          <>
            <StepQuestionResultBlock
              loading={false}
              score={null}
              nodata
              labelToRender="Your Score"
              renderLabel={showGlobalResult || showPreviousComp}
              firstGroupAndQuestion={firstGroupAndQuestion}
            />
            {showPreviousComp && (
              <StepQuestionResultBlock
                loading={false}
                score={null}
                nodata
                labelToRender="Previous Score"
                renderLabel={showGlobalResult || showPreviousComp}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            )}
            {showGlobalResult ? (
              <>
                <StepQuestionResultBlock
                  loading={false}
                  score={null}
                  nodata
                  labelToRender="Industry"
                  renderLabel={showGlobalResult}
                  firstGroupAndQuestion={firstGroupAndQuestion}
                />
                <StepQuestionResultBlock
                  loading={false}
                  score={null}
                  nodata
                  labelToRender="Global"
                  renderLabel={showGlobalResult}
                  firstGroupAndQuestion={firstGroupAndQuestion}
                />
              </>
            ) : null}
          </>
        )
      })}
    </Flex>
  )
}

export default StepQuestionColumn
