export const types = {
  LOAD_DEFAULT_TEMPLATES: "[TemplateSelection] Load Default Templates",
  LOAD_DEFAULT_TEMPLATES_SUCCESS:
    "[TemplateSelection] Load Default Templates Success",
  LOAD_DEFAULT_TEMPLATES_FAILURE:
    "[TemplateSelection] Load Default Templates Failure",
  LOAD_ORG_TEMPLATES: "[TemplateSelection] Load Organisation Templates",
  LOAD_ORG_TEMPLATES_SUCCESS:
    "[TemplateSelection] Load Organisation Templates Success",
  LOAD_ORG_TEMPLATES_FAILURE:
    "[TemplateSelection] Load Organisation Templates Failure",
  DELETE_ORG_TEMPLATE: "[TemplateSelection] Delete Organisation Template",
  DELETE_ORG_TEMPLATE_SUCCESS:
    "[TemplateSelection] Delete Organisation Template Success",
  DELETE_ORG_TEMPLATE_FAILURE:
    "[TemplateSelection] Delete Organisation Template Failure",
  RESET: "[TemplateSelection] Reset"
}

export const loadDefaultTemplates = () => ({
  type: types.LOAD_DEFAULT_TEMPLATES
})

export const loadDefaultTemplatesSuccess = payload => ({
  type: types.LOAD_DEFAULT_TEMPLATES_SUCCESS,
  payload
})

export const loadDefaultTemplatesFailure = () => ({
  type: types.LOAD_DEFAULT_TEMPLATES_FAILURE
})

export const loadOrgTemplates = () => ({
  type: types.LOAD_ORG_TEMPLATES
})

export const loadOrgTemplatesSuccess = payload => ({
  type: types.LOAD_ORG_TEMPLATES_SUCCESS,
  payload
})

export const loadOrgTemplatesFailure = () => ({
  type: types.LOAD_ORG_TEMPLATES_FAILURE
})

export const deleteOrgTemplate = payload => ({
  type: types.DELETE_ORG_TEMPLATE,
  payload
})

export const deleteOrgTemplateSuccess = payload => ({
  type: types.DELETE_ORG_TEMPLATE_SUCCESS,
  payload
})

export const deleteOrgTemplateFailure = () => ({
  type: types.DELETE_ORG_TEMPLATE_FAILURE
})

export const reset = () => ({
  type: types.RESET
})
