import { Button, CustomHooks } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

const ColourPickerComponent = styled.input`
  opacity: 0;
  position: absolute;
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  :hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`

const ColourPicker = ({
  value = "",
  error = "",
  id = "",
  name = "",
  onChange = () => null,
  label = "",
  width = "120px",
  height = "36px",
  colourProps = {},
  disabled = false,
  children,
  ...props
}) => {
  const [colour, setColour] = useState(value)

  const debouncedColour = CustomHooks.useDebounce(colour, 100)

  useEffect(() => onChange(debouncedColour), [debouncedColour])

  return (
    <Button width={width} height={height} {...props} elevation={0}>
      <label htmlFor={id} width={width} height={height}>
        {children || label}
      </label>
      <ColourPickerComponent
        type="color"
        id={id}
        name={name}
        value={value}
        onChange={e => setColour(e.target.value)}
        onSelect={e => onChange(e.target.value)}
        height={height}
        width={width}
        error={error}
        disabled={disabled}
      />
    </Button>
  )
}

export default ColourPicker
