import React from "react"
import { StyledTextField as StyledTextFieldComponent } from "../../ui/components/StyledTextField"

const preventDefaultSubmit = e => {
  const keyCode = typeof e.which === "number" ? e.which : e.keyCode
  if (keyCode === 13) {
    e.preventDefault()
  }
}

const TextField = ({
  type = "text",
  onKeyPress = e => preventDefaultSubmit(e),
  preview = false,
  ...props
}) => (
  <StyledTextFieldComponent
    type={type}
    preview={preview}
    onKeyPress={onKeyPress}
    width={1 / 1}
    borderRadius={10}
    {...props}
  />
)

export default TextField
