import { useState, useEffect } from "react"
import { CustomHooks } from "@engaging-tech/components"

const useDebouncedInput = (value, callback, timeout = 1000) => {
  const [inputLocal, setInputLocal] = useState(value)

  const debouncedInputLocal = CustomHooks.useDebounce(inputLocal, timeout)

  useEffect(() => {
    callback(inputLocal)
    setInputLocal(inputLocal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputLocal])

  useEffect(() => {
    if (inputLocal !== value) {
      setInputLocal(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return [inputLocal, setInputLocal]
}

export default useDebouncedInput
