import { Button, Flex, Icon, Modal } from "@engaging-tech/components"
import { CircleButton } from "@engaging-tech/components/dist/lib/IconButton/IconButton"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const CustomModal = styled(Modal)`
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ImageStyled = styled.img`
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 768px;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const SliceAndDiceCompareModal = ({
  setShowPreview,
  showSurveyLabel = false
}) => {
  const imageSource = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/sd_compare${
    showSurveyLabel ? "_survey" : ""
  }.png`)

  const closeModal = () => {
    setShowPreview(false)
  }

  return createPortal(
    <CustomModal bg="secondary.0" maxWidth="max-content" width={1 / 1}>
      <Flex flexDirection="column" width={1 / 1} p={30}>
        <Flex justifyContent="flex-end">
          <CircleButton size={32} onClick={closeModal}>
            <Icon name="close" size={32} color="#fff" />
          </CircleButton>
        </Flex>
        <Flex alignItems="center" px={80} py={20}>
          <ImageStyled src={imageSource} />
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            bg={"primary.0"}
            color="light.0"
            width="80px"
            mr={80}
            onClick={closeModal}
          >
            CLOSE
          </Button>
        </Flex>
      </Flex>
    </CustomModal>,
    appRoot()
  )
}

export default SliceAndDiceCompareModal
