import React from "react"
import { Thankyou as ThankyouView } from "../../features/account"

import GradientLayout from "../../layouts/GradientLayout"

const Thankyou = () => (
  <GradientLayout>
    <ThankyouView />
  </GradientLayout>
)

export default Thankyou
