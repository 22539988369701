import { connect } from "react-redux"
import SixStepsComponent from "../components/SixSteps"
import * as Selectors from "../store/sixsteps.selectors"

const mapState = state => ({
  sixSteps: Selectors.getSixSteps(state)
})

const SixSteps = connect(mapState)(SixStepsComponent)

export default SixSteps
