import {
  CustomHooks,
  Flex,
  Icon,
  MotionFlex,
  Text
} from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { TemplateSelectionSelectors } from "../templateSelection"

const bespokePoints = [
  "Build a completely bespoke survey",
  "Add unlimited custom questions with 4 different question types",
  "Add your business' branding to create a cohesive experience."
]

const standardPoints = [
  "Ask core questions about workplace engagement, categorised by the Six Steps",
  "Add custom sections and up to two custom questions to the template",
  "Receive tailored action planning to improve your scores"
]

const exitPoints = [
  "Ask core questions about the engagement of exiting employees as part of the exit process",
  "HR, Management and/or employee receive instant results via email",
  "HR and/or Management access to always on dashboard tracking overall sentiment of leavers"
]

const starterPoints = [
  "Ask core questions about the engagement of new starters to monitor the success of onboarding",
  "HR, Management and/or employee receive instant results via email",
  "HR and/or Management access to always on dashboard tracking overall sentiment of new starters"
]

const getPoints = (isStarter, isExit, isBespoke) => {
  if (isStarter) {
    return starterPoints
  }
  if (isExit) {
    return exitPoints
  }
  if (isBespoke) {
    return bespokePoints
  }
  return standardPoints
}

const hoverLabel = (isStarter, isExit, isBespoke) => {
  if (isStarter) {
    return "Starter"
  }
  if (isExit) {
    return "Exit"
  }
  if (isBespoke) {
    return "Bespoke"
  }
  return "Standard"
}

const delayTiming = 0.04
const tabHeight = 32
const tabWidth = 110
const priceHeight = 30

const Points = ({
  isBespoke,
  extendedHeight,
  extendedWidth,
  isStarter,
  isExit
}) => {
  const points = getPoints(isStarter, isExit, isBespoke)
  return (
    <MotionFlex
      position="relative"
      height={extendedHeight - tabHeight - priceHeight}
      width={extendedWidth - 20}
      flexDirection="column"
      mt={2}
      variants={{
        unActive: {
          opacity: 0,
          display: "none",

          transition: {
            delay: points.length * delayTiming,
            ease: "easeInOut",
            duration: 0.2
          }
        },
        active: {
          opacity: 1,
          display: "flex",
          transition: { delay: 0.1, ease: "easeInOut", duration: 0.2 }
        }
      }}
    >
      {points.map((pointText, index) => (
        <MotionFlex
          key={pointText}
          variants={{
            unActive: {
              opacity: 0,

              transition: {
                delay: index * delayTiming,
                ease: "easeInOut",
                duration: 0.2
              }
            },
            active: {
              opacity: 1,

              transition: {
                delay: points.length * delayTiming - index * delayTiming + 0.17,
                ease: "easeInOut",
                duration: 0.2
              }
            }
          }}
          alignItems="center"
          m={2}
        >
          <Icon name="fiber_manual_record" size={10} mr={2} />
          <Text fontSize="14px">{pointText}</Text>
        </MotionFlex>
      ))}
    </MotionFlex>
  )
}

const Price = ({ isBespoke, border, extendedWidth, isStarter, isExit }) => {
  const points = getPoints(isStarter, isExit, isBespoke)
  return (
    <MotionFlex
      position="relative"
      height={priceHeight}
      width={extendedWidth}
      flexDirection="column"
      style={{ borderTop: border }}
      mb={3}
      variants={{
        unActive: {
          opacity: 0,
          display: "none",
          transition: {
            delay: points.length * delayTiming,
            ease: "easeInOut",
            duration: 0.2
          }
        },
        active: {
          opacity: 1,
          display: "flex",
          transition: { delay: 0.1, ease: "easeInOut", duration: 0.2 }
        }
      }}
      pt="14px"
      pl="14px"
      pr="14px"
    >
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Text fontSize="14px" width="auto">
          {isBespoke ? "Bespoke licence fee" : "Standard licence fee"}
        </Text>
        <Text fontSize="14px" width="auto" fontWeight={700}>
          {
            // Telegraph survey licence price change
          }
          {isBespoke || isStarter || isExit ? "£2,500 + VAT" : "£500 + VAT"}
        </Text>
      </Flex>
    </MotionFlex>
  )
}

const Tab = ({ extendedWidth, isBespoke, border, isStarter, isExit }) => {
  return (
    <MotionFlex
      position="relative"
      height={tabHeight}
      variants={{
        unActive: {
          width: tabWidth,
          transition: { delay: 0.3, duration: 0.3, ease: "easeInOut" }
        },
        active: {
          width: extendedWidth,
          transition: { duration: 0.2, ease: "easeInOut" }
        }
      }}
      maxWidth={extendedWidth}
      borderRadius={tabHeight / 2}
      alignItems="center"
      p={2}
      bg={isBespoke ? "#FFF4EF" : "#DBEFFF"}
      border={border}
    >
      <MotionFlex
        width="100%"
        height="auto"
        justifyContent="space-between"
        alignItems="center"
        variants={{
          unActive: {
            x: 0,
            transition: {
              delay: 0.35
            }
          },
          active: {
            x: -1,
            transition: {
              delay: 0.05
            }
          }
        }}
      >
        <Text width="auto" fontSize={3} mr={2} fontWeight={700}>
          {hoverLabel(isStarter, isExit, isBespoke)}
        </Text>
        <Icon name="help" size={20} color={isBespoke ? "#E66F3D" : "#173C5A"} />
      </MotionFlex>
    </MotionFlex>
  )
}

const TemplateTypeToolTip = ({
  isBespoke = false,
  autoPopUp = false,
  templateId,
  ...props
}) => {
  const [hovered, setHovered] = useState(false)

  const isDefaultStarter =
    useSelector(state =>
      TemplateSelectionSelectors.getDefaultTemplateType(state, templateId)
    ) === "employeeStarter"
  const isMySurveyTemplatesStarter =
    useSelector(state =>
      TemplateSelectionSelectors.getOrgTemplateType(state, templateId)
    ) === "employeeStarter"
  const isDefaultExit =
    useSelector(state =>
      TemplateSelectionSelectors.getDefaultTemplateType(state, templateId)
    ) === "employeeExit"
  const isMySurveyTemplatesExit =
    useSelector(state =>
      TemplateSelectionSelectors.getOrgTemplateType(state, templateId)
    ) === "employeeExit"

  const isStarter = isDefaultStarter || isMySurveyTemplatesStarter

  const isExit = isDefaultExit || isMySurveyTemplatesExit

  const wrapperRef = useRef(null)
  CustomHooks.useOutsideAlerter(wrapperRef, () => setHovered(false))

  useEffect(() => {
    if (autoPopUp) {
      setHovered(true)
      setTimeout(() => {
        setHovered(false)
      }, 5000)
    }
  }, [isBespoke])

  const extendedHeight = isBespoke ? 230 : 280
  const extendedWidth = 270
  const border = `1px solid ${isBespoke ? "#E66F3D" : "#173C5A"}`
  const noBorder = `0px solid ${isBespoke ? "#E66F3D" : "#173C5A"}`

  return (
    <MotionFlex
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      onTap={() => setHovered(!hovered)}
      ref={wrapperRef}
      initial="unActive"
      animate={hovered ? "active" : "unActive"}
      width="auto"
      bg="light.0"
      justifyContent="flex-end"
      variants={{
        unActive: {
          height: tabHeight,
          borderTop: noBorder,
          borderLeft: noBorder,
          borderRight: noBorder,
          transition: {
            delay: 0.2,
            ease: "easeInOut",
            duration: 0.2
          }
        },
        active: {
          height: extendedHeight,
          transition: { delay: 0.1, ease: "easeInOut", duration: 0.2 },
          borderTop: border,
          borderLeft: border,
          borderRight: border
        }
      }}
      borderRadius={tabHeight / 2}
      flexDirection="column"
      {...props}
    >
      <Points
        isStarter={isStarter}
        isExit={isExit}
        isBespoke={isBespoke}
        extendedWidth={extendedWidth}
        extendedHeight={extendedHeight}
      />
      <Price
        isStarter={isStarter}
        isExit={isExit}
        isBespoke={isBespoke}
        border={border}
        extendedWidth={extendedWidth}
      />
      <Tab
        isStarter={isStarter}
        isExit={isExit}
        isBespoke={isBespoke}
        extendedWidth={extendedWidth}
        border={border}
      />
    </MotionFlex>
  )
}

export default TemplateTypeToolTip
