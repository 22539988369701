import React from "react"

import { OrgGuard } from "../../features/account"
import { JobMatches } from "../../features/jobs"
import BackButton from "../../features/ui/components/BackButton"

import PageLayout from "../../layouts/PageLayout"

const Matches = () => {
  return (
    <OrgGuard>
      <PageLayout title="Job Matches">
        <BackButton ml={[3, 3, 5]} mt={[3, 3, 5]} path="/jobs/live">
          Back to Jobs
        </BackButton>
        <JobMatches />
      </PageLayout>
    </OrgGuard>
  )
}

export default Matches
