import {
  Box,
  Flex,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import { CREATE_OR_UPDATE_BIP } from "../../../graphql/mutations"
import { GET_BIP } from "../../../graphql/queries"
import useAppSyncMutation from "../../../hooks/useAppSyncMutation"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { AccountSelectors } from "../../account"
import { load } from "../../account/store/account.actions"
import ErrorState from "../components/error-state"
import FormFooter from "../components/form-footer"
import useBIPNavigationContext from "../hooks/use-bip-navigation-context"
import BusinessInformationForm from "./tabs/business-information"
import Finish from "./tabs/finish"
import Review from "./tabs/review"
import YourCulture from "./tabs/your-culture"

const FormWrapper = styled.form`
  width: 100%;
`

export default function BipForm() {
  const form = useFormikContext()

  const dispatch = useDispatch()

  const { activeTab } = useBIPNavigationContext()

  const { data, isLoading } = useBusinessAppQuery(GET_BIP)

  useEffect(() => {
    if (data?.getBIP) {
      form.setValues({
        ...data.getBIP.summary,
        foundedAt: new Date(data.getBIP.summary.foundedAt).getFullYear(),
        culture: data.getBIP.culture
          ? {
              statement: data.getBIP.culture.statement || "",
              shortStatement: data.getBIP.culture.shortStatement || ""
            }
          : null,
        contactEmail: data.getBIP.summary.contactEmail || "",
        social: {
          twitter: data.getBIP.summary.social?.twitter || "",
          linkedIn: data.getBIP.summary.social?.linkedIn || "",
          pinterest: data.getBIP.summary.social?.pinterest || "",
          facebook: data.getBIP.summary.social?.facebook || "",
          instagram: data.getBIP.summary.social?.instagram || "",
          youtube: data.getBIP.summary.social?.youtube || ""
        },
        awardsLogoList: data.getBIP.summary?.awardsLogo?.length
          ? data.getBIP.summary.awardsLogo.map((item, index) => ({
              id: index,
              link: item
            }))
          : [
              {
                id: 0,
                link: ""
              }
            ]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const {
    post,
    errors,
    data: mutationdata,
    isLoading: mutationIsLoading
  } = useAppSyncMutation(CREATE_OR_UPDATE_BIP)

  useEffect(() => {
    if (mutationdata?.createOrUpdateBIP?.statusCode === "200") {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "BIP updated successfully"
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationdata])

  const orgId = useSelector(AccountSelectors.getOrgId)

  const onSubmit = async (values, publish) => {
    const awardsLogoList = []
    for (const awardLogo of values.awardsLogoList) {
      if (awardLogo.link) {
        awardsLogoList.push(awardLogo.link)
      }
    }

    await post({
      input: {
        organisationId: orgId,
        status:
          data?.getBIP?.status === "PUBLISHED"
            ? "PUBLISHED"
            : publish
            ? "PUBLISHED"
            : "DRAFT",
        culture: {
          statement: values.culture.statement,
          shortStatement: values.culture.shortStatement
        },
        summary: {
          logoUrl: values.logoUrl,
          banner: values.banner?.length ? values.banner : null,
          name: values.name,
          websiteURL: values.websiteURL,
          industryId: values.industryId,
          contactEmail: values.contactEmail,
          services: values.services,
          size: values.size,
          foundedAt: new Date(
            new Date().setFullYear(values.foundedAt, 1, 1)
          ).toISOString(),
          locations: values.locations.map(item => ({
            city: item.city,
            countryId: item.countryId
          })),
          social: values.social,
          videos: values.videos,
          awardsLogo: awardsLogoList
        }
      }
    })
    dispatch(load())
  }

  if (errors) return <ErrorState />

  return (
    <FormWrapper>
      <Flex flexDirection="column" maxWidth={1080} margin="auto" px={3} mb={50}>
        <Box mt={4}>
          {activeTab === "business-information" ? (
            <BusinessInformationForm
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          ) : null}
          {activeTab === "your-culture" ? (
            <YourCulture isLoading={isLoading} />
          ) : null}
          {activeTab === "review" ? <Review /> : null}
          {activeTab === "finish" ? <Finish /> : null}
          <FormFooter
            isLoading={isLoading || mutationIsLoading}
            onSubmit={onSubmit}
          />
        </Box>
      </Flex>
    </FormWrapper>
  )
}
