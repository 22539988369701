import { Box, Flex, H2, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import ClickableIcon from "../../../ui/components/ClickableIcon"
import Seperator from "../../../ui/components/Separator"
import {
  Card,
  DatePicker,
  TemplateTypeToolTip,
  TextField
} from "../../components"
import TemplateOptionsWrapper from "../../surveyInfo/components/TemplateOptionsWrapper"

const EditableField = ({ heading, children, onEdit = () => null }) => {
  const [editing, setEditing] = useState(false)

  const handleEdit = () => {
    setEditing(!editing)
    onEdit()
  }

  return (
    <Box>
      <Flex mb={3}>
        <Text>{heading}</Text>
        <ClickableIcon name="mode_edit" onClick={handleEdit} />
      </Flex>
      {(typeof children === "function" && children({ editing })) || children}
    </Box>
  )
}

const Review = ({ values, errors, handleChange, setFieldValue, ...props }) => {
  const paths = usePaths()
  const router = useRouter()
  return (
    <Card {...props}>
      <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={5}>
        Survey review
      </H2>
      <EditableField heading="Survey name">
        {({ editing }) =>
          editing ? (
            <TextField
              name="title"
              value={values && values.title}
              error={errors && errors.title}
              onChange={handleChange}
            />
          ) : (
            <Text fontSize={4} fontWeight="bold">
              <b>{values.title}</b>
            </Text>
          )
        }
      </EditableField>
      <Seperator width={1 / 1} lineColor="dark.3" />
      <EditableField heading="Survey template">
        {({ editing }) =>
          editing ? (
            <TemplateOptionsWrapper
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          ) : (
            <Flex
              alignItems={["flex-start", "center"]}
              flexDirection={["column", "row"]}
            >
              <Text fontWeight="bold" width="auto" mr={5} mb={[3, 0]}>
                <b>{values.template.name}</b>
              </Text>
              <TemplateTypeToolTip templateId={values.template.id} />
            </Flex>
          )
        }
      </EditableField>
      <Seperator width={1 / 1} lineColor="dark.3" />
      <EditableField
        heading="Participant details"
        onEdit={() =>
          router.navigate(paths.surveys.surveyCreation.participants)
        }
      >
        <Text fontWeight="bold">
          <b>
            {values.mode === "uniqueSubmission"
              ? `${values.recipients.length} email addresses added`
              : `${values.amount} shareable links generated`}
          </b>
        </Text>
      </EditableField>
      <Seperator width={1 / 1} lineColor="dark.3" />
      <EditableField heading="Survey time frame">
        {({ editing }) => (
          <Flex flexDirection={["column", "row"]}>
            <Text>
              Start Date:{" "}
              {editing ? (
                <DatePicker
                  width="max-content"
                  name="startDate"
                  onChange={date => setFieldValue("startDate", date)}
                  value={values.startDate}
                  error={errors.startDate}
                />
              ) : (
                <b>{values.startDate.toLocaleDateString()}</b>
              )}
            </Text>
            <Text>
              End Date:{" "}
              {editing ? (
                <DatePicker
                  width="max-content"
                  name="endDate"
                  onChange={date => setFieldValue("endDate", date)}
                  value={values.endDate}
                  error={errors.endDate}
                />
              ) : (
                <b>{values.endDate.toLocaleDateString()}</b>
              )}
            </Text>
            <Text>
              Length of survey:{" "}
              {
                <b>
                  {Math.ceil(
                    Math.abs(values.endDate - values.startDate) /
                      (1000 * 60 * 60 * 24)
                  )}{" "}
                  days
                </b>
              }
            </Text>
          </Flex>
        )}
      </EditableField>
    </Card>
  )
}

export default Review
