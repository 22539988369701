import { Utils } from "@engaging-tech/components"

const { getDefaultActions } = Utils.coreStore

const defaultActions = getDefaultActions("surveySelection.surveys")

export const types = {
  ...defaultActions.types,
  DELETE_SURVEY: "[surveySelection.surveys] Delete a survey"
}

export const {
  updateData,
  loadData,
  updateLoading,
  updateError,
  resetStore
} = defaultActions.actions

export const deleteSurvey = payload => ({
  type: types.DELETE_SURVEY,
  payload
})
