import React, { useContext } from "react"
import { Flex, Text, RoundedDropdown } from "@engaging-tech/components"
import TemplateQuestionContext from "../TemplateQuestionContext"

const options = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
]

const OptionsDropdown = ({ int, onChange, availableOptions }) => (
  <RoundedDropdown width="70px" onChange={onChange} value={int}>
    {availableOptions?.map(option => (
      <option value={option} key={option}>
        {option}
      </option>
    ))}
  </RoundedDropdown>
)

const ScaleQuestionEdit = ({ changeScale }) => {
  const { min, max, isCore, id } = useContext(TemplateQuestionContext)

  const availableMin = options.filter(option => option < max)
  const availableMax = options.filter(option => option > min)

  return (
    <Flex alignItems="center" mt={isCore ? "0px" : "16px"}>
      <Flex
        borderRadius="100%"
        bg="dark.4"
        height="40px"
        alignItems="center"
        width="auto"
      >
        {isCore ? (
          <Text width="40px" textAlign="center">
            {min}
          </Text>
        ) : (
          <OptionsDropdown
            availableOptions={availableMin}
            int={min}
            onChange={e => changeScale(parseInt(e.target.value, 10), "min", id)}
          />
        )}
      </Flex>
      <Text width="fit-content" mx={4}>
        to
      </Text>
      <Flex
        borderRadius="100%"
        bg="dark.4"
        height="40px"
        alignItems="center"
        width="auto"
      >
        {isCore ? (
          <Text width="40px" textAlign="center">
            {max}
          </Text>
        ) : (
          <OptionsDropdown
            availableOptions={availableMax}
            int={max}
            onChange={e => changeScale(parseInt(e.target.value, 10), "max", id)}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default ScaleQuestionEdit
