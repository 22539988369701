import { connect } from "react-redux"
import { AccountSelectors } from "../../../../account"
import CreditsDisplayComponent from "../../components/CreditsDisplay"

const mapState = state => ({
  amountOfCredits: AccountSelectors.getCurrentCredits(state),
  isLoading: AccountSelectors.getIsLoading(state)
})

const CreditsDisplay = connect(mapState)(CreditsDisplayComponent)

export default CreditsDisplay
