import React from "react"
import styled from "styled-components"
import Player from "react-player"
import { Flex } from "@engaging-tech/components"

const Wrapper = styled(Flex)`
  /* We have to use !important to overwrite the third party styles
  in react-player by default. */
  background-color: ${({ theme }) => theme.colors.dark[1]};

  .react-player__shadow {
    background: none !important;
    background-color: ${({ theme }) => theme.colors.secondary[0]} !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .react-player__play-icon {
    border-width: 12px 0px 12px 20px !important;
    border-color: transparent transparent transparent
      ${({ theme }) => theme.colors.dark[2]} !important;
  }
`

const Video = ({ url, thumbnail, ...props }) => (
  <Wrapper mt={2} {...props}>
    <Player url={url} controls light={thumbnail} playing />
  </Wrapper>
)

Video.defaultProps = {
  thumbnail: "null"
}

export default Video
