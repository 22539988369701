const formatLabel = label => {
  const labelArr = label.split(/(?=[A-Z]|_)/).map((string, idx) => {
    if (string.charAt(0) === "_") return string.slice(1)
    return string.charAt(0).toUpperCase() + string.slice(1)
  })

  return labelArr.join(" ")
}

export default formatLabel
