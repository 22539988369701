import React from "react"
import { useSelector } from "react-redux"

import {
  getEmployeeActionPlans,
  getManagerActionPlans
} from "../../../../../../store/actionPlans/actionPlans.selectors"
import { ActionPlanSelect } from "./action-plan-select"

export function SurveyActionPlan({
  surveyEmployeeActionPlan,
  surveyId,
  surveyManagementActionPlan
}) {
  const employeeActionPlanOptions = useSelector(getEmployeeActionPlans)
  const managerActionPlanOptions = useSelector(getManagerActionPlans)

  return (
    <div style={{ display: "flex", gap: 30, marginBottom: 20, marginTop: 20 }}>
      {employeeActionPlanOptions?.length ? (
        <ActionPlanSelect
          label={"Employees Action Plan"}
          options={employeeActionPlanOptions}
          surveyActionPlan={surveyEmployeeActionPlan}
          surveyId={surveyId}
        />
      ) : null}
      {managerActionPlanOptions?.length ? (
        <ActionPlanSelect
          label={"Managers Action Plan"}
          options={managerActionPlanOptions}
          surveyActionPlan={surveyManagementActionPlan}
          surveyId={surveyId}
        />
      ) : null}
    </div>
  )
}
