
import React from "react";
import {Tab} from "./Tab";

export const Tabs = ({ tabs, activeTab, setActiveTab }) => (
  <div>
    {tabs.map((tab, index) => (
      <Tab
        key={index}
        title={tab.title}
        active={tab.title === activeTab}
        onClick={() => setActiveTab(tab.title)}
      />
    ))}
  </div>
);
