import React from "react"

import OrganisationDataContainer from "../containers/OrganisationData/OrganisationDataContainer"
import PaymentContainer from "../containers/Payment/PaymentContainer"
import SelectProductContainer from "../containers/SelectProduct/SelectProductContainer"
import SDViewContainer from "../containers/SliceAndDice/SDViewContainer"

const pages = {
  1: {
    component: <SelectProductContainer />,
    intro: {
      title: "Select your Organisation Size",
      paragraph:
        "Click on the package that reflects the number of employees in your organisation. To qualify for the awards you must select the right company size for your total number of employees."
    }
  },
  2: {
    component: <OrganisationDataContainer />,
    intro: {
      title: "Set up your Survey",
      paragraph:
        "Write a short introduction to provide context to your employees so to encourage filling the survey in to boost your chances of being ranked as one of the Best Places to Work. To qualify, you will need to meet the minimum entry requirement based on your organisation size. We recommend keeping your survey open as long as possible to build a maximum number of responses. Your survey must finish by the specific Award end date to qualify. You can save your survey as a draft if you want to come back to it later."
    }
  },
  3: {
    component: <SDViewContainer />,
    intro: {
      title: "Enhance your reporting and insight!",
      paragraph:
        "Select a data package that suits your needs. As standard you'll receive some topline information about the engagement of your employees. To get to the heart of improvement areas and access action plans, add the enhanced Enhanced Data Insight Package."
    }
  },
  4: {
    component: <PaymentContainer />,
    intro: {
      title: "Your Basket",
      paragraph:
        "Pay by credit card or generate an invoice to purchase your package(s) and be able to send your survey out."
    }
  }
}

export default pages
