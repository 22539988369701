/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { connect } from "react-redux"
import { loadTemplate } from "../../surveyManagement/templates/store/activeTemplate/activeTemplate.actions"

const TemplateLoaderComponent = ({ templateId, getTemplate }) => {
  useEffect(() => {
    getTemplate({ templateId })
  }, [templateId])

  return null
}

const mapDispatch = dispatch => ({
  getTemplate: id => dispatch(loadTemplate(id))
})

const TemplateLoader = connect(null, mapDispatch)(TemplateLoaderComponent)

export default TemplateLoader
