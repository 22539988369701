/* eslint-disable react/no-array-index-key */
import React from "react"
import styled from "styled-components"
import { Paragraph, Text, Flex } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import ReportCard from "../../components/ReportCard"
import CustomQuestionPieChart from "./CustomQuestionPieChart"
import CustomQuestionScale from "./CustomQuestionScale"

const ItalicText = styled(Text)`
  font-style: italic;
`

const PDFView = ({
  customRadioDropdownQuestions,
  customScaleQuestions = []
}) => {
  const questionAmount = 4
  const customScaleQuestionsSplit = []
  const splitCustomScaleQuestions = startingValue => {
    const array = customScaleQuestions.slice(
      startingValue,
      startingValue + questionAmount
    )
    if (array.length > 0) {
      customScaleQuestionsSplit.push(array)
    }

    if (array.length === questionAmount) {
      splitCustomScaleQuestions(startingValue + questionAmount)
    }
  }

  splitCustomScaleQuestions(0)

  return (
    <>
      {customRadioDropdownQuestions.concat(customScaleQuestions).length > 0 &&
        customRadioDropdownQuestions.map((question, index) => (
          <ReportCard
            heading="Your Customised Questions"
            width={1 / 1}
            exportingPDF
          >
            <Paragraph color="dark.2" fontSize={3}>
              You can compare the average engagement score for each of the
              custom questions inputted. Use the custom questions to dive deeper
              into issues you have identified or questions that you would like
              clarification on.
            </Paragraph>
            <ItalicText mb={3} fontSize={3} color="dark.2">
              To protect the anonymity of employees the data will appear as 0 if
              less than 5 people match this data selection.
            </ItalicText>

            <CustomQuestionPieChart
              key={index}
              question={question}
              exportingPDF
            />
          </ReportCard>
        ))}
      {customScaleQuestionsSplit.length > 0 &&
        customScaleQuestionsSplit.map(scaleQuestionSet => (
          <ReportCard
            heading="Your Customised Questions"
            width={1 / 1}
            exportingPDF
          >
            <Paragraph color="dark.2" fontSize={3}>
              You can compare the average engagement score for each of the
              custom questions inputted. Use the custom questions to dive deeper
              into issues you have identified or questions that you would like
              clarification on.
            </Paragraph>
            <ItalicText mb={3} fontSize={3} color="dark.2">
              To protect the anonymity of employees the data will appear as 0 if
              less than 5 people match this data selection.
            </ItalicText>
            <Flex
              flexDirection={["column", "row"]}
              flexWrap="wrap"
              width={1 / 1}
              height="auto"
            >
              {scaleQuestionSet.map((scaleQuestion, i) => (
                <CustomQuestionScale
                  key={i}
                  scaleQuestion={scaleQuestion}
                  exportingPDF
                />
              ))}
            </Flex>
          </ReportCard>
        ))}
    </>
  )
}

const getHeading = (reportTypename, starterTypename, exitTypename) => {
  if (reportTypename === starterTypename) {
    return "New Starter Survey Core Questions"
  }
  if (reportTypename === exitTypename) {
    return "New Exit Survey Core Questions"
  }
  return "Your Customised Questions"
}

const getText = (reportTypename, starterTypename, exitTypename) => {
  if (reportTypename === starterTypename) {
    return "You can compare the average New Starter Survey scores for each of the core questions."
  }
  if (reportTypename === exitTypename) {
    return "You can compare the average Exit Survey scores for each of the core questions."
  }
  return "You can compare the average engagement score for each of the custom questions inputted. Use the custom questions to dive deeper into issues you have identified or questions that you would like clarification on."
}

const CustomQuestions = ({
  customRadioDropdownQuestions,
  customScaleQuestions = [],
  startExitCoreRadioDropdownQuestions,
  startExitCoreScaleQuestions,
  exportingPDF,
  reportTypename
}) => {
  const { starterReportTypename, exitReportTypename } = getConfig().other
  const radioDropdownQs = [
    ...customRadioDropdownQuestions,
    ...startExitCoreRadioDropdownQuestions
  ]
  const scaleQs = [...customScaleQuestions, ...startExitCoreScaleQuestions]
  if (exportingPDF) {
    return (
      <PDFView
        customRadioDropdownQuestions={radioDropdownQs}
        customScaleQuestions={scaleQs}
      />
    )
  }
  return (
    radioDropdownQs.concat(scaleQs).length > 0 && (
      <ReportCard
        heading={getHeading(
          reportTypename,
          starterReportTypename,
          exitReportTypename
        )}
        width={1 / 1}
      >
        <Paragraph color="dark.2" fontSize={3}>
          {getText(reportTypename, starterReportTypename, exitReportTypename)}
        </Paragraph>
        <ItalicText mb={3} fontSize={3} color="dark.2">
          To protect the anonymity of employees the data will appear as 0 if
          less than 5 people match this data selection.
        </ItalicText>
        {radioDropdownQs.map((question, index) => (
          <CustomQuestionPieChart key={index} question={question} />
        ))}
        <Flex
          flexDirection={["column", "row"]}
          flexWrap="wrap"
          width={1 / 1}
          height="auto"
        >
          {scaleQs.map((scaleQuestion, index) => (
            <CustomQuestionScale key={index} scaleQuestion={scaleQuestion} />
          ))}
        </Flex>
      </ReportCard>
    )
  )
}

export default CustomQuestions
