import React from "react"

/**
 * Simplifying the selected filters in a key/value pair for easy data access in other functions
 * @param {any} heatmapFilter - *ADD TYPE* The filter selected for the specific row
 * @returns {{[key]: string}} { 38fhr47329fk0569j61: "Female" }
 */
const generateSelectedFiltersForRow = heatmapFilter => {
  const selectedFiltersForRow = heatmapFilter?.length
    ? heatmapFilter.reduce((acc, next) => {
        const currentValues = {}

        next.values.forEach(({ valueId, value }) => {
          currentValues[valueId] = value
        })

        acc = { ...acc, ...currentValues }
        return acc
      }, {})
    : {}

  return selectedFiltersForRow
}

/**
 * @typedef {Object} dataValue
 * @property {string} valueId - the ID of the value in BSON
 * @property {string} value - the name of the value, such as "Female"
 */

/**
 * @typedef {Object} filterData
 * @property {string} question - question label, such as "Gender"
 * @property {string} questionId - questionId
 * @property {Array.<dataValue>} dataValue - data containing the value, it's an array of strings due to API demands, but here only 1 item is stored for checkboxes
 */

/**
 * @typedef {Object} allFilterData
 * @property {string} name - name of the filters
 * @property {boolean} selected - checkbox boolean used for forms
 * @property {Array.<filterData>} data - data used to send via the API for this filter selection
 */

/**
 * This functions generates a form friendly key/value list for all the available filters
 * @param {any} allHeatmapFilters - *ADD TYPE* All selected heatmap filters
 * @param {{[key]: string}} selectedFiltersForRow - Simplified key/value version of the selected filter(s) for the row
 * @param {number} selectedFiltersValueLength - The length of selectedFiltersForRow
 * @returns {{[key]: allFilterData}} key/value object usable for forms
 */
const generateAllFilters = (
  allHeatmapFilters,
  selectedFiltersForRow,
  selectedFiltersValueLength
) => {
  const allFilters = allHeatmapFilters.reduce((acc, next) => {
    const currentValues = {}

    next.values.forEach(({ valueId, value }) => {
      /* Need to make sure that if only a single filter is applied in a row,
          that single filter does not as a dropdown for flter combinations
          */
      if (selectedFiltersValueLength < 2 && selectedFiltersForRow[valueId])
        return

      currentValues[valueId] = {
        name: value,
        selected: selectedFiltersForRow[valueId] ? true : false,
        data: {
          question: next.question,
          questionId: next.questionId,
          values: [{ valueId, value }]
        }
      }
    })

    acc = { ...acc, ...currentValues }
    return acc
  }, {})

  return allFilters
}

/**
 * Used to generate a form friendly object needed for checkboxes. Generates it only for
 * all the heatmap filters, not the selected row.
 * @param {any} heatmapFilter - *ADD TYPE* the selected heatmap filter(s) for the row
 * @param {any} allHeatmapFilters - *ADD TYPE* all the selected filters for all heatmaps
 * @returns {{[key]: allFilterData}} key/value object usable for form
 */
const useHeatmapFilter = (heatmapFilter, allHeatmapFilters) => {
  const selectedFiltersForRow = generateSelectedFiltersForRow(heatmapFilter)
  const selectedFiltersValueLength = Object.keys(selectedFiltersForRow).length
  const allFilters = generateAllFilters(
    allHeatmapFilters,
    selectedFiltersForRow,
    selectedFiltersValueLength
  )

  return allFilters
}

export default useHeatmapFilter
