import React from "react"
import { Text, Button, Paragraph, Card } from "@engaging-tech/components"

const JoinOurNetworkHeader = ({
  subscriptionType,
  headerText,
  description,
  handleSubscribeClick
}) => (
  <Card bg="primary.5" elevation={0} pt={4}>
    <Text
      display="block"
      textAlign="center"
      width={1}
      color="dark.0"
      fontWeight={800}
      fontSize={5}
    >
      {subscriptionType ? `Subscribe to ${subscriptionType}` : headerText}
    </Text>
    <Text
      display="block"
      textAlign="center"
      width={1}
      color="dark.2"
      fontWeight={600}
      fontSize={4}
      mt={3}
    >
      £50 + VAT a month
    </Text>
    <Paragraph
      textAlign="center"
      width={1}
      fontSize={3}
      color="dark.1"
      mt={3}
      px={5}
    >
      {description}
    </Paragraph>
    <Button
      mx="auto"
      px={4}
      my={3}
      variant="secondary.0"
      onClick={handleSubscribeClick}
    >
      {subscriptionType ? `Subscribe to ${subscriptionType}` : "Subscribe"}
    </Button>
  </Card>
)

export default JoinOurNetworkHeader
