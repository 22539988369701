import { createSelector } from "reselect"

export const jobsState = state => state.jobs

export const getIsLoading = createSelector(
  jobsState,
  state => state && state.isLoading
)

export const getJobMatches = createSelector(
  jobsState,
  state => state.jobMatches || null
)
