import { types } from "./jobs.actions"

// TODO: Remove placeholders
export const initialState = {
  error: null,
  postJob: {},
  data: []
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.CLEAR_JOB_DATA:
      return {
        ...state,
        postJob: {}
      }

    case types.POST_JOB_SUCCESS:
      return {
        ...state,
        postJob: {
          ...state.postJob,
          ...payload
        }
      }

    case types.SAVE_JOB_DATA: {
      return {
        ...state,
        postJob: {
          ...state.postJob,
          ...payload
        }
      }
    }

    case types.GET_LIVE_JOBS_SUCCESS: {
      return {
        ...state,
        data: payload
      }
    }

    case types.POST_JOB_FAIL:
    case types.GET_LIVE_JOBS_FAIL: {
      return {
        ...state,
        error: payload.error
      }
    }

    default:
      return state
  }
}
