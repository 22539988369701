import { connect } from "react-redux"
import MatricesComponent from "../components/Matrix"
import * as Selectors from "../store/matrices.selectors"

const mapState = state => ({
  hasMatrices: Selectors.hasMatrices(state),
  matrixData: Selectors.getFirstMatrixValues(state),
  quadrantDescriptions: Selectors.getFirstMatrixBands(state),
  axisLabels: Selectors.getFirstMatrixAxisLabels(state),
  title: "Identify Unique Needs & Characteristics of Employee Segments",
  description: ""
})

const Matrices = connect(mapState)(MatricesComponent)

export default Matrices
