/* eslint-disable no-console */
import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { decode } from "@engaging-tech/jwt"
import { call, delay, put, select, takeLatest } from "redux-saga/effects"

import {
  applyDiscount,
  confirmEmail,
  createIntent,
  fullLoad,
  getSessionToken,
  load,
  publish,
  requestConfirmEmail,
  signOut,
  update,
  upload,
  getSessionExpiration
} from "../services/account.service"
import * as Actions from "./account.actions"
import * as AccountSelectors from "./account.selectors"

export const EMAIL_SENT_ERROR_CODE = "EMAIL_SENT"
export const RESET_PASSWORD = "RESET_PASSWORD"

export function* getTokenSaga() {
  try {
    const tokenString = yield call(getSessionToken)
    const token = decode(tokenString)
    const expireDate = token && new Date(token.exp * 1000)
    const isValid = expireDate > new Date()
    yield isValid
      ? put(Actions.signInSuccess(token))
      : put(Actions.tokenSignInFail())
  } catch (err) {
    if (err !== "No current user") {
      yield put(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            err?.message === "APP_ERROR"
              ? "There has been an error"
              : err.message
        })
      )
    }
    yield put(Actions.tokenSignInFail())
  }
}

const getGraphQLErrCode = err => {
  const extensions = err?.graphQLErrors[0] && err.graphQLErrors[0].extensions
  if (extensions.code) {
    return extensions.code
  }
  return (
    extensions.downstreamErrors[0] &&
    extensions.downstreamErrors[0].extensions.code
  )
}

export function* loadSaga() {
  try {
    // if (action?.payload) {
    // if (typeof action.payload === "number") {
    //   yield delay(action.payload)
    // }

    const expiration = yield call(getSessionExpiration)
    const expireDate = expiration && new Date(expiration * 1000)
    const isValid = expireDate > new Date()

    if (!isValid) return

    const res = yield call(load)
    const organisation = res && res.data && res.data.getMyOrganisation

    if (organisation) {
      yield put(Actions.loadSuccess(organisation))
    } else {
      yield call(signOut)
    }
    // }
  } catch (err) {
    const code = getGraphQLErrCode(err)

    if (code && code === "ERR007") {
      yield put(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "User not verified!"
        })
      )
    } else {
      yield put(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            err?.message === "APP_ERROR"
              ? "There has been an error"
              : err.message
        })
      )
    }
    yield put(Actions.loadFail(getGraphQLErrCode(err)))
  }
}

export function* fullOrgLoadSaga() {
  try {
    const res = yield call(fullLoad)
    const organisation = res && res.data && res.data.getMyOrganisation
    if (organisation) {
      yield put(Actions.loadSuccess(organisation))
    } else {
      yield put(Actions.setHasError(true))
    }
  } catch (err) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message:
          err?.message === "APP_ERROR" ? "There has been an error" : err.message
      })
    )
    yield put(Actions.loadFail(err.code))
  }
}

export function* confirmEmailSaga(action) {
  try {
    yield call(confirmEmail, action.payload)
    yield put(Actions.confirmEmailSuccess())
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Verified email"
      })
    )
  } catch {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Email verification failed. Please try again."
      })
    )
    yield put(Actions.confirmEmailFail())
  }
}

export function* requestConfirmationSaga() {
  try {
    yield call(requestConfirmEmail)
    yield put(Actions.requestConfirmationSuccess(EMAIL_SENT_ERROR_CODE))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Verification email sent"
      })
    )
  } catch (err) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: err.message || "Failed to send email verification"
      })
    )
    yield put(Actions.requestConfirmationFail())
  }
}

export function* createIntentSaga(action) {
  try {
    const discountState = yield select(AccountSelectors.getDiscountState)

    const res = yield call(createIntent, {
      source: action.payload,
      promotionCodeId: discountState.promotionCodeId
    })
    if (res?.data?.purchaseEBN) {
      yield put(Actions.createIntentSuccess(res.data.purchaseEBN))
    }
  } catch (err) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: err.message || "There has been an error"
      })
    )
    yield put(Actions.createIntentFail())
  }
}

export function* uploadImageSaga(action) {
  const res = yield call(upload, action.payload)

  if (res.status === 200) {
    yield put(Actions.uploadImageSuccess(res.data.result))
  } else {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Error uploading logo"
      })
    )
  }
}

const prepareSubmission = ({
  culture = {},
  businessInformation = {},
  logoUrl,
  ...rest
}) => {
  const prepareBI = {
    ...businessInformation,
    logoUrl,
    locations: businessInformation.locations.map(location => ({
      countryId: location.country ? location.country.id : location.countryId,
      city: location.city
    })),
    social: {
      facebook: businessInformation.social.facebook,
      pinterest: businessInformation.social.pinterest,
      instagram: businessInformation.social.instagram,
      youtube: businessInformation.social.youtube,
      linkedIn: businessInformation.social.linkedIn,
      twitter: businessInformation.social.twitter
    }
  }
  // eslint-disable-next-line no-underscore-dangle
  delete prepareBI.__typename

  return {
    ...rest,
    businessInformation: prepareBI,
    culture: {
      statement:
        culture && typeof culture.statement !== "object"
          ? culture?.statement
              ?.split("\n")
              .map(content => ({ content, type: "paragraph" })) || []
          : culture?.statement.map(({ content, type }) => ({
              content,
              type
            })) || []
    }
  }
}

export function* updateOrganisationSaga(action) {
  const organisationData = action.payload.data
  const submissionData = prepareSubmission(organisationData)

  try {
    const res = yield call(update, submissionData)
    if (res.data.updateBusinessInformation) {
      yield put(
        Actions.updateOrganisationSuccess(res.data.updateBusinessInformation)
      )
      if (action.payload.nextCrumb) {
        yield put(Actions.setActiveCrumb(action.payload.nextCrumb))
      } else {
        yield put(Actions.closeForm())
      }
    } else yield put(Actions.setHasError(true))
  } catch (error) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: error.message || "Failed to update organisation"
      })
    )
    yield put(Actions.setHasError(true))
  }
}
export function* publishBIPSaga() {
  try {
    const res = yield call(publish)
    if (res.data.publishBIP) {
      yield put(Actions.publishBIPSuccess(res.data.publishBIP))
      yield put(Actions.setActiveCrumb("Finish"))
    } else yield put(Actions.setHasError(true))
  } catch (error) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: error.message || "Failed to update business profile"
      })
    )
    yield put(Actions.setHasError(true))
  }
}

export function* applyDiscountSaga(action) {
  try {
    const res = yield call(applyDiscount, action.payload)
    if (res?.data?.applyDiscount) {
      yield put(Actions.applyDiscountSuccess(res.data.applyDiscount))
    }
  } catch (error) {
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Failed to apply discount"
      })
    )
    yield put(Actions.applyDiscountFail(getGraphQLErrCode(error)))
  }
}

export function* sendMagicLinkSuccessSaga() {
  yield put(Actions.sendMagicLinkSuccess()) // todo get userId
}

export function* saga() {
  yield takeLatest(Actions.types.APPLY_DISCOUNT, applyDiscountSaga)
  yield takeLatest(Actions.types.TOKEN_SIGN_IN, getTokenSaga)
  yield takeLatest(Actions.types.PUBLISH_BIP, publishBIPSaga)
  yield takeLatest(
    [
      Actions.types.LOAD,
      Actions.types.SIGN_IN_SUCCESS,
      Actions.types.SIGN_IN_MAGIC_LINK_SUCCESS,
      Actions.types.SIGN_UP_SUCCESS
    ],
    loadSaga
  )
  yield takeLatest([Actions.types.FULL_ORG_LOAD], fullOrgLoadSaga)

  yield takeLatest(Actions.types.CONFIRM_EMAIL, confirmEmailSaga)
  yield takeLatest(Actions.types.REQUEST_CONFIRMATION, requestConfirmationSaga)
  yield takeLatest(Actions.types.CREATE_INTENT, createIntentSaga)
  yield takeLatest(Actions.types.UPLOAD_IMAGE, uploadImageSaga)
  yield takeLatest(Actions.types.UPDATE_ORGANISATION, updateOrganisationSaga)
}
