import { connect } from "react-redux"
import BreakdownChartWrapper from "../components/BreakdownChartWrapper"
import * as Selectors from "../store/indicators.selectors"

const mapState = state => ({
  graphDataset: Selectors.getDiversityAndInclusionBreakdowns(state)
})

const DiversityAndInclusionBreakdown = connect(mapState)(BreakdownChartWrapper)

export default DiversityAndInclusionBreakdown
