import { Box, Text, TextArea } from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React from "react"
import styled from "styled-components"

import SectionCard from "../../../components/section-card"

const StyledTextArea = styled(TextArea)`
  textarea {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 100px;
  }
  padding: 0px;
`

const TextBold = styled(Text)`
  font-weight: bold;
`

export default function AboutYourOrganisation({ isLoading }) {
  const { errors, values, handleBlur, handleChange } = useFormikContext()

  return (
    <SectionCard title="Your Culture">
      <Box bg="secondary.4" py={3} px={3} mb={4} fontSize={3} borderRadius={4}>
        <Text>
          Create an engaging statement of intent for your business. Which brand values do you want to
          communicate? How do you support you employees? What makes your business stand out among others? This
          will appear in the <TextBold>What we’re about</TextBold> section.
        </Text>
      </Box>
      <StyledTextArea
        id="culture.statement"
        name="culture.statement"
        label="Mission Statement"
        type="text"
        width={1 / 1}
        disabled={isLoading}
        error={errors?.culture?.statement}
        value={values?.culture.statement}
        helperText={
          values.culture?.statement
            ? `${values.culture.statement.length}/1,000 characters`
            : "0/1,000 characters"
        }
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </SectionCard>
  )
}
