import { gql } from "@apollo/client"

const SEARCH_ACTION_PLAN_SURVEY = gql`
  query SearchActionPlanSurvey($input: SearchSurveyInput) {
    SearchActionPlanSurvey(input: $input) {
      count
      currentPage
      totalPages
      surveys {
        survey_id
        title
        status
      }
    }
  }
`

export default SEARCH_ACTION_PLAN_SURVEY
