/* eslint-disable react/no-array-index-key */
import React from "react"
import { Flex, Text, Paragraph } from "@engaging-tech/components"
import styled from "styled-components"
import ReportCard from "../../components/ReportCard"

const BottomBorderedFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  :last-child {
    border-bottom: none;
  }
`

const OverFlowFlex = styled(Flex)`
  max-height: 468px;
  overflow-y: auto;

  @media print {
    max-height: initial;
    overflow-y: initial;
  }
`

const QuestionBreakdown = ({ question, score }) => (
  <BottomBorderedFlex
    key={question}
    width="100%"
    justifyContent="space-between"
  >
    <Paragraph fontSize={3} mb={3} width="calc(100% - 50px)">
      {question}
    </Paragraph>
    <Text width={50} fontSize={4}>{`${score}%`}</Text>
  </BottomBorderedFlex>
)

const QuestionsBreakdown = ({
  sixStepsQuestions,
  customScaleQuestions,
  exportingPDF
}) => {
  const six = []
  sixStepsQuestions.forEach(step =>
    step.questions.forEach(question => six.push(question))
  )
  const fullQuestionsArray = [...six, ...customScaleQuestions]

  if (exportingPDF) {
    const questionAmount = 9
    const questionSplit = []
    const splitQuestionSet = startingValue => {
      const array = fullQuestionsArray.slice(
        startingValue,
        startingValue + questionAmount
      )
      questionSplit.push(array)
      if (array.length === questionAmount) {
        splitQuestionSet(startingValue + questionAmount)
      }
    }

    splitQuestionSet(0)

    return questionSplit.map((questionSet, index) => (
      <ReportCard
        heading={`Questions Breakdown ${index + 1}`}
        exportingPDF={exportingPDF}
      >
        <OverFlowFlex flexDirection="column" pr={3}>
          {questionSet.map((quest, i) => (
            <QuestionBreakdown
              key={i}
              question={quest.question}
              score={quest.values ? quest.values.score : quest.score}
            />
          ))}
        </OverFlowFlex>
      </ReportCard>
    ))
  }
  return (
    <ReportCard heading="Questions Breakdown" width={[1 / 1, 1 / 1, 59 / 100]}>
      <OverFlowFlex flexDirection="column" pr={3}>
        {fullQuestionsArray.map((quest, i) => (
          <QuestionBreakdown
            key={i}
            question={quest.question}
            score={quest.values ? quest.values.score : quest.score}
          />
        ))}
      </OverFlowFlex>
    </ReportCard>
  )
}

export default QuestionsBreakdown
