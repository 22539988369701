/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"

import BasketContainer from "../../basket/containers/BasketContainer"

const Payment = ({ saveSurvey }) => {
  const handleBack = () => saveSurvey({ page: 1 })

  return (
    <BasketContainer onBack={handleBack} cancelUrl={window.location.href} />
  )
}

export default Payment
