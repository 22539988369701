import { Button, Flex, Text, Utils } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"

import LinkToBtn from "../../surveyManagement/components/LinkToBtn"
import LoadingState from "../../surveyManagement/components/LoadingState"
import SurveyBar from "../../surveyManagement/surveySelection/components/survey-bar"

const { errorTypes } = Utils.coreStore

const EmptyState = (previousSurveys = 0) => {
  const paths = usePaths()
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="50vh"
      maxHeight={250}
      flexDirection="column"
    >
      {previousSurveys === 0 ? (
        <>
          <Text
            width="auto"
            fontSize="20px"
            mb={2}
            style={{ textAlign: "center" }}
          >
            You currently have no surveys to show here
          </Text>
          <Text
            width="auto"
            fontSize="16px"
            mb={5}
            style={{ textAlign: "center" }}
          >
            Start gathering insights into your employees by creating a survey
          </Text>
          <LinkToBtn
            path={paths.surveys.surveyCreation.index}
            text="Create Survey"
          />
        </>
      ) : (
        <Text
          width="auto"
          fontSize="20px"
          mb={2}
          style={{ textAlign: "center" }}
        >
          No more surveys to show
        </Text>
      )}
    </Flex>
  )
}

const ErrorState = ({ message }) => {
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="50vh"
      maxHeight={200}
      flexDirection="column"
    >
      <Text width="auto" fontSize="20px" mb={2} style={{ textAlign: "center" }}>
        Something went wrong!
      </Text>
      <Text width="auto" fontSize="16px" mb={5} style={{ textAlign: "center" }}>
        {message}
      </Text>
    </Flex>
  )
}

const ListSurveys = ({
  page,
  isLoading,
  surveysError,
  surveysToShow,
  isThereMore,
  onLoad,
  minimized
}) => {
  if (surveysError && surveysError?.type !== errorTypes.none) {
    return <ErrorState message={surveysError.message} />
  }

  return (
    <Flex flexDirection="column" width={1 / 1} alignItems="center">
      {surveysToShow?.map((survey, index) => (
        <SurveyBar key={index} index={index} {...survey} id={survey.id} />
      ))}
      {!surveysToShow?.length ? (
        <EmptyState previousSurveys={surveysToShow?.length} />
      ) : minimized ? null : isLoading ? (
        <LoadingState firstPage={page === 0} hideMessage />
      ) : (
        <Button
          bg={isThereMore ? "primary.0" : "primary.2"}
          color="light.0"
          mt={4}
          width="300px"
          disabled={isLoading || !isThereMore}
          onClick={() => onLoad()}
        >
          Load more
        </Button>
      )}
    </Flex>
  )
}

export default ListSurveys
