import { connect } from "react-redux"
import DescriptionForm from "../../forms/Description"
import { getPostJob } from "../../../../store/jobs/jobs.selector"
import { saveJobData } from "../../../../store/jobs/jobs.actions"

const mapState = state => ({
  job: getPostJob(state)
})

const mapDispatch = dispatch => ({
  onSubmit: data => dispatch(saveJobData(data))
})

const Description = connect(mapState, mapDispatch)(DescriptionForm)

export default Description
