export const types = {
  LOAD_SURVEY: "[surveyInfo] Load Survey",
  LOAD_SURVEY_SUCCESS: "[surveyInfo] Load Survey Success",
  LOAD_SURVEY_FAIL: "[surveyInfo] Load Survey Fail",
  SAVE_SURVEY: "[surveyInfo] Saving Survey",
  SAVE_SURVEY_SUCCESS: "[surveyInfo] Saving Survey Success",
  SAVE_SURVEY_FAIL: "[surveyInfo] Saving Survey Fail",
  SAVE_SURVEY_META: "[surveyInfo] Saving Survey Meta",
  SAVE_SURVEY_BRAND: "[surveyInfo] Saving Survey Branding",
  CLOSE_SURVEY: "[surveyInfo] Close Survey"
}

export const loadSurvey = payload => ({
  type: types.LOAD_SURVEY,
  payload
})

export const loadSurveySuccess = payload => ({
  type: types.LOAD_SURVEY_SUCCESS,
  payload
})

export const loadSurveyFail = () => ({
  type: types.LOAD_SURVEY_FAIL
})

export const saveSurvey = payload => ({
  type: types.SAVE_SURVEY,
  payload
})

export const saveSurveyMeta = payload => ({
  type: types.SAVE_SURVEY_META,
  payload
})

export const saveSurveyBrand = payload => ({
  type: types.SAVE_SURVEY_BRAND,
  payload
})

export const saveSurveySuccess = payload => ({
  type: types.SAVE_SURVEY_SUCCESS,
  payload
})

export const saveSurveyFail = () => ({
  type: types.SAVE_SURVEY_FAIL
})

export const closeSurvey = () => ({
  type: types.CLOSE_SURVEY
})
