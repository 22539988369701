import React from "react"
import { Flex } from "@engaging-tech/components"
import StepBarOnePager from "./StepBarOnePager"

import ReportCard from "../../components/ReportCard"

const SixStepsOnePager = ({ sixSteps, isPdfDownloading }) => {
  return (
    <ReportCard
      isPdfDownloading={isPdfDownloading}
      heading="Six Steps to Workplace Happiness"
      width={["100%"]}
      exportingPDF={false}
    >
      <Flex flexDirection="column">
        {sixSteps &&
          sixSteps.map((step, index) => (
            <StepBarOnePager
              key={step.name}
              section={step.info.sectionTitle}
              score={step.score}
              noBorder={index === sixSteps.length - 1}
            />
          ))}
      </Flex>
    </ReportCard>
  )
}

export default SixStepsOnePager
