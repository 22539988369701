/* eslint-disable react/no-array-index-key */
import {
  Box,
  Flex,
  ImageCropModal,
  Text,
  Utils,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import axios from "axios"
import { useFormikContext } from "formik"
import { AnimatePresence } from "framer-motion"
import { getOrientation } from "get-orientation"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import UPLOAD_FILE from "../../../../graphql/mutations/upload-file"
import useAppSyncMutation from "../../../../hooks/useAppSyncMutation"
import BannerImage from "./banner-image"
import FilePicker from "./file-picker"
import LogoImage from "./logo-image"

const UnderlinedText = styled(Text)`
  text-decoration: underline;
  width: fit-content;

  color: ${({ disabled }) => (disabled ? "grey" : "")};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
}

export default function MultipleImageUploader({ id, title, subTitle, icon, orgId, disabled }) {
  const { data, post } = useAppSyncMutation(UPLOAD_FILE)

  const dispatch = useDispatch()

  const { errors, values, setFieldValue } = useFormikContext()
  const list = values[id] || []

  const [fileDataTemp, setFileDataTemp] = useState(null)
  const [fileDataFinal, setFileDataFinal] = useState(null)
  const [imageToCrop, setImageToCrop] = useState(null)

  useEffect(() => {
    if (fileDataFinal) {
      post({
        fileName: `${Date.now()}_${fileDataFinal.name}`,
        service: "AWARDS_LOGOS",
        uniqueId: orgId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDataFinal])

  const onUploadFile = useCallback(
    async (url, fileKey) => {
      try {
        const res = await axios({
          method: "put",
          url,
          data: fileDataFinal,
          headers: {
            "Content-Type": "binary/octet-stream"
          }
        })

        if (res.status === 200) {
          setFileDataTemp(null)
          setFileDataFinal(null)
          setImageToCrop(null)

          const listUpdated = list
          listUpdated[listUpdated.length - 1] = { ...listUpdated[listUpdated.length - 1], link: fileKey }

          setFieldValue(id, listUpdated)
          dispatch(
            userInterfaceNotificationsStore.actions.addNotification({
              message: "Award image uploaded successfully"
            })
          )
        }
      } catch (error) {
        console.error(error)
        dispatch(
          userInterfaceNotificationsStore.actions.addNotification({
            message: "Error in award uploading image"
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileDataFinal]
  )

  useEffect(() => {
    if (data) {
      onUploadFile(data.uploadFile.url, data.uploadFile.key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // prepare image to crop
  const handleImageChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      // store file data
      setFileDataTemp(file)

      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await Utils.canvasHelpers.getRotatedImage(imageDataUrl, rotation)
      }

      // set image to crop
      setImageToCrop(imageDataUrl, file.name)
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey logo"])
      }
    }
  }

  return (
    <Flex flexDirection="column" mt="33px">
      <Text fontSize="20px" fontWeight="700" lineHeight="26px" color="#173C5A">
        {title}
      </Text>
      <Text fontSize="16px" lineHeight="20.8px" color="#173C5A">
        {subTitle}
      </Text>
      {list?.length
        ? list.map(item => (
            <Flex key={item.id} flexDirection="row" alignItems="center" mt={3}>
              {icon === "banner" ? <BannerImage imageUrl={item.link} /> : <LogoImage imageUrl={item.link} />}
              <FilePicker
                id={id}
                name={`${id}-${item.id}`}
                accept="image/png, image/jpeg, image/jpg"
                imageUrl={item.link}
                disabled={disabled}
                error={errors && errors[id]}
                onChange={e => handleImageChange(e)}
                onRemove={link => {
                  let listUpdated = list.filter(i => i.link !== link)
                  if (!listUpdated?.length) {
                    listUpdated = [{ id: 0, link: null }]
                  }

                  setFileDataTemp(null)
                  setImageToCrop(null)
                  setFileDataFinal(null)
                  setFieldValue(id, listUpdated)
                }}
              />
            </Flex>
          ))
        : null}

      <Flex flexDirection="row-reverse">
        <UnderlinedText
          color="primary.0"
          fontWeight="500"
          fontSize={[4, 3]}
          disabled={list[list.length - 1]?.link?.length === 0}
          onClick={() => {
            if (list[list.length - 1]?.link?.length !== 0) {
              setFieldValue(id, [...values[id], { id: values[id]?.length + 1, link: "" }])
            }
          }}
        >
          Add more
        </UnderlinedText>
      </Flex>

      <AnimatePresence>
        {imageToCrop ? (
          <ImageCropModal
            cancelCrop={() => setImageToCrop(null)}
            imageToCrop={imageToCrop}
            aspectRatio={icon === "logo" ? 1 : 4 / 1}
            saveCroppedImage={imageToSave => {
              if (imageToSave) {
                setFileDataFinal(new File([imageToSave], fileDataTemp.name, { type: fileDataTemp.type }))
                setImageToCrop(null)
              }
            }}
            imageName={`image-${id}`}
          />
        ) : null}
      </AnimatePresence>
    </Flex>
  )
}
