import { connect } from "react-redux"
import TopSixWordsComponent from "../components/TopSixWords"
import * as Selectors from "../store/sentimentAnalysis.selectors"

const mapState = (state, { questionId }) => ({
  wordCountForQuestion: Selectors.getWordCountForQuestion(state, questionId)
})

const TopSixWords = connect(mapState)(TopSixWordsComponent)

export default TopSixWords
