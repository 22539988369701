/* eslint-disable import/prefer-default-export */
import { Utils, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { takeLatest } from "redux-saga/effects"

import { deleteSurvey, loadSurveys } from "../../services/surveys.service"
import * as surveyActions from "./surveys.actions"
import { types } from "./surveys.actions"

const { defaultSagas } = Utils.coreStore

export function* saga() {
  yield takeLatest(types.LOAD_DATA, () =>
    defaultSagas.loadData({
      actions: surveyActions,
      errorMessage: "Unable to retrieve survey data",
      query: loadSurveys,
      queryName: "listOrganisationSurveys"
    })
  )
  yield takeLatest(types.DELETE_SURVEY, ({ payload }) =>
    defaultSagas.deleteData({
      actions: surveyActions,
      loadDataErrorMessage: "Unable to retrieve survey data",
      errorMessage: "Unable to delete survey",
      notificationsActions: userInterfaceNotificationsStore.actions,
      query: deleteSurvey,
      queryName: "deleteOrganisationSurvey",
      loadDataQuery: loadSurveys,
      loadDataQueryName: "listOrganisationSurveys",
      queryVariable: { surveyId: payload }
    })
  )
}
