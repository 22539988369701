import { CustomHooks, Paragraph } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import EmailInvite from "../components/EmailInvite"
import ParticipantsInfo from "../components/ParticpantsInfo"
import ShareableLinks from "../components/ShareableLinks"
import Nav from "../containers/Nav"

const Participants = ({ values, errors, handleChange, template, ...props }) => {
  const [showShareableLinks, setShowShareableLinks] = useState(true)
  useEffect(() => {
    if (template) {
      setShowShareableLinks(
        !["employeeStarter", "employeeExit"].includes(template.type)
      )
    }
  }, [template])
  CustomHooks.useFreshChatDeactivate(true)
  return (
    <CreationFormsLayout
      bg="#EEF4F9"
      maxWidth="1000px"
      mx={20}
      mb={7}
      BottomNav={() => <Nav {...props} />}
    >
      <Paragraph my={5} fontSize={[4, 5]}>
        Nearly there! Final step is to choose how and who you send your survey
        to. You can either invite participants via email or shareable link.
      </Paragraph>
      <ParticipantsInfo mb={5} />
      <EmailInvite
        mb={5}
        values={values}
        errors={errors}
        handleChange={handleChange}
        template={template}
        {...props}
      />
      {showShareableLinks && (
        <ShareableLinks
          values={values}
          errors={errors}
          handleChange={handleChange}
          {...props}
        />
      )}
    </CreationFormsLayout>
  )
}

export default Participants
