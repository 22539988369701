import React from "react"
import { Flex, Icon, Text } from "@engaging-tech/components"
import styled from "styled-components"

const CustomIcon = styled(Icon)`
  &:hover {
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  }
`

const EmailListItem = ({ entry, removeRecipients, isDisabled }) => (
  <Flex
    key={entry}
    bg="secondary.3"
    width="fit-content"
    py={1}
    px={2}
    mr={1}
    my={1}
  >
    <Text>{entry}</Text>
    <CustomIcon
      isDisabled={isDisabled}
      name="close"
      mt="1px"
      ml="1px"
      onClick={() => removeRecipients(entry)}
    />
  </Flex>
)

const EmailList = ({
  recipients,
  removeRecipients,
  isDisabled,
  pageErrors
}) => {
  return (
    <Flex width={1 / 1} flexWrap="wrap">
      {recipients.map(entry => (
        <EmailListItem
          key={entry}
          entry={entry}
          removeRecipients={removeRecipients}
          isDisabled={isDisabled}
        />
      ))}
      {pageErrors?.emails && (
        <Text color="red" fontSize={3}>
          {pageErrors.emails}
        </Text>
      )}
    </Flex>
  )
}

export default EmailList
