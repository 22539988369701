import React, { useContext } from "react"
import { Flex, MotionToggle, Text } from "@engaging-tech/components"
import styled from "styled-components"
import TemplateQuestionContext from "../TemplateQuestionContext"
import AddOptionContainer from "../../containers/EditBody/AddOption"
import AddOptionButton from "./AddOptionButton"

const CardToggleBox = styled(Flex)`
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid rgb(196, 196, 196);

  &:first-child {
    border: none;
  }
`

const OutlineFlex = styled(Flex)`
  border-top: 1px solid rgb(196, 196, 196);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`

const CardToggle = ({ label, onChange, value }) => (
  <CardToggleBox>
    <Text width="fit-content" mr={2} lineHeight="20px" fontSize={3}>
      {label}
    </Text>
    <MotionToggle onChange={onChange} value={value} id={label} />
  </CardToggleBox>
)

const BottomControls = ({
  setFilterOnReport,
  setRequired,
  switchQuestionType,
  availableCoreQuestionIds,
  setShowBranching,
  showBranching
}) => {
  const { isCore, type, id, isFilter, required } = useContext(
    TemplateQuestionContext
  )

  const showCoreQuestionToggle =
    (!isCore && availableCoreQuestionIds.length) || isCore

  return (
    <OutlineFlex>
      <Flex width="fit-content">
        <Flex width="125px" mr={3}>
          {!isCore && ["dropdown", "short-text", "radio"].includes(type) && (
            <AddOptionContainer id={id} label="Add Answer" />
          )}
        </Flex>
        <Flex width="125px">
          {!isCore && ["dropdown", "scale"].includes(type) && (
            <AddOptionButton
              onClick={() => setShowBranching(!showBranching)}
              id={id}
              label="Branching"
            />
          )}
        </Flex>
      </Flex>
      <Flex width="auto">
        {["dropdown", "radio"].includes(type) && (
          <CardToggle
            label="Filter on report"
            onChange={() => setFilterOnReport(!isFilter, id)}
            value={isFilter}
          />
        )}
        {showCoreQuestionToggle && (
          <CardToggle
            gridArea="D"
            label="Core Question"
            value={isCore}
            onChange={() => switchQuestionType(id, availableCoreQuestionIds)}
          />
        )}
        {!isCore && (
          <CardToggle
            label="Required"
            value={required}
            onChange={() => setRequired(!required, id)}
          />
        )}
      </Flex>
    </OutlineFlex>
  )
}

export default BottomControls
