import React, { useRef, useEffect } from "react"
import { Button, CustomHooks } from "@engaging-tech/components"
import generatePDF from "../../helpers/generatePDF"

const exportTypes = {
  print: "PRINT",
  download: "DOWNLOAD"
}

// The print button is always present, but if on safari or mobile/tablet, then it will use normal printing process. Otherwise it would perform PDF export and open in print mode
// The PDF export button is not present on safari or mobile/tablet.

const DownloadHeatMapPDF = ({
  title,
  subSection,
  isHeatMapPdfDownloading,
  filters,
  updateHeatMapPdfDownloading,
  updatePDFProgress,
  updatePdfDownloading
}) => {
  const downloadingRef = useRef(isHeatMapPdfDownloading)
  const theme = CustomHooks.useStyledTheme()

  const device = CustomHooks.useClientDevice()

  useEffect(() => {
    downloadingRef.current = isHeatMapPdfDownloading
  }, [isHeatMapPdfDownloading])

  useEffect(() => {
    updatePdfDownloading(isHeatMapPdfDownloading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeatMapPdfDownloading])

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const handleClick = ({ attemptNumber = 1, exportType }) => {
    updateHeatMapPdfDownloading(true)
    const reportItems = document.querySelectorAll(
      ".report-heatMap-item-exporting-child"
    )

    if (
      reportItems.length > 0 &&
      downloadingRef.current &&
      attemptNumber !== 1
    ) {
      if (typeof window !== "undefined") {
        window.scroll(0, 0)
      }
      if (isSafari || device !== "DESKTOP") {
        setTimeout(() => {
          updateHeatMapPdfDownloading(false)
        }, 1000)

        window.print()
      } else {
        generatePDF({
          pages: reportItems,
          theme,
          downloadingRef,
          updatePdfDownloading: updateHeatMapPdfDownloading,
          title,
          exportType,
          subSection,
          filters,
          updatePDFProgress
        })
      }
    } else if (attemptNumber < 5) {
      setTimeout(
        () =>
          handleClick({
            attemptNumber: attemptNumber + 1,
            exportType
          }),
        1000
      )
    } else {
      updateHeatMapPdfDownloading(false)
    }
  }

  return (
    <>
      <Button
        variant="outline.secondary.0"
        minWidth={[175, 200]}
        color="secondary.0"
        leadingIcon={isSafari ? "print" : "cloud_download"}
        fontSize={[2, 3]}
        elevation={0}
        m="6px"
        px={3}
        fontWeight={500}
        onClick={() => {
          handleClick({
            exportType: exportTypes.print
          })
        }}
      >
        Print
      </Button>

      {!isSafari && device === "DESKTOP" && (
        <>
          <Button
            variant="outline.secondary.0"
            minWidth={[175, 200]}
            color="secondary.0"
            leadingIcon="cloud_download"
            fontSize={[2, 3]}
            m="6px"
            elevation={0}
            mt={[0, 2]}
            px={3}
            fontWeight={500}
            onClick={() => {
              handleClick({
                exportType: exportTypes.download
              })
            }}
          >
            Download PDF
          </Button>
        </>
      )}
    </>
  )
}
export default DownloadHeatMapPDF
