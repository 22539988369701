import { gql } from "@apollo/client"

const SAVE_BASKET = gql`
  mutation saveBasket(
    $priceId: String!
    $productId: String!
    $object_type: String!
  ) {
    saveBasket(
      priceId: $priceId
      productId: $productId
      object_type: $object_type
    ) {
      price
      tax
      totalPrice
      acceptedCurrencies
      products {
        productId
        priceId
        object_type
        priceName
        productName
        priceDescription
        unit_amount
        thumbnail
        disclaimer
      }
    }
  }
`

export default SAVE_BASKET
