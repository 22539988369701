/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import PageLayout from "../../layouts/PageLayout"
import Banner from "../../features/selfServiceSurveys/components/Banner"
import PageManagerContainer from "../../features/selfServiceSurveys/containers/PageManager"
import { OrgGuard } from "../../features/account"
import { wellbeingPages } from "../../features/selfServiceSurveys/lib/pageControl"
import {
  setObjectType,
  setSurveyType
} from "../../features/selfServiceSurveys/store/actions"

const WellbeingSurvey = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setSurveyType("wellbeingSurvey"))
    dispatch(setObjectType(history.location.pathname.split("/")[2]))
  }, [])

  return (
    <OrgGuard>
      <PageLayout banner={Banner}>
        <PageManagerContainer pages={wellbeingPages} />
      </PageLayout>
    </OrgGuard>
  )
}

export default WellbeingSurvey
