export const getArrowPixelsFromStart = ({
  pointValue,
  minValue,
  maxValue,
  containerDimensions,
  arrowSize
}) => {
  const range = maxValue - minValue

  const distanceFromStart = range - (maxValue - pointValue)

  const percentageFromStart = distanceFromStart / range

  return containerDimensions.width * percentageFromStart - arrowSize / 2
}

export const getColor = ({
  pointValue,
  minValue,
  maxValue,
  containerDimensions,
  arrowSize,
  barStructure
}) => {
  const pixelsFromStart =
    getArrowPixelsFromStart({
      pointValue,
      minValue,
      maxValue,
      containerDimensions,
      arrowSize
    }) +
    arrowSize / 2
  let pixelSegmentCount = 0

  const matchedSegment = barStructure.find(s => {
    const segmentPixels = (containerDimensions.width / 100) * s.percentage
    pixelSegmentCount += segmentPixels

    return pixelsFromStart <= pixelSegmentCount
  })

  return matchedSegment?.color || "black"
}
