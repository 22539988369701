import { connect } from "react-redux"
import { getIsDeleteBarOpen } from "../../store/report.selectors"
import { setDeleteReport } from "../../store/report.actions"

import ReportsBar from "../components/ReportsBar"

const mapState = state => ({
  isBarOpen: getIsDeleteBarOpen(state)
})

const mapDispatch = dispatch => ({
  setReportsToBeSelected: reportArray => dispatch(setDeleteReport(reportArray))
})

const DeleteReportsBarContainer = connect(mapState, mapDispatch)(ReportsBar)

export default DeleteReportsBarContainer
