import React from "react"

import ForgotPasswordForm from "../forms/ForgotPassword"

/**
 * The "Forgot Password" dialog box.
 * @param {React.Component} siteLogo The brand logo to show within the dialog.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 */
const ForgotPassword = ({ siteLogo, cancelRoute, redirectTo }) => (
  <ForgotPasswordForm
    siteLogo={siteLogo}
    cancelRoute={cancelRoute}
    redirectTo={redirectTo}
  />
)

export default ForgotPassword
