/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import React, { useState } from "react"
import { startOfDay, endOfDay } from "date-fns"
import { DatePicker2, Button, Flex } from "@engaging-tech/components"
import styled from "styled-components"

const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.4;
  }
`

const ComparisonViewTimePickers = ({
  handleGetComparisonData,
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const [startError, setStartError] = useState(null)
  const [endError, setEndError] = useState(null)

  const handleChange = (date, error, setDate, setError) => {
    setError(null)
    setDate(null)

    if (error) {
      setError(error)
      return
    }

    if (new Date() < date) {
      setError("choose a date from the past")
      return
    }

    setDate(date)
  }

  return (
    <Flex width={1 / 1} flexDirection="column" alignItems="center">
      <DatePicker2
        zIndex={30001}
        label="Start Date"
        error={startError}
        date={startDate}
        onDateChange={(date, error) => {
          handleChange(
            date,
            error,
            setStartDate,
            setStartError,
            startOfDay,
            "startDate"
          )
        }}
        maxDate={new Date()}
      />
      <DatePicker2
        zIndex={30001}
        label="End Date"
        error={endError}
        date={endDate}
        onDateChange={(date, error) =>
          handleChange(
            date,
            error,
            setEndDate,
            setEndError,
            endOfDay,
            "endDate"
          )
        }
        minDate={startDate}
        maxDate={new Date()}
      />
      <StyledButton
        bg="primary.0"
        color="light.0"
        width="170px"
        onClick={() => handleGetComparisonData()}
        disabled={startError || endError || false}
      >
        Set new date range
      </StyledButton>
    </Flex>
  )
}

export default ComparisonViewTimePickers
