import React, { useContext } from "react"
import { Flex, RoundedDropdown, ReactSelect } from "@engaging-tech/components"
import questionTemplate from "../../lib/questionTemplate"
import options from "../../lib/questionIconOptions"
import TemplateQuestionContext from "../TemplateQuestionContext"
import EditQuestionTitle from "./EditQuestionTitle"
import EditCustomQuestionBody from "./EditCustomQuestionBody"
import EditQuestionLabel from "./EditQuestionLabel"

const ChooseQuestionIcon = ({ setQuestionIcon }) => {
  const { id, icon } = useContext(TemplateQuestionContext)

  const data = options?.map(option => {
    return { value: option.id, label: option.value, icon: option.image }
  })

  return (
    <ReactSelect
      options={data}
      defaultValue={data.filter(option => option.value === icon)[0]}
      onChange={({ value }) => setQuestionIcon(value, id)}
    />
  )
}

const ChooseQuestionType = ({ setCustomQuestionType }) => {
  const { id, type } = useContext(TemplateQuestionContext)

  return (
    <RoundedDropdown
      mb={2}
      width={1 / 1}
      onChange={e => setCustomQuestionType(e.target.value, id)}
      value={type}
    >
      {questionTemplate.map(value => (
        <option key={value.label} value={value.config.type}>
          {value.label}
        </option>
      ))}
    </RoundedDropdown>
  )
}

const EditCustomQuestion = ({
  setCustomQuestionType,
  deleteOption,
  changeScale,
  setQuestionTitle,
  setQuestionLabel,
  setValue,
  setField,
  setQuestionIcon,
  setPageErrors
}) => {
  const { type } = useContext(TemplateQuestionContext)

  return (
    <Flex width={1 / 1} justifyContent="space-between">
      <Flex width={0.75 / 1} pr={3} flexDirection="column">
        <EditQuestionTitle
          setQuestionTitle={setQuestionTitle}
          setPageErrors={setPageErrors}
        />
        <EditCustomQuestionBody
          deleteOption={deleteOption}
          changeScale={changeScale}
          setValue={setValue}
          setField={setField}
          setPageErrors={setPageErrors}
        />
      </Flex>
      <Flex flexDirection="column" width={0.25 / 1}>
        <ChooseQuestionType setCustomQuestionType={setCustomQuestionType} />
        <ChooseQuestionIcon setQuestionIcon={setQuestionIcon} />
        {["dropdown", "radio"].includes(type) && (
          <EditQuestionLabel
            setQuestionLabel={setQuestionLabel}
            setPageErrors={setPageErrors}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default EditCustomQuestion
