import { connect } from "react-redux"
import { EBNSelectors } from ".."
import ResearchComponent from "../components/Research"

const mapState = state => ({
  research: EBNSelectors.getRecentResearch(state)
})

const Research = connect(mapState)(ResearchComponent)

export default Research
