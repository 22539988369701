import { usePaths } from "@engaging-tech/routing"
import React from "react"
import JoinEventsComponent from "../components/JoinEvents"
import EBNBillingWrapper from "../containers/EBNBillingWrapper"

const JoinEvents = () => {
  const paths = usePaths()
  return (
    <EBNBillingWrapper cancelRoute={paths.home.index}>
      {props => <JoinEventsComponent {...props} />}
    </EBNBillingWrapper>
  )
}

export default JoinEvents
