import { Box, Button, Flex, Text, MatrixChart } from "@engaging-tech/components"
import React, { useState } from "react"
import styled from "styled-components"

const StyledBox = styled(Box)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const DisplayMatrix = ({
  matrixData,
  axisLabels,
  quadrantDescriptions,
  exportingPDF
}) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const matrixBandsKeys = Object.keys(quadrantDescriptions)
  return (
    <>
      {exportingPDF ? (
        matrixBandsKeys.map(key => {
          if (quadrantDescriptions[key].title) {
            return (
              <Flex
                flexDirection="row"
                mb={2}
                key={quadrantDescriptions[key].title}
              >
                <Text
                  width="auto"
                  fontWeight={700}
                  minWidth={200}
                  maxWidth={200}
                  pr={2}
                >
                  {quadrantDescriptions[key].title}:{" "}
                </Text>
                <Text width="auto">
                  {quadrantDescriptions[key].description}
                </Text>
              </Flex>
            )
          }
          return <></>
        })
      ) : (
        <Flex justifyContent="flex-end" pb={2} mt={exportingPDF ? 2 : 0}>
          <Button
            variant="text.primary.0"
            trailingIcon="help"
            color="primary.0"
            fontSize={2}
            elevation={0}
            onClick={() => setShowOverlay(!showOverlay)}
          >
            Show Label Descriptions
          </Button>
        </Flex>
      )}

      <StyledBox width={1 / 1}>
        <MatrixChart
          xAxisLabel={axisLabels.xAxisLabels.questions.map(label => label)}
          showQuadrantDescriptions={showOverlay}
          yAxisLabel={axisLabels.yAxisLabels.questions.map(label => label)}
          dataset={matrixData}
          showDataLabels={exportingPDF}
          disableAllAnimation={exportingPDF}
          quadrantDescriptions={quadrantDescriptions}
        />
      </StyledBox>
    </>
  )
}

export default DisplayMatrix
