/* eslint-disable no-nested-ternary */

/* eslint-disable react-hooks/exhaustive-deps */
import { CustomHooks, Spinner } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import ErrorState from "../../../features/business-information/components/error-state"
import NoMobileDevice from "../../../features/surveyManagement/template/components/NoMobileDevice"
import BottomStickyNav from "../../../features/surveyManagement/template/containers/BottomStickyNav"
import TemplateViewContainer from "../../../features/surveyManagement/template/containers/views/TemplateView"
import {
  GET_CORE_QUESTIONS,
  LOAD_TEMPLATE
} from "../../../features/surveyManagement/template/services/template.queries"
import {
  clearTemplateData,
  loadCoreQuestionsFail,
  loadCoreQuestionsReq,
  loadCoreQuestionsSuccess,
  loadTemplateFail,
  loadTemplateReq,
  loadTemplateSuccess,
  setTemplateId,
  setViewOnly
} from "../../../features/surveyManagement/template/store/template.actions"
import { getIsDraft } from "../../../features/surveyManagement/template/store/template.selectors"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import CreationFormsLayout from "../../../layouts/CreationFormsLayout"

const TemplateEdit = ({ match }) => {
  const device = CustomHooks.useClientDevice()
  const dispatch = useDispatch()
  const isDraft = useSelector(getIsDraft)

  useEffect(() => {
    dispatch(clearTemplateData())
    dispatch(setViewOnly(false))
    dispatch(setTemplateId(match.params.id))
  }, [])

  const { isLoading, errors } = useBusinessAppQuery(LOAD_TEMPLATE, {
    initAction: loadTemplateReq,
    successAction: loadTemplateSuccess,
    failAction: loadTemplateFail,
    variables: {
      id: match.params.id
    }
  })

  const { isLoading: loading, errors: isErrors } = useBusinessAppQuery(GET_CORE_QUESTIONS, {
    initAction: loadCoreQuestionsReq,
    successAction: loadCoreQuestionsSuccess,
    failAction: loadCoreQuestionsFail,
    variables: {
      type: "organisation"
    }
  })

  if (device === "MOBILE") return <NoMobileDevice />

  return (
    <CreationFormsLayout bg="#EEF4F9" maxWidth={1064} mx={20} my={5} BottomNav={() => <BottomStickyNav />}>
      {isLoading || loading ? (
        <Spinner justifyContent="center" color="primary.0" />
      ) : errors || isErrors || !isDraft ? (
        <ErrorState message="You can only edit templates that are in draft mode" />
      ) : (
        <TemplateViewContainer />
      )}
    </CreationFormsLayout>
  )
}

export default TemplateEdit
