import { Box, Button, Flex, H2, Text } from "@engaging-tech/components"
import { Item } from "@engaging-tech/components/dist/lib/List/List"
import React, { useState } from "react"
import styled from "styled-components"

import SliceAndDiceCompareModal from "../../../sliceAndDice/components/SliceAndDiceCompareModal"

const List = styled.ul`
  margin-top: 0px;
  margin-bottom: 0px;
`
const UnderlinedText = styled(Text)`
  text-decoration: underline;
  text-decoration-thickness: 3px;
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
`
const CardWithBorder = styled(Box)`
  border-radius: 10px;
  border: 10px solid;
  border-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary[0] : "#fff"};
`

const SliceAndDiceCard = ({
  id,
  selected,
  title,
  description,
  infoTopics,
  priceValue,
  priceCurrency,
  handleSelect
}) => {
  const [showPreview, setShowPreview] = useState(false)

  const selectProduct = () => {
    handleSelect(id)
  }

  return (
    <>
      <CardWithBorder selected={selected} width={1 / 1}>
        <Flex
          flexDirection="column"
          py={[2, 5]}
          px={[2, 3]}
          bg="secondary.0"
          borderRadius={selected ? 0 : 10}
        >
          <Flex borderRadius={"10px"} justifyContent="space-between">
            <H2
              fontSize={5}
              textAlign="left"
              color="light.0"
              mb={3}
              fontWeight={600}
            >
              {title}
            </H2>
            <Text
              color="light.0"
              fontSize={6}
              fontWeight={600}
              width="fit-content"
            >
              {new Intl.NumberFormat("en-EN", {
                style: "currency",
                minimumFractionDigits: 0,
                currency: priceCurrency
              }).format(priceValue)}
            </Text>
          </Flex>
          <Flex borderRadius={"10px"}>
            <Text color="light.0">{description}</Text>
          </Flex>

          <Flex flexDirection={["column"]} mt={2}>
            <List>
              {infoTopics.map(item => (
                <Item key={item} color="light.0" mb={[2, 3]}>
                  {item}
                </Item>
              ))}
            </List>
          </Flex>

          <Flex
            flexDirection={["column", "row"]}
            alignItems="center"
            justifyContent="space-between"
          >
            <UnderlinedText onClick={() => setShowPreview(true)}>
              What’s included?
            </UnderlinedText>
            <Button
              bg={"primary.0"}
              color="light.0"
              width="80px"
              onClick={selectProduct}
            >
              SELECT
            </Button>
          </Flex>
        </Flex>
      </CardWithBorder>
      {showPreview && (
        <SliceAndDiceCompareModal setShowPreview={setShowPreview} />
      )}
    </>
  )
}

export default SliceAndDiceCard
