import * as y from "yup"

const formSchema = y.object().shape({
  email: y
    .string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address")
})

export default formSchema
