import { Spinner } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"

import { OrgGuard } from "../../features/account"
import ErrorState from "../../features/business-information/components/error-state"
import ViewAllLogos from "../../features/logos/views/ViewAllLogos"
import BackBar from "../../features/ui/components/BackBar"
import { GET_ALL_LOGOS } from "../../graphql/queries"
import useBusinessAppQuery from "../../hooks/useBusinessAppQuery"
import PageLayout from "../../layouts/PageLayout"

const LogosHome = () => {
  const paths = usePaths()

  const { isLoading, data, errors } = useBusinessAppQuery(GET_ALL_LOGOS)

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />
  if (errors) return <ErrorState />

  return (
    <OrgGuard>
      <PageLayout
        title="Logos and Certifications"
        heading="Choose your award win to see which logos and certificates you can download. "
        banner={() => <BackBar to={paths.home.index} text="Back" visible />}
      >
        <ViewAllLogos data={data?.getAllLogos} />
      </PageLayout>
    </OrgGuard>
  )
}

export default LogosHome
