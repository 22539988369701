import { connect } from "react-redux"
import {
  getEmployeeCount,
  getShareableLinks
} from "../../store/awards.selectors"
import { setEmployeeCount, setPageErrors } from "../../store/awards.actions"
import EmployeeCount from "../../components/OrganisationData/EmployeeCount"

const mapState = state => ({
  shareableLinks: getShareableLinks(state),
  employeeCount: getEmployeeCount(state)
})

const mapDispatch = (dispatch, { id }) => ({
  setEmployeeCount: count => dispatch(setEmployeeCount(count)),
  handleErrors: (errors, type) => dispatch(setPageErrors(errors, type, id))
})

const EmployeeCountContainer = connect(mapState, mapDispatch)(EmployeeCount)

export default EmployeeCountContainer
