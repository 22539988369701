import React, { useContext } from "react"
import { Flex, Text } from "@engaging-tech/components"
import TemplateQuestionContext from "../TemplateQuestionContext"

const Field = ({ label, index, isCore }) => {
  const fieldNumberSize = 25
  return (
    <Flex
      width="100%"
      maxWidth={600}
      mb={2}
      alignItems="center"
      className="short-text-field-preview"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        borderRadius={99}
        bg={isCore ? "secondary.0" : "primary.0"}
        width={fieldNumberSize}
        height={fieldNumberSize}
        minHeight={fieldNumberSize}
        minWidth={fieldNumberSize}
        mr={3}
      >
        <Text width="auto" px={2} color="light.0" height="auto" noSelect>
          {index + 1}
        </Text>
      </Flex>
      <Text
        width="100%"
        px={2}
        color="dark.2"
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          borderBottom: "1px solid #AFAFAF"
        }}
        height="100%"
      >
        {label}
      </Text>
    </Flex>
  )
}

const ShortTextQuestionPreview = () => {
  const { fields, isCore } = useContext(TemplateQuestionContext)

  return (
    <Flex flexDirection="column" height="inherit" justifyContent="center">
      {fields?.map(({ label }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Field key={i} label={label} index={i} isCore={isCore} />
      ))}
    </Flex>
  )
}

export default ShortTextQuestionPreview
