import React from "react"

const FlightRiskLogo = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z"
        fill="#E8F0FB"
      />
      <path
        d="M4.66 20.43H23.66V22.43H4.66V20.43ZM24.23 11.07C24.02 10.27 23.19 9.79 22.39 10.01L17.08 11.43L10.18 5L8.25 5.51L12.39 12.68L7.42 14.01L5.45 12.47L4 12.86L6.59 17.35L23.16 12.92C23.97 12.69 24.44 11.87 24.23 11.07Z"
        fill="#1866DB"
      />
    </svg>
  )
}
export default FlightRiskLogo
