import { Flex, MotionFlex, MotionSelect, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import SliceAndDiceReportWrapper from "../../../sliceAndDice/components/SliceAndDiceReportWrapper"
import ReportCard from "../../components/ReportCard"
import SentimentAnalysisLogo from "../../reportLogos/SentimentAnalysisLogo"
import { getSliceAndDiceReport } from "../../store/report.selectors"
import TopSixWords from "../containers/TopSixWords"
import WordCloud from "../containers/WordCloud"

const SentimentAnalysisSection = ({
  exportingPDF,
  forceSelectedQuestionId,
  wordCountQuestions
}) => {
  const [wordCount, setWordCount] = useState(null)
  const [selectedOptionId, setSelectedOptionId] = useState(null)
  const isSliceAndDiceMember = useSelector(getSliceAndDiceReport)?.active
  const upgradedReport = new URLSearchParams(window.location.search).get(
    "upgraded"
  )
  const heading = "Comment Analysis"

  return (
    <ReportCard
      heading={heading}
      width="100%"
      height={400}
      exportingPDF={exportingPDF}
      HeadingImageComponent={SentimentAnalysisLogo}
      metaProps={{ mb: 2 }}
      innerProps={{ justifyContent: "space-between" }}
      showUpgradeLabel={upgradedReport}
    >
      <Text color="dark.2" fontSize="18px" mb={3}>
        A breakdown of the most frequent words used in your written survey
        responses
      </Text>
      <SliceAndDiceReportWrapper
        isSAndDMember={isSliceAndDiceMember}
        feature="sentimentAnalysis"
      >
        <Flex
          flexDirection={["column", "row"]}
          height={["auto", 400]}
          justifyContent="space-between"
          width="100%"
        >
          <Flex
            borderRadius={6}
            bg="#F5F5F5"
            flexDirection="column"
            p={3}
            mr={["0px", "10px"]}
            mb={["10px", "0px"]}
            width={["100%", "calc(100% - 280px)"]}
            height="100%"
          >
            {forceSelectedQuestionId || wordCountQuestions.length ? (
              <MotionSelect
                mb={3}
                disableClearButton
                onChange={option => setSelectedOptionId(option.id)}
                defaultOptionId={
                  forceSelectedQuestionId || wordCountQuestions[0]?.questionId
                }
                options={wordCountQuestions}
                width="100%"
                height={40}
                bg="dark.4"
                dropdownBg="dark.4"
                borderRadius={10}
                maxExtendedHeight={270}
                scrollBarThumbColor="dark.3"
                iconColor="dark.1"
                border={{ color: "#6398C4", width: "2px", style: "solid" }}
                textStyling={{
                  fontSize: 14,
                  ml: 3,
                  mr: 3,
                  color: "dark.1",
                  fontWeight: 500
                }}
                buttonTextStyling={{
                  fontSize: 14,
                  ml: 3,
                  mr: 0,
                  color: "dark.1",
                  fontWeight: 500
                }}
                selectedTextStyling={{
                  fontSize: 14,
                  ml: 3,
                  mr: 3,
                  color: "dark.0",
                  fontWeight: 500
                }}
              />
            ) : null}
            <Flex
              height={40}
              width="auto"
              mt={2}
              style={{ flexShrink: 0 }}
              bottom={50}
              position="absolute"
            >
              <AnimatePresence>
                {wordCount && (
                  <MotionFlex
                    bg="#173C5A"
                    borderRadius={4}
                    width="auto"
                    p={2}
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 5 }}
                  >
                    <Text width="auto" color="light.0">
                      Count: {wordCount}
                    </Text>
                  </MotionFlex>
                )}
              </AnimatePresence>
            </Flex>

            <WordCloud
              setWordCount={setWordCount}
              questionId={forceSelectedQuestionId || selectedOptionId}
              exportingPDF={exportingPDF}
            />
          </Flex>
          <TopSixWords
            exportingPDF={exportingPDF}
            questionId={forceSelectedQuestionId || selectedOptionId}
          />
        </Flex>
      </SliceAndDiceReportWrapper>
    </ReportCard>
  )
}

const SentimentAnalysis = ({ exportingPDF, wordCountQuestions }) => {
  if (exportingPDF) {
    return wordCountQuestions.map(w => (
      <SentimentAnalysisSection
        key={w.id}
        exportingPDF={exportingPDF}
        wordCountQuestions={wordCountQuestions}
        forceSelectedQuestionId={w.id}
      />
    ))
  }

  return (
    <SentimentAnalysisSection
      exportingPDF={exportingPDF}
      wordCountQuestions={wordCountQuestions}
    />
  )
}

export default SentimentAnalysis
