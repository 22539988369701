import { Button, Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const NormalisedText = styled(Text)`
  text-transform: initial;
  text-align: center;
`

export default function BottomNav({ onCancel, onClose }) {
  return (
    <Flex
      width="100vw"
      height={[100, 70]}
      bg="light.0"
      elevation={3}
      zIndex={99999999}
      position="fixed"
      style={{ bottom: "0px" }}
      alignItems="center"
      justifyContent="center"
      px={5}
      py={[3, 0]}
      flexDirection={["column", "row", "row"]}
    >
      <Flex justifyContent="space-between" maxWidth="1000px" width="100%">
        <Flex>
          <Button
            variant="secondary.0"
            mx={2}
            type="button"
            style={{ minHeight: 32 }}
            px={4}
            onClick={e => {
              e.preventDefault()
              onCancel()
            }}
          >
            <NormalisedText color="light.0">Discard Changes</NormalisedText>
          </Button>
          <Button
            variant="secondary.0"
            mx={2}
            type="button"
            style={{ minHeight: 32 }}
            px={4}
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
          >
            <NormalisedText color="light.0">Go Back</NormalisedText>
          </Button>
        </Flex>
        <Flex width="auto">
          <Button variant="primary.0" mx={2} type="submit" width="auto" style={{ minHeight: 32 }} px={4}>
            <NormalisedText width="auto" color="light.0">
              Save and close
            </NormalisedText>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
