import { connect } from "react-redux"
import { setGenerateReportTitle } from "../../store/report.actions"

import DialogueTextField from "../components/DialogueTextField"

const mapDispatch = dispatch => ({
  setTitle: title => dispatch(setGenerateReportTitle(title))
})

const DialogueTextFieldContainer = connect(null, mapDispatch)(DialogueTextField)

export default DialogueTextFieldContainer
