import { Button, CircularProgress, Flex, Icon, Text } from "@engaging-tech/components"
import { Link, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import { ScoreLabel, getLabelData } from "../../components/ScoreLabel"

const StyledFlex = styled(Flex)`
  flex-direction: column;
  position: relative;
  padding-bottom: ${({ device, br }) => device === "MOBILE" && br && "30px"};
  padding-top: ${({ device, br }) => device === "MOBILE" && !br && "30px"};

  &:before {
    content: "";
    position: absolute;
    top: ${({ device }) => device !== "MOBILE" && "20px"};
    right: ${({ device }) => device !== "MOBILE" && "0px"};
    bottom: ${({ device }) => device === "MOBILE" && "0px"};
    height: 90%;
    width: 100%;
    border-right: ${({ br, device }) => device !== "MOBILE" && br && "1px solid #B7B7B7"};
    border-bottom: ${({ device, br }) => device === "MOBILE" && br && "1px solid #B7B7B7"};
    z-index: 9999;
  }
`

const AreaForComponent = ({ score, questions, type, title, name, reportId, device }) => {
  const router = useRouter()

  return (
    <StyledFlex py={1} width="95%" justifyContent="space-between" br={type === "low"} device={device}>
      <Flex flexDirection="column">
        <Flex flexDirection="column" alignItems="center" width="100%">
          <Text textAlign="center" justifyContent="center" mx={2} fontWeight="600" fontSize={5}>
            {type === "low"
              ? `Area for improvement: ${title.split("-")[0]}`
              : `Area for celebration: ${title.split("-")[0]}`}
          </Text>
          <Text textAlign="center" justifyContent="center" mb={2}>
            {type === "low" ? "Your lowest scoring step" : "Your highest scoring step"}
          </Text>
          <CircularProgress
            percentage={score}
            width={160}
            mt={2}
            strokeColor={getLabelData(score).color}
            //   disableAllAnimation={exportingPDF}
          />
          <ScoreLabel textAlign="center" mt={3} score={score} />
        </Flex>
        <Flex flexDirection="column" width="100%" mt={3}>
          {questions.map(question => (
            <Flex key={question.question} width="100%" mt={2} flexDirection="row" alignItems="start">
              <Flex width="fit-content">
                <Icon
                  mt={1}
                  name={score < 60 ? "warning" : "check_circle"}
                  color={getLabelData(score).color}
                />
              </Flex>
              <Text fontSize={[4, 5]} mx={3}>
                {question.question}
              </Text>

              <Text fontSize={[4, 5]} fontWeight="600" width="fit-content" color={getLabelData(score).color}>
                {question.score}%
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex mt={3} textAlign="center" justifyContent="center" width="100%">
        <Button
          variant="secondary.0"
          // search param has to be hardcoded as it redirects to a completely different component.
          onClick={() => router.navigate(`${reportId}/action-plan/${name}?tag=sixStepsScores`)}
        >
          Instant Action Planning
        </Button>
      </Flex>
    </StyledFlex>
  )
}

export default AreaForComponent
