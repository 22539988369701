const { gql } = require("@apollo/client")

const GET_BIP = gql`
  query getBIP {
    getBIP {
      summary {
        name
        industryId
        websiteURL
        logoUrl
        contactEmail
        services
        size
        foundedAt
        locations {
          countryId
          city
        }
        social {
          twitter
          linkedIn
          pinterest
          facebook
          instagram
          youtube
        }
        address
        videos
        banner
        awardsLogo
      }
      culture {
        statement
        shortStatement
      }
      active
      organisationId
      status
    }
  }
`

export default GET_BIP
