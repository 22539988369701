import { Flex } from "@engaging-tech/components"
import React from "react"
import ReportCard from "../../components/ReportCard"
import BarIndicator from "../../components/BarIndicator/BarIndicator"
import { ScoreLabel } from "../../components/ScoreLabel"

const barStructure = [
  { color: "#EF464A", percentage: 25, labelStart: "-100", labelEnd: "-50" },
  { color: "#FFB547", percentage: 15, labelEnd: "-20" },
  { color: "#0DC187", percentage: 10 },
  { color: "#0DC187", percentage: 10 },
  { color: "#FFB547", percentage: 15, labelStart: "20" },
  { color: "#EF464A", percentage: 25, labelStart: "50", labelEnd: "100" }
]

const DiversityAndInclusionOnePager = ({
  averageScoreData,
  isPdfDownloading
}) => {
  const { score } = averageScoreData

  if (typeof score !== "number" || isNaN(score)) return <></>

  return (
    <ReportCard
      isPdfDownloading={isPdfDownloading}
      heading="Diversity & Inclusion"
      width={["100%"]}
      exportingPDF={false}
      innerProps={{ justifyContent: "space-between" }}
    >
      <BarIndicator
        dataSet={{ score }}
        disableAnimation={true}
        barStructure={barStructure}
        hideArrowLabels
        minValue={-100}
        maxValue={100}
        leftLabel="Majority"
        rightLabel="Minority"
      />
      <Flex flexDirection="column" mb={4}>
        <ScoreLabel showIcon score={100 - Math.abs(score)} />
      </Flex>
    </ReportCard>
  )
}

export default DiversityAndInclusionOnePager
