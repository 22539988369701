import { useState } from "react"
import PropTypes from "prop-types"
import useDidUpdateEffect from "../lib/useDidUpdateEffect"

const ToggleLogic = ({ initialState, onToggle, onReset, children }) => {
  const [on, setOn] = useState(initialState)

  const toggle = async () => setOn(currentOn => !currentOn)

  const toggleOn = () => setOn(true)
  const toggleOff = () => setOn(false)
  useDidUpdateEffect(() => onToggle(on), [on, onToggle])
  const reset = () => {
    setOn(initialState)
    onReset()
  }

  const getStateAndHelpers = () => ({
    on,
    toggle,
    reset,
    toggleOn,
    toggleOff
  })

  return children(getStateAndHelpers())
}

ToggleLogic.propTypes = {
  initialState: PropTypes.bool,
  onToggle: PropTypes.func,
  onReset: PropTypes.func,
  children: PropTypes.func.isRequired
}

ToggleLogic.defaultProps = {
  initialState: false,
  onToggle: () => {},
  onReset: () => {}
}

export default ToggleLogic
