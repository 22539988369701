import { Flex } from "@engaging-tech/components"
import React from "react"

const BlankLayout = ({ children, ...props }) => (
  <Flex as="main" flexDirection="column" alignItems="center">
    <Flex
      width={1 / 1}
      px={[1, 3, 4]}
      py={5}
      flex="1"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      maxWidth={1280}
      {...props}
    >
      {children}
    </Flex>
  </Flex>
)

export default BlankLayout
