import { Box, Button, Flex, Text, TextField } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import AccountDialog from "../../components/AccountDialog"

const ForgotPasswordFormContent = ({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading,
  siteLogo,
  subtitle,
  cancelRoute
}) => (
  <AccountDialog
    title="Forgotten your password?"
    subtitle="Send password reset via email"
    renderLogo={siteLogo}
    renderSubtitle={subtitle}
  >
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <TextField
          width={1 / 1}
          id="forgot-pw-email"
          name="email"
          type="email"
          label="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={touched.email && errors.email}
        />
      </Box>
      <Box mb={3}>
        <Text fontSize={[4, 3]}>Remembered your password?</Text>
        <Link to="/account/sign-in">
          <Text fontSize={[4, 3]} color="primary.0">
            {" "}
            Go to the back to sign in
          </Text>
        </Link>
      </Box>
      <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
        <Button
          variant="secondary.0"
          type="submit"
          disabled={!isValid || isLoading}
          ml={2}
          width="auto"
        >
          Send Email
        </Button>
        <Link to={cancelRoute} external>
          <Button type="button" variant="secondary.4" ml={2}>
            Cancel
          </Button>
        </Link>
        <Link to="/account/sign-up" mr="auto">
          <Button variant="secondary.4">Sign Up</Button>
        </Link>
      </Flex>
    </form>
  </AccountDialog>
)

export default ForgotPasswordFormContent
