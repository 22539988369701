import { Flex, Text } from "@engaging-tech/components"
import { ArrowPathIcon } from "@heroicons/react/24/outline"
import React from "react"
import { Tooltip } from "react-tooltip"

import TooltipIcon from "../tooltip-icon"

export default function SurveySubmissions({ isLoading = false, submissions, meta }) {
  return (
    <Flex flexDirection="column" alignItems="left" width={1}>
      <Flex>
        <a
          data-tooltip-id="survey-submissions-tooltip"
          data-tooltip-content="This show the ratio of total  submissions received till now v/s expected participants in the survey"
        >
          <Text fontSize={3} color="dark.2" pb={2} width="auto">
            Submission / Participants
          </Text>
          <TooltipIcon />
        </a>
        <Tooltip id="survey-submissions-tooltip" />
      </Flex>
      <Flex alignItems="center">
        <Text fontSize={6} fontWeight={800} color="dark.2" width="auto" mr="10px">
          {submissions} / {meta?.creditsPaid ?? 0}
        </Text>
        {isLoading ? <ArrowPathIcon width={22} height={22} /> : null}
      </Flex>
    </Flex>
  )
}
