export const types = {
  LOAD_JOB_MATCHES: "[Jobs] Load Job Matches",
  LOAD_JOB_MATCHES_SUCCESS: "[Jobs] Load Job Matches Success",
  LOAD_JOB_MATCHES_FAIL: "[Jobs] Load Job Matches Fail"
}

export const loadJobMatches = jobId => ({
  type: types.LOAD_JOB_MATCHES,
  payload: jobId
})

export const loadJobMatchesSuccess = jobs => ({
  type: types.LOAD_JOB_MATCHES_SUCCESS,
  payload: jobs
})

export const loadJobMatchesFail = () => ({
  type: types.LOAD_JOB_MATCHES_FAIL
})
