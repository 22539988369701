import { Flex, Text } from "@engaging-tech/components"
import React from "react"

import SurveyStatus from "./survey-status"

const addLeadingZero = num => (num < 10 ? `0${num}` : num)

const formatDate = date =>
  `${addLeadingZero(date.getDate())}/${addLeadingZero(
    date.getMonth() + 1
  )}/${date.getFullYear()}`

export default function SurveyDates({ startDate, endDate, status }) {
  return (
    <Flex
      flexDirection="column"
      width={["100%", "100%", "fit-content"]}
      minWidth={["100%", "100%", "fit-content"]}
      justifyContent="space-between"
      height={40}
      mb={[3, 3, 0]}
    >
      <SurveyStatus status={status} />
      <Flex width="auto">
        <Text fontSize={3} color="dark.2" width={80}>
          Start Date:
        </Text>
        <Text fontSize={3} color="dark.2" width="auto" fontWeight={500}>
          {formatDate(new Date(startDate))}
        </Text>
      </Flex>
      <Flex width="auto">
        <Text fontSize={3} color="dark.2" width={80}>
          End Date:
        </Text>
        <Text fontSize={3} color="dark.2" width="auto" fontWeight={500}>
          {formatDate(new Date(endDate))}
        </Text>
      </Flex>
    </Flex>
  )
}
