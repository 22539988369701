import styled from "styled-components"
import { hiDPI } from "polished"
import { Flex } from "@engaging-tech/components"

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Content = styled(Flex)`
  flex: 5;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.space[3]}px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    /* Offset for fixed position bottom bar */
    margin-bottom: ${({ theme }) => theme.space[6]}px;
  }
`

export const BottomBar = styled(Flex)`
  width: 100%;
  flex: 1;
  flex-grow: 0;
  justify-content: center;
  height: ${({ theme }) => theme.space[6]}px;
  padding: ${({ theme }) => theme.space[3]}px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    position: fixed;
    background-color: white;
    bottom: 0;
  }

  /* iPhone X */
  ${hiDPI(3)} {
    padding-bottom: 128px;
  }
`

export default FormWrapper
