import { TabletUp } from "@engaging-tech/components"
import { Formik } from "formik"
import React from "react"

import Breadcumbs from "../components/breadcrumbs"
import useBIPNavigationContext from "../hooks/use-bip-navigation-context"
import BipFormContent from "./bip-form"
import { validationSchema } from "./bip.schema"

const initialValues = {
  logoUrl: "",
  banner: "",
  name: "",
  websiteURL: "",
  culture: {
    statement: "",
    shortStatement: ""
  },
  contactEmail: "",
  services: "",
  size: "",
  industryId: "",
  foundedAt: new Date().getFullYear(),
  locations: [],
  social: {
    twitter: "",
    linkedIn: "",
    pinterest: "",
    facebook: "",
    instagram: "",
    youtube: ""
  },
  awardsLogo: [],
  awardsLogoList: [],
  videoLinkTemp: "",
  videos: []
}

const navigationItems = [
  { id: "business-information", title: "Business Information" },
  { id: "your-culture", title: "Your Culture" },
  { id: "review", title: "Review" },
  { id: "finish", title: "Finish" }
]

function TopNav() {
  const { activeTab } = useBIPNavigationContext()

  return (
    <TabletUp>
      <Breadcumbs items={navigationItems} active={activeTab} />
    </TabletUp>
  )
}

export default function BIPForm() {
  return (
    <div>
      <TopNav />

      <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnMount>
        <BipFormContent />
      </Formik>
    </div>
  )
}
