import { emailEB } from "./globalConstants"

export const header = [
  "PLEASE NOTE THIS TERMS APPLY TO THE PROVISION OF OUR ‘WORKL’ SERVICES, I.E. OUR B2B SERVICES OUTLINED IN MORE DETAIL AT BUSINESS.WORKL.CO. FOR OUR ‘WORKL’ CONSUMER TERMS AND CONDITIONS, PLEASE SEE https://workl.co/legal/terms-and-conditions.",
  "YOUR ATTENTION IS PARTICULARLY DRAWN TO THE PROVISIONS OF CLAUSE 13 (LIMITATION OF LIABILITY)."
]

export const terms = [
  {
    title: "About us",
    children: [
      {
        title: "Company details",
        content:
          "We are Engaging Works Group Ltd, a company registered in England and Wales. Our company registration number is 07946689 and our registered office is at Mole End Shorts Green Lane, Motcombe, Shaftesbury, Dorset, England, SP7 9PA. Our registered VAT number is 225707317. We operate under the trading names of WorkL and WorkL for Business."
      },
      {
        title: "Contacting us",
        content: `To contact us please email our customer service team at ${emailEB}. How to give us formal notice of any matter under the Contract is set out in clause 18.2.`
      }
    ]
  },
  {
    title: "Our contract with you",
    children: [
      {
        title: "Our contract",
        content:
          "These terms and conditions (Terms) apply to the order by you and supply of Services by us to you (Contract). They apply to the exclusion of any other terms that you seek to impose or incorporate, or which are implied by law, trade custom, practice or course of dealing."
      },
      {
        title: "Entire agreement",
        content:
          "The Contract is the entire agreement between you and us in relation to its subject matter. You acknowledge that you have not relied on any statement, promise or representation or assurance or warranty that is not set out in the Contract."
      }
    ]
  },
  {
    title: "Placing an order and its acceptance",
    children: [
      {
        title: "Placing your order",
        content:
          "To place an order, please follow the onscreen steps to sign your company up and select the right package based on the level of service you require. You may only submit an order using the method set out on the site. Each order is an offer by you to buy the services specified in the order (Services) subject to these Terms."
      },
      {
        title: "Correcting input errors",
        content:
          "Our order process allows you to check and amend any errors before submitting your order to us. Please check the order carefully before confirming it. You are responsible for ensuring that your order or any specification submitted by you is complete and accurate."
      },
      {
        title: "Acknowledging receipt of your order",
        content:
          "After you place your order, you will receive an email from us acknowledging that we have received it, but please note that this does not mean that your order has been accepted. Our acceptance of your order will take place as described in clause 3.4."
      },
      {
        title: "Accepting your order.",
        content:
          "Our acceptance of your order takes place when we send an email to you to accept it (Order Confirmation), at which point and on which date (Commencement Date) the Contract between you and us will come into existence. The Contract will relate only to those Services confirmed in the Order Confirmation."
      },
      {
        title: "If we cannot accept your order",
        content:
          "If we are unable to supply you with the Services for any reason, we will inform you of this by email and we will not process your order. If you have already paid for the Services, we will refund you the full amount."
      }
    ]
  },
  {
    title: "Cancelling your order and obtaining a refund",
    children: [
      {
        title: "Cancelling your order",
        content: `You may request a cancellation and full refund for our Services at any time by emailing ${emailEB}. We will determine whether this is possible on a case by case basis and, where possible, a refund will be made. If you are emailing us please include details of your order to help us to identify it. If you send us your cancellation notice by email, then your cancellation is effective from the date you send us the email.`
      },
      {
        title: "Obtaining a refund",
        content:
          "If you cancel the Contract, we will refund you in full for the price you paid for the Services, by the method you used for payment. We may deduct from any refund an amount for the supply of the Services provided for the period up to the time when you gave notice of cancellation in accordance with clause 4.2. The amount we deduct will reflect the amount that has been supplied as a proportion of the entirety of the Contract."
      }
    ]
  },
  {
    title: "Our services",
    children: [
      {
        title: "Descriptions of the services",
        content:
          "Any descriptions on our site are published for the sole purpose of giving an approximate idea of the services described in them. They will not form part of the Contract or have any contractual force."
      },
      {
        title: "Compliance with specification",
        content:
          "Subject to our right to amend the specification (see clause 5.3) we will supply the Services to you in accordance with the specification for the Services appearing on our information packs, sales materials and website at the date of your order in all material respects."
      },
      {
        title: "Changes to specification",
        content:
          "We reserve the right to amend the specification of the Services if required by any applicable statutory or regulatory requirement or if the amendment will not materially affect the nature or quality of the Services, and we will notify you in advance of any such amendment."
      },
      {
        title: "Reasonable care and skill",
        content:
          "We warrant to you that the Services will be provided using reasonable care and skill."
      },
      {
        title: "Time for performance",
        content:
          "We will use all reasonable endeavours to meet any performance dates specified in the Order Confirmation, but any such dates are estimates only and failure to perform the Services by such dates will not give you the right to terminate the Contract."
      }
    ]
  },
  {
    title: "Your obligations",
    children: [
      {
        content: "It is your responsibility to ensure that:",
        children: [
          "the terms of your order are complete and accurate;",
          "you co-operate with us in all matters relating to the Services;",
          "if applicable to the selected Services, you provide us, our employees, agents, consultants and subcontractors, with access to your premises, office accommodation and other facilities as we may reasonably require;",
          "you provide us with such information and materials we may reasonably require in order to supply the Services, and ensure that such information is complete and accurate in all material respects;",
          "if applicable, you obtain and maintain all necessary licences, permissions and consents which may be required for the Services before the date on which the Services are to start;",
          "you comply with all applicable laws, including health and safety laws; and",
          "if applicable, you keep all of our materials, equipment, documents and other property (Our Materials) at your premises in safe custody at your own risk, maintain Our Materials in good condition until returned to us, and not dispose of or use Our Materials other than in accordance with our written instructions or authorization."
        ]
      },
      {
        content:
          "If our ability to perform the Services is prevented or delayed by any failure by you to fulfil any obligation listed in clause 6.1 (Your Default):",
        children: [
          "we will be entitled to suspend performance of the Services until you remedy Your Default, and to rely on Your Default to relieve us from the performance of the Services, in each case to the extent Your Default prevents or delays performance of the Services. In certain circumstances Your Default may entitle us to terminate the Contract under clause 15;",
          "we will not be responsible for any costs or losses you sustain or incur arising directly or indirectly from our failure or delay to perform the Services; and",
          "it will be your responsibility to reimburse us on written demand for any costs or losses we sustain or incur arising directly or indirectly from Your Default."
        ]
      }
    ]
  },
  {
    title: "Charges",
    children: [
      {
        content:
          "In consideration of us providing the Services you must pay our charges (Charges) in accordance with this clause 8."
      },
      {
        content:
          "The Charges are the prices quoted on our site at the time you submit your order. Where the price is stated on our site as ‘POA’, we will provide you with a breakdown of the Charges, calculated on a time and materials basis, as soon as possible following receipt of your enquiry."
      },
      {
        content:
          "If you wish to change the scope of the Services after we accept your order, and we agree to such change, we will modify the Charges accordingly."
      },
      {
        content:
          "We take all reasonable care to ensure that the prices stated for the Services are correct at the time when the relevant information was entered into the system. However, please see clause 8.7 for what happens if we discover an error in the price of the Services you ordered."
      },
      {
        content:
          "Our Charges may change from time to time, but changes will not affect any order you have already placed."
      },
      {
        content:
          "Our Charges are exclusive of VAT. Where VAT is payable in respect of some or all of the Services you must pay us such additional amounts in respect of VAT, at the applicable rate, at the same time as you pay the Charges."
      },
      {
        content:
          "It is always possible that, despite our reasonable efforts, some of the Services on our site may be incorrectly priced. If the correct price for the Services is higher than the price stated on our site, we will contact you as soon as possible to inform you of this error and we will give you the option of continuing to purchase the Services at the correct price or cancelling your order. We will not process your order until we have your instructions. If we are unable to contact you using the contact details you provided during the order process, we will treat the order as cancelled and notify you in writing. However, if we mistakenly accept and process your order where a pricing error is obvious and unmistakable and could reasonably have been recognised by you as a mispricing, we may cancel supply of the Services and refund you any sums you have paid."
      }
    ]
  },
  {
    title: "How to pay",
    children: [
      {
        content:
          "Payment for any Services which require a one-off payment must be made on acceptance of your order."
      },
      {
        content:
          "Payment for any ongoing Services is required in advance. We will take your first payment upon acceptance of your order and will take subsequent payments monthly in advance if you are using any of our subscription services unless agreed otherwise."
      },
      {
        content:
          "Payment for subscription based Services is by direct debit. Your designated bank account will be charged automatically each month."
      },
      {
        content:
          "We will send you an electronic invoice within seven days of the beginning of the month following payment. For any failed or cancelled payments, your Service will be suspended and we will try to contact you to resolve the issue."
      },
      {
        content:
          "If you fail to make a payment under the Contract by the due date, then, without limiting our remedies under Clause 15 (Termination), you will have to pay interest on the overdue sum from the due date until payment of the overdue sum, whether before or after judgment. Interest under this Clause 9.4 will accrue each day at 4% a year above the Bank of England’s base rate from time to time, but at 4% a year for any period when that base rate is below 0%."
      }
    ]
  },
  {
    title: "Complaints",
    children: [
      {
        content: `If a problem arises or you are dissatisfied with the Services, please contact us at ${emailEB}.`
      }
    ]
  },
  {
    title: "Intellectual property rights",
    children: [
      {
        content:
          "All intellectual property rights in or arising out of or in connection with the Services (other than intellectual property rights in any materials provided by you) will be owned by us."
      },
      {
        content:
          "We agree to grant you a fully paid-up, worldwide, non-exclusive, royalty-free licence during the term of the Contract to copy the deliverables specified in your order (excluding materials provided you) for the purpose of receiving and using the Services and such deliverables in your business. You may not sub-license, assign or otherwise transfer the rights granted in this clause 11.2."
      },
      {
        content:
          "You agree to grant us a fully paid-up, non-exclusive, royalty-free, non-transferable licence to copy and modify any materials provided by you to us for the term of the Contract for the purpose of providing the Services to you."
      }
    ]
  },
  {
    title: "Data protection",
    children: [
      {
        content: "We will use any personal data that you provide to us to:",
        children: [
          "provide the Services;",
          "process your payment for the Services; and",
          "inform you about similar services that we provide (but you may stop receiving these at any time by contacting us or clicking ‘unsubscribe’)."
        ]
      },
      {
        content:
          "Full details of how we process personal data are set out in our privacy policy."
      }
    ]
  },
  {
    title: "Limitation of liability",
    children: [
      {
        content:
          "The limits and exclusions in this clause reflect the insurance cover we have been able to arrange and you are responsible for making your own arrangements for the insurance of any excess loss."
      },
      {
        content:
          "Nothing in the Contract limits any liability which cannot legally be limited, including liability for:",
        children: [
          "death or personal injury caused by negligence;",
          "fraud or fraudulent misrepresentation; and",
          "breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession)."
        ]
      },
      {
        content:
          "Subject to Clause 13.2, we will not be liable to you, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with the Contract for:",
        children: [
          "loss of profits;",
          "loss of sales or business;",
          "loss of agreements or contracts;",
          "loss of anticipated savings;",
          "loss of use or corruption of software, data or information;",
          "loss of or damage to goodwill; and",
          "any indirect or consequential loss."
        ]
      },
      {
        content:
          "Subject to Clause 13.2, our total liability to you arising under or in connection with the Contract, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, will be limited to 100% of the total Charges paid under the Contract."
      },
      {
        content:
          "We have given commitments as to compliance of the Services with the relevant specification in Clause 5.2. In view of these commitments, the terms implied by sections 3, 4 and 5 of the Supply of Goods and Services Act 1982 are, to the fullest extent permitted by law, excluded from the Contract."
      },
      {
        content:
          "Unless you notify us that you intend to make a claim in respect of an event within the notice period, we shall have no liability for that event. The notice period for an event shall start on the day on which you became, or ought reasonably to have become, aware of the event having occurred and shall expire 6 months from that date. The notice must be in writing and must identify the event and the grounds for the claim in reasonable detail."
      },
      {
        content: "This clause 13 will survive termination of the Contract."
      }
    ]
  },
  {
    title: "Confidentiality",
    children: [
      {
        content:
          "We each undertake that we will not at any time disclose to any person any confidential information concerning one another’s business, affairs, customers, clients or suppliers, except as permitted by clause 14.2."
      },
      {
        content: "We each may disclose the other’s confidential information:",
        children: [
          "to such of our respective employees, officers, representatives, subcontractors or advisers who need to know such information for the purposes of exercising our respective rights or carrying out our respective obligations under the Contract. We will each ensure that such employees, officers, representatives, subcontractors or advisers comply with this clause 14; and",
          "as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority."
        ]
      },
      {
        content:
          "Each of us may only use the other’s confidential information for the purpose of fulfilling our respective obligations under the Contract."
      }
    ]
  },
  {
    title: "Termination",
    children: [
      {
        content:
          "Without limiting any of our other rights, we may suspend the performance of the Services, or terminate the Contract with immediate effect by giving written notice to you if:",
        children: [
          "you commit a material breach of any term of the Contract and (if such a breach is remediable) fail to remedy that breach within 7 days of you being notified in writing to do so;",
          "you fail to pay any amount due under the Contract on the due date for payment;",
          "you take any step or action in connection with you entering administration, provisional liquidation or any composition or arrangement with your creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of your assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction;",
          "you suspend, threaten to suspend, cease or threaten to cease to carry on all or a substantial part of your business; or",
          "your financial position deteriorates to such an extent that in our opinion your capability to adequately fulfil your obligations under the Contract has been placed in jeopardy."
        ]
      },
      {
        content:
          "On termination of the Contract you must return all of Our Materials and any deliverables specified in your order which have not been fully paid for, if applicable. If you fail to do so, then we may enter your premises and take possession of them. Until they have been returned, you will be solely responsible for their safe keeping and must not use them for any purpose unconnected with the Contract."
      },
      {
        content:
          "Termination of the Contract will not affect your or our rights and remedies that have accrued as at termination."
      },
      {
        content:
          "Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination will remain in full force and effect."
      }
    ]
  },
  {
    title: "Events outside our control",
    children: [
      {
        content:
          "We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under the Contract that is caused by any act or event beyond our reasonable control (Event Outside Our Control)."
      },
      {
        content:
          "If an Event Outside Our Control takes place that affects the performance of our obligations under the Contract:",
        children: [
          "we will contact you as soon as reasonably possible to notify you; and",
          "our obligations under the Contract will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control. We will arrange a new date for performance of the Services with you after the Event Outside Our Control is over."
        ]
      },
      {
        content:
          "You may cancel the Contract affected by an Event Outside Our Control which has continued for more than 30 days. To cancel please contact us. If you opt to cancel we will refund the price you have paid, less the charges reasonably and actually incurred us by in performing the Services up to the date of the occurrence of the Event Outside Our Control."
      }
    ]
  },
  {
    title: "Non-solicitation",
    content:
      "You must not attempt to procure services that are competitive with the Services from any of our directors, employees or consultants, whether as an employee or on a freelance basis, during the period that we are providing the Services to you and for a period of six months following termination of the Contract."
  },
  {
    title: "Communications between us",
    children: [
      {
        content:
          "When we refer to “in writing” in these Terms, this includes email."
      },
      {
        content:
          "Any notice or other communication given under or in connection with the Contract must be in writing and be delivered personally, sent by pre-paid first class post or other next working day delivery service, or email."
      },
      {
        content:
          "A notice or other communication is deemed to have been received:",
        children: [
          "if delivered personally, on signature of a delivery receipt;",
          "if sent by pre-paid first class post or other next working day delivery service, at 9.00 am on the second working day after posting; or",
          "if sent by email, at 9.00 am the next working day after transmission."
        ]
      },
      {
        content:
          "In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an email, that such email was sent to the specified email address of the addressee."
      },
      {
        content:
          "The provisions of this clause will not apply to the service of any proceedings or other documents in any legal action."
      }
    ]
  },
  {
    title: "General",
    children: [
      {
        title: "Assignment & transfer",
        children: [
          "We may assign or transfer our rights and obligations under the Contract to another entity.",
          "You may only assign or transfer your rights or your obligations under the Contract to another person if we agree in writing."
        ]
      },
      {
        title: "Variation",
        content:
          "Any variation of the Contract only has effect if it is in writing and signed by you and us (or our respective authorised representatives)."
      },
      {
        title: "Waiver",
        content:
          "If we do not insist that you perform any of your obligations under the Contract, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you or that you do not have to comply with those obligations. If we do waive any rights, we will only do so in writing, and that will not mean that we will automatically waive any right related to any later default by you."
      },
      {
        title: "Severance",
        content:
          "Each paragraph of these Terms operates separately. If any court or relevant authority decides that any of them is unlawful or unenforceable, the remaining paragraphs will remain in full force and effect."
      },
      {
        title: "Third party rights",
        content:
          "The Contract is between you and us. No other person has any rights to enforce any of its terms."
      },
      {
        title: "Governing law and jurisdiction",
        content:
          "The Contract is governed by English law and we each irrevocably agree to submit all disputes arising out of or in connection with the Contract to the exclusive jurisdiction of the English courts."
      }
    ]
  }
]
