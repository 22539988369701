import { useLocation, useHistory } from "react-router-dom";

export const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const setQueryParam = (key, value) => {
    query.set(key, value);
    history.push({ search: query.toString() });
  };

  return { query, setQueryParam };
};
