import { connect } from "react-redux"
import EBNBillingComponent from "../components/EBNBilling"
import { AccountActions, AccountSelectors } from ".."

const mapState = state => ({
  isAuthenticated: AccountSelectors.getIsAuthenticated(state),
  isLoading: AccountSelectors.getIsLoading(state),
  intentSecret: AccountSelectors.getIntentSecret(state),
  paymentStatus: AccountSelectors.getPaymentStatus(state),
  discountState: AccountSelectors.getDiscountState(state)
})

const mapDispatch = dispatch => ({
  onSubmit: source => dispatch(AccountActions.createIntent(source)),
  onPaymentActionSuccess: () => dispatch(AccountActions.upgradeSuccess()),
  onPaymentActionFail: () => dispatch(AccountActions.upgradeFail()),
  onApplyDiscount: ({ discountCode }) =>
    dispatch(AccountActions.applyDiscount({ service: "ebn", discountCode }))
})

const EBNBilling = connect(mapState, mapDispatch)(EBNBillingComponent)

export default EBNBilling
