/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable react/jsx-curly-newline */
import { Button, Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"

import { SAVE_BASKET } from "../../../graphql/mutations"
import { FETCH_PRODUCTS } from "../../../graphql/queries"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { saveBasketFail, saveBasketReq, saveBasketSuccess } from "../../../store/basket/basket.actions"
import { getProductsFail, getProductsReq, getProductsSuccess } from "../../../store/products/products.actions"
import ErrorState from "../../business-information/components/error-state"
import EntryRequirements from "../components/SelectProduct/EntryRequirements"
import ChooseSurveySizeContainer from "../containers/SelectProduct/ChooseSurveySizeContainer"
import WhyToEnterContainer from "../containers/SelectProduct/WhyToEnterContainer"

const SelectProduct = ({
  selectedProduct,
  saveSurvey,
  participations,
  awardsType,
  setPage,
  awardsCurrency
}) => {
  const { errors, getData } = useBusinessAppQuery(FETCH_PRODUCTS, {
    initAction: getProductsReq,
    successAction: getProductsSuccess,
    failAction: getProductsFail,
    enabledOnMount: false
  })

  const { errors: basketErrors, post } = useBusinessAppMutation(SAVE_BASKET, {
    initAction: saveBasketReq,
    successAction: saveBasketSuccess,
    failAction: saveBasketFail
  })

  useEffect(() => {
    getData({ currency: awardsCurrency, object_type: awardsType })
  }, [awardsCurrency])

  const handleClick = () => {
    post({
      priceId: selectedProduct.id,
      productId: selectedProduct.product_details.id,
      object_type: awardsType
    })
    saveSurvey({
      page: 2,
      productId: selectedProduct.productId,
      shareableLinks: selectedProduct.options.shareableLinks
    })
    // Set page synchronously.
    setPage(2)
  }

  if (errors || basketErrors) return <ErrorState />

  return (
    <Flex mt={5} flexDirection="column">
      <ChooseSurveySizeContainer />
      <Flex mt={[5, 6]} width={1 / 1} justifyContent="flex-end">
        <Button
          color="light.0"
          bg="primary.0"
          width="120px"
          disabled={!!errors || !selectedProduct || !!basketErrors}
          position="relative"
          onClick={handleClick}
        >
          Next ⟶
        </Button>
      </Flex>
      <WhyToEnterContainer />
      <EntryRequirements participations={participations} />
    </Flex>
  )
}

export default SelectProduct
