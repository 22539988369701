import { connect } from "react-redux"

import * as IndicatorSelectors from "../../Indicators/store/indicators.selectors"
import DiversityAndInclusionIndicatorComponent from "../components/DiversityAndInclusionIndicator"

const mapState = state => ({
  averageScoreData: IndicatorSelectors.getDiversityAndInclusionAverageScores(state),
  breakDown: "diversityAndInclusion"
})

const DiversityAndInclusionIndicator = connect(mapState)(DiversityAndInclusionIndicatorComponent)

export default DiversityAndInclusionIndicator
