import React from "react"
import { PrivacyPolicy } from "@engaging-tech/legal"
import { Box } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"

import {
  collectedInformation,
  contactEmail,
  registeredAddress,
  dataController
} from "../../lib/legal"

import PageLayout from "../../layouts/PageLayout"

const Privacy = () => (
  <PageLayout>
    <Box pt={[3, 6]} px={[3, 4]} maxWidth={1080} mx="auto">
      <PrivacyPolicy
        date={new Date("2024-01-01")}
        cookiesUrl={`${getConfig().home.url}/legal/cookies`}
        siteName="Engaging Business"
        collectedInformation={collectedInformation}
        contactEmail={contactEmail}
        registeredAddress={registeredAddress}
        dataController={dataController}
      />
    </Box>
  </PageLayout>
)

export default Privacy
