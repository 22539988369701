import { gql } from "@apollo/client"

const GET_MY_EVENTS = gql`
  query($limit: Int) {
    getMyEvents(limit: $limit) {
      id
      title
      startDate
      location {
        name
      }
    }
  }
`

export default GET_MY_EVENTS
