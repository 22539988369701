/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, H2, Text } from "@engaging-tech/components"
import React, { useEffect } from "react"
import styled from "styled-components"
import Seperator from "../../../../ui/components/Separator"
import { Card, LoadingState } from "../../../components"
import {
  calcCreditsNeededToPurchase,
  generateLoadingText,
  hasCorrectSurveyLicence,
  roundTo2DecimalPlaces
} from "./lib"

const STANDARD_SURVEY_LICENCE_PRICE = 500
const VAT_PERCENT = 0.2
const CREDIT_PRICE = 3

const RightText = styled(Text)`
  text-align: right;
`

const OrderSummary = ({
  mode,
  creditsPaid,
  currentCredits,
  surveyLicences,
  accountIsLoading,
  scheduleStatus,
  surveyIsLoading,
  creditsToPurchase,
  setCredits,
  template,
  total,
  vatPrice
}) => {
  useEffect(() => {
    setCredits(calcCreditsNeededToPurchase(mode, creditsPaid, currentCredits))
  }, [mode, creditsPaid, currentCredits, setCredits])

  if (accountIsLoading || scheduleStatus === "scheduling" || surveyIsLoading)
    return (
      <LoadingState
        message={generateLoadingText(
          accountIsLoading,
          scheduleStatus,
          surveyIsLoading
        )}
      />
    )

  return (
    <Card mt={5} width="90%">
      <Flex mb={4} flexDirection={["column", "row"]} width="100%">
        <H2
          mr="auto"
          fontWeight="400"
          fontFamily="sansSerif"
          fontSize={5}
          mb={[2, 0]}
        >
          Order summary
        </H2>
        <Flex borderRadius={5} width="max-content" bg="primary.2" p={2}>
          <Text fontSize={[3, 4, 5]} textAlign="right">
            1 participant = 1 credit = £{CREDIT_PRICE} + VAT
          </Text>
        </Flex>
      </Flex>
      <Flex width="100%">
        <Text fontWeight="700">Credits</Text>
        <Text textAlign="right" fontWeight="700">
          Item
        </Text>
      </Flex>
      <Seperator lineColor="dark.3" my={4} width={1 / 1} />
      {mode.mode === "uniqueSubmission" ? (
        <Flex width="100%">
          <Text mb={2}>{mode.recipients.length} email participants</Text>
          <RightText mb={2}>{mode.recipients.length} credits</RightText>
        </Flex>
      ) : (
        <Flex width="100%">
          <Text mb={2}>{mode.amount} shareable links participants</Text>
          <RightText mb={2}>{mode.amount} credits</RightText>
        </Flex>
      )}
      <Flex width="100%">
        <Text>Credits already used on this survey</Text>
        <RightText>{creditsPaid} credits</RightText>
      </Flex>
      <Seperator lineColor="dark.3" my={4} width={1 / 1} />
      <Flex flexDirection="row" width="100%">
        <Text>You currently have</Text>
        <RightText>
          <b>{currentCredits} credits</b>
        </RightText>
      </Flex>
      <Seperator lineColor="dark.3" my={4} width={1 / 1} />
      <Flex width="100%">
        <Text mb={2}>Credits to purchase</Text>
        <Flex flexDirection="column" mb={2}>
          <RightText>
            {creditsToPurchase >= 0 ? creditsToPurchase : 0} credits
          </RightText>
          <RightText>
            (£
            {roundTo2DecimalPlaces(creditsToPurchase * CREDIT_PRICE) >= 0
              ? roundTo2DecimalPlaces(creditsToPurchase * CREDIT_PRICE)
              : 0}
            )
          </RightText>
        </Flex>
      </Flex>
      {!hasCorrectSurveyLicence(template, surveyLicences) && (
        <Flex width="100%">
          <Text mb={2}>Annual Standard Survey Licence</Text>
          <RightText mb={2}>£{STANDARD_SURVEY_LICENCE_PRICE}</RightText>
        </Flex>
      )}
      <Flex width="100%">
        <Text>VAT ({VAT_PERCENT * 100}%)</Text>
        <RightText>
          £
          {roundTo2DecimalPlaces(vatPrice) >= 0
            ? roundTo2DecimalPlaces(vatPrice)
            : 0}
        </RightText>
      </Flex>
      <Flex mt={4} p={2} borderRadius={5} bg="primary.2" width="100%">
        <Text fontSize={5}>Total to pay</Text>
        <RightText fontSize={5}>£{total >= 0 ? total.toFixed(2) : 0}</RightText>
      </Flex>
    </Card>
  )
}

export default OrderSummary
