import React from "react"
import { useSelector } from "react-redux"
import { OrgGuard } from "../../features/account"
import EBNLoader from "../../features/EBN/containers/EBNLoader"
import ContentView from "../../features/EBN/views/Content"
import { UISelectors } from "../../features/ui"
import DetailsLayout from "../../layouts/DetailsLayout"

const Content = () => {
  const previousRoute = useSelector(UISelectors.getPreviousRoute)
  return (
    <OrgGuard>
      <DetailsLayout bg="#EEEEEE" px={3} backTo={previousRoute}>
        <EBNLoader />
        <ContentView />
      </DetailsLayout>
    </OrgGuard>
  )
}
export default Content
