import { Button } from "@engaging-tech/components"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import GET_SURVEY_LAYOUT from "../../../graphql/queries/getSurveyLayout"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { getReportSurveyId } from "../store/report.selectors"
import ConfigureLayoutModal from "./ConfigureLayoutModal"

const ConfigureLayout = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const layoutParent = searchParams.get("tag")
  const surveyId = useSelector(getReportSurveyId)

  const [openModal, setOpenModal] = useState(false)

  const { data, isLoading, errors } = useBusinessAppQuery(GET_SURVEY_LAYOUT, {
    variables: {
      id: surveyId,
      parent: layoutParent
    }
  })
  return (
    <>
      {data?.getSurveyLayout?.length > 0 ? (
        <>
          <Button
            variant="outline.secondary.0"
            minWidth={[175, 200]}
            color="secondary.0"
            leadingIcon="compass"
            fontSize={[2, 3]}
            m="6px"
            elevation={0}
            px={3}
            fontWeight={500}
            onClick={() => setOpenModal(true)}
          >
            Configure Layout
          </Button>
          {openModal && (
            <ConfigureLayoutModal
              setOpenModal={setOpenModal}
              data={data}
              isLoading={isLoading}
              errors={errors}
              surveyId={surveyId}
              layoutParent={layoutParent}
            />
          )}
        </>
      ) : null}
    </>
  )
}

export default ConfigureLayout
