/* eslint-disable no-nested-ternary */
import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const ButtonText = styled.button`
  width: fit-content;
  text-decoration: underline;
  border: none;
  background: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.dark[3] : theme.colors.secondary[0]};
  margin: ${({ m }) => m};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
`

const EditStepButtons = ({ closeModal }) => {
  return (
    <Flex width={1 / 1} height="50px" justifyContent="space-between" mt="7px">
      <ButtonText onClick={() => closeModal(false)} m="0px 30px">
        Cancel
      </ButtonText>
      <ButtonText onClick={() => closeModal(false)} m="0px 30px">
        Done
      </ButtonText>
    </Flex>
  )
}

const StepButtons = ({
  openInput,
  localText,
  selectedStep,
  errorMsg,
  handleBack,
  unselect,
  closeModal,
  handleClick,
  openStepEdit
}) => {
  if (openStepEdit) return <EditStepButtons closeModal={closeModal} />

  return (
    <Flex
      width="350px"
      height="67px"
      justifyContent="space-around"
      alignItems="center"
    >
      <ButtonText
        onClick={openInput ? handleBack : selectedStep ? unselect : closeModal}
        color="secondary.0"
      >
        {openInput ? "Back" : selectedStep ? "Unselect" : "Cancel"}
      </ButtonText>
      <ButtonText
        onClick={handleClick}
        disabled={(openInput && !localText) || errorMsg}
      >
        {openInput ? "Save" : selectedStep ? "Select" : "Add a Step"}
      </ButtonText>
    </Flex>
  )
}

export default StepButtons
