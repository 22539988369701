import React from "react"
import { Box, Paragraph, Flex, List } from "@engaging-tech/components"

const PlanItem = ({ planPoint }) => {
  return (
    <Flex width={1 / 1} flexDirection="column">
      {planPoint.subtitle && (
        <Paragraph fontWeight={500}>{planPoint.subtitle}</Paragraph>
      )}
      {planPoint.explanation?.length &&
        planPoint.explanation.map(paragraph => (
          <Paragraph key={paragraph} color="dark.2">
            {paragraph}
          </Paragraph>
        ))}
      {planPoint.bulletPoints?.length && (
        <Box bg="#E0FCFF" mb={5} pt={3} pb={3}>
          {planPoint.bulletPoints.map(bulletPoint => (
            <Box key={bulletPoint.text}>
              <Paragraph
                width={1 / 1}
                px={4}
                color="dark.1"
                mb={bulletPoint.children ? 1 : 3}
              >
                {bulletPoint.text}
              </Paragraph>
              <List.Wrapper spacing="4px" mt={0}>
                {bulletPoint.children &&
                  bulletPoint.children.map(child => (
                    <List.Item key={child} ml={5}>
                      {child}
                    </List.Item>
                  ))}
              </List.Wrapper>
            </Box>
          ))}
        </Box>
      )}
    </Flex>
  )
}

export default PlanItem
