import { call, put, takeLatest } from "redux-saga/effects"

import { deleteTypeNames } from "../../../../utils/index"
import { load } from "../services/survey-view-mode.service"
import * as Actions from "./survey-view-mode.actions"

const generateSurveyRes = survey => {
  const surveyObj = { ...survey }
  if (surveyObj?.meta?.mode?.shareableLinks?.enabled) {
    surveyObj.meta.mode = {
      mode: "shareableLinks",
      amount: survey.meta.mode.shareableLinks.amount,
      recipients: []
    }
  } else if (survey?.meta?.mode?.uniqueSubmission?.enabled) {
    surveyObj.meta.mode = {
      mode: "uniqueSubmission",
      amount: 5,
      recipients: survey.meta.mode.uniqueSubmission.recipients
    }
  }
  surveyObj.meta.startDate = new Date(survey?.meta?.startDate)
  surveyObj.meta.endDate = new Date(survey?.meta?.endDate)
  return surveyObj
}

export function* loadSaga(action) {
  try {
    let res = yield call(load, action.payload)
    res = deleteTypeNames(res)
    res = generateSurveyRes(res.data.getOrganisationSurvey)
    yield put(Actions.loadSurveySuccess(res))
  } catch {
    yield put(Actions.loadSurveyFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_VIEW_SURVEY, loadSaga)
}
