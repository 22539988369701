import { Box, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const ConsiderText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space[1]}px;
  color: ${({ theme }) => theme.colors.dark[2]}px;
  width: 100%;
  display: block;
`

export default function ConsiderBox({ considerWording, paddingY = "4" }) {
  return (
    <Box bg="secondary.4" py={paddingY} px={3} my={3} borderRadius={4}>
      <ConsiderText fontSize={[3, 2]}>PLEASE CONSIDER:</ConsiderText>
      {considerWording.map(sentence => (
        <ConsiderText fontSize={3} key={sentence}>
          {sentence}
        </ConsiderText>
      ))}
    </Box>
  )
}
