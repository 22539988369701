import React from "react"
import { Button, Spinner } from "@engaging-tech/components"

// a button that accepts onclick to execute a required async functionality and display a loading
const LoadingButton = props => {
  const { label, onClick, isLoading = false } = props
  return (
    <Button bg="secondary.0" color="light.0" onClick={onClick} {...props}>
      {isLoading ? <Spinner color="white" size={30} /> : label}
    </Button>
  )
}

export default LoadingButton
