/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-dynamic-require */
import React from "react"
import { Flex, Box, H1 } from "@engaging-tech/components"
import { Item } from "@engaging-tech/components/dist/lib/List/List"
import styled from "styled-components"

const UList = styled.ul`
  margin: 5px 0px;
  padding-left: 20px;
`

const header = [
  "You cannot skip to a question that is in another section. Only questions within the section can be skipped.",
  "You will not be able to skip over required questions (highlighted in red).  All Core questions are required by default. You can adjust whenether a custom question is required.",
  "You can skip to the begining of another section, but any red questions in between will not be skippable",
  "If you choose to jump back to any previous question, you will be asked to answer all the questions in between again, potentialy causing a loop."
]

const BranchingHeader = () => {
  return (
    <Flex flexDirection="row" alignItems="center" width="100%">
      <Box py={3}>
        <H1 fontSize={5} color="dark" fontWeight="700">
          Branching Options
        </H1>
        <Box pt={1}>
          <UList>
            {header.map(item => (
              <Item key={item}>{item}</Item>
            ))}
          </UList>
        </Box>
      </Box>
    </Flex>
  )
}
export default BranchingHeader
