import { CustomHooks, Paragraph } from "@engaging-tech/components"
import React from "react"

import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import TemplateOptionsWrapper from "../components/TemplateOptionsWrapper"
import Title from "../components/Title"
import Nav from "../containers/Nav"

const TitleAndTemplate = ({ values, errors, handleChange, ...props }) => {
  CustomHooks.useFreshChatDeactivate(true)

  return (
    <CreationFormsLayout bg="#EEF4F9" maxWidth="1000px" mx={20} mb={7} BottomNav={() => <Nav {...props} />}>
      <Paragraph mt={5} fontSize={[4, 5]}>
        Time to set up your survey! First, choose a survey title and write a survey introduction.
      </Paragraph>
      <Paragraph fontSize={[4, 5]}>
        WorkL tip: Keep your introduction short and snappy and highlight that the survey is anonymous!
      </Paragraph>
      <Paragraph fontSize={[4, 5]}>Please remember to use the navigational buttons provided</Paragraph>
      <Title mb={5} values={values} errors={errors} {...props} />
      <TemplateOptionsWrapper values={values} errors={errors} handleChange={handleChange} {...props} />
    </CreationFormsLayout>
  )
}

export default TitleAndTemplate
