import { CircularProgress, Paragraph, Text } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { format } from "date-fns"
import React from "react"

import LinkButton from "../../../ui/components/LinkButton"
import ReportCard from "../../components/ReportCard"
import { ScoreLabel, getLabelData } from "../../components/ScoreLabel"
import StatisticBox from "../../components/StatisticBox"

const getWord = (reportTypename, starterTypename, exitTypename) => {
  if (reportTypename === starterTypename) {
    return "New Starter"
  }
  if (reportTypename === exitTypename) {
    return "Exit"
  }
  return "Engagement"
}

const DataHighlights = ({
  score,
  industry,
  surveyResponseRate,
  surveyResponseSize,
  heading,
  hasLink,
  exportingPDF,
  reportTypename,
  surveyStartDate,
  tag
}) => {
  const paths = usePaths()
  const { id, surveyType } = useParams()
  const industryDifference = industry ? score - industry : null
  const { starterReportTypename, exitReportTypename } = getConfig().other
  const upgradedReport = new URLSearchParams(window.location.search).get(
    "upgraded"
  )

  return (
    <>
      <ReportCard
        heading={heading}
        exportingPDF={exportingPDF}
        width={["100%", "100%", "60%"]}
        showUpgradeLabel={upgradedReport}
      >
        <CircularProgress
          percentage={score}
          disableAllAnimation={exportingPDF}
          width="70%"
          maxWidth={200}
          strokeColor={getLabelData(score).color}
        />
        {!score && score !== 0 && (
          <Text textAlign="center">No data for this step</Text>
        )}
        <Paragraph
          textAlign="center"
          fontSize={3}
          fontWeight="500"
          color="dark.2"
          mb={3}
          mt={3}
        >
          Your Average{" "}
          {getWord(reportTypename, starterReportTypename, exitReportTypename)}{" "}
          Score
        </Paragraph>
        <ScoreLabel showIcon score={score} />
        <StatisticBox
          comparedToIndustry={industryDifference}
          surveyResponseRate={surveyResponseRate}
          width={1 / 1}
          surveyResponseSize={surveyResponseSize}
          reportTypename={reportTypename}
        />
        {reportTypename === starterReportTypename ||
          (reportTypename === exitReportTypename && (
            <Text>
              *Overall score from survey results collected since survey started
              on {format(new Date(surveyStartDate), "dd/MM/yyyy")}
            </Text>
          ))}
        {hasLink && !exportingPDF && (
          <LinkButton
            variant="text.primary.0"
            color="primary.0"
            text="Discover"
            path={`${paths.reports.summary.dataHighlightsPage
              .replace(":surveyType", surveyType)
              .replace(":id", id)}?tag=${tag}`}
          />
        )}
      </ReportCard>
    </>
  )
}

export default DataHighlights
