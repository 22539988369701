import React from "react"
import { Flex, Box, Icon, Text } from "@engaging-tech/components"
import Button from "@engaging-tech/components/dist/lib/Button/Button"

const pageReady = false

const ExplanationBox = ({ section, explanation }) => (
  <Box bg="dark.7" p={3} borderRadius={4} width={7 / 10}>
    <Flex alignItems="center" mb={3} width={1 / 1}>
      <Icon name="sentiment_satisfied" mr={3} />
      <Text fontWeight={500}>{section}</Text>
    </Flex>
    <Text fontSize={3} color="dark.2">
      {explanation}
    </Text>
    {pageReady && (
      <Flex justifyContent="flex-end" mt={3} mx={0} width={1 / 1}>
        <Button elevation={0} bg="transparent">
          <Text color="#FF6F00" fontWeight={500} fontSize={3}>
            Read More
          </Text>
        </Button>
      </Flex>
    )}
  </Box>
)

export default ExplanationBox
