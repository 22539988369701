import React, { useState, useEffect } from "react"
import { Flex, MotionFlex, Icon, CustomHooks } from "@engaging-tech/components"

import TemplateAnimatedIllustration from "../stepOne/TemplateAnimatedIllustration"

const TemplateAnimatedWrapper = ({
  width,
  height,
  visibleSequenceStart,
  moveSequenceStart,
  currentSequenceNumber,
  hideTemplate
}) => {
  if (hideTemplate) {
    return <></>
  }
  const moveAmount = -Math.abs(width + 25)
  const variants = {
    hidden: {
      opacity: 0,
      x: moveAmount,
      transition: { x: { duration: 0.01, delay: 0.3 } }
    },
    visible: { opacity: 1, x: moveAmount, transition: { duration: 0.3 } },
    moved: { opacity: 1, x: 0, transition: { duration: 0.6 } }
  }
  return (
    <MotionFlex
      width={width}
      height={width}
      key={1}
      alignItems="flex-start"
      justifyContent="center"
      variants={variants}
      initial="hidden"
      animate={(() => {
        if (currentSequenceNumber >= moveSequenceStart) {
          return "moved"
        }

        return currentSequenceNumber >= visibleSequenceStart
          ? "visible"
          : "hidden"
      })()}
    >
      <TemplateAnimatedIllustration width={width} height={height} noAnimation />
    </MotionFlex>
  )
}

const TitleTextLine = ({ width, height, index }) => {
  return (
    <MotionFlex
      bg="#e6e6e6"
      height={height}
      borderRadius={height / 2}
      variants={{
        unActive: {
          width: "0%"
        },
        active: {
          width,
          transition: {
            delay: 0.2 * index
          }
        }
      }}
    />
  )
}

const TemplateTitle = ({
  sequenceStart,
  currentSequenceNumber,
  height,
  ...props
}) => {
  return (
    <MotionFlex
      bg="#909090"
      width="100%"
      height={height}
      mt={10}
      borderRadius={2}
      initial={{ opacity: 0 }}
      animate={currentSequenceNumber >= sequenceStart ? "active" : "unActive"}
      variants={{
        unActive: {
          opacity: 0
        },
        active: {
          opacity: 1
        }
      }}
      flexDirection="column"
      justifyContent="space-evenly"
      pl={2}
      exit={{ opacity: 0 }}
      {...props}
    >
      <TitleTextLine width="70%" index={1} height={height / 6} />
      <TitleTextLine width="50%" index={2} height={height / 6} />
    </MotionFlex>
  )
}

const TemplateEmptySpace = ({
  sequenceStart,
  sequenceEnd,
  moveSequenceStart,
  currentSequenceNumber,
  height,
  width,
  hideTemplate,
  ...props
}) => {
  const variants = {
    hidden: {
      border: "2px dashed rgba(23 60 90 / 100%)",
      opacity: 0,
      transition: { border: { delay: 0.3 } }
    },
    visible: {
      border: "2px dashed rgba(23 60 90 / 100%)",
      opacity: 1
    },
    exit: { border: "2px dashed rgba(23 60 90 / 0%)", opacity: 1 }
  }
  return (
    <MotionFlex
      width={width}
      height={height}
      mt={10}
      variants={variants}
      initial="hidden"
      animate={(() => {
        if (currentSequenceNumber >= sequenceEnd) {
          return "exit"
        }
        return currentSequenceNumber >= sequenceStart ? "visible" : "hidden"
      })()}
      borderRadius={4}
      {...props}
    >
      <TemplateAnimatedWrapper
        currentSequenceNumber={currentSequenceNumber}
        moveSequenceStart={moveSequenceStart}
        visibleSequenceStart={moveSequenceStart - 2}
        width={width - 4}
        height={height - 4}
        hideTemplate={hideTemplate}
      />
    </MotionFlex>
  )
}

const SurveyDateAndLogo = ({
  sequenceStart,
  sequenceEnd,
  currentSequenceNumber,
  height,
  width,
  ...props
}) => {
  const variants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    },
    exit: { opacity: 1 }
  }
  const iconSize = 20
  return (
    <MotionFlex
      width="100%"
      height={height}
      mt={10}
      variants={variants}
      initial="hidden"
      animate={(() => {
        if (currentSequenceNumber >= sequenceEnd) {
          return "exit"
        }
        return currentSequenceNumber >= sequenceStart ? "visible" : "hidden"
      })()}
      borderRadius={4}
      bg="#909090"
      alignItems="center"
      {...props}
    >
      <Flex justifyContent="space-between" px="10%">
        <MotionFlex
          width={iconSize}
          height={iconSize}
          variants={{
            hidden: {
              opacity: 0
            },
            visible: {
              opacity: 1,
              transition: { delay: 0.1 }
            },
            exit: { opacity: 1 }
          }}
        >
          <Icon name="date_range" size={iconSize} color="#e6e6e6" />
        </MotionFlex>
        <MotionFlex
          width={iconSize}
          height={iconSize}
          variants={{
            hidden: {
              opacity: 0
            },
            visible: {
              opacity: 1,
              transition: { delay: 0.2 }
            },
            exit: { opacity: 1 }
          }}
        >
          <Icon name="photo" size={iconSize} color="#e6e6e6" />
        </MotionFlex>
        <MotionFlex
          width={iconSize}
          height={iconSize}
          variants={{
            hidden: {
              opacity: 0
            },
            visible: {
              opacity: 1,
              transition: { delay: 0.3 }
            },
            exit: { opacity: 1 }
          }}
        >
          <Icon name="person_add" size={iconSize} color="#e6e6e6" />
        </MotionFlex>
      </Flex>
    </MotionFlex>
  )
}

let localSequenceCount = 1

const SurveyAnimatedIllustration = ({
  width = 220,
  height = 290,
  cycle = true,
  speed = 600,
  noAnimation = false,
  forceSequenceCount = null,
  hideTemplate = false,
  ...props
}) => {
  const device = CustomHooks.useClientDevice()

  const [sequenceNumber, setSequenceNumber] = useState(
    (() => {
      if (forceSequenceCount) {
        return forceSequenceCount
      }
      return noAnimation ? 10 : localSequenceCount
    })()
  )

  const updateSequence = () => {
    if (localSequenceCount === 10) {
      if (cycle) {
        setSequenceNumber(0)
        localSequenceCount = 0
      }
    } else {
      setSequenceNumber(localSequenceCount + 1)
      localSequenceCount += 1
    }
  }

  useEffect(() => {
    let isCancelled = false
    let interval = null
    const runAsync = async () => {
      if (!isCancelled && !noAnimation) {
        interval = setInterval(updateSequence, speed)
      }
    }

    runAsync()

    return () => {
      isCancelled = true
      if (interval) {
        clearInterval(interval)
      }
      localSequenceCount = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveAmount = noAnimation ? 0 : width / (device === "MOBILE" ? 3 : 2)
  const variants = {
    preMove: {
      x: moveAmount,
      transition: { duration: 0.6 }
    },
    moved: { x: 0, transition: { duration: 0.6 } }
  }
  const moveSequenceStart = 6
  const titleHeight = (height / 100) * 17
  const dateAndLogoHeight = (height / 100) * 14
  return (
    <MotionFlex
      width={width}
      height={height}
      key={1}
      alignItems="flex-start"
      p={2}
      bg="#bfbfbf"
      borderRadius={4}
      variants={variants}
      initial="preMove"
      animate={sequenceNumber >= moveSequenceStart ? "moved" : "preMove"}
      flexDirection="column"
      {...props}
    >
      <TemplateTitle
        mt={1}
        height={titleHeight}
        sequenceStart={2}
        currentSequenceNumber={sequenceNumber}
      />
      <SurveyDateAndLogo
        height={dateAndLogoHeight}
        sequenceStart={3}
        sequenceEnd={8}
        currentSequenceNumber={sequenceNumber}
      />
      <TemplateEmptySpace
        height={height - titleHeight - dateAndLogoHeight - 40}
        width={width - 50}
        sequenceStart={4}
        sequenceEnd={8}
        hideTemplate={hideTemplate}
        moveSequenceStart={moveSequenceStart}
        currentSequenceNumber={sequenceNumber}
      />
    </MotionFlex>
  )
}

export default SurveyAnimatedIllustration
