import { Button, Flex } from "@engaging-tech/components"
import React from "react"

export default function SaveAndCancel({ cancelDisable, handleSave, saveDisable, ...props }) {
  return (
    <Flex flexDirection="row" {...props}>
      <Button variant="text.dark.2" elevation={0} fontSize={4} disabled={cancelDisable} type="button">
        Cancel
      </Button>
      <Button
        variant="text.primary.0"
        elevation={0}
        fontSize={4}
        onClick={handleSave}
        disabled={saveDisable}
        type="button"
      >
        Add
      </Button>
    </Flex>
  )
}
