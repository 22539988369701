/* eslint-disable no-useless-escape */
import * as yup from "yup"
import currenciesList from "../../utils/currenciesList"
import languagesList from "../../utils/languagesList"

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  businessName: "",
  industry: "5b043ab9e6cc99c18616a573",
  country: "5abb593b5779598af1079569",
  website: "",
  phone: "",
  allowMarketing: false,
  acceptConditions: true,
  language: languagesList[0].code,
  countryId: "",
  timezone: "Europe/London",
  currency: currenciesList[0].code
}
const phoneRegExp = /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{1,3}[-\s\.]?[0-9]{1,7}$/

export const validationSchema = yup.object().shape({
  firstName: yup
    .string("Please enter your first name")
    .required("A first name is required"),
  lastName: yup
    .string("Please enter your surname")
    .required("A surname is required"),
  email: yup
    .string()
    .label("Email")
    .email()
    .required(),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8, "At least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm Password")
    .test("passwords-match", "Passwords must match", function(value) {
      return this.parent.password === value
    }),
  businessName: yup
    .string("Please enter the name of your Business")
    .required("A name for your business is required."),
  industry: yup
    .string("Please select an industry from the list")
    .required("An industry for your business must be selected"),
  country: yup
    .string("Please select an country from the list")
    .required("A Country must be selected"),
  website: yup.string().url("URL is not valid"),
  phone: yup
    .string()
    .matches(
      phoneRegExp,
      "Phone number is invalid, it should not contain any spaces"
    ),
  allowMarketing: yup.boolean(),
  acceptConditions: yup
    .boolean()
    .oneOf([true], "Terms and conditions must be accepted")
    .required(),
  language: yup
    .string("Please select a Language from the list")
    .required("A Language must be selected"),
  timezone: yup
    .string("Please select a Timezone from the list")
    .required("A Timezone must be selected")
  // currency: yup
  //   .string("Please select a Currency from the list")
  //   .required("A Currency must be selected")
})
