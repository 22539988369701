import React from "react"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"

import PageLayout from "../layouts/PageLayout"

const DownForMaintenance = () => {
  const router = useRouter()
  const { downForMaintenance } = getConfig()
  if (!downForMaintenance) {
    router.navigate("/")
  }
  return <PageLayout />
}

export default DownForMaintenance
