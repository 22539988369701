import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import styled from "styled-components"

const bannerPlaceholderImage = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/no-image-rectangle-icon.svg`)

const ImageCustom = styled.img`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
`

export default function BannerImage({ imageUrl, imageUploadIsLoading }) {
  const config = getConfig()

  if (
    imageUrl &&
    typeof imageUrl === "string" &&
    imageUrl?.includes("data:image")
  ) {
    return (
      <ImageCustom
        width="120px"
        height="70px"
        src={imageUrl}
        alt="Banner"
        disabled={imageUploadIsLoading}
      />
    )
  } else if (imageUrl) {
    return (
      <ImageCustom
        width="120px"
        height="70px"
        src={`${config.awsImageEndpoint}${imageUrl}`}
        alt="Banner"
        disabled={imageUploadIsLoading}
      />
    )
  }

  return (
    <ImageCustom
      width="120px"
      height="70px"
      src={bannerPlaceholderImage}
      alt="Banner Placeholder"
    />
  )
}
