import React, { useEffect } from "react"
import { Flex, LineChart, Spinner, Text } from "@engaging-tech/components"
import dateSort from "../../../utils/dateSort"
import buildDataset from "../lib/buildDataset"
import formatLabel from "../lib/formatLabel"
import lineGraphColors from "../lib/lineGraphColors"
import ComparisonOptionsContainer from "../containers/ComparisonOptionsContainer"

const ComparisonView = ({
  surveyComparisonData,
  dataset,
  setDataset,
  handleGetComparisonData,
  isLoading,
  hasError
}) => {
  useEffect(() => {
    if (surveyComparisonData.length > 0 && !hasError) {
      const newDataset = { datasets: [] }
      const allDatasets = {
        borderColor: "white",
        pointBackgroundColor: "white",
        label: "",
        data: []
      }

      const availableSections = buildDataset(
        surveyComparisonData,
        allDatasets,
        newDataset
      )

      availableSections.forEach((section, idx) => {
        const sectionIndex = newDataset.datasets.findIndex(
          item => item.label === section
        )

        // eslint-disable-next-line no-param-reassign
        if (idx === lineGraphColors.length) idx -= lineGraphColors.length
        newDataset.datasets[sectionIndex] = {
          borderColor: lineGraphColors[idx],
          pointBackgroundColor: lineGraphColors[idx],
          borderWidth: 1,
          pointRadius: 3,
          ...newDataset.datasets[sectionIndex]
        }

        newDataset.datasets[sectionIndex].data = dateSort(
          newDataset.datasets[sectionIndex].data
        )

        newDataset.datasets[sectionIndex].label = formatLabel(
          newDataset.datasets[sectionIndex].label
        )
      })

      dateSort(allDatasets.data)

      newDataset.datasets.unshift(allDatasets)
      setDataset(newDataset)
    } else {
      setDataset([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyComparisonData, hasError])

  return (
    <Flex
      height={["auto", "80vh"]}
      flexWrap="wrap"
      width={1 / 1}
      flexDirection="row"
    >
      <Flex
        width={[1 / 1, 4 / 5]}
        height={["80vh", "80%"]}
        justifyContent="center"
      >
        {isLoading && <Spinner color="primary.0" />}
        {!isLoading && !!dataset?.datasets?.length && !hasError && (
          <LineChart dataset={dataset} customTooltip />
        )}
        {!isLoading && !dataset?.datasets?.length && (
          <Text width={1 / 1} textAlign="center">
            {hasError.message ? hasError.message : hasError || "No data found"}
          </Text>
        )}
      </Flex>
      <ComparisonOptionsContainer
        handleGetComparisonData={handleGetComparisonData}
      />
    </Flex>
  )
}

export default ComparisonView
