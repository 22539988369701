import React from "react"

import { OrgGuard } from "../../features/account"
import MainHeader from "../../features/actionPlans/components/MainHeader"
import ViewActionPlanContainer from "../../features/actionPlans/containers/ViewActionPlanContainer"
import DashboardLayout from "../../layouts/DashboardLayout"

const ActionPlanView = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <MainHeader />
        <ViewActionPlanContainer />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default ActionPlanView
