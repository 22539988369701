/* eslint-disable react/jsx-curly-newline */
import { Flex, Paragraph } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import {
  logo,
  secondaryLogo,
  title,
  titleContent,
  type
} from "../components/HappiestWorkplace/data.json"
import AwardBox from "./AwardBox"

const Grid = styled(Flex)`
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  padding: 16px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    & > div {
      width: calc(45% - 8px);
      margin: 8px 8px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    & > div {
      width: calc(32% - 8px);
      margin: 8px 8px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[2]}px) {
    & > div {
      width: calc(33% - 8px);
      margin: 8px 8px;
    }
  }
`

const ChooseAward = ({ allAwards }) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Flex
        width="auto"
        flexDirection="column"
        justifyContent="center"
        maxWidth="1280px"
      >
        <Grid justifyContent="center" width={[]}>
          <AwardBox
            secondaryLogo={secondaryLogo}
            title={title}
            type={type}
            flag={logo}
            padding={5}
            text={[
              `<b>${title},</b> ${titleContent[0]}`,
              ...titleContent.slice(1)
            ]}
          />
          {allAwards.map(award => (
            <AwardBox
              key={award.id}
              secondaryLogo={award.secondaryLogo}
              title={award.title}
              type={award.type}
              flag={award.flag}
              text={[
                `<b>${award.title}, </b> ${award.titleContent[0]}`,
                ...award.titleContent.slice(1)
              ]}
              padding={3}
            />
          ))}
        </Grid>
      </Flex>
    </Flex>
  )
}

export default ChooseAward
