/* eslint-disable camelcase */

/* eslint-disable react/jsx-curly-newline */
import { Box, Button, Flex, Paragraph } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import CircleIcon from "../../../ui/components/CircleIcon"

const BorderedFlex = styled(Flex)`
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    height: -webkit-fill-available;
    height: -moz-available;
    height: stretch;
    border-radius: 10px;
    border: ${({ isBordered, theme }) =>
      isBordered && `4px solid ${theme.colors.primary[0]}`};
  }
`

const ProductCard = ({
  price,
  highlightedCard,
  handleClick,
  objectType,
  showProductName
}) => {
  return (
    <BorderedFlex
      flexDirection="column"
      padding="10px 16px"
      bg="secondary.0"
      borderRadius="10px"
      position="relative"
      isBordered={highlightedCard === price?.id}
      justifyContent="space-between"
    >
      <Flex>
        <CircleIcon
          name="store"
          bg="primary.0"
          iconColor="light.0"
          mr={2}
          size={40}
        />
        <Flex flex={3} flexDirection="column">
          <Paragraph
            color="light.0"
            width="fit-content"
            mb={1}
            fontSize="16px"
            fontWeight={600}
          >
            {price?.name}
          </Paragraph>
          <Paragraph
            color="light.0"
            width="fit-content"
            fontWeight="300"
            fontSize={2}
            mb={1}
          >
            {`${price?.options.description}`}
          </Paragraph>
        </Flex>
      </Flex>
      {showProductName ? (
        <Flex justifyContent="center" style={{ whiteSpace: "nowrap" }}>
          <Paragraph
            color="light.0"
            width="fit-content"
            mt={3}
            fontSize={4}
            fontWeight={"700"}
            textAlign="center"
          >
            {price?.product_details?.name}
          </Paragraph>
        </Flex>
      ) : (
        <Box height={"40px"} />
      )}
      <Flex justifyContent="space-between" alignItems="center" mt={[3, 4]}>
        <Box mr={4}>
          <Paragraph
            color="light.0"
            width="fit-content"
            mb="4px"
            fontSize={4}
            fontWeight={"700"}
          >
            {new Intl.NumberFormat("en-EN", {
              style: "currency",
              minimumFractionDigits: 0,
              currency: price?.currency || "GBP"
            }).format(price?.unit_amount)}
          </Paragraph>
        </Box>
        <Button
          bg="primary.0"
          color="light.0"
          fontSize={3}
          height="30px"
          style={{ overflow: "initial" }}
          onClick={() =>
            handleClick({
              productId: price?.product_details.id,
              priceId: price?.id,
              options: price?.options,
              object_type: objectType,
              name: price?.name
            })
          }
        >
          Select
        </Button>
      </Flex>
    </BorderedFlex>
  )
}

export default ProductCard
