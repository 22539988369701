import React from "react"
import styled from "styled-components"
import { Text, Flex, Button } from "@engaging-tech/components"

const Column = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary[5]};
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
`

const column1 = [
  {
    header: "Jobs",
    text:
      "Post unlimited jobs on the WorkL jobs Marketplace and get matched with candidates"
  },
  {
    header: "Business Information Page",
    text: "Promote your business through a dedicated page"
  },
  {
    header: "Coach Directory",
    text:
      "Create a coach profile page that will be listed on WorkL Coaching Directory - Coming Soon"
  },
  {
    header: "Monthly Newsletter",
    text: "Receive a monthly newsletter with our industry insights"
  },
  {
    header: "Members' Portal",
    text: "Connect, share and network with other members"
  },
  {
    header: "Research Packs",
    text: "Receive our engagement research packs for free (usually £50 each)"
  }
]

const column2 = [
  {
    header: "Lead Generation",
    text:
      "Receive notifications for ‘Ask an Expert’ questions in your areas of expertise"
  },
  {
    header: "Events",
    text:
      "Create business events that will be advertised on the WorkL Global Hub"
  },
  {
    header: "Workplace Happiness Summit",
    text: "Two tickets to our annual Workplace Happiness Summit"
  },
  {
    header: "Annual Lecture",
    text: "Two tickets to our annual Autumn lecture"
  },
  {
    header: "Exclusive Discount",
    text: "Receive 20% off everything in the WorkL Business Library"
  },
  {
    header: "Monthly Webinar",
    text: "Discussion on our latest research results and topical issues"
  }
]

const DetailPoint = ({ header, text }) => (
  <Flex flexDirection="column">
    <Text fontSize={2} fontWeight={500} mb={1} mt={2} color="dark.0">
      {header}
    </Text>
    <Text fontSize={2} color="dark.2" fontWeight={300} mb={2}>
      {text}
    </Text>
  </Flex>
)

const IndividualServiceText = () => (
  <>
    <Text
      display="block"
      textAlign="center"
      width={1}
      color="dark.0"
      fontWeight={800}
      fontSize={5}
      my={3}
    >
      Or join the WorkL Business Network
    </Text>
    <Text
      display="block"
      textAlign="center"
      width={1}
      color="dark.2"
      fontWeight={600}
      fontSize={4}
      mb={2}
    >
      £500 + VAT per year
    </Text>
  </>
)

const JoinOurNetworkDetail = ({
  handleCancel,
  handleAction,
  individualService
}) => (
  <>
    {individualService && <IndividualServiceText />}
    <Flex
      flexDirection={["column", "row"]}
      justifyContent={["center", "space-between"]}
      px={[0, 3]}
      alignItems={["start", "stretch"]}
      mt={2}
    >
      <Column>
        {column1.map(data => (
          <DetailPoint {...data} key={data.header} />
        ))}
      </Column>
      <Column>
        {column2.map(data => (
          <DetailPoint {...data} key={data.header} />
        ))}
      </Column>
    </Flex>
    <Flex justifyContent="space-between">
      <Button
        variant="light.0"
        onClick={handleCancel}
        elevation={0}
        ml={4}
        my={3}
      >
        Cancel
      </Button>

      <Button
        variant="secondary.0"
        width={[114, 180]}
        onClick={handleAction}
        mr={[2, 4]}
        my={3}
      >
        Join Now
      </Button>
    </Flex>
  </>
)

export default JoinOurNetworkDetail
