import React from "react"
import { Box, Text } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"

import { ConfirmBanner } from "../features/account"
import BackBar from "../features/ui/components/BackBar"
import Nav from "../features/ui/containers/Nav"
import Footer from "../features/ui/components/Footer"
import TestingSiteBanner from "../features/ui/components/TestingSiteBanner"

const DashboardLayout = ({
  title,
  promotionalBanner: Promotion,
  children,
  visible = false,
  text,
  to,
  BottomNav,
  ...props
}) => {
  const router = useRouter()
  const { downForMaintenance } = getConfig()
  if (downForMaintenance) {
    router.navigate("/")
  }
  return (
    <>
      <TestingSiteBanner />
      {Promotion && <Promotion />}
      <Nav />
      <ConfirmBanner />
      <BackBar visible={visible} text={text} to={to} />
      <Box
        as="main"
        py={[3, 4]}
        pl={["5px", "20px"]}
        pr={["17px", "30px"]}
        {...props}
      >
        <Box margin="0 auto" width={1 / 1} maxWidth={1280}>
          {title && (
            <Text
              fontSize={[5, 28]}
              fontWeight={700}
              as="h1"
              px={[3, 3, 4]}
              mb={[3, 3, 4]}
            >
              {title}
            </Text>
          )}
          {children}
        </Box>
      </Box>
      <Footer />
      {BottomNav && <BottomNav />}
    </>
  )
}

export default DashboardLayout
