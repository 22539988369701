import { connect } from "react-redux"

import {
  addLabel,
  addQuestion,
  deleteAnswer,
  removeQuestion,
  setFormErrors,
  setQuestionAnswer,
  setQuestionTitle,
  swapQuestions
} from "../../../../store/actionPlans/actionPlans.actions"
import ActionPlanQuestionsBody from "../../components/EditActionPlan/ActionPlanQuestionsBody"

const mapState = state => ({})

const mapDispatch = dispatch => ({
  setQuestionTitle: (title, id, stepQuestionId) =>
    dispatch(setQuestionTitle(title, id, stepQuestionId)),
  setQuestionAnswer: (answer, index, questionId, stepQuestionId) =>
    dispatch(setQuestionAnswer(answer, index, questionId, stepQuestionId)),
  deleteAnswer: (index, questionId, stepQuestionId) =>
    dispatch(deleteAnswer(index, questionId, stepQuestionId)),
  removeQuestion: (index, stepQuestionId) =>
    dispatch(removeQuestion(index, stepQuestionId)),
  swapQuestions: (aIndex, bIndex, stepQuestionId) =>
    dispatch(swapQuestions(aIndex, bIndex, stepQuestionId)),
  addQuestion: (index, stepQuestionId) =>
    dispatch(addQuestion(index, stepQuestionId)),
  addLabel: (questionId, stepQuestionId) =>
    dispatch(addLabel(questionId, stepQuestionId)),
  setFormErrors: (field, error) => dispatch(setFormErrors(field, error))
})

const ActionPlanQuestionsBodyContainer = connect(
  mapState,
  mapDispatch
)(ActionPlanQuestionsBody)

export default ActionPlanQuestionsBodyContainer
