import { Box, Text } from "@engaging-tech/components"
import React from "react"
import useDebouncedInput from "../../../../hooks/useDebouncedInput"
import { Card, TextField, TextArea } from "../../components"

const Title = ({ values, errors, setFieldValue, readOnly, ...props }) => {
  const [titleTextLocal, setTitleTextLocal] = useDebouncedInput(
    values.title,
    value => {
      setFieldValue("title", value)
    }
  )

  const [introductionTextLocal, setIntroductionTextLocal] = useDebouncedInput(
    values.introduction,
    value => {
      setFieldValue("introduction", value)
    }
  )

  return (
    <Card {...props}>
      {readOnly ? (
        <Text fontSize={5} fontWeight={700}>
          {values && values.title}
        </Text>
      ) : (
        <Box mb={2}>
          <TextField
            name="title"
            id="title"
            placeholder="Survey title (e.g. Engagement Survey, Staff Satisfaction Survey, Wellbeing Survey)"
            value={titleTextLocal}
            error={errors && errors.title}
            onChange={e => setTitleTextLocal(e.target.value)}
            placeholderStyles={{
              "font-weight": "bold"
            }}
            helperText={errors && errors.title}
            style={{
              fontWeight: 700
            }}
            fontSize={20}
          />
        </Box>
      )}
      {readOnly ? (
        <Text fontSize={4} style={{ whiteSpace: "break-spaces" }}>
          {values.introduction}
        </Text>
      ) : (
        <TextArea
          name="introduction"
          id="introduction"
          placeholder="Survey Introduction (e.g. Welcome to our employee engagement survey! Thanks for taking the time to fill it out and help us make our organisation a better place to work. The survey is anonymous and results are processed by WorkL)"
          style={{
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            minHeight: "86px",
            maxHeight: "360px"
          }}
          value={introductionTextLocal}
          error={errors && errors.introduction}
          onChange={e => setIntroductionTextLocal(e.target.value)}
          helperText={
            (errors && errors.introduction) ||
            "This will be seen by participants at the beginning of the survey"
          }
          fontSize={16}
        />
      )}
    </Card>
  )
}

export default Title
