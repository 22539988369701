import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import ThanksForJoining from "../components/ThanksForJoining"

const EBNSuccess = () => {
  const router = useRouter()
  const paths = usePaths()
  const handleGetStarted = () => router.navigate(paths.home.index)

  return <ThanksForJoining handleGetStarted={handleGetStarted} />
}

export default EBNSuccess
