/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable react/jsx-curly-newline */
import {
  Card,
  DatePicker2,
  Flex,
  H3,
  Paragraph,
  Text
} from "@engaging-tech/components"
import { addDays, set } from "date-fns"
import { useFormik } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"

const DateBox = ({
  label,
  newDate,
  handleChange,
  minDate,
  pageErrors,
  maxEndDate,
  type
}) => (
  <Flex flexDirection="column">
    <Text mb={1} color="secondary.0" fontWeight={600}>
      {label}
    </Text>
    <DatePicker2
      label={label}
      date={newDate}
      onDateChange={date => {
        handleChange(
          type,
          set(date, {
            hours: 1,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
          })
        )
      }}
      minDate={minDate}
      maxDate={maxEndDate}
      error={pageErrors}
      enableReinitialize
    />
  </Flex>
)

const DatePicker = ({
  startDate,
  endDate,
  setDate,
  handleErrors,
  introduction,
  maxEndDate
}) => {
  const minStartDate = addDays(new Date().setHours(1, 0, 0, 0), 1)
  const [minEndDate, setMinEndDate] = useState(
    set(startDate, { hours: 1, minutes: 0, seconds: 0, milliseconds: 0 })
  )

  const initialValues = useMemo(
    () => ({
      startDate,
      endDate,
      minEndDate,
      minStartDate
    }),
    [startDate, endDate, minEndDate, minStartDate]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      startDate: Yup.date()
        .min(minStartDate, "Start date cannot be earlier than tomorrow")
        .required("Cannot be empty"),
      endDate: Yup.date().required("Cannot be empty")
    }),
    validateOnMount: true,
    enableReinitialize: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setDate({ startDate: formik.values.startDate })
      const newDate = set(addDays(formik.values.startDate, 1), {
        hours: 1,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      })
      setMinEndDate(newDate)

      if (
        new Date(formik.values.startDate.toDateString()) >=
        new Date(endDate.toDateString())
      ) {
        setDate({ endDate: newDate })
        formik.setFieldValue("endDate", newDate)
      }
      handleErrors(formik.errors?.startDate, "startDate", endDate)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.startDate, formik.errors])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDate({ endDate: formik.values.endDate })
      handleErrors(formik.errors?.endDate, "endDate")
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.endDate, formik.errors])

  const getUnixDate = date => {
    if (typeof date === "number") return date
    return Math.floor(date?.getTime() / 1000)
  }

  const calcDayDifference = () =>
    Math.floor((getUnixDate(endDate) - getUnixDate(startDate)) / (24 * 60 * 60))

  return (
    <Card py={4} px={[2, 3]} mb={4}>
      <H3 fontSize={5} color="secondary.0" fontWeight={600}>
        When do you want your survey to start and finish?
      </H3>
      <Paragraph mt={3} color="secondary.0">
        {introduction}
      </Paragraph>
      <Flex flexDirection={["column", "row"]} alignItems="center">
        <DateBox
          label="Start Date"
          type="startDate"
          newDate={formik.values.startDate}
          minDate={minStartDate}
          handleChange={formik.setFieldValue}
          pageErrors={formik.errors?.startDate}
        />
        <DateBox
          label="End Date"
          type={endDate}
          newDate={formik.values.endDate}
          minDate={minEndDate}
          handleChange={formik.setFieldValue}
          pageErrors={formik.errors?.endDate}
          maxEndDate={maxEndDate}
        />
        <Text fontSize={4} color="secondary.0" fontWeight={600}>
          {`Number of days: ${calcDayDifference()}`}
        </Text>
      </Flex>
    </Card>
  )
}

export default DatePicker
