import React, { useState } from "react"
import { Flex, MotionFlex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import { withSize } from "react-sizeme"

import layoutConfig from "../lib/layoutConfig"

const getTextArray = step => {
  switch (step) {
    case 1: {
      return [
        "A survey template includes all the sections and questions you need to ask your participants."
      ]
    }
    case 2: {
      return [
        "Once you have created your survey template you will be able to set up your survey."
      ]
    }
    case 3: {
      return [
        "Once you are happy with your survey set up you will have to purchase a annual survey licence fee and credits for each participant that is taking the survey.",
        "The annual survey licence fee depends whether you are running a standard survey or bespoke survey.",
        "When this is purchased the survey will be able to go live."
      ]
    }
    default:
      return [""]
  }
}

const SizeAwarePanel = withSize({ monitorHeight: true, monitorWidth: false })(
  ({ selectedStep }) => {
    const textArray = getTextArray(selectedStep)
    return (
      <MotionFlex
        key={selectedStep}
        width={["100%", "100%", "calc(60% - 100px)"]}
        maxWidth={[1000, 1000, 800]}
        position="absolute"
        initial={{ x: 400, opacity: 0, transition: { duration: 0.4 } }}
        animate={{
          x: 0,
          opacity: 1,
          transition: { delay: 0.2, duration: 0.4 }
        }}
        exit={{ x: -400, opacity: 0, transition: { duration: 0.4 } }}
        flexDirection="column"
      >
        <Flex width="calc(100% - 60px)" flexDirection="column" pl={10}>
          {textArray.map(t => (
            <Text
              fontSize={layoutConfig.headerSubtitleTextSize}
              width="100%"
              mb={3}
            >
              {t}
            </Text>
          ))}
        </Flex>
      </MotionFlex>
    )
  }
)

const MainInformationPanel = ({ selectedStep }) => {
  const [size, setSize] = useState({ height: 200 })

  return (
    <Flex
      width={["100%", "100%", "50%"]}
      height={size.height}
      alignItems="flex-start"
    >
      <AnimatePresence>
        <SizeAwarePanel
          key={selectedStep}
          selectedStep={selectedStep}
          onSize={setSize}
        />
      </AnimatePresence>
    </Flex>
  )
}

export default MainInformationPanel
