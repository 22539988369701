import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const getWordCountQuestions = createSelector(
  ReportSelectors.getReportData,
  data =>
    data?.wordCounts?.map(wordCount => ({
      id: wordCount.questionId,
      value: wordCount.question
    }))
)

export const getWordCountForQuestion = createSelector(
  [ReportSelectors.getReportData, (_, questionId) => questionId],
  (data, questionId) => {
    const wordCountSet = data?.wordCounts?.find(
      wordCount => wordCount.questionId === questionId
    )
    if (wordCountSet) {
      return {
        ...wordCountSet,
        words: wordCountSet.words?.map(word => ({
          text: word.value,
          value: word.count
        }))
      }
    }
    return null
  }
)
