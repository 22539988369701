import { Flex, Text } from "@engaging-tech/components"
import React from "react"

import { OrgGuard } from "../../features/account"
import Success from "../../features/basket/components/Success"
import PageLayout from "../../layouts/PageLayout"

// Banner should be shared with logos and certs
const Banner = ({ title }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    bg="secondary.0"
    color="light.0"
    width={1 / 1}
    minHeight="300px"
    px={3}
    py={5}
  >
    <Text fontSize={8} fontWeight={700} color="light.0" textAlign="center" mb={2} mt={5}>
      {title}
    </Text>
  </Flex>
)

// Need to add the re-usable banner from the
const SuccessPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const paymentId = params.get("paymentId")

  if (!paymentId)
    return (
      <Text width={1 / 1} textAlign="center" mt={4}>
        404
      </Text>
    )

  return (
    <OrgGuard>
      <PageLayout banner={() => <Banner title="Payment Succesful" />}>
        <Success paymentId={paymentId} />
      </PageLayout>
    </OrgGuard>
  )
}

export default SuccessPage
