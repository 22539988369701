/* eslint-disable camelcase */
import { createSelector } from "reselect"

import { basketState } from "../basket/basket.selectors"

export const productState = state => state && state.products

export const getAvailableProducts = createSelector(
  productState,
  (_, { object_type }) => object_type,
  (state, object_type) => state.data[object_type]
)

const findSDProductByShareableLink = (state, shareableLinks) => {
  const { products } = state
  return (
    products?.data["slice-and-dice"]?.filter(
      product => product?.options?.shareableLinks === shareableLinks
    )[0] ?? { currency: "gbp", unit_amount: 0 }
  )
}

export const getSliceAndDiceProductBasedOnShareableLinks = createSelector(
  findSDProductByShareableLink,
  res => res && res
)

export const getSliceAndDiceProduct = createSelector(productState, state =>
  state.data["slice-and-dice"]
    ? state.data["slice-and-dice"][0]
    : { currency: "gbp", unit_amount: 0 }
)

export const getIsLoading = createSelector(
  productState,
  state => state.isLoading
)

export const getErrors = createSelector(productState, state => state.errors)

const getObjectType = (_, { object_type }) => object_type

export const getSelectedProduct = createSelector(
  [basketState, productState, getObjectType],
  (bState, state, object_type) => {
    /* 
    Selected product needs to be taken from products state. 
    We find that by checking if we have the object_type in our basket
    Then we match the selected product in basket with the product state and return it
    */

    const foundProduct = bState?.data.filter(
      product => product?.object_type === object_type
    )[0]

    const selectedProduct = state?.data[object_type]?.filter(
      product => product?.id === foundProduct?.priceId
    )

    return selectedProduct && selectedProduct[0]
  }
)

export const getAwardProduct = createSelector(
  [basketState, productState, getObjectType],
  (bState, state, object_type) => {
    const foundProduct = bState?.data.filter(
      product => product?.object_type === object_type
    )[0]
    return foundProduct
  }
)
