import { Box, Checkbox, Flex, H2, Paragraph, Text } from "@engaging-tech/components"
import React from "react"

import { Card } from "../../components"

const Privacy = ({ values, errors, setFieldValue, readOnly, ...props }) => {
  return (
    <Card {...props}>
      <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
        Participant results
      </H2>
      <Paragraph>
        Would you like survey participants to be able to see their personal results after they complete the
        survey? WorkL tip: Allowing participants to see their personal results often increases trust in the
        survey and improves response rates.
      </Paragraph>
      {readOnly ? (
        <Text>
          <b>{values && values.private ? "No" : "Yes"}</b>
        </Text>
      ) : (
        <Flex width="auto">
          <Checkbox
            id="yes"
            name="private"
            colour="dark.0"
            mr={3}
            label={<Text>Yes</Text>}
            checked={values && !values.private}
            value={values && values.private}
            errors={errors && errors.private}
            onChange={() => {
              setFieldValue("private", false)
              if (typeof window !== "undefined" && window.hj) {
                window.hj("tagRecording", ["[WFB][App][Surveys] Set survey privacy"])
              }
            }}
          />
          <Checkbox
            id="no"
            name="private"
            colour="dark.0"
            label={<Text>No</Text>}
            value={values && values.private}
            errors={errors && errors.private}
            checked={values && values.private}
            onChange={() => {
              setFieldValue("private", true)
              if (typeof window !== "undefined" && window.hj) {
                window.hj("tagRecording", ["[WFB][App][Surveys] Set survey privacy"])
              }
            }}
          />
        </Flex>
      )}
      <Box bg="primary.2" borderRadius="10px" py={3} px={3} mt={4}>
        <Paragraph color="secondary.0" mb={"10px"}>
          Please note:
        </Paragraph>
        <Paragraph color="secondary.0" mb="6px">
          - Allowing participants to see their personal results often increases trust in the survey and
          improves response rates
        </Paragraph>
        <Paragraph color="secondary.0" mb="6px">
          - You cannot send a survey to less than 5 participants, and you need at least 5 responses per
          demographic group to see data
        </Paragraph>
        <Paragraph color="secondary.0" mb="6px">
          - For the best results, you should aim higher than a 60% response rate
        </Paragraph>
      </Box>
    </Card>
  )
}

export default Privacy
