export const types = {
  MARK_LOADED: "[UI] Mark Loaded Feature",
  MARK_NOT_LOADED: "[UI] Mark Not Loaded Feature",
  LOAD_ALL_COUNTRIES: "[UI] Load All Countries",
  LOAD_ALL_COUNTRIES_SUCCESS: "[UI] Load All Countries Success",
  LOAD_ALL_COUNTRIES_FAILURE: "[UI] Load All Countries Failure",
  LOAD_ALL_INDUSTRIES: "[UI] Load All Industries",
  LOAD_ALL_INDUSTRIES_SUCCESS: "[UI] Load All Industries Success",
  LOAD_ALL_INDUSTRIES_FAILURE: "[UI] Load All Industries Failure",
  SET_BACKBAR: "[UI] SET BACKBAR PROPS",
  SET_ROUTE: "[UI] Set Previous Route",
  FEEDBACK_DIALOG_OPEN: "[UI] Feedback Dialog Open",
  FEEDBACK_DIALOG_CLOSE: "[UI] Feedback Dialog Close"
}

export const feedbackDialogOpen = payload => ({
  type: types.FEEDBACK_DIALOG_OPEN,
  payload
})

export const feedbackDialogClose = () => ({
  type: types.FEEDBACK_DIALOG_CLOSE
})

export const markLoadedFeature = featureName => ({
  type: types.MARK_LOADED,
  payload: featureName
})

export const markNotLoadedFeature = featureName => ({
  type: types.MARK_NOT_LOADED,
  payload: featureName
})

export const setBackBar = (visible, text, to) => ({
  type: types.SET_BACKBAR,
  payload: { visible, text, to }
})

export const setRoute = url => ({
  type: types.SET_ROUTE,
  payload: url
})

export const loadAllCountries = () => ({
  type: types.LOAD_ALL_COUNTRIES
})

export const loadAllCountriesSuccess = payload => ({
  type: types.LOAD_ALL_COUNTRIES_SUCCESS,
  payload
})

export const loadAllCountriesFailure = () => ({
  type: types.LOAD_ALL_COUNTRIES_FAILURE
})

export const loadAllIndustries = () => ({
  type: types.LOAD_ALL_INDUSTRIES
})

export const loadAllIndustriesSuccess = payload => ({
  type: types.LOAD_ALL_INDUSTRIES_SUCCESS,
  payload
})

export const loadAllIndustriesFailure = () => ({
  type: types.LOAD_ALL_INDUSTRIES_FAILURE
})
