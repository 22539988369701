import { connect } from "react-redux"
import { getShowBranching } from "../../store/template.selectors"
import TemplateView from "../../views/TemplateView"

const mapState = state => ({
  showBranching: getShowBranching(state)
})

const TemplateViewContainer = connect(mapState, null)(TemplateView)

export default TemplateViewContainer
