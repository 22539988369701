export const types = {
  GET_LIVE_JOBS_SUCCESS: "[Jobs] Get Live Jobs Success",
  GET_LIVE_JOBS_FAIL: "[Jobs] Get Live Jobs Fail",
  SAVE_JOB_DATA: "[Jobs] Save Job Data",
  POST_JOB_SUCCESS: "[Jobs] Post Job Success",
  POST_JOB_FAIL: "[Jobs] Post Job Fail",
  CLEAR_JOB_DATA: "[Jobs] Clear Job Data"
}

export const getLiveJobsFail = payload => ({
  type: types.GET_LIVE_JOBS_FAIL,
  payload
})

export const getLiveJobsSuccess = payload => ({
  type: types.GET_LIVE_JOBS_SUCCESS,
  payload: payload.getLiveJobs.data
})

export const saveJobData = payload => ({
  type: types.SAVE_JOB_DATA,
  payload
})

export const clearJobData = payload => ({
  type: types.CLEAR_JOB_DATA,
  payload
})

export const postJobSuccess = payload => ({
  type: types.POST_JOB_SUCCESS,
  payload
})

export const postJobFail = payload => ({
  type: types.POST_JOB_FAIL,
  payload
})
