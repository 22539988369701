export const heatMapTabsValues = [
  {
    key: "CURRENT",
    label: "VIEW CURRENT RESULTS",
    action: "tab"
  },
  {
    key: "PREVIOUS",
    label: "COMPARE PREVIOUS RESULTS",
    action: "dropdown"
  },
  {
    key: "GLOBAL",
    label: "COMPARE INDUSTRY/GLOBAL",
    action: "tab"
  }
]
