import React, { useState } from "react"
import { Text, Box } from "@engaging-tech/components"
import { StyledTextField } from "../../../ui/components/StyledTextField"

const DialogueTextField = ({ setTitle }) => {
  const [error, setError] = useState(null)

  const onTextInput = text => {
    setError(null)
    if (!text) {
      setError("The title should not be empty")
    }

    setTitle(text)
  }

  return (
    <Box px={4}>
      <StyledTextField
        placeholder="Report Name"
        borderRadius={10}
        onChange={event => onTextInput(event.target.value)}
      />
      {error && <Text color="red">{error}</Text>}
    </Box>
  )
}

export default DialogueTextField
