import { connect } from "react-redux"

import * as FiltersSelectors from "../../../Filters/store/filters.selectors"
import * as ReportActions from "../../../store/report.actions"
import * as Selectors from "../../../store/report.selectors"
import { HeatMapResults } from "./HeatMapResults"

const mapState = state => ({
  report: Selectors.getReportResponse(state),
  heatmapFilters: Selectors.getHeatmapFilters(state),
  allBusinessAreas: Selectors.allBusinessAreas(state),
  surveyType: Selectors.getSurveyType(state),
  directReports: FiltersSelectors.getDirectReports(state),
  exportingPDF: Selectors.getIsHeatMapPdfDownloading(state),
  allHeatmapFilters: Selectors.getAllHeatmapFilters(state),
  reportId: Selectors.getReportResponseReportId(state),
  questionFilter: Selectors.getQuestionFilter(state),
  tabSelected: Selectors.getHeatmapTabSelected(state),
  comparisonReport: Selectors.getComparisonReport(state),
  isComparisonLoading: Selectors.getComparisonIsLoading(state),
  comparisonSurveyTitle: Selectors.getComparisonSurveyTitle(state),
  comparisonFilters: Selectors.getComparisonFilters(state)
})

const mapDispatch = dispatch => ({
  addHeatmapFilter: filter => dispatch(ReportActions.addHeatmapFilter(filter)),
  clearAllHeatmapFilters: () =>
    dispatch(ReportActions.clearAllHeatmapFilters()),
  setAllBusinessAreas: payload =>
    dispatch(ReportActions.setAllBusinessAreas(payload)),
  loadAllBusinessAreas: (id, surveyType, directReports) =>
    dispatch(ReportActions.loadAllBusinessAreas(id, surveyType, directReports)),
  loadFilterData: (id, surveyType, filter, directReports, filterId) =>
    dispatch(
      ReportActions.loadFilterData(
        id,
        surveyType,
        filter,
        directReports,
        filterId
      )
    )
})
export const HeatMapResultsContainer = connect(
  mapState,
  mapDispatch
)(HeatMapResults)
