import { Flex, LocationAutocomplete } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

const LocationInput = ({ inputValue, setInputValue, selectedLocationCallback }) => {
  return (
    <Flex justifyContent="center" alignItems="stretch" flexDirection="column" width={[1, 0.5]}>
      <LocationAutocomplete
        id="location.name"
        name="location.name"
        placeholder="City"
        type="text"
        width={1}
        height={60}
        borderRadius="4px 4px 0px 0px"
        googleApiKey={getConfig().keys.googleApiKey}
        value={inputValue ?? ""}
        onChange={setInputValue}
        selectedLocationCallback={selectedLocationCallback}
      />
    </Flex>
  )
}

export default LocationInput
