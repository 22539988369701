import { connect } from "react-redux"
import { ReportSelectors, ReportActions } from "../index"
import SurveyComparisonView from "../views/SurveyComparisonView"

const mapState = state => ({
  stage: ReportSelectors.getSurveyComparisonStage(state),
  checkedList: ReportSelectors.getSurveyComparisonCheckedList(state),
  startDate: ReportSelectors.getSurveyComparisonStartDate(state),
  endDate: ReportSelectors.getSurveyComparisonEndDate(state),
  subCheckedList: ReportSelectors.getSubCheckedList(state)
})

const mapDispatch = dispatch => ({
  setStage: stage => dispatch(ReportActions.setSurveyComparisonStage(stage)),
  setBreadcrumbs: crumb =>
    dispatch(ReportActions.setSurveyComparisonBreadcrumbs(crumb)),
  setCheckedList: list =>
    dispatch(ReportActions.setSurveyComparisonCheckedList(list)),
  getSurveyComparisonData: options =>
    dispatch(ReportActions.getSurveyComparisonData(options)),
  setStartDate: startDate =>
    dispatch(ReportActions.setSurveyComparisonStartDate(startDate)),
  setEndDate: endDate =>
    dispatch(ReportActions.setSurveyComparisonEndDate(endDate)),
  setSubCheckedList: subCheckedList =>
    dispatch(ReportActions.setSubCheckedList(subCheckedList)),
  setHasError: error => dispatch(ReportActions.setHasError(error))
})

const SurveyComparisonViewContainer = connect(
  mapState,
  mapDispatch
)(SurveyComparisonView)

export default SurveyComparisonViewContainer
