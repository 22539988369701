import { Box, Button, Card, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import { emailEB } from "../../../lib/globalConstants"

export default function ErrorState({ message }) {
  return (
    <Flex justifyContent="center" width={1}>
      <Card mt={[5, 5, 6]} mb={[6, 5]} elevation={8} bg="light.0" alignItems="center" maxWidth="800px">
        <Box bg="dark.7" py={[2, 3]} px={3} width={1 / 1} mb={5}>
          <Text fontSize={[3, 6]} textAlign="center" fontWeight="500" display="block">
            {message || "Oops! Something Went Wrong…"}
          </Text>
        </Box>
        <Flex flexDirection="column" alignItems="center" px={3} pb={3}>
          <Text fontSize={3} mb={3} width="auto" textAlign="center" color="dark.2">
            If you need some help as to why you are seeing this message, please get in touch with us at:
          </Text>
          <Text fontWeight="500" fontSize={3} mb={3} width="auto" textAlign="center" color="primary.0">
            {emailEB}
          </Text>

          <Text fontSize={3} mb={5} width="auto" textAlign="center" color="dark.2">
            If you are seeing this message repeatedly, please sign out of your account and try again.
          </Text>
          <Link to="/">
            <Button variant="primary.0" px={[3, 5]}>
              Homepage
            </Button>
          </Link>
        </Flex>
      </Card>
    </Flex>
  )
}
