/* */
// import { isObjectType } from "graphql"
import { createSelector } from "reselect"

import { ReportSelectors } from "../.."
import { AccountSelectors } from "../../../account"
import createDatasetArray from "../../../utils/createDatasetArr"
import { getCurrentStep } from "../../SixStepsOverview/store/sixstepsoverview.selectors"

const pipePassedInProp = (state, prop) => prop

export const getSixStepsQuestions = createSelector(
  ReportSelectors.getReportData,
  data => data && data.sixStepsQuestions
)

export const getQuestionScore = createSelector(
  [getCurrentStep, ReportSelectors.getReportData],
  (step, data) => {
    const currentStepQuestions = data?.sixStepsQuestions?.filter(entry => entry?.step === step?.name)
    return currentStepQuestions?.[0]?.questions
  }
)

const labels = {
  score: "Your Score",
  global: "Global",
  industry: "Industry",
  rootReport: "Overall"
}

export const getQuestionsByStep = createSelector(
  [getSixStepsQuestions, AccountSelectors.getOrganisation, pipePassedInProp],
  (sixStepQuestions, org, step) => {
    const selectedQuestion = sixStepQuestions?.find(stepQuestionsItem => stepQuestionsItem.step === step)

    return {
      id: selectedQuestion?.id,
      title: selectedQuestion?.title,
      description: selectedQuestion?.description,
      graphDataset: {
        labels: selectedQuestion?.questions?.map(q => q.question),
        datasets: selectedQuestion?.questions?.length
          ? createDatasetArray(selectedQuestion.questions).map(t => ({
              label: t === "rootReport" ? org?.name : labels[t],
              data: selectedQuestion?.questions?.map(question => question.values[t])
            }))
          : []
      }
    }
  }
)
