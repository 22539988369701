import { Box, Button, Checkbox, Flex, Text } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { combineHeatmapFilters } from "../../store/report.actions.js"
import useHeatmapFilter from "../models/use-heatmap-filter.jsx"
import DualToggle from "./DualToggle.jsx"
import NoFiltersModal from "./NoFiltersModal"

const StickyFlex = styled(Flex)`
  position: sticky;
  left: 0;
  background-color: ${({ theme }) => theme.colors.light[0]};
  z-index: 1;
  padding-top: ${({ expand }) => (expand ? "0px" : "56px")};
`

const Form = styled("form")`
  position: absolute;
  top: 22px;
  left: 2px;
`

const CustomText = styled(Text)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.secondary[1]}`};
`

const CustomFlex = styled(Flex)`
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[1]}`};
  border-radius: 6px;
`

const CustomOverflowText = styled(Text)`
  overflow: hidden;
  width: 180px;
  height: 20px;
  padding-right: 3px;

  &:hover {
    width: max-content;
    z-index: 1;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.dark[4]};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
`

const mergeTitle = filters => {
  const valuesArray = filters?.reduce((acc, next) => {
    next.values.forEach(({ value }) => acc.push(value))
    return acc
  }, [])
  const title = valuesArray?.length ? valuesArray.join(", ") : ""

  return title
}

const HeatMapFilter = ({
  filter,
  loadFilterData,
  surveyType,
  directReports,
  setOpenModal,
  openMenu,
  setOpenMenu,
  allHeatmapFilters,
  marginBottomToUse,
  heightToUse
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  useEffect(() => {
    loadFilterData(
      id,
      surveyType,
      filter.heatmapFilter,
      directReports,
      filter.id
    )
  }, [
    directReports,
    id,
    loadFilterData,
    filter.id,
    surveyType,
    filter.heatmapFilter
  ])

  /* Terms:
    - all Filters - a way to track all the filters selected via the EDIT RESULTS button.
    - heatmapFilter object - src/features/Reports/store/report.reducer.js contains an entry to an array of objects.
  Every object here triggers an API call and is displayed in a row.
    - row - this component (HeatmapFilter) is a representative of a single row inside the heatmaps.
  
    The reason why I am using local state for setting checkboxes for all filters instead of 
  redux state where the heatmapFilter is stored for triggering API is because every single 
  heatmapFilter object would need to keep track the changes of all filters. Which means that 
  on every added new filter I need to update every heatmapFilter - all filters key with latest
  filter. 
    If this is not bad enough, the data object which is an array of objects within an array
  of objects, would make it very resource inefficient to update the checkboxes. To make it efficient
  you need to convert that data structure to something more checkbox friendly thats done in useHeatmapFilter
  hook.
    This means that every heatmapFilter object in redux state would need their all filters key to be converted
  to a checkbox friendly format, which in the end would make it less efficient than the solution implemented here;
  using a local state to track checkboxes
  */
  const [dropdownOptions, setDropdownOptions] = useState({})
  const [checked, setChecked] = useState(false)

  const setOptions = (heatmapFilter, filterOptions) => {
    const options = useHeatmapFilter(heatmapFilter, filterOptions)
    setDropdownOptions(options)
  }

  useEffect(() => {
    setOptions(filter.heatmapFilter, allHeatmapFilters)
  }, [allHeatmapFilters])

  useEffect(() => {
    const dropdownOptsArray = Object.values(dropdownOptions)

    for (let i = 0; i < dropdownOptsArray.length; i++) {
      if (dropdownOptsArray[i].selected) {
        setChecked(true)
        break
      }

      if (
        i === dropdownOptsArray.length - 1 &&
        !dropdownOptsArray[i].selected
      ) {
        setChecked(false)
      }
    }
  }, [dropdownOptions])

  const handleToggleClick = () => {
    if (
      allHeatmapFilters?.length > 1 ||
      (allHeatmapFilters.length === 1 && allHeatmapFilters[0].values.length > 1)
    ) {
      setOpenMenu(filter.id)
    } else {
      setOpenModal(true)
    }
  }

  const handleChange = e => {
    const valuesObj = dropdownOptions[e.target.id]
    setDropdownOptions({
      ...dropdownOptions,
      [e.target.id]: {
        ...valuesObj,
        selected: !valuesObj.selected
      }
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    /* 
      Need to parse/stringify, because we still have direct reference to the state here,
      and when we push the data object to heatmapFilter and later modify it, the state
      also gets modified
    */
    dispatch(
      combineHeatmapFilters(
        JSON.parse(JSON.stringify(dropdownOptions)),
        filter.id
      )
    )
    setOpenMenu("")
  }

  const cancel = () => {
    setOptions(filter.heatmapFilter, allHeatmapFilters)
    setOpenMenu("")
  }

  return (
    <Flex
      flexDirection="column"
      height={heightToUse}
      mb={marginBottomToUse}
      position="relative"
      pt="10px"
    >
      <CustomOverflowText pr={6}>
        {mergeTitle(filter.heatmapFilter)}
      </CustomOverflowText>
      <Box mt={1} ml={-1} position="relative">
        <DualToggle handleClick={handleToggleClick} checked={checked} />

        {openMenu === filter.id && (
          <Form onSubmit={handleSubmit}>
            <CustomFlex
              width={260}
              zIndex={10}
              position="relative"
              bg="dark.4"
              flexDirection="column"
            >
              <CustomText fontSize={3} py={2} pl={2} mb={2}>
                Select filters for AND comparison
              </CustomText>
              {Object.keys(dropdownOptions)?.map(key => (
                <Checkbox
                  key={key}
                  label={() => <Text>{dropdownOptions[key].name}</Text>}
                  width="inherit"
                  px={3}
                  my={1}
                  colour="dark.2"
                  checked={dropdownOptions[key].selected}
                  onChange={handleChange}
                  id={key}
                />
              ))}

              <Flex px={3} my={3} justifyContent="space-between">
                <Button bg="dark.4" elevation={0} onClick={() => cancel()}>
                  Cancel
                </Button>
                <Button
                  width="100px"
                  bg="secondary.1"
                  elevation={0}
                  type="submit"
                >
                  Apply
                </Button>
              </Flex>
            </CustomFlex>
          </Form>
        )}
      </Box>
    </Flex>
  )
}

const ActiveHeatMapFilters = ({
  heatmapFilters,
  loadFilterData,
  surveyType,
  directReports,
  expand,
  allHeatmapFilters,
  showGlobalResult,
  showPreviousComp
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [openMenu, setOpenMenu] = useState("")
  const itemsToRender = showGlobalResult ? 3 : 1

  const heightToUse = showGlobalResult ? 315 : showPreviousComp ? 227 : 65

  const marginBottomToUse = 16 * itemsToRender

  const closeNoFiltersModal = () => setOpenModal(false)

  return (
    <>
      <StickyFlex flexDirection="column" width={200} pl={1} expand={expand}>
        {expand && (
          <Text width="auto" fontWeight="600" fontSize={5} pt="112px">
            Active Filters
          </Text>
        )}
        <Flex
          flexDirection="column"
          height={heightToUse}
          mb={marginBottomToUse}
          pt="25px"
        >
          <Text fontWeight="400" pr={6}>
            All Business Areas
          </Text>
        </Flex>
        {heatmapFilters.map(filter => (
          <HeatMapFilter
            key={filter.id}
            filter={filter}
            loadFilterData={loadFilterData}
            surveyType={surveyType}
            directReports={directReports}
            setOpenModal={setOpenModal}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            allHeatmapFilters={allHeatmapFilters}
            heightToUse={heightToUse}
            marginBottomToUse={marginBottomToUse}
          />
        ))}
      </StickyFlex>
      {openModal && (
        <NoFiltersModal
          handleClose={closeNoFiltersModal}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  )
}

export default ActiveHeatMapFilters
