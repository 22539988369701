import { Flex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React from "react"
import { useState } from "react"
import { useSelector } from "react-redux"

import CancelPDFModal from "../../components/CancelPDFModal"
import { getReportResponseTitle } from "../../store/report.selectors"
import DownloadHeatMapPDF from "../containers/DownloadHeatMapPDF"
import FiltersButton from "../containers/FiltersButton"
import ExpandCollapseButton from "./ExpandCollapseButton"
import { HeatMapTabs } from "./HeatMapTabs/HeatMapTabs"

export function HeatmapHeader({ expand, setExpand, hideCollapseButton }) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const [isPdfDownloading, updatePdfDownloading] = useState(false)
  const title = useSelector(getReportResponseTitle)

  return (
    <>
      <AnimatePresence>
        {isPdfDownloading && !isSafari && <CancelPDFModal />}
      </AnimatePresence>
      <Flex
        justifyContent="space-between"
        flexDirection={["row", "column", "row"]}
        flexWrap="wrap"
        alignItems="stretch"
        width="100%"
      >
        <Flex flexDirection={["row"]}>
          <Flex flexDirection={["column"]}>
            <Text mb={4} fontSize={6} fontWeight={700}>
              Six Steps Heatmap - {title}
            </Text>
            <HeatMapTabs />
          </Flex>
          <Flex
            flexDirection={["column"]}
            width={["100%", "100%", "auto"]}
            justifyContent="flex-end"
          >
            <Flex>
              <DownloadHeatMapPDF updatePdfDownloading={updatePdfDownloading} />
            </Flex>
            <Flex>
              {hideCollapseButton ? null : (
                <ExpandCollapseButton expand={expand} setExpand={setExpand} />
              )}
              <FiltersButton />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
