import { types } from "./actions"

const general = "Something went wrong"

export const initialState = {
  isLoading: false,
  error: { general: "", error: null },
  data: {
    listOrganisationSurveys: []
  }
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.GET_SURVEYS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...payload
        }
      }
    }

    case types.GET_SURVEYS_LIST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: { general, error: payload.error }
      }
    }

    case types.GET_SURVEYS_LIST_REQ: {
      return {
        ...state,
        isLoading: true,
        data: {
          ...state.data
        }
      }
    }

    default:
      return state
  }
}
