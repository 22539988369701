import React from "react"
import { Box, CircularProgress } from "@engaging-tech/components"
import { getLabelData } from "../../components/ScoreLabel"

const CircularProgressComponent = ({ score }) => {
  return (
    <Box py={3} width="15%">
      <CircularProgress
        percentage={score}
        strokeColor={getLabelData(score).color}
      />
    </Box>
  )
}

export default CircularProgressComponent
