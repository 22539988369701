import { gql } from "@apollo/client"

const SAVE_ACTION_PLAN_STEP = gql`
  mutation saveActionPlanStep($input: EditActionPlanStepInput) {
    saveActionPlanStep(input: $input) {
      statusCode
      body
      error {
        code
        message
        type
      }
    }
  }
`

export default SAVE_ACTION_PLAN_STEP
