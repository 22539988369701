import { usePaths, useRouter } from "@engaging-tech/routing"
import { Formik } from "formik"
import React, { useEffect } from "react"
import { date, object, ref, string } from "yup"
import { LoadingState } from "../../components"
import ReviewView from "../views/Review"

const ReviewComponent = ({ handleSubmit, validateForm, onClose, ...props }) => {
  const router = useRouter()
  const paths = usePaths()

  const handleFormSubmit = async route => {
    const res = await validateForm()
    if (Object.keys(res).length === 0) {
      handleSubmit()
      router.navigate(route)
    }
  }

  const actions = {
    prev: {
      onClick: () => handleFormSubmit(paths.surveys.surveyCreation.participants)
    },
    next: {
      onClick: () => handleFormSubmit(paths.surveys.orderSummary)
    },
    saveAndClose: {
      onClick: () => handleFormSubmit(paths.surveys.draftSurveys)
    },
    close: {
      onClick: () => onClose() && router.navigate(paths.surveys.draftSurveys)
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <ReviewView {...actions} {...props} />
    </form>
  )
}

const validationSchema = object().shape({
  title: string().required("A title is required for the survey"),
  template: object()
    .shape({
      id: string().required("A template must be selected")
    })
    .required("A template must be selected"),
  startDate: date()
    .min(
      new Date(new Date().setHours(0, 0, 0, 0)),
      "The start date must be from today, onwards"
    )
    .required("Please enter a start date"),
  endDate: date()
    .min(ref("startDate"), "The end date must be after the start date")
    .required("Please enter an end date")
})

const ReviewForm = ({
  initialValues,
  onSubmit,
  onClose,
  meta,
  id,
  surveyIsLoading
}) => {
  const router = useRouter()
  const paths = usePaths()
  useEffect(() => {
    if (!id) {
      router.redirect(paths.surveys.index)
    }
  }, [id])

  if (surveyIsLoading)
    return <LoadingState message="Updating survey meta data..." />

  const handleSubmit = values =>
    onSubmit({
      survey: {
        title: values.title,
        template: values.template,
        meta: {
          ...meta,
          startDate: values.startDate,
          endDate: values.endDate
        }
      }
    })

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {props => <ReviewComponent onClose={onClose} {...props} />}
    </Formik>
  )
}

export default ReviewForm
