/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

/**
 * Get the discounted (or non discounted) price for a subscription/payment.
 * @param {Object} params - Parameters required for determining the price.
 * @param {number} params.fullPrice - The full price of the product or subscription.
 * @param {number} params.amountOff - The amount to be subtracted from the full price.
 * @param {number} params.percentOff - The percentage of the full price to be subtracted from the full price.
 * @return {number} The final price
 */
export const getDiscountedPrice = ({ amountOff, percentOff, fullPrice }) => {
  if (!fullPrice) throw new Error("Must provide fullPrice parameter")
  if (!amountOff && !percentOff) return fullPrice
  if (percentOff) {
    if (percentOff > 0 && percentOff <= 100) {
      return fullPrice - (fullPrice * percentOff) / 100
    }
    return fullPrice
  }
  const price = fullPrice - amountOff
  if (price <= 0) return 0
  if (price >= fullPrice) return fullPrice
  return price
}

/**
 * Recursively searches through a object, removing the __typename variables
 * @param {Object} source - An object that contains '__typename's'
 * @returns {Object} The same object, without the typenames
 */

const deleteTypeNamesRec = source => {
  if (typeof source !== "object" || source === null) return
  if (Array.isArray(source))
    // eslint-disable-next-line consistent-return
    return source.forEach(element => deleteTypeNamesRec(element))
  if (source.__typename) {
    delete source.__typename
  }
  Object.values(source).forEach(value => {
    if (Array.isArray(value)) {
      value.forEach(element => deleteTypeNamesRec(element))
    }
    if (typeof value === "object") {
      deleteTypeNamesRec(value)
    }
  })
}

export const deleteTypeNames = source => {
  const newSource = JSON.parse(JSON.stringify(source))
  deleteTypeNamesRec(newSource)
  return newSource
}
