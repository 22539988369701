import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import ChangeCurrency from "./ChangeCurrency"
import { MarginFlex } from "./Item"

const Summary = ({ basketTotals, acceptedCurrencies = ["gbp"], currency }) => {
  const { price, totalPrice, tax } = basketTotals

  return (
    <Flex
      flexDirection={["column-reverse", "column"]}
      width={[1 / 1, 1 / 2]}
      pl={[0, 6]}
      mt={[4, 0]}
    >
      <Flex mt={3}>
        <ChangeCurrency
          acceptedCurrencies={acceptedCurrencies}
          currency={currency}
        />
      </Flex>
      <MarginFlex width={1 / 1} pt={2} mt={3}>
        <Flex width={1 / 2} flexDirection="column">
          <Text fontSize={5}>Subtotal:</Text>
          <Text fontSize={5}>VAT:</Text>
          <Text fontSize={5} mt={5}>
            Total
          </Text>
        </Flex>
        <Flex width={1 / 2} flexDirection="column" alignItems="flex-end">
          <Text width="fit-content" fontWeight={700} fontSize={5}>
            {new Intl.NumberFormat("en-EN", {
              style: "currency",
              minimumFractionDigits: 0,
              currency
            }).format(price)}
          </Text>
          <Text width="fit-content" fontWeight={700} fontSize={5}>
            {new Intl.NumberFormat("en-EN", {
              style: "currency",
              minimumFractionDigits: 0,
              currency
            }).format(tax)}
          </Text>
          <Text width="fit-content" mt={5} fontWeight={700} fontSize={5}>
            {new Intl.NumberFormat("en-EN", {
              style: "currency",
              minimumFractionDigits: 0,
              currency
            }).format(totalPrice)}
          </Text>
        </Flex>
      </MarginFlex>
      <MarginFlex flexDirection="column" width={1 / 1} mt={3} pt={2}>
        <Text>
          If you need any support please contact our team at{" "}
          <a href="mailto:support@workl.com">
            <u>support@workl.com</u>
          </a>{" "}
          or call (+44) 20 4576 1730.
        </Text>
        <Text mt={3}>Please choose your payment option:</Text>
      </MarginFlex>
    </Flex>
  )
}

export default Summary
