import { Paragraph } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"

import LinkButton from "../../../ui/components/LinkButton"
import ReportCard from "../../components/ReportCard"
import DisplayMatrix from "./DisplayMatrix"
import EmptyMatrix from "./EmptyMatrix"

const Matrix = ({
  hasMatrices,
  matrixData,
  quadrantDescriptions,
  axisLabels,
  title,
  name,
  description,
  exportingPDF,
  tag
}) => {
  const paths = usePaths()
  const { id, surveyType } = useParams()
  if (exportingPDF && (!matrixData || matrixData.length === 0)) {
    return <></>
  }

  const getLogo = () =>
    ({
      matrices_jobSatisfaction: "case-tick",
      matrices_organisationSatisfaction: "shadow-person",
      matrices_wellBeing: "sunny",
      matrices_careerDevelopment: "sunny",
      matrices_inclusiveness: "supervised_user_circle",
      matrices_empowerment: "sunny",
      matrices_senseOfPurpose: "trending_up",
      matrices_lineManagerRelationship: "conference-speaker",
      matrices_workplaceEnvironment: "lamp",
      matrices_managementConfidence: "handing-profits"
    }[name])

  const getColour = () => {
    switch (name) {
      case "matrices_organisationSatisfaction":
        return "dark.0"
      case "matrices_managementConfidence":
        return "#FF5C00"
      default:
        return "#003E4C"
    }
  }

  return (
    <ReportCard
      headingIcon={getLogo()}
      headingIconProps={{
        size: 28,
        p: 4,
        mr: 2,
        borderRadius: "50%",
        bg: name === "matrices_managementConfidence" ? "#FFEFE6" : "rgba(204, 251, 226, 1)",
        color: getColour()
      }}
      heading={title}
      headingProps={{
        fontWeight: "normal",
        fontSize: 6
      }}
      width={1 / 1}
      exportingPDF={exportingPDF}
      metaProps={{
        mb: 2
      }}
    >
      <Paragraph color="dark.2" fontSize={3}>
        {description}
      </Paragraph>
      {!matrixData || matrixData.length === 0 ? (
        <EmptyMatrix />
      ) : (
        <>
          <DisplayMatrix
            exportingPDF={exportingPDF}
            axisLabels={axisLabels}
            matrixData={matrixData}
            quadrantDescriptions={quadrantDescriptions}
          />
          {hasMatrices && !exportingPDF && (
            <LinkButton
              variant="text.primary.0"
              color="primary.0"
              text="Discover"
              path={`${paths.reports.summary.matricesPage
                .replace(":surveyType", surveyType)
                .replace(":id", id)}?tag=${tag}`}
            />
          )}
        </>
      )}
    </ReportCard>
  )
}

export default Matrix
