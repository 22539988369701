const questionTemplate = [
  {
    label: "Linear scale",
    icon: "",
    config: {
      type: "scale",
      min: 0,
      max: 10,
      title: "",
      description: "",
      icon: "trophy",
      enabled: true,
      required: true,
      isCore: false
    }
  },
  {
    label: "Short text",
    icon: "",
    config: {
      type: "short-text",
      title: "",
      description: "",
      icon: "trophy",
      enabled: true,
      required: true,
      fields: [
        {
          label: "",
          limit: 250,
          type: "text"
        }
      ],
      isCore: false
    }
  },
  {
    label: "Multiple choice",
    icon: "",
    config: {
      type: "radio",
      title: "",
      description: "",
      label: "",
      icon: "trophy",
      enabled: true,
      required: true,
      isFilter: false,
      values: [],
      isCore: false
    }
  },
  {
    icon: "",
    label: "Dropdown",
    config: {
      type: "dropdown",
      title: "",
      description: "",
      label: "",
      icon: "trophy",
      enabled: true,
      required: true,
      isFilter: false,
      values: [],
      isCore: false
    }
  }
]

export default questionTemplate
