import { getConfig } from "@engaging-tech/ssr-config"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getOrganisationCurrentUser } from "../features/account/store/account.selectors"
import { showFreshchatWidget, destroyFreshchatWidget } from "../utils/freschat"

const useFreschatWidget = () => {
  const { fsNormalWidgetId, fsNonLoginWidget } = getConfig()
  let widgetId = fsNonLoginWidget
  const user = useSelector(getOrganisationCurrentUser)
  let tags = []

  useEffect(() => {
    if (user?.firstName) {
      widgetId = fsNormalWidgetId
      showFreshchatWidget(widgetId, tags, null, user.firstName)
    } else {
      showFreshchatWidget(widgetId)
    }

    return () => {
      destroyFreshchatWidget()
    }
  }, [user])

  return null
}

export default useFreschatWidget
