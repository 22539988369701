import React from "react"
import { Flex } from "@engaging-tech/components"
import SeeAllWebinars from "../containers/SeeAllWebinars"

const SeeAllWebinarsView = () => (
  <Flex
    flexDirection={["column", "column", "row"]}
    flexWrap="wrap"
    pb={5}
    width={1 / 1}
    justifyContent={["space-between"]}
  >
    <SeeAllWebinars />
  </Flex>
)

export default SeeAllWebinarsView
