import { Flex, Modal, Text } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

import { ActionPlanStepPreview } from "../../../../components/action-plan/action-plan-step"
import { objectToHtml } from "../../lib/objectToHtml"

const StyledUnderline = styled.u`
  &:hover {
    cursor: pointer;
  }
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const PreviewStepModal = ({
  setOpenModal,
  actionPlanStep,
  handleOpenPreviewModal
}) => {
  const parsedData = {
    ...actionPlanStep,
    questions: actionPlanStep.questions?.map(entry => ({
      body: {
        ...entry.body,
        suggestedActions: entry?.body?.suggestedActions
          ? objectToHtml(JSON.parse(entry?.body?.suggestedActions))
          : "",
        suggestedPhrases: entry?.body?.suggestedPhrases
          ? objectToHtml(JSON.parse(entry?.body?.suggestedPhrases))
          : "",
        description: entry?.body?.description
          ? objectToHtml(JSON.parse(entry?.body?.description))
          : ""
      },
      title: entry?.title,
      sixStepsQuestionId: entry?.sixStepsQuestionId
    }))
  }

  return createPortal(
    <Modal
      bg="light.0"
      width={1 / 1}
      height="100vh"
      maxWidth="2000"
      onToggle={setOpenModal}
    >
      <Flex width={1 / 1} flexDirection="column">
        <Flex width={1 / 1} bg="primary.1">
          <Text width={1 / 1} textAlign="center" p={3}>
            <b>Preview.</b> This is a preview of your Action Plan.{" "}
            <StyledUnderline onClick={handleOpenPreviewModal}>
              Click here
            </StyledUnderline>{" "}
            to go back to the Action Plan editor
          </Text>
        </Flex>
        <Flex p={5} justifyContent="center">
          <Flex width="fit-content">
            <ActionPlanStepPreview step={parsedData} />
          </Flex>
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default PreviewStepModal
