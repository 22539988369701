import { connect } from "react-redux"

import { setDescription } from "../../../../store/actionPlans/actionPlans.actions"
import EditDescription from "../../components/EditActionPlan/EditDescription"

const mapState = state => ({})

const mapDispatch = dispatch => ({
  setDescription: (description, stepQuestionId) =>
    dispatch(setDescription(description, stepQuestionId))
})

const EditDescriptionContainer = connect(mapState, mapDispatch)(EditDescription)

export default EditDescriptionContainer
