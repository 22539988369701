import React from "react"
import { Text, BarChart } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"

import styled from "styled-components"
import ReportItem from "../../components/ReportItem"
import ScrollOverflowBox from "../../components/ScrollOverflowBox"
import ReportCard from "../../components/ReportCard"

const ItalicText = styled(Text)`
  font-style: italic;
`
const NAMES = ["score", "industry", "global", "rootReport"]

const labels = {
  score: "Your Score",
  industry: "Industry",
  global: "Global",
  rootReport: "Overall"
}

const addToData = (data, name, questionValue, overwriteLabel) => {
  const { question, values } = questionValue
  const score = Math.round((values[name] || 0) * 100)
  if (!score) return data
  const label = overwriteLabel || labels[name]
  const questionScore = { x: question, y: score }
  const current = data[label]
  return {
    ...data,
    [label]: current ? [...current, questionScore] : [questionScore]
  }
}

const getData = (questionValues, orgName) =>
  questionValues.reduce(
    (acc, questionValue) =>
      NAMES.reduce(
        (data, name) =>
          addToData(
            data,
            name,
            questionValue,
            name === "rootReport" ? orgName : null
          ),
        acc
      ),
    {}
  )

const SixStepsQuestionsComparison = ({ exportingPDF, questionData }) => {
  return (
    <ReportCard width={1 / 1} exportingPDF={exportingPDF}>
      <ReportItem
        heading={titleCaps(questionData.title)}
        subHeading={questionData.description}
      >
        <ItalicText color="dark.2" fontSize={3}>
          To protect the anonymity of employees the data will appear as 0 if
          less than 5 people match this data selection
        </ItalicText>
        <ScrollOverflowBox>
          <BarChart
            disableAllAnimation={exportingPDF}
            dataset={questionData.graphDataset}
            graphOptions={{
              scales: {
                y: {
                  min: 0,
                  max: 100
                }
              }
            }}
            mt={3}
            showDataLabels
            height={[250, 400, 550]}
            unit="%"
            yAxisLabel="Your Average Question Score"
          />
        </ScrollOverflowBox>
      </ReportItem>
    </ReportCard>
  )
}

export default SixStepsQuestionsComparison
