import React from "react"
import { createGlobalStyle } from "styled-components"
import { Flex, Text } from "@engaging-tech/components"

const Background = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.dark[4]};
  }
`

const Dialog = ({ title, children }) => (
  <>
    <Background />
    <Flex p={3} justifyContent="center" alignItems="center" mb={[6, 0]}>
      <Flex
        maxWidth={560}
        elevation={12}
        p={3}
        bg="light.0"
        borderRadius={3}
        flexDirection="column"
      >
        <Flex mb={3} mt={2}>
          <Text textAlign="center" fontWeight={700} fontSize={5}>
            {title}
          </Text>
        </Flex>
        {children}
      </Flex>
    </Flex>
  </>
)

export default Dialog
