import { Box, Spinner } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import ErrorState from "../../../business-information/components/error-state"
import ProductCard from "./ProductCard"

export const CustomGrid = styled(Box)`
  display: grid;
  width: 100%;
  margin-top: 24px;
  grid-gap: 16px;

  grid-template-columns: ${({ length }) => `repeat(${length || 4}, minmax(160px, 275px))`};

  @media (max-width: 1152px) {
    grid-template-columns: ${({ length }) => `repeat(${length === 1 ? 1 : 2}, minmax(160px, 275px))`};
    grid-template-rows: auto;
    grid-row-gap: 16px;
    justify-content: center;
  }

  @media (max-width: 578px) {
    grid-template-columns: minmax(160px, 275px);
  }
`

const ChooseSurveySize = ({
  setSelectedProduct,
  products = [],
  selectedPriceId,
  isLoading,
  error,
  objectType,
  showProductName = false
}) => {
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />
  if (error) return <ErrorState />

  return (
    <CustomGrid length={products?.length}>
      {products?.map(price => (
        <ProductCard
          key={price.id}
          highlightedCard={selectedPriceId}
          handleClick={setSelectedProduct}
          price={price}
          objectType={objectType}
          showProductName={showProductName}
        />
      ))}
    </CustomGrid>
  )
}
export default ChooseSurveySize
