import { Flex, Icon, Text } from "@engaging-tech/components"
import React from "react"

const defaultBreakpoints = [80, 70, 60, 30]
const indicatorBreakpoints = [80, 60, 40, 20]

export const getIndicatorLabelData = (
  score,
  breakpoints = indicatorBreakpoints
) => {
  const colorsMatrix = [
    {
      color: "#BF4630",
      text: "Improvement Required",
      icon: "error"
    },
    {
      color: "#BF4630",
      text: "Improvement Recommended",
      icon: "error"
    },
    {
      color: "#FF9800",
      text: "Improvement Advised",
      icon: "error"
    },
    {
      color: "#00BB88",
      text: "Good Score!",
      icon: "check_circle"
    },
    {
      color: "#00BB88",
      text: "Excellent!",
      icon: "check_circle"
    }
  ]

  if (score >= breakpoints[0]) return colorsMatrix[4]
  if (score >= breakpoints[1]) return colorsMatrix[3]
  if (score >= breakpoints[2]) return colorsMatrix[2]
  if (score >= breakpoints[3]) return colorsMatrix[1]
  return colorsMatrix[0]
}

export const getLabelData = (theScore, breakpoints = defaultBreakpoints) => {
  const colorsMatrix = [
    {
      color: "#DD0000",
      text: "Improvement Required",
      icon: "error"
    },
    {
      color: "#FF0000",
      text: "Improvement Recommended",
      icon: "error"
    },
    {
      color: "#FF9800",
      text: "Improvement Advised",
      icon: "warn"
    },
    {
      color: "#50C878",
      text: "Good Score!",
      icon: "check_circle"
    },
    {
      color: "#39B086",
      text: "Excellent!",
      icon: "check_circle"
    }
  ]

  if (theScore >= breakpoints[0]) return colorsMatrix[4]
  if (theScore >= breakpoints[1]) return colorsMatrix[3]
  if (theScore >= breakpoints[2]) return colorsMatrix[2]
  if (theScore >= breakpoints[3]) return colorsMatrix[1]
  return colorsMatrix[0]
}

export const ScoreLabel = ({
  score,
  showIcon,
  fontSize = 4,
  fontWeight = 500,
  breakpoints,
  indicator = false,
  ...props
}) => {
  const labelData = indicator
    ? getIndicatorLabelData(score, breakpoints)
    : getLabelData(score, breakpoints)
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" {...props}>
      {showIcon && (
        <Icon
          name={labelData.icon}
          color={labelData.color}
          mr={0}
          width="auto"
        />
      )}
      <Text
        color={labelData.color}
        pl={1}
        fontWeight={fontWeight}
        width="auto"
        fontSize={fontSize}
      >
        {labelData.text}
      </Text>
    </Flex>
  )
}
