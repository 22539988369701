import { Redirect, usePaths } from "@engaging-tech/routing"
import React from "react"
import { connect } from "react-redux"
import { UISelectors } from "../../ui"
import {
  getHasOrganisations,
  getIsAuthenticated
} from "../store/account.selectors"

const Guard = ({ isAuthenticated, hasOrganisations, isReady, children }) => {
  const paths = usePaths()
  let desiredLocation = ""

  if (!isReady) {
    return null
  }
  if (typeof window !== "undefined") {
    desiredLocation = window.location.pathname + window.location.search
  }
  if (!isAuthenticated || (isAuthenticated && !hasOrganisations)) {
    const checkDesiredLocation = () => {
      if (desiredLocation) {
        return `?desiredLocation=${desiredLocation}`
      }
      return ""
    }
    return <Redirect to={`${paths.account.signIn}${checkDesiredLocation()}`} />
  }

  if (isAuthenticated && hasOrganisations) {
    return children
  }

  return null
}

const mapState = state => ({
  isAuthenticated: getIsAuthenticated(state),
  hasOrganisations: getHasOrganisations(state),
  isReady: UISelectors.getIsReady(state)
})

const OrgGuard = connect(mapState)(Guard)

export default OrgGuard
