import { connect } from "react-redux"
import { AccountSelectors } from "../../account"
import CreateForm from "../components/CreateForm"

const mapState = state => ({
  orgId: AccountSelectors.getOrgId(state)
})

const CreateEventContainer = connect(mapState, null)(CreateForm)

export default CreateEventContainer
