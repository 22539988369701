import { Modal, Flex, Icon } from "@engaging-tech/components"
import { CircleButton } from "@engaging-tech/components/dist/lib/IconButton/IconButton"
import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const Image = styled.img`
  width: 100%;
`

const CustomModal = styled(Modal)`
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ShowPreview = ({ setShowPreview, link }) => {
  return createPortal(
    <CustomModal
      bg="secondary.0"
      maxWidth={["560px", "560px", "768px"]}
      px={[0, 0, "7%"]}
      pt={[0, 0, "30px"]}
      position="relative"
      width={1 / 1}
    >
      <Flex
        position="fixed"
        right="0"
        width={1 / 1}
        top="10px"
        justifyContent="flex-end"
        pr={5}
      >
        <CircleButton size={32} onClick={() => setShowPreview(false)}>
          <Icon name="close" size={32} color="secondary.0" />
        </CircleButton>
      </Flex>
      <Image src={link} position="relative" />
    </CustomModal>,
    appRoot()
  )
}

export default ShowPreview
