import { createSelector } from "reselect"

import { ReportSelectors } from "../.."
import * as SixStepOverviewSelectors from "../../SixStepsOverview/store/sixstepsoverview.selectors"

const pipePassedInState = state => state
export const info = {
  reward_recognition: { sectionTitle: "Reward & Recognition", icon: "stars" },
  information_sharing: {
    sectionTitle: "Information Sharing",
    icon: "record_voice_over"
  },
  empowerment: {
    sectionTitle: "Empowerment",
    icon: "accessibility_new"
  },
  well_being: { sectionTitle: "Wellbeing", icon: "tag_faces" },
  instilling_pride: { sectionTitle: "Instilling Pride", icon: "thumb_up" },
  job_satisfaction: {
    sectionTitle: "Job Satisfaction",
    icon: "sentiment_satisfied"
  }
}

// RAG - UPDATE - TEST
export const getSixSteps = createSelector(
  [ReportSelectors.getReportData, pipePassedInState],
  (data, state) => {
    return (
      data &&
      data.surveySixSteps &&
      data.surveySixSteps.map(step => {
        const newStep = { ...step }
        newStep.info = info[step.name]
        newStep.industry = SixStepOverviewSelectors.getCurrentStepIndustry(
          state,
          step.name
        )
        newStep.score = step.score
        newStep.trend = null
        return newStep
      })
    )
  }
)
