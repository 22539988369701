import React from "react"
import { createPortal } from "react-dom"
import { Flex } from "@engaging-tech/components"
import ReportElementsLayout from "./ReportElementsLayout"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const IsolatedPortal = ({ children }) => {
  return createPortal(
    <Flex
      minWidth={1000}
      minHeight={700}
      position="relative"
      zIndex={1000}
      flexDirection="column"
    >
      {children}
    </Flex>,
    appRoot()
  )
}

const ExternalReportItemsRender = ({ reportComponents }) => {
  return (
    <IsolatedPortal>
      <ReportElementsLayout componentTags={reportComponents} exportingPDF />
    </IsolatedPortal>
  )
}

export default ExternalReportItemsRender
