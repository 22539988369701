import { Flex, Modal } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"

import UPDATE_SURVEY_ACTION_PLAN from "../../../../graphql/mutations/updateSurveyActionPlan"
import SEARCH_ACTION_PLAN_SURVEY from "../../../../graphql/queries/searchActionPlanSurvey"
import useAppSyncMutation from "../../../../hooks/useAppSyncMutation"
import useAppSyncQuery from "../../../../hooks/useAppSyncQuery"
import AssignSurveysBody from "./AssignSurveysBody"
import AssignSurveysHeader from "./AssignSurveysHeader"
import AssignSurveysSearch from "./AssignSurveysSearch"

export const filterList = [
  { label: "All Surveys", value: "ALL" },
  { label: "Assigned", value: "ASSIGNED" },
  { label: "Not Assigned", value: "AVAILABLE" },
  { label: "Unavailable", value: "UNAVAILABLE" }
]

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const AssignSurveys = ({ setOpenModal, actionPlanId, isDefault }) => {
  const limit = 20
  const [keywords, setKeywords] = useState("")
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState(filterList[0].value)
  const [surveys, setSurveys] = useState([])

  const {
    errors: updateErrors,
    data: updateData,
    post
  } = useAppSyncMutation(UPDATE_SURVEY_ACTION_PLAN)

  const { isLoading, errors, data, getData } = useAppSyncQuery(
    SEARCH_ACTION_PLAN_SURVEY,
    {
      variables: {
        input: {
          keywords,
          actionPlanId,
          page,
          filters,
          limit
        }
      },
      reRender: [page, filters]
    }
  )

  useEffect(() => {
    const res = data?.SearchActionPlanSurvey?.surveys || []

    if (!surveys?.length) {
      setSurveys(res)
    } else {
      setSurveys([...surveys, ...res])
    }
  }, [data])

  const handleSearch = () => {
    setSurveys([])
    if (page !== 1) {
      setPage(1)
    } else {
      setPage(1)
      getData()
    }
  }

  const handleFilters = value => {
    setSurveys([])
    setFilters(value)
    setPage(1)
  }

  const removeLoading = (surveyIdx, status) => {
    setSurveys(
      surveys.toSpliced(surveyIdx, 1, {
        ...surveys[surveyIdx],
        loading: false,
        status
      })
    )
  }

  const handleChange = e => {
    const surveyIdx = surveys.findIndex(
      ({ survey_id }) => survey_id === e.target.id
    )
    const survey = surveys[surveyIdx]

    const newInput = {
      ...surveys,
      loading: true
    }

    if (survey.status === filterList[1].value) {
      setSurveys(surveys.toSpliced(surveyIdx, 1, newInput))

      post(
        {
          input: {
            surveyId: survey.survey_id,
            action: "remove",
            actionPlanId
          }
        },
        () => removeLoading(surveyIdx, filterList[2].value)
      )
    }

    if (survey.status === filterList[2].value) {
      setSurveys(surveys.toSpliced(surveyIdx, 1, newInput))
      post(
        {
          input: {
            surveyId: survey.survey_id,
            action: "assign",
            actionPlanId
          }
        },
        () => removeLoading(surveyIdx, filterList[1].value)
      )
    }
  }

  const removeLoadingFromMany = (compareValue, addValue) => {
    setSurveys(
      surveys.map(survey => ({
        ...survey,
        loading: false,
        status: survey.status === compareValue ? addValue : survey.status
      }))
    )
  }

  const handleSelectAll = () => {
    setSurveys(
      surveys.map(survey => ({
        ...survey,
        loading: survey.status === filterList[2].value && true
      }))
    )

    post({ input: { actionPlanId, action: "assignAll" } }, () =>
      removeLoadingFromMany(filterList[2].value, filterList[1].value)
    )
  }

  const handleRemoveAll = () => {
    setSurveys(
      surveys.map(survey => ({
        ...survey,
        loading: survey.status === filterList[1].value && true
      }))
    )

    post({ input: { actionPlanId, action: "removeAll" } }, () =>
      removeLoadingFromMany(filterList[1].value, filterList[2].value)
    )
  }

  return createPortal(
    <Modal onToggle={setOpenModal} maxWidth="900px" width={1 / 1}>
      <Flex flexDirection="column" width={1 / 1} bg="light.0">
        <AssignSurveysHeader setOpenModal={setOpenModal} />
        <Flex
          p={4}
          bg="light.0"
          width={1 / 1}
          flexDirection="column"
          alignItems="flex-end"
        >
          <AssignSurveysSearch
            setKeywords={setKeywords}
            keywords={keywords}
            handleSearch={handleSearch}
            handleFilters={handleFilters}
          />
          <AssignSurveysBody
            isDefault={isDefault}
            setPage={setPage}
            data={surveys}
            isLoading={isLoading}
            errors={errors}
            updateErrors={
              updateErrors ||
              (updateData?.updateSurveyActionPlan?.statusCode !== "200" &&
                updateData?.updateSurveyActionPlan?.statusCode)
            }
            limit={limit}
            currentPage={data?.SearchActionPlanSurvey?.currentPage}
            count={data?.SearchActionPlanSurvey?.count}
            totalPages={data?.SearchActionPlanSurvey?.totalPages}
            handleChange={handleChange}
            handleSelectAll={handleSelectAll}
            surveys={surveys}
            handleRemoveAll={handleRemoveAll}
          />
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default AssignSurveys
