import { usePaths } from "@engaging-tech/routing"
import React from "react"
import JoinJobsComponent from "../components/JoinJobs"
import EBNBillingWrapper from "../containers/EBNBillingWrapper"

const JoinJobs = () => {
  const paths = usePaths()
  return (
    <EBNBillingWrapper cancelRoute={paths.home.index}>
      {props => <JoinJobsComponent {...props} />}
    </EBNBillingWrapper>
  )
}

export default JoinJobs
