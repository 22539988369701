import React, { useState } from "react"
import { withSize } from "react-sizeme"
import { Flex, MotionFlex, Text } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"

import layoutConfig from "../lib/layoutConfig"

const getText = step => {
  switch (step) {
    case 1: {
      return "Create a template"
    }
    case 2: {
      return "Create a survey"
    }
    case 3: {
      return "Review & pay"
    }
    default:
      return ""
  }
}

const SizeAwareText = withSize({ monitorHeight: true, monitorWidth: false })(
  ({ selectedStep }) => {
    return (
      <MotionFlex
        key={selectedStep}
        position="absolute"
        initial={{ x: 400, opacity: 0, transition: { duration: 0.4 } }}
        animate={{
          x: 0,
          opacity: 1,
          transition: { delay: 0.2, duration: 0.4 }
        }}
        exit={{ x: -400, opacity: 0, transition: { duration: 0.4 } }}
      >
        <Text fontSize={layoutConfig.headerTextSize} fontWeight={700}>
          {getText(selectedStep)}
        </Text>
      </MotionFlex>
    )
  }
)

const Title = ({ selectedStep }) => {
  const [size, setSize] = useState({ height: 80 })
  return (
    <Flex
      width="calc(100% - 20px)"
      height={size.height}
      alignItems="flex-start"
      pl={10}
      mt={20}
    >
      <AnimatePresence>
        <SizeAwareText
          key={selectedStep}
          selectedStep={selectedStep}
          onSize={setSize}
        />
      </AnimatePresence>
    </Flex>
  )
}

export default Title
