import { connect } from "react-redux"
import SummaryBoxComponent from "../components/SummaryBox"
import * as Selectors from "../store/sixstepsoverview.selectors"

const mapState = (state, { step }) => ({
  questions: Selectors.getQuestionsForStep(state, step)
})

const SummaryBox = connect(mapState)(SummaryBoxComponent)

export default SummaryBox
