import { Flex } from "@engaging-tech/components"
import styled from "styled-components"

const ValueBadge = styled(Flex)`
  background: ${({ theme }) => theme.colors.dark[4]};
  font-size: 14px;
  border-radius: 5px;
  min-width: ${({ minWidth }) => minWidth || 192}px;
  width: auto;
  padding: 2px 8px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin: 4px;
`

export default ValueBadge
