import Checkbox from "@engaging-tech/components/dist/lib/Checkbox/Checkbox"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import React from "react"

const ReportCheckbox = ({ id, isChecked, onChange, labelText }) => {
  return (
    <Flex width={1 / 1} justifyContent="center" flexDirection="column">
      <Text fontSize={4} color="primary.0" pb={2}>
        {labelText}
      </Text>
      <Checkbox
        id={id}
        ml={3}
        width={[1 / 10, 1 / 10, 1 / 30]}
        checked={isChecked}
        pb={2}
        onChange={onChange}
      />
    </Flex>
  )
}

export default ReportCheckbox
