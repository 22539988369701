import { Card, Flex, H2, H3, ImageIcon, Text } from "@engaging-tech/components"
import React from "react"

const surveyOnSurvey1 = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/SurveyOnSurvey1.svg`)

const Explanation = () => {
  return (
    <Flex flexDirection="column" width={1 / 1}>
      <H2 width={1 / 1} fontSize={[5, 6]} fontWeight="600">
        Survey on Survey Comparison
      </H2>
      <Text
        width={1 / 1}
        fontSize={[4, 5]}
        my={2}
        fontWeight="600"
        color="dark.2"
      >
        Compare Your Survey Results to Previous Results
      </Text>
      <Text width={1 / 1}>Explore the data and results from your surveys.</Text>
      <Card
        width={1 / 1}
        py={20}
        my={20}
        height="inherit"
        flexWrap="wrap"
        flexDirection="row"
      >
        <ImageIcon src={surveyOnSurvey1} width={[1 / 10, 1 / 20]} />
        <Flex flexDirection="column" width={[8 / 10, 18 / 20]}>
          <H3 width={1 / 1} fontSize={[3, 4]} fontWeight="600" mb={2}>
            How to use this feature:
          </H3>
          <Text width={1 / 1}>
            In the Survey Results Comparison panel below, you can select survey
            types (happiness surveys, etc.) and survey sub-categories, such as
            six steps to Workplace Happiness, to compare your past and present
            survey data.
          </Text>
        </Flex>
      </Card>
    </Flex>
  )
}

export default Explanation
