import React from "react"
import { H2, Text } from "@engaging-tech/components"

const FormTitle = ({ text, children }) => (
  <>
    <H2
      fontWeight={700}
      width="auto"
      fontSize={[5, 6]}
      pb={3}
      pt={[5, 0]}
    >
      {children}
    </H2>
    {text && (
      <Text color="dark.2" fontSize={3} pb={5}>
        {text}
      </Text>
    )}
  </>
)

export default FormTitle
