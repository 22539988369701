import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import EBNCard from "../../ui/components/EBNCard"
import WebinarsContainer from "../containers/Webinars"

const Webinars = () => {
  const router = useRouter()
  const paths = usePaths()

  const handleSeeAll = () => router.redirect(paths.home.webinars)

  return (
    <EBNCard
      title="Recent Webinars and Podcasts"
      height="100%"
      mb={5}
      isSubscribed
      showSeeAll
      seeAllAction={handleSeeAll}
    >
      <WebinarsContainer />
    </EBNCard>
  )
}
export default Webinars
