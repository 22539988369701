import { useLocation } from "@engaging-tech/routing"
import React from "react"
import { useHistory } from "react-router-dom"

export const BIPNavigationContext = React.createContext({
  activeTab: "business-information",
  onChangeTab: () => {},
  onPublish: () => {}
})

/**
 * BIPNavigationContextProvider component
 * @param {Object} props - The props object
 * @param {React.ReactNode} props.children - The child nodes
 */
export default function BIPNavigationContextProvider({ children }) {
  const history = useHistory()
  const location = useLocation()

  let activeTab = ""
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    activeTab = urlParams.get("activeTab") || "business-information"
  }

  /**
   * Change the active tab
   * @param {('business-information'|'your-culture'|'review'|'finish')} value - The new active tab
   */
  const onChangeTab = value => {
    history.push({
      pathname: location.pathname,
      search: `?activeTab=${value}`
    })
  }

  /**
   * Get BIP data
   */

  /**
   * Insert/Update BIP data
   */
  const onPublish = () => {
    onChangeTab("finish")
  }

  return (
    <BIPNavigationContext.Provider value={{ activeTab, onChangeTab, onPublish }}>
      {children}
    </BIPNavigationContext.Provider>
  )
}
