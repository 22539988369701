import React from "react"
import { Button, Flex, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"

const TemplateHeader = ({ viewOnly, templateId }) => {
  const paths = usePaths()
  const router = useRouter()
  const {
    telegraphTemplateId,
    starterTemplateId,
    exitTemplateId
  } = getConfig().other

  return (
    <Flex width={1 / 1} alignItems="center">
      <Text>
        Create a customised template to use for your survey. Templates include
        all of the questions you want to ask and the order which they appear
      </Text>
      {telegraphTemplateId !== templateId &&
        viewOnly &&
        starterTemplateId !== templateId &&
        exitTemplateId !== templateId && (
          <Button
            width="200px"
            ml={3}
            variant="primary.0"
            color="light.0"
            trailingIcon="content_copy"
            onClick={() => {
              router.navigate(
                `${paths.surveys.buildATemplate.cloneTemplate.replace(
                  ":id",
                  templateId
                )}`
              )
            }}
          >
            <Text
              color="light.0"
              fontSize="14px"
              style={{ textTransform: "none" }}
            >
              Clone template
            </Text>
          </Button>
        )}
    </Flex>
  )
}
export default TemplateHeader
