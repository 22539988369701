import React from "react"
import { Box, Text } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { Link } from "@engaging-tech/routing"

const Disclaimer = () => {
  const config = getConfig()
  return (
    <Box mb={3}>
      <Text fontSize={3} color="dark.2">
        Your event will be shared on the{" "}
        <Link to={`${config.workL.app}/global-hub`} external newTab>
          Global Hub
        </Link>{" "}
        so you can promote it to potential attendees
      </Text>
    </Box>
  )
}

export default Disclaimer
