import { Flex, IconButton, Text } from "@engaging-tech/components"
import React from "react"

export const tableHeadings = [
  {
    id: "title",
    title: (
      <Flex alignItems="center">
        <Text color="dark.1" fontSize={[2, 3]}>
          Survey Name
        </Text>
        <IconButton
          icon="sort"
          size={24}
          iconColor="dark.1"
          id="assigned-to-sort-name"
          hoverColor="light.0"
        />
      </Flex>
    )
  },
  {
    id: "createdAt",
    title: (
      <Flex alignItems="center">
        <Text color="dark.1" fontSize={[2, 3]}>
          Date
        </Text>
        <IconButton
          icon="sort"
          size={24}
          iconColor="dark.1"
          id="assigned-to-sort-date"
          hoverColor="light.0"
        />
      </Flex>
    )
  }
]

export const tableConfig = {
  stylesPerColumn: [
    {
      height: "45px",
      px: "15px"
    },
    {
      height: "45px",
      pr: "15px"
    }
  ],
  columnWidths: ["auto", "100px"],
  main: {
    textAlign: "left"
  },
  headingPerColumn: [
    {
      py: "15px",
      px: "10px"
    },
    {
      py: "15px",
      pr: "10px",
      fontSize: [2, 3],
      fontWeight: 800,
      color: "dark.1"
    }
  ],
  data: {
    borderTop: "1px solid",
    borderColor: "dark.4"
  }
}
