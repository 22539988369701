import { createSelector } from "reselect"

import { ReportSelectors } from "../.."
import { useCheckIfGlobalTabIsSelected } from "../../Heatmaps/components/HeatMapTabs/useCheckIfGlobalTabIsSelected"

export const getFilters = createSelector(
  ReportSelectors.getSurveyReportState,
  state => state.filters
)
export const getDirectReports = createSelector(
  ReportSelectors.getSurveyReportState,
  state => state.directReports
)

export const getIsCurrentDirectReports = createSelector(
  ReportSelectors.getSurveyReportState,
  state => !!state.isCurrentDirectReports
)

export const getIsDirectReportsAvailable = createSelector(
  ReportSelectors.getReportResponse,
  state => state.directReportsAvailable
)

export const getActiveFilterList = createSelector(getFilters, filters =>
  filters.reduce(
    (acc, { questionId, values }) => [
      ...acc,
      ...values.map(value => ({ questionId, value: value.valueId }))
    ],
    []
  )
)

export const getFormFilters = createSelector(
  [getFilters, getIsCurrentDirectReports],
  (filters, directReports) =>
    filters &&
    filters.reduce(
      (acc, entry) => {
        return {
          ...acc,
          [entry.questionId]: entry.values.map(({ valueId }) => valueId)
        }
      },
      { directReports }
    )
)

export const getAvailableFilters = createSelector(
  ReportSelectors.getReportResponse,
  report => report?.availableFilters
)

export const getCurrentFilters = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.filters?.applied
)

export const getRequiredFilters = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.filters?.required
)

export const getAvailableFiltersForHeatmap = createSelector(
  [
    ReportSelectors.getReportResponse,
    ReportSelectors.getHeatmapTabSelected,
    ReportSelectors.getComparisonReport
  ],
  (report, tabSelected, comparisonReport) => {
    const showGlobalResult = useCheckIfGlobalTabIsSelected(tabSelected)

    if (showGlobalResult && report?.availableFilters) {
      return report?.availableFilters.filter(item =>
        ["22", "23", "25", "27", "29", "34"].includes(item.questionId)
      )
    }

    if (tabSelected === "PREVIOUS") {
      return report?.availableFilters.filter(({ questionId }) =>
        comparisonReport?.availableFilters
          .map(({ questionId }) => questionId)
          .includes(questionId)
      )
    }

    return report?.availableFilters
  }
)
