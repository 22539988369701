import React, { useContext } from "react"
import { Text, Flex, Button, Icon, Box } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import styled from "styled-components"
import { getConfig } from "@engaging-tech/ssr-config"

import Dialog from "../components/Dialog"
import PostJobContext from "../lib/postJobContext"

const BorderBottomBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[4]};
  margin-bottom: 25px;
  width: 100%;
`

const CompleteDialog = ({ clearJobData, postedJob }) => {
  const { cancel, goToFirstStep } = useContext(PostJobContext)

  const exit = () => {
    clearJobData()
    cancel()
  }

  const restart = () => {
    clearJobData()
    goToFirstStep()
  }

  // We nee to recieve a job ID on success
  const jobLink = postedJob?.jobId
    ? `${getConfig().workL.app}/find-work/jobs/${postedJob?.jobId}`
    : `${getConfig().workL.app}/find-work/`

  return (
    <Dialog bg="dark.2" fontWeight={700} title="Job Posted">
      <BorderBottomBox />
      <Text textAlign="center" mb={3} mt={1}>
        Thank you for posting a job!
      </Text>
      {["PUBLISHED_MARKETPLACE, PUBLISHED_MARKETPLACE_MATCHER"].includes(
        postedJob?.status
      ) && (
        <Flex>
          <Icon mr={2} name="check" />
          <Flex flexDirection="column">
            <Text fontWeight={500} mb={1}>
              Jobs Marketplace
            </Text>
            <Text mb={3}>
              Your job post is now visible on the WorkL{" "}
              <Link to={jobLink} newTab external>
                <Text fontSize={3} fontWeight={600} color="primary.0">
                  Jobs Marketplace
                </Text>
              </Link>
              .
            </Text>
          </Flex>
        </Flex>
      )}

      {["PUBLISHED_MATCHER, PUBLISHED_MARKETPLACE_MATCHER"].includes(
        postedJob?.status
      ) && (
        <Flex>
          <Icon mr={2} name="check" />
          <Flex flexDirection="column">
            <Text fontWeight={500} mb={1}>
              Jobs Matcher
            </Text>
            <Text mb={3}>
              We will now match your candidate requirements against our database
              and provide you with a shortlist of candidates. Please note: this
              may take up to X days.
            </Text>
          </Flex>
        </Flex>
      )}

      <Flex flexDirection="row" justifyContent="space-between" mt={3}>
        <Button
          variant="text.primary.0"
          width="auto"
          elevation={0}
          onClick={exit}
        >
          Back to Jobs
        </Button>
        <Button onClick={restart} width="auto" variant="primary.0" ml={3}>
          Post another job
        </Button>
      </Flex>
    </Dialog>
  )
}

export default CompleteDialog
