import React from "react"
import { H2, Paragraph, Card } from "@engaging-tech/components"

const ReportItem = ({
  heading,
  subHeading,
  children,
  width = "inherit",
  height = "auto",
  mb = 5
}) => (
  <Card elevation={0} alignItems="center" width={width} height={height}>
    {heading && (
      <H2 fontSize={5} fontWeight={500} mb={!subHeading ? mb : 2}>
        {heading}
      </H2>
    )}
    {subHeading && (
      <Paragraph fontSize={3} color="dark.2">
        {subHeading}
      </Paragraph>
    )}
    {children}
  </Card>
)

export default ReportItem
