import React from "react"
import JoinEventsView from "../../features/account/views/JoinEvents"
import GradientLayout from "../../layouts/GradientLayout"

const JoinEvents = () => {
  return (
    <GradientLayout>
      <JoinEventsView />
    </GradientLayout>
  )
}

export default JoinEvents
