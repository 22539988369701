import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from "redux-saga/effects"

import {
  createLatestReport,
  deleteReports,
  generateReport,
  getSurveyComparisonData,
  load,
  loadReports
} from "../services/report.service"
import * as Actions from "./report.actions"
import { getComparisonSurveyId } from "./report.selectors"

const RETRY_TIMEOUT = 2000

export function* getSurveyComparisonDataSaga({ payload }) {
  try {
    const res = yield call(getSurveyComparisonData, payload)

    if (res)
      yield put(
        Actions.getSurveyComparisonDataSuccess(res.data.getSurveyComparisonData)
      )
  } catch (err) {
    yield put(Actions.getSurveyComparisonDataFail(err))
  }
}

export function* loadReportsSaga({ payload }) {
  try {
    const res = yield call(loadReports, payload)
    const dataObj = res.data[Object.keys(res.data)]

    if (!dataObj.length) {
      yield put(Actions.emptyReportList())
    }

    if (dataObj) {
      yield put(Actions.loadReportsSuccess(dataObj))
    }
  } catch (err) {
    yield put(Actions.loadReportsFail(err))
  }
}

export function* generateReportSaga({ payload }) {
  try {
    const res = yield call(generateReport, payload)
    if (res.data.createEmployeeReport.id) {
      yield put(Actions.generateReportSuccess(res.data.createEmployeeReport.id))
    }
  } catch (error) {
    yield put(Actions.generateReportFail(error))
  }
}

export function* deleteReportsSaga({ payload }) {
  try {
    const res = yield call(deleteReports, payload)
    const {
      data: { deleteEmployeeReports }
    } = res

    if (deleteEmployeeReports) {
      yield put(Actions.deleteReportsSuccess())
    }
  } catch (err) {
    yield put(Actions.deleteReportsFail(err))
  }
}

export function* loadAllBusinessAreasSaga(
  action,
  retryTimeout = RETRY_TIMEOUT
) {
  // Keeping this seperate from the loadSaga for now.

  const res = yield call(load, action.payload)

  const {
    errors,
    data: { getSurveyReport: report }
  } = res
  if (errors) {
    let code = null
    if (errors[0].extensions.downstreamErrors) {
      const {
        extensions: { downstreamErrors }
      } = errors[0]
      code = downstreamErrors[0].extensions.code
    } else {
      code = errors[0].extensions.code
    }
    yield put(Actions.handleAllBusinessAreasSurveyErrors(code, report))
  } else if (report?.status === "processed") {
    yield put(Actions.loadAllBusinessAreasSuccess(report))
  } else {
    yield delay(retryTimeout)
    yield put(
      Actions.retryLoadAllBusinessAreas({ payload: action.payload, report })
    )
  }
}
export function* loadFilterDataSaga(action, retryTimeout = RETRY_TIMEOUT) {
  // Keeping this seperate from the loadSaga for now.
  const comparisonSurveyId = yield select(getComparisonSurveyId)

  if (comparisonSurveyId) {
    const comparisonPayload = {
      surveyId: comparisonSurveyId,
      directReports: action.payload.directReports,
      filters: action.payload.filters,
      surveyType: action.payload.surveyType
    }
    const {
      errors,
      data: { getSurveyReport: report }
    } = yield call(load, comparisonPayload)

    if (errors) yield put(Actions.LoadHeatmapComparisonFail())
    if (report?.status === "processed")
      yield put(
        Actions.LoadHeatmapComparisonSuccess(report, action.payload.filterId)
      )
  }

  const res = yield call(load, action.payload)
  const {
    errors,
    data: { getSurveyReport: report }
  } = res
  if (errors) {
    let code = null
    if (errors[0].extensions.downstreamErrors) {
      const {
        extensions: { downstreamErrors }
      } = errors[0]
      code = downstreamErrors[0].extensions.code
    } else {
      code = errors[0].extensions.code
    }
    yield put(
      Actions.handleLoadFilterDataErrors(code, report, action.payload.filterId)
    )
  } else if (report?.status === "processed") {
    yield put(Actions.loadFilterDataSuccess(report, action.payload.filterId))
  } else {
    yield delay(retryTimeout)
    yield put(Actions.retryloadFilterData({ payload: action.payload, report }))
  }
}

export function* loadSaga(action, retryTimeout = RETRY_TIMEOUT) {
  // not using a try catch here so we can capture the error and the returned data

  const res = yield call(load, action.payload)
  const {
    errors,
    data: { getSurveyReport: report }
  } = res

  if (errors && !action.payload?.comparison) {
    let code = null
    if (errors[0].extensions.downstreamErrors) {
      const {
        extensions: { downstreamErrors }
      } = errors[0]
      code = downstreamErrors[0].extensions.code
    } else {
      code = errors[0].extensions.code
    }
    yield put(Actions.handleSurveyErrors(code, report)) // if permission errors we want to still load the rest of survey without report
  } else if (errors && action.payload.comparison) {
    yield put(Actions.comparisonFail)
  } else if (report?.status === "processed" && !action.payload?.comparison) {
    yield put(Actions.loadSuccess(report))
  } else if (report?.status === "processed" && action.payload.comparison) {
    yield put(Actions.comparisonSuccess(report))
  } else if (!action.payload?.comparison) {
    yield delay(retryTimeout)
    yield put(Actions.retryLoad({ payload: action.payload, report }))
  }
}

export function* createLatestReportSaga({ payload }) {
  try {
    const res = yield call(createLatestReport, payload)

    if (res.data.createLatestReport.id) {
      const link = res.data.createLatestReport.id

      yield put(Actions.createLatestReportSuccess(link))
    }
  } catch (error) {
    yield put(Actions.createLatestReportFail(error))
  }
}

export function* saga() {
  yield takeLatest(
    Actions.types.GET_SURVEY_COMPARISON_DATA,
    getSurveyComparisonDataSaga
  )
  yield takeLatest(Actions.types.LOAD_REPORTS, loadReportsSaga)
  yield takeLatest(Actions.types.GENERATE_REPORT, generateReportSaga)
  yield takeLatest(Actions.types.DELETE_REPORTS, deleteReportsSaga)
  yield takeLatest(
    [
      Actions.types.LOAD,
      Actions.types.RETRY_LOAD,
      Actions.types.LOAD_COMPARISON
    ],
    loadSaga
  )
  yield takeLatest(
    [
      Actions.types.LOAD_ALL_BUSINESS_AREAS,
      Actions.types.RETRY_LOAD_ALL_BUSINESS_AREAS
    ],
    loadAllBusinessAreasSaga
  )
  yield takeEvery(
    [
      Actions.types.LOAD_HEATMAP_FILTER_DATA,
      Actions.types.RETRY_LOAD_HEATMAP_FILTER_DATA
    ],
    loadFilterDataSaga
  )
  yield takeLatest(Actions.types.CREATE_LATEST_REPORT, createLatestReportSaga)
}
