import React, { useState } from "react"
import { Text, Icon, MotionFlex, CustomHooks } from "@engaging-tech/components"

const AddOptionButton = ({ onClick, label }) => {
  const [addNewOptionHovered, setAddNewOptionHovered] = useState(false)
  const device = CustomHooks.useClientDevice()

  const iconVariants = {
    unActive: { rotate: 0 },
    active: { rotate: 90 }
  }

  return (
    <MotionFlex
      className="edit-question-add-option-button"
      alignItems="center"
      width="auto"
      style={{ cursor: addNewOptionHovered ? "pointer" : "initial" }}
      onHoverStart={() => setAddNewOptionHovered(true)}
      onHoverEnd={() => setAddNewOptionHovered(false)}
      onTap={onClick}
      justifyContent="space-between"
      pl="1px"
    >
      <MotionFlex
        justifyContent="center"
        alignItems="center"
        height={27}
        width={27}
        variants={iconVariants}
        animate={addNewOptionHovered ? "active" : "unActive"}
        initial="unActive"
      >
        <Icon name="control_point" size={27} />
      </MotionFlex>

      <Text width="auto" noSelect ml={2}>
        {device === "MOBILE" ? "" : label}
      </Text>
    </MotionFlex>
  )
}

export default AddOptionButton
