import React from "react"
import styled from "styled-components"
import { TextField, Flex, CustomHooks } from "@engaging-tech/components"

import OutlineButton from "./OutlineButton"
import useDebouncedInput from "../../../../hooks/useDebouncedInput"

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.light[0]};
`

const Wrapper = styled(Flex)`
  flex-shrink: 0;
`
const SearchBar = ({
  placeholder,
  searchTerm = "",
  setSearchTerm,
  ...props
}) => {
  const [debouncedInputText, setDebouncedInputText] = useDebouncedInput(
    searchTerm,
    setSearchTerm,
    500
  )
  const device = CustomHooks.useClientDevice()

  const handleChange = e => {
    setDebouncedInputText(e.target.value)
  }

  const handleReset = () => {
    setDebouncedInputText("")
  }

  return (
    <Wrapper alignItems="center" justifyContent="flex-start" {...props}>
      <StyledTextField
        id="searchTextField"
        noLabel
        height={40}
        border="1px solid rgb(1, 62, 76)"
        borderRadius={20}
        iconStyle={{
          color: debouncedInputText ? "primary.0" : "dark.3",
          width: "20px"
        }}
        fontSize={16}
        iconName="search"
        noHighlight
        onChange={handleChange}
        value={debouncedInputText}
        placeholder={placeholder}
      />
      <OutlineButton
        ml={3}
        text={device === "MOBILE" ? " X " : "Clear Search"}
        onClick={handleReset}
        width="auto"
        height={40}
      />
    </Wrapper>
  )
}

export default SearchBar
