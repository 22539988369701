import { Button, Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { usePaths } from "@engaging-tech/routing"
import styled from "styled-components"
import CheckboxList from "../SurveyComparison/components/CheckboxList"
import Explanation from "../SurveyComparison/components/Explanation"
import BackButton from "../../ui/components/BackButton"
import { subTypesList, surveyTypes } from "../../../lib/surveyTypes"
import ComparisonViewWrapper from "../SurveyComparison/components/ComparisonViewWrapper"
import ComparisonViewContainer from "../SurveyComparison/containers/ComparisonViewContainer"
import BreadcrumbsContainer from "../SurveyComparison/containers/BreadcrumbsContainer"

const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.4;
  }
`

const SurveyComparisonView = ({
  stage,
  checkedList,
  setStage,
  setBreadcrumbs,
  setCheckedList,
  getSurveyComparisonData,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  subCheckedList,
  setSubCheckedList
}) => {
  const paths = usePaths()

  const resetState = () => {
    setBreadcrumbs(["Select Survey Type"])
    setStage(0)
    setSubCheckedList(["1"])
    setCheckedList([])
    setStartDate(new Date(1514764800000))
    setEndDate(new Date())
  }

  useEffect(() => {
    resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetComparisonData = (newStage = false) => {
    const surveyType = checkedList.map(id => surveyTypes[id].surveyType)
    const subTypes = subCheckedList.map(id => subTypesList[id].subType)
    getSurveyComparisonData({
      surveyType,
      startDate,
      endDate,
      subTypes
    })

    if (newStage) setStage(newStage)
  }

  return (
    <Flex flexDirection="column" width={1 / 1} ml={[1, 0]}>
      <BackButton
        path={paths.reports.engagementSurveys.index}
        mb={20}
        onClick={() => resetState()}
      >
        Back
      </BackButton>
      <Explanation />
      <Flex width={1 / 1} height="auto" flexDirection="column">
        <BreadcrumbsContainer />
        {stage === 0 && (
          <ComparisonViewWrapper
            title="Survey Results Comparison"
            subheading="Select which survey results you would like to compare"
          >
            <CheckboxList
              itemList={surveyTypes}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            >
              <StyledButton
                mt={[20, 0]}
                alignSelf={["flex-start", "flex-end"]}
                bg="primary.0"
                color="light.0"
                width="170px"
                onClick={() => handleGetComparisonData(1)}
                disabled={!checkedList.length}
              >
                Next
              </StyledButton>
            </CheckboxList>
          </ComparisonViewWrapper>
        )}
        {stage === 1 && (
          <ComparisonViewWrapper
            title="Survey Results Comparison"
            subheading=" Compare the results of your survey scores as they have changed over
            time. Click REFINE RESULTS to examine your survey scores in more
            detail."
          >
            <ComparisonViewContainer
              handleGetComparisonData={handleGetComparisonData}
            />
          </ComparisonViewWrapper>
        )}
      </Flex>
    </Flex>
  )
}

export default SurveyComparisonView
