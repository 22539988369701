import React from "react"
import { Flex } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import Breakdowns from "../containers/Breakdowns"

const SixStepsBreakdown = ({ ...props }) => {
  const { step } = useParams()
  return (
    <Flex
      alignItems="stretch"
      justifyContent="space-between"
      flexDirection={["column", "column", "row"]}
    >
      <Breakdowns step={step} {...props} />
    </Flex>
  )
}

export default SixStepsBreakdown
