import { createSelector } from "reselect"

export const surveyInfoReview = state =>
  state && state["surveyManagement.surveyInfoReview"]

export const getIntentSecret = createSelector(
  surveyInfoReview,
  state => state?.intentSecret
)

export const getPaymentStatus = createSelector(
  surveyInfoReview,
  state => state?.paymentStatus
)

export const getHasError = createSelector(
  surveyInfoReview,
  state => state?.hasError
)

export const getIsLoading = createSelector(
  surveyInfoReview,
  state => state?.isLoading
)

export const getIsPaying = createSelector(
  surveyInfoReview,
  state => state?.isPaying
)

export const getScheduleStatus = createSelector(
  surveyInfoReview,
  state => state?.scheduleStatus
)

export const getCreditsToPurchase = createSelector(
  surveyInfoReview,
  state => state?.creditsToPurchase
)
