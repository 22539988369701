import {
  Flex,
  Text,
  TabletUp,
  MobileOnly,
  Table
} from "@engaging-tech/components"
import React from "react"
import CircleIcon from "../../../ui/components/CircleIcon"

const tableHeadings = [
  {
    id: "orgSizeTitle",
    title: "Organization Size (number of employees)"
  },
  {
    id: "minParticipation",
    title: "Minimum Participation Rate Required"
  }
]
const tableRows = [
  {
    OrgSize: "10-49 (Small)",
    Rate: "60%"
  },
  {
    OrgSize: "50+ (Mid)",
    Rate: "50%"
  },
  {
    OrgSize: "250+ (Big)",
    Rate: "50%"
  },
  {
    OrgSize: "2000+ (Very Big)",
    Rate: "40%"
  }
]

const sampleConfig = {
  stylesPerColumn: [
    {
      fontSize: [2, 4, 5],
      color: "secondary.0"
    },
    { fontSize: [2, 4, 5], color: "secondary.0" }
  ],
  main: {
    borderRadius: "8px",
    border: "1px solid"
  },
  heading: {
    fontSize: [2, 4, 5],
    fontWeight: 800,
    color: "secondary.0",
    borderTop: "1px solid",
    borderColor: "secondary.0",
    py: 3
  },
  head: {
    borderTop: "1px solid"
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "secondary.0"
  }
}

const EntryRequirements = ({ participations }) => {
  return (
    <>
      <MobileOnly>
        <Flex flexDirection="column" mt="60px">
          <Flex flexDirection="column">
            <Flex>
              <CircleIcon
                name="trophy"
                bg="primary.0"
                iconColor="light.0"
                mr={2}
                size={30}
              />
              <Text
                fontWeight={700}
                fontSize={6}
                mb={2}
                lineHeight="-5px"
                color="secondary.0"
                width={1 / 1}
              >
                Minimum Entry Requirements
              </Text>
            </Flex>
            <Table
              headings={tableHeadings}
              rows={tableRows}
              mt={3}
              config={sampleConfig}
            />
          </Flex>
        </Flex>
      </MobileOnly>
      <TabletUp>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          pt="80px"
        >
          <Flex width="100%">
            <Flex width="40px">
              <CircleIcon
                name="trophy"
                bg="primary.0"
                iconColor="light.0"
                mr={2}
                size={30}
              />
            </Flex>
            <Flex flexDirection="column">
              <Text
                width="100%"
                fontWeight={700}
                fontSize={6}
                mb={2}
                lineHeight="-5px"
                color="secondary.0"
              >
                Minimum Entry Requirements
              </Text>
            </Flex>
          </Flex>
          <Table
            headings={tableHeadings}
            rows={participations}
            mt={3}
            config={sampleConfig}
            justifyContent="center"
          />
        </Flex>
      </TabletUp>
    </>
  )
}

export default EntryRequirements
