import { Flex, Text } from "@engaging-tech/components"
import React, { useContext, useState } from "react"
import styled from "styled-components"

import { ViewActionPlanContext } from "../views/ViewActionPlan"

const HoverText = styled(Text)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

const ml = 2
const width = "fit-content"

const generateAssignedText = (assignedTo, setOpenModal) => {
  if (assignedTo?.length === 0)
    return (
      <Text ml={ml} width={width}>
        "N/A"
      </Text>
    )

  if (assignedTo?.length === 1)
    return (
      <Text ml={ml} width={width}>
        {assignedTo[0].label}
      </Text>
    )

  return (
    <HoverText ml={ml} width={width} onClick={() => setOpenModal(true)}>
      {assignedTo?.length} surveys
    </HoverText>
  )
}

const ActionPlanInfoHeader = () => {
  const { actionPlan, setOpenModal } = useContext(ViewActionPlanContext)

  return (
    <Flex flexDirection="column">
      <Text fontSize={6} fontWeight={600}>
        {actionPlan?.title}
      </Text>
      <Flex mt={1}>
        <Text fontWeight={600} width={width}>
          Assigned to:
        </Text>
        {generateAssignedText(actionPlan?.assignedTo, setOpenModal)}
      </Flex>
      <Flex mt={1}>
        <Text fontWeight={600} width={width}>
          Date:
        </Text>
        <Text width={width} ml={ml}>
          {actionPlan?.createdAt}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ActionPlanInfoHeader
