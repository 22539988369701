import { types } from "./actions"

const general = "Something went wrong"

export const initialState = {
  isLoading: false,
  error: { general: "", error: null },
  data: {}
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.GET_SD_LOWEST_PRICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ["lowestPrice"]: payload.fetchProducts.prices[0]
        }
      }
    }

    case types.GET_SD_LOWEST_PRICE_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: { general, error: payload.error }
      }
    }

    case types.GET_SD_LOWEST_PRICE_REQ: {
      return {
        ...state,
        isLoading: true,
        data: {
          ...state.data
        }
      }
    }

    case types.GET_SD_UPGRADE_PRICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ["upgradePrice"]: payload.fetchProducts.prices[0]
        }
      }
    }

    case types.GET_SD_UPGRADE_PRICE_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: { general, error: payload.error }
      }
    }

    case types.GET_SD_UPGRADE_PRICE_REQ: {
      return {
        ...state,
        isLoading: true,
        data: {
          ...state.data
        }
      }
    }

    default:
      return state
  }
}
