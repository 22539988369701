import React from "react"
import { Flex } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"

import SixStepsQuestionsChart from "../containers/SixStepsQuestionsComparison"

const SixStepsQuestions = ({ ...props }) => {
  const { step } = useParams()

  return (
    <Flex
      alignItems="stretch"
      justifyContent="space-between"
      flexDirection={["column", "column", "row"]}
    >
      <SixStepsQuestionsChart step={step} {...props} />
    </Flex>
  )
}
export default SixStepsQuestions
