import { connect } from "react-redux"
import { setAmount } from "../../../store/actions"
import { getAmount, getShareableLinks } from "../../../store/selectors"
import ShareableLinks from "../../../components/Page2/Participants/ShareableLinks"

const mapState = state => ({
  amount: getAmount(state),
  maxAmount: getShareableLinks(state)
})

const mapDispatch = dispatch => ({
  setAmount: amount => dispatch(setAmount(amount))
})

const ShareableLinksContainer = connect(mapState, mapDispatch)(ShareableLinks)

export default ShareableLinksContainer
