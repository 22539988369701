import { Card, Flex, H2, Paragraph } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import { ReportSelectors } from ".."
import BackButton from "../components/BackButton"
import ReportElementsLayout from "../components/ReportElementsLayout"
import ReportHeader from "../Header/containers/ReportHeader"

const Matrices = () => {
  const { id, surveyType } = useParams()
  const components = useSelector(ReportSelectors.getMatricesChildrenLayout)
  const paths = usePaths()
  return (
    <>
      <BackButton
        path={paths.reports.summary.index
          .replace(":surveyType", surveyType)
          .replace(":id", id)}
      />
      <ReportHeader
        subSection="Identify Unique Needs & Characteristics of Your Employee Segments"
        reportComponents={components}
      />
      <Card
        width={1 / 1}
        p={3}
        justifyContent="center"
        flexDirection="column"
        elevation={2}
        alignItems="center"
      >
        <Flex flexDirection="column" alignItems="center" width={1 / 1} px={2}>
          <H2 color="dark.1" fontWeight="500" fontSize={5} mb={4}>
            How to Use This Data
          </H2>
          <Paragraph color="dark.2" fontSize={3}>
            Most companies recognize that not all customers are alike and offer
            different value propositions based on the preferences of various
            groups. The same goes for employees. By grouping employees according
            to their needs, your organisation can tailor everything from terms
            of employment to incentives for different segments. Identifying
            employee segments helps improving financial performance, customer
            service and employee satisfaction—and reducing costs.
          </Paragraph>
          <Paragraph color="dark.2" fontSize={3}>
            The logic is simple: just as customers don’t fit neatly into a
            single homogenous group, employees are motivated by different
            factors that are often dictated by their stage of life or personal
            interests. By learning what motivates each group and offering a
            customized program that delivers what matters most, companies find
            that employees are willing to work more effectively and devote more
            discretionary thought and attention to their jobs. Whether companies
            apply the approach with call center agents, field force workers or
            any other employee group, it can deliver positive results.
          </Paragraph>
        </Flex>
      </Card>

      <Flex
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        flexWrap="wrap"
        alignItems="stretch"
      >
        <ReportElementsLayout componentTags={components} />
      </Flex>
    </>
  )
}

export default Matrices
