import { Flex, MotionFlex, Text, Icon } from "@engaging-tech/components"
import React from "react"
import { SizeMe } from "react-sizeme"

import { getArrowPixelsFromStart, getColor } from "./lib/getColorValue"

const BarArrow = ({
  indicatorVisible,
  hideArrowLabels,
  score,
  label,
  arrowSize,
  disableAnimation,
  containerDimensions,
  minValue,
  maxValue,
  index,
  barStructure
}) => {
  const cappedScore = (() => {
    if (score > maxValue) {
      return maxValue
    }
    if (score < minValue) {
      return minValue
    }
    return score
  })()

  const pixelsFromStart = getArrowPixelsFromStart({
    pointValue: cappedScore,
    minValue,
    maxValue,
    containerDimensions,
    arrowSize,
    barStructure
  })
  const zeroPoint = getArrowPixelsFromStart({
    pointValue: 0,
    minValue,
    maxValue,
    containerDimensions,
    arrowSize,
    barStructure
  })

  const arrowColor = getColor({
    pointValue: cappedScore,
    minValue,
    maxValue,
    containerDimensions,
    arrowSize,
    barStructure
  })
  return (
    <Flex style={{ position: "relative" }} mt={index * 1 + 16}>
      <MotionFlex
        style={{ position: "absolute" }}
        initial={{
          left:
            disableAnimation || indicatorVisible ? pixelsFromStart : zeroPoint
        }}
        animate={{
          left:
            disableAnimation || indicatorVisible ? pixelsFromStart : zeroPoint
        }}
        transition={{ duration: disableAnimation ? 0 : 0.7 }}
        width={arrowSize}
        height={arrowSize}
        alignItems="center"
      >
        <Icon name="keyboard_arrow_up" size={arrowSize} color={arrowColor} />
        {!hideArrowLabels && (
          <SizeMe>
            {({ size }) => (
              <MotionFlex
                position="absolute"
                left={cappedScore > 0 ? -size.width : arrowSize}
                width="auto"
              >
                <Text
                  width="auto"
                  style={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                  }}
                  color="dark.2"
                  maxWidth={130}
                  textSize={14}
                >
                  {label}
                </Text>
              </MotionFlex>
            )}
          </SizeMe>
        )}
      </MotionFlex>
    </Flex>
  )
}

export default BarArrow
