import { Flex } from "@engaging-tech/components"
import styled from "styled-components"

const EditBodyFlex = styled(Flex)`
  width: 100%;
  height: 115px;
  flex-direction: column;
  margin: 10px 0px;
  overflow-y: auto;
`

export default EditBodyFlex
