import { createSelector } from "reselect"

export const sdUpgradeModalState = state => state && state.sdUpgradeModal

export const getSDLowestPrice = createSelector(sdUpgradeModalState, state =>
  state.data["lowestPrice"]
    ? state.data["lowestPrice"]
    : { currency: "gbp", unit_amount: 0 }
)

export const getSDUpgradePrice = createSelector(sdUpgradeModalState, state =>
  state.data["upgradePrice"]
    ? state.data["upgradePrice"]
    : { currency: "gbp", unit_amount: 0 }
)
