import React, { useState } from "react"
import { Flex, Modal, Text, Button, Checkbox } from "@engaging-tech/components"
import { createPortal } from "react-dom"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const ManageTargetChoices = ({
  setOpenModal,
  currentChoice,
  target,
  setBranchingLogic,
  targetAnswers,
  currentQuestionId
}) => {
  const [checkedAnswers, setCheckedAnswers] = useState(targetAnswers)
  const handleChange = e => {
    const index = checkedAnswers.indexOf(e.target.id)
    if (index !== -1) {
      if (checkedAnswers.length === 1) return null
      return setCheckedAnswers([
        ...checkedAnswers.slice(0, index),
        ...checkedAnswers.slice(index + 1)
      ])
    }

    return setCheckedAnswers([...checkedAnswers, e.target.id])
  }

  return createPortal(
    <Modal width={8 / 10} onToggle={setOpenModal}>
      <Flex
        bg="light.0"
        flexDirection="column"
        alignItems="center"
        width={1 / 1}
        p={4}
      >
        <Text width="fit-content" textAlign="center">
          {`Answering "${currentChoice.value}" leads to "${target?.title}" and shows the following values:`}
        </Text>
        <Text width="fit-content" fontSize={4} mt={5}>
          <i>(uncheck answers to hide)</i>
        </Text>
        <Flex width={1 / 1} flexWrap="wrap" mt={2}>
          {target?.values?.map(choice => (
            <Checkbox
              width={1 / 2}
              key={choice.id}
              mb={3}
              colour="secondary.0"
              label={() => <Text>{choice.value}</Text>}
              checked={checkedAnswers.includes(choice.id)}
              id={choice.id}
              onChange={handleChange}
            />
          ))}
        </Flex>
        <Button
          bg="secondary.0"
          color="light.0"
          width="100px"
          onClick={() => {
            setBranchingLogic(
              currentChoice.id,
              currentQuestionId,
              target?.id,
              checkedAnswers
            )
            setOpenModal(false)
          }}
          mt={3}
        >
          Ok
        </Button>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default ManageTargetChoices
