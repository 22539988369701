import { connect } from "react-redux"

import * as DataHighlightsSelectors from "../../DataHighlights/store/datahighlights.selectors"
import * as DiversitySelectors from "../../Indicators/store/indicators.selectors"
import * as LowestScoreSelectors from "../../LowestScore/store/lowestscore.selectors"
import * as NetPromoterSelectors from "../../NetPromoterScore/store/netPromoterScore.selectors"
import * as QuestionsBreakdownSelectors from "../../QuestionsBreakdown/store/questionsBreakdown.selectors"
import * as SixStepsSelectors from "../../SixSteps/store/sixsteps.selectors"
import LowestScoreInfo from "../../lib/LowestScoreInfo"
import {
  getInstantActionPlan,
  getIsPdfDownloading,
  getReportLayout,
  getSliceAndDiceReport
} from "../../store/report.selectors"
import OnePageReportComponent from "../components/OnePageReport"

const mapState = state => {
  const managementConfidenceAverageScoreData =
    DataHighlightsSelectors.getIndicatorAverageScores(
      state,
      "managementConfidence"
    )

  return {
    dataHighlightsScore: DataHighlightsSelectors.getScore(state),
    lowestScore: LowestScoreSelectors.getScore(state),
    lowestScoreStepInfo:
      LowestScoreInfo[LowestScoreSelectors.getLowestStepName(state)].section,
    highestScore: getInstantActionPlan(state).high.score,
    highestScoreStepInfo:
      getInstantActionPlan(state).high.questionGroup.title.split("-")[0],
    sixSteps: SixStepsSelectors.getSixSteps(state),
    wellBeingAverageScoreData:
      DataHighlightsSelectors.getIndicatorAverageScores(state, "wellBeingRisk"),
    wellBeingMainScore: DataHighlightsSelectors.getWellBeingMainScore(state),
    wellBeingLabelScore:
      100 - DataHighlightsSelectors.getWellBeingMainScore(state),
    flightRiskAverageScoreData:
      DataHighlightsSelectors.getIndicatorAverageScores(state, "flightRisk"),
    flightRiskMainScore: DataHighlightsSelectors.getFlightRiskMainScore(state),
    flightRiskLabelScore:
      100 - DataHighlightsSelectors.getFlightRiskMainScore(state),
    managementConfidenceAverageScoreData,
    managementConfidenceMainScore: managementConfidenceAverageScoreData.score,
    managementConfidenceLabelScore: managementConfidenceAverageScoreData.score,
    netPromoterData: NetPromoterSelectors.getNetPromoterData(state),
    diversityAndInclusionData:
      DiversitySelectors.getDiversityAndInclusionAverageScores(state),
    sixStepsQuestions: QuestionsBreakdownSelectors.getSixStepsQuestions(state),
    customScaleQuestions:
      QuestionsBreakdownSelectors.getCustomScaleQuestions(state),
    isPdfDownloading: getIsPdfDownloading(state),
    hasAreaForCelebration: getReportLayout(state).find(
      el => el.name === "instantActionPlanning"
    ),
    isSliceAndDiceMember: getSliceAndDiceReport(state)?.active
  }
}

const OnePageReport = connect(mapState)(OnePageReportComponent)

export default OnePageReport
