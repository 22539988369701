import React, { useState } from "react"
import { Flex, MotionFlex } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import { withSize } from "react-sizeme"

import Title from "../components/Title"
import MainInformationPanel from "../components/MainInformationPanel"
import StepAnimation from "../components/StepAnimation"
import StepOneMainPanel from "../components/stepOne/StepOneMainPanel"
import StepTwoMainPanel from "../components/stepTwo/StepTwoMainPanel"

const sliderWidth = "calc(100% - 20px)"
const sliderMaxWidth = 1280

const getStep = selectedStep => {
  switch (selectedStep) {
    case 1:
      return <StepOneMainPanel width={sliderWidth} maxWidth={sliderMaxWidth} />
    case 2:
      return <StepTwoMainPanel width={sliderWidth} maxWidth={sliderMaxWidth} />
    default:
      return <></>
  }
}

const SizeAwareSlider = withSize({
  monitorHeight: true,
  monitorWidth: false,
  noPlaceholder: true
})(({ selectedStep }) => {
  return (
    <MotionFlex
      key={selectedStep}
      position="absolute"
      initial={{ x: 400, opacity: 0, transition: { duration: 0.4 } }}
      animate={{
        x: 0,
        opacity: 1,
        transition: { delay: 0.2, duration: 0.4 }
      }}
      pt={6}
      width={sliderWidth}
      pb={7}
      maxWidth={sliderMaxWidth}
      exit={{ x: -400, opacity: 0, transition: { duration: 0.4 } }}
    >
      {getStep(selectedStep)}
    </MotionFlex>
  )
})

const SurveyInformationView = ({ step }) => {
  const [mainBodySize, setMainBodySize] = useState({ height: 80 })

  return (
    <Flex flexDirection="column">
      <Title selectedStep={step} />
      <Flex
        width="100%"
        mt={[10, 12, 20]}
        flexDirection={["column", "column", "row"]}
      >
        <MainInformationPanel selectedStep={step} />
        <StepAnimation selectedStep={step} />
      </Flex>

      <MotionFlex
        width="100%"
        animate={{
          height: mainBodySize.height,
          transition: { type: "tween", ease: "easeInOut" }
        }}
      >
        <AnimatePresence>
          <SizeAwareSlider
            key={step}
            selectedStep={step}
            onSize={setMainBodySize}
          />
        </AnimatePresence>
      </MotionFlex>
    </Flex>
  )
}

export default SurveyInformationView
