import { Flex } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import React from "react"

import ActionPlanItems from "../../lib/ActionPlanItems"
import StepScore from "../containers/StepScore"
import SummaryBox from "../containers/SummaryBox"

const SixStepsOverview = ({ actionPlanGroup = "generic", disableQuestions, exportingPDF }) => {
  const { step } = useParams()
  const actionPlan = ActionPlanItems[actionPlanGroup].find(plan => plan.section === step)
  return (
    <Flex
      alignItems="stretch"
      justifyContent="space-between"
      flexDirection={exportingPDF ? "column" : ["column", "column", "row"]}
    >
      <SummaryBox
        summary={actionPlan?.summary}
        step={step}
        disableQuestions={disableQuestions}
        exportingPDF={exportingPDF}
      />
      <StepScore step={step} heading={actionPlan?.dialHeading} exportingPDF={exportingPDF} />
    </Flex>
  )
}
export default SixStepsOverview
