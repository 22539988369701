import { Box, Flex, H4, Paragraph } from "@engaging-tech/components"
import DOMPurify from "dompurify"
import React from "react"
import styled from "styled-components"

import CircleIcon from "../../ui/components/CircleIcon"
import IntroStepsIllustrations from "./IntroStepsIlustrations"

const AbsoluteFlex = styled(Flex)`
  @media (min-width: 1300px) {
    position: absolute;
    left: -100px;
  }
`

const Intro = ({ totalPages, currentPage, intro, isAwards }) => {
  const purifiedText = DOMPurify.sanitize(intro?.paragraph)

  return (
    <Flex width={1 / 1} flexDirection={["column", "column", "column"]} mt={5} position="relative">
      <AbsoluteFlex width={["auto", "auto", "120px"]} mt={[0, 0, "4px"]}>
        <Paragraph ml={["66px", "66px", "0px"]} color="secondary.0">
          {`Step ${currentPage} of ${totalPages}`}
        </Paragraph>
      </AbsoluteFlex>
      <Flex>
        <Box width="fit-content" mr={3} mt={1}>
          <CircleIcon name="trophy" bg="primary.0" iconColor="light.0" mr={2} size={40} />
        </Box>
        <Flex flexDirection={["column"]}>
          <H4 color="secondary.0" fontSize={6} fontWeight="600">
            {intro?.title}
          </H4>
          <Paragraph color="secondary.0" mt={4} dangerouslySetInnerHTML={{ __html: purifiedText }} />
        </Flex>
      </Flex>
      {isAwards ? <IntroStepsIllustrations currentStep={currentPage} /> : null}
    </Flex>
  )
}

export default Intro
