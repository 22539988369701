import { connect } from "react-redux"
import BespokeStepView from "../BespokeStepView"
import * as Selectors from "../store/bespokeStep.selectors"

const mapState = state => ({
  bespokeSteps: Selectors.getBespokeSteps(state)
})

const BespokeStep = connect(mapState)(BespokeStepView)

export default BespokeStep
