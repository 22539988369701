/* eslint-disable import/prefer-default-export */
import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../../lib/Apollo"
import { DELETE_ORGANISATION_SURVEY, LIST_ORGANISATION_SURVEYS } from "./surveys.queries"

const GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

export const loadSurveys = () =>
  apollo.query({
    uri: GRAPHQL_API_URL,
    query: LIST_ORGANISATION_SURVEYS
  })

export const deleteSurvey = variables =>
  apollo.mutate({
    uri: GRAPHQL_API_URL,
    mutation: DELETE_ORGANISATION_SURVEY,
    variables
  })
