/* eslint-disable react/jsx-curly-brace-presence */
import { Flex, Text } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"

import { OrgGuard } from "../../features/account"
import Success from "../../features/basket/components/Success"
import PageLayout from "../../layouts/PageLayout"

const Banner = ({ title }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    bg="secondary.0"
    color="light.0"
    width={1 / 1}
    minHeight="300px"
    px={3}
    py={5}
  >
    <Text fontSize={8} fontWeight={700} color="light.0" textAlign="center" mb={2} mt={5}>
      {title}
    </Text>
  </Flex>
)

const PaymentSuccess = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const paymentId = params.get("paymentId")
  const [reportId, setReportId] = useState("")

  const showGoToLiveSurveys = paymentId && !reportId

  useEffect(() => {
    setReportId(window?.localStorage?.getItem("surveyIdUpgrade"))
  }, [])

  return (
    <OrgGuard>
      <PageLayout pt={0} banner={() => <Banner title="Enhanced Data Insight Package" />}>
        <Success paymentId={paymentId} reportId={reportId} showGoToLiveSurveys={showGoToLiveSurveys} />
      </PageLayout>
    </OrgGuard>
  )
}

export default PaymentSuccess
