import { connect } from "react-redux"

import WelcomeViewComponent from "../views/WelcomeView"
import { getOrganisationCurrentUser } from "../../account/store/account.selectors"
import { surveysStore } from "../../surveyManagement/surveySelection"
import { getLastTwoSurveys } from "../../surveyManagement/surveySelection/store/surveys/surveys.selectors"

const mapState = state => ({
  currentUser: getOrganisationCurrentUser(state),
  isLoading: surveysStore.selectors.getIsLoading(state),
  surveysError: surveysStore.selectors.getError(state),
  showLatestSurveys: getLastTwoSurveys(state)
})

const mapDispatch = dispatch => ({
  loadScheduledLiveSurveys: () => dispatch(surveysStore.actions.loadData())
})

const WelcomeView = connect(mapState, mapDispatch)(WelcomeViewComponent)

export default WelcomeView
