/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const GET_ALL_ACTIVE_AWARDS = gql`
  query getAllActiveAwards {
    getAllActiveAwards {
      id
      type
      title
      titleContent
      description
      secondaryLogo
      flag
    }
  }
`

export const GET_AWARDS_BY_TYPE = gql`
  query getAwardsByType($type: String!) {
    getAwardsByType(type: $type) {
      id
      type
      title
      logo
      description
      endDate
      surveyContent {
        title
        introduction
      }
      country {
        currency
      }
      contactInfo {
        email
        phone
      }
      websiteUrl
      participations {
        orgSizeTitle
        minParticipation
      }
      downloads {
        faq
        welcomePack
      }
    }
  }
`

export const SAVE_AWARDS_SURVEY = gql`
  mutation saveAwardsSurvey($type: String, $input: AwardsSurveyData) {
    saveAwardsSurvey(type: $type, input: $input) {
      id
      shareableLinks
      title
      introduction
      startDate
      endDate
      employeeCount
      page
      templateName
      private
    }
  }
`
