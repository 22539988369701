import { Button, Card, Flex, Text } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { CheckMarkGreenIcon } from "../../../../assets/icons"
import { AccountSelectors } from "../../../account"

const MainContainer = styled(Flex)`
  justify-content: center;
  align-items: center;

  flex-direction: column;
`

const FinishTitle = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  line-height: 31.2px;
  color: #173c5a;
`

const FinishTitleContainer = styled(Flex)`
  width: fit-content;

  align-items: center;
`

const FinishSubtitle = styled(Text)`
  margin-top: 37px;

  width: fit-content;
  max-width: 579px;

  text-align: center;

  font-weight: 400;
  font-size: 18px;
  line-height: 23.4px;
  color: #173c5a;
`

const FinishButtonsContainer = styled(Flex)`
  margin-top: 37px;

  width: fit-content;
`

export default function Finish() {
  const history = useHistory()
  const paths = usePaths()
  const organisation = useSelector(AccountSelectors.getOrganisation)

  const onViewProfile = () => {
    window.open(
      `${getConfig().workL.app}/business-profile/${organisation.companyDetails.companyNameKey}?id=${
        organisation.companyDetails.surveyCompanyId
      }`,
      "__blank"
    )
  }

  return (
    <Card mb={3} pb={3} bg="light.0">
      <MainContainer mt={32} mb={53}>
        <FinishTitleContainer>
          <FinishTitle mr={10}>Your profile has been published</FinishTitle>
          <CheckMarkGreenIcon />
        </FinishTitleContainer>
        <FinishSubtitle>
          Congratulations, you are now showcasing your business to thousands of potential employees!
        </FinishSubtitle>
        <FinishSubtitle>Don’t worry, you can still make changes at any time.</FinishSubtitle>
        <FinishButtonsContainer>
          <Button
            type="button"
            variant="primary.0"
            text
            mr={32}
            onClick={e => {
              e.preventDefault()

              history.push(paths.home.index)
            }}
          >
            <Text color="light.0" style={{ textTransform: "uppercase" }}>
              Back Home
            </Text>
          </Button>
          <Button type="button" variant="outline.secondary.0" onClick={() => onViewProfile()}>
            <Text style={{ textTransform: "uppercase" }} color="secondary.0">
              View Profile
            </Text>
          </Button>
        </FinishButtonsContainer>
      </MainContainer>
    </Card>
  )
}
