import React, { useEffect } from "react"
import { usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { OrgGuard } from "../../features/account"
import PageLayout from "../../layouts/PageLayout"
import Home from "../../features/events/views/Home"
import BackBar from "../../features/ui/components/BackBar"
import addParams from "../../utils/addParams"

const EventsHome = () => {
  const paths = usePaths()

  useEffect(() => {
    addParams(false, getConfig().entitlements.events, "type")
  }, [])

  return (
    <OrgGuard>
      <PageLayout
        title="Your Events"
        banner={() => <BackBar to={paths.home.index} text="Back" visible />}
      >
        <Home />
      </PageLayout>
    </OrgGuard>
  )
}

export default EventsHome
