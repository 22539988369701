import { Flex, Text } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import getFeatures from "../lib/getFeatures"
import FeatureBox from "./FeatureBox"

// Note: Bespoke survey licence is a temp solution before migration to features
const FeatureList = ({ features, wonAward, bespokeSurveyLicence }) => {
  const paths = usePaths()
  paths.contactUs = getConfig().publicSite + getConfig().publicPaths.contactUs

  return (
    <>
      <Text
        fontSize={24}
        fontWeight={700}
        lineHeight="30px"
        color="secondary.0"
        mt="32px"
      >
        Setup or buy our services
      </Text>
      <Flex
        style={{ flexWrap: "wrap" }}
        alignItems="center"
        justifyContent="center"
        width={[1 / 1, "1300px"]}
        ml="auto"
        mr="auto"
        mb={6}
        mt="4px"
      >
        {getFeatures(features, paths, wonAward, bespokeSurveyLicence)?.map(
          (feature, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FeatureBox key={index} {...feature} />
          )
        )}
      </Flex>
    </>
  )
}

export default FeatureList
