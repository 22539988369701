import { Box, CustomHooks, ImageCropModal, Text, Utils } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { AnimatePresence } from "framer-motion"
import { getOrientation } from "get-orientation/browser"
import React, { useState } from "react"
import styled from "styled-components"

import { Card, FilePickerButton } from "../../../components"

const StyledImg = styled.img`
  width: -webkit-fill-available;
  height: 100%;
  object-fit: contain;
`

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
}

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const LogoPicker = ({ values, errors, setFieldValue, readOnly }) => {
  const theme = CustomHooks.useStyledTheme()
  const [imageToCrop, setImageToCrop] = useState(null)
  const [localImageURL, setLocalImageUrl] = useState(null)

  const handleImageChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await Utils.canvasHelpers.getRotatedImage(imageDataUrl, rotation)
      }

      setImageToCrop(imageDataUrl, file.name)
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey logo"])
      }
    }
  }

  return (
    <>
      <Box width={[1 / 1, 1 / 2]}>
        <Text>Brand Logo</Text>
        <Card
          border={(errors && errors.logo && `1px solid ${theme.colors.error[0]}`) || 0}
          mt={3}
          p={3}
          justifyContent="center"
          height="146px"
        >
          {values && (
            <StyledImg
              src={(typeof values.logo === "string" && values.logo) || localImageURL}
              alt="Your brand logo"
            />
          )}
        </Card>
        {!readOnly && (
          <>
            <Text color={errors && errors.logo && "errors.0"} fontSize={2} ml={10}>
              {(errors && errors.logo && errors.logo) ||
                "*WorkL tip: Please ensure your image is in png, jpeg or jpg format."}
            </Text>
            <FilePickerButton
              error={errors && errors.logo}
              mt={4}
              id="logo"
              name="logo"
              accept="image/png, image/jpeg, image/jpg"
              onChange={e => handleImageChange(e)}
              text="Change Logo"
            />
          </>
        )}
      </Box>
      <AnimatePresence>
        {imageToCrop && (
          <ImageCropModal
            cancelCrop={() => setImageToCrop(null)}
            imageToCrop={imageToCrop}
            aspectRatio={3 / 1}
            saveCroppedImage={imageToSave => {
              if (imageToSave) {
                setFieldValue("logo", imageToSave)

                const reader = new FileReader()
                reader.onloadend = () => setLocalImageUrl(reader.result)
                reader.readAsDataURL(imageToSave)
                setImageToCrop(null)
              } else {
                setLocalImageUrl(null)
              }
            }}
            imageName="company-logo"
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default LogoPicker
