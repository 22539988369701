/* eslint-disable prefer-destructuring */
import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import ActiveHeatMapFilters from "./ActiveHeatMapFilters"
import { OverflowHeatmapHeaderText } from "./SixStepCollapseColumn"
import StepQuestionColumn from "./StepQuestionColumn"

const DashedFlex = styled(Flex)`
  border-left: 2px dashed #173c5a99;
`

const StepColumn = ({
  questionGroup,
  allBusinessAreas,
  heatmapFilters,
  reportId,
  questionValuesMap,
  showGlobalResult,
  firstGroup,
  comparisonReport,
  isComparisonLoading,
  comparisonSurveyTitle,
  allComparisonSteps,
  showPreviousComp,
  comparisonFilters,
  showCurrentComp,
  allHeatmapFilters,
  expand,
  directReports,
  loadFilterData,
  exportingPDF,
  surveyType
}) => {
  return (
    <Flex
      flexDirection="row"
      className="report-heatMap-item-exporting-child"
      width="100%"
    >
      {exportingPDF && (
        <ActiveHeatMapFilters
          heatmapFilters={heatmapFilters}
          loadFilterData={loadFilterData}
          surveyType={surveyType}
          directReports={directReports}
          expand={expand}
          allHeatmapFilters={allHeatmapFilters}
          showGlobalResult={showGlobalResult}
          showPreviousComp={showPreviousComp}
        />
      )}
      <Flex flexDirection="column" width="auto" height="100%">
        <OverflowHeatmapHeaderText>
          {questionGroup.title ? questionGroup.title : questionGroup.step}
        </OverflowHeatmapHeaderText>

        <DashedFlex flexDirection="row">
          <Flex flexDirection="row" borderRadius={10}>
            {questionGroup.questions.map((stepQuestion, i) => {
              return (
                <StepQuestionColumn
                  showCurrentComp={showCurrentComp}
                  showPreviousComp={showPreviousComp}
                  key={stepQuestion.question}
                  stepQuestion={stepQuestion}
                  allComparisonSteps={allComparisonSteps}
                  comparisonReport={comparisonReport}
                  comparisonSurveyTitle={comparisonSurveyTitle}
                  isComparisonLoading={isComparisonLoading}
                  allBusinessAreas={allBusinessAreas}
                  heatmapFilters={heatmapFilters}
                  comparisonFilters={comparisonFilters}
                  questionGroupStep={
                    stepQuestion?.step ? stepQuestion?.step : questionGroup.step
                  }
                  reportId={reportId}
                  questionValuesMap={questionValuesMap}
                  showGlobalResult={showGlobalResult}
                  firstGroupAndQuestion={firstGroup && i === 0}
                />
              )
            })}
          </Flex>
        </DashedFlex>
      </Flex>
    </Flex>
  )
}

export default StepColumn
