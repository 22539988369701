import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../../../lib/Apollo"
import {
  LOAD_TEMPLATE,
  SAVE_ORGANISATION_TEMPLATE
} from "./activeTemplate.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Loads survey template from ID
 * @param {string} id Survey template ID to load
 */
export const loadTemplate = ({ id }) =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LOAD_TEMPLATE,
    variables: { id }
  })

/**
 * Saves survey template. If ID is provided, will overwrite backend data for that ID
 * @param {string} surveyTemplateId Survey template ID to save
 * @param {object} surveyTemplate Survey template data to save
 */
export const saveTemplate = ({ surveyTemplateId, surveyTemplate }) =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: SAVE_ORGANISATION_TEMPLATE,
    variables: {
      surveyTemplateId,
      surveyTemplate: {
        ...surveyTemplate,
        type: "organisation"
      }
    }
  })
