import React from "react"
import { Flex, MotionFlex, Text, CustomHooks } from "@engaging-tech/components"

import BespokeIndicatorAnimatedIllustration from "./BespokeIndicatorAnimatedIllustration"
import CoreQuestionAnimatedIllustration from "../../containers/stepOne/CoreQuestionAnimatedIllustration"
import CustomQuestionAnimatedIllustration from "./CustomQuestionAnimatedIllustration"

import layoutConfig from "../../lib/layoutConfig"

const StepOneMainPanel = ({ width, maxWidth }) => {
  const device = CustomHooks.useClientDevice()
  const leftSideWidth = (() => {
    if (device === "TABLET") {
      return 200
    }
    return 250
  })()
  return (
    <Flex
      width={width}
      height="auto"
      key={1}
      pl={[20, 10, 0]}
      maxWidth={maxWidth}
      position="relative"
      alignItems={["center", "flex-start", "flex-start"]}
      flexDirection={["column-reverse", "row", "row"]}
    >
      <BespokeIndicatorAnimatedIllustration width={leftSideWidth} />

      <Flex
        flexDirection="column"
        width={[
          "100%",
          `calc(100% - ${leftSideWidth}px)`,
          `calc(100% - ${leftSideWidth}px)`
        ]}
      >
        <MotionFlex
          width="100%"
          maxWidth={900}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 1.5 } }}
          exit={{ opacity: 0, x: 0 }}
          flexDirection="column"
        >
          <Text fontSize={layoutConfig.bodyTextSize} width="100%" my={2}>
            Create a standard survey template using our core questions to
            understand your employees workplace engagement, categorised by the
            Six Steps to Workplace Engagement.
          </Text>
          <Text fontSize={layoutConfig.bodyTextSize} width="100%" my={2}>
            You can also add custom sections and up to two custom questions to
            your survey template. Once your survey is complete you will be able
            to filter results by demographics and receive tailored action
            planning to improve your scores.
          </Text>
        </MotionFlex>

        <CoreQuestionAnimatedIllustration mt={4} delay={1.55} />
        <MotionFlex
          width="100%"
          mt={6}
          maxWidth={900}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 1.6 } }}
          exit={{ opacity: 0, x: 0 }}
          flexDirection="column"
        >
          <Text fontSize={layoutConfig.bodyTextSize} width="100%" my={2}>
            When building a bespoke survey template you will be able to base it
            off a standard survey template.
          </Text>
          <Text fontSize={layoutConfig.bodyTextSize} width="100%" my={2}>
            Bespoke templates allow you to add, change and remove unlimited
            sections, questions, demographic questions, matrices and
            participants’ results.
          </Text>
          <Text fontSize={layoutConfig.bodyTextSize} width="100%" my={2}>
            Customise your bespoke survey template with your businesses branding
            for a cohesive experience.
          </Text>
        </MotionFlex>

        <CustomQuestionAnimatedIllustration mt={4} delay={1.65} />
      </Flex>
    </Flex>
  )
}

export default StepOneMainPanel
