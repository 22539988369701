const jobTypes = [
  "Full-time",
  "Part-time",
  "Internship",
  "Volunteer",
  "Graduate Scheme",
  "Placement",
  "Apprenticeship"
]

export default jobTypes
