import { connect } from "react-redux"
import {
  getHasError,
  getIsLoading,
  getSelfServeDisplayQuestions
} from "../../../surveyManagement/templates/store/activeTemplate/activeTemplate.selectors"
import { getQuestionsPreview, getTemplateId } from "../../store/selectors"
import QuestionList from "../../components/Page2/QuestionList"

const mapState = state => ({
  displayQuestions: getSelfServeDisplayQuestions(state),
  templateId: getTemplateId(state),
  isLoading: getIsLoading(state),
  error: getHasError(state),
  questionsPreview: getQuestionsPreview(state)
})

const QuestionListContainer = connect(mapState, null)(QuestionList)

export default QuestionListContainer
