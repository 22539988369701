import { connect } from "react-redux"

import FiltersComponent from "../../Filters/components/Filters"
import * as Selectors from "../../Filters/store/filters.selectors"
import * as ReportActions from "../../store/report.actions"
import * as ReportSelectors from "../../store/report.selectors"

const mapState = state => ({
  availableFilters: Selectors.getAvailableFiltersForHeatmap(state),
  formValues: ReportSelectors.getAppliedHeatmapFilterFormValues(state),
  isDirectReportsAvailable: Selectors.getIsDirectReportsAvailable(state),
  renderingHeatMap: true
})

const mapDispatch = (dispatch, ownProps) => ({
  setFilters: filters => {
    if (!filters || filters.length === 0) {
      dispatch(ReportActions.clearAllHeatmapFilters())
      ownProps.toggle()
      return
    }
    let count = 0
    for (let i = 0; i < filters.length; i++) {
      for (let j = 0; j < filters[i].values.length; j++) {
        if (count > 50) {
          break
        }
        const filter = {
          question: filters[i].question,
          questionId: filters[i].questionId,
          values: [
            {
              valueId: filters[i].values[j].valueId,
              value: filters[i].values[j].value
            }
          ]
        }

        dispatch(ReportActions.addHeatmapFilter(filter, filters))
        count++
      }
    }
    ownProps.toggle()
  },
  setDirectReports: directReports => {
    //ignoring this for heatmaps
  },
  setQuestionFilter: questions =>
    dispatch(ReportActions.setQuestionFilter(questions))
})

const Filters = connect(mapState, mapDispatch)(FiltersComponent)

export default Filters
