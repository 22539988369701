const BESPOKE_SURVEY_LICENCE_PRICE = 2500
const EXIT_SURVEY_LICENCE_PRICE = 2500
const STANDARD_SURVEY_LICENCE_PRICE = 500
const STARTER_SURVEY_LICENCE_PRICE = 2500
const VAT_PERCENT = 0.2
const CREDIT_PRICE = 3

/**
 * Calculates the number of credits that are needed for the survey to be scheduled
 * @param {Object} mode mode object holds the mode of the survey invite mode
 * @param {Number} creditsPaid number of credits that have already been paid for the survey
 * @param {Number} currentCredits number of credits that the organisation currently holds
 * @returns The number of credits needed to purchase
 */
const calcCreditsNeededToPurchase = (mode, creditsPaid = 0, currentCredits) => {
  if (mode.mode === "uniqueSubmission")
    return mode.recipients.length - (creditsPaid + currentCredits)
  if (mode.mode === "shareableLinks")
    return mode.amount - (creditsPaid + currentCredits)
  return 0
}

/**
 * Rounds a number to 2 decimal places
 * @param {Number} num number to round
 * @returns a new number, rounded to 2 decimal places
 */
const roundTo2DecimalPlaces = (num = 0) =>
  (Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2)

/**
 * Checks the loading state of the survey and returns a message to display
 * @param {Boolean} accountIsLoading If the account is loading
 * @param {Boolean} scheduleStatus The survey schedule status
 * @param {Boolean} surveyIsLoading If the survey meta data is loading
 * @returns a message to display
 */
const generateLoadingText = (
  accountIsLoading,
  scheduleStatus,
  surveyIsLoading
) => {
  if (accountIsLoading) return "Loading credits..."
  if (scheduleStatus === "scheduling") return "Scheduling survey..."
  if (surveyIsLoading) return "Updating survey meta data..."
  return "Loading..."
}

/**
 * Checks if an organisation has the correct survey licence for a survey
 * @param {Object} template an object containing the survey template
 * @param {Array} surveyLicences An array of licences to check against
 * @returns a boolean indicating if the organisation has the correct survey licence
 */
const hasCorrectSurveyLicence = (template, surveyLicences) => {
  if (template.isBespoke) {
    return surveyLicences.find(
      licence => licence.name === "bespokeSurveyLicence" && licence.active
    )
  }
  switch (template.type) {
    case "employeeStarter":
      return surveyLicences.find(
        licence =>
          licence.name === "employeeStarterSurveyLicence" && licence.active
      )
    case "employeeExit":
      return surveyLicences.find(
        licence =>
          licence.name === "employeeExitSurveyLicence" && licence.active
      )
    default:
      return (
        surveyLicences.find(
          licence => licence.name === "standardSurveyLicence" && licence.active
        ) ||
        surveyLicences.find(
          licence => licence.name === "bespokeSurveyLicence" && licence.active
        )
      )
  }
}

/**
 * Calculates the VAT price of a survey
 * @param {Object} template an object containing the survey template
 * @param {Array} surveyLicences an array of licences to check against
 * @param {Number} creditsToPurchase The number of credits to purchase
 * @returns the vat price
 */
const getVatPrice = (template, surveyLicences, creditsToPurchase = 0) => {
  let vat = 0
  vat += creditsToPurchase * CREDIT_PRICE * VAT_PERCENT
  if (hasCorrectSurveyLicence(template, surveyLicences)) return vat
  if (template.isBespoke) {
    vat += BESPOKE_SURVEY_LICENCE_PRICE * VAT_PERCENT
    return vat
  }
  switch (template.type) {
    case "employeeStarter":
      vat += STARTER_SURVEY_LICENCE_PRICE * VAT_PERCENT
      return vat
    case "employeeExit":
      vat += EXIT_SURVEY_LICENCE_PRICE * VAT_PERCENT
      return vat
    default:
      vat += STANDARD_SURVEY_LICENCE_PRICE * VAT_PERCENT
      return vat
  }
}

/**
 * Calculates the total price of a survey
 * @param {Object} template an object containing the survey template
 * @param {Array} surveyLicences an array of licences to check against
 * @param {Number} creditsToPurchase the number of credits to purchase
 * @returns the total price
 */
const getTotal = (template, surveyLicences, creditsToPurchase = 0) => {
  let total = 0
  total += creditsToPurchase * CREDIT_PRICE
  total += getVatPrice(template, surveyLicences, creditsToPurchase)
  if (hasCorrectSurveyLicence(template, surveyLicences)) return total
  if (template.isBespoke) {
    total += creditsToPurchase * CREDIT_PRICE
    total += getVatPrice(template, surveyLicences, creditsToPurchase)
    if (hasCorrectSurveyLicence(template, surveyLicences)) return total
    total += BESPOKE_SURVEY_LICENCE_PRICE
    return total
  }
  switch (template.type) {
    case "employeeStarter":
      total += STARTER_SURVEY_LICENCE_PRICE
      return total
    case "employeeExit":
      total += EXIT_SURVEY_LICENCE_PRICE
      return total
    default:
      total += STANDARD_SURVEY_LICENCE_PRICE
      return total
  }
}

export {
  calcCreditsNeededToPurchase,
  roundTo2DecimalPlaces,
  generateLoadingText,
  hasCorrectSurveyLicence,
  getTotal,
  getVatPrice,
  EXIT_SURVEY_LICENCE_PRICE,
  STARTER_SURVEY_LICENCE_PRICE,
  STANDARD_SURVEY_LICENCE_PRICE,
  BESPOKE_SURVEY_LICENCE_PRICE,
  VAT_PERCENT,
  CREDIT_PRICE
}
