import { gql } from "@apollo/client"

const PUBLISH_ACTION_PLAN = gql`
  mutation publishActionPlan($input: PublishActionPlanInput) {
    publishActionPlan(input: $input) {
      statusCode
      body
      error {
        code
        message
        type
      }
    }
  }
`

export default PUBLISH_ACTION_PLAN
