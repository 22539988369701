import { types } from "./awards.actions"

const general = "Something went wrong"

export const initialState = {
  awardsType: "",
  pageErrors: {},
  survey: {
    error: { general: "", error: null },
    data: {
      startDate: new Date(),
      endDate: new Date(),
      page: null,
      private: false
    }
  },
  awards: {
    error: { general: "", error: null },
    data: {}
  },
  allAwards: null
}

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.SET_PRIVATE: {
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...state.survey.data,
            private: payload
          }
        }
      }
    }
    
    case types.SET_PAGE: {
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...state.survey.data,
            page: payload
          }
        }
      }
    }

    case types.SET_AWARDS_TYPE: {
      return {
        ...state,
        awardsType: payload
      }
    }

    case types.GET_AWARDS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        awards: {
          ...state.awards,
          data: payload.getAwardsByType[0]
        }
      }
    }

    case types.GET_AWARDS_BY_TYPE_FAIL: {
      return {
        ...state,
        awards: {
          ...state.awards,
          error: {
            general,
            error: payload
          }
        }
      }
    }

    case types.GET_ALL_ACTIVE_AWARDS_FAIL:
      return {
        ...state,
        allAwards: {
          error: {
            general,
            error: payload
          }
        }
      }

    case types.SET_DATE:
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...state.survey.data,
            startDate: payload?.startDate || state.survey.data.startDate,
            endDate: payload?.endDate || state.survey.data.endDate
          }
        }
      }

    case types.SET_EMPLOYEE_COUNT: {
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...state.survey.data,
            employeeCount: payload
          }
        }
      }
    }

    case types.SET_PAGE_ERRORS: {
      let pageErrors = JSON.parse(JSON.stringify(state.pageErrors))

      pageErrors = {
        ...pageErrors,
        [payload.id]: {
          ...pageErrors[payload.id],
          [payload.type]: payload.errors
        }
      }

      // Clean up empty errors
      if (!payload.errors) delete pageErrors[payload.id][payload.type]
      Object.keys(pageErrors).forEach(key => {
        if (Object.keys(pageErrors[key]).length === 0) delete pageErrors[key]
      })

      return {
        ...state,
        pageErrors
      }
    }

    case types.SET_AWARDS_INTRODUCTION: {
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...state.survey.data,
            introduction: payload
          }
        }
      }
    }

    case types.SET_AWARDS_TITLE: {
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...state.survey.data,
            title: payload
          }
        }
      }
    }

    case types.SAVE_AWARDS_SURVEY_FAIL: {
      return {
        ...state,
        survey: {
          ...state.survey,
          error: { general, error: payload }
        }
      }
    }

    case types.SAVE_AWARDS_SURVEY_SUCCESS: {
      return {
        ...state,
        survey: {
          ...state.survey,
          data: {
            ...payload.saveAwardsSurvey,
            startDate: new Date(payload.saveAwardsSurvey.startDate),
            endDate: new Date(payload.saveAwardsSurvey.endDate)
          }
        }
      }
    }

    default:
      return state
  }
}
