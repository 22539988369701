import React from "react"

import SliceAndDiceUpsellChooseCompanySize from "./SliceAndDiceUpsellChooseCompanySize"
import SliceAndDiceUpsellGetLastSurveyContainer from "./SliceAndDiceUpsellGetLastSurveyContainer"

const SliceAndDiceUpsell = ({ chooseCompanySize }) => {
  const inactiveParam = new URLSearchParams(window.location.search).get(
    "inactive"
  )

  if (inactiveParam === "slice-and-dice") {
    if (chooseCompanySize) {
      return <SliceAndDiceUpsellChooseCompanySize />
    }

    return <SliceAndDiceUpsellGetLastSurveyContainer />
  }

  return null
}

export default SliceAndDiceUpsell
