import React, { useEffect } from "react"
import { Text, Flex, Spinner, Table } from "@engaging-tech/components"
import styled from "styled-components"
import { useParams } from "@engaging-tech/routing"
import {
  jobMatcherTableConfig,
  jobMatcherTableHeadings
} from "../lib/jobMatchTableConfig"

const FlexTableContainer = styled(Flex)`
  overflow-x: auto;
`

const EmailRef = styled.a`
  text-decoration: underline;
  color: blue;
`

const createEmailElement = email => (
  <EmailRef href={`mailto:${email}`}>{email}</EmailRef>
)

const formattedRows = rows =>
  rows &&
  rows.map(row => {
    const { name, currentRole, desiredRoles, desiredSalary, score, email } = row
    return {
      name,
      currentRole,
      desiredRoles: desiredRoles.join(", "),
      salary: `£${desiredSalary.min} - £${desiredSalary.max}`,
      skills: `${score}%`,
      contact: createEmailElement(email)
    }
  })

const JobMatches = ({ isLoading, onLoad, jobMatches }) => {
  const { jobId } = useParams()
  useEffect(() => {
    onLoad(jobId)
  }, [onLoad, jobId])

  return isLoading ? (
    <Spinner justifyContent="center" color="primary.0" />
  ) : (
    <FlexTableContainer width={1} flexDirection="column" mt={5} px={3}>
      <Flex width={1} justifyContent="center" mb={4}>
        <Text color="dark.0" fontSize={5} fontWeight={500}>
          Matches with your job post
        </Text>
      </Flex>
      <Table
        headings={jobMatcherTableHeadings}
        rows={formattedRows(jobMatches)}
        config={jobMatcherTableConfig}
      />
    </FlexTableContainer>
  )
}

export default JobMatches
