import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const StyledFlex = styled(Flex)`
  width: 100%;
  border: 4px solid #d9d9d9;
  border-radius: 16px;
  padding: 20px 0px;
  align-items: center;
  margin: ${({ margin }) => margin || "0px"};
`

const MultiRowCard = ({ rowData, bg, margin }) => {
  return (
    <StyledFlex margin={margin}>
      {Object.values(rowData).map(value => (
        <Flex justifyContent="center" width={1 / 1} bg={bg || "light.0"}>
          {value}
        </Flex>
      ))}
    </StyledFlex>
  )
}

export default MultiRowCard
