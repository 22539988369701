import React from "react"
import { Flex, Modal, Text, Button } from "@engaging-tech/components"
import ClickableIcon from "../../../ui/components/ClickableIcon"

const ConfirmationModal = ({
  show,
  handleSave,
  handleCloseSurvey,
  handleCloseModal,
  setShowModal,
  ...props
}) => {
  return (
    <>
      <Modal toggle={show} {...props}>
        <Flex width={1 / 1} mt={2} justifyContent="flex-end">
          <ClickableIcon
            name="close"
            onClick={() => setShowModal(false)}
            mr={3}
          />
        </Flex>
        <Text
          fontSize={[3, 4]}
          pb={5}
          mt={4}
          fontWeight={700}
          color="secondary.0"
          width="auto"
          textAlign="center"
        >
          Are you sure? Any changes will not be saved
        </Text>
        <Flex
          flexDirection={["column", "row"]}
          justifyContent="center"
          alignItems="center"
          mb={4}
          mt={[3, 4]}
        >
          <Button mb={[4, 0]} onClick={handleCloseSurvey} variant="secondary.0">
            Close
          </Button>
          <Button
            ml={["inherit", 4]}
            onClick={handleSave}
            variant="secondary.0"
          >
            Save
          </Button>
        </Flex>
      </Modal>
    </>
  )
}

export default ConfirmationModal
