import React from "react"
import {
  Flex,
  MotionFlex,
  Text,
  CustomHooks
} from "@engaging-tech/components"

import MultipleSurveysOneTemplateIllustration from "./MultipleSurveysOneTemplateIllustration"

import layoutConfig from "../../lib/layoutConfig"

const StepTwoMainPanel = ({ width, maxWidth }) => {
  const device = CustomHooks.useClientDevice()
  const illustrationSize = (() => {
    if (device === "DESKTOP") {
      return 500
    }
    return device === "TABLET" ? 400 : 270
  })()
  return (
    <Flex
      width={width}
      height="auto"
      key={1}
      pl={[20, 10, 0]}
      maxWidth={maxWidth}
      position="relative"
      alignItems={["center", "flex-start", "flex-start"]}
      flexDirection={["column-reverse", "row", "row"]}
    >
      <MultipleSurveysOneTemplateIllustration
        width={illustrationSize}
        height={illustrationSize}
      />
      <MotionFlex
        width={[
          "100%",
          `calc(100% - ${illustrationSize}px)`,
          `calc(100% - ${illustrationSize}px)`
        ]}
        initial={{ x: 400, opacity: 0, transition: { duration: 0.4 } }}
        animate={{
          x: 0,
          opacity: 1,
          transition: { delay: 1.7, duration: 0.4 }
        }}
        height="auto"
        pl={[0, 50, 50]}
        mb={[40, 0, 0]}
        flexDirection="column"
      >
        <Text fontSize={layoutConfig.bodyTextSize} width="100%">
          Once you have created your survey template you can set up your survey.
          Within the survey set up you can set a start and end date, invite your
          participants and purchase credits to make your survey live.
        </Text>
        <Text fontSize={layoutConfig.bodyTextSize} width="100%" mt={3}>
          A survey template can be used on multiple surveys. Your survey
          template can be edited and adapted based on what you want to find out
          from your participants. If multiple surveys use the same unedited
          survey template you will be able to compare results.
        </Text>
      </MotionFlex>
    </Flex>
  )
}

export default StepTwoMainPanel
