import React from "react"

import BackBar from "../features/ui/components/BackBar"
import PageLayout from "./PageLayout"

const DetailsLayout = ({
  bg = "dark.4",
  backTo = "/network",
  children,
  actionName,
  ...props
}) => (
  <PageLayout
    bg={bg}
    pt={[3, 4]}
    banner={() => <BackBar to={backTo} text="Back" visible />}
    minHeight="100vh"
    {...props}
    justifyContent="center"
    width={1 / 1}
  >
    {children}
  </PageLayout>
)

export default DetailsLayout
