import React from "react"

import SliceAndDiceContainer from "../containers/SliceAndDice/SliceAndDiceContainer"

const SDView = ({ saveSurvey, setPage, inputs, nextPage, hideContinueButton, hidePurchaseButton }) => {
  const handleSubmit = () => {
    saveSurvey(inputs)
    setPage(nextPage)
  }

  const handleGoBack = () => {
    saveSurvey({ page: 2 })
    setPage(2)
  }

  return (
    <SliceAndDiceContainer
      handleGoBack={handleGoBack}
      handleSubmit={handleSubmit}
      hideContinueButton={hideContinueButton}
      hidePurchaseButton={hidePurchaseButton}
    />
  )
}

export default SDView
