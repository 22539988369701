import Page1Container from "../containers/Page1"
import Page2Container from "../containers/Page2"
import Page3Container from "../containers/Page3"

export const questionsPreview = {
  wellbeingSurvey: {
    title: "Self Serve Wellbeing Survey",
    image: "https://d19vbgnwz7jfjm.cloudfront.net/wellbeing_questions.png"
  },

  organisationSurvey: {
    title: "Self Serve Engagement Survey",
    image: "https://d19vbgnwz7jfjm.cloudfront.net/fix_new_q_preview.png"
  }
}

export const engagementPages = [
  {
    id: 1,
    component: Page1Container,
    intro: {
      title: "Select your Organisation Size",
      paragraph:
        "Select the package that reflects the number of employees in your organisation at the time your survey will run. If your organisation grows you can upgrade your account at a later date by contacting <u><a href='mailto:support@workl.co'>support@workl.co</a></u>."
    }
  },
  {
    id: 2,
    component: Page2Container,
    intro: {
      title: "Set up your Survey",
      paragraph: "Now it's time to set up your survey."
    }
  },
  {
    id: 3,
    component: Page3Container,
    intro: {
      title: "",
      paragraph: "Purchase your Survey Package"
    }
  }
]

export const wellbeingPages = [
  {
    id: 1,
    component: Page1Container,
    intro: {
      title: "Select your Organisation Size",
      paragraph:
        "Select the package that reflects the number of employees in your organisation at the time your survey will run. If your organisation grows you can upgrade your account at a later date by contacting <u><a href='mailto:support@workl.co'>support@workl.co</a></u>."
    }
  },
  {
    id: 2,
    component: Page2Container,
    intro: {
      title: "Set up your Survey",
      paragraph: "Now it's time to set up your survey."
    }
  },
  {
    id: 3,
    component: Page3Container,
    intro: {
      title: "Your Basket",
      paragraph:
        "Pay by credit card or generate an invoice to purchase your package(s) and be able to send your survey out."
    }
  }
]

export const cardData = [
  {
    id: 1,
    title: "1. Select your organisation size",
    img: "https://d19vbgnwz7jfjm.cloudfront.net/1orgSize.png",
    paragraph:
      "Select if you are a small, medium, big or very big organisation based on your number of employees."
  },
  {
    id: 2,
    title: "2. Set up your Survey",
    img: "https://d19vbgnwz7jfjm.cloudfront.net/2setUp.png",
    paragraph:
      "Select when you`d like your survey to start end and how many employees you have."
  },
  {
    id: 3,
    title: "3. Purchase your Self Serve Survey",
    img: "https://d19vbgnwz7jfjm.cloudfront.net/3purchase.png",
    paragraph:
      "Pay on credit card or generate an invoice to purchase your package and be able to send your survey out."
  },
  {
    id: 4,
    title: "4. You`re ready to go!",
    img: "https://d19vbgnwz7jfjm.cloudfront.net/4success.png",
    paragraph:
      "Your survey will go live on the date you’ve set, at which point it can be sent to and be taken by your employees. Once it closes, you’ll get access to your interactive dashboard where you can see your survey results."
  }
]
