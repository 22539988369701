import { Button, Flex, Spinner, Text } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import TwoBtnDialogueContainer from "../containers/TwoBtnDialogueContainer"

const StyledButton = styled(Button)`
  :hover {
    background-color: ${({ theme }) => theme.colors.primary[0]};
    color: ${({ theme }) => theme.colors.light[0]};
  }
`

const ResolverDialogue = props => {
  const { errorMessage, successMessage, onClick } = props

  return (
    <Flex flexDirection="column" alignItems="center">
      <Text textAlign="center">{errorMessage || successMessage}</Text>
      <StyledButton
        variant="outline.primary.0"
        elevation={0}
        width={2 / 5}
        onClick={() => onClick()}
      >
        Ok
      </StyledButton>
    </Flex>
  )
}

const ReportsDialogue = ({
  yesActionParameters,
  selectedReports,
  yesAction,
  isLoading,
  Error,
  successActionParameters,
  successAction,
  openModal,
  setOpenModal,
  dialogueText,
  successMessage,
  children,
  disableButton = false
}) => {
  const reportsAvailable = !!selectedReports.length
  const router = useRouter()

  return (
    <Flex
      width={1 / 1}
      bg="white"
      alignItems="center"
      flexDirection="column"
      py={3}
    >
      {reportsAvailable && !isLoading && (
        <TwoBtnDialogueContainer
          dialogue={dialogueText}
          yesBtnText="Confirm"
          yesAction={() => yesAction(yesActionParameters)}
          noBtnText="Cancel"
          noAction={() => setOpenModal(!openModal)}
          disableButton={disableButton}
        >
          {children}
        </TwoBtnDialogueContainer>
      )}

      {isLoading && <Spinner justifyContent="center" color="primary.0" />}

      {!reportsAvailable && !isLoading && (
        <ResolverDialogue
          successMessage={successMessage}
          errorMessage={Error}
          onClick={() => {
            if (!Error && successAction) {
              successAction(successActionParameters)
              setOpenModal(!openModal)
            } else if (successActionParameters) {
              router.navigate(successActionParameters)
            } else {
              setOpenModal(!openModal)
            }
          }}
        />
      )}
    </Flex>
  )
}

export default ReportsDialogue
