import { connect } from "react-redux"
import SliceAndDice from "../../views/SDView"
import { getSliceAndDiceProduct } from "../../../../store/products/products.selectors"
import { setPage } from "../../store/awards.actions"
import { generateNextPage } from "../../store/awards.selectors"

const mapState = state => ({
  sliceAndDiceProduct: getSliceAndDiceProduct(state),
  nextPage: generateNextPage(state),
  inputs: { page: generateNextPage(state) }
})

const mapDispatch = dispatch => ({
  setPage: page => dispatch(setPage(page))
})

const SDViewContainer = connect(mapState, mapDispatch)(SliceAndDice)

export default SDViewContainer
