import { connect } from "react-redux"
import sixStepsComparisonComponent from "../components/SixStepsComparison"
import * as Selectors from "../store/sixStepsComparison.selectors"

const mapState = state => ({
  graphDataset: Selectors.getSixStepsComparisonScores(state)
})

const SixStepsComparison = connect(mapState)(sixStepsComparisonComponent)

export default SixStepsComparison
