import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const CheckboxContainer = styled(Flex)`
  cursor: pointer;
  user-select: none;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #013e4c;
  border-radius: 100%;
  transition: all 150ms;

  // Style for the blue dot
  ${HiddenCheckbox}:checked + & {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // After the checkbox is checked, display a smaller blue circle (dot) inside it
  ${HiddenCheckbox}:checked + &::after {
    content: "";
    width: 10px; // Size of the dot
    height: 10px; // Ensure a square to make a perfect circle for the dot
    border-radius: 50%; // Makes the dot circular
    background: #013e4c; // Color of the dot
  }
`

const Label = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #173c5a;
`

export default function Checkbox({ label, checked, onClick, ...props }) {
  return (
    <CheckboxContainer onClick={onClick}>
      <HiddenCheckbox checked={checked} {...props} onChange={() => null} />
      <StyledCheckbox checked={checked} />
      <Label ml={20}>{label}</Label>
    </CheckboxContainer>
  )
}
