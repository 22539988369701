/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Spinner } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { SAVE_SELF_SERVE_SURVEY } from "../../../graphql/mutations"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import addParams from "../../../utils/addParams"
import ErrorState from "../../business-information/components/error-state"
import Intro from "../components/Intro"
import SurveyFlow from "../components/SurveyFlow"
import { saveSelfServeSurveyDataFail, saveSelfServeSurveyDataSuccess } from "../store/actions"

const PaddedBox = styled(Box)`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1150px) {
    padding: 0px 24px;
  }
`

const PageManager = ({ currentPage, pages, selfServeSurveyType, sliceAndDiceLicence }) => {
  const [displayPage, setDisplayPage] = useState(null)

  const { isLoading, errors, post } = useBusinessAppMutation(SAVE_SELF_SERVE_SURVEY, {
    successAction: saveSelfServeSurveyDataSuccess,
    failAction: saveSelfServeSurveyDataFail
  })

  const saveSelfServeSurvey = input => {
    post({
      surveyObj: input,
      selfServeSurveyType
    })
  }

  useEffect(() => {
    const page = pages.filter(obj => obj.id === currentPage)[0]
    setDisplayPage(page)
  }, [currentPage])

  useEffect(() => {
    addParams(sliceAndDiceLicence, "slice-and-dice", "inactive")
  }, [])

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />
  if (errors) return <ErrorState />

  return (
    <PaddedBox>
      <Intro totalPages={pages.length} currentPage={currentPage} intro={displayPage?.intro} />
      <SurveyFlow currentPage={currentPage} />
      {displayPage?.component && React.createElement(displayPage.component, { saveSelfServeSurvey })}
    </PaddedBox>
  )
}

export default PageManager
