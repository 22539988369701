/* eslint-disable react-hooks/exhaustive-deps */
import { getConfig } from "@engaging-tech/ssr-config"
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import { apollo } from "../lib/Apollo"

const BUSINESS_APP_GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

const useBusinessAppMutation = (mutation, options = {}) => {
  const {
    initAction = null,
    successAction = null,
    failAction = null,
    entitlement = null,
    onSuccess = null,
    onError = null
  } = options

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [data, setData] = useState(null)

  const post = useCallback(
    async (variables, next) => {
      try {
        setIsLoading(true)
        const res = await apollo.mutate({
          uri: BUSINESS_APP_GRAPHQL_API_URL,
          entitlement,
          mutation,
          variables
        })

        if (res?.data) {
          setData(res.data)
          setIsLoading(false)
        }
        if (initAction) dispatch(initAction(isLoading))
        if (res?.data && successAction) dispatch(successAction(res.data))
        if (res?.data && next) next()
        if (res.data && onSuccess) onSuccess()
      } catch (error) {
        setErrors(error)
        if (failAction) dispatch(failAction(error))
        setIsLoading(false)
        if (onError) onError()
      }
    },
    [mutation]
  )

  return { post, isLoading, errors, data }
}

export default useBusinessAppMutation
