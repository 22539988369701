import { gql } from "../../../../../lib/Apollo"

export const GET_CORE_QUESTIONS = gql`
  query($type: TemplateType!) {
    getCoreQuestions(type: $type) {
      happinessQuestions {
        id
        type
        title
        description
        enabled
        required
        icon
        ... on DropdownQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }
        ... on RadioQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }

        ... on ScaleQuestion {
          min
          max
        }
        ... on ShortTextQuestion {
          fields {
            label
            limit
            type
          }
        }
      }
      additionalQuestions {
        id
        type
        title
        description
        enabled
        required
        icon
        ... on DropdownQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }
        ... on RadioQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }

        ... on ScaleQuestion {
          min
          max
        }
        ... on ShortTextQuestion {
          fields {
            label
            limit
            type
          }
        }
      }
    }
  }
`
