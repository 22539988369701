import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import { Card, DatePicker } from "../../components"

const StyledText = styled(Text)`
  visibility: ${({ hide }) => (hide ? "hidden" : "visible")};
`

const SurveyDate = ({
  values,
  errors,
  setFieldValue,
  readOnly,
  template,
  ...props
}) => {
  const disableDatesInPast = day => {
    return new Date() > day
  }

  const disableWeekends = day => {
    return day.getDay() === 0 || day.getDay() === 6
  }

  const disableStartDateDays = day => {
    if (disableDatesInPast(day)) return true
    if (disableWeekends(day)) return true
    return false
  }

  return (
    <Card {...props}>
      <Text mb={4}>
        When do you want your survey to start and finish? WorkL tip: We
        recommend your survey is open for at least a week to make sure everyone
        has a chance of completing it.
      </Text>
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignItems="center"
        width={1 / 1}
        height={readOnly ? [60, 30] : [250, 100]}
      >
        {readOnly ? (
          <Text>
            Start Date: <b>{values && values.startDate.toLocaleDateString()}</b>
          </Text>
        ) : (
          <Flex flexDirection="column" mb={[3, 0]}>
            <Text mb={2} ml={1}>
              Start Date
            </Text>
            <DatePicker
              width="250px"
              id="startDate"
              name="startDate"
              dayPickerInputProps={{
                disabledDays: disableStartDateDays
              }}
              handleChange={date => {
                setFieldValue("startDate", new Date(date.setHours(5, 0, 0, 0)))
                if (typeof window !== "undefined" && window.hj) {
                  window.hj("tagRecording", [
                    "[WFB][App][Surveys] Changed survey start date"
                  ])
                }
              }}
              value={values && values.startDate}
              error={errors && errors.startDate}
            />
          </Flex>
        )}
        {readOnly ? (
          <Text>
            End Date: <b>{values && values.endDate.toLocaleDateString()}</b>
          </Text>
        ) : (
          <Flex flexDirection={readOnly ? "row" : "column"} mb={[3, 0]}>
            <Text mb={2} ml={1}>
              End Date
            </Text>
            <DatePicker
              width="250px"
              id="endDate"
              name="endDate"
              dayPickerInputProps={{
                disabledDays: day => values.startDate > day
              }}
              handleChange={date => {
                setFieldValue("endDate", new Date(date.setHours(22, 0, 0, 0)))
                if (typeof window !== "undefined" && window.hj) {
                  window.hj("tagRecording", [
                    "[WFB][App][Surveys] Changed survey end date"
                  ])
                }
              }}
              value={values && values.endDate}
              error={errors && errors.endDate}
            />
          </Flex>
        )}
        <StyledText hide={errors && (errors.endDate || errors.startDate)}>
          Number of days:{" "}
          {
            <b>
              {Math.ceil(
                Math.abs(values.endDate - values.startDate) /
                  (1000 * 60 * 60 * 24)
              )}
            </b>
          }
        </StyledText>
      </Flex>
    </Card>
  )
}

export default SurveyDate
