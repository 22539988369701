import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import useBusinessAppQuery from "../../../../../hooks/useBusinessAppQuery"
import { LIST_ORGANISATION_SURVEYS } from "../../../../surveyManagement/surveySelection/services/surveys.queries"
import SliceAndDiceUpsellIAS from "../SliceAndDiceUpsellIAS"
import {
  getSurveysListFail,
  getSurveysListReq,
  getSurveysListSuccess
} from "./store/actions"
import { getSDUpsellSurveys } from "./store/selectors"

const SliceAndDiceUpsellGetLastSurveyContainer = () => {
  const surveysList = useSelector(getSDUpsellSurveys)
  const { errors, isLoading } = useBusinessAppQuery(LIST_ORGANISATION_SURVEYS, {
    initAction: getSurveysListReq,
    successAction: getSurveysListSuccess,
    failAction: getSurveysListFail
  })

  const getLastActiveReportId = useMemo(() => {
    const filterSurveysListByStatus = surveysList.filter(survey =>
      ["live", "scheduled"].includes(survey.status)
    )

    const sorteredActiveSurveys = filterSurveysListByStatus.sort(
      (a, b) => a.createdAt - b.createdAt
    )

    return sorteredActiveSurveys[sorteredActiveSurveys.length - 1]?.id
  }, [surveysList])

  if (errors || isLoading) {
    return null
  }

  if (getLastActiveReportId) {
    return <SliceAndDiceUpsellIAS reportId={getLastActiveReportId} />
  }

  return null
}

export default SliceAndDiceUpsellGetLastSurveyContainer
