import { connect } from "react-redux"
import LiveJobsList from "../components/LiveJobList"
import { getLiveJobs } from "../../../store/jobs/jobs.selector"

const mapState = state => ({
  liveJobs: getLiveJobs(state)
})

const LiveJobs = connect(mapState, null)(LiveJobsList)

export default LiveJobs
