import React, { useState } from "react"
import { Flex, Text, Icon } from "@engaging-tech/components"
import styled from "styled-components"
import StepInput from "./StepInput"
import StepList from "./StepList"
import StepEmptyState from "./StepEmptyState"

const IconBtn = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

const CustomInput = styled.input`
  width: 65%;
  height: 20px;
  border-radius: 2px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[0]}`};
`

const EditStepBody = ({
  bespokeStepQuestions,
  deleteQuestionFromStep,
  setQuestionTitle
}) => {
  const [openEditInput, setOpenEditInput] = useState("")
  const [editText, setEditText] = useState("")

  const handleClick = questionId => {
    setQuestionTitle(editText, questionId)
    setOpenEditInput("")
  }

  return (
    <Flex width={1 / 1} height="160px" flexDirection="column" mt="9px">
      {bespokeStepQuestions.map((question, i) => (
        <Flex width="inherit" mb={1} key={question.id}>
          <Text width="15%" textAlign="center" color="secondary.0" fontSize={3}>
            {" "}
            Q{i + 1}.
          </Text>
          {openEditInput === question.id ? (
            <CustomInput
              value={editText}
              onChange={e => setEditText(e.target.value)}
            />
          ) : (
            <Text width="65%" color="secondary.0" fontSize={3}>
              {question.title}
            </Text>
          )}
          <Flex width="20%" justifyContent="center">
            {openEditInput === question.id ? (
              <>
                <IconBtn
                  name="checkmark"
                  mr={1}
                  onClick={() => handleClick(question.id)}
                />
                <Icon name="" />
              </>
            ) : (
              <>
                <IconBtn
                  name="edit"
                  mr={1}
                  onClick={() => {
                    setOpenEditInput(question.id)
                    setEditText(question.title)
                  }}
                />
                <IconBtn
                  name="delete"
                  onClick={() => deleteQuestionFromStep(question)}
                />
              </>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

const StepBody = ({
  openInput,
  selectedStep,
  setSelectedStep,
  bespokeSteps,
  setLocalText,
  errorMsg,
  openStepEdit,
  bespokeStepQuestions,
  deleteQuestionFromStep,
  setQuestionTitle
}) => {
  if (openStepEdit)
    return (
      <EditStepBody
        deleteQuestionFromStep={deleteQuestionFromStep}
        bespokeStepQuestions={bespokeStepQuestions}
        setQuestionTitle={setQuestionTitle}
      />
    )

  return (
    <Flex width="350px" height="159px">
      {openInput && (
        <StepInput errorMsg={errorMsg} setLocalText={setLocalText} />
      )}

      {bespokeSteps.length > 0 && !openInput && (
        <StepList
          bespokeSteps={bespokeSteps}
          setSelectedStep={setSelectedStep}
          selectedStep={selectedStep}
        />
      )}

      {bespokeSteps.length === 0 && !openInput && <StepEmptyState />}
    </Flex>
  )
}

export default StepBody
