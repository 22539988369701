import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import { StripeWrapper, BillingForm } from "@engaging-tech/payments"
import { getConfig } from "@engaging-tech/ssr-config"
import styled from "styled-components"
import Dialog from "../../jobs/components/Dialog"
import ApplyDiscount from "../../forms/ApplyDiscount"
import { getDiscountedPrice } from "../../../utils/index"

const STRIPE_KEY = () => getConfig().keys.stripe

const EBN_FULL_PRICE = 500

const StrikeThroughText = styled(Text)`
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 3px solid ${({ theme }) => theme.colors.dark[1]};

    transform: rotate(-15deg);
  }
`

const DisplayPrice = ({ amountOff, percentOff, fullPrice }) => {
  const price = getDiscountedPrice({
    amountOff,
    percentOff,
    fullPrice
  })
  return (
    <Flex width={1} justifyContent="flex-end" mt={4}>
      {price < fullPrice && (
        <>
          <StrikeThroughText
            color="dark.2"
            fontWeight="600"
            fontSize={5}
            mb={3}
            mr={2}
            width="55px"
            textAlign="center"
          >
            £{fullPrice}
          </StrikeThroughText>

          <Text
            color="primary.0"
            fontWeight="600"
            fontSize={5}
            mb={3}
            mr={2}
            width="auto"
          >
            £{Math.round(price)}
          </Text>
          {Math.round(price) > 0 && (
            <Text
              color="dark.2"
              fontWeight="600"
              fontSize={5}
              mb={3}
              width="auto"
            >
              + VAT per year
            </Text>
          )}
        </>
      )}
      {price === fullPrice && (
        <Text color="dark.2" fontWeight="600" fontSize={5} mb={3} width="auto">
          £{fullPrice} + VAT per year
        </Text>
      )}
    </Flex>
  )
}

const EBNBilling = ({
  isLoading,
  intentSecret,
  paymentStatus,
  onBack,
  onSubmit,
  onPaymentActionSuccess,
  onPaymentActionFail,
  onApplyDiscount,
  discountState
}) => {
  const paymentAction = intentSecret
    ? { action: "handle_card_payment", intentSecret }
    : null

  React.useEffect(() => {
    if (paymentStatus === "completed") {
      onPaymentActionSuccess()
    }
  }, [paymentStatus, onPaymentActionSuccess])

  return (
    <Dialog title="Billing Information">
      <Text color="dark.0" fontWeight="500" fontSize={3} mb={3}>
        Please enter your billing information in the form below:
      </Text>
      <StripeWrapper apiKey={STRIPE_KEY()}>
        <BillingForm
          isLoading={isLoading}
          paymentAction={paymentAction}
          onSubmit={{
            action: onSubmit,
            text: "Purchase",
            variant: "primary.0"
          }}
          onCancel={{
            action: onBack,
            text: "Cancel"
          }}
          onPaymentActionSuccess={{
            action: onPaymentActionSuccess,
            text: "Success Payment Action"
          }}
          onPaymentActionFail={{
            action: onPaymentActionFail,
            text: "Failed Payment Action"
          }}
        >
          <ApplyDiscount onSubmit={onApplyDiscount} {...discountState} />
          <DisplayPrice {...discountState} fullPrice={EBN_FULL_PRICE} />
        </BillingForm>
      </StripeWrapper>
    </Dialog>
  )
}

export default EBNBilling
