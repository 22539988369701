import { takeLatest, put } from "redux-saga/effects"
import * as Actions from "./EBN.actions"
import { articles, lectures, webinars, research } from "../lib/EBNLibrary"

export function* loadLecturesSaga(action) {
  try {
    // const res = yield call(loadHubData, action.payload)
    const res = lectures
    yield put(Actions.loadLecturesSuccess(res))
  } catch {
    yield put(Actions.loadLecturesFail())
  }
}

export function* loadResearchSaga(action) {
  try {
    // const res = yield call(loadHubData, action.payload)
    const res = research
    yield put(Actions.loadResearchSuccess(res))
  } catch {
    yield put(Actions.loadResearchFail())
  }
}
export function* loadArticlesSaga(action) {
  try {
    const res = articles
    yield put(Actions.loadArticlesSuccess(res))
  } catch {
    yield put(Actions.loadArticlesFail())
  }
}

export function* loadWebinarsSaga(action) {
  try {
    // const res = yield call(loadHubData, action.payload)
    const res = webinars
    yield put(Actions.loadWebinarsSuccess(res))
  } catch {
    yield put(Actions.loadWebinarsFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_LECTURES, loadLecturesSaga)
  yield takeLatest(Actions.types.LOAD_RESEARCH, loadResearchSaga)
  yield takeLatest(Actions.types.LOAD_ARTICLES, loadArticlesSaga)
  yield takeLatest(Actions.types.LOAD_WEBINARS, loadWebinarsSaga)
}
