import { Flex, Icon, Text } from "@engaging-tech/components"
import { usePaths, useRouter, Link } from "@engaging-tech/routing"
import React from "react"
import EBNCard from "../../ui/components/EBNCard"

const UnlockButton = ({ hasBIPSubscription, isEBNMember, name }) => {
  const router = useRouter()
  const paths = usePaths()
  const handleUnlock = () => router.redirect(paths.account.joinBIP)

  return (
    <EBNCard
      width={["100%", "330px"]}
      height="60px"
      isSubscribed={hasBIPSubscription || isEBNMember?.active}
      unlockText="Create Business Information Page"
      handleUnlock={handleUnlock}
      BIP
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Link to={paths.home.businessInformationHub}>
          <Flex alignItems="center">
            <Icon name="store" size={32} color="dark.1" />
            <Text width="auto" ml={3} fontSize={5}>
              {name}
            </Text>
          </Flex>
        </Link>
      </Flex>
    </EBNCard>
  )
}

const Header = ({
  name,
  title,
  hasBIPSubscription,
  isEBNMember,
  unlockButton
}) => {
  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      justifyContent="space-between"
      alignItems={["center", "center", "flex-start"]}
    >
      <Text
        fontSize={7}
        mb={[0, 0, 4]}
        mr={[0, 0, 3]}
        pb={5}
        as="h1"
        fontWeight={700}
        width="auto"
      >
        {title}
      </Text>
      {unlockButton && (
        <UnlockButton
          name={name}
          hasBIPSubscription={hasBIPSubscription}
          isEBNMember={isEBNMember}
        />
      )}
    </Flex>
  )
}

export default Header
