import { connect } from "react-redux"

import Payment from "../../views/Payment"
import { getSurveyId } from "../../store/awards.selectors"

const mapState = state => ({
  surveyId: getSurveyId(state)
})

const CheckoutContainer = connect(mapState, null)(Payment)

export default CheckoutContainer
