import { pdf } from "@react-pdf/renderer"
import "canvas-toBlob"
// polyfill for unsupported browsers
import { saveAs } from "file-saver"
import html2canvas from "html2canvas"
import React from "react"

import SurveyReport from "./SurveyReport"

const exportTypes = {
  print: "PRINT",
  download: "DOWNLOAD"
}

const generateImages = async (updatePDFProgress, pages, downloadingRef) => {
  let pagesToRender = []
  let completedPages = 0

  for (const page of Array.from(pages)) {
    pagesToRender.push(page)
  }

  const canvasArray = await Promise.all(
    pagesToRender.map(async elem => {
      if (!document.body.contains(elem)) {
        console.log("Unable to find element")
        throw new Error("Unable to find element")
      }

      const canvas = await html2canvas(elem, { imageTimeout: 6000 })
      completedPages += 1
      if (!canvas) throw new Error("Failed to create canvas")

      updatePDFProgress({ completedPages })
      return canvas
    })
  )

  if (!downloadingRef.current) {
    throw new Error("PDF generation canceled")
  }

  const blobs = await Promise.all(
    canvasArray.map(
      canvas =>
        new Promise(resolve => {
          canvas.toBlob(blob => resolve(URL.createObjectURL(blob))) // toBlob on canvas needs polyfill for some browsers
        })
    )
  )

  updatePDFProgress({ isAllImagesGenerated: true })
  return blobs
}

export const generateDownload = (exportType, exportTypes, blob, title) => {
  if (exportType === exportTypes.print) {
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, "_blank")
  } else if (exportType === exportTypes.download) {
    saveAs(blob, `${title}.pdf`)
  } else {
    saveAs(blob, `${title}.pdf`)
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, "_blank")
  }
}

const generatePDF = async ({
  pages,
  downloadingRef,
  updatePdfDownloading,
  title,
  subSection,
  theme,
  filters,
  updatePDFProgress,
  exportType
}) => {
  try {
    let totalPages = pages.length
    updatePDFProgress({ totalPages })
    const blobs = await generateImages(updatePDFProgress, pages, downloadingRef)

    if (!downloadingRef.current) {
      throw Error("PDF generation canceled")
    }

    const blob = await pdf(
      <SurveyReport
        images={blobs}
        title={title}
        filters={filters}
        subSection={subSection}
        theme={theme}
      />
    ).toBlob()

    if (downloadingRef.current) {
      updatePDFProgress({ pdfGenerated: true })
      generateDownload(exportType, exportTypes, blob, title)
      updatePdfDownloading(false)
    }
  } catch (err) {
    updatePdfDownloading(false)
  }
}

export default generatePDF
