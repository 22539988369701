import React from "react"
import styled from "styled-components"
import { useRouter, Link } from "@engaging-tech/routing"
import { goBack } from "@engaging-tech/frontend-utils"
import { Button } from "@engaging-tech/components"

const LowercaseButton = styled(Button)`
  text-transform: none;
`

const BackButton = ({ children = "Back to business", path, ...props }) => {
  const router = useRouter()

  const { url } = goBack(router.current())
  return (
    <Link to={path || url}>
      <LowercaseButton
        variant="light.0"
        borderRadius={99}
        leadingIcon="arrow_back"
        {...props}
      >
        {children}
      </LowercaseButton>
    </Link>
  )
}

export default BackButton
