import { Box, Flex, Paragraph, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const OverflowBox = styled(Box)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const EmptyMatrix = () => {
  return (
    <Box position="relative" width={1 / 1}>
      <OverflowBox width={1 / 1}>
        <Box minWidth={["auto", 650]}>
          <img
            width="100%"
            // eslint-disable-next-line global-require
            src={require(`${process.env.RAZZLE_PUBLIC_DIR}/img/Empty_State_Matrix.png`)}
            alt="comparison placeholder"
            position="relative"
          />
        </Box>
      </OverflowBox>
      <Flex
        position="absolute"
        top={[0, "30%"]}
        left={[0, "35%"]}
        width={[1 / 1, 1 / 3]}
        flexDirection="column"
        alignItems="center"
      >
        <Text
          fontSize={[3, 4]}
          fontWeight={500}
          textAlign="center"
          width="auto"
          color="dark.1"
          mb={2}
        >
          You do not have enough data under your selected filters to show these
          matrices.
        </Text>
        {[
          "Try changing your selected filters to a broader range.",
          "Please note: no data will show if you have less than 5 submissions under any combination of filters."
        ].map(text => (
          <Paragraph
            fontSize={[3, 4]}
            width={1 / 1}
            key={text}
            color="dark.2"
            textAlign="center"
          >
            {text}
          </Paragraph>
        ))}
      </Flex>
    </Box>
  )
}

export default EmptyMatrix
