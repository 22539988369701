import { connect } from "react-redux"

import { isCurrentUseradmin } from "../../../account/store/account.selectors"
import { getIsPdfDownloading } from "../../store/report.selectors"
import ReportHeaderComponent from "../components/ReportHeader"
import * as Selectors from "../store/report.header.selectors"

const mapState = (state, props) => ({
  title: props.title ? props.title : Selectors.getTitle(state),
  layout: Selectors.getLayout(state),
  isPdfDownloading: getIsPdfDownloading(state),
  isAdmin: isCurrentUseradmin(state)
})

const ReportHeader = connect(mapState)(ReportHeaderComponent)

export default ReportHeader
