import { connect } from "react-redux"
import DatePicker from "../../../selfServiceSurveys/components/Page2/DatePicker"
import {
  getEndDate,
  getPageErrors,
  getStartDate,
  getAwardsEndDate
} from "../../store/awards.selectors"
import { setDate, setPageErrors } from "../../store/awards.actions"

const mapState = state => ({
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  pageErrors: getPageErrors(state),
  maxEndDate: getAwardsEndDate(state)
})

const mapDispatch = (dispatch, { id }) => ({
  setDate: dateObj => dispatch(setDate(dateObj)),
  handleErrors: (errors, type) => dispatch(setPageErrors(errors, type, id))
})

const DatePickerContainer = connect(mapState, mapDispatch)(DatePicker)

export default DatePickerContainer
