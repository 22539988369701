import { Flex, H3, Text, MotionToggle } from "@engaging-tech/components"
import React from "react"

const handleMode = stateMode => {
  if (stateMode === "uniqueSubmission") return "shareableLinks"
  if (stateMode === "shareableLinks") return "uniqueSubmission"
  return "uniqueSubmission"
}

const ParticipantsIntro = ({ mode, title, text, setMode, stateMode }) => (
  <>
    <Flex width={1 / 1} justifyContent="space-between" mb={2}>
      <H3 fontSize={5} color="secondary.0" fontWeight={600} width={0.85 / 1}>
        {title}
      </H3>
      {/* Temporary Disabled */}
      {/* <MotionToggle
        type="radio"
        name="mode"
        value={stateMode === mode}
        onChange={() => setMode(handleMode(stateMode))}
      /> */}
    </Flex>
    {text.map(t => (
      <Text mb={3} color="secondary.0" key={t}>
        {t}
      </Text>
    ))}
  </>
)

export default ParticipantsIntro
