import { connect } from "react-redux"
import FiltersButton from "../components/FiltersButton"
import * as Selectors from "../store/filters.selectors"

const mapState = state => ({
  activeFilters: Selectors.getActiveFilterList(state),
  availableFilters: Selectors.getAvailableFilters(state)
})

const Filters = connect(mapState)(FiltersButton)

export default Filters
