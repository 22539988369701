export const types = {
  SET_CURRENT_PAGE: "[SELF SERVE ORG] Set Current Page",
  SET_TITLE: "[SELF SERVE ORG] Set Title",
  SET_INTRODUCTION: "[SELF SERVE ORG] Set Introduction",
  SET_DATE: "[SELF SERVE ORG] Set Date",
  SET_MODE: "[SELF SERVE ORG] Set Mode",
  SET_AMOUNT: "[SELF SERVE ORG] Set Amount",
  SET_PRIVATE: "[SELF SERVE ORG] Set Private",
  SET_RECIPIENTS: "[SELF SERVE ORG] Set Recipients",
  REMOVE_RECIPIENTS: "[SELF SERVE ORG] Remove Recipients",
  SAVE_SELF_SERVE_SURVEY_DATA_FAIL:
    "[SELF SERVE ORG] Save Self Serve Survey Data Fail",
  SAVE_SELF_SERVE_SURVEY_DATA_SUCCESS:
    "[SELF SERVE ORG] Save Self Serve Survey Data Success",
  RESET_PAGE: "[SELF SERVE ORG] Reset Page",
  SET_SURVEY_TYPE: "[SELF SERVE ORG] Survey Type",
  SET_OBJECT_TYPE: "[SELF SERVE ORG] Set Object Type"
}

export const setObjectType = payload => ({
  type: types.SET_OBJECT_TYPE,
  payload
})

export const setSurveyType = payload => ({
  type: types.SET_SURVEY_TYPE,
  payload
})

export const resetPage = () => ({
  type: types.RESET_PAGE
})

export const saveSelfServeSurveyDataSuccess = payload => ({
  type: types.SAVE_SELF_SERVE_SURVEY_DATA_SUCCESS,
  payload: payload.saveSelfServeSurvey
})

export const saveSelfServeSurveyDataFail = payload => ({
  type: types.SAVE_SELF_SERVE_SURVEY_DATA_FAIL,
  payload
})

export const setAmount = payload => ({
  type: types.SET_AMOUNT,
  payload
})

export const setMode = payload => ({
  type: types.SET_MODE,
  payload
})

export const setRecipients = payload => ({
  type: types.SET_RECIPIENTS,
  payload
})

export const removeRecipients = payload => ({
  type: types.REMOVE_RECIPIENTS,
  payload
})

export const setCurrentPage = payload => ({
  type: types.SET_CURRENT_PAGE,
  payload
})

export const setTitle = payload => ({
  type: types.SET_TITLE,
  payload
})

export const setIntroduction = payload => ({
  type: types.SET_INTRODUCTION,
  payload
})

export const setDate = payload => ({
  type: types.SET_DATE,
  payload
})

export const setPrivate = payload => ({
  type: types.SET_PRIVATE,
  payload
})
