import { BarChart, Flex, Text } from "@engaging-tech/components"
import React from "react"

const ScoreRepartition = ({ graphData, exportingPDF, ...props }) => {
  const decimalNo = graphData.datasets[0].data.map(setNo => {
    if (!Number.isInteger(setNo)) {
      return +Number.parseFloat(setNo).toFixed(1)
    }
    return setNo
  })

  const graphDataWithDecimal = {
    labels: graphData.labels,
    datasets: [{ data: decimalNo }]
  }

  return (
    <Flex
      bg="dark.4"
      height="auto"
      width="auto"
      borderRadius={10}
      p={3}
      flexDirection="column"
      {...props}
    >
      <Text fontWeight={400} fontSize={3} color="dark.2">
        Score Repartition
      </Text>
      <BarChart
        disableAllAnimation={exportingPDF}
        showDataLabels
        width="100%"
        dataset={graphDataWithDecimal}
        graphOptions={{
          scales: {
            y: {
              min: 0,
              max: 100
            }
          }
        }}
        mt={3}
        height="100%"
        unit="%"
        colorScale={[
          "#EF464A",
          "#EF464A",
          "#EF464A",
          "#EF464A",
          "#EF464A",
          "#EF464A",
          "#EF464A",
          "#FFB547",
          "#FFB547",
          "#0DC187",
          "#0DC187"
        ]}
      />
    </Flex>
  )
}

export default ScoreRepartition
