import React from "react"
import { Flex, Text, Button } from "@engaging-tech/components"

/**
 * A banner prompting a user to confirm their email address.
 * @param {boolean} isConfirmed Whether the user has already confirmed their email address or not.
 * @param {boolean} isAuthenticated Flag to determine if the user has signed in or not.
 * @param {function} sendVerification The function to call when the "Send Verification" button is clicked.
 */
const ConfirmBanner = ({ isConfirmed, isAuthenticated, sendVerification }) => (
  <>
    {!isConfirmed && isAuthenticated && (
      <Flex width={1 / 1} height={52} alignItems="center" bg="dark.4" px={3}>
        <Text color="dark.2" fontSize={3} mr={[3, 4]} width="auto">
          Please verify your email address
        </Text>
        <Button
          fontSize={3}
          onClick={sendVerification}
          variant="text.textPrimary.0"
          width="auto"
          elevation={0}
        >
          Send Verification
        </Button>
      </Flex>
    )}
  </>
)

export default ConfirmBanner
