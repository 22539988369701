const dateSort = data => {
  return data.sort((f, s) => {
    const a = f.x
      .split("/")
      .reverse()
      .join("")
    const b = s.x
      .split("/")
      .reverse()
      .join("")
    return a > b ? 1 : a < b ? -1 : 0
  })
}

export default dateSort
