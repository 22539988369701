import { Spinner } from "@engaging-tech/components"
import React from "react"

import { GET_BIP } from "../../graphql/queries"
import useBusinessAppQuery from "../../hooks/useBusinessAppQuery"
import DisplayProduct from "../ui/components/DisplayProduct"
import BIPForm from "./form"
import BIPNavigationContextProvider from "./navigation-context"

export default function BIPView() {
  const { errors, isLoading } = useBusinessAppQuery(GET_BIP)

  if (errors?.networkError?.result.errors.filter(error => error.extensions.code === "ERR403")[0])
    return <DisplayProduct />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return (
    <BIPNavigationContextProvider>
      <BIPForm />
    </BIPNavigationContextProvider>
  )
}
