import { connect } from "react-redux"
import { setMode } from "../../store/actions"
import { getMode } from "../../store/selectors"
import Participants from "../../components/Page2/Participants"

const mapState = state => ({
  mode: getMode(state)
})

const mapDispatch = dispatch => ({
  setMode: mode => dispatch(setMode(mode))
})

const ParticipantsContainer = connect(mapState, mapDispatch)(Participants)

export default ParticipantsContainer
