import { connect } from "react-redux"
import { getIsLoading } from "../../store/report.selectors"
import TwoBtnDialogue from "../components/TwoBtnDialogue"

const mapState = state => ({
  isLoading: getIsLoading(state)
})

const TwoBtnDialogueContainer = connect(mapState)(TwoBtnDialogue)

export default TwoBtnDialogueContainer
