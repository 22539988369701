import { Footer as FooterComponent } from "@engaging-tech/navigation"
import { usePaths, useRoutes } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import AppBadges from "./AppBadges"
import SiteLogo from "./SiteLogo"

const socialMediaLinks = [
  {
    name: "facebook",
    url: "https://www.facebook.com/WorkLforBusiness"
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/worklforbusiness"
  },
  {
    name: "twitter",
    url: "https://twitter.com/WorkLforBusines"
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/workl_for_business"
  }
]

const Footer = () => {
  const routes = useRoutes()
  const paths = usePaths()
  const legalRoutes = routes.find(route => route.path === paths.legal.index)
    .children

  const footerRoutes = [
    {
      title: "About",
      routes: [
        {
          linkProps: {
            external: true,
            newTab: true
          },
          description: "Contact us",
          path: `${getConfig().publicSite}${getConfig().publicPaths.contactUs}`
        },
        {
          linkProps: {
            external: true,
            newTab: true
          },
          description: "Pricing Plans",
          path: `${getConfig().publicSite}${
            getConfig().publicPaths.pricingPlans
          }`
        }
      ]
    },
    {
      title: "Products",
      routes: [
        routes.find(route => route.path === paths.surveys.index),
        routes.find(route => route.path === paths.home.index),
        {
          linkProps: {
            external: true,
            newTab: true
          },
          description: "Business Services",
          path: `${getConfig().publicSite}${
            getConfig().publicPaths.businessServices
          }`
        }
      ]
    },
    {
      title: "Legal",
      routes: [
        legalRoutes.find(route => route.path === paths.legal.cookies),
        legalRoutes.find(route => route.path === paths.legal.privacyPolicy),
        legalRoutes.find(route => route.path === paths.legal.termsAndConditions)
      ]
    }
  ]

  return (
    <FooterComponent
      sections={footerRoutes}
      socialMedia={socialMediaLinks}
      badges={AppBadges}
      Logo={SiteLogo}
    />
  )
}

export default Footer
