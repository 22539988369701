/* eslint-disable camelcase */
import { useParams } from "@engaging-tech/routing"
import React from "react"

import PageManagement from "../containers/PageManagement"

const SixStepsPages = ({ exportingPDF }) => {
  const { step } = useParams()

  return <PageManagement step={step} exportingPDF={exportingPDF} />
}

export default SixStepsPages
