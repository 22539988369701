import { connect } from "react-redux"
import CompleteDialog from "../../forms/Complete"
import { getPostJob } from "../../../../store/jobs/jobs.selector"
import { clearJobData } from "../../../../store/jobs/jobs.actions"

const mapState = state => ({
  postedJob: getPostJob(state)
})

const mapDispatch = dispatch => ({
  clearJobData: () => dispatch(clearJobData())
})

const Complete = connect(mapState, mapDispatch)(CompleteDialog)

export default Complete
