import React from "react"
import {
  Text,
  Flex,
  Box,
  DesktopOnly,
  TabletDown
} from "@engaging-tech/components"
import styled from "styled-components"
import { format } from "date-fns"
import ClickableIcon from "../../ui/components/ClickableIcon"
import CircleIcon from "../../ui/components/CircleIcon"
import Carousel from "../../ui/components/Carousel"

const CoverImage = styled.img`
  height: 150px;
  width: 288px;
  object-fit: cover;
  @media (max-width: 414px) {
    margin-right: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    height: 100px;
    width: 178px;
  }
`

const BorderedBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.primary[0]};
  border-radius: 4px;
  &:hover {
    cursor: "pointer";
  }
`

const StyledCircleIcon = styled(CircleIcon)`
  position: absolute;
  margin-top: 101px;
  margin-left: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-top: 40px;
    margin-left: 4px;
  }
`

const Resource = ({ pdf, coverImage, title, summary, publishedAt }) => (
  <a href={pdf.link} target="_blank noopener noreferrer" download>
    <BorderedBox
      width={["180px", "290px", "290px"]}
      height={["100%", "300px", "300px"]}
      mr={[0, 0, 1]}
      // mr={[0]}
      mb={2}
    >
      <StyledCircleIcon name="attach_file" bg="#E2F5FE" />
      <CoverImage src={coverImage.source} alt={coverImage.caption} />
      <Flex p={2} flexDirection="column" width={1 / 1}>
        <Text fontWeight={700} fontSize={3} my={1}>
          {title}
        </Text>
        <Text fontSize={3}>
          {summary.slice(0, 80)}
          {summary.length > 80 && <Text>...</Text>}
        </Text>
        <Flex flexDirection="row" alignItems="center" my={2}>
          <ClickableIcon name="date_range" color="dark.2" mr={2} />
          {publishedAt && (
            <Text fontWeight={500} color="dark.2" fontSize={3}>
              {format(new Date(publishedAt), "MMMM yyyy")}
            </Text>
          )}
        </Flex>
      </Flex>
    </BorderedBox>
  </a>
)

const Resources = ({ resources }) => {
  return (
    <>
      <DesktopOnly width={1 / 1}>
        <Carousel
          carouselItems={resources}
          numberOfItems={3}
          componentToShow={Resource}
        />
      </DesktopOnly>
      <TabletDown width={1 / 1}>
        <Carousel
          carouselItems={resources}
          numberOfItems={1}
          componentToShow={Resource}
        />
      </TabletDown>
    </>
  )
}

export default Resources
