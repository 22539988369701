import React from "react"
import EBNSuccessView from "../../features/account/views/EBNSuccessView"
import GradientLayout from "../../layouts/GradientLayout"

const EBNSuccess = () => (
  <GradientLayout>
    <EBNSuccessView />
  </GradientLayout>
)

export default EBNSuccess
