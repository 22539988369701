import React from "react"
import JoinJobsView from "../../features/account/views/JoinJobs"
import GradientLayout from "../../layouts/GradientLayout"

const JoinJobs = () => {
  return (
    <GradientLayout>
      <JoinJobsView />
    </GradientLayout>
  )
}

export default JoinJobs
