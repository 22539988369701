import layoutIndexHelper from "../../helpers/LayoutIndexHelper"
// eslint-disable-next-line import/no-cycle
import otherHelper from "../../helpers/otherHelper"
import questionIconOptions from "../../lib/questionIconOptions"
import questionTypeOptions from "../../lib/questionTypeOptions"
import { types as genericTypes } from "../generic/generic.actions"
import { types } from "./activeTemplate.actions"

const dotProp = require("dot-prop-immutable")

export const defaultParameters = {
  other: {
    filterReport: true
  },
  scale: {
    startOfScale: 1,
    endOfScale: 10
  },
  shortText: {
    fields: []
  },
  multipleChoice: {
    filterReport: true,
    label: "",
    options: []
  },
  dropdown: {
    filterReport: true,
    label: "",
    options: []
  },
  smileys: {
    filterReport: true,
    label: "",
    options: []
  }
}

// trueType field is used for referencing a core question type as on the backend there is more types that are used than the 4 frontend types
export const defaultQuestionObject = {
  type: questionTypeOptions[0].id,
  coreQuestion: false,
  parameters: {
    scale: defaultParameters.scale,
    shortText: defaultParameters.shortText,
    multipleChoice: defaultParameters.multipleChoice,
    dropdown: defaultParameters.dropdown,
    smileys: defaultParameters.smileys,
    other: defaultParameters.other
  },
  questionText: "",
  description: "",
  coreQuestionId: "01",
  required: true,
  disableCoreQuestionToggle: false,
  icon: questionIconOptions[0].id,
  trueType: null
}

export const defaultSectionObject = {
  title: "",
  description: "",
  questions: {},
  stackMode: false
}

export const initialState = {
  isLoading: false,
  isSaving: false,
  hasError: false,
  title: "",
  id: null,
  description: "",
  isDraft: true,
  type: "organisation",
  sections: {
    0: {
      ...defaultSectionObject,
      id: otherHelper.generateRandomId().toString()
    }
  },
  bespokeSteps: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // getting generic types, to record when modal is closed
    case genericTypes.SET_SHOW_VALIDATION_MODAL: {
      if (!action.payload.showValidationModal) {
        return {
          ...state,
          isSaving: false,
          hasError: false
        }
      }
      return state
    }

    case types.SET_BESPOKE_STEPS: {
      return {
        ...state,
        bespokeSteps: action.payload
      }
    }

    case types.SAVE_TEMPLATE: {
      return {
        ...state,
        isSaving: true,
        hasError: false
      }
    }

    case types.LOAD_TEMPLATE: {
      return {
        ...state,
        isLoading: true,
        hasError: false
      }
    }

    case types.SAVE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasError: false
      }
    }

    case types.LOAD_TEMPLATE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        hasError: false
      }
    }

    case types.SAVE_TEMPLATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        hasError: "Saving"
      }
    }

    case types.LOAD_TEMPLATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        hasError: "Loading"
      }
    }

    case types.SORT_QUESTION_DROPDOWN_ALPHABETICAL: {
      const locationString = `data.sections.${action.payload.sectionIndex}.questions.${action.payload.questionIndex}`
      const question = dotProp.get(state, locationString)
      question.parameters.dropdown.options.sort((a, b) => {
        if (a.value > b.value) {
          return 1
        }
        if (b.value > a.value) {
          return -1
        }
        return 0
      })
      return state
    }

    case types.SET_CORE_QUESTION_SUCCESS: {
      const locationString = `sections.${action.payload.sectionIndex}.questions.${action.payload.questionIndex}`
      return dotProp.set(state, locationString, action.payload.coreQuestionData)
    }

    case types.SET_STACK_ALL_SECTIONS: {
      const sections = dotProp.get(state, "sections")
      return Object.keys(sections).reduce(
        (returnedState, sectionKey) =>
          dotProp.set(
            returnedState,
            `sections.${sectionKey}.stackMode`,
            action.payload
          ),
        state
      )
    }

    case types.UPDATE_TEMPLATE_VALUE: {
      const locationString = `${action.payload.key}`
      return dotProp.set(state, locationString, action.payload.value)
    }

    case types.DELETE_QUESTION: {
      const questions = dotProp.get(
        state,
        `sections.${action.payload.sectionIndex}.questions`
      )
      const newQuestionSet = layoutIndexHelper.removeIndex({
        layout: questions,
        removeIndex: parseInt(action.payload.questionIndex, 10)
      })
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", [
          "[WFB][App][Survey Templates] Deleted question"
        ])
      }
      return dotProp.set(
        state,
        `sections.${action.payload.sectionIndex}.questions`,
        newQuestionSet
      )
    }

    case types.DELETE_SECTION: {
      const sections = dotProp.get(state, "sections")
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", [
          "[WFB][App][Survey Templates] Deleted section"
        ])
      }
      const newSectionSet = layoutIndexHelper.removeIndex({
        layout: sections,
        removeIndex: parseInt(action.payload.sectionIndex, 10)
      })
      return dotProp.set(state, "sections", newSectionSet)
    }

    case types.MOVE_QUESTION: {
      const sections = dotProp.get(state, "sections")
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", [
          "[WFB][App][Survey Templates] Moved question"
        ])
      }
      const newSectionsSet = layoutIndexHelper.moveQuestion({
        layout: sections,
        ...action.payload
      })
      return dotProp.set(state, "sections", newSectionsSet)
    }

    case types.MOVE_SECTION: {
      const sections = dotProp.get(state, "sections")
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", [
          "[WFB][App][Survey Templates] Moved section"
        ])
      }
      const newSectionsSet = layoutIndexHelper.moveSection({
        layout: sections,
        ...action.payload
      })
      return dotProp.set(state, "sections", newSectionsSet)
    }

    case types.ADD_QUESTION_SUCCESS: {
      const questions = dotProp.get(
        state,
        `sections.${action.payload.sectionIndex}.questions`
      )
      const newQuestionSet = layoutIndexHelper.insertIndex({
        layout: questions,
        insertIndex: parseInt(action.payload.questionIndex, 10) + 1,
        itemToInsert: action.payload.newQuestion
      })

      const newState = dotProp.set(
        state,
        `sections.${action.payload.sectionIndex}.questions`,
        newQuestionSet
      )
      return dotProp.set(
        newState,
        `sections.${action.payload.sectionIndex}.stackMode`,
        false
      )
    }

    case types.ADD_SECTION: {
      const sections = dotProp.get(state, "sections")
      const newSection = {
        ...defaultSectionObject,
        id: otherHelper.generateRandomId()
      }

      const newSectionSet = layoutIndexHelper.insertIndex({
        layout: sections,
        insertIndex: parseInt(action.payload.sectionIndex, 10) + 1,
        itemToInsert: newSection
      })
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", [
          "[WFB][App][Survey Templates] Added section"
        ])
      }
      return dotProp.set(state, "sections", newSectionSet)
    }

    case types.UPDATE_SECTION_VALUE: {
      return dotProp.set(
        state,
        `sections.${action.payload.sectionIndex}.${action.payload.key}`,
        action.payload.value
      )
    }

    case types.UPDATE_QUESTION_VALUE: {
      const locationString = `sections.${action.payload.sectionIndex}.questions.${action.payload.questionIndex}.`
      let rState = state
      const mutateState = ({ st, key, value, mutationType }) => {
        switch (mutationType) {
          case "delete":
            return dotProp.delete(st, locationString + key, value)
          case "merge":
            return dotProp.merge(st, locationString + key, value)
          case "toggle":
            return dotProp.toggle(st, locationString + key)
          default:
            return dotProp.set(st, locationString + key, value)
        }
      }
      if (Array.isArray(action.payload.mutation)) {
        rState = action.payload.mutation.reduce(
          (returnedState, mutation) =>
            mutateState({
              st: returnedState,
              key: mutation.key,
              value: mutation.value,
              mutationType: mutation.mutationType
            }),
          rState
        )
      } else {
        rState = mutateState({
          st: rState,
          key: action.payload.mutation.key,
          value: action.payload.mutation.value,
          mutationType: action.payload.mutation.mutationType
        })
      }
      return rState
    }

    case types.RESET: {
      return initialState
    }

    default:
      return state
  }
}
