/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { Text } from "@engaging-tech/components"
import ParticipantsIntro from "./ParticipantsIntro"
import { TextField } from "../../../../surveyManagement/components"
import { findIfDisabled } from "../../../lib/functions"
import { CustomCard } from "./EmailInvite"

const localMode = "shareableLinks"
const participantsIntroText = [
  "Please enter the number of employees you’d like to complete the survey via shareable link. One shareable link is generated per organisation for all employees to use.",
  "We recommend sharing this link with employees via emails, on messenger/community groups, in bulletins and any people platforms you use."
  // "If you don’t wish to send the survey via shareable link please toggle this off."
]

const ShareableLinks = ({
  mode,
  setMode,
  amount,
  setAmount,
  maxAmount,
  handleErrors,
  pageErrors
}) => {
  const isDisabled = findIfDisabled(mode, localMode)

  const validateValues = value => {
    if (value < 5) {
      handleErrors("Cannot have less than 5 participants", "amount")
    } else if (value > maxAmount) {
      handleErrors(
        `Cannot have more participants than the selected licence: ${maxAmount}`,
        "amount"
      )
    } else {
      handleErrors(null, "amount")
    }
  }

  const setValue = value => {
    validateValues(value)
    setAmount(value)
  }

  useEffect(() => {
    validateValues(amount)
  }, [maxAmount])

  return (
    <CustomCard
      py={4}
      px={[2, 3]}
      mb={4}
      bg={mode === localMode ? "light.0" : "dark.4"}
      isDisabled={isDisabled}
    >
      <ParticipantsIntro
        setMode={setMode}
        stateMode={mode}
        mode={localMode}
        title="Invite participants via shareable link"
        text={participantsIntroText}
      />
      <TextField
        onChange={e => setValue(e.target.value)}
        type="number"
        value={amount}
        name="shareableLinks"
        disabled={isDisabled}
        min={5}
        width="120px"
        fontSize={20}
        max={maxAmount}
        error={pageErrors?.amount}
      />
      <Text color="secondary.0">
        This shareable link can be used until you reach the number of links you
        have set in the box above, so remember to add enough for all employees!
      </Text>
    </CustomCard>
  )
}

export default ShareableLinks
