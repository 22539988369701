import React from "react"
import { Flex } from "@engaging-tech/components"
import TemplateHeaderContainer from "../containers/TemplateHeader"
import TemplateIntroCard from "../containers/TemplateIntroCard"
import TemplateBodyContainer from "../containers/TemplateBody"
import BranchingCard from "../containers/Branching/BranchingCard"

const TemplateView = ({ showBranching }) => {
  return (
    <Flex width={1 / 1} m="24px" flexDirection="column" px={6}>
      {showBranching && <BranchingCard />}
      {!showBranching && (
        <>
          <TemplateHeaderContainer />
          <TemplateIntroCard
            id="introduction"
            mt={5}
            titlePlaceholder="Survey template title"
            introPlaceholder="Survey template description"
          />
          <TemplateBodyContainer />
        </>
      )}
    </Flex>
  )
}

export default TemplateView
