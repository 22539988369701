import { connect } from "react-redux"
import BreakdownChartWrapper from "../components/BreakdownChartWrapper"
import * as Selectors from "../store/indicators.selectors"

const mapState = state => ({
  graphDataset: Selectors.getIndicatorGraphQuestionBreakdown(
    state,
    "managementConfidence"
  )
})

const ConfidenceInManagementBreakdown = connect(mapState)(BreakdownChartWrapper)

export default ConfidenceInManagementBreakdown
