import React from "react"

import MagicLinkForm from "../forms/MagicLink"

/**
 * The "SendMagicLink" dialog box.
 * @param {React.Component} siteLogo The brand logo to show within the dialog.
 * @param {string} cancelRoute The route to redirect a user to if they click "Cancel".
 */
const SendMagicLink = ({ siteLogo, cancelRoute, redirectTo }) => (
  <MagicLinkForm
    siteLogo={siteLogo}
    cancelRoute={cancelRoute}
    redirectTo={redirectTo}
  />
)

export default SendMagicLink
