import styled from "styled-components"
import { Icon } from "@engaging-tech/components"

const ClickableIcon = styled(Icon)`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.dark[4] : theme.colors.primary[4]};
  }
`
export default ClickableIcon
