import { Flex } from "@engaging-tech/components"
import React from "react"

import FormTitle from "../../../../ui/components/FormTitle"
import AboutYourOrganisation from "./about-your-organisation"
import VideoLink from "./video-links"
import WorkLAwardsWins from "./workl-awards-wins"

export default function YourCulture({ isLoading }) {
  return (
    <>
      <FormTitle text="Use this section to give potential employees an insight into the culture within your business.">
        Your Culture
      </FormTitle>
      <Flex width={1 / 1} flexDirection="column" mt={20}>
        <AboutYourOrganisation isLoading={isLoading} />
        <WorkLAwardsWins isLoading={isLoading} />
        <VideoLink isLoading={isLoading} />
      </Flex>
    </>
  )
}
