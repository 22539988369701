import React from "react"
import SiteBanner from "../../features/cms/containers/SiteBanner"
import EBNLoader from "../../features/EBN/containers/EBNLoader"
import Articles from "../../features/EBN/views/Articles"
import Research from "../../features/EBN/views/Research"
import Resources from "../../features/EBN/views/Resources"
import Webinars from "../../features/EBN/views/Webinars"
import PageLayout from "../../layouts/PageLayout"
import WelcomeView from "../../features/welcome/containers/WelcomeView"
import { OrgGuard } from "../../features/account"

const EBN = () => (
  <OrgGuard>
    <PageLayout promotionalBanner={SiteBanner} bg="#EEEEEE" px={3}>
      <EBNLoader />
      <WelcomeView />
      <Webinars />
      <Resources />
      <Research />
      <Articles />
    </PageLayout>
  </OrgGuard>
)

export default EBN
