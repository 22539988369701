import React from "react"
import { Text, Flex, HorizontalBarChart } from "@engaging-tech/components"
import EmptyCustomQuestion from "./EmptyCustomQuestion"

const CustomQuestionScale = ({ scaleQuestion, exportingPDF }) => {
  return (
    <Flex
      width={[1 / 1, 48 / 100]}
      flexDirection="column"
      justifyContent="space-between"
      key={scaleQuestion.id}
      mx={[0, 2]}
    >
      <Flex
        width={[1 / 1, 1 / 1]}
        bg="dark.4"
        py={4}
        px={[1, 4]}
        mb={4}
        alignItems="center"
        flexDirection="column"
      >
        <Text
          height={["auto", "50px"]}
          color="dark.2"
          fontWeight={500}
          width="auto"
          mb={2}
          px={4}
          textAlign="center"
        >
          {scaleQuestion.question}
        </Text>
        {scaleQuestion && scaleQuestion.score ? (
          <HorizontalBarChart
            disableAllAnimation={exportingPDF}
            showDataLabels={exportingPDF}
            min={0}
            max={100}
            height={100}
            dataset={{
              labels: [scaleQuestion.question],
              datasets: [
                {
                  data: [scaleQuestion.score]
                }
              ]
            }}
          />
        ) : (
          <EmptyCustomQuestion />
        )}
      </Flex>
    </Flex>
  )
}
export default CustomQuestionScale
