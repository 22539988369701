import { Flex } from "@engaging-tech/components"
import React from "react"

import BarIndicator from "../../components/BarIndicator/BarIndicator"
import ReportCard from "../../components/ReportCard"
import { ScoreLabel } from "../../components/ScoreLabel"
import AverageScore from "./AverageScore"
import ThreeWayBreakdown from "./ThreeWayBreakdown"

const barStructure = [
  { color: "#EF464A", percentage: 50, labelStart: "-100", labelEnd: "0" },
  { color: "#FFB547", percentage: 15, labelEnd: "30" },
  { color: "#0DC187", percentage: 35, labelEnd: "100" }
]

const NetPromoterScoreOnePager = ({ data, isPdfDownloading }) => {
  return data ? (
    <ReportCard
      isPdfDownloading={isPdfDownloading}
      heading="Net Promoter Score"
      width="100%"
      height={400}
      exportingPDF={false}
      metaProps={{ mb: 2 }}
      innerProps={{ justifyContent: "space-between" }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "250px 110px"
        }}
        width="100%"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          mb={[3, 3, 0]}
          width={["100%"]}
        >
          <BarIndicator
            dataSet={{ score: data.scores.index }}
            barStructure={barStructure}
            disableAnimation
            minValue={-100}
            maxValue={100}
            hideArrowLabels
          />
          <ScoreLabel
            showIcon
            score={data.scores.index}
            breakpoints={[60, 30, 0, -30]}
            mt={2}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          mb={[0]}
          width={["100%"]}
          style={{ transform: "translate(-0%, -25%) " + "scale(" + 0.8 + ")" }}
        >
          <ThreeWayBreakdown
            width={["100%"]}
            mb={[0]}
            p={1}
            promotersPercentage={data?.scores?.promoters}
            passivesPercentage={data?.scores?.passives}
            detractorsPercentage={data?.scores?.detractors}
          />
          <AverageScore
            width={["100%"]}
            mb={[0]}
            p={1}
            score={data?.average.mean}
            justifyContent="center"
          />
        </Flex>
      </div>
    </ReportCard>
  ) : null
}

export default NetPromoterScoreOnePager
