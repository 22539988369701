import {
  Box,
  Button,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import useBusinessAppQuery from "../../../../../hooks/useBusinessAppQuery"
import { GET_SURVEY_INFO } from "../../services/surveys.queries"
import DeleteButton from "./delete-button"
import ResponseRate from "./response-rate"
import StarterExitLabel from "./starter-exit-label"
import { SurveyActionPlan } from "./survey-action-plan"
import SurveyActions from "./survey-actions"
import SurveyDataPackage from "./survey-data-package"
import SurveyDates from "./survey-dates"
import SurveyDaysToClose from "./survey-days-to-close"
import SurveyScore from "./survey-score"
import SurveyScoreToQualify from "./survey-score-to-qualify"
import SurveyStatus from "./survey-status"
import SurveySubmissions from "./survey-submissions"
import SurveyTitle from "./survey-title"

const GridContainer = styled.div`
  width: 100%;
  padding: 20px;
  margin: 6px;

  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols}, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const GridSubContainer = styled.div`
  width: 100%;

  margin-top: 18px;
  padding-top: 18px;

  border-top: 1px solid #efefef;

  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols}, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const GridItem = styled.div`
  width: 100% !important;
  grid-column: span ${props => props.spanLarge || 1};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: fit-content;

    grid-column: span ${props => props.spanMobile || 1};
  }
`

const SurveyBar = ({
  index,
  meta,
  title,
  responseRate,
  participationRate,
  status,
  data,
  id,
  type,
  isAwards,
  submissions,
  sliceAndDice,
  employeeActionPlan,
  managementActionPlan
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(index <= 2)

  const dispatch = useDispatch()

  const {
    data: surveyInfoData,
    getData,
    isLoading
  } = useBusinessAppQuery(GET_SURVEY_INFO, {
    enabledOnMount: false,
    onSuccess: () => {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Your Completion rate has been refreshed"
        })
      )
    }
  })

  return (
    <Box position="relative" width={1 / 1}>
      <SurveyActions
        id={id}
        status={status}
        isDetailsOpen={isDetailsOpen}
        onChangeIsDetailsOpan={() => setIsDetailsOpen(!isDetailsOpen)}
      />
      <GridContainer cols={8}>
        <GridItem spanLarge={2} spanMobile={2}>
          <SurveyTitle
            id={id}
            title={title}
            type={type}
            status={status}
            isAwards={isAwards}
          />
        </GridItem>

        <GridItem spanLarge={status === "draft" ? 1 : 2} spanMobile={1}>
          <SurveyDates
            startDate={meta.startDate}
            endDate={meta.endDate}
            status={status}
          />
        </GridItem>
        <GridItem spanLarge={1} spanMobile={1}>
          {data?.happinessDataHighlight?.score ? (
            <SurveyScore
              score={data?.happinessDataHighlight?.score}
              width={110}
            />
          ) : null}
          {type === "employeeStarter" || type === "employeeExit" ? (
            <StarterExitLabel type={type} width={110} />
          ) : (
            <ResponseRate
              responseRate={
                surveyInfoData?.getOrgSurveyInfo.responseRate || responseRate
              }
              status={status}
            />
          )}
        </GridItem>
        {status === "draft" && (
          <GridItem spanLarge={1} spanMobile={1}>
            <DeleteButton surveyId={id} />
          </GridItem>
        )}
        <GridItem spanLarge={3} spanMobile={3}>
          <SurveyActionPlan
            surveyEmployeeActionPlan={employeeActionPlan}
            surveyManagementActionPlan={managementActionPlan}
            surveyId={id}
          />
        </GridItem>
        {isDetailsOpen ? (
          <GridItem spanLarge={8} spanMobile={2}>
            <GridSubContainer cols={5} mt={0}>
              <GridItem spanLarge={1} spanMobile={1}>
                <SurveyDataPackage sliceAndDice={sliceAndDice} />
              </GridItem>
              {type === "awards" ? (
                <GridItem spanLarge={1} spanMobile={1}>
                  <SurveyScoreToQualify participationRate={participationRate} />
                </GridItem>
              ) : null}
              <GridItem spanLarge={1} spanMobile={1}>
                <SurveyDaysToClose meta={meta} />
              </GridItem>
              <GridItem spanLarge={1} spanMobile={1}>
                <SurveySubmissions
                  isLoading={isLoading}
                  meta={meta}
                  submissions={
                    surveyInfoData?.getOrgSurveyInfo?.submissions || submissions
                  }
                />
              </GridItem>
              {type === "awards" ? null : <GridItem spanLarge={1} />}
              <GridItem spanLarge={1} spanMobile={2}>
                <Button
                  width={1}
                  variant="text.primary.0"
                  color="primary.0"
                  disabled={isLoading}
                  onClick={() => {
                    getData({ surveyId: id })
                  }}
                >
                  Refresh Completion rate
                </Button>
              </GridItem>
            </GridSubContainer>
          </GridItem>
        ) : null}
      </GridContainer>
    </Box>
  )
}
export default SurveyBar
