import { Box, Button, Flex, TextField } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

import AccountDialog from "../../components/AccountDialog"
import FormTextField from "../../components/FormTextField"
import PasswordHint from "../../components/PasswordHint"

const HiddenBox = styled(Flex)`
  display: none;
`

const ResetPasswordFormContent = ({
  handleSubmit,
  errors,
  touched,
  values,
  isValid,
  handleChange,
  handleBlur,
  isLoading,
  siteLogo,
  cancelRoute,
  email
}) => (
  <AccountDialog
    title="Forgotten your password?"
    subtitle="Reset your password"
    renderLogo={siteLogo}
  >
    <form onSubmit={handleSubmit}>
      <HiddenBox>
        <input
          width={1 / 1}
          id="email"
          name="email"
          type="hidden"
          label="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={email}
        />
      </HiddenBox>
      <HiddenBox>
        <TextField
          width={1 / 1}
          id="code"
          name="code"
          type="hidden"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.code}
          error={touched.code && errors.code}
        />
      </HiddenBox>
      <Box mb={2}>
        <FormTextField
          width={1 / 1}
          id="sign-up-password"
          name="password"
          type="password"
          label="Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password}
        />
      </Box>
      <Box mb={2}>
        <FormTextField
          width={1 / 1}
          id="sign-up-confirm-password"
          name="confirmPassword"
          type="password"
          label="Confirm password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          error={touched.confirmPassword && errors.confirmPassword}
        />
      </Box>
      <PasswordHint />
      <Flex width={1 / 1} flexDirection="row-reverse" alignItems="center">
        <Button
          variant="secondary.0"
          type="submit"
          disabled={!isValid || isLoading}
          ml={2}
          width="auto"
        >
          Submit
        </Button>
        <Link to={cancelRoute} external>
          <Button type="button" variant="secondary.4" ml={2}>
            Cancel
          </Button>
        </Link>
      </Flex>
    </form>
  </AccountDialog>
)

export default ResetPasswordFormContent
