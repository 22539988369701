import { Button, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

const LinkToBtn = ({ path, text }) => {
  return (
    <Flex flexDirection="column" width="auto" alignItems="start">
      <Link to={path}>
        <Button
          variant="primary.0"
          leadingIcon="add"
          color="light.0"
          height={36}
          width={200}
        >
          <Text
            color="light.0"
            fontSize="16px"
            style={{ textTransform: "none" }}
          >
            {text}
          </Text>
        </Button>
      </Link>
    </Flex>
  )
}

export default LinkToBtn
