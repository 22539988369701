import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useLocation } from "@engaging-tech/routing"
import { Auth } from "aws-amplify"
import { Formik } from "formik"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ForgotPasswordFormContent from "./ForgotPasswordFormContent"
import formSchema from "./form-schema"

const ForgotPasswordForm = ({ siteLogo, subtitle, footer, cancelRoute }) => {
  const email = new URLSearchParams(useLocation().search)
    .get("email")
    ?.replace("|", "")

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const onSubmit = useCallback(async values => {
    try {
      setIsLoading(true)

      await Auth.forgotPassword(values.email)

      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Send password reset email"
        })
      )
    } catch (error) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message:
            typeof error.message === "string"
              ? error.message
              : "Failed to send password reset email, please try again"
        })
      )
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (email) {
      onSubmit({ email })
    }
  }, [email, onSubmit])

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {props => (
        <ForgotPasswordFormContent
          siteLogo={siteLogo}
          subtitle={subtitle}
          footer={footer}
          isLoading={isLoading}
          cancelRoute={cancelRoute}
          {...props}
        />
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
