import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import React from "react"
import SectionBody from "./SectionBody"

const TemplateBody = ({ sections }) => {
  const renderSections = sections?.map((section, i) => (
    <SectionBody
      key={section.sectionId}
      section={section}
      current={i + 1}
      total={sections?.length}
    />
  ))

  return <Flex flexDirection="column">{renderSections}</Flex>
}

export default TemplateBody
