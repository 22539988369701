import { connect } from "react-redux"
import { EBNSelectors } from "../.."
import WebinarComponent from "../../components/Content/Webinar"

const mapState = (state, { id }) => ({
  webinar: EBNSelectors.getWebinarById(state, id)
})

const Webinar = connect(mapState)(WebinarComponent)

export default Webinar
