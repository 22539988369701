/* eslint-disable prefer-destructuring */
import { Box, Flex, Text } from "@engaging-tech/components"
import React from "react"

import { getLabelData } from "../../components/ScoreLabel"

const StepQuestionSumResultBlock = ({
  loading,
  nodata,
  sumAverageScore,
  firstGroupAndQuestion,
  labelToRender,
  renderLabel
}) => {
  const sumAverageScorePercentage = Math.round(Number(sumAverageScore))

  return (
    <Flex borderRadius={5} width="100%" p={2} flexDirection="column">
      <Flex flexDirection="row">
        <Flex
          borderRadius={5}
          flexDirection="column"
          width="120px"
          height="65px"
          bg={
            !loading && nodata
              ? "dark.4"
              : getLabelData(sumAverageScorePercentage).color
          }
        >
          {!loading && !nodata && (
            <Text pt={3} fontWeight="500" textAlign="center" fontSize={6}>
              {sumAverageScorePercentage}%
            </Text>
          )}
          {loading && (
            <Text pt={3} fontWeight="500" textAlign="center" fontSize={6}>
              ...
            </Text>
          )}
          {!loading && nodata && (
            <Text pt={3} fontWeight="500" textAlign="center" fontSize={6}>
              NA
            </Text>
          )}
        </Flex>
      </Flex>
      {renderLabel ? (
        <Box height={20} width="initial" mb={20}>
          {firstGroupAndQuestion ? (
            <Text fontWeight="700" color="dark.1">
              {labelToRender}
            </Text>
          ) : null}
        </Box>
      ) : null}
    </Flex>
  )
}

export default StepQuestionSumResultBlock
