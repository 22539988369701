import React from "react"
import { Box, Text } from "@engaging-tech/components"

/**
 * A small prompt to hint at what a strong password should contain.
 */
const PasswordHint = () => (
  <Box bg="secondary.4" mb={3} borderRadius={4} p={3}>
    <Text fontSize={[4, 3]} color="dark.2">
      Passwords are case-sensitive and must contain at least eight characters. A
      good password should contain a mix of capital and lowercase letters,
      numbers and symbols.
    </Text>
  </Box>
)

export default PasswordHint
