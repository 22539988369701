import { Button, Flex } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import React from "react"

const LinkButton = ({
  text,
  path,
  onClick,
  props,
  justify = "flex-end",
  color,
  variant,
  onMouseEnter,
  onMouseLeave,
  disabled
}) => {
  const router = useRouter()

  return (
    <Flex
      justifyContent={justify}
      alignItems="flex-end"
      width={1 / 1}
      {...props}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Button
        variant={variant}
        color={color}
        trailingIcon="chevron_right"
        elevation={0}
        disabled={disabled}
        onClick={onClick ? onClick : () => router.navigate(path)}
      >
        {text}
      </Button>
    </Flex>
  )
}

export default LinkButton
