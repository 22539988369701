import { connect } from "react-redux"

import { requestConfirmation } from "../store/account.actions"

import ConfirmBanner from "../components/ConfirmBanner"
import { getIsAuthenticated, getIsConfirmed } from "../store/account.selectors"

const mapState = state => ({
  isConfirmed: getIsConfirmed(state),
  isAuthenticated: getIsAuthenticated(state)
})

const mapDispatch = dispatch => ({
  sendVerification: () => dispatch(requestConfirmation())
})

/**
 * A banner prompting a user to confirm their email address.
 */
const ConfirmEmailBanner = connect(mapState, mapDispatch)(ConfirmBanner)

export default ConfirmEmailBanner
