import { connect } from "react-redux"
import BreakdownsComponent from "../components/Breakdowns"
import * as Selectors from "../store/sixStepsBreakdown.selectors"

const mapState = (state, { step }) => {
  return {
    title: Selectors.getBreakdownTitle(state, step),
    description: Selectors.getBreakdownDescription(state, step),
    categories: Selectors.getBreakdownCategories(state, step)
  }
}

const Breakdowns = connect(mapState)(BreakdownsComponent)

export default Breakdowns
