import React from "react"
import { Text, Paragraph, CircularProgress } from "@engaging-tech/components"
import { ScoreLabel, getLabelData } from "./ScoreLabel"
import StatisticBox from "./StatisticBox"
import LinkButton from "../../ui/components/LinkButton"
import ReportCard from "./ReportCard"

const ScoreGraphic = ({
  heading,
  path,
  score,
  industry,
  surveyResponseRate,
  middleText = "Your Average Engagement Score",
  TopSection = null,
  buttonText = "Discover",
  width,
  exportingPDF,
  props
}) => {
  const industryDifference = score - industry

  return (
    <ReportCard
      heading={heading}
      width={width}
      exportingPDF={exportingPDF}
      {...props}
    >
      {TopSection && <TopSection />}
      <CircularProgress
        percentage={score}
        width={200}
        disableAllAnimation={exportingPDF}
        strokeColor={getLabelData(score).color}
      />

      {!score && score !== 0 && (
        <Text textAlign="center">No data for this step</Text>
      )}
      <Paragraph
        textAlign="center"
        fontSize={3}
        fontWeight="500"
        color="dark.2"
        mb={3}
      >
        {middleText}
      </Paragraph>
      <ScoreLabel showIcon score={score} />
      <StatisticBox
        comparedToIndustry={industryDifference}
        surveyResponseRate={surveyResponseRate}
        width={1 / 1}
      />
      {path && (
        <LinkButton
          variant="text.primary.0"
          color="primary.0"
          text={buttonText}
          path={path}
        />
      )}
    </ReportCard>
  )
}

export default ScoreGraphic
