import { connect } from "react-redux"
import { EBNSelectors } from ".."
import SeeAllWebinarsComponent from "../components/Webinars/SeeAllWebinars"

const mapState = state => ({
  webinars: EBNSelectors.getAllWebinars(state)
})

const SeeAllWebinars = connect(mapState)(SeeAllWebinarsComponent)

export default SeeAllWebinars
