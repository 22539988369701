import React from "react"
import EmailInviteContainer from "../../containers/Page2/Participants/EmailInvite"
import ShareableLinksContainer from "../../containers/Page2/Participants/ShareableLinks"

const Participants = ({ mode, setMode, pageErrors, handleErrors }) => {
  return (
    <>
      {/* Temporary disabled  */}
      {/* <EmailInviteContainer
        mode={mode}
        setMode={setMode}
        pageErrors={pageErrors}
        handleErrors={handleErrors}
      /> */}
      <ShareableLinksContainer
        mode={mode}
        setMode={setMode}
        pageErrors={pageErrors}
        handleErrors={handleErrors}
      />
    </>
  )
}
export default Participants
