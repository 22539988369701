import React from "react"

import { MotionFlex, Flex, Text } from "@engaging-tech/components"

const getColour = ({ type }) => {
  if (type === "BESPOKE_INDICATOR") {
    return "#E66F3D"
  }
  if (type === "STANDARD_INDICATOR") {
    return "#173C5A"
  }
  return "#ff9a70"
}

const Dot = ({ type, text, index, maxNumberDots }) => {
  const dotSize = 30
  const textWidth = 120
  return (
    <Flex
      width={textWidth + dotSize}
      alignItems="center"
      justifyContent="center"
    >
      <MotionFlex
        bg={getColour({ type })}
        borderRadius={
          type === "BESPOKE_INDICATOR" || type === "STANDARD_INDICATOR" ? 3 : 99
        }
        width={dotSize}
        height={dotSize}
        minHeight={dotSize}
        minWidth={dotSize}
        variants={{
          unActive: {
            scale: 0,
            transition: {
              duration: 0.2,
              delay: 0.1 * maxNumberDots - 0.1 * index
            }
          },
          active: {
            scale: 1,
            transition: {
              duration: 0.3,
              delay: 0.7 + 0.2 * index
            }
          }
        }}
        initial="unActive"
        animate="active"
      />
      <MotionFlex
        variants={{
          unActive: {
            opacity: 0,
            transition: {
              delay: 0.1 * maxNumberDots - 0.1 * index,
              duration: 0.3
            }
          },
          active: {
            opacity: 1,
            transition: {
              delay: 0.9 + 0.2 * index,
              duration: 0.3
            }
          }
        }}
        initial="unActive"
        animate="active"
        width={textWidth}
        height={1}
        alignItems="center"
        ml={3}
      >
        <Text fontSize={[4, 5]} noSelect>
          {text}
        </Text>
      </MotionFlex>
    </Flex>
  )
}

const LinkLine = ({ index, maxNumberLines }) => {
  return (
    <Flex
      width={10}
      height="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      pt={10}
      ml="4px"
    >
      <MotionFlex
        width={6}
        borderRadius={3}
        bg="#d4d4d4"
        variants={{
          unActive: {
            height: 0,
            transition: {
              type: "tween",
              duration: 0.05,
              delay: 0.05 * maxNumberLines - 0.5 * index
            }
          },
          active: {
            height: "80%",
            transition: {
              type: "tween",
              delay: 0.9 + 0.2 * index,
              duration: 0.2
            }
          }
        }}
        initial="unActive"
        animate="active"
      />
    </Flex>
  )
}

const BespokeIndicatorAnimatedIllustration = ({ isBespoke, width = 200 }) => {
  return (
    <Flex
      height={[350, 600]}
      width={width}
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pl={20}
      ml={[90, 0, 0]}
      mt={[60, 15, 15]}
    >
      <Dot
        usedState={!isBespoke}
        type="STANDARD_INDICATOR"
        text="Standard Template"
        index={0}
        maxNumberDots={4}
      />
      <LinkLine index={0} maxNumberLines={3} />
      <Dot text="+1 Custom Questions" index={1} maxNumberDots={4} />
      <LinkLine index={1} maxNumberLines={3} />
      <Dot text="+2 Custom Questions" index={2} maxNumberDots={4} />
      <LinkLine index={2} maxNumberLines={3} />
      <Dot
        usedState={isBespoke}
        type="BESPOKE_INDICATOR"
        text="Bespoke Template"
        index={3}
        maxNumberDots={4}
      />
    </Flex>
  )
}

export default BespokeIndicatorAnimatedIllustration
