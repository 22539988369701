import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { call, put, select, takeLatest } from "redux-saga/effects"

import { getSurveyId } from "../../survey-view-mode/store/survey-view-mode.selectors"
import { updateSurveyService, uploadLogo } from "../services/survey-edit-mode.services"
import { updateSurveyFail, uploadLogoFail, uploadLogoSuccess } from "./survey-edit-mode.actions"
import * as Actions from "./survey-edit-mode.actions"

const generateSurveyReq = survey => {
  const { template, status, meta, ...req } = survey
  if (template) {
    req.templateId = template.id
    req.type = template.type
  }
  const { creditsPaid, frequencySettings, ...restMeta } = meta
  req.meta = restMeta
  switch (req.type) {
    case "employeeExit":
    case "employeeStarter":
      if (!frequencySettings?.endDate) {
        req.meta = {
          ...req.meta,
          frequencySettings: {
            frequency: frequencySettings?.frequency || "never"
          }
        }
      } else {
        req.meta = {
          ...req.meta,
          frequencySettings
        }
      }
      return req
    default:
      return req
  }
}

export function* updateSaga(action) {
  const { id, ...surveyInfo } = action.payload
  try {
    const survey = generateSurveyReq({
      ...surveyInfo,
      meta: {
        ...surveyInfo.meta
      }
    })
    const res = yield call(updateSurveyService, {
      surveyId: id,
      survey
    })
    yield put(Actions.updateSurveySuccess(res.data.updateOrganisationSurvey.id))
    yield put(
      userInterfaceNotificationsStore.actions.addNotification({ message: "Survey updated successfully!" })
    )
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Updated survey"])
    }
  } catch (e) {
    console.log({ e })
    yield put(updateSurveyFail())
  }
}

export function* uploadLogoSaga(action) {
  try {
    if (typeof action.payload !== "object") return
    const surveyId = yield select(getSurveyId)
    const res = yield call(uploadLogo, {
      logo: action.payload.logo,
      surveyId
    })
    yield put(uploadLogoSuccess(res.data.result))
  } catch {
    yield put(uploadLogoFail())
  }
}

export function* saga() {
  yield takeLatest([Actions.types.UPDATE_SURVEY], updateSaga)
  yield takeLatest(Actions.types.UPLOAD_LOGO, uploadLogoSaga)
}
