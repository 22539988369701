import { Flex, Text, TextField } from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React from "react"

import SectionCard from "../../../components/section-card"

export default function BusinessSocialSection({ isLoading }) {
  const { errors, handleBlur, handleChange, values } = useFormikContext()

  return (
    <SectionCard title="Social Media" optional>
      <Text color="dark.2" fontSize={3} my={3}>
        Add up to 6 social media profiles.
      </Text>
      <Flex flexDirection={["column", "row"]}>
        <TextField
          id="social.linkedIn"
          name="social.linkedIn"
          type="text"
          label="LinkedIn"
          width={98 / 100}
          disabled={isLoading}
          error={errors.social?.linkedIn}
          value={values.social.linkedIn}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          id="social.facebook"
          name="social.facebook"
          type="text"
          label="Facebook"
          width={98 / 100}
          disabled={isLoading}
          error={errors.social?.facebook}
          value={values.social.facebook}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <TextField
          id="Twitter"
          name="social.twitter"
          type="text"
          label="Twitter"
          width={98 / 100}
          disabled={isLoading}
          error={errors.social?.twitter}
          value={values.social.twitter}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          id="social.instagram"
          name="social.instagram"
          type="text"
          label="Instagram"
          width={98 / 100}
          disabled={isLoading}
          error={errors.social?.instagram}
          value={values.social.instagram}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <TextField
          id="social.pinterest"
          name="social.pinterest"
          type="text"
          label="Pinterest"
          width={98 / 100}
          disabled={isLoading}
          error={errors.social?.pinterest}
          value={values.social.pinterest}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          id="social.youtube"
          name="social.youtube"
          type="text"
          label="YouTube"
          width={98 / 100}
          disabled={isLoading}
          error={errors.social?.youtube}
          value={values.social.youtube}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Flex>
    </SectionCard>
  )
}
