import { Button, Flex, Spinner, Text } from "@engaging-tech/components"
import { format } from "date-fns"
import React, { useContext } from "react"

import CREATE_BUSINESS_JOB from "../../../graphql/mutations/createBusinessJob"
import useAppSyncMutation from "../../../hooks/useAppSyncMutation"
import { postJobFail, postJobSuccess } from "../../../store/jobs/jobs.actions"
import ErrorState from "../../business-information/components/error-state"
import DisplayProduct from "../../ui/components/DisplayProduct"
import { BottomBar, Content } from "../components/FormWrapper"
import { ReviewStepBox } from "../components/StepBox"
import personalities from "../lib/jobPersonalities"
import PostJobContext from "../lib/postJobContext"

const ReviewDetail = ({ title, input, label, noMargin, ...props }) => (
  <Flex flexDirection="column" {...props} mt={noMargin ? 0 : 3}>
    {title && (
      <Text color="dark.2" fontSize={2} mb={3}>
        {title}
      </Text>
    )}
    <Text mb={label ? 3 : 0}>{input}</Text>
    {label && (
      <Text color="dark.2" fontSize={2}>
        {label}
      </Text>
    )}
  </Flex>
)

const ReviewForm = ({ jobPost }) => {
  const { goForward, goBack, cancel } = useContext(PostJobContext)

  const { post, errors, isLoading } = useAppSyncMutation(CREATE_BUSINESS_JOB, {
    successAction: postJobSuccess,
    failAction: postJobFail
  })

  const handlePost = () => {
    const postObj = { input: { ...jobPost, status: "PUBLISHED_MARKETPLACE" } }
    if (jobPost.hobbies) postObj.input.hobbies = jobPost.hobbies.split(",")
    if (jobPost.skills) postObj.input.skills = jobPost.skills.split(",")
    post(postObj, goForward)
  }

  if (errors?.errors.filter(error => error.errorType === "ERR403")[0]) return <DisplayProduct />
  if (errors) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return (
    <Flex flexDirection="column">
      <Content>
        <ReviewStepBox step="1" title="Admin Email">
          <ReviewDetail
            noMargin
            title="Email Address"
            input={jobPost.contact_email}
            label="This person will manage the recruitment of the candidate and the email
            will be visible on the jobs marketplace"
          />
          {jobPost.url && (
            <ReviewDetail
              noMargin
              title="URL Link"
              input={jobPost.url}
              label="Hyperlink link to the company website or the specific job application page"
            />
          )}
        </ReviewStepBox>
        <ReviewStepBox step="2" title="The Role">
          <ReviewDetail noMargin title="Role" input={jobPost.title} />
          <ReviewDetail title="Job Type" input={jobPost.job_type} />
          <ReviewDetail title="Experience Level" input={jobPost.experience_level} />
          <ReviewDetail title="Location" input={jobPost.location} />
          <ReviewDetail title="Position" input={jobPost.position} />
          <Flex width={[1 / 1, "auto"]}>
            <ReviewDetail
              title="Minimum salary"
              input={jobPost.salary_private ? "competitive" : jobPost.min_salary}
              mr={[0, 6]}
            />
            <ReviewDetail
              title="Maximum salary"
              input={jobPost.salary_private ? "competitive" : jobPost.max_salary}
            />
          </Flex>
          <ReviewDetail
            title="Vacancy expiry date"
            input={jobPost.age ? format(new Date(jobPost.age), "dd/MM/yyyy") : "None"}
          />
        </ReviewStepBox>
        <ReviewStepBox step="3" title="Candidate Skills and Interests">
          <ReviewDetail noMargin title="Key skills" input={jobPost.skills} />
          {jobPost.hobbies && <ReviewDetail title="Hobbies" input={jobPost.hobbies} />}
        </ReviewStepBox>
        <ReviewStepBox step="4" title="Job Description">
          <ReviewDetail noMargin input={jobPost.snippet} />
        </ReviewStepBox>
        <ReviewStepBox step="5" title="Responsibilities">
          <ReviewDetail noMargin input={jobPost.responsibilities} />
        </ReviewStepBox>
        <ReviewStepBox step="6" title="Requirements">
          <ReviewDetail noMargin input={jobPost.requirements} />
        </ReviewStepBox>
        {jobPost?.personality_type_code?.length > 0 && (
          <ReviewStepBox step="7" title=" Candidate Personality">
            {jobPost.personality_type_code.map(personalityCode => (
              <Flex key={personalityCode} alignItems="center" mb="16px">
                <Text color="dark.0" fontSize={[4]} mx="10px" fontWeight="bold">
                  {personalityCode}
                </Text>
                <Text color="dark.0" fontSize={[4]} ml="10px">
                  {personalities.find(personality => personality.code === personalityCode).description}
                </Text>
              </Flex>
            ))}
          </ReviewStepBox>
        )}
      </Content>
      <BottomBar alignItems={["flex-start", "center"]} elevation={6}>
        <Flex maxWidth="1080px" justifyContent="space-between">
          <Button variant="text.primary.0" width="auto" elevation={0} onClick={goBack}>
            Back
          </Button>
          <Flex flexDirection="row-reverse">
            <Button width={148} variant="primary.0" ml={3} onClick={handlePost}>
              Post
            </Button>
            <Button variant="text.primary.0" width="auto" elevation={0} type="submit" onClick={cancel}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </BottomBar>
    </Flex>
  )
}

export default ReviewForm
