import { types } from "./surveyInfoReview.actions"

export const initialState = {
  isLoading: false,
  isPaying: false,
  hasError: false,
  intentSecret: "",
  creditsToPurchase: 0,
  paymentStatus: null // true for success, false for failure
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_INTENT:
      return { ...state, isLoading: true, hasError: false }

    case types.SCHEDULE_SURVEY:
      return { ...state }

    case types.CREATE_INTENT_SUCCESS:
      return {
        ...state,
        intentSecret: action.payload,
        isPaying: true
      }

    case types.SCHEDULE_SURVEY_SUCCESS:
      return {
        ...state,
        hasError: false
      }

    case types.PURCHASE_CREDITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        intentSecret: "",
        paymentStatus: true,
        isPaying: false,
        creditsToPurchase: 0
      }

    case types.CREATE_INTENT_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: true
      }

    case types.PURCHASE_CREDITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        intentSecret: "",
        paymentStatus: false,
        isPaying: false
      }

    case types.SCHEDULE_SURVEY_FAIL:
      return {
        ...state,
        hasError: true
      }

    case types.SET_CREDITS_TO_PURCHASE:
      return {
        ...state,
        creditsToPurchase: action.payload < 0 ? 0 : action.payload
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
