import { connect } from "react-redux"
import HoldingPageComponent from "../components/HoldingPage"
import { ReportSelectors } from ".."

const mapState = state => ({
  status: ReportSelectors.getReportStatus(state),
  responseSize: ReportSelectors.getResponseSize(state)
})

const HoldingPage = connect(mapState)(HoldingPageComponent)

export default HoldingPage
