import React from "react"
import styled from "styled-components"
import { createPortal } from "react-dom"
import { Button, Flex, Modal } from "@engaging-tech/components"
import CheckboxList from "./CheckboxList"
import { subTypesList } from "../../../../lib/surveyTypes"

const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.4;
  }
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const SubtypesModal = ({
  subCheckedList,
  setSubCheckedList,
  handleGetComparisonData,
  setOpenModal,
  openModal
}) => {
  return (
    <>
      {createPortal(
        <Modal bg="white">
          <Flex m={30} width="fit-content">
            <CheckboxList
              title="Select which sub-categories you want to examine"
              itemList={subTypesList}
              checkedList={subCheckedList}
              setCheckedList={setSubCheckedList}
            >
              <Flex width={1 / 1} justifyContent="flex-end">
                <StyledButton
                  mt={[20, 0]}
                  alignSelf={["flex-start", "flex-end"]}
                  bg="primary.0"
                  color="light.0"
                  width="170px"
                  onClick={() => {
                    handleGetComparisonData()
                    setOpenModal(!openModal)
                  }}
                  disabled={!subCheckedList.length}
                >
                  Select
                </StyledButton>
              </Flex>
            </CheckboxList>
          </Flex>
        </Modal>,
        appRoot()
      )}
    </>
  )
}

export default SubtypesModal
