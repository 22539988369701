import React from "react"
import { useHistory } from "react-router-dom"

import { SAVE_BASKET } from "../../../../graphql/mutations"
import { FETCH_PRODUCTS } from "../../../../graphql/queries"
import useBusinessAppMutation from "../../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../../hooks/useBusinessAppQuery"
import {
  saveBasketFail,
  saveBasketReq,
  saveBasketSuccess
} from "../../../../store/basket/basket.actions"
import SliceAndDiceUpgradeModalComponent from "./SliceAndDiceUpgradeModalComponent"
import {
  getSDLowestPriceFail,
  getSDLowestPriceReq,
  getSDLowestPriceSuccess,
  getSDUpgradePriceFail,
  getSDUpgradePriceReq,
  getSDUpgradePriceSuccess
} from "./store/actions"

const SliceAndDiceUpgradeModalContainer = ({
  closeModal,
  sliceAndDiceProductUpgradePrice,
  sliceAndDiceProductLowestPrice,
  surveyId
}) => {
  const history = useHistory()
  const { errorGettingLowestPrice } = useBusinessAppQuery(FETCH_PRODUCTS, {
    initAction: getSDLowestPriceReq,
    successAction: getSDLowestPriceSuccess,
    failAction: getSDLowestPriceFail,
    variables: { object_type: "slice-and-dice" }
  })

  const { errors: errorGettingUpgradePrice } = useBusinessAppQuery(
    FETCH_PRODUCTS,
    {
      initAction: getSDUpgradePriceReq,
      successAction: getSDUpgradePriceSuccess,
      failAction: getSDUpgradePriceFail,
      variables: { object_type: "slice-and-dice", surveyId: surveyId }
    }
  )

  const { errors: basketErrors, post } = useBusinessAppMutation(SAVE_BASKET, {
    initAction: saveBasketReq,
    successAction: saveBasketSuccess,
    failAction: saveBasketFail
  })

  const goToBasketPage = () => {
    window.localStorage.setItem("surveyIdUpgrade", surveyId)
    history.push("/basket")
  }

  const makeUpgrade = async () => {
    await post(
      {
        productId: sliceAndDiceProductUpgradePrice.product_details.id,
        priceId: sliceAndDiceProductUpgradePrice.id,
        object_type: "slice-and-dice"
      },
      () => dispatch(setAddedToBasket(sliceAndDiceProductUpgradePrice.name))
    )
    goToBasketPage()
  }

  if (errorGettingLowestPrice || errorGettingUpgradePrice || basketErrors) {
    return null
  }

  return (
    <SliceAndDiceUpgradeModalComponent
      closeModal={closeModal}
      price={sliceAndDiceProductLowestPrice?.unit_amount}
      currency={sliceAndDiceProductLowestPrice?.currency}
      makeUpgrade={makeUpgrade}
    />
  )
}

export default SliceAndDiceUpgradeModalContainer
