import { types } from "./EBN.actions"

export const initialState = {
  isLoading: false,
  showEBNModal: true,
  articles: [],
  lectures: [],
  webinars: [],
  research: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_LECTURES:
    case types.LOAD_RESEARCH:
    case types.LOAD_ARTICLES:
    case types.LOAD_WEBINARS:
      return { ...state, isLoading: true }

    case types.LOAD_LECTURES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lectures: action.payload
      }
    case types.LOAD_RESEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        research: action.payload
      }
    case types.LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: action.payload
      }
    case types.LOAD_WEBINARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        webinars: action.payload
      }

    case types.LOAD_LECTURES_FAIL:
    case types.LOAD_RESEARCH_FAIL:
    case types.LOAD_ARTICLES_FAIL:
    case types.LOAD_WEBINARS_FAIL:
      return { ...state, isLoading: false }

    case types.CLOSE_EBN_MODAL:
      return { ...state, showEBNModal: false }
    default:
      return state
  }
}
