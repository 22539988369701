import { Button, Flex, ReactSelect, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import { StyledTextField } from "../../../ui/components/StyledTextField"
import { filterList } from "./AssignSurveys"

const RoundedTextField = styled(StyledTextField)`
  input {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.light[0]};
  }
`

const AssignSurveysSearch = ({
  setKeywords,
  keywords,
  handleSearch,
  handleFilters
}) => {
  return (
    <>
      <Text>
        Choose from the list below, the Surveys that you want your Action Plan
        to be assigned to
      </Text>
      <Flex
        width={1 / 1}
        justifyContent="space-between"
        alignItems="flex-start"
        mt={4}
      >
        <Flex width="500px">
          <RoundedTextField
            value={keywords}
            height="36px"
            noLabel
            noHighlight
            onChange={e => setKeywords(e.target.value)}
          />
        </Flex>
        <Button
          width="120px"
          bg="primary.0"
          color="light.0"
          height="35px"
          onClick={handleSearch}
        >
          Search
        </Button>
        <Flex width="180px" zIndex={10}>
          <ReactSelect
            height="36px"
            borderRadius="4px"
            options={filterList}
            defaultValue={filterList[0]}
            onChange={obj => handleFilters(obj.value)}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default AssignSurveysSearch
