import { DatePicker as DatePickerComponent } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const StyledDatePickerComponent = styled(DatePickerComponent)`
  & > div > .DayPickerInput {
    border: 1px solid
      ${({ theme, error, disabled }) =>
        disabled ? "#ccc" : error ? theme.colors.error[0] : "rgba(99, 152, 195, 1)"};
    border-radius: 10px;
    padding: 2px;
    &:hover {
      border: 2px solid ${({ theme, error }) => (error ? theme.colors.error[0] : "rgba(99, 152, 195, 1)")};
      border-radius: 10px;
      padding: 1px;
    }
  }

  & > div > .DayPickerInput > input {
    border-bottom: none;
    padding-top: ${({ theme }) => theme.space[2]}px;
    padding-bottom: ${({ theme }) => theme.space[2]}px;

    &:hover {
      border-bottom: none;
      padding-top: ${({ theme }) => theme.space[2]}px;
      padding-bottom: ${({ theme }) => theme.space[2]}px;
    }
  }

  & > div > .DayPickerInput > input:focus {
    border: none;
  }

  & > div > span {
    padding-left: 10px;
  }
`

const DatePicker = ({
  value,
  onChange,
  format,
  formatDateFn,
  parseDateFn,
  noLabel,
  label,
  id,
  name,
  wrapperWidth = "100%",
  error,
  helperText,
  disabled = false,
  iconColor = "dark.2",
  width = "100%",
  ...props
}) => (
  <StyledDatePickerComponent
    value={value}
    handleChange={onChange}
    format={format}
    formatDateFn={formatDateFn}
    parseDateFn={parseDateFn}
    noLabel={noLabel}
    label={label}
    id={id}
    name={name}
    wrapperWidth={wrapperWidth}
    error={error}
    helperText={helperText}
    disabled={disabled}
    iconColor={iconColor}
    width={width}
    {...props}
  />
)

export default DatePicker
