import React, { useState, useContext } from "react"
import { Flex, Checkbox, Text, Button } from "@engaging-tech/components"
import StepBox from "../components/StepBox"
import PostJobContext from "../lib/postJobContext"
import personalities from "../lib/jobPersonalities"
import FormWrapper, { Content, BottomBar } from "../components/FormWrapper"

const PersonalityForm = ({ onSubmit, job }) => {
  const [selected, setSelected] = useState(job?.personality_type_code || [])
  const { goForward, goBack } = useContext(PostJobContext)

  const handleFormSubmission = e => {
    e.stopPropagation()
    e.preventDefault() // Prevent submissions redirecting on Safari
    onSubmit(selected)
    goForward()
  }

  const isSelected = id => !!selected.find(selectedItem => selectedItem === id)

  const toggleSelection = id =>
    isSelected(id)
      ? setSelected(selected.filter(item => item !== id))
      : setSelected([...selected, id])

  return (
    <FormWrapper onSubmit={handleFormSubmission}>
      <Content>
        <StepBox
          step="7"
          title="Candidate personality (Optional EXCEPT required for job matcher)"
        >
          {personalities?.map(personality => (
            <Checkbox
              mb={5}
              key={personality.code}
              colour="dark.2"
              id={personality.code}
              checked={selected.indexOf(personality.code) >= 0}
              onChange={e => toggleSelection(e.target.id)}
              onBlur={() => null}
              label={() => (
                <Flex alignItems="center">
                  <Text color="dark.0" fontSize={[4]} mx={2} fontWeight="bold">
                    {personality.code}
                  </Text>
                  <Text color="dark.0" fontSize={[4]} ml={3}>
                    {personality.description}
                  </Text>
                </Flex>
              )}
            />
          ))}
        </StepBox>
      </Content>
      <BottomBar alignItems={["flex-start", "center"]} elevation={6}>
        <Flex
          maxWidth="1080px"
          flexDirection="row-reverse"
          justifyContent="space-between"
        >
          <Button width={148} type="submit" variant="primary.0">
            Next
          </Button>
          <Button
            variant="text.primary.0"
            width="auto"
            elevation={0}
            onClick={goBack}
          >
            Back
          </Button>
        </Flex>
      </BottomBar>
    </FormWrapper>
  )
}

export default PersonalityForm
