import { gql } from "@apollo/client"

const CREATE_EVENT = gql`
  mutation createEvent(
    $title: String!
    $categories: [String]!
    $summary: String!
    $location: EventLocationInput!
    $startDate: Date!
    $endDate: Date!
    $email: String!
    $urllink: String
    $attendees: Float
  ) {
    createEvent(
      title: $title
      categories: $categories
      summary: $summary
      location: $location
      startDate: $startDate
      endDate: $endDate
      email: $email
      url: $urllink
      maxAttendees: $attendees
    ) {
      id
      hubPostId
    }
  }
`

export default CREATE_EVENT
