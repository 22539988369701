import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

const StyledImage = styled.img`
  width: 500px;
  height: auto;

  @media only screen and (max-width: 766px) {
    max-width: 380px;
  }
  @media only screen and (min-width: 767px) and (max-width: 1023) {
    max-width: 420px;
  }
`

const AwardsBanner = ({ title, logo, description }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bg="secondary.0"
      color="light.0"
      width={1 / 1}
      minHeight="300px"
      px={3}
      py={5}
    >
      <StyledImage alt={title} src={logo} />
      <Text
        fontSize={7}
        fontWeight={700}
        color="light.0"
        textAlign="center"
        mb={2}
        mt={5}
      >
        {title}
      </Text>
      <Text
        width={["80%", "70%", "60%"]}
        color="light.0"
        textAlign="center"
        fontWeight={100}
        mb={2}
      >
        {description}
      </Text>
    </Flex>
  )
}

export default AwardsBanner
