import { connect } from "react-redux"
import * as DataHighlightSelectors from "../store/datahighlights.selectors"
import IndicatorComponent from "../components/Indicator"
import WellBeingLogo from "../../reportLogos/WellBeingLogo"

const mapState = state => ({
  averageScoreData: DataHighlightSelectors.getIndicatorAverageScores(
    state,
    "wellBeingRisk"
  ),
  mainScore: DataHighlightSelectors.getWellBeingMainScore(state),
  labelScore: 100 - DataHighlightSelectors.getWellBeingMainScore(state),
  heading: "Wellbeing Risk Index",
  text: "of your employees are at risk of having poor wellbeing",
  logo: WellBeingLogo,
  breakDown: "wellBeing"
})

const WellBeingIndicator = connect(mapState)(IndicatorComponent)

export default WellBeingIndicator
