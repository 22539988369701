import { connect } from "react-redux"

import { setActionPlanStep } from "../../../store/actionPlans/actionPlans.actions"
import { postActionPlanStepData } from "../../../store/actionPlans/actionPlans.selectors"
import EditActionPlanStep from "../views/EditActionPlanStep"

const mapState = state => ({
  actionPlanStep: postActionPlanStepData(state)
})

const mapDispatch = dispatch => ({
  setActionPlanStep: step => dispatch(setActionPlanStep(step))
})

const EditActionPlanStepContainer = connect(
  mapState,
  mapDispatch
)(EditActionPlanStep)

export default EditActionPlanStepContainer
