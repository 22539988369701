import { createSelector } from "reselect"

const uiState = state => state && state.ui

export const getLoadedFeatures = createSelector(
  uiState,
  state => state?.loadedFeatures
)

export const getIsReady = createSelector(getLoadedFeatures, features =>
  Object.values(features).every(flag => flag)
)

export const getBackBar = createSelector(uiState, state => state?.backBar)

export const getPreviousRoute = createSelector(
  uiState,
  state => state?.previousRoute
)

export const getIsLoading = createSelector(uiState, state => state?.isLoading)

export const getHasError = createSelector(uiState, state => state?.hasError)

export const getAllCountries = createSelector(
  uiState,
  state => state?.allCountries
)

export const getAllCountryOptions = createSelector(getAllCountries, state =>
  state?.map(country => ({ id: country.id, value: country.name }))
)

export const getAllIndustries = createSelector(
  uiState,
  state => state?.allIndustries
)

export const getAllIndustryOptions = createSelector(getAllIndustries, state =>
  state?.map(industry => ({ id: industry.id, value: industry.name }))
)

export const getFeebackDialogIsOpen = createSelector(
  uiState,
  state => state?.feebackDialogIsOpen
)

export const getFeedbackData = createSelector(
  uiState,
  state => state?.feedbackData
)
