import React from "react"
import { Flex } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"

import StepOneAnimatedWrapper from "./stepOne/StepOneAnimatedWrapper"
import StepTwoAnimatedWrapper from "./stepTwo/StepTwoAnimatedWrapper"
import StepThreeAnimatedWrapper from "./stepThree/StepThreeAnimatedWrapper"

const getStep = selectedStep => {
  switch (selectedStep) {
    case 1:
      return <StepOneAnimatedWrapper key={1} />
    case 2:
      return <StepTwoAnimatedWrapper key={2} />
    case 3:
      return <StepThreeAnimatedWrapper key={3} />
    default:
      return <></>
  }
}

const StepAnimation = ({ selectedStep, height }) => {
  return (
    <Flex
      width={["100%", "100%", "50%"]}
      height={height}
      alignItems="center"
      pl={10}
      justifyContent="center"
    >
      <AnimatePresence exitBeforeEnter>{getStep(selectedStep)}</AnimatePresence>
    </Flex>
  )
}

export default StepAnimation
