import { CustomHooks, Flex, Icon, MotionFlex } from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { surveysStore } from "../../../"
import DraftSurveyDeleteWarning from "./draft-survey-delete-warning"

export default function DeleteButton({ surveyId }) {
  const [showModal, setShowModal] = useState(false)
  const device = CustomHooks.useClientDevice()

  const dispatch = useDispatch()

  return (
    <>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <AnimatePresence>
          <MotionFlex
            width="fit-content"
            height="100%"
            justifyContent={["flex-end", "flex-end", "center"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            whileHover={{ scale: 1.03 }}
            style={{ cursor: "pointer" }}
            transition={{ duration: 0.1 }}
            onTap={() => setShowModal(true)}
          >
            <Icon name="delete" size={device === "DESKTOP" ? 35 : 30} />
          </MotionFlex>
        </AnimatePresence>
      </Flex>
      <AnimatePresence>
        {showModal && (
          <DraftSurveyDeleteWarning
            surveyId={surveyId}
            onCancel={() => setShowModal(false)}
            onConfirm={() => {
              setShowModal(false)
              if (typeof window !== "undefined" && window.hj) {
                window.hj("tagRecording", ["[WFB][App][Surveys] Deleted survey"])
              }

              dispatch(surveysStore.actions.loadData())
            }}
          />
        )}
      </AnimatePresence>
    </>
  )
}
