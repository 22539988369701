import { gql } from "@apollo/client"

const SAVE_SELF_SERVE_SURVEY = gql`
  mutation saveSelfServeSurvey(
    $surveyObj: SelfServiceSurveyObj
    $selfServeSurveyType: String
  ) {
    saveSelfServeSurvey(
      surveyObj: $surveyObj
      selfServeSurveyType: $selfServeSurveyType
    ) {
      title
      introduction
      templateId
      meta {
        creditsPaid
        startDate
        endDate
        mode
        recipients
        shareableLinks
        private
      }
    }
  }
`

export default SAVE_SELF_SERVE_SURVEY
