import { Box, Flex, IconButton, Modal, Table } from "@engaging-tech/components"
import { format } from "date-fns"
import React, { useContext, useEffect } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

import { tableConfig, tableHeadings } from "../lib/listAssignedSurveysTable"
import { ViewActionPlanContext } from "../views/ViewActionPlan"
import MainHeader from "./MainHeader"

const StyledTable = styled(Table)`
  border: ${({ theme }) => `2px solid ${theme.colors.dark[4]}`};
  border-radius: 6px;
`

const formattedRows = rows =>
  rows &&
  rows.map(row => {
    const { title, createdAt } = row
    return {
      title,
      createdAt: format(new Date(createdAt), "dd/MM/yyyy")
    }
  })

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const AssignedToModal = () => {
  const { actionPlan, setOpenModal, sortAssignedSurveys } = useContext(
    ViewActionPlanContext
  )

  const sortData = sortBy => sortAssignedSurveys(sortBy)

  useEffect(() => {
    const name = document.getElementById("assigned-to-sort-name")
    const date = document.getElementById("assigned-to-sort-date")

    name.addEventListener("click", () => sortData("title"))
    date.addEventListener("click", () => sortData("createdAt"))

    return () => {
      name.removeEventListener("click", () => sortData("title"))
      date.removeEventListener("click", () => sortData("createdAt"))
    }
  }, [])

  return createPortal(
    <Modal bg="light.0" minWidth={800}>
      <Flex
        flexDirection="column"
        pb={5}
        pt={4}
        px={4}
        justifyContent="space-between"
        position="relative"
        width={1 / 1}
      >
        <MainHeader />
        <Box position="absolute" top="10px" right="10px" width="fit-content">
          <IconButton icon="close" onClick={() => setOpenModal(false)} />
        </Box>
        <StyledTable
          headings={tableHeadings}
          rows={formattedRows(actionPlan?.assignedTo)}
          config={tableConfig}
        />
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default AssignedToModal
