import { connect } from "react-redux"
import ParticipantsForm from "../forms/Participants"
import {
  closeSurvey,
  loadSurvey,
  saveSurveyMeta
} from "../store/surveyInfo.actions"
import {
  getAmount,
  getIsLoading,
  getIsSaving,
  getMeta,
  getMode,
  getRecipients,
  getSurveyId,
  getTemplate
} from "../store/surveyInfo.selectors"

const mapDispatch = dispatch => ({
  onSubmit: payload => dispatch(saveSurveyMeta(payload)),
  onLoad: surveyId => dispatch(loadSurvey(surveyId)),
  onClose: () => dispatch(closeSurvey())
})

const mapState = state => ({
  initialValues: {
    recipients: getRecipients(state),
    amount: getAmount(state),
    mode: getMode(state),
    closeSurvey: false
  },
  template: getTemplate(state),
  meta: getMeta(state),
  surveyId: getSurveyId(state),
  isLoading: getIsLoading(state),
  isSaving: getIsSaving(state)
})

const Participants = connect(mapState, mapDispatch)(ParticipantsForm)

export default Participants
