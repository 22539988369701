import { CustomHooks, Flex, MotionFlex, Text } from "@engaging-tech/components"
import { Link, usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import TemplateTypeToolTip from "../../components/TemplateTypeToolTip"
import OptionButton from "./OptionButton"

const NoUnderlineAbbr = styled.abbr`
  text-decoration: none;
`

const TemplateItem = ({
  template,
  bg,
  addSelect,
  templateSelected,
  setTemplateSelected,
  deleteTemplate,
  disableIcons,
  showViewOnlyButton,
  noHover
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const device = CustomHooks.useClientDevice()
  const router = useRouter()
  const paths = usePaths()
  const { telegraphTemplateId } = getConfig().other
  useEffect(() => {
    if (
      templateSelected &&
      templateSelected.id === template.id &&
      templateSelected.name !== template.name
    ) {
      setTemplateSelected(template)
    }
  }, [templateSelected])

  const height = 180
  const width = 264
  const labelHeight = 30
  const borderPadding = 14

  const showTemplateToolTip = () => {
    if (noHover) {
      return templateSelected.id === template.id
    }
    return true
  }

  return (
    <Flex
      flexDirection="column"
      mb={4}
      width={width}
      height={height + labelHeight}
      mr={4}
      style={{
        opacity: noHover && templateSelected.id !== template.id ? 0.5 : 1
      }}
      position="relative"
      justifyContent="flex-end"
    >
      <MotionFlex
        borderRadius="10px"
        position="absolute"
        height={height}
        width={width}
        initial={{ scale: 0.8 }}
        animate={(() => {
          return templateSelected && templateSelected.id === template.id
            ? { scale: 1 }
            : { scale: 0.8 }
        })()}
        style={{ border: "2px solid #c3c3c3", top: 0 }}
        transition={{
          duration: 0.4,
          type: "spring",
          bounce: 0.5
        }}
      />

      <MotionFlex
        p={6}
        justifyContent="center"
        alignItems="center"
        position="absolute"
        onHoverStart={() => setShowOptions(true)}
        onHoverEnd={() => setShowOptions(false)}
        animate={(() => {
          if (templateSelected && !noHover) {
            if (templateSelected.id !== template.id && showOptions) {
              return { scale: 1.02 }
            }
            return { scale: 1 }
          }
          return showOptions && !noHover ? { scale: 1.02 } : { scale: 1 }
        })()}
        transition={{ duration: 0.15 }}
        width={width - borderPadding}
        height={height - borderPadding}
        borderRadius={8}
        style={{
          backgroundImage: bg,
          cursor: noHover ? "initial" : "pointer",
          top: borderPadding / 2,
          left: borderPadding / 2
        }}
        onTap={e => {
          if (addSelect) {
            setTemplateSelected(template)
          } else if (device === "MOBILE") {
            setShowOptions(!showOptions)
          } else if (
            !e.target.classList.contains("template-option-button") &&
            !addSelect
          ) {
            router.navigate(
              paths.surveys.buildATemplate.viewTemplate.replace(
                ":id",
                template.id
              )
            )
          }
        }}
      >
        <Text
          textAlign="center"
          fontWeight={700}
          fontSize={4}
          color="light.0"
          noSelect
        >
          {template.name}
        </Text>
        {showViewOnlyButton && (
          <Flex
            style={{ top: "10px", right: "10px" }}
            position="absolute"
            width="auto"
          >
            <OptionButton
              showOptions={showOptions}
              onClick={() => {
                window.open(
                  paths.surveys.buildATemplate.viewTemplate.replace(
                    ":id",
                    template.id
                  ),
                  "_blank"
                )
              }}
              iconName="visibility"
            />
          </Flex>
        )}
        {showTemplateToolTip() && (
          <TemplateTypeToolTip
            isBespoke={template.isBespoke}
            templateId={template.id}
            style={{
              bottom: "10px",
              left: "10px",
              position: "absolute"
            }}
          />
        )}

        {!disableIcons && (
          <Flex
            style={{ top: "10px", right: "10px" }}
            position="absolute"
            width="auto"
          >
            {device !== "DESKTOP" && (
              <NoUnderlineAbbr title="View Template">
                <Link
                  to={paths.surveys.buildATemplate.viewTemplate.replace(
                    ":id",
                    template.id
                  )}
                >
                  <OptionButton
                    showOptions={showOptions}
                    iconName="visibility"
                  />
                </Link>
              </NoUnderlineAbbr>
            )}
            {template.isDraft && (
              <>
                <NoUnderlineAbbr title="Edit Template">
                  <Link
                    to={paths.surveys.buildATemplate.editTemplate.replace(
                      ":id",
                      template.id
                    )}
                  >
                    <OptionButton
                      showOptions={showOptions}
                      iconName="mode_edit"
                    />
                  </Link>
                </NoUnderlineAbbr>

                <NoUnderlineAbbr title="Delete Template">
                  <OptionButton
                    showOptions={showOptions}
                    onClick={() => {
                      deleteTemplate(template.id)
                      if (typeof window !== "undefined" && window.hj) {
                        window.hj("tagRecording", [
                          "[WFB][App][Survey Templates] Deleted template"
                        ])
                      }
                    }}
                    iconName="delete"
                  />
                </NoUnderlineAbbr>
              </>
            )}
            {
              // Disable copy features for telegraph template
            }

            {/* {telegraphTemplateId !== template.id && (
              <Link
                to={`${paths.surveys.buildATemplate.createTemplate}?baseTemplateId=${template.id}`}
                onClick={() => {
                  if (typeof window !== "undefined" && window.hj) {
                    window.hj("tagRecording", [
                      "[WFB][App][Survey Templates] Cloned survey template"
                    ])
                  }
                }}
              >
                <OptionButton
                  showOptions={showOptions}
                  iconSize={22}
                  iconName="content_copy"
                />
              </Link>
            )} */}
          </Flex>
        )}
      </MotionFlex>
    </Flex>
  )
}

export default TemplateItem
