/* eslint-disable no-param-reassign */
import { useDispatch, useSelector } from "react-redux"
import {
  addSection,
  moveQuestion,
  moveSection,
  deleteQuestion,
  deleteSection,
  addCoreQuestion,
  addCustomQuestion
} from "../store/template.actions"
import {
  getAvailableCoreQuestionIds,
  getSections
} from "../store/template.selectors"

const useGenerateMenu = (
  type,
  setIsEditMode,
  isEditMode,
  currentQuestion,
  currentSection
) => {
  const dispatch = useDispatch()
  const sections = useSelector(getSections)
  const availableCoreQuestionIds = useSelector(getAvailableCoreQuestionIds)
  /* Single source of truth for all question types */
  if (["dropdown", "scale", "short-text", "radio"].includes(type))
    type = "question"

  /* onType, onQuestion and onSection decides which buttons(icon) should appear on each pop up menu of
    a template card. We then take type, currentQuestion ID or currentSection ID and map over the
    onType, onQuestion and onSection arrays to match */

  /* hovertip => there are two types of hovertips, one renders "multiple"  clickable buttons, 
    other is just a "single" hover helper tooltip */

  const choices = [
    {
      onType: [
        "question",
        "section",
        "other",
        "industry",
        "job-role",
        "country"
      ],
      icon: "add_circle",
      hovertip: {
        type: "multi",
        labels: [
          {
            isVisible: !!availableCoreQuestionIds.length,
            name: "Core Question",
            action: () =>
              dispatch(
                addCoreQuestion({
                  [type]: currentQuestion || currentSection,
                  availableCoreQuestionIds
                })
              ),
            bg: "secondary.0",
            color: "light.0"
          },
          {
            isVisible: true,
            name: "Custom Question",
            action: () =>
              dispatch(
                addCustomQuestion({ [type]: currentQuestion || currentSection })
              ),
            bg: "primary.0",
            color: "light.0"
          }
        ]
      },
      action: () => null
    },
    {
      onQuestion: sections?.map(
        section => section.questions[section.questions.length - 1]
      ),
      onType: ["introduction", "section"],
      icon: "filter_none",
      hovertip: {
        type: "single",
        name: "Add Section"
      },
      action: () =>
        dispatch(addSection({ [type]: currentQuestion || currentSection }))
    },
    {
      onType: [
        "question",
        "section",
        "other",
        "industry",
        "job-role",
        "country"
      ],
      icon: "delete",
      hovertip: { type: "single", name: "Delete" },
      action: () =>
        currentQuestion
          ? dispatch(deleteQuestion(currentQuestion))
          : dispatch(deleteSection(currentSection))
    },
    {
      onType: ["question", "other", "industry", "job-role", "country"],
      icon: isEditMode ? "visibility" : "edit",
      hovertip: { type: "single", name: isEditMode ? "Preview" : "Edit" },
      action: () => setIsEditMode(!isEditMode)
    },
    {
      onQuestion: sections?.reduce((acc, section, idx) => {
        if (idx === 0) {
          acc.push(...section.questions.slice(1))
        } else {
          acc.push(...section.questions)
        }
        return acc
      }, []),
      onSection: sections?.map(section => section.sectionId).slice(1),
      icon: "keyboard_arrow_up",
      hovertip: { type: "single", name: "Move up" },
      action: () =>
        currentQuestion
          ? dispatch(moveQuestion(currentQuestion, -1))
          : dispatch(moveSection(currentSection, -1))
    },
    {
      onQuestion: sections?.reduce((acc, section, idx) => {
        if (sections.length === idx + 1) {
          acc.push(...section.questions.slice(0, -1))
        } else {
          acc.push(...section.questions)
        }

        return acc
      }, []),
      onSection: sections?.map(section => section.sectionId).slice(0, -1),
      icon: "keyboard_arrow_down",
      hovertip: { type: "single", name: "Move down" },
      action: () =>
        currentQuestion
          ? dispatch(moveQuestion(currentQuestion, 1))
          : dispatch(moveSection(currentSection, 1))
    }
  ]

  return choices.reduce((acc, choice) => {
    if (
      choice?.onType?.includes(type) ||
      choice?.onQuestion?.includes(currentQuestion) ||
      choice?.onSection?.includes(currentSection)
    )
      acc.push(choice)
    return acc
  }, [])
}

export default useGenerateMenu
