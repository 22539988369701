import { connect } from "react-redux"
import LowestScoreComponent from "../components/LowestScore"
import LowestScoreInfo from "../../lib/LowestScoreInfo"
import * as Selectors from "../store/lowestscore.selectors"

const mapState = state => ({
  score: Selectors.getScore(state),
  stepInfo: LowestScoreInfo[Selectors.getLowestStepName(state)]
})

const LowestScore = connect(mapState)(LowestScoreComponent)

export default LowestScore
