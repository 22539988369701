import React from "react"
import { Link, useLocation, usePaths } from "@engaging-tech/routing"
import { Flex, Text } from "@engaging-tech/components"
import PageLayout from "../../layouts/PageLayout"
import { OrgGuard } from "../../features/account"

const EntryForbidden = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  const email = queryParams.get("email")
  const paths = usePaths()

  return (
    <OrgGuard>
      <PageLayout pt={0}>
        <Flex alignItems="center" flexDirection="column" mt={6}>
          <Text width={8 / 10} textAlign="center">
            It looks like you have already entered the awards for this year. If
            this isn’t correct email us at{" "}
            <u>
              <Link to={email} newTab external>
                {email}
              </Link>
            </u>{" "}
            so we can help.
          </Text>
          <Text width={8 / 10} textAlign="center" mt={4}>
            If you would like to enter one of our other Awards click{" "}
            <b>
              <Link to={paths.awards.index}>HERE</Link>
            </b>{" "}
            to find out more.
          </Text>
        </Flex>
      </PageLayout>
    </OrgGuard>
  )
}

export default EntryForbidden
