import { connect } from "react-redux"

import { setSuggestedPhrases } from "../../../../store/actionPlans/actionPlans.actions"
import SuggestedPhrases from "../../components/EditActionPlan/SuggestedPhrases"

const mapState = state => ({})

const mapDispatch = dispatch => ({
  setSuggestedPhrases: (actions, stepQuestionId) =>
    dispatch(setSuggestedPhrases(actions, stepQuestionId))
})

const SuggestedPhrasesContainer = connect(
  mapState,
  mapDispatch
)(SuggestedPhrases)

export default SuggestedPhrasesContainer
