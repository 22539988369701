import { Flex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { ReportSelectors } from ".."
import ReportHeader from "../Header/containers/ReportHeader"
import ReportElementsLayout from "../components/ReportElementsLayout"

const Summary = () => {
  const components = useSelector(ReportSelectors.getSummaryLayout)

  return (
    <>
      <ReportHeader reportComponents={components} />
      <Flex
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        flexWrap="wrap"
        alignItems="stretch"
        alignContent="stretch"
        id="report-wrapper"
        position="relative"
      >
        <ReportElementsLayout componentTags={components} />
      </Flex>
    </>
  )
}
export default Summary
