import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import { ActionPlanStepPreview } from "./action-plan-step"
import { ActionPlanTitleComponent } from "./action-plan-title"

const ActionPlanCompleteStepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 38px;
  width: 100%;
`

export function ActionPlanStepsPreview({ surveyTypeTitle, data }) {
  const { title, actionPlanSteps } = data

  return (
    <Flex flexDirection="column">
      <ActionPlanTitleComponent
        surveyTypeTitle={surveyTypeTitle}
        title={title}
      />
      <ActionPlanCompleteStepsContainer>
        {actionPlanSteps.map((step, index) => (
          <ActionPlanStepPreview key={index} step={step} />
        ))}
      </ActionPlanCompleteStepsContainer>
    </Flex>
  )
}
