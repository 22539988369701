import { types } from "./templateSelection.actions"

export const initialState = {
  isLoading: false,
  hasError: false,
  defaultTemplates: [],
  orgTemplates: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_DEFAULT_TEMPLATES:
    case types.LOAD_ORG_TEMPLATES:
    case types.DELETE_ORG_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        hasError: false
      }

    case types.LOAD_DEFAULT_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        defaultTemplates: [...action.payload]
      }

    case types.LOAD_ORG_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        orgTemplates: [...action.payload]
      }

    case types.DELETE_ORG_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false
      }

    case types.LOAD_DEFAULT_TEMPLATES_FAILURE:
    case types.LOAD_ORG_TEMPLATES_FAILURE:
    case types.DELETE_ORG_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
