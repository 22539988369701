export const types = {
  TOKEN_SIGN_IN: "[Account] Token Sign In",
  TOKEN_SIGN_IN_FAIL: "[Account] Token Sign In Fail",
  SIGN_IN_SUCCESS: "[Account] Sign In Success",
  SIGN_IN_FAIL: "[Account] Sign In Fail",
  SIGN_OUT_SUCCESS: "[Account] Sign Out Success",
  SIGN_OUT_FAIL: "[Account] Sign Out Fail",
  SIGN_UP_SUCCESS: "[Account] SIGN_UP Success",
  SIGN_UP_FAIL: "[Account] SIGN_UP Fail",
  FORGOT_PASSWORD_SUCCESS: "[Account] Forgot Password Success",
  FORGOT_PASSWORD_FAIL: "[Account] Forgot Password Fail",
  RESET_PASSWORD_SUCCESS: "[Account] Reset Password Success",
  RESET_PASSWORD_FAIL: "[Account] Reset Password Fail",
  LOAD: "[Organisations] Load",
  LOAD_SUCCESS: "[Organisations] Load Success",
  LOAD_FAIL: "[Organisations] Load Fail",
  CONFIRM_EMAIL: "[Account] Confirm Email",
  CONFIRM_EMAIL_SUCCESS: "[Account] Confirm Email Success",
  CONFIRM_EMAIL_FAIL: "[Account] Confirm Email Fail",
  REQUEST_CONFIRMATION: "[Account] Request Confirmation Email",
  REQUEST_CONFIRMATION_SUCCESS: "[Account] Request Confirmation Success",
  REQUEST_CONFIRMATION_FAIL: "[Account] Request Confirmation Fail",
  UPDATE_CURRENT_CREDITS: "[Organisations] Update Current Credits",
  CREATE_INTENT: "[Account] Create intent for EBN upgrade",
  CREATE_INTENT_SUCCESS: "[Account] Create intent Success for EBN upgrade",
  CREATE_INTENT_FAIL: "[Account] Create intent Fail for EBN upgrade",
  UPGRADE_SUCCESS: "[Account] EBN upgrade success",
  UPGRADE_FAILURE: "[Account] EBN upgrade failure",
  UPDATE_ORGANISATION: "[BusinessInformation] upadate organisation ",
  UPDATE_ORGANISATION_SUCCESS:
    "[BusinessInformation] upadate organisation success",
  UPDATE_ORGANISATION_FAIL: "[BusinessInformation] upadate organisation fail",
  SAVE_LOCATION: "[BusinessInformation] Save Location",
  DELETE_LOCATION: "[BusinessInformation] Delete Location",
  SAVE_STATEMENT: "[BusinessInformation] Save Statements",
  UPLOAD_IMAGE: "[BusinessInformation] Upload Image",
  UPLOAD_IMAGE_SUCCESS: "[BusinessInformation] Upload Image Success",
  PUBLISH_BIP: "[BusinessInformation] Publish BIP",
  PUBLISH_BIP_SUCCESS: "[BusinessInformation] Publish BIP Success",
  SET_ACTIVE_CRUMB: "[BusinessInformation] Set Active Crumb",
  CLOSE_FORM: "[BusinessInformation] Close Form",
  RESET_CLOSE_FORM: "[BusinessInformation] Reset Close Form",
  SET_HAS_ERROR: "[BusinessInformation] Set Has Error",
  FULL_ORG_LOAD: "[BusinessInformation] Full Organisation Load",
  APPLY_DISCOUNT: "[BusinessInformation] Apply Discount",
  APPLY_DISCOUNT_SUCCESS: "[BusinessInformation] Apply Discount Success",
  APPLY_DISCOUNT_FAILURE: "[BusinessInformation] Apply Discount Failure",
  RESET_DISCOUNT_STATE: "[BusinessInformation] Reset Discount State",
  UPDATE_ACCOUNT_PREFERENCES_SUCCESS:
    "[Account] Update Account Preferences Success",
  UPDATE_ACCOUNT_PREFERENCES_FAIL: "[Account] Update Account Preferences Fail",
  SIGN_IN_MAGIC_LINK_SUCCESS: "[Account] Magic Link Sign In Success",
  SIGN_IN_MAGIC_LINK_FAIL: "[Account] Magic Link Sign In Fail",
  SEND_MAGIC_LINK_SUCCESS: "[Account] Magic Link Sent Success",
  SEND_MAGIC_LINK_FAIL: "[Account] Magic Link Sent Fail",
  RESET_SEND_MAGIC_LINK: "[Account] Reset Send Magic Link",
  SET_PDF_DOWNLOAD_KEY: "[Account] Set Pdf Download Key"
}

export const setPdfDownloadKey = key => ({
  type: types.SET_PDF_DOWNLOAD_KEY,
  payload: { key }
})

export const updateAccountPreferencesFail = payload => ({
  type: types.UPDATE_ACCOUNT_PREFERENCES_FAIL,
  payload
})

export const updateAccountPreferencesSuccess = payload => ({
  type: types.UPDATE_ACCOUNT_PREFERENCES_SUCCESS,
  payload
})

export const applyDiscount = ({ service, discountCode }) => ({
  type: types.APPLY_DISCOUNT,
  payload: { service, discountCode }
})

export const applyDiscountSuccess = payload => ({
  type: types.APPLY_DISCOUNT_SUCCESS,
  payload
})

export const applyDiscountFail = payload => ({
  type: types.APPLY_DISCOUNT_FAILURE,
  payload
})

export const resetDiscountState = () => ({ type: types.RESET_DISCOUNT_STATE })

export const tokenSignIn = () => ({ type: types.TOKEN_SIGN_IN })
export const tokenSignInFail = () => ({ type: types.TOKEN_SIGN_IN_FAIL })

export const signIn = ({ email, password, domain }) => ({
  type: types.SIGN_IN,
  payload: { email, password, domain }
})

export const fullOrgLoad = () => ({ type: types.FULL_ORG_LOAD })

export const signInSuccess = token => ({
  type: types.SIGN_IN_SUCCESS,
  payload: token
})

export const signInFail = errorCode => ({
  type: types.SIGN_IN_FAIL,
  payload: errorCode
})

export const signOut = () => ({ type: types.SIGN_OUT })

export const signOutSuccess = () => ({ type: types.SIGN_OUT_SUCCESS })

export const signOutFail = () => ({ type: types.SIGN_OUT_FAIL })

/** SIGNUP */
export const signUpSuccess = ({ token, organisation }) => ({
  type: types.SIGN_UP_SUCCESS,
  payload: { token, organisation }
})
export const signUpFail = errorCode => ({
  type: types.SIGN_UP_FAIL,
  payload: errorCode
})

/** FORGOT PASSWORD */
export const forgotPasswordSuccess = email => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload: email
})

export const forgotPasswordFail = errorCode => ({
  type: types.FORGOT_PASSWORD_FAIL,
  payload: errorCode
})

/** RESET PASSWORD */

export const resetPasswordSuccess = notificationCode => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload: notificationCode
})
export const resetPasswordFail = errorCode => ({
  type: types.RESET_PASSWORD_FAIL,
  payload: errorCode
})

/** ORGANISATIONS */
export const load = payload => ({
  type: types.LOAD,
  payload
})

export const loadSuccess = organisation => ({
  type: types.LOAD_SUCCESS,
  payload: organisation
})

export const loadFail = errorCode => ({
  type: types.LOAD_FAIL,
  payload: errorCode
})

export const confirmEmail = confirmToken => ({
  type: types.CONFIRM_EMAIL,
  payload: confirmToken
})
export const confirmEmailSuccess = () => ({ type: types.CONFIRM_EMAIL_SUCCESS })
export const confirmEmailFail = () => ({ type: types.CONFIRM_EMAIL_FAIL })

export const requestConfirmation = () => ({
  type: types.REQUEST_CONFIRMATION
})
export const requestConfirmationSuccess = notificationCode => ({
  type: types.REQUEST_CONFIRMATION_SUCCESS,
  payload: notificationCode
})
export const requestConfirmationFail = () => ({
  type: types.REQUEST_CONFIRMATION_FAIL
})

export const updateCurrentCredits = payload => ({
  type: types.UPDATE_CURRENT_CREDITS,
  payload
})

export const saveLocation = payload => ({
  type: types.SAVE_LOCATION,
  payload
})

export const deleteLocation = payload => ({
  type: types.DELETE_LOCATION,
  payload
})

export const saveStatement = payload => ({
  type: types.SAVE_STATEMENT,
  payload
})

/** CREATE INTENT EBN */
export const createIntent = source => ({
  type: types.CREATE_INTENT,
  payload: source
})

export const createIntentSuccess = payload => ({
  type: types.CREATE_INTENT_SUCCESS,
  payload
})

export const createIntentFail = errorCode => ({
  type: types.CREATE_INTENT_FAIL,
  payload: errorCode
})

export const upgradeSuccess = () => ({
  type: types.UPGRADE_SUCCESS
})

export const uploadImage = payload => ({
  type: types.UPLOAD_IMAGE,
  payload
})

export const uploadImageSuccess = payload => ({
  type: types.UPLOAD_IMAGE_SUCCESS,
  payload
})

export const updateOrganisation = ({ data, nextCrumb }) => ({
  type: types.UPDATE_ORGANISATION,
  payload: {
    data,
    nextCrumb
  }
})

export const updateOrganisationSuccess = payload => ({
  type: types.UPDATE_ORGANISATION_SUCCESS,
  payload
})

export const updateOrganisationFail = payload => ({
  type: types.UPDATE_ORGANISATION_FAIL,
  payload
})

export const setActiveCrumb = payload => ({
  type: types.SET_ACTIVE_CRUMB,
  payload
})

export const publishBIP = () => ({
  type: types.PUBLISH_BIP
})

export const publishBIPSuccess = payload => ({
  type: types.PUBLISH_BIP_SUCCESS,
  payload
})

export const closeForm = () => ({
  type: types.CLOSE_FORM
})

export const setHasError = payload => ({
  type: types.SET_HAS_ERROR,
  payload
})

export const loginWithMagicLinkSuccess = token => ({
  type: types.SIGN_IN_MAGIC_LINK_SUCCESS,
  payload: token
})

export const loginWithMagicLinkFail = payload => ({
  type: types.SIGN_IN_MAGIC_LINK_FAIL,
  payload
})

export const sendMagicLinkSuccess = () => ({
  type: types.SEND_MAGIC_LINK_SUCCESS
})

export const sendMagicLinkFail = payload => ({
  type: types.SEND_MAGIC_LINK_FAIL,
  payload
})

export const resetSendMagicLink = () => ({
  type: types.RESET_SEND_MAGIC_LINK
})
