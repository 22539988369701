import { Utils } from "@engaging-tech/components"
import { types } from "./surveys.actions"

const { getDefaultReducer } = Utils.coreStore

const defaultReducer = getDefaultReducer()
export const initialState = {
  ...defaultReducer.initialState
}

const initialStoreDeepCopy = JSON.parse(JSON.stringify(initialState))

export const reducer = (s = initialState, action) => {
  const state = defaultReducer.defaultSwitchCases({
    state: s,
    action,
    types,
    initialState: initialStoreDeepCopy
  })
  switch (action.type) {
    default:
      return state
  }
}
