import { connect } from "react-redux"

import { getIsLoading, getJobMatches } from "../store/jobs.selectors"
import { loadJobMatches } from "../store/jobs.actions"

import JobMatchesComponent from "../components/JobMatches"

const mapState = state => ({
  isLoading: getIsLoading(state),
  jobMatches: getJobMatches(state)
})

const mapDispatch = dispatch => ({
  onLoad: jobId => dispatch(loadJobMatches(jobId))
})

const JobMatches = connect(mapState, mapDispatch)(JobMatchesComponent)

export default JobMatches
