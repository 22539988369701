import { connect } from "react-redux"
import DetailsForm from "../../forms/Details"
import { saveJobData } from "../../../../store/jobs/jobs.actions"
import { getPostJob } from "../../../../store/jobs/jobs.selector"

const mapState = state => ({
  job: getPostJob(state)
})

const mapDispatch = dispatch => ({
  onSubmit: data => dispatch(saveJobData(data))
})

const Details = connect(mapState, mapDispatch)(DetailsForm)

export default Details
