import { connect } from "react-redux"
import FiltersComponent from "../components/Filters"
import * as Selectors from "../store/filters.selectors"
import * as ReportActions from "../../store/report.actions"

const mapState = state => ({
  availableFilters: Selectors.getAvailableFilters(state),
  formValues: Selectors.getFormFilters(state),
  isDirectReportsAvailable: Selectors.getIsDirectReportsAvailable(state)
})

const mapDispatch = dispatch => ({
  setFilters: filters => dispatch(ReportActions.updateFilters(filters)),
  setDirectReports: directReports =>
    dispatch(ReportActions.updateDirectReports(directReports))
})

const Filters = connect(mapState, mapDispatch)(FiltersComponent)

export default Filters
