import { Card as CardComponent } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const StyledCardComponent = styled(CardComponent)`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`

const Card = ({ children, ...props }) => (
  <StyledCardComponent
    width={1 / 1}
    p={5}
    bg="light.0"
    elevation={0}
    {...props}
  >
    {children}
  </StyledCardComponent>
)

export default Card
