import { Flex, Text, TextArea, TextField } from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React from "react"
import styled from "styled-components"

import ConsiderBox from "../../../components/consider-box"
import SectionCard from "../../../components/section-card"

const StyledTextArea = styled(TextArea)`
  textarea {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 100px;
  }
  padding: 0px;
`

const StyledAreaMaxCharactersText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;

  color: #173c5acc;
`

export default function BasicInformationSection({ isLoading }) {
  const { errors, values, handleBlur, handleChange } = useFormikContext()

  return (
    <SectionCard title="Basic Information">
      <TextField
        id="name"
        name="name"
        type="text"
        label="Business Name *"
        width={1}
        disabled={isLoading}
        error={errors.name}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={values.name ? `${values.name.length}/50 characters` : "0/50 characters"}
      />
      <TextField
        id="websiteURL"
        name="websiteURL"
        type="text"
        label="Website"
        width={1}
        disabled={isLoading}
        error={errors.websiteURL}
        value={values.websiteURL}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ConsiderBox
        paddingY="2"
        considerWording={[
          "The email address that you add below will be displayed publicly on the WorkL site."
        ]}
      />
      <TextField
        name="contactEmail"
        type="text"
        width={1 / 1}
        error={errors?.contactEmail}
        value={values?.contactEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        id="Email"
        label="Contact Email (optional)"
      />
      <Flex flexDirection="column">
        <StyledTextArea
          id="culture.shortStatement"
          name="culture.shortStatement"
          type="text"
          label="Short Statement *"
          width={1}
          disabled={isLoading}
          error={errors?.culture?.shortStatement}
          value={values?.culture.shortStatement}
          helperText={`This will appear under the company name at the top of your page. Other add their mission statement or company slogan here.`}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <StyledAreaMaxCharactersText>
          {values.culture?.shortStatement
            ? `${values.culture.shortStatement.length}/130 characters`
            : "0/130 characters"}
        </StyledAreaMaxCharactersText>
      </Flex>
    </SectionCard>
  )
}
