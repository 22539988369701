/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CustomHooks, Flex, Text } from "@engaging-tech/components"
import { useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import styled from "styled-components"

import SurveyQuickview from "../components/SurveyQuickview"
import FeatureList from "../containers/FeatureList"

const OrganisationsScoresContainer = styled.div`
  margin-top: 20px;

  width: 100%;

  display: flex;
  flex-direction: column;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 8px;
`

const WelcomeView = ({
  currentUser,
  isLoading,
  surveysError,
  showLatestSurveys,
  loadScheduledLiveSurveys
}) => {
  const config = getConfig()

  useEffect(() => {
    loadScheduledLiveSurveys()
  }, [])

  const device = CustomHooks.useClientDevice()

  return (
    <Flex justifyContent="center" width={[1 / 1, "1300px"]} ml="auto" mr="auto">
      <Flex flexDirection="column" width={1 / 1}>
        <Text
          fontSize={30}
          color="secondary.0"
          fontWeight={700}
          lineHeight="30px"
          width="auto"
        >
          {`Hello${
            currentUser?.firstName ? ` ${currentUser.firstName}` : ""
          }, what would you like to work on today?`}
        </Text>
        <Text
          fontSize={24}
          fontWeight={700}
          lineHeight="30px"
          color="secondary.0"
          mt="32px"
        >
          View your organisation's score{" "}
        </Text>

        <OrganisationsScoresContainer>
          <Flex width={1} bg="#003E4C" borderRadius="8px 8px 0px 0px">
            <Text
              color="white"
              fontSize={18}
              fontWeight={700}
              lineHeight="23.4px"
              ml="24px"
              mt="8px"
              mb="8px"
            >
              WorkL's Happiest Workplaces List
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            width={1}
            bg="white"
            borderRadius="0px 0px 8px 8px"
            p="16px 20px"
          >
            <Flex>
              <Text width="fit" fontSize={18} fontWeight={400}>
                The
              </Text>
              <Text width="fit" mx="3px" fontSize={18} fontWeight={700}>
                WorkL Happiest Workplaces 2023
              </Text>
              <Text width="fit" fontSize={18} fontWeight={400}>
                are now live. Find out how your workplace scores.
              </Text>
            </Flex>
            <Button
              bg="#6DE47B"
              width="auto"
              minWidth="288px"
              onClick={() => {
                window.open(
                  `${config.workL.app}/happiest-companies/companies-list`,
                  "_blank"
                )
              }}
            >
              <Text
                color="#003E4C"
                fontSize={16}
                fontWeight={700}
                width="264px"
              >
                DISCOVER HAPPIEST WORKPLACES
              </Text>
            </Button>
          </Flex>
        </OrganisationsScoresContainer>
        {showLatestSurveys && device !== "MOBILE" && (
          <SurveyQuickview
            isLoading={isLoading}
            surveysError={surveysError}
            showLatestSurveys={showLatestSurveys}
          />
        )}
        <FeatureList />
      </Flex>
    </Flex>
  )
}

export default WelcomeView
