const { gql } = require("@apollo/client")

const FETCH_PRODUCTS = gql`
  query fetchProducts($object_type: String, $currency: String, $surveyId: String) {
    fetchProducts(object_type: $object_type, currency: $currency, surveyId: $surveyId) {
      object_type
      prices {
        id
        currency
        tax_behavior
        name
        unit_amount
        unit_amount_decimal
        options {
          shareableLinks
          description
        }
        product_details {
          id
          name
        }
      }
    }
  }
`

export default FETCH_PRODUCTS
