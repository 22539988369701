import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const getNetPromoterData = createSelector(
  ReportSelectors.getReportData,
  data => data?.nps
)

export const getScoreRepartitionGraphData = createSelector(
  getNetPromoterData,
  data => {
    return {
      labels: data?.answers.map(answer => answer.answer),
      datasets: [
        {
          data: data?.answers.map(answer => answer?.percentage)
        }
      ]
    }
  }
)
