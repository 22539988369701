import { connect } from "react-redux"

import { loadComparison } from "../../store/report.actions"
import {
  getComparisonSurveyId,
  getMatchedSurveys
} from "../../store/report.selectors"
import { DropdownTab } from "../components/HeatMapTabs/DropdownTab"

const mapState = state => ({
  dropdownData: getMatchedSurveys(state),
  comparisonSurveyId: getComparisonSurveyId(state)
})

const mapDispatch = dispatch => ({
  load: (id, directReports, filters, type, title) =>
    dispatch(loadComparison(id, directReports, filters, type, title))
})

const DropdownTabContainer = connect(mapState, mapDispatch)(DropdownTab)

export default DropdownTabContainer
