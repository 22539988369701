import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  H2,
  Modal,
  Paragraph,
  Text,
  Utils
} from "@engaging-tech/components"
import { Formik } from "formik"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

import FilterGroup from "./FilterGroup"
import { QuestionFilter } from "./QuestionFilter"

const StickyFlex = styled(Flex)`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background-color: white;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const FullWidthForm = styled.form`
  width: 100%;
`
const Filters = ({
  toggle,
  setFilters,
  formValues,
  availableFilters,
  isDirectReportsAvailable,
  setDirectReports,
  onClick,
  renderingHeatMap,
  setQuestionFilter
}) => {
  const close = () => {
    toggle()
    onClick && onClick()
  }

  const onSubmit = formVals => {
    if (formVals.hasOwnProperty("question")) {
      setQuestionFilter(formVals["question"])
    }

    const newActiveFilters = Object.keys(formVals).reduce((res, key) => {
      if (key !== "directReports") {
        const filter = availableFilters.find(f => f.questionId === key)

        if (filter) {
          const { question, values } = filter

          if (formVals[key].length) {
            res.push({
              questionId: key,
              question,
              values: formVals[key].map(valueId => ({
                valueId,
                value: values.find(v => v.valueId === valueId).value
              }))
            })
          }
        }
      }
      return res
    }, [])

    setFilters(newActiveFilters)
    setDirectReports(formVals.directReports)
    onClick && onClick()
  }

  const dropDownListInitialState = availableFilters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.questionId]: false
    }),
    {}
  )

  const [dropDownList, setDropDownList] = useState(dropDownListInitialState)

  return createPortal(
    <Modal
      bg="light.0"
      onToggle={toggle}
      maxWidth={1100}
      my={[5]}
      px={4}
      pt={3}
      width="90vw"
    >
      <Utils.DisableScroll />
      <Card elevation={0} alignItems="center" width={1}>
        <Formik
          initialValues={formValues}
          validate={() => {}}
          onSubmit={onSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => {
            return (
              <FullWidthForm onSubmit={handleSubmit}>
                <Flex>
                  <Box width={1}>
                    <H2 fontSize={4} fontWeight={400} mb={2}>
                      Filter Results by Organisational Groups & Demographics
                    </H2>

                    <Paragraph fontSize={2} color="dark.2">
                      View a specific set of results by selecting the business
                      groups that you want to see. This will help you deep dive
                      into issues and do advanced analysis.
                    </Paragraph>
                  </Box>

                  {isDirectReportsAvailable && (
                    <Box width="150px" ml={6} pt={3}>
                      <Checkbox
                        bg="primary.2"
                        py={2}
                        px={3}
                        borderRadius="5px"
                        label={() => <Text fontSize={3}>VIEW DIRECTS</Text>}
                        checked={values.directReports}
                        id="directReports"
                        colour="dark.2"
                        onChange={() => {
                          setFieldValue("directReports", !values.directReports)
                        }}
                        onBlur={handleBlur}
                      />
                    </Box>
                  )}
                </Flex>
                <Flex
                  flexDirection={["column", "row"]}
                  flexWrap={["noWrap", "wrap"]}
                  justifyContent="space-between"
                >
                  {availableFilters?.length > 0 && (
                    <Box>
                      <FilterGroup
                        title="Organisational Groups & Demographics"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        filterOptions={availableFilters}
                        dropDownList={dropDownList}
                        setDropDownList={setDropDownList}
                      />
                      {renderingHeatMap ? (
                        <QuestionFilter
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          dropDownList={dropDownList}
                          setDropDownList={setDropDownList}
                        />
                      ) : null}
                    </Box>
                  )}
                </Flex>
                <StickyFlex justifyContent="flex-end" py={3}>
                  <Button
                    variant="text.dark.2"
                    elevation={0}
                    onClick={close}
                    mr={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    bg="primary.0"
                    type="submit"
                    color="light.0"
                    elevation={0}
                    px={4}
                  >
                    Apply Filters
                  </Button>
                </StickyFlex>
              </FullWidthForm>
            )
          }}
        </Formik>
      </Card>
    </Modal>,
    appRoot()
  )
}

export default Filters
