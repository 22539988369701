export const removeKey = (key, { [key]: _, ...rest }) => rest

export const findIfDisabled = (mode, localMode) => mode !== localMode

export const camelCaseString = string => {
  return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ""
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}
