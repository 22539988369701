import { connect } from "react-redux"
import { setRecipients } from "../../../store/actions"
import { getRecipients } from "../../../store/selectors"
import AddEmail from "../../../components/Page2/Participants/AddEmail"

const mapState = state => ({
  emailLength: getRecipients(state).length
})

const mapDispatch = dispatch => ({
  setRecipients: recipients => dispatch(setRecipients(recipients))
})

const AddEmailContainer = connect(mapState, mapDispatch)(AddEmail)

export default AddEmailContainer
