import { Text, TextField } from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React from "react"
import { useSelector } from "react-redux"

import { UISelectors } from "../../../../ui"
import { SelectInput } from "../../../components/custom"
import SectionCard from "../../../components/section-card"
import { companySizes } from "../../../utils"

export default function BusinessDetailsSection({ isLoading }) {
  const { errors, values, handleBlur, handleChange } = useFormikContext()
  const allIndustriesOptions = useSelector(UISelectors.getAllIndustryOptions)

  const currentYear = new Date().getFullYear()
  const years = []
  for (let year = 1900; year <= currentYear; year++) {
    years.push(year)
  }

  return (
    <SectionCard title="Business Details">
      <TextField
        id="services"
        name="services"
        type="text"
        label="Services/products *"
        width={1 / 1}
        disabled={isLoading}
        error={errors.services}
        value={values?.services}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={values?.services ? `${values?.services?.length}/50 characters` : "0/50 characters"}
      />
      <SelectInput
        id="size"
        name="size"
        type="text"
        label="Size *"
        width={1 / 1}
        pb={3}
        disabled={isLoading}
        error={errors?.size}
        value={values?.size}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="" disabled>
          {" "}
          --{" "}
        </option>
        {companySizes.map(size => (
          <option key={size.id} value={size.id}>
            {size.name}
          </option>
        ))}
      </SelectInput>
      <SelectInput
        id="industryId"
        name="industryId"
        label="Industry"
        type="text"
        width={1 / 1}
        pb={3}
        disabled={isLoading}
        error={errors.industryId}
        value={values?.industryId}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value="" disabled>
          --
        </option>
        {allIndustriesOptions.map(ind => (
          <option key={ind.id} value={ind.id}>
            {ind.value}
          </option>
        ))}
      </SelectInput>
      <Text color="dark.2" fontWeight={500} mb={1}>
        When was your business founded?
      </Text>
      <SelectInput
        id="foundedAt"
        name="foundedAt"
        type="text"
        width="auto"
        pb={3}
        disabled={isLoading}
        error={errors.foundedAt}
        value={values?.foundedAt}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {years.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </SelectInput>
    </SectionCard>
  )
}
