const { gql } = require("@apollo/client")

const GET_ACTION_PLAN = gql`
  query getActionPlan($actionPlanId: String!) {
    getActionPlan(actionPlanId: $actionPlanId) {
      steps {
        id
        title
        updatedAt
      }
      default
      title
      createdAt
      assignedTo {
        id
        title
        createdAt
      }
    }
  }
`

export default GET_ACTION_PLAN
