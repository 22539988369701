import React from "react"
import styled from "styled-components"
import { Button, Text } from "@engaging-tech/components"

import colorStringToTheme from "../../../../helpers/colorStringToTheme"

const ButtonText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`

const BorderButton = styled(Button)`
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  border-color: ${({ theme, borderColor }) =>
    colorStringToTheme(theme, borderColor)};
  &:hover {
    transform: translateY(0.5px);
  }
`

const OutlineButton = ({
  text = null,
  color = "secondary.0",
  bg = "light.0",
  elevation = 0,
  borderColor = "secondary.0",
  children = null,
  ...props
}) => (
  <BorderButton
    alignItems="center"
    width="auto"
    bg={bg}
    elevation={elevation}
    borderColor={borderColor}
    {...props}
  >
    <ButtonText color={color}>{text || children}</ButtonText>
  </BorderButton>
)

export default OutlineButton
