/* eslint-disable react-hooks/exhaustive-deps */
import { getConfig } from "@engaging-tech/ssr-config"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { apollo } from "../lib/Apollo"

const BUSINESS_APP_GRAPHQL_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

const useBusinessAppQuery = (query, options = {}) => {
  const {
    initAction = null,
    successAction = null,
    failAction = null,
    variables = null,
    enabledOnMount = true,
    entitlement = null
  } = options

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(enabledOnMount)
  const [errors, setErrors] = useState()
  const [data, setData] = useState()

  const getData = async unmountedVariables => {
    try {
      setIsLoading(true)
      const res = await apollo.query({
        uri: BUSINESS_APP_GRAPHQL_API_URL,
        entitlement,
        query,
        variables: enabledOnMount ? variables : unmountedVariables
      })

      setIsLoading(res.loading)
      setData(res.data)
      if (res.data && successAction) dispatch(successAction(res.data))
      if (res.loading && initAction) dispatch(initAction(res.loading))
    } catch (error) {
      if (error) {
        setIsLoading(false)
        if (failAction) dispatch(failAction(error))
        setErrors(error)
      }
    }
  }

  useEffect(() => {
    if (enabledOnMount) getData()
  }, [])

  return { isLoading, errors, data, getData }
}

export default useBusinessAppQuery
