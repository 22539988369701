import React from "react"
import { Flex, Box, Text, BarChart } from "@engaging-tech/components"
import styled from "styled-components"

const ItalicText = styled(Text)`
  font-style: italic;
`

const OverflowContainerBox = styled(Box)`
  overflow-x: auto;
  min-width: "700px";
  -webkit-overflow-scrolling: touch;
`

const SplitByCategory = ({ category, exportingPDF }) => {
  return (
    <Flex flexDirection="column">
      <Flex>
        <Text color="dark.2" fontWeight={500} width="auto">
          Comparison Type -
        </Text>
        <Text ml={1} fontWeight={500} width="auto">
          {category.type}
        </Text>
      </Flex>
      <ItalicText color="dark.2" fontSize={3} mt={3}>
        To protect the anonymity of employees the data will appear as 0 if less
        than 5 people match this data selection
      </ItalicText>
      <OverflowContainerBox>
        <BarChart
          disableAllAnimation={exportingPDF}
          dataset={category.graphDataset}
          graphOptions={{
            scales: {
              y: {
                min: 0,
                max: 100
              }
            }
          }}
          mt={3}
          showDataLabels
          height={[250, 400, 550]}
          unit="%"
          yAxisLabel="Average Six Step Scores"
        />
      </OverflowContainerBox>
    </Flex>
  )
}

export default SplitByCategory
