export const types = {
  GET_LIVE_LEADS_SUCCESS: "[Leads] Get Live Leads Success",
  GET_LIVE_LEADS_FAIL: "[Leads] Get Live Leads Fail"
}

export const getLiveLeadsSuccess = payload => ({
  type: types.GET_LIVE_LEADS_SUCCESS,
  payload: payload.getMyLeads
})

export const getLiveLeadsFail = payload => ({
  type: types.GET_LIVE_LEADS_FAIL,
  payload
})
