import React from "react"

const SentimentAnalysisLogo = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z"
        fill="#E9F9FB"
      />
      <path
        d="M23 10C21.55 10 20.74 11.44 21.07 12.51L17.52 16.07C17.22 15.98 16.78 15.98 16.48 16.07L13.93 13.52C14.27 12.45 13.46 11 12 11C10.55 11 9.73 12.44 10.07 13.52L5.51 18.07C4.44 17.74 3 18.55 3 20C3 21.1 3.9 22 5 22C6.45 22 7.26 20.56 6.93 19.49L11.48 14.93C11.78 15.02 12.22 15.02 12.52 14.93L15.07 17.48C14.73 18.55 15.54 20 17 20C18.45 20 19.27 18.56 18.93 17.48L22.49 13.93C23.56 14.26 25 13.45 25 12C25 10.9 24.1 10 23 10Z"
        fill="#20C1D3"
      />
      <path
        d="M17 11L17.94 8.93L20 8L17.94 7.07L17 5L16.08 7.07L14 8L16.08 8.93L17 11Z"
        fill="#20C1D3"
      />
      <path
        d="M5.5 13L6 11L8 10.5L6 10L5.5 8L5 10L3 10.5L5 11L5.5 13Z"
        fill="#20C1D3"
      />
    </svg>
  )
}
export default SentimentAnalysisLogo