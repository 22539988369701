import { connect } from "react-redux"
import ChooseSurveySize from "../../components/Page1/ChooseSurveySize"
import {
  getAvailableProducts,
  getErrors,
  getIsLoading,
  getSelectedProduct
} from "../../../../store/products/products.selectors"
import { setSelectedProduct } from "../../../../store/basket/basket.actions"
import { getObjectType } from "../../store/selectors"

const mapState = state => ({
  products: getAvailableProducts(state, { object_type: getObjectType(state) }),
  isLoading: getIsLoading(state),
  error: getErrors(state),
  selectedPriceId: getSelectedProduct(state, {
    object_type: getObjectType(state)
  })?.id,
  objectType: getObjectType(state)
})

const mapDispatch = dispatch => ({
  setSelectedProduct: product => dispatch(setSelectedProduct(product))
})

const ChooseSurveySizeContainer = connect(
  mapState,
  mapDispatch
)(ChooseSurveySize)

export default ChooseSurveySizeContainer
