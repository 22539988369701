import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

const UppercaseText = styled(Text)`
  text-transform: uppercase;
`

const ServiceHelperText = ({ title, subtitle, items, mb = [0, 4] }) => (
  <Flex
    flexDirection="column"
    width={["auto", "100%"]}
    p={2}
    mx={[3, 0]}
    mb={mb}
    bg="secondary.4"
  >
    <UppercaseText px={2} pt={2} pb={1} fontSize={3} color="dark.1">
      {title}
    </UppercaseText>
    {!!subtitle && (
      <Text px={2} pb={2} fontSize={3} color="dark.1">
        {subtitle}
      </Text>
    )}
    {items &&
      items.map(item => (
        <Text px={2} pb={1} fontSize={3} color="dark.1">
          - {item}
        </Text>
      ))}
  </Flex>
)

export default ServiceHelperText
