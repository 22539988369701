import { Button } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

const DownloadShortText = ({ link, isErrorView }) => {
  if (isErrorView) return null
  if (!link) return null
  return (
    <Link newTab external to={link}>
      <Button
        variant="outline.secondary.0"
        minWidth={[175, 200]}
        color="secondary.0"
        leadingIcon="cloud_download"
        fontSize={[2, 3]}
        m="6px"
        elevation={0}
        px={3}
        fontWeight={500}
      >
        Text Responses
      </Button>
    </Link>
  )
}
export default DownloadShortText
