import { connect } from "react-redux"
import TemplateHeader from "../components/TemplateHeader"
import { getTemplateId, getViewOnly } from "../store/template.selectors"

const mapState = state => ({
  templateId: getTemplateId(state),
  viewOnly: getViewOnly(state)
})

const TemplateHeaderContainer = connect(mapState, null)(TemplateHeader)

export default TemplateHeaderContainer
