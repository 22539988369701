const surveyTypes = {
  "1": {
    label: "Engagement Survey",
    surveyType: "organisation"
  }
}

const subTypesList = {
  "1": {
    label: "Happiness Score",
    subType: "happinessScore"
  },
  "2": { label: "Six steps", subType: "sixSteps" }
}

export { surveyTypes, subTypesList }
