import { connect } from "react-redux"
import NavComponent from "../components/Nav"
import { getStatus } from "../store/surveyInfo.selectors"

const mapState = state => ({
  status: getStatus(state)
})

const Nav = connect(mapState)(NavComponent)

export default Nav
