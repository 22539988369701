export const types = {
  CREATE_INTENT: "[surveyInfoReview] Create Intent",
  CREATE_INTENT_SUCCESS: "[surveyInfoReview] Create Intent Success",
  CREATE_INTENT_FAIL: "[surveyInfoReview] Create Intent Failure",
  PURCHASE_CREDITS_SUCCESS: "[surveyInfoReview Purchase Credits Success",
  PURCHASE_CREDITS_FAILURE: "[surveyInfoReview] Purchase Credits Failure",
  SCHEDULE_SURVEY: "[surveyInfoReview] Schedule Survey",
  SCHEDULE_SURVEY_SUCCESS: "[surveyInfoReview] Schedule Survey Success",
  SCHEDULE_SURVEY_FAIL: "[surveyInfoReview] Schedule Survey Failure",
  RESET: "[surveyInfoReview] Reducer Reset",
  SET_CREDITS_TO_PURCHASE: "[surveyInfoReview] Set Credits To Purchase"
}

export const createIntent = ({ bypassPayment, onSuccess }) => ({
  type: types.CREATE_INTENT,
  payload: { bypassPayment, onSuccess }
})

export const createIntentSuccess = payload => ({
  type: types.CREATE_INTENT_SUCCESS,
  payload
})

export const createIntentFail = () => ({
  type: types.CREATE_INTENT_FAIL
})

export const purchaseCreditsSuccess = () => ({
  type: types.PURCHASE_CREDITS_SUCCESS
})

export const purchaseCreditsFailure = () => ({
  type: types.PURCHASE_CREDITS_FAILURE
})

export const scheduleSurvey = payload => ({
  type: types.SCHEDULE_SURVEY,
  payload
})

export const scheduleSurveySuccess = () => ({
  type: types.SCHEDULE_SURVEY_SUCCESS
})

export const scheduleSurveyFail = () => ({
  type: types.SCHEDULE_SURVEY_FAIL
})

export const reset = () => ({
  type: types.RESET
})

export const setCreditsToPurchase = credits => ({
  type: types.SET_CREDITS_TO_PURCHASE,
  payload: credits
})
