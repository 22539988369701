import { Button, Flex, Text } from "@engaging-tech/components"
import { useFormikContext } from "formik"
import React from "react"

import useBIPNavigationContext from "../hooks/use-bip-navigation-context"

export default function FormFooter({ isLoading, onSubmit }) {
  const { isValid, values } = useFormikContext()

  const { activeTab, onChangeTab, onPublish } = useBIPNavigationContext()

  return (
    <Flex justifyContent="space-between">
      {activeTab !== "finish" ? (
        <Button
          type="button"
          variant="text.primary.0"
          elevation={0}
          minWidth={110}
          disabled={isLoading}
          onClick={() => {
            onSubmit(values)
          }}
        >
          Save
        </Button>
      ) : null}
      <Flex width="fit">
        {activeTab === "your-culture" || activeTab === "review" ? (
          <Button
            type="button"
            variant="text.dark.2"
            elevation={0}
            width={[80, 80, 130]}
            mr={2}
            onClick={e => {
              e.preventDefault()

              if (activeTab === "your-culture") {
                onChangeTab("business-information")
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              } else if (activeTab === "review") {
                onChangeTab("your-culture")
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              }
            }}
          >
            Back
          </Button>
        ) : null}
        {activeTab !== "finish" ? (
          <Button
            type="button"
            variant="primary.0"
            width="auto"
            px={[3, 5]}
            disabled={activeTab === "review" && !isValid}
            onClick={e => {
              e.preventDefault()

              if (activeTab === "business-information") {
                onSubmit(values)
                onChangeTab("your-culture")
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              } else if (activeTab === "your-culture") {
                onSubmit(values)
                onChangeTab("review")
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              } else if (activeTab === "review") {
                onSubmit(values, true)
                onPublish()
              }
            }}
          >
            <Text color="light.0">
              {activeTab === "review" ? "Publish" : "Next"}
            </Text>
          </Button>
        ) : null}
      </Flex>
    </Flex>
  )
}
