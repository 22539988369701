import {
  Button,
  Flex,
  Icon,
  Modal,
  Spinner,
  Text
} from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"
import { createPortal } from "react-dom"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import REMOVE_FROM_BASKET from "../../../graphql/mutations/removeFromBasket"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import {
  removeFromBasketFail,
  removeFromBasketSuccess
} from "../../../store/basket/basket.actions"
import { UPDATE_ACCOUNT_PREFERENCES } from "../../account/services/account.queries"
import {
  updateAccountPreferencesFail,
  updateAccountPreferencesSuccess
} from "../../account/store/account.actions"
import { getAccountCurrency } from "../../account/store/account.selectors"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const Warning = ({ basketTitle, setDisplayWarning, basketProductId }) => {
  const history = useHistory()
  const paths = usePaths()
  const accountPrefCurrency = useSelector(getAccountCurrency)

  const { isLoading, post } = useBusinessAppMutation(REMOVE_FROM_BASKET, {
    successAction: removeFromBasketSuccess,
    failAction: removeFromBasketFail
  })
  const { isLoading: accountLoading, post: postAccount } =
    useBusinessAppMutation(UPDATE_ACCOUNT_PREFERENCES, {
      successAction: updateAccountPreferencesSuccess,
      failAction: updateAccountPreferencesFail
    })

  const cancel = async () => {
    // Need to set back the prefered currency on cancel because landing on the page will set the prefered currency to new
    postAccount({ currency: accountPrefCurrency }, () =>
      history.push(paths.yourBasket.index)
    )
  }
  const accept = () =>
    post({ productId: basketProductId }, () => setDisplayWarning(false))

  if (isLoading || accountLoading)
    return createPortal(
      <Modal
        bg="light.0"
        minWidth={900}
        animated
        disableScrollbar
        p={3}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Spinner justifyContent="center" color="primary.0" />
      </Modal>,
      appRoot()
    )

  return createPortal(
    <Modal
      bg="light.0"
      minWidth={900}
      animated
      disableScrollbar
      p={3}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Icon name="warning" size={60} color="primary.0" />
      <Text mb={2}>
        {`You currently have entry into ${basketTitle} award in your basket. You
        can only purchase one awards entry at a time. Please note that entering
        a new award will remove your current award entry from your basket.`}
      </Text>
      <Text mb={4}>
        Please select continue if you wish to purchase a new award or select
        cancel if you wish to continue with your current award entry.
      </Text>

      <Flex justifyContent="space-around">
        <Button width={150} bg="secondary.0" color="light.0" onClick={accept}>
          Continue
        </Button>
        <Button width={150} bg="primary.0" color="light.0" onClick={cancel}>
          Cancel
        </Button>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default Warning
