import { createSelector } from "reselect"

export const actionPlansState = state => {
  return state && state.actionPlans
}

export const getActionPlans = createSelector(
  actionPlansState,
  state => state?.actionPlans
)

export const getActionPlanStep = createSelector(
  actionPlansState,
  state => state?.actionPlanStep
)

export const getSelectedTab = createSelector(
  actionPlansState,
  state => state.selectedTab
)

export const getEmployeeActionPlans = createSelector(
  getActionPlans,
  state => state?.employeeActionPlans
)

export const getManagerActionPlans = createSelector(
  getActionPlans,
  state => state?.managerActionPlans
)

export const getActionPlansList = createSelector(
  getActionPlans,
  state => state?.list
)

export const getActionPlanData = createSelector(
  actionPlansState,
  state => state?.selectedActionPlan
)

export const getListActionPlansPage = createSelector(
  getActionPlans,
  state => state?.currentPage
)

export const getListActionPlansSort = createSelector(
  getActionPlans,
  state => state?.sort
)

export const showListLoadMorePlans = createSelector(
  getActionPlans,
  state => state?.currentPage !== state?.totalPages
)

export const getStepQuestions = createSelector(
  getActionPlanStep,
  state => state?.questions
)

export const postActionPlanStepData = createSelector(
  getActionPlanStep,
  state => ({
    id: state?.id,
    actionPlanId: state?.actionPlanId,
    default: false,
    title: state?.title,
    type: state?.type,
    questions: Object.keys(state?.questions || {}).map(key => ({
      sixStepsQuestionId: key,
      title: state.questions[key].title,
      body: {
        questions: state.questions[key].questions,
        suggestedActions: state.questions[key].suggestedActions,
        suggestedPhrases: state.questions[key].suggestedPhrases,
        resources: state.questions[key].resources,
        description: state.questions[key].description
      }
    }))
  })
)

export const canSendForm = createSelector(getActionPlanStep, state => {
  return Object.keys(state?.formErrors)?.length === 0
})
