/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import {
  Flex,
  Text,
  Icon,
  Checkbox,
  Chip,
  Box
} from "@engaging-tech/components"

import categories from "../../../lib/categories"

const KEY_CODES = {
  spaceBar: 32,
  enter: 13
}

const activeDropdown = css`
  background-color: ${({ theme }) => theme.colors.dark[5]};
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary[0]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark[5]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary[0]};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.dark[5]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary[0]};
  }

  img {
    transform: rotate(180deg);
  }
`

const CategorySelectDropdown = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[3]};
  background-color: ${({ theme }) => theme.colors.dark[7]};
  border-radius: 4px 4px 0 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark[6]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.dark[1]};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.dark[5]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary[0]};
    transition: 0.15s;
  }

  ${({ isActive }) => isActive && activeDropdown}
`

const LabelText = styled(Text)`
  user-select: none;
`

const ChipWrapper = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`

const DropdownWrapper = styled(Flex)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  & > :last-child {
    padding-bottom: ${({ theme }) => theme.space[5]}px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    & > :last-child {
      padding-bottom: ${({ theme }) => theme.space[3]}px;
    }
  }
`

const CategorySelect = ({
  name,
  label,
  helperText,
  onChange,
  closeDropdownTrigger,
  onBlur
}) => {
  const [isDropdownVisible, setisDropdownVisible] = useState(false)
  const [selected, setSelected] = useState([])

  const toggleDropdown = () => setisDropdownVisible(!isDropdownVisible)

  const isSelected = id => !!selected.find(selectedItem => selectedItem === id)

  const toggleSelection = id =>
    isSelected(id)
      ? setSelected(selected.filter(item => item !== id))
      : setSelected([...selected, id])

  /**
   * Opens/closes the dropdown's contents when either the space bar or enter key
   * have been pressed whilst the dropdown is focused.
   * @param {Event} event the key press DOM event.
   */
  const handleKeyPress = event => {
    event.preventDefault()
    const keyCode = event.which

    if (keyCode === KEY_CODES.enter || keyCode === KEY_CODES.spaceBar) {
      toggleDropdown()
    }
  }

  useEffect(() => {
    // Formik expects an object with target, name and value. It won't
    // work otherwise.
    const customEventForFormik = {
      target: {
        name,
        value: selected
      }
    }
    onChange(customEventForFormik)
  }, [selected])

  useEffect(() => {
    setisDropdownVisible(false)
  }, [closeDropdownTrigger])

  return (
    <Box mb={3} position="relative">
      <CategorySelectDropdown
        id="categorySelect"
        bg="dark.7"
        height={55}
        alignItems="center"
        justifyContent="space-between"
        px={3}
        onClick={toggleDropdown}
        onBlur={onBlur}
        onKeyPress={handleKeyPress}
        isActive={isDropdownVisible}
        tabIndex={0}
        width={1 / 1}
        position="relative"
      >
        {selected.length > 0 ? (
          <ChipWrapper width="auto">
            {selected.map(id => (
              <Chip
                key={id}
                mr={2}
                isActive
                trailingIcon="close"
                onClick={e => {
                  e.stopPropagation()
                  toggleSelection(id)
                }}
              >
                {categories.find(category => category.id === id).name}
              </Chip>
            ))}
          </ChipWrapper>
        ) : (
          <LabelText color="dark.2">{label}</LabelText>
        )}
        <Icon name="dropdown-arrow" width="auto" m={0} />
      </CategorySelectDropdown>
      {helperText && (
        <Text mx={3} color="dark.2" fontSize={2}>
          {helperText}
        </Text>
      )}
      {isDropdownVisible && (
        <DropdownWrapper
          flexWrap="wrap"
          width={1 / 1}
          p={3}
          position="absolute"
          bg="light.0"
          zIndex="1"
          elevation={2}
          borderRadius={4}
          maxHeight={300}
        >
          {categories.map(category => (
            <Checkbox
              width={[1 / 1, 1 / 2, 1 / 3]}
              py={2}
              colour="primary.0"
              id={category.id}
              onBlur={onBlur}
              checked={isSelected(category.id)}
              onChange={() => toggleSelection(category.id)}
              key={category.id}
              value={category.id}
              label={() => <Text>{category.name}</Text>}
              disabled={!isSelected(category.id) && selected.length >= 3}
            />
          ))}
        </DropdownWrapper>
      )}
    </Box>
  )
}

export default CategorySelect
