import { connect } from "react-redux"
import EditCustomQuestion from "../../components/EditBody/EditCustomQuestion"
import {
  deleteOption,
  setCustomQuestionType,
  changeScale,
  setQuestionTitle,
  setQuestionLabel,
  setValue,
  setField,
  setQuestionIcon,
  setPageErrors
} from "../../store/template.actions"

const mapDispatch = dispatch => ({
  setCustomQuestionType: (type, id) =>
    dispatch(setCustomQuestionType(type, id)),
  deleteOption: (valueIdx, questionId) =>
    dispatch(deleteOption(valueIdx, questionId)),
  changeScale: (value, type, questionId) =>
    dispatch(changeScale(value, type, questionId)),
  setQuestionTitle: (title, questionId) =>
    dispatch(setQuestionTitle(title, questionId)),
  setQuestionLabel: (label, questionId) =>
    dispatch(setQuestionLabel(label, questionId)),
  setValue: (value, valueIdx, questionId) =>
    dispatch(setValue(value, valueIdx, questionId)),
  setField: (field, fieldIdx, questionId) =>
    dispatch(setField(field, fieldIdx, questionId)),
  setQuestionIcon: (icon, questionId) =>
    dispatch(setQuestionIcon(icon, questionId)),
  setPageErrors: (errors, type, id) => dispatch(setPageErrors(errors, type, id))
})

const EditCustomQuestionContainer = connect(
  null,
  mapDispatch
)(EditCustomQuestion)

export default EditCustomQuestionContainer
