import React from "react"
import styled from "styled-components"

const TooltipContainer = styled.span`
  margin-top: 2px;
  margin-left: 1px;
  font-size: 8px;
  border-radius: 20px;
  background-color: #f1efef;
  padding: 0 3px;
  border: 1px solid #b4b4b8;
`

export default function TooltipIcon() {
  return <TooltipContainer>?</TooltipContainer>
}
