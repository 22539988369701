/* eslint-disable camelcase */
import React from "react"
import { Flex, H2, Spinner, Text } from "@engaging-tech/components"
import styled from "styled-components"

export const MarginFlex = styled(Flex)`
  border-top: 2px solid #dfdfdf;
`
export const UnderlinedText = styled(Text)`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

const Image = styled.img`
  margin-right: 15px;
`

const Item = ({ item, currency, handleRemoveItem, isLoading }) => {
  const {
    productName,
    unit_amount,
    priceName,
    priceDescription,
    productId,
    thumbnail
  } = item

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return (
    <MarginFlex width={1 / 1} mt={3} pt={2}>
      {/* TODO: Add images */}
      <Image src={thumbnail} width={60} />
      <Flex flexDirection="column">
        <Flex justifyContent="space-between">
          <H2 fontSize={5} fontWeight={700} color="secondary.0" pr={4}>
            {productName}
          </H2>
          <Text
            width="fit-content"
            fontSize={5}
            fontWeight={700}
            color="secondary.0"
            mt="1px"
          >
            {new Intl.NumberFormat("en-EN", {
              style: "currency",
              minimumFractionDigits: 0,
              currency
            }).format(unit_amount)}
          </Text>
        </Flex>
        <Flex flexDirection="column" mt={4}>
          <Text color="secondary.0">{priceName}</Text>
          <Text color="secondary.0">{priceDescription}</Text>
          <UnderlinedText
            color="secondary.0"
            textAlign="right"
            mt={2}
            onClick={() => handleRemoveItem({ productId })}
          >
            Remove from basket
          </UnderlinedText>
        </Flex>
      </Flex>
    </MarginFlex>
  )
}

export default Item
