import { Button } from "@engaging-tech/components"
import React from "react"

import ToggleLogic from "../../../ui/containers/ToggleLogic"
import Filters from "../containers/Filters"

const FiltersButton = ({
  activeFilters,
  availableFilters,
  noIcon,
  width,
  onClick
}) => {
  if (!availableFilters || availableFilters.length === 0) return null
  return (
    <ToggleLogic>
      {({ on, toggle, toggleOn }) => (
        <>
          <Button
            bg="primary.0"
            minWidth={width || [175, 200]}
            onClick={toggleOn}
            leadingIcon={noIcon ? "" : "filter_list"}
            elevation={0}
            color="light.0"
            fontSize={[12, 3]}
            m={["6px"]}
            px={3}
          >
            {activeFilters && activeFilters.length > 1
              ? "EDIT RESULTS"
              : "FILTER RESULTS"}
          </Button>
          {on && <Filters toggle={toggle} onClick={onClick} />}
        </>
      )}
    </ToggleLogic>
  )
}

export default FiltersButton
