import {
  Box,
  Button,
  Flex,
  H2,
  Icon,
  Modal,
  Paragraph,
  Text
} from "@engaging-tech/components"
import { FieldArray } from "formik"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"
import { createPortal } from "react-dom"
import styled, { css } from "styled-components"
import ClickableIcon from "../../../ui/components/ClickableIcon"
import { Card, FilePickerButton, TextFieldAndButton } from "../../components"

const StyledFlex = styled(Flex)`
  ${({ nonBorderBottom }) =>
    !nonBorderBottom &&
    css`
      border-bottom: 1px solid #c4c4c4;
    `}

  ${({ nonHover }) =>
    !nonHover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`

const StyledModal = styled(Modal)`
  border-radius: 10px;
  box-shadow: none;
`

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const UploadCSV = ({ push }) => {
  const handleFileChange = e => {
    if (typeof e.target.files === "undefined") return
    const reader = new FileReader()
    reader.onloadend = loadEvent => {
      const emailAddrs = loadEvent.target.result.split(",")
      emailAddrs.forEach(address => {
        push(address)
      })
    }
    const csv = e.target.files[0]
    reader.readAsText(csv)
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Uploaded survey CSV"])
    }
  }

  return (
    <>
      <FilePickerButton
        width={145}
        accept=".csv"
        id="csv"
        name="csv"
        onChange={handleFileChange}
        leadingIcon="backup"
        text="Upload .CSV"
      />
      <Text fontSize={2} ml={1}>
        *Email addresses within a .CSV file are not validated
      </Text>
    </>
  )
}

const VerifiedEmail = ({ onDelete, children }) => {
  return (
    <Text
      bg="secondary.2"
      p={2}
      color="dark.0"
      fontSize={4}
      mr={2}
      mb={3}
      borderRadius={4}
    >
      {onDelete ? (
        <Flex>
          {children}
          <ClickableIcon name="close" onClick={onDelete} ml={2} />
        </Flex>
      ) : (
        children
      )}
    </Text>
  )
}

const EmailTextInput = ({
  values,
  setFieldValue,
  errors,
  push,
  handleChange,
  disabled
}) => {
  const handlePush = value => {
    if (values.resultsEmails && values.resultsEmails.includes(value)) return
    push(value)
  }

  const handleKeyPress = e => {
    if (e.key !== "Enter") return
    e.preventDefault()
    if ((errors && errors.email) || e.target.value === "") return
    handlePush(e.target.value)
    setFieldValue("email", "")
  }

  const handleClick = () => {
    if (!values || !values.email) return
    if (errors && errors.email) return
    handlePush(values.email)
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Added email to survey"])
    }
  }

  return (
    <TextFieldAndButton
      onKeyPress={e => handleKeyPress(e)}
      onclick={handleClick}
      value={values && values.email}
      error={errors && (errors.email || errors.resultsEmails)}
      disabled={disabled}
      name="email"
      textFieldProps={{
        onChange: handleChange,
        type: "email",
        placeholder: "Participant email addresses"
      }}
      mb={3}
      buttonText="Add email"
    />
  )
}

const FrequencyModal = ({
  values,
  errors,
  setFieldValue,
  frequencies,
  setShowModal
}) => {
  return createPortal(
    <AnimatePresence>
      <StyledModal
        onToggle={() => setShowModal(false)}
        disableScrollbar
        animated
        bg="light.0"
        width="300px"
      >
        <Card>
          {frequencies.map((frequency, i, arr) => (
            <StyledFlex
              alignItems="flex-end"
              height="36px"
              pb={2}
              mb={i === arr.length - 1 ? 0 : 2}
              onClick={() => {
                setFieldValue("frequency", frequency.id)
              }}
              key={frequency.id}
            >
              <Text>{frequency.text}</Text>
              {frequency.id === values.frequency && (
                <Icon color="secondary.0" name="done" size={27} />
              )}
            </StyledFlex>
          ))}
        </Card>
      </StyledModal>
    </AnimatePresence>,
    appRoot()
  )
}

const StyledBox = styled(Box)`
  background-color: white;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #6398c4;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  .DayPicker-Day--today {
    color: inherit !important;
    font-weight: inherit !important;
  }
  .DayPicker-Day:hover {
    background-color: ${({ theme }) => theme.colors.primary[2]} !important;
  }
  .DayPicker-Day--selected,
  .DayPicker-Day--selected:hover {
    background-color: ${({ theme }) => theme.colors.primary[0]} !important;
    color: ${({ theme }) => theme.colors.light[0]} !important;
  }
`

const EndDateModal = ({ setShowModal, setFieldValue, values, errors }) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  return createPortal(
    <AnimatePresence>
      <StyledModal
        onToggle={() => setShowModal(false)}
        disableScrollbar
        animated
        bg="none"
        width={500}
      >
        <Card
          mx="auto"
          width={showDatePicker ? "inherit" : "284px"}
          bg="light.0"
        >
          <StyledFlex
            alignItems="flex-end"
            height="36px"
            pb={2}
            mb={2}
            onClick={() => setFieldValue("frequencyEndDate", null)}
            width="100%"
          >
            <Text>Never</Text>
            {!values.frequencyEndDate && (
              <Icon color="secondary.0" name="done" size={27} />
            )}
          </StyledFlex>
          <StyledFlex
            onClick={() => setShowDatePicker(!showDatePicker)}
            nonBorderBottom
            alignItems="center"
            width="100%"
          >
            <Text>End Repeat Date</Text>
            <Icon name="keyboard_arrow_right" size={32} color="dark.3" />
          </StyledFlex>
        </Card>
        {showDatePicker && (
          <StyledBox mt={6}>
            <DayPicker
              value={values.frequencyEndDate || new Date()}
              onDayClick={date => setFieldValue("frequencyEndDate", date)}
              selectedDays={values.frequencyEndDate}
              showOutsideDays
              disabledDays={day => day <= new Date()}
            />
          </StyledBox>
        )}
      </StyledModal>
    </AnimatePresence>,
    appRoot()
  )
}

const Rules = ({
  values,
  readOnly,
  errors,
  handleChange,
  validateField,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false)
  const frequencies = [
    { text: "Never", id: "never" },
    { text: "Daily", id: "daily" },
    { text: "Weekly", id: "weekly" },
    { text: "Monthly", id: "monthly" },
    { text: "Quarterly", id: "quaterly" }
  ]

  return (
    <Card {...props}>
      <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5} pb={3}>
        Survey Rules
      </H2>
      <Paragraph>
        This is where you can set additional rules for the survey, like if you
        want it to repeat reminders for users and how often, and specify an
        email address for the results report to be sent to, like a manager.
      </Paragraph>
      <Flex alignItems="flex-end">
        <Icon
          name="repeat"
          size={30}
          bg="primary.0"
          color="light.0"
          p={4}
          borderRadius={30}
          mr={2}
        />
        <StyledFlex alignItems="center" nonHover pb={3}>
          <Text width="auto">Repeat Survey</Text>
          <Button
            ml="auto"
            bg="light.0"
            type="button"
            elevation={0}
            onClick={() => setShowModal("frequency")}
            fontSize={4}
            style={{
              textTransform: "capitalize"
            }}
            color="dark.3"
            disabled={readOnly}
          >
            {
              frequencies.find(frequency => frequency.id === values.frequency)
                .text
            }
            <Icon name="keyboard_arrow_right" size={32} color="dark.3" />
          </Button>
        </StyledFlex>
      </Flex>
      <StyledFlex alignItems="center" mt={3} pb={2} nonHover>
        <Text width="auto">End Repeat</Text>
        <Button
          ml="auto"
          bg="light.0"
          type="button"
          elevation={0}
          onClick={() => setShowModal("endDate")}
          fontSize={4}
          style={{
            textTransform: "capitalize"
          }}
          disabled={readOnly}
          color="dark.3"
        >
          {values.frequencyEndDate
            ? new Date(values.frequencyEndDate).toLocaleDateString()
            : "Never"}
          <Icon name="keyboard_arrow_right" size={32} color="dark.3" />
        </Button>
      </StyledFlex>
      {showModal === "frequency" && (
        <FrequencyModal
          frequencies={frequencies}
          setShowModal={setShowModal}
          values={values}
          errors={errors}
          {...props}
        />
      )}
      {showModal === "endDate" && (
        <EndDateModal
          setShowModal={setShowModal}
          values={values}
          errors={errors}
          {...props}
        />
      )}
      <Flex mt={3} mb={3} alignItems="center">
        <Icon
          mr={2}
          size={30}
          p={4}
          borderRadius={30}
          name="email"
          color="light.0"
          bg="secondary.0"
        />
        <H2 fontWeight="400" fontFamily="sansSerif" fontSize={5}>
          Receive Survey Results Emails
        </H2>
      </Flex>
      <FieldArray name="resultsEmails">
        {({ remove, push }) => (
          <>
            {!readOnly && (
              <EmailTextInput
                push={push}
                values={values}
                errors={errors}
                handleChange={handleChange}
                {...props}
              />
            )}
            {values && values.resultsEmails && (
              <Flex flexWrap="wrap" width="auto">
                {values.resultsEmails.map((email, i) => {
                  return (
                    <VerifiedEmail onDelete={readOnly ? null : () => remove(i)}>
                      {email}
                    </VerifiedEmail>
                  )
                })}
              </Flex>
            )}
            {!readOnly && (
              <UploadCSV
                validateField={validateField}
                push={push}
                remove={remove}
                handleChange={handleChange}
                {...props}
              />
            )}
          </>
        )}
      </FieldArray>
    </Card>
  )
}

export default Rules
