import { gql } from "@apollo/client"

const UPDATE_SURVEY_ACTION_PLAN = gql`
  mutation updateSurveyActionPlan(
    $input: updateSurveyActionPlanAssignmentInput
  ) {
    updateSurveyActionPlan(input: $input) {
      statusCode
      error {
        code
        message
        type
      }
    }
  }
`

export default UPDATE_SURVEY_ACTION_PLAN
