import { connect } from "react-redux"
import TemplateBody from "../components/TemplateBody"
import { getSections } from "../store/template.selectors"

const mapState = state => ({
  sections: getSections(state)
})

const TemplateBodyContainer = connect(mapState, null)(TemplateBody)

export default TemplateBodyContainer
