import { Button, Flex, Spinner } from "@engaging-tech/components"
import React from "react"

import { SAVE_BASKET } from "../../../graphql/mutations"
import { FETCH_PRODUCTS } from "../../../graphql/queries"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { saveBasketFail, saveBasketReq, saveBasketSuccess } from "../../../store/basket/basket.actions"
import { getProductsFail, getProductsReq, getProductsSuccess } from "../../../store/products/products.actions"
import ErrorState from "../../business-information/components/error-state"
import ChooseSurveySizeContainer from "../containers/Page1/ChooseSurveySize"

const Page1 = ({ setPage, saveSelfServeSurvey, selectedProduct, objectType }) => {
  const { errors: fetchErrors } = useBusinessAppQuery(FETCH_PRODUCTS, {
    initAction: getProductsReq,
    successAction: getProductsSuccess,
    failAction: getProductsFail,
    variables: { object_type: objectType }
  })

  const {
    errors: basketErrors,
    post,
    isLoading
  } = useBusinessAppMutation(SAVE_BASKET, {
    initAction: saveBasketReq,
    successAction: saveBasketSuccess,
    failAction: saveBasketFail
  })

  const handleNext = async () => {
    post({
      productId: selectedProduct.product_details.id,
      priceId: selectedProduct.id,
      object_type: objectType
    })
    saveSelfServeSurvey({
      shareableLinks: selectedProduct.options.shareableLinks
    })
    setPage(2)
  }

  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />
  if (basketErrors) return <ErrorState />

  return (
    <Flex mt={5} flexDirection="column">
      <ChooseSurveySizeContainer />
      <Flex mt={[5, 6]} width={1 / 1} justifyContent="flex-end">
        <Button
          color="light.0"
          bg="primary.0"
          width="120px"
          disabled={!!fetchErrors}
          position="relative"
          onClick={() => handleNext()}
        >
          Next ⟶
        </Button>
      </Flex>
    </Flex>
  )
}

export default Page1
