import { Card, Flex, Icon, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const Container = styled(Flex)`
  background: white;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  padding: 16px;

  @media (max-width: 768px) {
    padding: 24px;

    flex-direction: row;
    align-items: center;
  }
`

/**
 *
 * @param {String} title
 * @param {Boolean} optional
 * @param {React.Component} children
 * @returns {React.Component}
 */
export default function SectionCard({ title, optional = false, children }) {
  return (
    <Card mb={3} pb={3} bg="light.0">
      <Container>
        <Flex width={1 / 1} justifyContent="space-between">
          <Flex width="auto">
            <Flex width={20} height={20} bg="secondary.0" borderRadius={99} mr={2}>
              <Icon size={20} name="check" color="white" />
            </Flex>
            <Text fontSize="20px" fontWeight="700" lineHeight="26px" color="#173C5A">
              {title}
            </Text>
          </Flex>
          {optional ? (
            <Flex width="auto">
              <Text color="dark.2" fontWeight={500} width="auto" mt={[3, 0]} mb={[2, 0]}>
                OPTIONAL
              </Text>
            </Flex>
          ) : null}
        </Flex>
      </Container>
      <Flex px={4} flexDirection="column">
        {children}
      </Flex>
    </Card>
  )
}
