import { List, Paragraph, Text } from "@engaging-tech/components"
import React from "react"

import ReportCard from "../../components/ReportCard"

const SummaryBox = ({ summary, questions, disableQuestions, exportingPDF }) => {
  return (
    <ReportCard width={[1 / 1, 1 / 1, 59 / 100]} heading={summary?.heading} exportingPDF={exportingPDF}>
      {summary?.infos?.map((info, i) => {
        if (info.type === "paragraph")
          return (
            <Paragraph color="dark.2" fontSize={3} lineHeight="1.6" key={`${info.text}-${i.toString()}`}>
              {info.text}
            </Paragraph>
          )
        return info.points.map((point, ind) => {
          const isLast = ind === info.points.length - 1
          return (
            <Text
              key={`${point}-${ind.toString()}`}
              color="dark.2"
              fontSize={3}
              mb={isLast ? 4 : 0}
              lineHeight="1.6"
            >
              {`- ${point}`}
            </Text>
          )
        })
      })}
      <List.Wrapper spacing="16px" width={1 / 1}>
        {questions &&
          !disableQuestions &&
          questions.map(question => (
            <List.Item color="dark.2" fontSize={3} key={question.toString()} fontWeight={500}>
              {question}
            </List.Item>
          ))}
      </List.Wrapper>
    </ReportCard>
  )
}

export default SummaryBox
