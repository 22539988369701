import { gql } from "@apollo/client"

const LIST_ACTION_PLANS = gql`
  query listActionPlans(
    $planType: String!
    $limit: Int
    $page: Int
    $sort: ActionPlanSort
  ) {
    listActionPlans(
      planType: $planType
      limit: $limit
      page: $page
      sort: $sort
    ) {
      employeeActionPlans {
        title
        _id
      }
      managerActionPlans {
        title
        _id
      }
      actionPlans {
        _id
        default
        organisationId
        planType
        status
        steps
        createdAt
        surveyCount
        surveyTitle
        title
        updatedAt
      }
      count
      currentPage
      totalPages
    }
  }
`

export default LIST_ACTION_PLANS
