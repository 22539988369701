export const types = {
  SAVE_AWARDS_SURVEY_FAIL: "[Awards] Get Awards Survey Fail",
  SAVE_AWARDS_SURVEY_SUCCESS: "[Awards] Get Awards Survey Success",
  SET_AWARDS_TITLE: "[Awards] Set Title",
  SET_AWARDS_INTRODUCTION: "[Awards] Set Introduction",
  SET_PAGE_ERRORS: "[Awards] Set Page Errors",
  SET_EMPLOYEE_COUNT: "[Awards] Set Employee Count",
  SET_DATE: "[Awards] Set Date",
  GET_ALL_ACTIVE_AWARDS_FAIL: "[Awards] Get All Active Awards Fail",
  GET_AWARDS_BY_TYPE_SUCCESS: "[Awards] Get Awards By Type Success",
  GET_AWARDS_BY_TYPE_FAIL: "[Awards] Get Awards By Type Fail",
  SET_AWARDS_TYPE: "[Awards] Set Awards Type",
  SET_PAGE: "[Awards] Set Page",
  SET_PRIVATE: "[Awards] Set Private"
}

export const setPrivate = payload => ({
  type: types.SET_PRIVATE,
  payload
})

export const setPage = payload => ({
  type: types.SET_PAGE,
  payload
})

export const setAwardsType = payload => ({
  type: types.SET_AWARDS_TYPE,
  payload
})

export const getAwardsByTypeSuccess = payload => ({
  type: types.GET_AWARDS_BY_TYPE_SUCCESS,
  payload
})

export const getAwardsByTypeFail = payload => ({
  type: types.GET_AWARDS_BY_TYPE_FAIL,
  payload
})

export const getAllActiveAwardsFail = payload => ({
  type: types.GET_ALL_ACTIVE_AWARDS_FAIL,
  payload
})

export const setDate = payload => ({
  type: types.SET_DATE,
  payload
})

export const setEmployeeCount = payload => ({
  type: types.SET_EMPLOYEE_COUNT,
  payload
})

export const setPageErrors = (errors, type, id) => ({
  type: types.SET_PAGE_ERRORS,
  payload: { errors, type, id }
})

export const setAwardsIntroduction = payload => ({
  type: types.SET_AWARDS_INTRODUCTION,
  payload
})

export const setAwardsTitle = payload => ({
  type: types.SET_AWARDS_TITLE,
  payload
})

export const saveAwardsSurveyFail = payload => ({
  type: types.SAVE_AWARDS_SURVEY_FAIL,
  payload
})

export const saveAwardsSurveySuccess = payload => ({
  type: types.SAVE_AWARDS_SURVEY_SUCCESS,
  payload
})
