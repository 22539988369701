import React from "react"
import StartSurveyButtons from "./StartSurveyButtons"
import ContentBlock from "../../ui/components/ContentBlock"

const LandingBanner = () => {
  return (
    <ContentBlock
      bg="secondary.0"
      flexDirection="column"
      titleStyles={{
        fontSize: 8,
        fontWeight: 600,
        textAlign: "center",
        color: "white"
      }}
      descriptionStyles={{
        textAlign: "center",
        color: "white",
        mt: 4,
        mb: 1
      }}
      descriptionStyles2={{
        textAlign: "center",
        color: "white"
      }}
      p="60px 24px"
      title="Self Serve Surveys"
      description="Ideal for small and medium organisations, and as pulse surveys for large organisations, with flexible surveys"
      description2="to measure employee experience, wellbeing and more. Quick and easy to set up."
    >
      <StartSurveyButtons />
    </ContentBlock>
  )
}

export default LandingBanner
