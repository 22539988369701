import { usePaths } from "@engaging-tech/routing"
import React from "react"
import JoinLeadsComponent from "../components/JoinLeads"
import EBNBillingWrapper from "../containers/EBNBillingWrapper"

const JoinLeads = () => {
  const paths = usePaths()
  return (
    <EBNBillingWrapper cancelRoute={paths.home.index}>
      {props => <JoinLeadsComponent {...props} />}
    </EBNBillingWrapper>
  )
}

export default JoinLeads
