/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from "redux-saga/effects"
import { loadCMS } from "../services/cms.service"
import * as Actions from "./cms.actions"

export function* loadSaga(action) {
  try {
    const res = yield call(loadCMS, action.payload)
    yield put(Actions.loadSuccess(res.data.getCMS))
  } catch (error) {
    yield put(Actions.loadFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD, loadSaga)
}
