import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../../../lib/Apollo"
import { GET_CORE_QUESTIONS } from "./coreQuestions.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Loads template core questions
 * @param {Object} payload - Template type
 */
export const loadCoreQuestions = (payload = "organisation") =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_CORE_QUESTIONS,
    variables: { type: "organisation" }
  })
