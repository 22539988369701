import { Button, Flex, Modal, Text, userInterfaceNotificationsStore } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"
import { useDispatch } from "react-redux"

import useBusinessAppMutation from "../../../../../../hooks/useBusinessAppMutation"
import { DELETE_ORGANISATION_SURVEY } from "../../../services/surveys.queries"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

export default function DraftSurveyDeleteWarning({ surveyId, onConfirm, onCancel }) {
  const dispatch = useDispatch()

  const { post, isLoading } = useBusinessAppMutation(DELETE_ORGANISATION_SURVEY, {
    onSuccess: () => {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({ message: "Survey deleted successfully!" })
      )
      onConfirm()
    },
    onError: () => {
      dispatch(userInterfaceNotificationsStore.actions.addNotification({ message: "Error deleting survey!" }))
    }
  })

  return createPortal(
    <Modal
      bg="light.0"
      maxWidth={500}
      animated
      disableScrollbar
      p={3}
      overlayProps={{ zIndex: 99999999999999 }}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text fontSize={7} mb={4} fontFamily="Centra" width="auto" color="dark.1" textAlign="center">
        Delete draft survey
      </Text>
      <Text fontSize={5} fontFamily="Centra" width="auto" textAlign="center" color="dark.1">
        Would you like to delete your draft survey? You won’t be able to recover this after deletion.
      </Text>
      <Flex mt={5} mb={3} width="100%" justifyContent="center">
        <Button
          variant="text.noBg.dark.1"
          onClick={onCancel}
          fontSize={4}
          px={4}
          mr={3}
          fontWeight={700}
          style={{ cursor: "pointer" }}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          bg="primary.0"
          onClick={e => {
            e.preventDefault()
            post({ surveyId })
          }}
          fontSize={4}
          width={120}
          color="dark.0"
          fontWeight={700}
          style={{ cursor: "pointer" }}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </Flex>
    </Modal>,
    appRoot()
  )
}
