import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const crossedTools = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/crossed_tools.svg`)
const Img = styled.img``

const StepEmptyState = () => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
    >
      <Text textAlign="center" fontSize={4} width={1 / 1} color="secondary.0">
        You don`t have any Bespoke Steps
      </Text>
      <Img src={crossedTools} alt="crossed tools" />
    </Flex>
  )
}

export default StepEmptyState
