import React from "react"
import { getConfig } from "@engaging-tech/ssr-config"

import { ForgotPassword as ForgotPasswordView } from "../../features/account"
import SiteLogo from "../../features/ui/components/SiteLogo"
import GradientLayout from "../../layouts/GradientLayout"

const ForgotPassword = () => (
  <GradientLayout>
    <ForgotPasswordView
      siteLogo={SiteLogo}
      cancelRoute={`${getConfig().publicSite}`}
      redirectTo="/account/reset-password"
    />
  </GradientLayout>
)

export default ForgotPassword
