import { CircularProgress, Flex, Text } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"

import LinkButton from "../../../ui/components/LinkButton"
import ReportCard from "../../components/ReportCard"
import { ScoreLabel, getIndicatorLabelData } from "../../components/ScoreLabel"
import IndustryAndGlobalScoreBox from "./IndustryAndGlobalScoreBox"

const Indicator = ({
  heading,
  logo,
  averageScoreData,
  mainScore,
  labelScore,
  exportingPDF,
  text,
  breakDown
}) => {
  const { id, surveyType } = useParams()
  const paths = usePaths()
  const { score, industry, global } = averageScoreData

  if (typeof score !== "number") return <></>

  return (
    <>
      <ReportCard
        heading={heading}
        width={["100%", "100%", "40%"]}
        exportingPDF={exportingPDF}
        HeadingImageComponent={logo}
        innerProps={{ justifyContent: "space-between" }}
      >
        <CircularProgress
          percentage={mainScore}
          disableAllAnimation={exportingPDF}
          strokeColor={getIndicatorLabelData(labelScore).color}
          width="70%"
          maxWidth={200}
        />

        <Flex flexDirection="column" mb={4} mt={3}>
          <Text textAlign="center" fontSize={3} color="dark.2" mb={3}>
            {`${mainScore}% ${text}`}
          </Text>

          <ScoreLabel showIcon indicator score={labelScore} />
        </Flex>

        <IndustryAndGlobalScoreBox
          globalScore={global ? Math.round(global) : null}
          industryScore={industry ? Math.round(industry) : null}
          mb={3}
          mt={[3, 0]}
        />

        <Flex flexDirection="row" justifyContent="space-between">
          {!exportingPDF && (
            <LinkButton
              variant="text.primary.0"
              color="primary.0"
              text="SEE RESULTS BREAKDOWN"
              path={`${paths.reports.summary.indicatorBreakdown[breakDown]
                .replace(":surveyType", surveyType)
                .replace(":id", id)}?tag=${breakDown}`}
            />
          )}
        </Flex>
      </ReportCard>
    </>
  )
}

export default Indicator
