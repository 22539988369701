import React from "react"
import { OrgGuard } from "../../features/account"
import SurveyComparisonViewContainer from "../../features/Reports/containers/SurveyComparisonViewContainer"
import DashboardLayout from "../../layouts/DashboardLayout"

const SurveyComparison = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <SurveyComparisonViewContainer />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default SurveyComparison
