import { Button, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

const NoTextTransform = styled(Button)`
  text-transform: none;
`

const BackButton = ({ path, onClick }) => {
  return (
    <Flex width={1 / 1} justifyContent="flex-start">
      <Link to={path}>
        <NoTextTransform
          leadingIcon="arrow_back"
          variant="light.0"
          borderRadius={24}
          elevation={1}
          alignItems="flex-start"
          mb={3}
          onClick={onClick}
        >
          <Text marginRight={4} fontWeight={400} color="dark.2" fontSize={3}>
            Back
          </Text>
        </NoTextTransform>
      </Link>
    </Flex>
  )
}
export default BackButton
