import { getConfig } from "@engaging-tech/ssr-config"
import { apollo } from "../../../../lib/Apollo"
import {
  DELETE_ORGANISATION_TEMPLATE,
  LIST_DEFAULT_SURVEY_TEMPLATES,
  LIST_SURVEY_TEMPLATES
} from "./templateSelection.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * @param {string} status - The status of the templates you want to load
 * @returns An array of survey templates
 */
export const loadOrgSurveyTemplates = (status = "all") =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LIST_SURVEY_TEMPLATES,
    variables: { status, type: "all" }
  })

/**
 * @param {string} surveyTemplateId - The ID of the template the user wants to delete
 * @returns A boolean if successful or not
 */
export const deleteTemplate = payload =>
  apollo.mutate({
    uri: GRAPHQL_API_URL(),
    mutation: DELETE_ORGANISATION_TEMPLATE,
    variables: { surveyTemplateId: payload }
  })

/**
 * Loads default survey templates
 */
export const loadDefaultSurveyTemplate = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: LIST_DEFAULT_SURVEY_TEMPLATES,
    variables: { type: "all" }
  })
