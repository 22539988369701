import { decode } from "@engaging-tech/jwt"
import { useLocation, usePaths, useRouter } from "@engaging-tech/routing"
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession
} from "amazon-cognito-identity-js"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { apollo } from "../../../lib/Apollo"
import { UIActions, UISelectors } from "../../ui"
import { getCognitoTokens } from "../services/account.service"
import { signInFail, signInSuccess } from "../store/account.actions"

const OnLoad = ({ onLoad, isReady }) => {
  const router = useRouter()
  const queryParams = new URLSearchParams(useLocation().search)
  const code = queryParams.get("code")

  const paths = usePaths()

  const getTokens = React.useCallback(
    async function getTokens() {
      try {
        const {
          data: {
            id_token: idToken,
            access_token: accessToken,
            refresh_token: refreshToken
          }
        } = await getCognitoTokens(code)
        onLoad({ idToken, accessToken, refreshToken })
      } catch (error) {
        onLoad({})
      }
    },
    [code, onLoad]
  )

  useEffect(() => {
    getTokens()
  }, [getTokens])

  useEffect(() => {
    if (isReady) router.redirect(paths.reports.index)
  }, [isReady, paths.reports.index, router])

  return null
}

const mapState = state => ({
  isReady: UISelectors.getIsReady(state)
})

const mapDispatch = dispatch => ({
  onLoad: ({ idToken, accessToken, refreshToken }) => {
    dispatch(UIActions.markNotLoadedFeature("account"))
    if (!idToken || !accessToken || !refreshToken) {
      return dispatch(signInFail("ERR006"))
    }
    apollo.useSessionStorage()
    const cognitoUser = new CognitoUser({
      Username: decode(idToken).email,
      Pool: apollo.UserPool(),
      Storage: sessionStorage
    })
    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: idToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken })
    })
    cognitoUser.setSignInUserSession(session)

    return dispatch(signInSuccess(decode(idToken)))
  }
})

/**
 * Validate user's SAML authentication.
 */
const SamlAuth = connect(mapState, mapDispatch)(OnLoad)

export default SamlAuth
