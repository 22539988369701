/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import styled from "styled-components"
import { Flex, Icon } from "@engaging-tech/components"

const ICON_SIZE_RATIO = 1.666667

const NoShrink = styled(Flex)`
  flex-shrink: 0;
`

const getIconSize = circleSize => Math.round(circleSize / ICON_SIZE_RATIO)

const IconCircle = ({
  name,
  size,
  bg = "secondary.1",
  iconColor = "dark.0",
  ...props
}) => (
  <NoShrink
    borderRadius={99}
    justifyContent="center"
    alignItems="center"
    width={size}
    height={size}
    bg={bg}
    {...props}
  >
    <Icon name={name} size={getIconSize(size)} color={iconColor} />
  </NoShrink>
)

IconCircle.defaultProps = {
  size: 40
}

export default IconCircle
