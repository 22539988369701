import { Flex } from "@engaging-tech/components"
import React from "react"
import LargeWebinar from "./LargeWebinar"
import TinyWebinar from "./TinyWebinar"

const Webinars = ({ webinars }) => {
  const mainImageSizing = {
    width: [1 / 1, 1 / 1, 3 / 5],
    height: ["auto", "auto", "300px"]
  }
  return (
    webinars?.length > 0 && (
      <Flex
        flexDirection={["column", "column", "row"]}
        width={1 / 1}
        elevation={0}
        height={320}
        justifyContent="space-between"
        my={2}
      >
        <LargeWebinar featured={webinars[0]} />

        <Flex
          flexDirection="column"
          alignItems={["center", "center", "flex-start"]}
          alignContent="space-between"
          justifyContent="space-between"
          width={[1 / 1, 1 / 1, 2 / 5]}
          height={mainImageSizing.height}
        >
          {webinars?.slice(1, 4).map(webinar => (
            <TinyWebinar
              key={webinar.id}
              linkTo={`network/webinars/${webinar.id}`}
              {...webinar}
            />
          ))}
        </Flex>
      </Flex>
    )
  )
}

export default Webinars
