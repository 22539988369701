/* eslint-disable no-console */
import { connect } from "react-redux"
import { getTargetAnswers } from "../../store/template.selectors"
import ManageTargetChoices from "../../components/Branching/ManageTargetChoices"
import { setBranchingLogic } from "../../store/template.actions"

const mapState = (state, { target, currentChoice }) => ({
  targetAnswers: getTargetAnswers(state, { target, currentChoice })
})

const mapDispatch = dispatch => ({
  setBranchingLogic: (choice, questionId, target, targetAnswers) =>
    dispatch(setBranchingLogic(choice, questionId, target, targetAnswers))
})

const ManageTargetChoicesContainer = connect(
  mapState,
  mapDispatch
)(ManageTargetChoices)

export default ManageTargetChoicesContainer
