import React from "react"
import JoinNetworkView from "../../features/account/views/JoinOurNetwork"
import GradientLayout from "../../layouts/GradientLayout"

const JoinOurNetwork = () => {
  return (
    <GradientLayout>
      <JoinNetworkView />
    </GradientLayout>
  )
}

export default JoinOurNetwork
