import React, { useState } from "react"
import { Flex, Button, Text, Paragraph } from "@engaging-tech/components"
import styled from "styled-components"
import ContentBlock from "../../ui/components/ContentBlock"
import ShowPreview from "./Page2/ShowPreview"

const StyledImg = styled.img`
  width: 400px;
  height: auto;
  max-width: 100%;
`
const StyledText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

const DashboardContent = ({
  imgSrc,
  imgFooterText,
  title,
  paragraph1,
  paragraph2,
  footerLink,
  showBtn
}) => {
  const ImgGroup = () => (
    <Flex flexDirection="column">
      <StyledImg src={imgSrc} />
      {imgFooterText && (
        <>
          <Paragraph color="secondary.0" mt={2}>
            {imgFooterText}{" "}
            <u>
              <a href="mailto:support@workl.co">support@workl.co.</a>
            </u>
          </Paragraph>
        </>
      )}
    </Flex>
  )
  const [showPreview, setShowPreview] = useState(false)
  return (
    <ContentBlock bg="primary.2" flexDirection="column" width={1 / 1} p={6}>
      <Flex flexDirection={["column-reverse", "row", "row"]}>
        <ImgGroup />
        <Flex flexDirection="column" width={["100%", "95%", "90%"]}>
          <Text fontSize={6} color="secondary.0" fontWeight="700" mb={4}>
            {title}
          </Text>
          <Paragraph color="secondary.0" fontSize={3} mb={4} ml={[0, 2, 4]}>
            {paragraph1}
          </Paragraph>
          <Paragraph color="secondary.0" fontSize={3} mb={4} ml={[0, 2, 4]}>
            {paragraph2}
          </Paragraph>
          {/* <Text textAlign="right" >
            <u>{footerLink}</u>
          </Text> */}
          <StyledText
            color="secondary.0"
            width="300px"
            onClick={() => setShowPreview(true)}
            mb={4}
            ml={[0, 2, 4]}
          >
            <u>{footerLink}</u>
          </StyledText>
          {showPreview && (
            <ShowPreview
              setShowPreview={setShowPreview}
              link="https://d19vbgnwz7jfjm.cloudfront.net/comparison_SAD.png"
            />
          )}
        </Flex>
      </Flex>
      {showBtn ? (
        <Flex justifyContent="center" mt={3}>
          <Button bg="primary.0" color="light.0" mr={3}>
            GET STARTED
          </Button>
          <Button bg="primary.0" color="light.0">
            REQUEST A DEMO
          </Button>
        </Flex>
      ) : (
        ""
      )}
    </ContentBlock>
  )
}

export default DashboardContent
