/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { Card, Text } from "@engaging-tech/components"
import styled from "styled-components"
import ParticipantsIntro from "./ParticipantsIntro"
import AddEmailContainer from "../../../containers/Page2/Participants/AddEmail"
import EmailListContainer from "../../../containers/Page2/Participants/EmailList"
import { findIfDisabled } from "../../../lib/functions"

export const CustomCard = styled(Card)`
  &:hover {
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "default")};
  }
`

const localMode = "uniqueSubmission"
const participantsIntroText = [
  "Send participants the survey link directly to their inbox by entering their email addresses in the text box below, or uploading a CSV file - we do the rest!",
  "If you don’t wish to send the survey via email, please toggle this option off.",
  "Please note: Email addresses within a CSV are not validated. Please check before uploading."
]

// TODO: Needs to handle UX on duplicate emails
const EmailInvite = ({
  mode,
  setMode,
  pageErrors,
  handleErrors,
  emailLength,
  maxAmount
}) => {
  const isDisabled = findIfDisabled(mode, localMode)

  useEffect(() => {
    if (!isDisabled && emailLength < 5) {
      handleErrors("emails", "Must contain at least 5 emails")
    } else if (!isDisabled && emailLength > maxAmount) {
      handleErrors(
        "emails",
        `Cannot have more emails than the selected licence allows: ${maxAmount}`
      )
    } else {
      handleErrors("emails")
    }
  }, [isDisabled, emailLength])

  return (
    <CustomCard
      py={4}
      px={[2, 3]}
      mb={4}
      bg={isDisabled ? "dark.4" : "light.0"}
      isDisabled={isDisabled}
    >
      <ParticipantsIntro
        setMode={setMode}
        stateMode={mode}
        mode={localMode}
        title="Invite participants via email"
        text={participantsIntroText}
      />
      <Text mb={2}>{`${emailLength} / ${maxAmount}`}</Text>
      <AddEmailContainer isDisabled={isDisabled} handleErrors={handleErrors} />
      {!isDisabled && (
        <EmailListContainer isDisabled={isDisabled} pageErrors={pageErrors} />
      )}
    </CustomCard>
  )
}

export default EmailInvite
