import { Flex, Paragraph, Text } from "@engaging-tech/components"
import React from "react"

import BarIndicator from "../../components/BarIndicator/BarIndicator"
import ReportCard from "../../components/ReportCard"
import { ScoreLabel } from "../../components/ScoreLabel"
import NetPromoterScoreLogo from "../../reportLogos/NetPromoterScoreLogo"
import AverageScore from "./AverageScore"
import ScoreRepartition from "./ScoreRepartition"
import ThreeWayBreakdown from "./ThreeWayBreakdown"

const barStructure = [
  { color: "#EF464A", percentage: 50, labelStart: "-100", labelEnd: "0" },
  { color: "#FFB547", percentage: 15, labelEnd: "30" },
  { color: "#0DC187", percentage: 35, labelEnd: "100" }
]

const NetPromoterScore = ({
  exportingPDF,
  data,
  scoreRepartitionGraphData
}) => {
  return data && scoreRepartitionGraphData ? (
    <ReportCard
      heading="Net Promoter Score (NPS)"
      width="100%"
      height={400}
      exportingPDF={exportingPDF}
      HeadingImageComponent={NetPromoterScoreLogo}
      metaProps={{ mb: 2 }}
      innerProps={{ justifyContent: "space-between" }}
      tooltip={{
        header: "Net Promoter Score (NPS)",
        text: "A positive NPS score means employees would recommend an organisation to their network, friends and family. A negative score suggests they wouldn't do this and aren't satisfied with working there."
      }}
    >
      <Paragraph color="dark.2" fontSize={3}>
        The Net Promoter Score (NPS) is made up of one question, “I would
        recommend my friends and family to work for my organisation” and is
        calculated as the difference between the percentage of Promoters and
        Detractors.
      </Paragraph>

      <Text color="dark.2" fontSize="18px" mb={3}>
        Average Score for NPS
      </Text>
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        height="auto"
        width="100%"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          mb={[3, 3, 0]}
          width={["100%", "100%", "30%"]}
        >
          <BarIndicator
            dataSet={{ score: data.scores.index }}
            barStructure={barStructure}
            disableAnimation={exportingPDF}
            minValue={-100}
            maxValue={100}
            hideArrowLabels
          />
          <ScoreLabel
            showIcon
            score={data.scores.index}
            breakpoints={[60, 30, 0, -30]}
            mt={2}
          />
        </Flex>
        <ThreeWayBreakdown
          width={["100%", "100%", "15%"]}
          mb={[3]}
          promotersPercentage={data?.scores?.promoters}
          passivesPercentage={data?.scores?.passives}
          detractorsPercentage={data?.scores?.detractors}
        />
        <AverageScore
          width={["100%", "100%", "15%"]}
          height={120}
          mb={[3]}
          score={data?.average.mean}
          justifyContent="center"
        />
        <ScoreRepartition
          width={["100%", "100%", "35%"]}
          graphData={scoreRepartitionGraphData}
          exportingPDF={exportingPDF}
        />
      </Flex>
    </ReportCard>
  ) : null
}

export default NetPromoterScore
