import React from "react"
import styled from "styled-components"
import { Paragraph, Text, BarChart } from "@engaging-tech/components"
import ReportCard from "../../components/ReportCard"

const ItalicText = styled(Text)`
  font-style: italic;
`

const SixStepsComparison = ({ graphDataset, exportingPDF }) => {
  return (
    <ReportCard
      heading="Category Comparison for the Six Steps to Workplace Engagement"
      width={1 / 1}
      exportingPDF={exportingPDF}
    >
      <Paragraph color="dark.2" fontSize={3}>
        The Six Steps are the six categories you need address and work on to
        engage your staff and improve workplace engagement. The Six Steps to
        workplace engagement are improving reward and recognition, information
        sharing, empowerment, wellbeing, instilling pride and job satisfaction.
        Compare your company’s scores with the global average and your industry
        average scores.
      </Paragraph>
      <ItalicText mb={3} fontSize={3} color="dark.2">
        To protect the anonymity of employees the data will appear as 0 if less
        than 5 people match this data selection.
      </ItalicText>

      <BarChart
        disableAllAnimation={exportingPDF}
        showDataLabels
        dataset={graphDataset}
        graphOptions={{
          scales: {
            y: {
              min: 0,
              max: 100
            }
          }
        }}
        mt={3}
        height={[250, 400, 550]}
        unit="%"
        yAxisLabel="Your Average Engagement Scores"
        colorScale={[
          "rgba(169, 240, 213, 1)",
          "rgba(191, 169, 240, 1)",
          "rgba(169, 199, 240, 1)",
          "rgba(243, 161, 202, 1)"
        ]}
      />
    </ReportCard>
  )
}

export default SixStepsComparison
