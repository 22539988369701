import React, { Fragment, useState } from "react"
import {
  Card,
  IconButton,
  CustomHooks,
  Flex,
  Text,
  Button
} from "@engaging-tech/components"
import styled from "styled-components"

const HoverTipCard = styled(Card)`
  right: 50px;
  @media (min-width: 1350px) {
    left: 50px;
  }
`

const MultiHovertip = ({ hovertip }) => {
  return (
    <Flex
      position="absolute"
      top="-6px"
      left={window.innerWidth > 1350 ? "30px" : "-163px"}
    >
      <Card
        mx="11px"
        borderRadius={10}
        width="max-content"
        bg="light.0"
        py={1}
        px={2}
        justifyContent="center"
      >
        {hovertip?.labels?.map(label => (
          <Fragment key={label.name}>
            {label?.isVisible && (
              <Button
                borderRadius={99}
                width="-webkit-fill-available"
                bg={label.bg || "light.0"}
                my={1}
                mx={2}
                justifyContent="center"
                onClick={label.action}
                fontSize={1}
                color={label.color || "dark.0"}
                fontWeight={400}
                height={20}
              >
                {label.name}
              </Button>
            )}
          </Fragment>
        ))}
      </Card>
    </Flex>
  )
}

const SingleHovertip = ({ hovertip }) => (
  <HoverTipCard
    position="absolute"
    borderRadius={99}
    width="max-content"
    bg={hovertip?.bg || "light.0"}
    py={1}
    px={2}
    justifyContent="center"
  >
    <Text
      fontSize={3}
      onClick={hovertip?.hoverAction}
      color={hovertip?.color || "dark.0"}
    >
      {hovertip?.name}
    </Text>
  </HoverTipCard>
)

const MenuButton = ({ icon, hovertip, action }) => {
  const [hover, setHover] = useState(false)
  const device = CustomHooks.useClientDevice()
  const singleTooltip =
    device !== "MOBILE" && hover && hovertip?.type === "single"
  const multiTooltip =
    device !== "MOBILE" && hover && hovertip?.type === "multi"

  return (
    <Flex
      position="relative"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      zIndex={999}
    >
      <IconButton icon={icon} size={30} hoverColor="#E4E4E4" onClick={action} />
      {singleTooltip && <SingleHovertip hovertip={hovertip} />}
      {multiTooltip && <MultiHovertip hovertip={hovertip} />}
    </Flex>
  )
}

const PopUpMenu = ({ menu }) => {
  const renderMenu = menu?.map(({ icon, hovertip, action }) => (
    <MenuButton icon={icon} hovertip={hovertip} key={icon} action={action} />
  ))

  return (
    <Card
      position="absolute"
      top="0px"
      right="-48px"
      width="auto"
      padding="6px"
      borderRadius={10}
      bg="light.0"
    >
      {renderMenu}
    </Card>
  )
}

export default PopUpMenu
