import {
  Button,
  CustomHooks,
  Flex,
  H2,
  H3,
  Text
} from "@engaging-tech/components"
import { AnimatePresence } from "framer-motion"
import React from "react"

import ActiveFilters from "../../Filters/containers/ActiveFilters"
import FiltersButton from "../../Filters/containers/FiltersButton"
import ConfigureLayout from "../../components/ConfigureLayout"
import CancelPDFModal from "../../containers/CancelPDFModal"
import DownloadPDF from "../../containers/DownloadPDF"
import DownloadShortText from "../../containers/DownloadShortText"

const ReportHeaderElements = {
  textResponses: DownloadShortText,
  filters: FiltersButton,
  exportButton: DownloadPDF,
  morrisonsExportButton: DownloadPDF
}

const ReportHeader = ({
  title,
  subtitle = "Explore the data and results from your survey",
  isErrorView,
  layout,
  isPdfDownloading,
  reportComponents,
  subSection,
  isAdmin
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const device = CustomHooks.useClientDevice()
  return (
    <>
      <AnimatePresence>
        {isPdfDownloading && !isSafari && <CancelPDFModal />}
      </AnimatePresence>

      <Flex flexDirection="column" width={1 / 1}>
        <Flex
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          alignItems={["center", "flex-start"]}
          width={1 / 1}
          flexWrap="no-wrap"
          mb={3}
        >
          <Flex flexDirection="column" width={["100%", "80%"]}>
            {title && (
              <H2 fontWeight={700} width={1 / 1} fontSize={[5, 7]}>
                {title}
              </H2>
            )}
            {subSection && (
              <H3 fontWeight={700} width={1 / 1} mt={2} fontSize={[5, 6]}>
                {subSection}
              </H3>
            )}
            {subtitle && (
              <Text my={3} color="dark.2" width={1 / 1} fontSize={[3, 4]}>
                {subtitle}
              </Text>
            )}
          </Flex>
          <Flex flexDirection="column" alignItems={["flex-start", "flex-end"]}>
            <Flex
              width={1}
              justifyContent={["flex-start", "flex-end"]}
              flexWrap="wrap"
              flexDirection="row"
            >
              {!!layout &&
                layout.length !== 0 &&
                layout.map(element => {
                  const Component = ReportHeaderElements[element.name]
                  if (!Component) return null
                  return (
                    <Component
                      key={element.name}
                      isErrorView={isErrorView}
                      title={title}
                      reportComponents={reportComponents}
                      subSection={subSection}
                    />
                  )
                })}
              {!!isAdmin && <ConfigureLayout />}
            </Flex>
            {isSafari ||
              (device !== "DESKTOP" && (
                <Text
                  mt={2}
                  color="dark.2"
                  fontSize={3}
                  style={{ textAlign: device === "DESKTOP" ? "right" : "left" }}
                  maxWidth="80%"
                  mx={2}
                >
                  To directly export as PDF, access this report with a Chrome
                  browser on desktop
                </Text>
              ))}
          </Flex>
        </Flex>
        <ActiveFilters />
      </Flex>
    </>
  )
}
export default ReportHeader
