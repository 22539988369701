import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { loadAllCountries, loadAllIndustries } from "../../ui/store/ui.actions"
import SignUpForm from "../forms/SignUp"

const SignUp = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllCountries())
    dispatch(loadAllIndustries())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SignUpForm />
}

export default SignUp
