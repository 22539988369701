import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

const pipePassedInProp = (state, prop) => prop

const getAllBreakdowns = createSelector(
  ReportSelectors.getReportData,
  data => data && data.sixStepsBreakdown
)
export const getBreakdownByStep = createSelector(
  [getAllBreakdowns, pipePassedInProp],
  (breakdowns, step) => breakdowns?.find(breakdown => breakdown.step === step)
)

export const getBreakdownTitle = createSelector(
  getBreakdownByStep,
  breakdown => breakdown && breakdown.title
)
export const getBreakdownDescription = createSelector(
  getBreakdownByStep,
  breakdown => breakdown && breakdown.description
)
export const getBreakdownCategories = createSelector(
  getBreakdownByStep,
  breakdown => {
    if (breakdown) {
      const filteredCategories = breakdown.categories.filter(
        cat => cat.values?.length > 0
      )
      return filteredCategories.map(cat => ({
        ...cat,
        graphDataset: {
          labels: cat.values.map(v => v.name),
          datasets: [
            {
              data: cat.values.map(v => v.value)
            }
          ]
        }
      }))
    }

    return []
  }
)
