import React from "react"
import { Flex, Text, Button } from "@engaging-tech/components"

const ErrorState = ({ message, onClick, buttonText = "Close" }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={5}
    >
      <Text
        mb={4}
        width="auto"
        fontWeight={700}
        fontSize={30}
        style={{ textAlign: "center" }}
      >
        Something went wrong!
      </Text>
      <Text
        mb={6}
        width="auto"
        fontWeight={700}
        fontSize={20}
        style={{ textAlign: "center" }}
      >
        {message}
      </Text>

      <Button width="auto" variant="secondary.0" px={3} onClick={onClick}>
        {buttonText}
      </Button>
    </Flex>
  )
}

export default ErrorState
