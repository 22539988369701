import { Icon, MotionFlex } from "@engaging-tech/components"

import React, { useState } from "react"

const OptionButton = ({
  showOptions,
  onClick,
  iconName,
  iconSize = 24,
  ...props
}) => {
  const [hovered, setHovered] = useState(false)
  return (
    <MotionFlex
      width={32}
      height={32}
      ml={2}
      initial={{ scale: 0, opacity: 0 }}
      animate={
        showOptions ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }
      }
      onTap={onClick}
      style={{ cursor: "pointer" }}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      className="template-option-button"
      {...props}
    >
      <MotionFlex
        position="relative"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        className="template-option-button"
        whileHover={{ opacity: 1, scale: 1.1 }}
      >
        <MotionFlex
          width="100%"
          position="absolute"
          height="100%"
          borderRadius="99px"
          className="template-option-button"
          initial={{ scale: 0.2, opacity: 0 }}
          animate={
            hovered ? { opacity: 1, scale: 1 } : { scale: 0.2, opacity: 0 }
          }
          style={{ border: "2px solid #ffffff" }}
        />

        <Icon
          name={iconName}
          color="light.0"
          size={iconSize}
          className="template-option-button"
        />
      </MotionFlex>
    </MotionFlex>
  )
}

export default OptionButton
