import { getConfig } from "@engaging-tech/ssr-config"

export const destroyFreshchatWidget = () => {
  if (typeof window === "undefined") return

  setTimeout(() => {
    if (window.fcWidget.isLoaded()) window.fcWidget.destroy()
  }, 100)
}

export const showExistingWidget = () => {
  if (typeof window !== "undefined") {
    if (window.fcWidget.isLoaded()) {
      return
    }
  }
}

export const showFreshchatWidget = (
  widgetUuid,
  tags = [],
  userId,
  firstName,
  lastName,
  email
) => {
  destroyFreshchatWidget()
  if (typeof window === "undefined") return
  const config = getConfig()
  let widgetConfig = {
    token: config.fsKey,
    host: config.fsDomain
  }
  if (userId || firstName || lastName || widgetUuid) {
    widgetConfig = {
      ...widgetConfig,
      externalId: userId,
      firstName: firstName || "Guest",
      lastName,
      email,
      widgetUuid,
      tags
    }
  }

  setTimeout(() => {
    window.fcWidget.init(widgetConfig)
  }, 1000)
}
