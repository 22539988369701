import { Flex } from "@engaging-tech/components"
import React from "react"

import IntroStepsIlustrationsItem from "./IntroStepsIlustrationsItem"

const introStepsIlustrationsData = [
  {
    description:
      "Select if you are a small, medium, big or very big organisation based on your number of employees. ",
    image: "img/step_survey_1.png",
    title: "Select your organisation size"
  },
  {
    description:
      "Pay on credit card or generate an invoice to purchase your package and be able to send your survey out. ",
    image: "img/step_survey_2.png",
    title: "Set up your Survey"
  },
  {
    description: "Select when you’d like your survey to start end and how many employees you have. ",
    image: "img/step_survey_3.png",
    title: "Select your data package"
  },
  {
    description:
      "Once your survey results have been collected and the entry period closes, our panel of experts will evaluate your results and determine the final lists, based on organisation size.",
    image: "img/step_survey_4.png",
    title: "You’re entered!"
  }
]

const IntroStepsIllustrations = ({ currentStep }) => {
  if (currentStep < 4) {
    return (
      <Flex width={1 / 1} mt={5} justifyContent="space-between">
        {introStepsIlustrationsData.map((item, index) => (
          <IntroStepsIlustrationsItem
            key={`IntroStepsIlustrationsItem-${item.title}`}
            active={currentStep === index + 1}
            description={item.description}
            imagePath={item.image}
            stepNumber={index + 1}
            title={item.title}
          />
        ))}
      </Flex>
    )
  }

  return null
}

export default IntroStepsIllustrations
