import React from "react"
import styled from "styled-components"
import { H1, Flex, Box, Text } from "@engaging-tech/components"

const Dialog = styled(Flex)`
  width: 90%;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.space[7]}px;
  margin-top: ${({ theme }) => theme.space[6]}px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-top: ${({ theme }) => theme.space[6]}px;
    margin-bottom: ${({ theme }) => theme.space[6]}px;
    width: 50%;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    margin-top: ${({ theme }) => theme.space[5]}px;
    margin-bottom: ${({ theme }) => theme.space[5]}px;
    max-width: 560px;
  }
`

/**
 * Primative dialog for account-related UI. This is used with all of the account
 * forms. (sign in, sign up, etc.)
 * @param {React.Component} renderLogo A brand logo to render at the top of the dialog.
 * @param {string} title The title text for the dialog.
 * @param {string} subtitle Optional subtitle text for the dialog.
 * @param {React.Component} children Child components to be rendered as the body of the dialog.
 * @package
 */
const AccountDialog = ({ renderLogo: Logo, title, subtitle, children }) => (
  <Dialog bg="light.0" borderRadius={4} elevation={12}>
    <Flex flexDirection="column" alignItems="center" width="100%">
      {Logo && (
        <Flex width="auto" mt="48px" mb={2}>
          <Logo />
        </Flex>
      )}
      <Box px={[4, 5]} py={3}>
        <H1 fontSize={5} color="dark.2" fontWeight="700">
          {title}
        </H1>
        {subtitle && (
          <Box pt={1} pb={3}>
            <Text color="dark.2" fontSize={[4, 3]}>
              {subtitle}
            </Text>
          </Box>
        )}
        {children}
      </Box>
    </Flex>
  </Dialog>
)

export default AccountDialog
