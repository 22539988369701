import { TextField as TextAreaComponent } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const objectToCssString = style =>
  Object.entries(style)
    .map(([k, v]) => `${k}:${v}`)
    .join(";")

const StyledTextAreaComponent = styled(TextAreaComponent)`
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.colors.error[0]}`
      : "1px solid rgba(99,152,195,1)"};
  border-radius: 10px;
  padding: 15px;
  font-family: Centra;
  font-size: 14px;
  background-color: #f4f4f4;
  color: ${({ theme }) => theme.colors.dark[0]};
  &:focus {
    border: ${({ error, theme, preview }) => {
      if (preview) {
        return "2px solid rgba(99,152,195,1)"
      }
      return error
        ? `2px solid ${theme.colors.error[0]}`
        : "2px solid rgba(99,152,195,1)"
    }};
    padding: 14px;
    outline: 0;
    background-color: #eaeaea;
  }
  &:hover {
    background-color: #eaeaea;
  }

  &:disabled {
    cursor: ${({ preview }) => {
      if (preview) {
        return "default"
      }
      return "inherit"
    }};
    opacity: ${({ preview }) => {
      if (preview) {
        return 1
      }
      return "inherit"
    }};

    background-color: ${({ preview }) => {
      if (preview) {
        return "#f5f5f5"
      }
      return "inherit"
    }};
  }

  ${({ placeholderStyles }) =>
    placeholderStyles &&
    `
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        ${objectToCssString(placeholderStyles)}
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        ${objectToCssString(placeholderStyles)}
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        ${objectToCssString(placeholderStyles)}
      }
      :-moz-placeholder {
        /* Firefox 18- */
        ${objectToCssString(placeholderStyles)}
      }
  `}
`

const TextArea = ({ type = "text", ...props }) => (
  <StyledTextAreaComponent
    type={type}
    width={1 / 1}
    as="textarea"
    borderRadius={10}
    {...props}
  />
)

export default TextArea
