import { connect } from "react-redux"

import { setSuggestedActions } from "../../../../store/actionPlans/actionPlans.actions"
import SuggestedActions from "../../components/EditActionPlan/SuggestedActions"

const mapState = state => ({})

const mapDispatch = dispatch => ({
  setSuggestedActions: (actions, stepQuestionId) =>
    dispatch(setSuggestedActions(actions, stepQuestionId))
})

const SuggestedActionsContainer = connect(
  mapState,
  mapDispatch
)(SuggestedActions)

export default SuggestedActionsContainer
