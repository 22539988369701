import {
  Box,
  CustomHooks,
  ImageCropModal,
  Text,
  Utils,
  userInterfaceNotificationsStore
} from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import axios from "axios"
import { AnimatePresence } from "framer-motion"
import { getOrientation } from "get-orientation/browser"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { Card, FilePickerButton } from "."
import { getIdToken } from "../../account/services/account.service"

const UPLOAD_API_URL = () => `${getConfig().api.url}${getConfig().api.endpoints.upload}`

const StyledImg = styled.img`
  width: -webkit-fill-available;
  height: 100%;
  object-fit: contain;
`

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
}

const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default function LogoPicker({ values, errors, setFieldValue, readOnly }) {
  const theme = CustomHooks.useStyledTheme()
  const [imageToCrop, setImageToCrop] = useState(null)
  const [localImageURL, setLocalImageUrl] = useState(null)

  const dispatch = useDispatch()

  const handleImageChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await Utils.canvasHelpers.getRotatedImage(imageDataUrl, rotation)
      }

      setImageToCrop(imageDataUrl, file.name)
      if (typeof window !== "undefined" && window.hj) {
        window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey logo"])
      }
    }
  }

  const upload = async (file, url = UPLOAD_API_URL()) => {
    const tokenString = await getIdToken()

    return axios({
      method: "post",
      url,
      data: file,
      headers: { Authorization: `JWT ${tokenString}` }
    })
  }

  const uploadLogo = async ({ logo }) => {
    try {
      const formData = new FormData()
      formData.append("file", logo, `${values.id}_${new Date().getTime()}.png`)
      const result = await upload(formData, `${UPLOAD_API_URL()}surveyLogo`)
      setFieldValue("logo", result.data.result)
    } catch (error) {
      dispatch(userInterfaceNotificationsStore.actions.addNotification({ message: "Error uploading logo!" }))
    }
  }

  return (
    <>
      <Box width={[1 / 1, 1 / 2]}>
        <Text>Brand Logo</Text>
        <Card
          border={(errors && errors.logo && `1px solid ${theme.colors.error[0]}`) || 0}
          mt={3}
          p={3}
          justifyContent="center"
          height="146px"
        >
          {values && (
            <StyledImg
              src={(typeof values.logo === "string" && values.logo) || localImageURL}
              alt="Your brand logo"
            />
          )}
        </Card>
        {!readOnly && (
          <>
            <Text color={errors && errors.logo && "errors.0"} fontSize={2} ml={10}>
              {(errors && errors.logo && errors.logo) ||
                "*WorkL tip: Please ensure your image is in png, jpeg or jpg format."}
            </Text>
            <FilePickerButton
              error={errors && errors.logo}
              mt={4}
              id="logo"
              name="logo"
              accept="image/png, image/jpeg, image/jpg"
              onChange={e => handleImageChange(e)}
              text="Change Logo"
            />
          </>
        )}
      </Box>
      <AnimatePresence>
        {imageToCrop && (
          <ImageCropModal
            cancelCrop={() => setImageToCrop(null)}
            imageToCrop={imageToCrop}
            aspectRatio={3 / 1}
            saveCroppedImage={imageToSave => {
              if (imageToSave) {
                uploadLogo({ logo: imageToSave })

                const reader = new FileReader()
                reader.onloadend = () => setLocalImageUrl(reader.result)
                reader.readAsDataURL(imageToSave)
                setImageToCrop(null)
              } else {
                setLocalImageUrl(null)
              }
            }}
            imageName={`company-logo-${new Date().getTime()}`}
          />
        )}
      </AnimatePresence>
    </>
  )
}
