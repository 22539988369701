import { Box, Flex, Text } from "@engaging-tech/components"
import React, { useState } from "react"

import { getLabelData } from "../../components/ScoreLabel"
import { HighLowAreasModal } from "./HighLowAreasModal"

const StepQuestionResultBlock = ({
  score,
  loading,
  nodata,
  questionGroupStep,
  questionId,
  reportId,
  firstGroupAndQuestion,
  labelToRender,
  renderLabel,
  showCurrentComp
}) => {
  const [openDetailModal, setOpenDetailModal] = useState(false)

  function toggleModal() {
    setOpenDetailModal(!openDetailModal)
  }

  const scoreInfo = getLabelData(score)
  const scoreInfoIcon = getLabelData(score).icon
  const renderHighLowAreasModal =
    ["error", "check_circle"].includes(scoreInfoIcon) &&
    score !== null &&
    labelToRender === "Your Score" &&
    showCurrentComp

  return (
    <>
      <Flex
        borderRadius={5}
        flexDirection="column"
        width="100%"
        height="65px"
        bg={!loading && nodata ? "dark.4" : scoreInfo.color}
        my={2}
        onClick={renderHighLowAreasModal ? toggleModal : () => null}
        style={{ cursor: renderHighLowAreasModal ? "pointer" : "auto" }}
      >
        {!loading && !nodata && (
          <Text pt={3} fontWeight="500" textAlign="center" fontSize={6}>
            {score}%
          </Text>
        )}
        {loading && (
          <Text pt={3} fontWeight="500" textAlign="center" fontSize={6}>
            ...
          </Text>
        )}
        {!loading && nodata && (
          <Text pt={3} fontWeight="500" textAlign="center" fontSize={6}>
            NA
          </Text>
        )}
      </Flex>
      {renderLabel ? (
        <Box height={20} width="initial" mb={20}>
          {firstGroupAndQuestion ? (
            <Text fontWeight="700" color="dark.1">
              {labelToRender}
            </Text>
          ) : null}
        </Box>
      ) : null}
      {renderHighLowAreasModal && openDetailModal ? (
        <HighLowAreasModal
          closeModal={toggleModal}
          highScore={scoreInfoIcon === "check_circle"}
          questionId={questionId}
          questionGroupStep={questionGroupStep}
          reportId={reportId}
        />
      ) : null}
    </>
  )
}

export default StepQuestionResultBlock
