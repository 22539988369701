import { userInterfaceNotificationsStore } from "@engaging-tech/components"
import { connect } from "react-redux"
import { UISelectors } from "../../../ui"
import { surveyInfoActions } from "../../surveyInfo"
import BillingFormComponent from "../forms/BillingForm"
import {
  createIntent,
  purchaseCreditsFailure,
  purchaseCreditsSuccess,
  reset
} from "../store/surveyInfoReview.actions"
import {
  getIntentSecret,
  getIsLoading,
  getIsPaying
} from "../store/surveyInfoReview.selectors"

const mapState = state => ({
  intentSecret: getIntentSecret(state),
  isPaying: getIsPaying(state),
  isLoading: getIsLoading(state),
  previousRoute: UISelectors.getPreviousRoute(state)
})

const mapDispatch = dispatch => ({
  onSubmit: payload => dispatch(createIntent(payload)),
  paymentActionSuccess: () => {
    dispatch(purchaseCreditsSuccess())
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Purchase Successful"
      })
    )
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Purchased credits"])
    }
  },
  paymentActionFail: error => {
    dispatch(purchaseCreditsFailure())
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: `Failed to purchase credits - ${error.message}`
      })
    )
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", [
        "[WFB][App][Surveys] Failed to purchase credits"
      ])
    }
  },
  onClose: () => {
    dispatch(surveyInfoActions.closeSurvey())
    dispatch(reset())
  }
})

const BillingForm = connect(mapState, mapDispatch)(BillingFormComponent)

export default BillingForm
