/* eslint-disable react/jsx-curly-newline */
import { Button, Flex, Paragraph, Text } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import DOMPurify from "dompurify"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const StyledFlag = styled.img`
  height: 40px;
  @media (min-width: 768px) {
    height: 30px;
  }
  @media (min-width: 1120px) {
    display: block;
    height: 40px;
  }
`

const StyledImg = styled.img`
  height: 60px;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 20px;
  align-self: center;
`

const AwardBox = ({ title, type, secondaryLogo, flag, text, padding }) => {
  const history = useHistory()
  const paths = usePaths()

  const handleOnClick = () => {
    if (type === "happiest-workplaces-awards") {
      return (window.location.href = `${
        getConfig().publicSite
      }/happiest-workplaces-logos`)
    }
    history.push(
      `${paths.awards.enterAwards.index.replace(":awardType", type)}`
    )
  }

  return (
    <Flex
      flexDirection="column"
      bg="secondary.3"
      borderRadius={10}
      width={1 / 1}
      mb={2}
    >
      <Flex width={1 / 1} justifyContent="space-between">
        <StyledImg src={secondaryLogo} alt={title} />
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          px={padding}
          height={115}
          width={180}
        >
          <StyledFlag src={flag} />
        </Flex>
      </Flex>
      <Flex width={1 / 1} flexDirection="column">
        {text?.map(entry => (
          <Paragraph
            px={3}
            key={entry}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(entry, { ALLOWED_TAGS: ["b"] })
            }}
          />
        ))}
      </Flex>

      <Flex alignItems="center" mt="auto" width={1 / 1} justifyContent="center">
        <Button
          bg="primary.0"
          fontWeight={700}
          color="light.0"
          width={125}
          height={40}
          fontSize={4}
          my={3}
          justifyContent="center"
          onClick={handleOnClick}
        >
          <Text color="light.0">Enter Now</Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default AwardBox
