import { getConfig } from "@engaging-tech/ssr-config"

import { apollo } from "../../../../../lib/Apollo"
import { GET_ALL_SURVEY_JOB_ROLES } from "./allSurveyJobRoles.queries"

const GRAPHQL_API_URL = () =>
  `${getConfig().api.url}${getConfig().api.endpoints.graphql}`

/**
 * Loads all survey job roles
 */
export const loadAllSurveyJobRoles = () =>
  apollo.query({
    uri: GRAPHQL_API_URL(),
    query: GET_ALL_SURVEY_JOB_ROLES
  })
