import React from "react"
import { Card, Flex, H3, Text } from "@engaging-tech/components"
import DOMPurify from "dompurify"
import styled from "styled-components"

const RoundedBox = styled(Flex)`
  border-radius: 5px;
  z-index: 0;
  transform: translate(0px, 30px);
`

const TransformedCard = styled(Card)`
  z-index: 99;
  background-color: white;
`

export const SectionTab = ({ current, total }) => (
  <RoundedBox bg="secondary.0" width="150px" height="60px">
    <Text
      color="light.0"
      alignText="center"
      ml="auto"
      mr="auto"
      width="fit-content"
      fontWeight={600}
      mt={1}
    >
      {`Section ${current} of ${total}`}
    </Text>
  </RoundedBox>
)

const SectionBreak = ({ children, title, text, length, currentPage, mb }) => {
  const purifiedText = DOMPurify.sanitize(text, { ADD_ATTR: ["target"] })

  return (
    <Flex flexDirection="column">
      <SectionTab current={currentPage} total={length} />
      <TransformedCard flexDirection="column" pt={4} mb={mb}>
        <H3
          fontSize={5}
          textAlign="left"
          color="secondary.0"
          mb={2}
          fontWeight={600}
          px={[2, 3]}
        >
          {title}
        </H3>
        <Text
          px={[2, 3]}
          dangerouslySetInnerHTML={{ __html: purifiedText }}
          mt={2}
          color="secondary.0"
        />
        {children}
      </TransformedCard>
    </Flex>
  )
}

export default SectionBreak
