import { call, put, select, takeLatest } from "redux-saga/effects"

import { deleteTypeNames } from "../../../../utils/index"
import { load, save, uploadLogo } from "../services/surveyInfo.service"
import * as Actions from "./surveyInfo.actions"
import * as Selectors from "./surveyInfo.selectors"

const generateSurveyReq = survey => {
  const { template, status, meta, ...req } = survey
  req.templateId = template.id
  req.type = template.type
  const { creditsPaid, frequencySettings, ...restMeta } = meta
  req.meta = restMeta
  switch (req.type) {
    case "employeeExit":
    case "employeeStarter":
      if (!frequencySettings?.endDate) {
        req.meta = {
          ...req.meta,
          frequencySettings: {
            frequency: frequencySettings?.frequency || "never"
          }
        }
      } else {
        req.meta = {
          ...req.meta,
          frequencySettings
        }
      }
      return req
    default:
      return req
  }
}

const generateSurveyRes = survey => {
  const surveyObj = { ...survey }
  if (surveyObj?.meta?.mode?.shareableLinks?.enabled) {
    surveyObj.meta.mode = {
      mode: "shareableLinks",
      amount: survey.meta.mode.shareableLinks.amount,
      recipients: []
    }
  } else if (survey?.meta?.mode?.uniqueSubmission?.enabled) {
    surveyObj.meta.mode = {
      mode: "uniqueSubmission",
      amount: 5,
      recipients: survey.meta.mode.uniqueSubmission.recipients
    }
  }
  surveyObj.meta.startDate = new Date(survey?.meta?.startDate)
  surveyObj.meta.endDate = new Date(survey?.meta?.endDate)
  return surveyObj
}

export function* loadSaga(action) {
  try {
    let res = yield call(load, action.payload)
    res = deleteTypeNames(res)
    res = generateSurveyRes(res.data.getOrganisationSurvey)
    yield put(Actions.loadSurveySuccess(res))
  } catch {
    yield put(Actions.loadSurveyFail())
  }
}

export function* saveSaga(action) {
  try {
    const surveyObj = yield select(Selectors.getSurvey)
    const survey = generateSurveyReq(surveyObj)
    const id = yield select(Selectors.getSurveyId)
    const surveyData = {
      ...survey,
      meta: {
        ...survey.meta,
        brand: {
          colour: action.payload?.brand?.colour || survey.meta.brand.colour,
          logo: action.payload?.logo || survey.meta.brand.logo
        }
      }
    }

    const res = yield call(save, {
      surveyId: id,
      survey: surveyData
    })
    yield put(Actions.saveSurveySuccess(res.data.createOrganisationSurvey.id))
    if (typeof window !== "undefined" && window.hj) {
      window.hj("tagRecording", ["[WFB][App][Surveys] Saved survey"])
    }
    if (action.payload.closeSurvey) {
      yield put(Actions.closeSurvey())
    }
    if (action.payload.onSaveSuccess) {
      action.payload.onSaveSuccess()
    }
  } catch (e) {
    yield put(Actions.saveSurveyFail())
  }
}

export function* saga() {
  yield takeLatest(Actions.types.LOAD_SURVEY, loadSaga)
  yield takeLatest(
    [Actions.types.SAVE_SURVEY, Actions.types.SAVE_SURVEY_META, Actions.types.SAVE_SURVEY_BRAND],
    saveSaga
  )
}
