import { connect } from "react-redux"
import {
  deleteReports,
  setDeleteReport,
  createLatestReport,
  setHasError
} from "../store/report.actions"
import {
  getAllReports,
  getIsLoading,
  getRedirectLink,
  getReportsToDelete,
  getSurveyType
} from "../store/report.selectors"
import EmployeeReportsDashboard from "../views/EmployeeReportsDashboard"

const mapState = state => ({
  filteredReports: getAllReports(state),
  reportsToDelete: getReportsToDelete(state),
  surveyType: getSurveyType(state),
  isLoading: getIsLoading(state),
  url: getRedirectLink(state)
})

const mapDispatch = dispatch => ({
  deleteReports: reportsToDelete => dispatch(deleteReports(reportsToDelete)),
  openLatestReport: surveyType => dispatch(createLatestReport(surveyType)),
  emptyReportsToDelete: () => dispatch(setDeleteReport([])),
  setHasError: error => dispatch(setHasError(error))
})

const EmployeeReportsDashboardContainer = connect(
  mapState,
  mapDispatch
)(EmployeeReportsDashboard)

export default EmployeeReportsDashboardContainer
