const createDatasetArray = selectedQuestion => {
  return selectedQuestion?.reduce((arr, question) => {
    // Some datasets come as array some as object, we pull out correct one here
    const questionObj = question.values ? question.values : question

    Object.keys(questionObj).forEach(key => {
      if (typeof questionObj[key] === "number" && !arr.includes(key))
        arr.push(key)
    })
    return arr
  }, [])
}

export default createDatasetArray
