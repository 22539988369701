import { Spinner } from "@engaging-tech/components"
import React from "react"

import { LOAD_LEADS } from "../../../graphql/queries"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { getLiveLeadsFail, getLiveLeadsSuccess } from "../../../store/leads/leads.actions"
import ErrorState from "../../business-information/components/error-state"
import DisplayProduct from "../../ui/components/DisplayProduct"
import LiveLeads from "../containers/LiveLeadGeneration"

const LeadsHome = () => {
  const { errors, isLoading } = useBusinessAppQuery(LOAD_LEADS, {
    successAction: getLiveLeadsSuccess,
    failAction: getLiveLeadsFail
  })

  if (errors?.networkError?.result.errors.filter(error => error.extensions.code === "ERR403")[0])
    return <DisplayProduct />
  if (errors) return <ErrorState />
  if (isLoading) return <Spinner justifyContent="center" color="primary.0" />

  return <LiveLeads />
}

export default LeadsHome
