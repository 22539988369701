import { Flex, Icon, Text } from "@engaging-tech/components"
import { hideVisually } from "polished"
import React from "react"
import styled from "styled-components"

const FilePickerComponent = styled.input`
  opacity: 0;
  position: absolute;
  /* top: 100px; */
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  :hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`

const HiddenInput = styled.input.attrs({ type: "file" })`
  ${hideVisually()}
`

const FilePickerButton = ({
  error = "",
  id,
  name,
  onChange = () => null,
  accept,
  label = "",
  width = "120px",
  height = "36px",
  disabled = false,
  leadingIcon = "",
  text = "",
  bg = "secondary.0",
  ...props
}) => {
  return (
    <Flex
      as="label"
      htmlFor={id}
      onChange={e => {
        onChange(e)
      }}
      alignItems="center"
      width={width}
    >
      <HiddenInput
        id={id}
        name={name}
        accept={accept}
        error={error}
        disabled={disabled}
        label={label}
      />
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        height={height}
        width={width}
        bg={bg}
        borderRadius="4px"
        {...props}
      >
        {leadingIcon && <Icon name={leadingIcon} mr={3} color="light.0" />}
        <Text
          color="light.0"
          fontWeight={700}
          noSelect
          width="auto"
          height="auto"
          fontSize={14}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  )
}

export default FilePickerButton
