import { Flex } from "@engaging-tech/components"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  clearAllHeatmapFilters,
  setHeatMapTab
} from "../../../store/report.actions"
import { getHeatmapTabSelected } from "../../../store/report.selectors"
import DropdownTabContainer from "../../containers/DropdownTabContainer"
import { Tab } from "./Tab"
import { heatMapTabsValues } from "./heatMapTabsValues"

const renderComponent = (
  { action, key, label },
  handleChangeTab,
  tabSelected
) => {
  switch (action) {
    case "dropdown":
      return (
        <DropdownTabContainer
          key={key}
          isSelected={tabSelected === key}
          keyValue={key}
          onClick={handleChangeTab}
          value={label}
        />
      )
    default:
      return (
        <Tab
          key={key}
          isSelected={tabSelected === key}
          keyValue={key}
          onClick={handleChangeTab}
          value={label}
        />
      )
  }
}

export function HeatMapTabs() {
  const tabSelected = useSelector(getHeatmapTabSelected)
  const dispatch = useDispatch()

  function handleChangeTab(key) {
    dispatch(setHeatMapTab(key))
    dispatch(clearAllHeatmapFilters())
  }

  return (
    <Flex>
      {heatMapTabsValues.map(item =>
        renderComponent(item, handleChangeTab, tabSelected)
      )}
    </Flex>
  )
}
