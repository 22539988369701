import { Flex, Text, MotionFlex, CustomHooks } from "@engaging-tech/components"
import React, { useRef } from "react"
import styled from "styled-components"

const BottomBorderedFlex = styled(Flex)`
  border-bottom: 2px solid #e1e1e1;
  margin-top: 5px;
`

const SentimentScore = ({ word, index, startAnimation, exportingPDF }) => (
  <BottomBorderedFlex width="100%" justifyContent="space-between">
    <MotionFlex
      initial={exportingPDF ? "visible" : "hidden"}
      animate={startAnimation || exportingPDF ? "visible" : "hidden"}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -20 }
      }}
      transition={{
        delay: index * 0.1
      }}
      width="auto"
    >
      <Text fontWeight={700} width="auto" fontSize={4} mb={0} mt={3}>
        {word.text}
      </Text>
    </MotionFlex>

    <MotionFlex
      initial={exportingPDF ? "visible" : "hidden"}
      animate={startAnimation || exportingPDF ? "visible" : "hidden"}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 20 }
      }}
      transition={{
        delay: index * 0.1
      }}
      width="auto"
      style={{ textAlign: "end" }}
    >
      <Text fontWeight={700} width="auto" fontSize={4} mb={0} mt={3}>
        {word.value}
      </Text>
    </MotionFlex>
  </BottomBorderedFlex>
)

const Card = ({ refContainer, exportingPDF, wordCountForQuestion }) => {
  const elementVisible = CustomHooks.useElementVisibleTrigger({
    componentRef: refContainer,
    delay: 0
  })

  return (
    <Flex
      height="100%"
      width="100%"
      color="dark.3"
      bg="dark.4"
      p={3}
      flexDirection="column"
      borderRadius={10}
    >
      <Text color="dark.5" fontWeight="500" fontSize={5}>
        Top 6 Words
      </Text>
      {Array.isArray(wordCountForQuestion?.words) ? (
        wordCountForQuestion.words
          .slice(0, 6)
          .map((word, index) => (
            <SentimentScore
              word={word}
              key={wordCountForQuestion.question + word.text}
              index={index}
              startAnimation={elementVisible}
              exportingPDF={exportingPDF}
            />
          ))
      ) : (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Text width="auto" fontSize={6}>
            Unable to show words
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

const TopSixWords = ({ exportingPDF, wordCountForQuestion }) => {
  const containerRef = useRef()
  return (
    <Flex ref={containerRef} height="100%" width={["100%", 260]}>
      <Card
        refContainer={containerRef}
        exportingPDF={exportingPDF}
        wordCountForQuestion={wordCountForQuestion}
      />
    </Flex>
  )
}

export default TopSixWords
