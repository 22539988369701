/* eslint-disable no-param-reassign */
import { Flex, Icon, Text } from "@engaging-tech/components"
import React, { useRef, useState } from "react"
import styled from "styled-components"

const IconBtn = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

const CustomInput = styled.input`
  width: 75%;
  height: 25px;
  border-radius: 2px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[0]}`};
`

const EditStepHeader = ({
  customStepName,
  bespokeSteps,
  bespokeStepQuestions,
  currentCsn,
  setBespokeSteps,
  addStepToQuestion
}) => {
  const openedStep = useRef(
    bespokeSteps.filter(step => step === customStepName)[0]
  )
  const [openEdit, setOpenEdit] = useState(false)
  const [editText, setEditText] = useState(currentCsn.current)

  const handleClick = () => {
    const newBespokeSteps = bespokeSteps.map(string =>
      string === openedStep.current ? editText : string
    )

    openedStep.current = editText
    currentCsn.current = editText
    bespokeStepQuestions.forEach(({ id }) => {
      addStepToQuestion(editText, id)
    })

    setBespokeSteps(newBespokeSteps)
    setOpenEdit(false)
    setEditText("")
  }

  return (
    <Flex width="350px" height="44px" bg="secondary.1">
      <Flex
        width="15%"
        justifyContent="center"
        alignItems="center"
        height="inherit"
      >
        <Icon name="brush" />
      </Flex>
      <Flex
        width="65%"
        alignItems="center"
        height="inherit"
        color="secondary.0"
      >
        {openEdit ? (
          <CustomInput
            value={editText}
            onChange={e => setEditText(e.target.value)}
          />
        ) : (
          <Text width={1 / 1}>{openedStep.current}</Text>
        )}
      </Flex>
      <Flex
        width="20%"
        justifyContent="center"
        alignItems="center"
        height="inherit"
      >
        {openEdit ? (
          <>
            <IconBtn name="checkmark" mr={1} onClick={() => handleClick()} />
            <Icon name="" />
          </>
        ) : (
          <>
            <IconBtn name="edit" mr={1} onClick={() => setOpenEdit(true)} />
            <IconBtn name="" />
          </>
        )}
      </Flex>
    </Flex>
  )
}

const StepManagementHeader = ({
  openInput,
  customStepName,
  openStepEdit,
  bespokeSteps,
  bespokeStepQuestions,
  currentCsn,
  setBespokeSteps,
  addStepToQuestion
}) => {
  if (openStepEdit)
    return (
      <EditStepHeader
        customStepName={customStepName}
        bespokeSteps={bespokeSteps}
        bespokeStepQuestions={bespokeStepQuestions}
        currentCsn={currentCsn}
        setBespokeSteps={setBespokeSteps}
        addStepToQuestion={addStepToQuestion}
      />
    )

  return (
    <Flex width={1 / 1} alignItems="center" height="54px">
      <Text textAlign="center" fontSize={2} color="secondary.0">
        {openInput
          ? "Give your Bespoke Step a Name"
          : "Select a Bespoke Step to add your Question to"}
      </Text>
    </Flex>
  )
}

export default StepManagementHeader
