import { types } from "./coreQuestions.actions"

export const initialState = {
  isLoading: false,
  hasError: false,
  happinessQuestions: [],
  additionalQuestions: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CORE_QUESTIONS:
      return {
        ...state,
        isLoading: true,
        hasError: false
      }

    case types.LOAD_CORE_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        happinessQuestions: [...action.payload.happinessQuestions],
        additionalQuestions: [...action.payload.additionalQuestions]
      }

    case types.LOAD_CORE_QUESTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
