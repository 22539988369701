import React, { useState } from "react"
import { Flex } from "@engaging-tech/components"
import ClickableIcon from "./ClickableIcon"


const Carousel = ({
  carouselItems,
  numberOfItems,
  componentToShow: ComponentToShow
}) => {
  const [carouselStep, setCarouselStep] = useState(0)
  const leftClickEnabled = carouselStep > 0
  const rightClickEnabled = carouselStep + numberOfItems < carouselItems?.length
  return (
    carouselItems && (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        width={1 / 1}
      >
        <ClickableIcon
          name="keyboard_arrow_left"
          size={40}
          onClick={() => leftClickEnabled && setCarouselStep(carouselStep - 1)}
          onDoubleClick={() => leftClickEnabled && setCarouselStep(0)}
          color={leftClickEnabled ? "dark.0" : "dark.4"}
          alignSelf="center"
          margin="left"
        />
        <Flex
          justifyContent="space-around"
          alignItems="flex-start"
          flexDirection="row"
          width={1 / 1}
        >
          {carouselItems
            ?.slice(carouselStep, carouselStep + numberOfItems)
            .map(item => (
              <ComponentToShow {...item} key={item.id} />
            ))}
        </Flex>
        <ClickableIcon
          name="keyboard_arrow_right"
          size={40}
          // ml={-numberOfItems}
          onClick={() => rightClickEnabled && setCarouselStep(carouselStep + 1)}
          onDoubleClick={() => {
            return leftClickEnabled && setCarouselStep(carouselItems.length - 1)
          }}
          color={rightClickEnabled ? "dark.0" : "dark.4"}
          margin="right"
        />
      </Flex>
    )
  )
}

export default Carousel
