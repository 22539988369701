/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Flex, Table, Text } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import styled from "styled-components"
import NewPostButton from "../../jobs/components/NewPostButton"

const eventsTableHeadings = [
  {
    id: "title",
    title: "Title"
  },
  {
    id: "location",
    title: "Location"
  },
  {
    id: "startDate",
    title: "Start Date"
  }
]

const eventsTableConfig = {
  stylesPerColumn: [
    {
      fontSize: 3,
      fontWeight: 500
    },
    { fontSize: 3 },
    { fontSize: 3 }
  ],
  main: {
    borderRadius: "8px"
  },
  heading: {
    fontSize: [1, 3, 3],
    fontWeight: 500,
    color: "dark.2",
    py: 3
  },
  head: {
    bg: "dark.4"
  },
  data: {
    py: 3,
    borderTop: "1px solid",
    borderColor: "dark.4"
  }
}

const formattedRows = rows =>
  rows &&
  rows.map(row => {
    const { title, location, startDate } = row
    return {
      title,
      location: location.name,
      startDate: new Date(startDate).toUTCString()
    }
  })

const FlexTable = styled(Flex)`
  overflow-x: auto;
`

const MyEvents = ({ events }) => {
  const router = useRouter()
  const paths = usePaths()
  const handleClick = () => router.navigate(paths.home.events.postNewEvent)

  return (
    <>
      <Flex flexDirection={["column", "row"]}>
        {events?.length > 0 ? (
          <Text fontSize={3} pr={[2, 0]} pl={[3, 4]} color="dark.2">
            View your events and post new ones.
          </Text>
        ) : (
          <Text fontSize={3} pr={[2, 0]} pl={[3, 4]} color="dark.2">
            You currently don't have any events. Post an event today! If you
            need any support with posting a job please email{" "}
            <a href="mailto:support@workl.com">
              <u>support@workl.com</u>
            </a>
            .
          </Text>
        )}
        <NewPostButton handleClick={handleClick} title="Post an event" />
      </Flex>
      <Flex justifyContent="center" width="100%" mt={5}>
        <Flex flexDirection="column" width={["auto", "auto", "955px"]}>
          {/* {events?.length ? ( */}
          <FlexTable flexDirection="column" mt={[4, 4, 0]}>
            <Table
              headings={eventsTableHeadings}
              rows={formattedRows(events)}
              config={eventsTableConfig}
            />
          </FlexTable>
          {/* ) : (
             <EmptyJobState />
           )} */}
        </Flex>
      </Flex>
    </>
  )
}

export default MyEvents
