import React from "react"
import styled from "styled-components"
import { Flex, Box } from "@engaging-tech/components"

const ThumbnailWrapper = styled(Flex)`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${({ theme }) => theme.colors.dark[2]};
  flex-shrink: 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    background-size: contain;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[1]}px) {
    background-size: cover;
    max-width: 250px;
  }
`

const PlayButton = styled(Flex)`
  border: 4px solid ${({ theme }) => theme.colors.secondary[0]};

  & > div {
    width: 16px;
    height: 24px;
    border-style: solid;
    border-width: 24px;
    border-color: transparent transparent transparent
      ${({ theme }) => theme.colors.secondary[0]};
    border-width: 12px 0px 12px 16px;
    margin-left: 8px;
  }
`

const Thumbnail = ({ src, hasPlayButton, ...props }) => (
  <ThumbnailWrapper
    src={src}
    {...props}
    minHeight="186px"
    alignItems="center"
    justifyContent="center"
  >
    {hasPlayButton && (
      <PlayButton
        width={48}
        height={48}
        borderRadius={99}
        elevation={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box />
      </PlayButton>
    )}
  </ThumbnailWrapper>
)

export default Thumbnail
