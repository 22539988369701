import React, { useState } from "react"
import { H2, Flex, Text, DatePicker2, Button } from "@engaging-tech/components"
import { startOfDay, endOfDay } from "date-fns"
import { createPortal } from "react-dom"
import { useDispatch } from "react-redux"
import Modal from "@engaging-tech/components/dist/lib/Modal/Modal"
import EmptyDashboard from "../components/EmptyDashboard"
import EmployeeReportListGenerateContainer from "../EmployeeReports/containers/EmployeeReportListGenerateContainer"
import GenerateReportsBarContainer from "../EmployeeReports/containers/GenerateReportsBarContainer"
import GenerateReportsDialogueContainer from "../EmployeeReports/containers/GenerateReportsDialogueContainer"
import DialogueTextFieldContainer from "../EmployeeReports/containers/DialogueTextFieldContainer"
import ErrorState from "../../ui/components/ErrorState"
import { setHasError } from "../store/report.actions"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

const NewSurveyReportCreationView = ({
  onSubmit,
  reports,
  surveyType,
  hasError
}) => {
  const dispatch = useDispatch()

  const dashboardAttributes = {
    employeeStarter: {
      title: "New Starter Survey",
      createLink: "new-starter-surveys"
    },
    employeeExit: {
      title: "Exit Survey",
      createLink: "exit-surveys"
    }
  }

  const [openModal, setOpenModal] = useState(false)
  // set like this just for dev purposes
  const [startDate, setStartDate] = useState(new Date(1514764800000))
  // /01/01/18
  const [endDate, setEndDate] = useState(new Date())
  const [startError, setStartError] = useState(null)
  const [endError, setEndError] = useState(null)
  const [validationError, setValidationError] = useState(null)

  // do validation on both inputs at once whenever one of them is changed
  const handleChange = (date, error, setDate, setError, setDateTime) => {
    setError(null)
    setDate(null)

    if (error) {
      setError(error)
      return
    }

    if (new Date() < date) {
      setError("choose a date from the past")
      return
    }

    setDate(setDateTime(new Date(date)))
  }

  const validateDates = () => {
    setValidationError(null)

    if (!startDate) {
      setValidationError("Start date missing or invalid")
      return
    }

    if (!endDate) {
      setValidationError("End date missing or invalid")
      return
    }

    if (endDate < startDate) {
      setValidationError("End date must be after start date")
    }
    onSubmit({
      surveyType,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      isRoot: true
    })
  }

  return (
    <>
      <Flex flexDirection="column">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pb={3}
        >
          <H2 fontWeight={700} width="auto" fontSize={[5, 6]}>
            Create New Report
          </H2>
        </Flex>
        <Flex
          flexDirection={["column", "row", "row"]}
          flexWrap={["wrap"]}
          pb={3}
          width={1 / 1}
        >
          <Text mb={5} fontWeight="600" width={1 / 1}>
            Please select a date range
          </Text>
          <Flex flexDirection={["column", "row", "row"]} width="auto">
            <Flex width="250px">
              <DatePicker2
                // The navbar has a very high z-index therefore this is needed
                // in case the calendar open upwards
                zIndex={30001}
                label="Start Date"
                error={startError}
                date={startDate}
                onDateChange={(date, error) =>
                  handleChange(
                    date,
                    error,
                    setStartDate,
                    setStartError,
                    startOfDay
                  )
                }
                maxDate={new Date()}
              />
            </Flex>
            <Flex width="250px">
              <DatePicker2
                // The navbar has a very high z-index therefore this is needed
                // in case the calendar open upwards
                zIndex={30001}
                label="End Date"
                error={endError}
                date={endDate}
                onDateChange={(date, error) =>
                  handleChange(date, error, setEndDate, setEndError, endOfDay)
                }
                minDate={startDate}
                maxDate={new Date()}
              />
            </Flex>
          </Flex>

          <Flex width="200px" mt={2}>
            <Button
              bg="secondary.0"
              color="light.0"
              width={1 / 1}
              onClick={() => validateDates()}
            >
              List Reports
            </Button>
            {hasError &&
              createPortal(
                <Modal>
                  <Flex
                    bg="white"
                    alignItems="center"
                    justifyContent="center"
                    width={1 / 1}
                    p={3}
                    overflow="none"
                  >
                    <ErrorState
                      message="No reports are available, try another date range"
                      onClick={() => dispatch(setHasError(false))}
                    />
                  </Flex>
                </Modal>,
                appRoot()
              )}
          </Flex>
          <Flex mb={2} width={1 / 1}>
            {validationError && <Text color="red">{validationError}</Text>}
          </Flex>
        </Flex>
        {reports ? (
          <EmployeeReportListGenerateContainer
            surveyDetails={dashboardAttributes[surveyType]}
            checkBoxLabelText="Select"
            showCheckBox={isRoot => true}
          />
        ) : (
          <EmptyDashboard />
        )}
      </Flex>

      <GenerateReportsBarContainer
        buttonText="generate report"
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      {openModal && (
        <Modal>
          <GenerateReportsDialogueContainer
            openModal={openModal}
            setOpenModal={setOpenModal}
            dialogueText="You are about to generate a new report, please choose a name for it"
            successMessage="A new merged report has been created"
          >
            <DialogueTextFieldContainer />
          </GenerateReportsDialogueContainer>
        </Modal>
      )}
    </>
  )
}

export default NewSurveyReportCreationView
