import { Flex, IconButton } from "@engaging-tech/components"
import React from "react"

const BranchingBackButton = ({ onClick }) => (
  <Flex position="absolute" top="20px" width="auto">
    <IconButton
      style={{ cursor: "pointer" }}
      size={0}
      icon="arrow_back"
      onClick={onClick}
    />
  </Flex>
)

export default BranchingBackButton
