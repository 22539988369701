import { connect } from "react-redux"
import { getReportsToGenerateFrom } from "../../store/report.selectors"
import { setGenerateReport } from "../../store/report.actions"

import ReportsBar from "../components/ReportsBar"

const mapState = state => ({
  isBarOpen: getReportsToGenerateFrom(state).length > 1
})

const mapDispatch = dispatch => ({
  setReportsToBeSelected: reportArray =>
    dispatch(setGenerateReport(reportArray))
})

const GenerateReportsBarContainer = connect(mapState, mapDispatch)(ReportsBar)

export default GenerateReportsBarContainer
