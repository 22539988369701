export const types = {
  GET_SD_LOWEST_PRICE_REQ: "[SDUpgradeModal] Get SD Lowest Price Req",
  GET_SD_LOWEST_PRICE_SUCCESS: "[SDUpgradeModal] Get SD Lowest Price Success",
  GET_SD_LOWEST_PRICE_FAIL: "[SDUpgradeModal] Get SD Lowest Price Fail",
  GET_SD_UPGRADE_PRICE_REQ: "[SDUpgradeModal] Get SD Upgrade Price Req",
  GET_SD_UPGRADE_PRICE_SUCCESS: "[SDUpgradeModal] Get SD Upgrade Price Success",
  GET_SD_UPGRADE_PRICE_FAIL: "[SDUpgradeModal] Get SD Upgrade Price Fail"
}

export const getSDLowestPriceFail = payload => ({
  type: types.GET_SD_LOWEST_PRICE_FAIL,
  payload
})

export const getSDLowestPriceSuccess = payload => ({
  type: types.GET_SD_LOWEST_PRICE_SUCCESS,
  payload
})

export const getSDLowestPriceReq = payload => ({
  type: types.GET_SD_LOWEST_PRICE_REQ,
  payload
})

export const getSDUpgradePriceFail = payload => ({
  type: types.GET_SD_UPGRADE_PRICE_FAIL,
  payload
})

export const getSDUpgradePriceSuccess = payload => ({
  type: types.GET_SD_UPGRADE_PRICE_SUCCESS,
  payload
})

export const getSDUpgradePriceReq = payload => ({
  type: types.GET_SD_UPGRADE_PRICE_REQ,
  payload
})
