import { ReactSelect } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { GET_BASKET } from "../../../graphql/queries"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { getBasketFail, getBasketReq, getBasketSuccess } from "../../../store/basket/basket.actions"
import { awardInBasket } from "../../../store/basket/basket.selectors"
import { UPDATE_ACCOUNT_PREFERENCES } from "../../account/services/account.queries"
import { updateAccountPreferencesSuccess, updateOrganisationFail } from "../../account/store/account.actions"
import ErrorState from "../../business-information/components/error-state"

const ChangeCurrency = ({ currency, acceptedCurrencies }) => {
  const awardFound = useSelector(awardInBasket)

  const { isLoading, errors, post } = useBusinessAppMutation(UPDATE_ACCOUNT_PREFERENCES, {
    successAction: updateAccountPreferencesSuccess,
    failAction: updateOrganisationFail
  })

  const {
    isLoading: loadingBasket,
    errors: basketErrors,
    getData
  } = useBusinessAppQuery(GET_BASKET, {
    initAction: getBasketReq,
    successAction: getBasketSuccess,
    failAction: getBasketFail,
    enabledOnMount: false
  })

  const data = acceptedCurrencies?.map(entry => ({
    value: entry,
    label: entry.toUpperCase()
  }))

  const defaultValue = data?.filter(option => option.value.toUpperCase() === currency.toUpperCase())[0]

  const handleChange = ({ value }) => {
    post({ currency: value }, () => getData())
  }

  if (errors || basketErrors) return <ErrorState />
  return (
    <>
      {/* Does not render the component without default value if no condition */}
      {defaultValue && (
        <ReactSelect
          disabled={!!awardFound}
          options={data}
          currency
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={handleChange}
          isLoading={isLoading || loadingBasket}
        />
      )}
    </>
  )
}

export default ChangeCurrency
