import { connect } from "react-redux"

import PersonalityForm from "../../forms/Personality"
import { saveJobData } from "../../../../store/jobs/jobs.actions"
import { getPostJob } from "../../../../store/jobs/jobs.selector"

const mapState = state => ({
  job: getPostJob(state)
})

const mapDispatch = dispatch => ({
  onSubmit: jobPersonality =>
    dispatch(saveJobData({ personality_type_code: jobPersonality }))
})

const Personality = connect(mapState, mapDispatch)(PersonalityForm)

export default Personality
