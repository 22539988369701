import { createSelector } from "reselect"
import { ReportSelectors } from "../.."

export const getSixStepsQuestions = createSelector(
  ReportSelectors.getReportData,
  data => data && data?.sixStepsQuestions
)

export const getCustomScaleQuestions = createSelector(
  ReportSelectors.getReportData,
  data =>
    data && data?.customQuestions?.filter(question => question.type === "scale")
)
