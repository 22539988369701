/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { Card, H4 } from "@engaging-tech/components"
import Box from "@engaging-tech/components/dist/lib/Box/Box"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import Text from "@engaging-tech/components/dist/lib/Text/Text"
import Link from "@engaging-tech/routing/dist/components/Link"
import { getConfig } from "@engaging-tech/ssr-config"
import format from "date-fns/format"
import React, { useState } from "react"
import styled from "styled-components"

import CopyLinkButton from "./CopyLinkButton"
import LanguageAccordion from "./LanguageAccordion"
import ReportCheckbox from "./ReportCheckbox"

const CardBorderFlex = styled(Card)`
  display: Flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  margin: 6px;
  flex-direction: row;
`

const PointerH4 = styled(H4)`
  &:hover {
    cursor: pointer;
  }
`

const ItalicText = styled(Text)`
  font-style: italic;
`

const CardHeader = ({ id, isRoot, title, surveyDetails, reportId }) => {
  let reportLink = ""

  if (surveyDetails.createLink === "engagement-surveys") {
    reportLink = `/reports/${surveyDetails?.createLink}/${id}`
  } else {
    reportLink = `/reports/${surveyDetails?.createLink}/${reportId}`
  }

  return (
    <Box width={[1 / 1, 1 / 1, 2 / 7]} pl={[0, 0, 3]}>
      <Link to={reportLink}>
        <PointerH4 fontSize={5} color="primary.0" width="fit-content">
          {title}
        </PointerH4>
      </Link>
      <ItalicText fontSize={3}>
        {isRoot === true || isRoot === undefined ? surveyDetails?.title : `Merged ${surveyDetails?.title}`}
      </ItalicText>
    </Box>
  )
}

const ScoreText = ({ result, heading }) => {
  return (
    <Flex width={[1 / 3, 1 / 3, 1 / 8]} mt={[3, 0, 0]} flexDirection="column">
      <Text color="dark.2" width={1 / 1} textAlign={["left", "left", "center"]}>
        {heading}
      </Text>
      <Text color="dark.2" width={1 / 1} textAlign={["left", "left", "center"]} fontSize={6} fontWeight={600}>
        {result ? `${result}%` : "n/a"}
      </Text>
    </Flex>
  )
}

const LanguageText = ({ languages, onClick, clicked }) => {
  return (
    <Flex width={[1 / 3, 1 / 3, 1 / 8]} mt={[3, 0, 0]} flexDirection="column">
      <Text color="dark.2" width={1 / 1} textAlign={["left", "left", "center"]}>
        Languages <span onClick={onClick}>{clicked ? "▲" : "▼"}</span>
      </Text>
      <Text color="dark.2" width={1 / 1} textAlign={["left", "left", "center"]} fontSize={6} fontWeight={600}>
        {languages?.length ? languages.length : "n/a"}
      </Text>
    </Flex>
  )
}

const EmployeeReportItem = ({
  reportData,
  surveyDetails,
  isChecked,
  showCheckBox,
  checkBoxLabelText,
  onChange
}) => {
  const [accordion, setAccordion] = useState(false)

  const { id, createdAt, title, responseRate, reportId, isRoot, data, meta } = reportData
  const languages = data?.languages

  return (
    <>
      <CardBorderFlex height={["auto", "auto", "100px"]}>
        <Flex width={(8 / 10, 8 / 10, 28 / 30)} flexWrap="wrap" alignItems="center">
          <CardHeader
            id={id}
            title={title}
            surveyDetails={surveyDetails}
            reportId={reportId}
            isRoot={isRoot}
          />
          <Text color="dark.1" width={[1 / 1, 1 / 1, 1 / 5]}>
            {createdAt && (
              <>Created at: {createdAt ? `${format(new Date(createdAt), "dd/MM/yyyy")}` : "n/a"}</>
            )}
            {(meta?.startDate || meta?.endDate) && (
              <>
                <>Started At: {meta.startDate ? format(new Date(meta.startDate), "dd/MM/yyyy") : "n/a"}</>
                <br />
                <>
                  Ended at:{" "}
                  {meta.endDate
                    ? format(
                        new Date(meta.endDate).setDate(new Date(meta.endDate).getDate() - 1),
                        "dd/MM/yyyy"
                      )
                    : "n/a"}
                </>
              </>
            )}
          </Text>
          <ScoreText
            result={data?.happinessDataHighlightOverview?.score ?? data?.happinessDataHighlight?.score}
            heading="Overall score"
          />
          <ScoreText result={responseRate} heading="Response rate" />
          <LanguageText
            languages={languages}
            onClick={() => {
              setAccordion(!accordion)
            }}
            clicked={accordion}
          />
          <Flex
            mt={[3, 3, 0]}
            width={[1 / 3, 1 / 3, 1 / 9]}
            justifyContent={["flex-start", "flex-start", "center"]}
          >
            <CopyLinkButton
              width={[1 / 1, 1 / 1, 4 / 5]}
              directLink={
                surveyDetails.createLink === "engagement-surveys"
                  ? `${getConfig().home.url}/reports/${surveyDetails?.createLink}/${id}`
                  : `${getConfig().home.url}/reports/${surveyDetails?.createLink}/${reportId}`
              }
            />
          </Flex>
        </Flex>
        {/* Checkbox (Outside of flex due to mobile styling) */}
        <Flex
          width={[2 / 10, 2 / 10, 2 / 30]}
          justifyContent="center"
          flexDirection="column"
          height="inherit"
        >
          {showCheckBox(isRoot) && (
            <ReportCheckbox
              id={reportId}
              isChecked={isChecked}
              onChange={onChange}
              labelText={checkBoxLabelText}
            />
          )}
        </Flex>
      </CardBorderFlex>
      {accordion && languages?.length && (
        <CardBorderFlex>
          <LanguageAccordion languages={languages} />
        </CardBorderFlex>
      )}
    </>
  )
}

export default EmployeeReportItem
