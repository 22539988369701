/* eslint-disable import/prefer-default-export */
import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query($type: CMSType!) {
    getCMS(type: $type) {
      type
      content
    }
  }
`
