export default {
  reward_recognition: {
    section: "Reward & Recognition",
    explanation:
      "Being fairly paid is a huge concern for everyone, and if you are not paying a fair salary no amount of recognition for a job well done will be enough to make your employees forget they are not being paid enough. Your pay scale has to meet expectations."
  },
  information_sharing: {
    section: "Information Sharing",
    explanation:
      "Everybody in your organisation needs to have a realistic and well-sourced view of where they work. Not sharing information makes employees feel an unimportant part of the business. Engagement and commitment can be eroded by this. The closed-door approach doesn’t just have a negative impact on engagement, it can directly impact on decision-making and therefore profitability and success."
  },
  empowerment: {
    section: "Empowerment",
    explanation:
      "Nobody is perfect but a team can be. The aim of any business must surely be to make their employees feel empowered and this means making them a key part of the decision-making process, listening to their ideas and integrating their suggestions to build and refine into your strategy."
  },
  well_being: {
    section: "Wellbeing",
    explanation:
      "There is a growing body of evidence to support the idea that wellbeing is an essential aspect of employee engagement. It leads to improved production, lower rates of absence and stress, and higher levels of motivation. In other words, employee health and wellbeing has become a hard economic factor."
  },
  instilling_pride: {
    section: "Instilling Pride",
    explanation:
      "Have you ever heard any of your employees respond to ‘where do you work'? Employees who love what they do and feel proud of where they work will speak openly and positively about it to colleagues, potential employees, customers and people in their community. Instilling such pride is not just about stirring speeches, sharing growth figures, or saying a few well-placed thank yous. It centres on having a purpose and helping everyone see that what they do each day is worthwhile."
  },
  job_satisfaction: {
    section: "Job Satisfaction",
    explanation:
      "Job satisfaction is defined as the extent to which an employee feels self-motivated, content & satisfied with his/her job. Job satisfaction happens when an employee feels he or she is having job stability, career growth and a comfortable work life balance."
  }
}
