import React from "react"
import styled from "styled-components"
import { Text, Flex } from "@engaging-tech/components"

const TitleText = styled(Text)`
  text-transform: capitalize;
`

const HeaderWrapper = styled(Flex)`
  border-radius: 4px 4px 0 0;
`

const Header = ({ title, isPlural, ...props }) => (
  <HeaderWrapper width={1 / 1} alignItems="center" p={3} {...props}>
    <TitleText fontWeight="500">{isPlural ? `${title}s` : title}</TitleText>
  </HeaderWrapper>
)

Header.defaultProps = {
  title: ""
}

export default Header
