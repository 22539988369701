import React from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"

const AbsoluteFlex = styled(Flex)`
  position: absolute;
  bottom: 65px;
  right: -125px;
  width: 300px;
  height: fit-content;
  background-color: white;
  padding: 10px;
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.colors.error[0]}`};
  border-radius: 8px;
`

const ShowPageErrors = ({ pageErrors }) => {
  return (
    <AbsoluteFlex>
      {pageErrors.map(error => (
        <Text my={2}>{error}</Text>
      ))}
    </AbsoluteFlex>
  )
}

export default ShowPageErrors
