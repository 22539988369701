/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import ManageChoiceContainer from "../../containers/Branching/ManageChoice"

const StyledText = styled(Text)`
  border-top: ${({ theme }) => `4px solid ${theme.colors.primary[0]}`};
  transform: translate(-24px, 0px);
  max-width: 936px;
  width: 200%;
`

const ManageQuestion = ({ question }) => {
  const [values, setValues] = useState([])

  useEffect(() => {
    if (question.type === "scale") {
      // Generates an array from min and max numbers
      setValues(
        Array.from({ length: question.max - question.min + 1 }, (_, index) => ({
          id: (question.min + index).toString(),
          value: question.min + index
        }))
      )
    } else {
      setValues(question.values)
    }
  }, [])

  return (
    <Flex key={question.id} mt={6} flexDirection="column">
      <StyledText
        fontSize={5}
        fontWeight={600}
        color="dark.0"
        zIndex={99}
        py={2}
        bg="primary.1"
        pl="24px"
      >
        {question?.title || "Untitled question"}
      </StyledText>
      {values?.map(choice => (
        <ManageChoiceContainer
          key={choice?.id}
          currentChoice={choice}
          currentQuestion={question}
        />
      ))}
    </Flex>
  )
}

export default ManageQuestion
