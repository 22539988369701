import React from "react"
import { useSelector } from "react-redux"
import { getConfig } from "@engaging-tech/ssr-config"
import DataHighlights from "../containers/DataHighlights"
import { getReportTypename } from "../../Header/store/report.header.selectors"

import CompareAverageHappinessScores from "../containers/CompareAverageHappinessScores"

const HappinessDataHighlightOverview = ({ ...props }) => {
  const reportTypename = useSelector(getReportTypename)
  const { starterReportTypename, exitReportTypename } = getConfig().other
  return (
    <>
      <DataHighlights
        heading={
          reportTypename === starterReportTypename ||
          reportTypename === exitReportTypename
            ? "Overall Score"
            : "Overview"
        }
        reportTypename={reportTypename}
        {...props}
      />
      <CompareAverageHappinessScores
        reportTypename={reportTypename}
        {...props}
      />
    </>
  )
}

export default HappinessDataHighlightOverview
