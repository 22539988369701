import { Button, Flex, Text } from "@engaging-tech/components"
import { useParams, usePaths, useRouter } from "@engaging-tech/routing"
import React, { useState } from "react"
import styled from "styled-components"
import ConfirmationModal from "./ConfirmationModal"

const NormalisedText = styled(Text)`
  text-transform: initial;
`

const Nav = ({ next, prev, saveAndClose, close, status, readOnly = false }) => {
  const [showModal, setShowModal] = useState(false)
  const router = useRouter()
  const paths = usePaths()
  const { id } = useParams()
  return (
    <Flex
      width="100vw"
      height={[100, 70]}
      bg="light.0"
      elevation={3}
      zIndex={99999999}
      position="fixed"
      style={{ bottom: "0px" }}
      alignItems="center"
      px={5}
      py={[3, 0]}
      flexDirection={["column", "row", "row"]}
    >
      <Flex justifyContent={["center", "unset"]}>
        {close && (
          <>
            {showModal && (
              <ConfirmationModal
                animated
                bg="light.0"
                maxWidth={500}
                width={1 / 1}
                flexDirection="column"
                alignItems="center"
                handleCloseSurvey={e => {
                  e.preventDefault()
                  close.onClick(e)
                }}
                handleSave={e => {
                  e.preventDefault()
                  saveAndClose.onClick(e)
                }}
                show={showModal}
                setShowModal={setShowModal}
              />
            )}
            <Button
              variant="secondary.0"
              type="submit"
              style={{ minHeight: 32 }}
              onClick={e => {
                e.preventDefault()
                setShowModal(true)
              }}
              px={4}
              mx={2}
              {...close.props}
            >
              <NormalisedText color="light.0">
                {close.children || "Close"}
              </NormalisedText>
            </Button>
          </>
        )}
        {saveAndClose && !readOnly && (
          <Button
            variant="secondary.0"
            mx={2}
            type="submit"
            style={{ minHeight: 32 }}
            px={4}
            onClick={e => saveAndClose.onClick(e)}
            {...saveAndClose.props}
          >
            <NormalisedText color="light.0">
              {saveAndClose.children || "Save and close"}
            </NormalisedText>
          </Button>
        )}
        {status === "scheduled" && readOnly && (
          <Button
            variant="secondary.0"
            style={{ minHeight: 32 }}
            onClick={() =>
              router.navigate(paths.surveys.editSurvey.replace(":id", id))
            }
            mx={2}
            px={4}
          >
            <NormalisedText color="light.0">Edit</NormalisedText>
          </Button>
        )}
      </Flex>
      <Flex
        ml={[0, "auto"]}
        justifyContent={["center", "unset"]}
        width="auto"
        mt={[2, 0, 0]}
      >
        {prev && (
          <Button
            leadingIcon="chevron_left"
            variant="outline.primary.0"
            color="primary.0"
            style={{ minHeight: 32, boxShadow: "inherit" }}
            type="submit"
            mx={2}
            onClick={e => prev.onClick(e)}
            {...prev.props}
          >
            <NormalisedText color="primary.0">
              {prev.children || "Previous"}
            </NormalisedText>
          </Button>
        )}
        {next && (
          <Button
            trailingIcon="chevron_right"
            variant="primary.0"
            type="submit"
            color="light.0"
            mx={2}
            style={{ minHeight: 32 }}
            onClick={e => next.onClick(e)}
            {...next.props}
          >
            <NormalisedText color="light.0">
              {next.children || "Next"}
            </NormalisedText>
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default Nav
