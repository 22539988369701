import { connect } from "react-redux"

import { getStepQuestions } from "../../../../store/actionPlans/actionPlans.selectors"
import AccordionWrapper from "../../components/EditActionPlan/AccordionWrapper"

const mapState = state => ({
  stepQuestions: getStepQuestions(state)
})

const mapDispatch = dispatch => ({})

const AccordionWrapperContainer = connect(
  mapState,
  mapDispatch
)(AccordionWrapper)

export default AccordionWrapperContainer
