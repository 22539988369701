import { Flex } from "@engaging-tech/components"
import React, { useContext } from "react"
import EditCoreQuestionContainer from "../../containers/EditBody/EditCoreQuestion"
import EditCustomQuestionContainer from "../../containers/EditBody/EditCustomQuestion"
import BottomControlContainer from "../../containers/EditBody/BottomControls"
import TemplateQuestionContext from "../TemplateQuestionContext"

const EditBody = () => {
  const { isCore } = useContext(TemplateQuestionContext)

  return (
    <Flex
      height="270px"
      flexDirection="column"
      p="24px 34px 24px 24px"
      width="100%"
      justifyContent="space-between"
    >
      {isCore ? <EditCoreQuestionContainer /> : <EditCustomQuestionContainer />}
      <BottomControlContainer />
    </Flex>
  )
}

export default EditBody
