import { createSelector } from "reselect"

import { ReportSelectors } from "../.."
import { getAllIndicators } from "../../Indicators/store/indicators.selectors"

export const getHappinessDataHighlight = createSelector(
  ReportSelectors.getReportData,
  data => data?.happinessDataHighlight || data?.happinessDataHighlightOverview
)

export const getRootReportScore = createSelector(
  getHappinessDataHighlight,
  data => data && data.rootReport
)

export const getScore = createSelector(
  getHappinessDataHighlight,
  data => data && data.score
)

export const getIndustryScore = createSelector(
  getHappinessDataHighlight,
  data => data && data.industry
)

export const getGlobalScore = createSelector(
  getHappinessDataHighlight,
  data => data && data.global
)

export const getTrend = createSelector(
  getHappinessDataHighlight,
  data => data && data.trend
)

export const getResponseSize = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.responseSize
)

export const getSurveyResponseRate = createSelector(
  ReportSelectors.getReportResponse,
  response => response?.responseRate
)

export const getFlightRiskMainScore = createSelector(
  getAllIndicators,
  indicators => indicators?.flightRisk?.percentage
)

export const getWellBeingMainScore = createSelector(
  getAllIndicators,
  indicators => indicators?.wellBeingRisk?.percentage
)

// Can handle management confidence, flight risk and well being indicator data. D & I have to be handled separately
export const getIndicatorAverageScores = createSelector(
  [getAllIndicators, (_, type) => type],
  (allIndicators, type) => {
    const selectedIndicator = allIndicators ? allIndicators[type] : null
    if (!selectedIndicator) {
      return {}
    }

    return {
      score: selectedIndicator.average?.score,
      industry: selectedIndicator.average?.industry,
      global: selectedIndicator.average?.global
    }
  }
)
