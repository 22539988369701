import React from "react"
import BasketContainer from "../../basket/containers/BasketContainer"

const Page3 = ({ setCurrentPage }) => {
  const handleBack = () => setCurrentPage(1)

  return (
    <BasketContainer onBack={handleBack} cancelUrl={window.location.href} />
  )
}

export default Page3
