import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import { Tooltip } from "react-tooltip"

import TooltipIcon from "../tooltip-icon"

export default function SurveyScoreToQualify({ participationRate }) {
  return (
    <Flex
      flexDirection="column"
      alignItems="left"
      data-tooltip-id="survey-qualify-score-tooltip"
      data-tooltip-content="This is your response/completion rate to qualify for the award."
    >
      <Flex>
        <Text fontSize={3} color="dark.2" pb={2} width="fit-content">
          Award Completion Rate
        </Text>
        <TooltipIcon />
        <Tooltip id="survey-qualify-score-tooltip" />
      </Flex>
      <Text fontSize={6} fontWeight={800} color="dark.2" width="fit-content">
        {participationRate ?? " -- "}
      </Text>
    </Flex>
  )
}
