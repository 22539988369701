import { gql } from "../../../../lib/Apollo"

export const LIST_SURVEY_TEMPLATES = gql`
  query($status: TemplateStatus!, $type: TemplateType!) {
    listSurveyTemplates(status: $status, type: $type) {
      id
      type
      name
      description
      isDraft
      isBespoke
    }
  }
`

export const LIST_DEFAULT_SURVEY_TEMPLATES = gql`
  query($type: TemplateType!) {
    listDefaultSurveyTemplates(type: $type) {
      id
      type
      name
      isBespoke
      description
      workFromHome
      questions {
        id
        type
        title
        description
        icon
        enabled
        required
      }
      sections {
        name
        visible
        description {
          type
          content
        }
        questions
      }
    }
  }
`

export const DELETE_ORGANISATION_TEMPLATE = gql`
  mutation($surveyTemplateId: ID!) {
    deleteOrganisationTemplate(surveyTemplateId: $surveyTemplateId)
  }
`
