import { questionsPreview } from "../lib/pageControl"
import { types } from "./actions"

const currentDate = new Date()

export const initialState = {
  selfServeSurveyType: null,
  objectType: "",
  currentPage: 1,
  isLoading: false,
  error: null,
  customQuestions: [{}],
  questionsPreview: {
    title: "",
    image: ""
  },
  survey: {
    templateId: null,
    title: "",
    introduction: "",
    startDate: new Date(),
    endDate: new Date(currentDate.setDate(currentDate.getDate() + 1)),
    recipients: [],
    creditsPaid: 5,
    mode: "shareableLinks",
    shareableLinks: 0,
    private: false
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OBJECT_TYPE:
      return {
        ...state,
        objectType: action.payload
      }

    case types.SET_SURVEY_TYPE:
      return {
        ...state,
        selfServeSurveyType: action.payload,
        questionsPreview: questionsPreview[action.payload]
      }

    case types.RESET_PAGE:
      return {
        ...state,
        currentPage: 1,
        questionsPreview: {
          title: "",
          image: ""
        },
        survey: {
          templateId: null,
          title: "",
          introduction: "",
          startDate: new Date().setHours(1, 0, 0),
          endDate: new Date(
            currentDate.setDate(currentDate.getDate() + 1)
          ).setHours(1, 0, 0),
          recipients: [],
          creditsPaid: 5,
          mode: "shareableLinks",
          shareableLinks: 0
        },
        error: null
      }

    case types.SAVE_SELF_SERVE_SURVEY_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case types.SAVE_SELF_SERVE_SURVEY_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        survey: {
          ...state.survey,
          title: action.payload.title,
          introduction: action.payload.introduction,
          templateId: action.payload.templateId,
          startDate: new Date(action.payload.meta.startDate),
          endDate: new Date(action.payload.meta.endDate),
          recipients: action.payload.meta.recipients,
          creditsPaid: action.payload.meta.creditsPaid,
          mode: action.payload.meta.mode,
          shareableLinks: action.payload.meta.shareableLinks
        }
      }
    }

    case types.REMOVE_RECIPIENTS:
      return {
        ...state,
        survey: {
          ...state.survey,
          recipients: state.survey.recipients.filter(
            recipients => recipients !== action.payload
          )
        }
      }

    case types.SET_RECIPIENTS:
      return {
        ...state,
        survey: {
          ...state.survey,
          recipients: state.survey.recipients?.includes(action.payload)
            ? state.survey.recipients
            : [...state.survey.recipients, action.payload]
        }
      }
    case types.SET_AMOUNT:
      return {
        ...state,
        survey: {
          ...state.survey,
          creditsPaid: parseInt(action.payload, 10)
        }
      }
    case types.SET_MODE:
      return {
        ...state,
        survey: {
          ...state.survey,
          mode: action.payload
        }
      }
    case types.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }

    case types.SET_TITLE:
      return {
        ...state,
        survey: {
          ...state.survey,
          title: action.payload
        }
      }

    case types.SET_INTRODUCTION:
      return {
        ...state,
        survey: {
          ...state.survey,
          introduction: action.payload
        }
      }

    case types.SET_DATE:
      return {
        ...state,
        survey: {
          ...state.survey,
          startDate: action.payload?.startDate || state.survey.startDate,
          endDate: action.payload?.endDate || state.survey.endDate
        }
      }

    case types.SET_PRIVATE: {
      return {
        ...state,
        survey: {
          ...state.survey,
          private: action.payload
        }
      }
    }

    default:
      return state
  }
}
