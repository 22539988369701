import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"

import { OrgGuard } from "../../features/account"
import BIPView from "../../features/business-information/bip-view"
import PageLayout from "../../layouts/PageLayout"
import addParams from "../../utils/addParams"

export default function BIP() {
  useEffect(() => {
    addParams(false, getConfig().entitlements.bip, "type")
  }, [])

  return (
    <OrgGuard>
      <PageLayout>
        <BIPView />
      </PageLayout>
    </OrgGuard>
  )
}
