/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@engaging-tech/components"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import StepManagementHeader from "./StepManagementHeader"
import StepButtons from "./StepButtons"
import StepSuccessMsg from "./StepSuccessMsg"
import StepBody from "./StepBody"
import TemplateQuestionContext from "../TemplateQuestionContext"

const BespokeGrid = styled(Box)`
  display: grid;
  grid-template-areas:
    "A"
    "B"
    "C";
  width: 350px;
  height: 270px;
`

const StepManagement = ({
  bespokeSteps,
  setBespokeSteps,
  templateCustomQuestions,
  addStepToQuestion,
  deleteStepFromQuestion,
  setQuestionTitle
}) => {
  const { setOpenBespokeStep, id, customStepName } = useContext(
    TemplateQuestionContext
  )
  const filterBespokeSteps = (questionList, csn) => {
    return questionList.filter(question => question.customStepName === csn)
  }

  const currentCsn = useRef(customStepName)
  const [openInput, setOpenInput] = useState(false)
  const [selectedStep, setSelectedStep] = useState("")
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [localText, setLocalText] = useState("")
  const [errorMsg, setErrorMsg] = useState(false)
  const [openStepEdit, setOpenStepEdit] = useState(false)
  const [persistModal, setPersistModal] = useState(false)
  const [bespokeStepQuestions, setBespokeStepQuestions] = useState([])

  const clearModal = () => {
    setSelectedStep("")
    setShowSuccessMsg(false)
    setOpenBespokeStep(false)
  }

  const handleClick = () => {
    if (!openInput && !selectedStep) setOpenInput(true)
    if (localText) {
      setBespokeSteps([...bespokeSteps, localText])
      setLocalText("")
      setOpenInput(false)
    }
    if (selectedStep) {
      addStepToQuestion(selectedStep, id)

      setShowSuccessMsg(true)
    }
  }

  const handleBack = () => {
    setOpenInput(false)
    setLocalText("")
  }

  const closeModal = () => {
    setOpenInput(false)
    setLocalText("")
    setOpenBespokeStep(false)
    setPersistModal(false)
  }

  const unselect = () => setSelectedStep("")

  const deleteQuestionFromStep = ({ customStepName: value }) => {
    deleteStepFromQuestion(value, id)
    setPersistModal(true)
  }

  useEffect(() => {
    if ((!showSuccessMsg && customStepName) || persistModal) {
      setOpenStepEdit(true)
    } else {
      setOpenStepEdit(false)
    }
  }, [showSuccessMsg, customStepName, persistModal])

  useEffect(() => {
    if (bespokeSteps.includes(localText)) {
      setErrorMsg("Duplicate Name")
    } else {
      setErrorMsg(false)
      setPersistModal(false)
    }
  }, [localText])

  useEffect(() => {
    setBespokeStepQuestions(
      filterBespokeSteps(templateCustomQuestions, currentCsn.current)
    )
  }, [templateCustomQuestions])

  if (showSuccessMsg)
    return (
      <StepSuccessMsg selectedStep={selectedStep} clearModal={clearModal} />
    )

  return (
    <BespokeGrid>
      <StepManagementHeader
        openInput={openInput}
        openStepEdit={openStepEdit}
        customStepName={customStepName}
        bespokeSteps={bespokeSteps}
        bespokeStepQuestions={bespokeStepQuestions}
        currentCsn={currentCsn}
        setBespokeSteps={setBespokeSteps}
        addStepToQuestion={addStepToQuestion}
      />
      <StepBody
        openInput={openInput}
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        setLocalText={setLocalText}
        errorMsg={errorMsg}
        openStepEdit={openStepEdit}
        bespokeStepQuestions={bespokeStepQuestions}
        deleteQuestionFromStep={deleteQuestionFromStep}
        bespokeSteps={bespokeSteps}
        setQuestionTitle={setQuestionTitle}
      />
      <StepButtons
        openInput={openInput}
        localText={localText}
        selectedStep={selectedStep}
        errorMsg={errorMsg}
        handleClick={handleClick}
        closeModal={closeModal}
        handleBack={handleBack}
        unselect={unselect}
        openStepEdit={openStepEdit}
      />
    </BespokeGrid>
  )
}

export default StepManagement
