import { Flex, Modal, Spinner, Text } from "@engaging-tech/components"
import { Formik } from "formik"
import React from "react"
import { createPortal } from "react-dom"
import { useDispatch, useSelector } from "react-redux"

import UPDATE_TEMPLATE_LAYOUT from "../../../graphql/mutations/updateTemplateLayout"
import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import ErrorState from "../../business-information/components/error-state"
import { load } from "../store/report.actions"
import { getRequiredFilter, getSurveyType } from "../store/report.selectors"
import ConfigureLayoutForm from "./ConfigureLayoutForm"

const appRoot = () => typeof document !== "undefined" && document.getElementById("root")

const changeDataShape = data => {
  if (!data) return {}

  return data.reduce((acc, next) => {
    if (next.child) {
      acc[`${next.name}-${next.child}`] = next.visible
    } else {
      acc[next.name] = next.visible
    }
    return acc
  }, {})
}

const recoverDataShape = data => {
  const reqObj = []
  Object.keys(data).map(key => {
    if (key.includes("-")) {
      const keys = key.split("-")
      reqObj.push({ name: keys[0], child: keys[1], visible: data[key] })
    } else {
      reqObj.push({ name: key, visible: data[key] })
    }
  })

  return reqObj
}

const ConfigureLayoutModal = ({ setOpenModal, data, isLoading, errors, surveyId, layoutParent }) => {
  const dispatch = useDispatch()
  const reportFilters = useSelector(getRequiredFilter)
  const surveyType = useSelector(getSurveyType)

  const { isLoading: loading, errors: error, post } = useBusinessAppMutation(UPDATE_TEMPLATE_LAYOUT)

  const renderData = changeDataShape(data?.getSurveyLayout)

  const nextAction = () => {
    dispatch(load(surveyId, false, reportFilters, surveyType))
    setOpenModal(false)
  }

  const onSubmit = async values => {
    const submitVariables = {
      surveyId,
      templateLayout: {
        parent: layoutParent,
        layout: recoverDataShape(values)
      }
    }

    post(submitVariables, () => nextAction())
  }

  if (errors || error) return <ErrorState />
  if (isLoading || loading)
    return createPortal(<Spinner justifyContent="center" color="primary.0" />, appRoot())

  return createPortal(
    <Modal disableScrollbar justifyContent="center" alignItems="center" maxWidth="400px">
      <Flex width={1 / 1} p={3} bg="light.0" flexDirection="column">
        <Text fontSize={6} color="secondary.0" width={1 / 1} textAlign="center" fontWeight={600} mb={3}>
          Configure Layout
        </Text>
        <Formik initialValues={renderData} enableReinitialize={true} onSubmit={onSubmit}>
          {({ values, handleBlur, handleSubmit, setFieldValue }) => (
            <ConfigureLayoutForm
              values={values}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              setOpenModal={setOpenModal}
            />
          )}
        </Formik>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default ConfigureLayoutModal
