import React from "react"
import EBNCard from "../../ui/components/EBNCard"
import Research from "../containers/Research"

const ResearchView = () => (
  <EBNCard title="Research For You" height="auto" mb={5} isSubscribed>
    <Research />
  </EBNCard>
)

export default ResearchView
