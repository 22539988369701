export const types = {
  GET_MY_EVENTS_SUCCESS: "[Events] Get My Events Success",
  GET_MY_EVENTS_FAIL: "[Events] Get My Events Fail"
}

export const getMyEventsFail = payload => ({
  type: types.GET_MY_EVENTS_FAIL,
  payload
})

export const getMyEventsSuccess = payload => ({
  type: types.GET_MY_EVENTS_SUCCESS,
  payload: payload.getMyEvents
})
