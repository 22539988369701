import React from "react"
import {
  Button,
  Flex,
  Icon,
  MobileOnly,
  TabletUp,
  Text
} from "@engaging-tech/components"

const NewPostButton = ({ handleClick, title }) => (
  <>
    <TabletUp>
      <Button
        width={200}
        borderRadius={40}
        bg="primary.0"
        height={40}
        position="absolute"
        mt={-50}
        onClick={handleClick}
      >
        <Icon mx={2} color="dark.0" name="work" />
        <Text fontWeight={500}>{title}</Text>
      </Button>
    </TabletUp>
    <MobileOnly width="100%">
      <Flex justifyContent="center" mb={-25} mt={3}>
        <Button
          width={200}
          borderRadius={40}
          bg="primary.0"
          height={50}
          onClick={handleClick}
        >
          <Icon mx={2} color="dark.0" name="work" />
          <Text fontWeight={500}>{title}</Text>
        </Button>
      </Flex>
    </MobileOnly>
  </>
)

export default NewPostButton
