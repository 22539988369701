import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import DashboardLayout from "../../../layouts/DashboardLayout"
import { OrgGuard } from "../../../features/account"
import NewSurveyReportCreationView from "../../../features/Reports/containers/NewSurveyReportCreationView"

import {
  setSurveyType,
  setDeleteReport,
  loadReportsSuccess,
  setGenerateReport
} from "../../../features/Reports/store/report.actions"

const NewStarterReportCreation = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSurveyType(["employeeStarter"]))
    dispatch(loadReportsSuccess([]))
    dispatch(setDeleteReport([]))
    dispatch(setGenerateReport([]))
  }, [])

  return (
    <OrgGuard>
      <DashboardLayout>
        <NewSurveyReportCreationView />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default NewStarterReportCreation
