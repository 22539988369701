import { Button } from "@engaging-tech/components"
import Flex from "@engaging-tech/components/dist/lib/Flex/Flex"
import React from "react"
import styled from "styled-components"

const StyledButton = styled(Button)`
  :hover {
    background-color: ${({ theme }) => theme.colors.primary[0]};
    color: ${({ theme }) => theme.colors.light[0]};
  }
`

const SliderContainer = styled(Flex)`
  position: fixed;
  overflow: hidden;
  bottom: 0px;
  left: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 9999;
`

const InnerContainer = styled(Flex)`
  justify-content: space-around;
  padding-top: 8px;
  border-top: 1px solid black;
`

const ReportsBar = ({
  isBarOpen,
  setReportsToBeSelected,
  buttonText,
  openModal,
  setOpenModal
}) => {
  return (
    <>
      <SliderContainer width={1 / 1} height={isBarOpen ? "60px" : "0px"}>
        <InnerContainer width={1 / 1}>
          <Button
            bg="secondary.0"
            color="light.0"
            btnText={buttonText}
            width={[2 / 5, 2 / 5, 1 / 5]}
            onClick={() => setOpenModal(!openModal)}
          >
            {buttonText}
          </Button>

          <StyledButton
            variant="outline.primary.0"
            elevation={0}
            width={[2 / 5, 2 / 5, 1 / 5]}
            onClick={() => setReportsToBeSelected([])}
          >
            Clear All
          </StyledButton>
        </InnerContainer>
      </SliderContainer>
    </>
  )
}

export default ReportsBar
