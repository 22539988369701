import React from "react"
import styled from "styled-components"
import { Box, Flex } from "@engaging-tech/components"

const SeparatorBox = styled(Box)`
  height: 1px;
  background-color: transparent;
  border-top: ${({ lineColor, lineHeight, lineStyle, theme }) =>
    `${lineHeight} ${lineStyle} ${
      theme.colors[lineColor.split(".")[0]][lineColor.split(".")[1]]
    }`};
`

const Separator = ({
  width = 9 / 10,
  lineColor = "dark.3",
  lineHeight = "1px",
  lineStyle = "solid",
  my = 2
}) => (
  <Flex width={1} justifyContent="center" my={my}>
    <SeparatorBox
      width={width}
      lineColor={lineColor}
      lineHeight={lineHeight}
      lineStyle={lineStyle}
    />
  </Flex>
)

export default Separator
