/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react"
import { useSpring, useViewportScroll } from "framer-motion"
import { Utils } from "@engaging-tech/components"

const usePercentageGraphicAnimation = ({
  scoreToAnimateTo,
  delay = 0,
  containerRef,
  deactivate = false
}) => {
  const [currentPercentage, setCurrentPercentage] = useState(0)
  const percentageSpring = useSpring(0, { stiffness: 50 })
  const { scrollY } = useViewportScroll()

  const timeout = useRef()
  const animationTimeout = () => {
    timeout.current = setTimeout(() => {
      percentageSpring.set(scoreToAnimateTo)
    }, delay)
  }

  useEffect(() => {
    if (Utils.isInViewport(containerRef?.current)) {
      animationTimeout()
    }
    if (!deactivate) {
      const cancelOnChangeScroll = scrollY.onChange(y => {
        if (Utils.isInViewport(containerRef?.current)) {
          animationTimeout()
        }
      })

      const cancelOnChange = percentageSpring.onChange(v =>
        setCurrentPercentage(v > 100 ? 100 : v)
      )

      return () => {
        cancelOnChange()
        cancelOnChangeScroll()
        if (timeout.current) {
          clearTimeout(timeout.current)
        }
      }
    }
    return () => {}
  }, [scoreToAnimateTo])

  return currentPercentage
}

export default usePercentageGraphicAnimation
