import React from "react"
import { Flex, H1, Text } from "@engaging-tech/components"
import { useSelector } from "react-redux"
import BasketContainer from "../containers/BasketContainer"
import { getBasketItems } from "../../../store/basket/basket.selectors"

const BasketView = () => {
  const basketLength = useSelector(getBasketItems).length

  return (
    <Flex flexDirection="column">
      <H1 fontSize={6} fontWeight={700} color="secondary.0">
        Your Basket
      </H1>
      <Text mt={2} color="secondary.0">
        {basketLength !== 0 &&
          "Click on your preferred payment option to make your purchase on our secure payment page"}
      </Text>
      <BasketContainer />
    </Flex>
  )
}

export default BasketView
