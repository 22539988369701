import { connect } from "react-redux"
import { EBNSelectors } from ".."
import ArticlesComponent from "../components/Articles"

const mapState = state => ({
  articles: EBNSelectors.getRecentArticles(state)
})

const Articles = connect(mapState)(ArticlesComponent)

export default Articles
