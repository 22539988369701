import { Flex, Text } from "@engaging-tech/components"
import { DateTime } from "luxon"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { Card, DatePicker } from "../../components"
import { getSurvey } from "../../survey-view-mode/store/survey-view-mode.selectors"

const StyledText = styled(Text)`
  visibility: ${({ hide }) => (hide ? "hidden" : "visible")};
`

export default function SurveyDatesForm({
  values,
  errors,
  setFieldValue,
  readOnly,
  template,
  startDateDisabled = false,
  ...props
}) {
  const survey = useSelector(getSurvey)
  const surveyEndDate = survey.type === "awards" ? new Date(survey.awardsInfo.endDate) : null

  const tomorrowDate = DateTime.now().plus({ day: 1 }).toJSDate()

  const currentStartDate = values?.startDate ? new Date(values.startDate) : null
  const nextDateFromCurrentDate = currentStartDate
    ? DateTime.fromJSDate(currentStartDate).plus({ day: 1 }).toJSDate()
    : null
  const currentEndDate = values?.endDate ? new Date(values.endDate) : null
  const previousDateFromCurrentEndDate = currentEndDate
    ? DateTime.fromJSDate(currentEndDate).minus({ day: 1 }).toJSDate()
    : null

  const disableDatesInPast = day => {
    return new Date() > day
  }

  const disableWeekends = day => {
    return day.getDay() === 0 || day.getDay() === 6
  }

  const disableStartDateDays = day => {
    if (disableDatesInPast(day)) return true
    if (disableWeekends(day)) return true
    return false
  }

  return (
    <Card {...props}>
      <Text mb={4}>
        When do you want your survey to start and finish? WorkL tip: We recommend your survey is open for at
        least a week to make sure everyone has a chance of completing it.
      </Text>
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignItems="center"
        width={1 / 1}
        height={readOnly ? [60, 30] : [250, 100]}
      >
        {readOnly ? (
          <>
            <Text>
              Start Date: <b>{values && values?.startDate && values.startDate.toLocaleDateString()}</b>
            </Text>
            <Text>
              End Date: <b>{values && values?.endDate && values.endDate.toLocaleDateString()}</b>
            </Text>
          </>
        ) : (
          <>
            <Flex flexDirection="column" mb={[3, 0]}>
              <Text mb={2} ml={1}>
                Start Date
              </Text>
              <DatePicker
                id="startDate"
                name="startDate"
                width="250px"
                dayPickerInputProps={{
                  disabledDays: {
                    before: tomorrowDate,
                    after: previousDateFromCurrentEndDate
                  }
                }}
                disabled={values?.status === "live"}
                style={{ pointerEvents: values?.status === "live" ? "none" : "all" }}
                handleChange={date => {
                  if (values?.status !== "live") {
                    setFieldValue("startDate", new Date(date.setHours(5, 0, 0, 0)))
                    if (typeof window !== "undefined" && window.hj) {
                      window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey start date"])
                    }
                  }
                }}
                value={values && values?.startDate}
                error={errors && errors?.startDate}
              />
            </Flex>
            <Flex flexDirection={readOnly ? "row" : "column"} mb={[3, 0]}>
              <Text mb={2} ml={1}>
                End Date
              </Text>
              <DatePicker
                width="250px"
                id="endDate"
                name="endDate"
                dayPickerInputProps={{
                  disabledDays: {
                    before: tomorrowDate && nextDateFromCurrentDate,
                    after: surveyEndDate
                  }
                }}
                handleChange={date => {
                  setFieldValue("endDate", new Date(date.setHours(22, 0, 0, 0)))
                  if (typeof window !== "undefined" && window.hj) {
                    window.hj("tagRecording", ["[WFB][App][Surveys] Changed survey end date"])
                  }
                }}
                value={values && values?.endDate}
                error={errors && errors?.endDate}
              />
            </Flex>
          </>
        )}

        <StyledText hide={errors && (errors?.endDate || errors?.startDate)}>
          Number of days:{" "}
          {
            <b>
              {values?.endDate && values?.startDate
                ? Math.ceil(Math.abs(values.endDate - values.startDate) / (1000 * 60 * 60 * 24))
                : 0}
            </b>
          }
        </StyledText>
      </Flex>
    </Card>
  )
}
