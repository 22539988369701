import { Flex, Text, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useParams, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import CreationFormsLayout from "../../../../layouts/CreationFormsLayout"
import surveyLifecycle from "../../../../utils/surveyLifecycle"
import ClickableIcon from "../../../ui/components/ClickableIcon"
import Separator from "../../../ui/components/Separator"
import { Card } from "../../components"
import ColourPicker from "../../surveyInfo/components/Branding/ColourPicker"
import LogoPicker from "../../surveyInfo/components/Branding/LogoPicker"
import SurveyLink from "../../surveyInfoReview/components/SurveyLink"
import BottomNav from "../components/bottom-nav"
import EditableField from "../components/editable-field"
import { loadSurvey } from "../store/survey-view-mode.actions"
import { getSurvey } from "../store/survey-view-mode.selectors"

const TestingText = styled(Text)`
  text-transform: uppercase;
`

export default function SurveyViewModeView() {
  const history = useHistory()
  const { id } = useParams()
  const paths = usePaths()

  const survey = useSelector(getSurvey)
  const editDisabled = survey?.template.type === "awards"

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadSurvey(id))
  }, [])

  const onCopy = message => {
    dispatch(userInterfaceNotificationsStore.actions.addNotification({ message: message }))
  }

  const onEdit = () => {
    history.push(paths.surveys.editSurvey.replace(":id", id).replace(":status", survey.status))
  }

  const onGoBack = () => {
    history.push(paths.surveys.index)
  }

  return (
    <CreationFormsLayout
      bg="#EEF4F9"
      maxWidth="1000px"
      mx={20}
      mb={7}
      BottomNav={() => <BottomNav onEdit={onEdit} onGoBack={onGoBack} />}
    >
      {survey?.status && survey.status !== surveyLifecycle.live ? (
        <Flex bg="dark.3" p={[2, 3]} width="100vw" justifyContent="center">
          {[surveyLifecycle.draft, surveyLifecycle.scheduled].includes(survey.status) && (
            <TestingText width="auto" color="light.0" fontSize={[4, 6]} fontWeight="700">
              This survey is not yet live - Submissions will not be recorded
            </TestingText>
          )}

          {surveyLifecycle.completed === survey.status && (
            <TestingText width="auto" color="light.0" fontSize={[4, 6]} fontWeight="700">
              This survey has already finished! Your submission will not be recorded
            </TestingText>
          )}
        </Flex>
      ) : null}

      <Text mt="20px" fontSize={["18px", 5]} mb={4} fontWeight={700}>
        Your survey is in view mode
      </Text>
      <Text>
        You can view your survey information here and update any of the below survey information by selecting
        the Edit Survey button.
      </Text>

      <SurveyLink id={id} onCopy={onCopy} mt="20px" />

      <Card mt="10px">
        <EditableField heading="Survey Name & Intro" onEditDisabled={editDisabled} onEdit={onEdit}>
          <Text fontSize={4} fontWeight="bold">
            <b>{survey?.title}</b>
          </Text>
        </EditableField>
        <Separator width={1 / 1} lineColor="dark.3" />
        <EditableField
          heading="Survey template"
          hiddeEdit={survey?.type === "awards"}
          onEditDisabled={editDisabled}
          onEdit={() => {
            if (survey?.type !== "awards") {
              onEdit()
            }
          }}
        >
          <Flex alignItems={["flex-start", "center"]} flexDirection={["column", "row"]}>
            <Text fontWeight="bold" width="auto" mr={5} mb={[3, 0]}>
              <b>{survey?.template.name}</b>
            </Text>
          </Flex>
        </EditableField>
        <Separator width={1 / 1} lineColor="dark.3" />
        <EditableField heading="Survey time frame" onEditDisabled={editDisabled} onEdit={onEdit}>
          <Flex flexDirection={["column", "row"]}>
            <Text>
              Start Date:{" "}
              <b>{survey?.meta.startDate ? survey.meta.startDate.toLocaleDateString() : " -- "}</b>
            </Text>
            <Text>
              End Date: <b>{survey?.meta.endDate ? survey.meta.endDate.toLocaleDateString() : " -- "}</b>
            </Text>
            <Text>
              Length of survey:{" "}
              <b>
                {survey?.meta.endDate && survey?.meta.startDate
                  ? Math.ceil(Math.abs(survey.meta.endDate - survey.meta.startDate) / (1000 * 60 * 60 * 24))
                  : " -- "}{" "}
                days
              </b>
            </Text>
          </Flex>
        </EditableField>
      </Card>

      {survey?.type !== "awards" ? (
        <Card mt="10px">
          <Flex flexDirection="row-reverse">
            <ClickableIcon
              name="mode_edit"
              disabled={survey?.type === "awards"}
              onClick={e => {
                e.preventDefault()

                if (survey?.type !== "awards") {
                  onEdit()
                }
              }}
            />
          </Flex>
          <Flex flexDirection={["column", "row"]} width={1 / 1}>
            <ColourPicker
              values={{
                colour: survey?.meta.brand.colour
              }}
              readOnly
            />
            <LogoPicker
              surveyId={id}
              values={{
                logo: survey?.meta.brand.logo
              }}
              readOnly
            />
          </Flex>
        </Card>
      ) : null}
    </CreationFormsLayout>
  )
}
