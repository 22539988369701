import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import { Tooltip } from "react-tooltip"

import TooltipIcon from "../tooltip-icon"

export default function ResponseRate({ responseRate, status }) {
  return (
    <Flex
      flexDirection="column"
      width={["100%", "100%", 110]}
      minWidth={["100%", "100%", 110]}
      alignItems="left"
      mb={[3, 3, 0]}
    >
      <Flex>
        <a
          data-tooltip-id="survey-response-rate-tooltip"
          data-tooltip-content="This is your survey completion rate calculated by avg of submission / participants%"
        >
          <Text fontSize={3} color="dark.2" pb={2} width="auto">
            Response Rate
          </Text>
          <TooltipIcon />
        </a>
        <Tooltip id="survey-response-rate-tooltip" />
      </Flex>
      <Text fontSize={6} fontWeight={800} color="dark.2" width="auto">
        {["live", "completed"].includes(status) ? `${responseRate ?? 0}%` : " -- "}
      </Text>
    </Flex>
  )
}
