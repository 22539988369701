const { gql } = require("@apollo/client")

const GET_BASKET = gql`
  query getBasket {
    getBasket {
      totalPrice
      price
      tax
      acceptedCurrencies
      products {
        productId
        object_type
        priceId
        priceName
        productName
        priceDescription
        unit_amount
        thumbnail
        disclaimer
      }
    }
  }
`

export default GET_BASKET
