/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Flex,
  Icon,
  Paragraph,
  Text
} from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"
import SiteLogo from "../../ui/components/SiteLogo"

const BorderFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[5]};
`

const Grid = ({ icon, title, paragraph }) => (
  <Box
    bg="secondary.4"
    width={[1 / 1, "244px"]}
    m={2}
    px={3}
    py={2}
    borderRadius={4}
  >
    <Flex flexDirection="row" alignItems="center">
      <Icon name={icon} mr={2} color="dark.2" />
      <Text fontSize={2} color="dark.1" fontWeight={500}>
        {title}
      </Text>
    </Flex>
    <Text fontSize={2} color="dark.2" fontWeight={300}>
      {paragraph}
    </Text>
  </Box>
)

const gridInfo = [
  {
    title: "Launch Engagement Surveys",
    icon: "poll",
    paragraph:
      "Monitor, track and improve employee experience at your organisation with our extensive range of surveys."
  },
  {
    title: "Enter Awards",
    icon: "work",
    paragraph:
      "Enter awards in the UK, Ireland, Australia and many more! Help attract, recruit and retain employees."
  },
  {
    title: "Promote your Organisation",
    icon: "supervised_user_circle-24px",
    paragraph:
      "Claim and populate your Business Information Page to promote your organisation."
  },
  {
    title: "Post Job Vacancies",
    icon: "insert_invitation",
    paragraph:
      "Target job seekers and find the best candidates for your vacancies."
  }
]

const Thankyou = ({ navigateTo = null, buttonText = "Get Started" }) => {
  const paths = usePaths()
  const router = useRouter()

  const nav = navigateTo ?? paths.home.index

  return (
    <Flex p={3} justifyContent="center" alignItems="center" mb={[6, 0]}>
      <Flex
        maxWidth={560}
        elevation={12}
        p={3}
        bg="light.0"
        borderRadius={3}
        flexDirection="column"
        alignItems="center"
      >
        <BorderFlex flexDirection="column" alignItems="center">
          <SiteLogo />
          <Text
            color="dark.3"
            fontSize={5}
            fontWeight={600}
            textAlign="center"
            py={3}
          >
            Success! Thank you for signing up
          </Text>
          <Paragraph textAlign="center" fontSize={[4, 3, 3]} color="dark.2">
            Welcome to the WorkL for Business Network.
          </Paragraph>
          <Paragraph textAlign="center" fontSize={[4, 3, 3]} color="dark.2">
            Here you can find all of your employee experience services in one
            place.
          </Paragraph>
        </BorderFlex>
        <Flex flexWrap="wrap" py={3} justifyContent="center" width={1 / 1}>
          {gridInfo.map((point, index) => (
            <Grid
              key={index}
              title={point.title}
              icon={point.icon}
              paragraph={point.paragraph}
            />
          ))}
        </Flex>
        <Button
          variant="secondary.0"
          px={5}
          onClick={() => router.navigate(nav)}
        >
          <Text fontSize={3} color="light.0">
            {buttonText}
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default Thankyou
