import DiversityAndInclusionBreakdown from "../containers/DiversityAndInclusionBreakdown"
import FlightRiskBreakdown from "../containers/FlightRiskBreakdown"
import WellBeingBreakdown from "../containers/WellBeingBreakdown"
import ConfidenceInManagementBreakdown from "../containers/ConfidenceInManagementBreakdown"

import ManagementConfidenceLogo from "../../reportLogos/ManagementConfidenceLogo"
import WellBeingLogo from "../../reportLogos/WellBeingLogo"
import FlightRiskLogo from "../../reportLogos/FlightRiskLogo"
import DiversityAndInclusionLogo from "../../reportLogos/DiversityAndInclusionLogo"

const indicatorBreakdownInfos = {
  "diversity-and-inclusion": {
    Component: DiversityAndInclusionBreakdown,
    howToText: [
      "Most companies recognize that not all customers are alike and offer different value propositions based on the preferences of various groups. The same goes for employees. By grouping employees according to their needs, your organisation can tailor everything from terms of employment to incentives for different segments. Identifying segments helps improve financial performance, customer service and employee satisfaction—and reduces costs.",
      "The logic is simple: just as customers don’t fit neatly into a single homogenous group, employees are motivated by different factors that are often dictated by their stage of life or personal interests. By learning what motivates each group and offering a customized program that delivers what matters most, companies find that employees are willing to work more effectively and devote more discretionary thought and attention to their jobs. Whether companies apply the approach with call center agents, field force workers or any other employee group, it can deliver positive results."
    ],
    props: {
      HeadingImageComponent: DiversityAndInclusionLogo,
      heading: "Diversity & Inclusion Summary",
      description:
        "This graph displays the percentage points of difference between the minority and majority employees at your organisation. A positive score means the minority groups have scored more highly, a negative score means the majority groups have scored more highly.",
      yAxisLabel: "Percentage point difference in Average Score",
      yAxisDecimalPlaces: 1,
      graphOptions: {
        plugins: {
          datalabels: {
            display: context =>
              context.dataset.data[context.dataIndex] > 0 ||
              context.dataset.data[context.dataIndex] < 0
          }
        }
      }
    }
  },
  "flight-risk": {
    Component: FlightRiskBreakdown,
    howToText: [
      "Most companies recognise that employees increase in value to their organisation as knowledge and experience increases, and for this reason, the ability to retain talented, experienced individuals is important.",
      "Flight Risk provides insight into the likelihood of employees to leave their organisation in the near future.",
      "The higher the number, the larger the proportion of respondents who are likely to leave their organisation. The lower the number, the lower the risk.",
      "Visibility of this risk enables an organisation to focus on the key areas influencing the underlying causes of unhappiness, which may, if not improved, drive an employee to exit an organisation."
    ],
    props: {
      HeadingImageComponent: FlightRiskLogo,
      heading: "Flight Risk Summary",
      description:
        "The Flight Risk Indicator is the percentage of employees who scored below 60% in an averaged score across the following 4 questions: I am fairly paid, I have a good relationship with my manager, I enjoy my job and I am being developed.",
      yAxisLabel: "Average Score",
      graphOptions: {
        scales: {
          y: {
            min: 0,
            max: 100
          }
        },
        plugins: {
          datalabels: {
            display: context =>
              context.dataset.data[context.dataIndex] > 0 ||
              context.dataset.data[context.dataIndex] < 0
          }
        }
      }
    }
  },
  "well-being": {
    Component: WellBeingBreakdown,
    howToText: [
      "In recent years there has been an increasing focus on the wellbeing of the workforce, and following a global pandemic, this continues to be an important issue for both employers and employees alike. There are a number of contributing factors to wellbeing, which can be monitored through this wellbeing risk indicator.",
      "The Wellbeing Risk score is the percentage of people who have Low/Poor wellbeing within their organisation.",
      "The higher the number, the larger percentage of respondents who have low wellbeing and the higher the risk. The closer the percentage is to 0%, the better the overall wellbeing score.",
      "Visibility of this risk enables an organisation to focus on the key areas influencing an employee’s view of their wellbeing."
    ],
    props: {
      HeadingImageComponent: WellBeingLogo,
      heading: "Wellbeing Risk Index",
      description:
        "The wellbeing Indicator is the percentage of employees who scored below 60% in an averaged score across the following 4 questions: My employer cares for my wellbeing, I rarely feel anxious or depressed about work, I am happy with my working environment and I feel happy at work.",
      yAxisLabel: "Average Score",
      graphOptions: {
        scales: {
          y: {
            min: 0,
            max: 100
          }
        },
        plugins: {
          datalabels: {
            display: context =>
              context.dataset.data[context.dataIndex] > 0 ||
              context.dataset.data[context.dataIndex] < 0
          }
        }
      }
    }
  },
  "confidence-in-management": {
    Component: ConfidenceInManagementBreakdown,
    howToText: [
      "Confidence in Management is important in terms of how the leadership takes action, drives results and how employees engage with the purpose of the organisation. When confidence is high, the employees are clear on direction of travel, their contribution towards success and are invested in a successful outcome.",
      "The Confidence in Management score is the percentage of people who have a positive perception of how the organisation is established and functioning.",
      "The higher the number, the larger percentage of respondents have confidence, the lower the risk. The closer the percentage is to 100%, the better the overall score.",
      "Visibility of this risk enables an organisation to focus on the key areas influencing an employees view of the organisation within which they work."
    ],
    props: {
      HeadingImageComponent: ManagementConfidenceLogo,
      heading: "Confidence in Management Index",
      description:
        "The Confidence in Management score is an average of the following 4 questions: I do something worthwhile, I feel proud to work for my organisation, I work in a well run organisation and I understand the organisation’s plan.",
      yAxisLabel: "Average Score",
      graphOptions: {
        scales: {
          y: {
            min: 0,
            max: 100
          }
        },
        plugins: {
          datalabels: {
            display: context =>
              context.dataset.data[context.dataIndex] > 0 ||
              context.dataset.data[context.dataIndex] < 0
          }
        }
      }
    }
  }
}

export default indicatorBreakdownInfos
