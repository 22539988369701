import { connect } from "react-redux"
import ComparisonView from "../components/ComparisonView"
import { ReportSelectors, ReportActions } from "../../index"

const mapState = state => ({
  surveyComparisonData: ReportSelectors.getSurveyComparisonData(state),
  dataset: ReportSelectors.getComparisonDataset(state),
  isLoading: ReportSelectors.getIsLoading(state),
  hasError: ReportSelectors.getHasError(state)
})

const mapDispatch = dispatch => ({
  setDataset: dataset => dispatch(ReportActions.setComparisonDataset(dataset))
})

const ComparisonViewContainer = connect(mapState, mapDispatch)(ComparisonView)

export default ComparisonViewContainer
