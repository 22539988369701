import React from "react"
import { Flex } from "@engaging-tech/components"
import { SectionTab } from "../../../selfServiceSurveys/components/Page2/SectionBreak"
import TemplateQuestionContainer from "../containers/TemplateQuestion"
import TemplateSectionCard from "../containers/TemplateSectionCard"

const SectionBody = ({ section, total, current }) => {
  const { description, name, questions, sectionId } = section
  const renderQuestions = questions?.map(questionId => (
    <TemplateQuestionContainer key={questionId} questionId={questionId} />
  ))

  return (
    <Flex flexDirection="column">
      <SectionTab current={current} total={total} />
      <TemplateSectionCard
        title={name}
        introduction={description}
        id={sectionId}
      />
      {renderQuestions}
    </Flex>
  )
}

export default SectionBody
