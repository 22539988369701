/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-dynamic-require */
import { Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import BranchingBackButton from "./BranchingBackButton"
import BranchingContext from "./BranchingContext"
import BranchingHeader from "./BranchingHeader"
import ManageQuestion from "./ManageQuestion"

const NoOverflowFlex = styled(Flex)`
  overflow: hidden;
`

const BranchingCard = ({
  showBranching,
  setShowBranching,
  branchingQuestion
}) => {
  return (
    <BranchingContext.Provider value={{ branchingQuestion }}>
      <NoOverflowFlex
        borderRadius="10px"
        bg="light.0"
        p={4}
        width={1}
        height="auto"
        elevation={3}
        flexDirection="column"
        alignItems="center"
        position="relative"
        overflow="hidden"
      >
        <Flex width={1} justifyContent="flex-between">
          <BranchingBackButton
            onClick={() => setShowBranching(!showBranching)}
          />
        </Flex>
        <BranchingHeader />
        {branchingQuestion?.map(question => (
          <ManageQuestion question={question} key={question.id} />
        ))}
      </NoOverflowFlex>
    </BranchingContext.Provider>
  )
}
export default BranchingCard
