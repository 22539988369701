import React from "react"

import DashboardLayout from "../../../layouts/DashboardLayout"
import SurveySelectionDashboard from "../../../features/surveyManagement/surveySelection/containers/views/SurveySelectionDashboard"
import { OrgGuard } from "../../../features/account"

const DraftSurveySelectionDashboard = () => {
  return (
    <OrgGuard>
      <DashboardLayout>
        <SurveySelectionDashboard draft />
      </DashboardLayout>
    </OrgGuard>
  )
}

export default DraftSurveySelectionDashboard
