export const types = {
  UPDATE_SURVEY: "[surveyInfo] Updating Survey",
  UPDATE_SURVEY_SUCCESS: "[surveyInfo] Updating Survey Success",
  UPDATE_SURVEY_FAIL: "[surveyInfo] Updating Survey Fail",
  UPLOAD_LOGO: "[surveyInfo] Upload Logo",
  UPLOAD_LOGO_SUCCESS: "[surveyInfo] Upload Logo Success",
  UPLOAD_LOGO_FAIL: "[surveyInfo] Upload Logo Fail"
}

export const updateSurvey = payload => ({
  type: types.UPDATE_SURVEY,
  payload
})

export const updateSurveySuccess = payload => ({
  type: types.UPDATE_SURVEY_SUCCESS,
  payload
})

export const updateSurveyFail = () => ({
  type: types.UPDATE_SURVEY_FAIL
})

export const uploadLogo = payload => ({
  type: types.UPLOAD_LOGO,
  payload
})

export const uploadLogoSuccess = payload => ({
  type: types.UPLOAD_LOGO_SUCCESS,
  payload
})

export const uploadLogoFail = () => ({
  type: types.UPLOAD_LOGO_FAIL
})
