import { Flex, IconButton, Text } from "@engaging-tech/components"
import React from "react"
import { useDispatch } from "react-redux"

import { addQuestion } from "../../../../store/actionPlans/actionPlans.actions"
import ActionPlanQuestionsBodyContainer from "../../containers/EditActionPlan/ActionPlanQuestionsBodyContainer"

const ActionPlanQuestions = ({ questions, stepQuestionId }) => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch(addQuestion(0, stepQuestionId))

  return (
    <Flex width="96%" flexDirection="column">
      <Flex width={1 / 1} alignItems="center" mt={questions.length ? 3 : 0}>
        <Text fontSize={5} fontWeight={600} width="fit-content">
          Action Plan Questions
        </Text>
        {!questions.length && (
          <IconButton
            icon="add_circle"
            onClick={handleClick}
            iconColor="primary.0"
          />
        )}
      </Flex>
      <Flex width={1 / 1} flexDirection="column" alignItems="center">
        {questions?.map((question, questionIndex) => (
          <ActionPlanQuestionsBodyContainer
            key={question.id}
            question={question}
            stepQuestionId={stepQuestionId}
            questionIndex={questionIndex}
            questionsLength={questions?.length}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default ActionPlanQuestions
