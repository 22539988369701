import { gql } from "@apollo/client"

const REMOVE_FROM_BASKET = gql`
  mutation removeFromBasket($productId: String!) {
    removeFromBasket(productId: $productId) {
      price
      tax
      totalPrice
      acceptedCurrencies
      products {
        productId
        priceId
        object_type
        priceName
        productName
        priceDescription
        unit_amount
        thumbnail
      }
    }
  }
`

export default REMOVE_FROM_BASKET
