import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import SubscriptionLayout from "../../../layouts/SubscriptionLayout"
import JoinOurNetworkDetail from "./JoinOurNetworkDetail"
import JoinOurNetworkHeader from "./JoinOurNetworkHeader"

const JoinEvents = ({ handleAction, handleCancel }) => {
  const router = useRouter()
  const paths = usePaths()

  return (
    <SubscriptionLayout>
      <JoinOurNetworkHeader
        subscriptionType="Events"
        description="Create, post and publicise unlimited events to thousands of users on WorkL via our social feed, the Global Hub."
        handleSubscribeClick={() =>
          router.navigate(paths.home.events.subscription)
        }
      />

      <JoinOurNetworkDetail
        handleCancel={handleCancel}
        handleAction={handleAction}
        individualService
      />
    </SubscriptionLayout>
  )
}

export default JoinEvents
