import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import { getConfig } from "@engaging-tech/ssr-config"
import styled from "styled-components"

const BorderedFlex = styled(Flex)`
  div {
    border-left: 2px solid ${({ theme }) => theme.colors.dark[4]};
    span {
      margin: 0 8px;
    }
  }
  div:first-child {
    border: none;
  }
`

const StatColumn = ({ heading, data, color }) => (
  <Flex width="auto" flexDirection="column">
    <Text color="dark.1" fontSize={14} pb={1}>
      {heading}
    </Text>
    <Text color={color} fontWeight={700}>
      {data}
    </Text>
  </Flex>
)

const getIndustryComparisonText = score => {
  if (score === 0) return "No Difference"
  return `${Math.abs(score.toFixed(0))}% ${score >= 0 ? "above" : "below"}`
}

const ResponseStat = ({ surveyResponseSize, surveyResponseRate, color }) => {
  if (surveyResponseRate)
    return (
      <StatColumn
        heading="Response Rate"
        data={`${Math.round(surveyResponseRate)}%`}
        color={color}
      />
    )

  if (surveyResponseSize)
    return (
      <StatColumn
        heading="Number of Respondents"
        data={surveyResponseSize}
        color={color}
      />
    )

  return <StatColumn heading="Response Rate" data="N/A" color={color} />
}

const StatisticBox = ({
  comparedToIndustry,
  surveyResponseRate,
  surveyResponseSize,
  trend,
  primaryColor = "dark.2",
  secondaryColor = "dark.4",
  reportTypename,
  props
}) => {
  const { starterReportTypename, exitReportTypename } = getConfig().other
  if (
    typeof trend !== "number" &&
    typeof surveyResponseRate !== "number" &&
    typeof surveyResponseSize !== "number" &&
    typeof comparedToIndustry !== "number"
  ) {
    return <></>
  }
  return (
    <BorderedFlex
      width={1 / 1}
      height={104}
      bg={secondaryColor}
      mb={2}
      mt={4}
      borderRadius={4}
      alignItems="center"
      justifyContent="space-evenly"
      {...props}
    >
      {typeof trend === "number" && <StatColumn heading="Trend" data={trend} />}
      {typeof surveyResponseRate === "number" &&
        typeof surveyResponseSize === "number" &&
        reportTypename !== starterReportTypename &&
        reportTypename !== exitReportTypename && (
          <ResponseStat
            surveyResponseRate={surveyResponseRate}
            surveyResponseSize={surveyResponseSize}
          />
        )}

      {typeof comparedToIndustry === "number" && (
        <StatColumn
          color={primaryColor}
          heading="Compared to Industry"
          data={getIndustryComparisonText(comparedToIndustry)}
        />
      )}
    </BorderedFlex>
  )
}

export default StatisticBox
