import { Button, Flex, Modal, Paragraph } from "@engaging-tech/components"
import React from "react"
import { createPortal } from "react-dom"

import Filters from "../containers/FiltersButton"

const appRoot = () =>
  typeof document !== "undefined" && document.getElementById("root")

/**
 * Modal used for heatmaps dual toggle in case there is no
 * filters selected
 * @param {function} handleClose function for the onClick action for the "Cancel" button
 * @param {boolean} setOpenModal used for onToggle property in the Modal component
 */
const NoFiltersModal = ({ handleClose, setOpenModal }) => {
  return createPortal(
    <Modal bg="light.0" width="300px" onToggle={setOpenModal}>
      <Flex flexDirection="column" alignItems="center" width={1 / 1}>
        <Paragraph
          width={1 / 1}
          py={1}
          margin={0}
          textAlign="center"
          bg="dark.4"
          fontWeight={600}
          color="dark.2"
        >
          Add Filters
        </Paragraph>
        <Paragraph px={3} color="dark.2">
          Add some filters to compare your data sets.
        </Paragraph>
        <Flex justifyContent="space-around" mb={3} mt={5} alignItems="center">
          <Button variant="text.secondary.0" onClick={handleClose}>
            Cancel
          </Button>
          <Filters noIcon width={130} onClick={handleClose} />
        </Flex>
      </Flex>
    </Modal>,
    appRoot()
  )
}

export default NoFiltersModal
