import { connect } from "react-redux"
import { AccountSelectors } from "../../../account"
import CompareAverageHappinessScoresComponent from "../components/CompareAverageHappinessScores"
import * as Selectors from "../store/datahighlights.selectors"

const mapState = state => ({
  score: Selectors.getScore(state),
  industry: Selectors.getIndustryScore(state),
  global: Selectors.getGlobalScore(state),
  rootReport: Selectors.getRootReportScore(state),
  orgName: AccountSelectors.getOrgName(state)
})

const CompareAverageHappinessScores = connect(mapState)(
  CompareAverageHappinessScoresComponent
)

export default CompareAverageHappinessScores
