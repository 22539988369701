/* eslint-disable react/jsx-curly-newline */
/* eslint-disable quotes */
import React from "react"
import { Button, Flex, H3 } from "@engaging-tech/components"
import { format } from "date-fns"
import { usePaths, useRouter } from "@engaging-tech/routing"
import SurveyIntroCardContainer from "../containers/OrganisationData/SurveyIntroCardContainer"
import EmployeeCountContainer from "../containers/OrganisationData/EmployeeCountContainer"
import DatePickerContainer from "../containers/OrganisationData/DatePickerContainer"
import Privacy from '../../surveyManagement/surveyInfo/components/Privacy';

const OrganisationData = ({
  templateName,
  saveSurvey,
  organisationData,
  pageErrors,
  awardsEndDate,
  setPage,
  setPrivate
}) => {
  const arePageErrors = Object.keys(pageErrors)?.length > 0
  const paths = usePaths()
  const router = useRouter()
  const params = new URLSearchParams(window.location.search).get("inactive")

  return (
    <>
      <H3
        width={1 / 1}
        fontSize={[4, 5]}
        textAlign="center"
        mb={15}
        mt={30}
        fontWeight={600}
        color="secondary.0"
      >
        Template in use: {templateName}
      </H3>
      <SurveyIntroCardContainer mt={4} id="introCard" />
      <EmployeeCountContainer id="employeeCount" />
      <DatePickerContainer
        id="datePicker"
        introduction={`To qualify for ${
          organisationData?.title
        }, you will need to meet a minimum entry requirement based on your organisation size. We recommend keeping your survey open as long as possible to build a maximum number of responses. \n Your survey must finish by the ${format(
          new Date(awardsEndDate),
          "do MMMM y"
        )} for you to qualify. Surveys run on GMT. Set your survey to start a day earlier to accommodate the time difference.`}
      />
      <Privacy values={organisationData} setFieldValue={setPrivate} mb={4}  />
      <Flex justifyContent="space-between">
        <Flex width="280px" justifyContent="space-between">
          <Button
            color="light.0"
            bg="primary.0"
            width="120px"
            onClick={() => {
              saveSurvey({ page: 1, ...organisationData })
              setPage(1)
            }}
          >
            Back
          </Button>
          <Button
            color="light.0"
            bg="secondary.0"
            width="140px"
            onClick={() =>
              saveSurvey({ ...organisationData }).then(() =>
                router.navigate(paths.surveys.draftSurveys)
              )
            }
          >
            Save and close
          </Button>
        </Flex>
        <Button
          color="light.0"
          bg={arePageErrors ? "dark.2" : "primary.0"}
          width="120px"
          disabled={arePageErrors}
          onClick={() => {
            const page = params ? 3 : 4
            saveSurvey({
              page: params ? 3 : 4,
              ...organisationData
            })
            setPage(page)
          }}
        >
          Next ⟶
        </Button>
      </Flex>
    </>
  )
}

export default OrganisationData
