import { connect } from "react-redux"

import { getSliceAndDiceProductBasedOnShareableLinks } from "../../../../store/products/products.selectors"
import SliceAndDiceComponent from "../../../awards/components/SliceAndDiceComponent"

const mapState = (state, { shareableLinks }) => ({
  sliceAndDiceProduct: getSliceAndDiceProductBasedOnShareableLinks(
    state,
    shareableLinks
  )
})

const SliceAndDiceContainer = connect(mapState, null)(SliceAndDiceComponent)

export default SliceAndDiceContainer
