import React from "react"
import { string, object } from "yup"
import { Button, Flex, Icon, TextField } from "@engaging-tech/components"
import styled, { css } from "styled-components"
import { Form, Formik } from "formik"
import Text from "@engaging-tech/components/dist/lib/Text/Text"

const successfulCSS = css`
  border-bottom: 1px solid rgba(19, 186, 0, 1);
  :hover {
    border-bottom: 1px solid rgba(19, 186, 0, 1);
  }
  :focus {
    border-bottom: 1px solid rgba(19, 186, 0, 1);
    + label {
      color: rgba(19, 186, 0, 1);
    }
  }
  + label {
    color: rgba(19, 186, 0, 1);
    :hover {
      color: rgba(19, 186, 0, 1);
    }
    :focus {
      color: rgba(19, 186, 0, 1);
    }
  }

  &[value=""] {
    &:focus {
      & + label {
        color: rgba(19, 186, 0, 1);
      }
    }
  }
`

const CheckIcon = styled(Icon)`
  position: absolute;
  transform: translate(-130%, 0%);
  color: rgba(19, 186, 0, 1);
`
const SuccessTextField = styled(TextField)`
  ${({ success }) => (success ? successfulCSS : "")}
`

const validationSchema = object().shape({
  discountCode: string().required()
})

const ApplyDiscount = ({ onSubmit, isLoading, promotionCodeId, code }) => {
  return (
    <Formik
      initialValues={{ discountCode: code || "" }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({
        handleSubmit,
        values,
        touched,
        isValid,
        errors,
        handleChange,
        handleBlur
      }) => (
        <Form
          onSubmit={handleSubmit}
          style={{ marginTop: "16px", width: "100%" }}
        >
          <Flex justifyContent="center" width={1} alignItems="center">
            <SuccessTextField
              label="Apply Discount Code"
              name="discountCode"
              noLabel
              type="text"
              success={!!promotionCodeId}
              width={1}
              value={values.discountCode}
              error={touched.discountCode && errors.discountCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Flex alignItems="center" width={0} position="relative">
              {!!promotionCodeId && <CheckIcon name="check" />}
            </Flex>
            <Button
              variant="primary.0"
              borderRadius={4}
              fontSize={4}
              ml={4}
              elevation={0}
              disabled={isLoading || !isValid}
              width="100px"
              type="submit"
            >
              {promotionCodeId ? "Applied" : "Apply"}
            </Button>
          </Flex>
          <Text width={1} alignText="right" fontSize={2}>
            *Please enter card details above. If you are receiving a 100%
            discount you will not be charged.
          </Text>
        </Form>
      )}
    </Formik>
  )
}

export default ApplyDiscount
