const tabs = [
  {
    name: "Employees",
    type: "employee"
  },
  {
    name: "Managers",
    type: "management"
  }
]

export default tabs
